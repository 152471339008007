import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useAppContext } from 'context/AppContext';
import strings from 'localization/strings';
import allPaymentProviders from 'enums/paymentProviders';
import { useField } from 'react-final-form';
import { required } from 'form/validation';
import { getInvoiceFee } from 'logic/paymentLogic';
import { makeStyles } from 'styles/util';

import { Radios, showErrorOnBlur } from 'mui-rff';
import StripePaymentElement from '../stripe/StripePaymentElement';
import BillectaPaymentElement from '../billecta/BillectaPaymentElement';
import Box from '@material-ui/core/Box';
import Amount from 'common/Amount';
import MarginBox from 'common/MarginBox';
import Typography from '@material-ui/core/Typography';

const useStyles = makeStyles(({ theme, colors }) => ({
    paymentProvidersContainer: {
        '& .MuiFormGroup-root': {
            gap: theme.spacing(1)
        },
        '& .MuiFormControlLabel-root': {
            alignItems: 'start'
        },
        '& .MuiIconButton-root': {
            paddingTop: 0
        }
    },
    paymentProviderInformationItems: {
        fontSize: '80%',
        color: colors.textColorDisabled
    },
    paymentProviderInformationItem: {
        '&::after': {
            content: '" "'
        }
    },
    stripeStep: {
        paddingBottom: theme.spacing(3.125)
    }
}));

const PaymentElement = ({ children, name, paymentProviders, paymentProviderInformations, stripePaymentContext, changeIntended, onSelectedPaymentProvider, paymentSetupComplete}) => {
    const { appContext } = useAppContext();
    const classes = useStyles();
    const getName = suffix => suffix ? `${name}.${suffix}` : name;
    const getValue = suffix => useField(getName(suffix)).input.value;
    const getInitialValue = suffix => useField(getName(suffix)).meta.initial;

    const availablePaymentProviders = Object.values(allPaymentProviders)
        .filter(paymentProvider => paymentProviders.find(pp => pp === paymentProvider.key));

    const initialPaymentProvider = getInitialValue('paymentProvider');
    const selectedPaymentProvider = getValue('paymentProvider');

    const getPaymentProviderLabel = paymentProvider => {
        let line1;
        let updateInformation;
        const isUpdate = paymentProvider.key === initialPaymentProvider;
        if(!initialPaymentProvider || !changeIntended) {
            line1 = paymentProvider.title;
        } else if(isUpdate) {
            line1 = paymentProvider.updateTitle;
            updateInformation = paymentProvider.updateInformation;
        } else {
            line1 = paymentProvider.switchTitle;
        }
        const invoiceFee = getInvoiceFee(paymentProvider.key, appContext).amountIncludingVat;
        const paymentProviderInformation = paymentProviderInformations
            ? paymentProviderInformations[paymentProvider.key]
            : undefined;
        const items = [
            invoiceFee > 0 ? <>{strings.formatString(strings.invoiceFeeWillBeAdded, <Amount value={invoiceFee} currency={appContext.currency.code}/>)}</> : undefined,
            updateInformation,
            paymentProviderInformation ? <>{paymentProviderInformation}</> : undefined
        ].filter(o => o);
        return (
            <Box>
                <Box>{line1}</Box>
                {
                    items.length > 0 &&
                    (
                        <Box className={classes.paymentProviderInformationItems}>
                            {items.map((item, i) => <Box component="span" className={classes.paymentProviderInformationItem} key={i}>{item}</Box>)}
                        </Box>
                    )
                }
            </Box>
        );
    };

    const paymentProviderItems = availablePaymentProviders
        .map(paymentProvider => ({ label: getPaymentProviderLabel(paymentProvider), value: paymentProvider.key}));

    useEffect(() => {
        if (selectedPaymentProvider) {
            onSelectedPaymentProvider(selectedPaymentProvider);
        }
    }, [selectedPaymentProvider]);

    return (
        <>
            {
                !paymentSetupComplete && availablePaymentProviders.length > 1 &&
                (
                    <MarginBox bottom={4}>
                        <Typography variant="h6" gutterBottom>{strings.selectPaymentProvider}</Typography>
                        <Box className={classes.paymentProvidersContainer}>
                            <Radios
                                name={getName('paymentProvider')}
                                data={paymentProviderItems}
                                showError={showErrorOnBlur}
                                required
                                fieldProps={{ validate: required }}
                            />
                        </Box>
                    </MarginBox>
                )
            }
            {
                paymentSetupComplete && selectedPaymentProvider && children &&
                (
                    <MarginBox bottom={availablePaymentProviders.length > 1 ? 4 : 0}>
                        {children}
                    </MarginBox>
                )
            }
            {
                paymentSetupComplete && selectedPaymentProvider === allPaymentProviders.stripe.key &&
                (
                    <Box className={classes.stripeStep}>
                        <StripePaymentElement
                            name={getName('stripe')}
                            paymentContext={stripePaymentContext}
                        />
                    </Box>
                )
            }
            {
               paymentSetupComplete && selectedPaymentProvider === allPaymentProviders.billecta.key &&
                (
                    <BillectaPaymentElement
                        name={getName('billecta')}
                    />
                )
            }
        </>
    );
};

PaymentElement.propTypes = {
    children: PropTypes.node,
    name: PropTypes.string.isRequired,
    paymentProviders: PropTypes.array.isRequired,
    paymentProviderInformations: PropTypes.object,
    stripePaymentContext: PropTypes.object,
    stripeSetupContext: PropTypes.object,
    changeIntended: PropTypes.bool,
    paymentSetupComplete: PropTypes.bool,
    onSelectedPaymentProvider: PropTypes.func
};

export default PaymentElement;
