import React, { useEffect } from 'react';
import { useAppContext } from 'context/AppContext';
import strings from 'localization/strings';
import { userAgentIsBot } from 'helpers/ConfigurationHelper';

import { Helmet } from 'react-helmet-async';
import OpenGraphImage from 'common/OpenGraphImage';

const Head = () => {
    const { appContext } = useAppContext();

    useEffect(() => {
        if(!userAgentIsBot()) {
            window.dataLayer = window.dataLayer || [];
            window.gtag = window.dataLayer.push;

            const l = document.location;
            window.dataLayer.push({
                originalLocation: l.protocol + '//' + l.hostname + l.pathname + l.search
            });

            window.consentGranted = () => {
                window.dataLayer.push('consent', 'update', { 'ad_storage': 'granted' });
            };
            window.dataLayer.push('consent', 'default', { 'ad_storage': 'denied' });
        }
    }, []);

    return (
        <>
            <Helmet defer={false}>
                <link rel="apple-touch-icon" sizes="180x180" href={appContext.images.appleTouchIcon}/>
                <link rel="icon" type="image/png" sizes="32x32" href={appContext.images.favicon32x32}/>
                <link rel="mask-icon" href={appContext.images.maskIcon} color="#5bbad5"/>

                <link rel="manifest" href="/site.webmanifest" />
                <title>{strings.companyName}</title>
                <meta name="msapplication-TileColor" content={appContext.colors.primaryColor} />
                <meta name="theme-color" content={appContext.colors.primaryColor} />
            </Helmet>
            <OpenGraphImage url={appContext.images.topLogo}/>
        </>
    );
};

export default Head;
