import * as actionTypes from 'actions/localStorage';
import store from 'store2';

const defaults = {
    startPageCategory: undefined
};

const initialState = {};
Object.keys(defaults).forEach(key => {
    initialState[key] = store.get(key) || defaults[key];
});

const localStorage = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.LOCAL_STORAGE_SET:
            store.set(action.key, action.value);
            return { ...state, [action.key]: action.value };
        default:
            return state;
    }
};

export default localStorage;
