import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from 'styles/util';
import { sanitizePage } from './AdminTableHelper';

import Box from '@material-ui/core/Box';
import TableFooter from '@material-ui/core/TableFooter';
import TableRow from '@material-ui/core/TableRow';
import TablePagination from '@material-ui/core/TablePagination';
import IconButton from '@material-ui/core/IconButton';
import FirstPageIcon from '@material-ui/icons/FirstPage';
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight';
import LastPageIcon from '@material-ui/icons/LastPage';

const useStyles = makeStyles(({ theme }) => ({
    root: {
        flexShrink: 0,
        marginLeft: theme.spacing(2.5)
    }
}));

const Pagination = ({ count, page, pageSize, colSpan, onPageChange, onPageChangeSize }) => {
    const classes = useStyles();
    const sanitizedPage = sanitizePage(page, pageSize, count);

    const handleFirstPageButtonClick = () => {
        onPageChange(0);
    };

    const handleBackButtonClick = () => {
        onPageChange(sanitizedPage - 1);
    };

    const handleNextButtonClick = () => {
        onPageChange(sanitizedPage + 1);
    };

    const handleLastPageButtonClick = () => {
        onPageChange(Math.max(0, Math.ceil(count / pageSize) - 1));
    };

    const handlePageChange = (event, newPage) => {
        onPageChange(newPage);
    };

    const handlePageChangeSize = event => {
        onPageChangeSize(parseInt(event.target.value, 10));
    };

    const actionsComponent = () => (
        <Box className={classes.root}>
            <IconButton
                onClick={handleFirstPageButtonClick}
                disabled={sanitizedPage === 0}
                aria-label="first page"
            >
                <FirstPageIcon />
            </IconButton>
            <IconButton
                onClick={handleBackButtonClick}
                disabled={sanitizedPage === 0}
                aria-label="previous page"
            >
                <KeyboardArrowLeft />
            </IconButton>
            <IconButton
                onClick={handleNextButtonClick}
                disabled={sanitizedPage >= Math.ceil(count / pageSize) - 1}
                aria-label="next page"
            >
                <KeyboardArrowRight />
            </IconButton>
            <IconButton
                onClick={handleLastPageButtonClick}
                disabled={sanitizedPage >= Math.ceil(count / pageSize) - 1}
                aria-label="last page"
            >
                <LastPageIcon />
            </IconButton>
        </Box>
    );

    return (
        <TableFooter>
            <TableRow>
                <TablePagination
                    rowsPerPageOptions={[20, 50, 100, 200, 500]}
                    colSpan={colSpan}
                    count={count}
                    rowsPerPage={pageSize}
                    page={sanitizedPage}
                    SelectProps={{
                        inputProps: { 'aria-label': 'rows per page' },
                        native: true
                    }}
                    onPageChange={handlePageChange}
                    onRowsPerPageChange={handlePageChangeSize}
                    ActionsComponent={actionsComponent}
                />
            </TableRow>
        </TableFooter>
    );
};

Pagination.propTypes = {
    count: PropTypes.number.isRequired,
    page: PropTypes.number.isRequired,
    pageSize: PropTypes.number.isRequired,
    colSpan: PropTypes.number,
    onPageChange: PropTypes.func.isRequired,
    onPageChangeSize: PropTypes.func.isRequired
};

export default Pagination;
