import React from 'react';
import PropTypes from 'prop-types';
import strings from 'localization/strings';
import { makeStyles } from 'styles/util';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { email } from 'form/validation';

import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import { FieldArray } from 'react-final-form-arrays';
import { TextField, Checkboxes, showErrorOnBlur } from 'mui-rff';

const useStyles = makeStyles(({ theme }) => ({
    container: {
        marginBottom: theme.spacing(4)
    },
    userConnectionContainer: {
        display: 'flex',
        flexWrap: 'wrap',
        alignItems: 'center'
    },
    name: {
        flex: '1 1 37.5%',
        paddingRight: theme.spacing(1)
    },
    email: {
        flex: '1 1 37.5%',
        paddingRight: theme.spacing(1)
    },
    remove: {
        flex: '1 1 25%',
        textAlign: 'right'
    },
    addInvitationButton: {
        marginTop: theme.spacing(2)
    },
    invitedUserName: {
        fontStyle: 'italic'
    }
}));

const UserConnectionsEditor = ({ name }) => {
    const classes = useStyles();
    const buttonFullWidth = !useMediaQuery(theme => theme.breakpoints.up('md'));

    const handleAddInvitation = fields => {
        const newUserConnection = {
            type: 'userInvitation',
            id: undefined,
            firstName: undefined,
            lastName: undefined,
            email: undefined,
            invite: true,
            remove: false
        };
        fields.push(newUserConnection);
    };

    const renderUser = (item, itemName) => (
        <Box className={classes.userConnectionContainer}>
            <Box className={classes.name}>
                {item.firstName} {item.lastName}
            </Box>
            <Box className={classes.email}>
                {item.email}
            </Box>
            <Box className={classes.remove}>
                <Checkboxes
                    name={`${itemName}.remove`}
                    data={{
                        label: strings.remove,
                        value: true
                    }}
                />
            </Box>
        </Box>
    );

    const renderUserInvitation = (item, itemName) => (
        <Box className={classes.userConnectionContainer}>
            <Box className={`${classes.name} ${classes.invitedUserName}`}>
                { item.invite ? strings.newInvitedUser : strings.pendingInvitedUser }
            </Box>
            <Box className={classes.email}>
                {
                    item.invite &&
                    (
                        <TextField
                        name={`${itemName}.email`}
                            label={strings.userInvitationEmail}
                            variant="outlined"
                            showError={showErrorOnBlur}
                            required
                            fieldProps={{ validate: email }}
                        />
                    )
                }
                {
                    !item.invite && (
                        <>{item.email}</>
                    )
                }
            </Box>
            <Box className={classes.remove}>
                <Checkboxes
                    name={`${itemName}.remove`}
                    data={{
                        label: strings.remove,
                        value: true
                    }}
                />
            </Box>
        </Box>
    );

    return (
        <Box className={classes.container}>
            <FieldArray name={name}>
                {({ fields }) => (
                    <>
                        {
                            fields.map((itemName, index) => {
                                const item = fields.value[index];
                                return (
                                    <React.Fragment key={itemName}>
                                        { item.type === 'user' && renderUser(item, itemName) }
                                        { item.type === 'userInvitation' && renderUserInvitation(item, itemName) }
                                    </React.Fragment>
                                );
                            })
                        }
                        <Button
                            className={classes.addInvitationButton}
                            variant="outlined"
                            color="primary"
                            onClick={() => handleAddInvitation(fields)}
                            fullWidth={buttonFullWidth}
                        >
                            {strings.addUserInvitation}
                        </Button>
                    </>
                )}
            </FieldArray>
        </Box>
    );
};

UserConnectionsEditor.propTypes = {
    name: PropTypes.string.isRequired
};

export default UserConnectionsEditor;
