const environments = {
    local: {},
    test: {},
    production: {}
};

Object.keys(environments).forEach(key => {
    environments[key].key = key;
});

export default environments;
