import React from 'react';
import PropTypes from 'prop-types';
import strings from 'localization/strings';
import Typography from '@material-ui/core/Typography';
import PriceSpecificationTableNew from 'common/PriceSpecification/PriceSpecificationTableNew';

const InitialPaymentPriceSpecification = ({ booking, paymentProvider }) => {
    if(!paymentProvider) {
        return null;
    }

    const { initialInvoice, firstSubscriptionInvoice } = booking.paymentProviderInvoiceInfos.find(o => o.paymentProvider === paymentProvider);

    const showVat =
        initialInvoice.priceInfo.purchaseItems.filter(o => o.vat).length > 0 ||
        firstSubscriptionInvoice?.priceInfo.purchaseItems.filter(o => o.vat).length > 0;

    return (
        <>
            <Typography variant="h5" gutterBottom>{strings.specification}</Typography>
            <PriceSpecificationTableNew booking={booking} purchaseItems={initialInvoice.priceInfo.purchaseItems} showVat={showVat} />
        </>
    );
};

InitialPaymentPriceSpecification.propTypes = {
    booking: PropTypes.object.isRequired,
    paymentProvider: PropTypes.string
};

export default InitialPaymentPriceSpecification;
