import React from 'react';
import PropTypes from 'prop-types';
import { useForm } from 'react-final-form';
import multiplicities from 'enums/multiplicities';

import DisplayStep1Single from './DisplayStep1Single';
import DisplayStep1Multiple from './DisplayStep1Multiple';

const DisplayStep1 = ({ storageSiteId }) => {
    const formValues = useForm().getState().values;
    return (
        <>
            {
                formValues.steps[0].multiplicity === multiplicities.single &&
                (
                    <DisplayStep1Single/>
                )
            }
            {
                formValues.steps[0].multiplicity === multiplicities.multiple &&
                (
                    <DisplayStep1Multiple storageSiteId={storageSiteId} />
                )
            }
        </>
    );
};

DisplayStep1.propTypes = {
    storageSiteId: PropTypes.number.isRequired
};

export default DisplayStep1;
