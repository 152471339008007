import React from 'react';
import PropTypes from 'prop-types';
import { useAppContext } from 'context/AppContext';
import strings from 'localization/strings';
import { formatDateAndTimeSpan } from 'helpers/OpeningTimeSpanHelper';

import { TextField, showErrorOnBlur } from 'mui-rff';
import MenuItem from '@material-ui/core/MenuItem';
import Alert from '@material-ui/lab/Alert';

const DateCandidateSelector = ({ availableDateCandidates, name, label }) => {
    const { appContext } = useAppContext();

    return (
        <>
            {
                availableDateCandidates.length === 0 &&
                (
                    <Alert severity="error">
                        {strings.noAvailableDates}
                    </Alert>
                )
            }
            {
                availableDateCandidates.length > 0 &&
                (
                    <TextField
                        select
                        name={name}
                        label={label}
                        variant="outlined"
                        showError={showErrorOnBlur}
                        required
                    >
                        {
                            availableDateCandidates
                                .map(candidate => (
                                    <MenuItem key={candidate.date} value={candidate.date}>
                                        {formatDateAndTimeSpan(candidate.date, candidate, appContext)}
                                    </MenuItem>
                                ))
                        }
                    </TextField>
                )
            }
        </>
    );
};

DateCandidateSelector.propTypes = {
    availableDateCandidates: PropTypes.array.isRequired,
    name: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired
};

export default DateCandidateSelector;
