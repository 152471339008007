export const STORE_BOOKING_REQUEST_VALUES = 'STORE_BOOKING_REQUEST_VALUES';
export const STORE_BOOKING_REQUEST_VALUES_AT_LOGIN_REDIRECT = 'STORE_BOOKING_REQUEST_VALUES_AT_LOGIN_REDIRECT';

export const storeBookingRequestValues = values => ({
    type: STORE_BOOKING_REQUEST_VALUES,
    formValues: values
});

export const storeBookingRequestValuesAtLoginRedirect = values => ({
    type: STORE_BOOKING_REQUEST_VALUES_AT_LOGIN_REDIRECT,
    formValuesAtLoginRedirect: values
});

