import React, { useEffect, useState } from 'react';
import { useDispatch} from 'react-redux';
import { useLocation } from 'react-router-dom';
import { useAppContext } from 'context/AppContext';
import strings from 'localization/strings';
import { makeStyles } from 'styles/util';
import { handleResponse } from 'actions/actionHelpers';
import { createTableItems, createTableColumns, createTableFooterCells } from 'helpers/ReportHelper';
import queryString from 'query-string';
import reportDefinitions from 'account/reportDefinitions';

import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import Loader from 'common/Loader';
import AdminTable from 'components/adminTable/AdminTable';

// used by backend to generate reports that are sent as attachments to emails

const useStyles = makeStyles(() => ({
    reportTable: {
        zoom: 0.67,
        '-webkit-print-color-adjust': 'exact !important',
        printColorAdjust: 'exact !important'
    }
}));

const ReportPage = () => {
    const classes = useStyles();
    const location = useLocation();
    const dispatch = useDispatch();
    const parameters = queryString.parse(location.search);
    const { appContext } = useAppContext();

    const [reportData, setReportData] = useState();
    const [isLoading, setIsLoading] = useState(true);

    const reportDefinition = reportDefinitions[parameters.report];

    useEffect(() => {
        // populate data
        const request = {
            inputFields: {
                [parameters.report]: {
                    ...parameters,
                    report: undefined
                }
            }
        };
        dispatch(reportDefinition.fetchData(request))
            .then(handleResponse(
                response => {
                    setReportData(response.payload);
                    setIsLoading(false);
                }
            ));
    }, []);

    return (
        <Box>
            {
                isLoading && <Loader />
            }
            {
                reportData &&
                (
                    <>
                        {
                            parameters.title && <Typography variant="h1" gutterBottom>{parameters.title}</Typography>
                        }
                        <AdminTable
                            className={classes.reportTable}
                            items={createTableItems(reportData)}
                            columns={createTableColumns(reportData, reportDefinition, appContext)}
                            footerCells={createTableFooterCells(reportData, reportDefinition, appContext)}
                            noItemsMessage={strings.noItemsMessage}
                        />
                    </>
                )
            }
        </Box>
    );
};

export default ReportPage;
