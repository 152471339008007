const vatModes = {
    eligibleForVat: {},
    notEligibleForVat: {},
    businessesOnlyEligibleForVat: {}
};
Object.keys(vatModes).forEach(key => {
    vatModes[key].key = key;
});

export default vatModes;
