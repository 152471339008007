import React from 'react';
import PropTypes from 'prop-types';
import strings from 'localization/strings';
import { makeStyles } from 'styles/util';
import { differenceInCalendarDays } from 'date-fns';
import { parseIsoDate } from 'helpers/DateHelper';
import { getBusinessOrPrivateName } from 'helpers/ActorHelper';

import Box from '@material-ui/core/Box';
import ShortDate from 'common/ShortDate';

const useStyles = makeStyles(({ colors }) => ({
    topContainer: {
        display: 'flex',
        margin: '1em 0'
    },
    informationBox: {
        flex: '1 1 60%',
        marginRight: '1em'

    },
    borderBox: {
        border: `3px solid ${colors.black}`,
        borderRadius: '0.5em',
        padding: '1em'
    },
    informationList: {
        display: 'table'
    },
    informationListRow: {
        display: 'table-row'

    },
    informationListLabel: {
        display: 'table-cell',
        fontWeight: 'bold',
        padding: '0.15em 2em 0.15em 0',
    },
    informationListValue: {
        display: 'table-cell',
        padding: '0.15em 0 0.15em 0'
    },
    buyerAndSellerBox: {
        flex: '1 1 40%',
    },
    caption: {
        color: colors.white,
        fontWeight: 'bold',
        backgroundColor: colors.primaryColor,
        borderRadius: '0.25em',
        padding: '0.5em',
        marginBottom: '0.75em'
    },
    name: {
        marginLeft: '0.25em',
        marginBottom: '1.5em'
    }
}));

const PostInvoiceTopContainer = ({ purchase }) => {
    const classes = useStyles();

    return (
        <Box className={classes.topContainer}>
            <Box className={classes.informationBox + ' ' + classes.borderBox}>

                <Box className={classes.informationList}>
                    <Box className={classes.informationListRow}>
                        <Box className={classes.informationListLabel}>{strings.invoiceNumber}</Box>
                        <Box className={classes.informationListValue}>{purchase.id === 0 ? '-' :  purchase.id}</Box>
                    </Box>
                    <Box className={classes.informationListRow}>
                        <Box className={classes.informationListLabel}>{strings.paymentDocuments.customerNumber}</Box>
                        <Box className={classes.informationListValue}>{purchase.booking.tenantActor.id}</Box>
                    </Box>
                    <Box className={classes.informationListRow}>
                        <Box className={classes.informationListLabel}>{strings.invoiceDate}</Box>
                        <Box className={classes.informationListValue}><ShortDate value={purchase.createdTime}/></Box>
                    </Box>
                    <Box className={classes.informationListRow}>
                        <Box className={classes.informationListLabel}>{strings.paymentDocuments.paymentCondition}</Box>
                        <Box className={classes.informationListValue}>{strings.formatString(strings.paymentDocuments.xDaysNet, differenceInCalendarDays(parseIsoDate(purchase.dueDate), parseIsoDate(purchase.createdTime)))}</Box>
                    </Box>
                    <Box className={classes.informationListRow}>
                        <Box className={classes.informationListLabel}>{strings.dueDate}</Box>
                        <Box className={classes.informationListValue}><ShortDate value={purchase.dueDate}/></Box>
                    </Box>
                </Box>

            </Box>

            <Box className={classes.buyerAndSellerBox + ' ' + classes.borderBox}>
                <Box className={classes.caption}>{strings.paymentDocuments.buyer}</Box>
                <Box className={classes.name}>
                    {getBusinessOrPrivateName(purchase.booking.tenantActor)}
                </Box>

                <Box className={classes.caption}>{strings.paymentDocuments.seller}</Box>
                <Box className={classes.name}>
                    {getBusinessOrPrivateName(purchase.booking.ownerActor)}
                </Box>
            </Box>
        </Box>

    );
};

PostInvoiceTopContainer.propTypes = {
    purchase: PropTypes.object.isRequired
};

export default PostInvoiceTopContainer;
