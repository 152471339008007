import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from 'styles/util';

import { TextField } from 'mui-rff';
import MenuItem from '@material-ui/core/MenuItem';
import KeyboardArrowDown from '@material-ui/icons/KeyboardArrowDown';

const useStyles = makeStyles(({ theme, colors, styles }) => ({
    orderBy: {
        display: 'flex',
        alignItems: 'center',
        gap: theme.spacing(2)
    },
    textField: {
        margin: theme.spacing(0),
        padding: theme.spacing(0.5, 2),
        borderRadius: `${styles.borderRadius}px`,
        border: 'none',
        backgroundColor: colors.lightButtonColor,
        '&:hover': {
            backgroundColor: colors.lightButtonHoverColor
        },
        '& .MuiInput-underline:before': {
            borderBottom: 'none !important'
        },
        '& .MuiInput-underline:after': {
            borderBottom: 'none !important'
        },
        '& .MuiSelect-select:focus': {
            backgroundColor: 'transparent'
        }
    }
}));

const DropdownButton = ({ name, items, onChange }) => {
    const classes = useStyles();
    return (
        <TextField
            select
            name={name}
            variant="standard"
            SelectProps={{ IconComponent: KeyboardArrowDown }}
            className={classes.textField}
            onChange={onChange}
        >
            {
                items.map(item => (
                    <MenuItem key={item.value} value={item.value}>
                        {item.title}
                    </MenuItem>
                ))
            }
        </TextField>
    );
};

DropdownButton.propTypes = {
    items: PropTypes.array.isRequired,
    name: PropTypes.string.isRequired,
    onChange: PropTypes.func
};

export default DropdownButton;
