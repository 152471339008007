import React, { useState, useEffect } from 'react';
import strings from 'localization/strings';
import { useDispatch, useSelector } from 'react-redux';
import { closeSwitchActorDialog } from 'actions/responses';
import { useHistory } from 'react-router-dom';
import { getActor } from 'actions/admin/actors';
import { logIn } from 'actions/authentication';
import { handleResponse } from 'actions/actionHelpers';
import routes from 'routes';

import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import Loader from 'common/Loader';

const SwitchActorDialog = () => {
    const dispatch = useDispatch();
    const history = useHistory();

    const open = useSelector(state => state.responses.switchActorDialogOpen);
    const dialogData = useSelector(state => state.responses.switchActorDialogData) || {}; // { actorId }

    const [actor, setActor] = useState(undefined);
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        if(open) {
            setIsLoading(true);
            dispatch(getActor(dialogData.actorId))
                .then(handleResponse(
                    response => {
                        setActor(response.payload);
                        setIsLoading(false);
                    }
                ));
        }
    }, [open]);

    const handleOk = () => {
        setIsLoading(true);
        dispatch(logIn({ impersonationActorId: actor.id }))
            .then(handleResponse(
                () => {
                    dispatch(closeSwitchActorDialog());
                    setIsLoading(false);
                    history.push(history.location.pathname + history.location.search);
                },
                () => {
                    dispatch(closeSwitchActorDialog());
                    setIsLoading(false);
                }
            ));

        history.push();
    };

    const handleCancel = () => {
        dispatch(closeSwitchActorDialog());
        history.push(routes.index);
    };

    return (
        <Dialog open={open} onClose={handleCancel}>
            <DialogTitle disableTypography>
                <Typography variant="h5">
                    {strings.switchActorDialogTitle}
                </Typography>
            </DialogTitle>
            <DialogContent>
            {
                isLoading && <Loader />
            }
            {
                actor &&
                (
                    <Typography variant="body1">
                        {strings.formatString(strings.switchActorDialogBody, actor.name)}
                    </Typography>
                )
            }
            </DialogContent>
            <DialogActions>
                {
                    actor &&
                    (
                        <Button
                            type="button"
                            variant="contained"
                            color="primary"
                            onClick={handleOk}
                        >
                            {strings.formatString(strings.switchActorToX, actor.name)}
                        </Button>
                    )
                }
                <Button
                    type="button"
                    variant="outlined"
                    color="primary"
                    onClick={handleCancel}
                >
                    {strings.cancel}
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default SwitchActorDialog;
