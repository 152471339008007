import strings from 'localization/strings';
import actorTypes from 'enums/actorTypes';

const cancellationReasons = {
    foundAnotherStorage365Storage: {
        availableFor: [ actorTypes.tenant ]
    },
    foundAnotherExternalStorage: {
        availableFor: [ actorTypes.tenant ]
    },
    missingFeature: {
        availableFor: [ actorTypes.tenant ]
    },
    tooExpensive: {
        availableFor: [ actorTypes.tenant ]
    },
    canceledAfterShowing: {
        availableFor: [ actorTypes.tenant ]
    },
    unsuitableGoods: {
        availableFor: [ actorTypes.owner ]
    },
    lackOfAvailability: {
        availableFor: [ actorTypes.owner ]
    },
    wantedToContactOwnerDirectly: {
        availableFor: []
    },
    dontWantToTell: {
        availableFor: [ actorTypes.tenant, actorTypes.owner ]
    },
    other: {
        availableFor: [ actorTypes.tenant, actorTypes.owner ]
    },
    refunded: {
        availableFor: []
    }
};

Object.keys(cancellationReasons).forEach(key => {
    cancellationReasons[key].key = key;
});

strings.addLoadCallback(() => {
    Object.keys(cancellationReasons).forEach(key => {
        cancellationReasons[key].title = strings.cancellationReasons[key];
    });
});

export default cancellationReasons;
