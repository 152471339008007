import * as actionTypes from 'actions/bookingRequest';
import store from 'store2';
import storageGroupCategories from 'enums/storageGroupCategories';
import { convertToNumber } from 'helpers/StringHelper';
import { formatIsoDate, parseIsoDate } from 'helpers/DateHelper';
import { sanitizeBookingRequestFormValues } from 'helpers/SearchHelper';

const bookingRequestFormValuesKey = 'brFormValues';
const bookingRequestFormAtLoginRedirectValuesKey = 'bookingRequestFormAtLoginRedirect';

const bookingRequestFormValues = store.get(bookingRequestFormValuesKey);
let initialFormValues = {};
if (bookingRequestFormValues) {
    initialFormValues = {
        ...bookingRequestFormValues,
        requestedStartDate: bookingRequestFormValues.requestedStartDate ? parseIsoDate(bookingRequestFormValues.requestedStartDate) : undefined,
        requestedEndDate: bookingRequestFormValues.requestedEndDate ? parseIsoDate(bookingRequestFormValues.requestedEndDate) : undefined
    };
}

initialFormValues = sanitizeBookingRequestFormValues(initialFormValues);

const initialState = {
    formValues: initialFormValues,
    formValuesAtLoginRedirect: store.get(bookingRequestFormAtLoginRedirectValuesKey)
};
if(initialState.formValuesAtLoginRedirect) {
    initialState.formValuesAtLoginRedirect.startDate = parseIsoDate(bookingRequestFormValues.startDate);
    initialState.formValuesAtLoginRedirect.endDate = parseIsoDate(bookingRequestFormValues.endDate);
}

const bookingRequestReducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.STORE_BOOKING_REQUEST_VALUES:
            // store dates and length+width for all categories
            const localStorageValues = {
                requestedStartDate: action.formValues.requestedStartDate
                    ? formatIsoDate(action.formValues.requestedStartDate)
                    : undefined,
                    requestedEndDate: action.formValues.requestedEndDate
                    ? formatIsoDate(action.formValues.requestedEndDate)
                    : undefined
            };
            Object.values(storageGroupCategories).forEach(o => {
                const widthKey = `categories.${o.key}.width`;
                const lengthKey = `categories.${o.key}.length`;
                localStorageValues[widthKey] = convertToNumber(action.formValues[widthKey]);
                localStorageValues[lengthKey] = convertToNumber(action.formValues[lengthKey]);
            });
            store.set(bookingRequestFormValuesKey, localStorageValues);

            return {...state, formValues: action.formValues};

        case actionTypes.STORE_BOOKING_REQUEST_VALUES_AT_LOGIN_REDIRECT:
            store.set(bookingRequestFormAtLoginRedirectValuesKey, action.formValuesAtLoginRedirect);
            return {...state, formValuesAtLoginRedirect: action.formValuesAtLoginRedirect};

        default:
            return state;
    }
};

export default bookingRequestReducer;
