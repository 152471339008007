import React from 'react';
import { makeStyles } from 'styles/util';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import Text from 'common/Text';
import PropTypes from 'prop-types';

const OwnerSalesInfo = ({title, text, imgSource, reverse = false }) => {
    const useStyles = makeStyles(({ theme, colors }) => ({
        ownerSalesInfoContainer: {
            display: 'flex',
            flexDirection: reverse ? 'row-reverse' : 'row',
            gap: theme.spacing(6),
            '& > *': {
                flex: '1 1 0',
            },
            '& img': {
                height: '100%',
                width: '100%'
            },
            [theme.breakpoints.down('sm')]: {
                gap: theme.spacing(0),
                flexDirection: 'column-reverse',
            }
        },
        textContainer: {
            display: 'flex',
            flexDirection: 'column',
            margin: 'auto',
            '& h2': {
                margin: theme.spacing(3, 0, 0),
                fontSize: theme.spacing(5),
                color: colors.black,
                fontStyle: 'normal',
                fontWeight: 'bold',
                lineHeight: '120%',
                letterSpacing: '-0.01em',
                [theme.breakpoints.down('sm')]: {
                    fontSize: theme.spacing(4),
                }
            },
            '& p': {
                margin: theme.spacing(3, 0),
                fontSize: theme.spacing(2.25),
                color: colors.black,
                fontStyle: 'normal',
                fontWeight: 'normal',
                lineHeight: '160%',
                letterSpacing: '-0.01em',
            },
        }
    }));
    const classes = useStyles();

    return (
        <Box className={classes.ownerSalesInfoContainer}>
            <Box className={classes.textContainer}>
                <Typography variant="h2" display="block" gutterBottom>{title}</Typography>
                <Typography variant="body1" display="block">
                    <Text text={text}/>
                </Typography>
            </Box>
            <Box>
                <img src={imgSource} />
            </Box>
        </Box>
    );
};

OwnerSalesInfo.propTypes = {
    title: PropTypes.string,
    text: PropTypes.string,
    imgSource: PropTypes.node,
    reverse: PropTypes.bool
};

export default OwnerSalesInfo;
