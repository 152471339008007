import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from 'styles/util';
import { useForm, useField } from 'react-final-form';

import { Field } from 'react-final-form';
import RadioGroup from '@material-ui/core/RadioGroup';
import Radio from '@material-ui/core/Radio';

const useStyles = makeStyles(() => ({
    itemContainer: {
        display: 'block',
        cursor: 'pointer'
    }
}));

const CustomRadioButtonList = ({ name, options, validate, renderer }) => {
    const classes = useStyles();
    const form = useForm();
    const field = useField(name);
    const value = field.input.value;

    return (
        <Field name={name} value={value} type="radio" validate={validate}>
            {
                ({ input }) => (
                    <RadioGroup {...input}>
                        {
                            options.map((option, i) => {
                                // for some reason, we need to trigger an onChange on the radio button itself when the value changes
                                // otherwise, the validation will not be executed at the first change if the initial value doesn't match any option (e.g. undefined)
                                const onChange = () => {
                                    if( option.value !== value) {
                                        form.mutators.changeValue({ name, value: option.value });
                                    }
                                };

                                return (
                                    <label key={i} className={classes.itemContainer}>
                                        { renderer(<Radio name={input.name} value={option.value} onChange={onChange} />, option) }
                                    </label>
                                );
                            })
                        }
                    </RadioGroup>
                )
            }
        </Field>
    );
};

CustomRadioButtonList.propTypes = {
    name: PropTypes.string.isRequired,
    options: PropTypes.array.isRequired,
    validate: PropTypes.func,
    renderer: PropTypes.func.isRequired
};

export default CustomRadioButtonList;
