import { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';
import { handleResponse } from 'actions/actionHelpers';
import { useDispatch, useSelector } from 'react-redux';
import routes from 'routes';
import { logOut, impersonationLogOut } from 'actions/authentication';

const LogoutPage = ({ to }) => {
    const dispatch = useDispatch();
    const history = useHistory();
    const authenticationContext = useSelector(state => state.authentication.context);

    useEffect(() => {
        if(authenticationContext.isAuthenticated) {
            if(authenticationContext.isImpersonated) {
                dispatch(impersonationLogOut())
                    .then(handleResponse(
                        () => {
                            history.push(routes.admin.index);
                        }
                    ));
            } else {
                dispatch(logOut());
                redirect();
            }
        } else {
            redirect();
        }
    }, []);

    const redirect = () => history.push(to ?? routes.logIn);

    return null;
};

LogoutPage.propTypes = {
    to: PropTypes.string
};

export default LogoutPage;
