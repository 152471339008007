import React from 'react';
import Box from '@material-ui/core/Box';
import Form from 'form/Form';
import SizeSelector from 'components/storageSearchForm/SizeSelector';
import AdditionalFiltersSelector from 'components/storageSearchForm/AdditionalFiltersSelector';
import Popover from '@material-ui/core/Popover';
import PropTypes from 'prop-types';
import MaxPricePerAreaUnitSelector from 'components/storageSearchForm/MaxPriceSelectors';

const FilterPopovers = ({filterType, anchorEl, open, onClose, onSubmit, initialValues, landingPage}) => {
    return (
        <Popover
            anchorEl={anchorEl}
            aria-labelledby="simple-modal-title"
            aria-describedby="simple-modal-description"
            open={open}
            onClose={() => onClose()}
            anchorOrigin={{
                vertical: 'top',
                horizontal: 'right',
            }}
            transformOrigin={{
                vertical: 'top',
                horizontal: 'left',
            }}
        >
            <Box>
                <Form
                    onSubmit={onSubmit}
                    initialValues={initialValues}
                >
                    {({handleSubmit}) => (
                        <form onSubmit={handleSubmit}>
                            <SizeSelector show={filterType === 'size'} />
                            <MaxPricePerAreaUnitSelector show={filterType === 'price'} />
                            <AdditionalFiltersSelector show={filterType === 'additionalFilters'} landingPage={landingPage}/>
                        </form>
                    )}
                </Form>
            </Box>
        </Popover>
    );
};

FilterPopovers.propTypes = {
    filterType: PropTypes.string,
    anchorEl: PropTypes.object,
    open: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    onSubmit: PropTypes.func.isRequired,
    initialValues: PropTypes.object.isRequired,
    landingPage: PropTypes.object.isRequired,
};

export default FilterPopovers;
