import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from 'styles/util';
import { useHistory } from 'react-router-dom';
import { useAppContext } from 'context/AppContext';
import strings from 'localization/strings';
import { Link } from 'react-router-dom';

import Box from '@material-ui/core/Box';
import IconButton from '@material-ui/core/IconButton';
import Drawer from '@material-ui/core/Drawer';
import List from '@material-ui/core/List';
import Hidden from '@material-ui/core/Hidden';
import { ListItem, ListItemText } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import ContentBox from 'common/ContentBox';
import AccountNavigationCompact from 'account/AccountNavigationCompact';
import IconMenu from 'common/IconMenu';

const useStyles = makeStyles(({ theme, colors, styles }) => ({
    mobileMenuPaper: {
        width: '100%',
        display: 'block',
        flex: 'unset',
        backgroundColor: colors.lightBackgroundColor ?? colors.white,
    },
    menuContainer: {
        padding: theme.spacing(5.625, 6.25),
        display: 'flex',
        justifyContent: 'center'
    },
    fullwidth: {
        width: '100%'
    },
    mobileContainer: {
        flex: '1 0 auto',
        textAlign: 'right',
    },
    menuItem: {
        color: colors.secondaryColor,
        cursor: 'pointer',
        '&:first-child': {
            paddingTop: 0
        },
        '&:last-child': {
            paddingBottom: 0
        },
        '& > *:first-child': {
            margin: 0
        },
        '& span': {
            fontSize: theme.typography.bodyMedium.fontSize,
            display: 'inline-block',
            position: 'relative'
        },
        '& span::after': {
            content: '""',
            position: 'absolute',
            bottom: '-3px',
            left: 0,
            width: 0,
            height: '2px',
            backgroundColor: colors.primaryColor,
            transition: `width ${styles.transitionEffect}`
        },
        '&:hover span::after, &.active span::after': {
            width: '100%'
        },
        '&:hover span, &.active span': {
            fontFamily: theme.overrides.MuiTypography.h3.fontFamily
        }
    },
    menuIcon: {
        color: colors.primaryColor
    },
    headerLogoMobile: {
        maxWidth: '191px'
    },
    menuHeader: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        '& a': {
            display: 'flex',
            alignItems: 'center'
        }
    },
    closeMenu: {
        background: 'white',
        borderRadius: '50%',
        minWidth: 'unset',
        padding: '0',
        '& svg': {
            transition: `fill ${styles.transitionEffect}`,
            fill: colors.primaryColor
        },
        '&:hover': {
            backgroundColor: colors.primaryColor,
            '& svg': {
                fill: colors.white
            }
        }
    },
    mobileMenuButton: {
        width: '100%',
        padding: '10.5px 0'
    }
}));

const StorageMobileNavigation = ({ links }) => {
    const { appContext } = useAppContext();
    const classes = useStyles();
    const history = useHistory();
    const [open, setOpen] = useState(false);

    const handleToggle = () => {
        setOpen(!open);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const handleLinkClick = link => {
        if (link.onClick) {
            link.onClick();
        } else {
            history.push(link.url);
        }
        handleClose();
    };

    return (
        <Hidden mdUp>
            <Box className={classes.mobileContainer}>
                <IconButton className={classes.menuIcon} aria-label="menu" onClick={handleToggle}>
                    <IconMenu fill={appContext.colors.primaryColor} />
                </IconButton>
                <Drawer
                    anchor="right"
                    open={open}
                    onClose={handleClose}
                    PaperProps={{className: classes.mobileMenuPaper}}
                >
                    <Box className={classes.menuContainer}>
                        <ContentBox className={classes.fullwidth} themeMaxWidth="xxs" widgetSpacing="30px">
                            <Box className={classes.menuHeader}>
                                <Link to="/" onClick={() => handleLinkClick({url: '/'})}>
                                    <img className={classes.headerLogoMobile} src={appContext.images.headerLogo} alt={strings.companyName}/>
                                </Link>
                                <Button className={classes.closeMenu} onClick={handleClose} type="button" aria-label={strings.closeIcon}>
                                    <svg width="40" height="40" viewBox="0 0 40 40" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M12.9596 25.6875C12.5691 26.078 12.5691 26.7111 12.9596 27.1017C13.3502 27.4922 13.9833 27.4922 14.3739 27.1017L20.0712 21.4043L25.7686 27.1017C26.1592 27.4922 26.7923 27.4922 27.1828 27.1017C27.5734 26.7112 27.5734 26.078 27.1828 25.6875L21.4854 19.9901L27.1018 14.3737C27.4923 13.9832 27.4923 13.3501 27.1018 12.9595C26.7113 12.569 26.0781 12.569 25.6876 12.9595L20.0712 18.5759L14.4549 12.9596C14.0644 12.569 13.4312 12.569 13.0407 12.9596C12.6502 13.3501 12.6502 13.9833 13.0407 14.3738L18.657 19.9901L12.9596 25.6875Z"/>
                                    </svg>
                                </Button>
                            </Box>
                            <Box>
                                <List disablePadding>
                                    {links && links.filter(link => link.visible).map(link => (
                                        <ListItem
                                            key={link.url || link.text}
                                            className={`${!link.isButton ? classes.menuItem : ''} ${link.selected ? 'active' : ''}`}
                                            onClick={() => handleLinkClick(link)}
                                            disableGutters
                                        >
                                            {link.isButton ? (
                                                <Button component={Link} to={link.url} className={classes.mobileMenuButton} variant="contained" color="primary">
                                                    {link.text}
                                                </Button>
                                                ) :
                                                <ListItemText>{link.text}</ListItemText>
                                            }
                                        </ListItem>
                                    ))}
                                </List>
                            </Box>
                            <AccountNavigationCompact handleLinkClick={handleLinkClick} />
                        </ContentBox>
                    </Box>
                </Drawer>
            </Box>
        </Hidden>
    );
};

StorageMobileNavigation.propTypes = {
    links: PropTypes.array
};

export default StorageMobileNavigation;
