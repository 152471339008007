import React from 'react';
import PropTypes from 'prop-types';
import strings from 'localization/strings';
import { makeStyles } from 'styles/util';
import organizationTypes from 'enums/organizationTypes';

import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import TwoButtons from 'common/TwoButtons';

import privateIcon from 'assets/images/private-white.svg';
import businessIcon from 'assets/images/business-white.svg';

const useStyles = makeStyles(({ theme }) => ({
    container: {
        display: 'flex',
        flexDirection: 'column',
        gap: theme.spacing(3.75)
    }
}));

const OrganizationTypeSelector = ({ onClick }) => {
    const classes = useStyles();
    return (
        <Box className={classes.container}>
            <Typography variant="body1">
                {strings.selectOrganizationTypeDescription}
            </Typography>
            <TwoButtons
                first={{
                    text: organizationTypes.private.title,
                    icon: privateIcon,
                    onClick: () => onClick(organizationTypes.private.key)
                }}
                second={{
                    text: organizationTypes.business.title,
                    icon: businessIcon,
                    onClick: () => onClick(organizationTypes.business.key)
                }}
            />
        </Box>
    );
};

OrganizationTypeSelector.propTypes = {
    onClick: PropTypes.func.isRequired
};

export default OrganizationTypeSelector;
