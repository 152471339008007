import React, { useState }  from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from 'styles/util';
import { useAppContext } from 'context/AppContext';

import Box from '@material-ui/core/Box';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import CloseIcon from '@material-ui/icons/Close';
import Drawer from '@material-ui/core/Drawer';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import { Link } from 'react-router-dom';

const useStyles = makeStyles(({ theme, colors }) => ({
    menuIconButton: {
        [theme.breakpoints.up('sm')]: {
            zoom: 1.5
        }
    },
    closeIconButton: {
        backgroundColor: colors.white,
        [theme.breakpoints.up('sm')]: {
            zoom: 1.5
        }
    },
    paper: {
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        backgroundColor: colors.lightButtonColor
    },
    closeIconButtonContainer: {
        margin: '30px 30px 30px auto',
        [theme.breakpoints.up('sm')]: {
            margin: '90px 90px 90px auto'
        }
    },
    menuContainer: {
        flex: '1 1 auto',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center'
    },
    logo: {
        width: '200px',
        marginBottom: theme.spacing(2)
    },
    list: {
        '& .MuiListItemText-root': {
            textAlign: 'center'
        },
        '& a, & a:visited': {
            color: colors.black,
            textDecoration: 'none'
        }
    }
}));

const MobileNavigation = ({ menuItems }) => {
    const classes = useStyles();
    const { appContext } = useAppContext();
    const [open, setOpen] = useState(false);

    const handleToggle = () => {
        setOpen(!open);
    };

    const handleClose = () => {
        setOpen(false);
    };

    return (
        <>
            <IconButton className={classes.menuIconButton} color="primary" aria-label="menu" onClick={handleToggle}>
                <MenuIcon/>
            </IconButton>
            <Drawer
                classes={{ paper: classes.paper }}
                anchor="right"
                open={open}
                onClose={handleClose}
            >
                <Box className={classes.closeIconButtonContainer}>
                    <IconButton className={classes.closeIconButton} color="primary" aria-label="menu" onClick={handleClose}>
                        <CloseIcon/>
                    </IconButton>
                </Box>
                <Box className={classes.menuContainer}>
                    <img className={classes.logo} src={appContext.images.headerLogo}/>
                    <List className={classes.list}>
                        {
                            menuItems.map(menuItem => (
                                <ListItem key={menuItem.route}>
                                    <ListItemText>
                                        <Link to={menuItem.route} onClick={handleClose}>
                                            {menuItem.text}
                                        </Link>
                                    </ListItemText>
                                </ListItem>
                            ))
                        }
                    </List>
                </Box>
            </Drawer>
        </>
    );
};

MobileNavigation.propTypes = {
    menuItems: PropTypes.array.isRequired
};

export default MobileNavigation;
