import React, { useEffect, useState } from 'react';
import { useAppContext } from 'context/AppContext';
import { useParams } from 'react-router';
import { useDispatch } from 'react-redux';
import { handleResponse } from 'actions/actionHelpers';
import { fetchBooking } from 'actions/bookings';
import strings from 'localization/strings';
import { makeStyles } from 'styles/util';
import { formatStorageSiteAddress, getPriceAdjustmentExplanation } from 'helpers/StorageSiteHelper';
import storageGroupCategories from 'enums/storageGroupCategories';
import storageSiteFacilities from 'enums/storageSiteFacilities';
import { getResizedImageUrl } from 'helpers/ImageHelper';
import { getBookingDescription } from 'logic/bookingLogic';
import bookingStatuses from 'enums/bookingStatuses';

import Container from 'common/Container';
import Loader from 'common/Loader';
import Box from '@material-ui/core/Box';
import Actor from './Actor';
import InformationItem from './InformationItem';
import Amount from 'common/Amount';
import ShortDate from 'common/ShortDate';
import Area from 'common/Area';

const useStyles = makeStyles(({ theme, colors }) => ({
    container: {
        fontSize: '12px',
        '& *': {
            color: colors.black
        }
    },
    header: {
        display: 'flex',
        gap: theme.spacing(2),
        alignItems: 'center',
        justifyContent: 'space-between'
    },
    caption: {
        fontSize: '200%',
        fontWeight: 'bold'
    },
    logotype: {
        width: '25%'
    },
    actorContainer: {
        display: 'flex',
        gap: theme.spacing(4),
        padding: theme.spacing(2, 0),
        margin: theme.spacing(4, 0),
        borderTop: `2px solid ${colors.black}`,
        borderBottom: `2px solid ${colors.black}`,
        alignContent: 'flex-start',
        justifyContent: 'space-between',
        '& > *': {
            width: '50%'
        }
    },
    informationContainer: {
        position: 'relative',
        display: 'table',
        width: '100%',
        '& > *': {
            display: 'table-row',
            lineHeight: '150%',
            '& > *': {
                display: 'table-cell',
                paddingBottom: theme.spacing(1),
                '&:first-child': {
                    paddingRight: theme.spacing(2)
                }
            }
        }
    },
    backgroundText: {
        position: 'absolute',
        transform: 'rotate(45deg)',
        textTransform: 'uppercase',
        fontSize: '2000%',
        width: '100%',
        textAlign: 'center',
        zIndex: -1,
        color: colors.textColorDisabled
    }
}));

// url: /agreement-cover-pages/:bookingId/:token
const AgreementCoverPage = () => {
    const classes = useStyles();
    const { appContext } = useAppContext();
    const params = useParams();
    const dispatch = useDispatch();

    const [booking, setBooking] = useState(undefined);
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        dispatch(fetchBooking(params.bookingId, params.token))
            .then(handleResponse(
                response => {
                    setBooking(response.payload);
                    setIsLoading(false);
                },
                () => {
                    setIsLoading(false);
                }
            ));
    }, []);

    if(isLoading) {
        return <Loader />;
    }

    const storageSite = booking.storageGroup.storageSite;
    const storageGroupCategory = storageGroupCategories[booking.storageGroup.category];

    const title = booking.bookingStatus === bookingStatuses.purchased.key
        ? strings.rentalAgreement
        : strings.draftRentalAgreement;

    const backgroundText = booking.bookingStatus === bookingStatuses.purchased.key
        ? ''
        : strings.draft;

    const priceAdjustmentDescription = storageSite.annualPriceAdjustmentDate
        ? getPriceAdjustmentExplanation(
            storageSite.annualPriceAdjustmentPercentage < 0 ? strings.priceDecreaseDescriptionTemplate : strings.priceIncreaseDescriptionTemplate,
            Math.abs(storageSite.annualPriceAdjustmentPercentage),
            storageSite.annualPriceAdjustmentDate,
            appContext
        )
        : undefined;

    const priceLabel = booking.priceInfo.perMonth.amounts.totalVat
        ? `${strings.pricePerMonth} (${strings.excludingVatSuffix})`
        : strings.pricePerMonth;

    const facilities = storageSite.facilities.map((o, i) => (
        <React.Fragment key={i}>
            {storageSiteFacilities[o].title}
            <br/>
        </React.Fragment>
    ));

    return (
        <Container className={classes.container}>
            <Box className={classes.header}>
                <Box className={classes.caption}>
                    {title}
                </Box>
                {
                    storageSite.ownerActor.logotypeUrl &&
                    (
                        <img
                            className={classes.logotype}
                            src={getResizedImageUrl(storageSite.ownerActor.logotypeUrl, { height: 64 })}
                        />
                    )
                }
            </Box>

            <Box className={classes.actorContainer}>
                <Actor caption={strings.tenant} value={booking.tenantActor}/>
                <Actor caption={strings.owner} value={booking.ownerActor}/>
            </Box>

            <Box className={classes.informationContainer}>
                <Box className={classes.backgroundText}>
                    {backgroundText}
                </Box>
                <InformationItem label={strings.agreementNumber} value={booking.id} />
                { booking.purchases.length > 0 && <InformationItem label={strings.agreementDate} value={<ShortDate value={booking.purchases[0].paidTime} />} /> }
                <InformationItem label={strings.agreementObject} value={storageSite.title} />
                { booking.storage?.title && <InformationItem label={storageGroupCategory.storageEnumerationLabel} value={booking.storage.title} /> }
                { booking.area > 0 && <InformationItem title={strings.size} value={<Area value={booking.area} maxNumberOfDecimals={1}/>}/> }
                <InformationItem label={strings.address} value={formatStorageSiteAddress(storageSite)} />
                { storageSite.propertyReference && <InformationItem label={strings.propertyReference} value={storageSite.propertyReference} /> }
                <InformationItem label={priceLabel} value={<Amount value={booking.priceInfo.perMonth.amounts.totalAmount} currency={booking.currency} />} />
                { priceAdjustmentDescription && <InformationItem label={strings.priceAdjustment} value={priceAdjustmentDescription} /> }
                <InformationItem label={strings.minBookingLength} value={`${booking.minBookingLength} ${strings.daysLower}`} />
                <InformationItem label={strings.noticePeriod} value={`30 ${strings.daysLower}`} />
                { storageSite.facilities.length > 0 && <InformationItem label={strings.facilities} value={facilities} /> }
                { getBookingDescription(booking) && <InformationItem label={strings.purpose} value={getBookingDescription(booking)} /> }
            </Box>
        </Container>
    );
};

export default AgreementCoverPage;
