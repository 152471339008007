import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from 'styles/util';
import { formatClassNames } from 'helpers/ComponentHelper';

import Button from '@material-ui/core/Button';

const useStyles = makeStyles(() => ({
    button: {
        '& .MuiButton-label': {
            justifyContent: 'space-between'
        }
    }
}));

const RightIconButton = ({ children, icon, className, ...rest }) => {
    const classes = useStyles();
    return (
        <Button
            {...rest}
            className={formatClassNames([classes.button, className])}
            endIcon={icon}
        >
            {children}
        </Button>
    );
};

RightIconButton.propTypes = {
    children: PropTypes.node.isRequired,
    icon: PropTypes.node,
    className: PropTypes.string
};

export default RightIconButton;
