import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from 'styles/util';

import { Link } from 'react-router-dom';

// renders a link with a button appearence: <a href="...">...</a>

const useStyles = makeStyles(({ colors, fonts, styles }) => ({
    buttonLink: {
        display: 'inline-block',
        fontFamily: fonts.medium,
        fontSize: '16px',
        textDecoration: 'none',
        lineHeight: 1.5,
        letterSpacing: '-0.16px',
        padding: '8px 20px',
        borderRadius: styles.buttonBorderRadius,
        backgroundColor: colors.primaryColor,
        color: `${colors.white} !important`,
        boxShadow: '0px 3px 1px -2px rgb(0 0 0 / 20%), 0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%)',
        '&:hover': {
            textDecoration: 'none',
            backgroundColor: colors.primaryHoverColor
        },
        '&:active': {
            textDecoration: 'none',
            backgroundColor: colors.primaryHoverColor
        }
    },
    white: {
        fontFamily: fonts.light,
        letterSpacing: 'normal',
        color: `${colors.textColor} !important`,
        backgroundColor: colors.white,
        boxShadow: 'none',
        '&:hover': {
            backgroundColor: colors.lightButtonColor
        },
        '&:active': {
            backgroundColor: colors.lightButtonColor
        }
    },
    light: {
        fontFamily: fonts.light,
        letterSpacing: 'normal',
        color: `${colors.textColor} !important`,
        backgroundColor: colors.lightButtonColor,
        '&:hover': {
            color: `${colors.white} !important`,
            backgroundColor: colors.lightButtonHoverColor
        },
        '&:active': {
            color: `${colors.white} !important`,
            backgroundColor: colors.lightButtonHoverColor
        }
    },
    dark: {
        color: `${colors.white} !important`,
        backgroundColor: colors.darkButtonColor,
        '&:hover': {
            backgroundColor: colors.darkButtonHoverColor,
        },
        '&:active': {
            backgroundColor: colors.darkButtonHoverColor,
        }
    },
    large: {
        padding: '18px 30px'
    }
}),
({ colors }) => ({
    storage: {
        light: {
            '&:hover': {
                color: `${colors.textColor} !important`,
            },
            '&:active': {
                color: `${colors.textColor} !important`,
            }
        }
    }
}));

const ButtonLink = ({ variant, size, className, ...rest }) => {
    const classes = useStyles();
    const classNames = [classes.buttonLink];

    if(className) {
        classNames.push(className);
    }

    if(classes[variant]) {
        classNames.push(classes[variant]);
    }

    if(classes[size]) {
        classNames.push(classes[size]);
    }

    return (
        <Link
            {...rest}
            className={classNames.join(' ')}
        />
    );
};

ButtonLink.propTypes = {
    variant: PropTypes.string,
    size: PropTypes.string,
    className: PropTypes.string
};

export default ButtonLink;
