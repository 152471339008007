import branches from 'enums/branches';

const defaultBranchKey = branches.storage.key;

export const getCurrentBranchKey = () => window.configuration.appContext?.branchKey ?? getBranchKeyFromHostName() ?? defaultBranchKey;

const getBranchKeyFromHostName = () => {
    if(window.location.hostname.indexOf('officespace') > -1) {
        return branches.office.key;
    }
    return branches.storage.key;
};
