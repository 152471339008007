import { RSAA } from 'redux-api-middleware';
import { createRsaaTypes, createHeaders } from './actionHelpers';

export const STRIPE_FETCH_ACCOUNT_INFO = 'STRIPE_FETCH_ACCOUNT_INFO';
export const STRIPE_FETCH_ACCOUNT_INFO_SUCCESS = 'STRIPE_FETCH_ACCOUNT_INFO_SUCCESS';

export const STRIPE_CREATE_ACCOUNT_INFO = 'STRIPE_CREATE_ACCOUNT_INFO';

export const STRIPE_UPDATE_ACCOUNT_INFO = 'STRIPE_UPDATE_ACCOUNT_INFO';

export const STRIPE_CREATE_ACCOUNT_LINK = 'STRIPE_CREATE_ACCOUNT_LINK';

export const STRIPE_PAY_INVOICE = 'STRIPE_PAY_INVOICE';

export const STRIPE_CREATE_PAYMENT_INTENT = 'STRIPE_CREATE_PAYMENT_INTENT';

export const STRIPE_FETCH_PAYMENT_INTENT = 'STRIPE_FETCH_PAYMENT_INTENT';

export const STRIPE_CREATE_SETUP_INTENT = 'STRIPE_CREATE_SETUP_INTENT';

export const STRIPE_FETCH_CARD_FOR_BOOKING = 'STRIPE_FETCH_CARD_FOR_BOOKING';

export const fetchStripeAccountInfo = () => ({
    [RSAA]: {
        types: createRsaaTypes(STRIPE_FETCH_ACCOUNT_INFO),
        endpoint: '/api/stripe/account',
        headers: createHeaders(false),
        method: 'GET',
    }
});

export const createStripeAccountInfo = stripeAccountInfo => ({
    [RSAA]: {
        types: createRsaaTypes(STRIPE_CREATE_ACCOUNT_INFO),
        endpoint: '/api/stripe/account',
        method: 'POST',
        headers: createHeaders(true),
        body: JSON.stringify(stripeAccountInfo)
    }
});

export const updateStripeAccountInfo = stripeAccountInfo => ({
    [RSAA]: {
        types: createRsaaTypes(STRIPE_UPDATE_ACCOUNT_INFO),
        endpoint: '/api/stripe/account',
        method: 'PUT',
        headers: createHeaders(true),
        body: JSON.stringify(stripeAccountInfo)
    }
});

export const createStripeAccountLink = stripeAccountLinkInfo => ({
    [RSAA]: {
        types: createRsaaTypes(STRIPE_CREATE_ACCOUNT_LINK),
        endpoint: '/api/stripe/account/link',
        method: 'POST',
        headers: createHeaders(true),
        body: JSON.stringify(stripeAccountLinkInfo)
    }
});

export const createPaymentIntent = request /* { bookingId, bookingIdToExtend, immediateBookingRequestId, type, purchaseId, endDate } */ => ({
    [RSAA]: {
        types: createRsaaTypes(STRIPE_CREATE_PAYMENT_INTENT),
        endpoint: '/api/stripe/paymentIntent',
        method: 'POST',
        headers: createHeaders(true),
        body: JSON.stringify(request)
    }
});

export const fetchPaymentIntent = paymentIntentId => ({
    [RSAA]: {
        types: createRsaaTypes(STRIPE_FETCH_PAYMENT_INTENT),
        endpoint: `/api/stripe/paymentIntent/${paymentIntentId}`,
        method: 'GET',
        headers: createHeaders(false)
    }
});

export const createSetupIntent = request /* { bookingId } */ => ({
    [RSAA]: {
        types: createRsaaTypes(STRIPE_CREATE_SETUP_INTENT),
        endpoint: '/api/stripe/setupIntent',
        method: 'POST',
        headers: createHeaders(true),
        body: JSON.stringify(request)
    }
});

export const fetchCardForBooking = bookingId => ({
    [RSAA]: {
        types: createRsaaTypes(STRIPE_FETCH_CARD_FOR_BOOKING),
        endpoint: `/api/stripe/bookings/${bookingId}/card`,
        headers: createHeaders(false),
        method: 'GET',
    }
});
