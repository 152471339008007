import React, { useState } from 'react';
import { useAppContext } from 'context/AppContext';
import { useDispatch } from 'react-redux';
import { handleResponse } from 'actions/actionHelpers';
import { createTableItems, createTableColumns, createTableFooterCells } from 'helpers/ReportHelper';
import { getGoogleMapsScript } from 'helpers/MapHelper';

import AdminPage from '../../presentational/AdminPage';
import AdminContentWrapper from '../../presentational/AdminContentWrapper';
import ReportForm from './ReportForm';
import AdminTable from 'components/adminTable/AdminTable';

const Reports = () => {
    const googleMapsScript = getGoogleMapsScript();
    const dispatch = useDispatch();

    const { appContext } = useAppContext();

    const [isSubmitting, setIsSubmitting] = useState(false);
    const [reportDefinition, setReportDefinition] = useState(undefined);
    const [reportData, setReportData] = useState(undefined);

    const handleReportFormSubmit = formValues => {
        setIsSubmitting(true);
        setReportDefinition(formValues.reportDefinition);
        setReportData(undefined);

        const fetchData = fv => dispatch(fv.reportDefinition.fetchData(fv))
            .then(handleResponse(
                response => {
                    if(formValues.format === 'json') {
                        setReportData(response.payload);
                    }
                    setIsSubmitting(false);
                },
                () => setIsSubmitting(false)
            ));

        if(formValues.reportDefinition.beforeFetchData) {
            formValues.reportDefinition.beforeFetchData(formValues, appContext, fetchData);
        } else {
            fetchData(formValues);
        }
    };

    const parameters = {};

    return (
        <AdminPage title="Reports" fullWidth>
            <AdminContentWrapper type="listing">
                {
                    googleMapsScript.isLoaded &&
                    (
                        <ReportForm
                            parameters={parameters}
                            isSubmitting={isSubmitting}
                            onSubmit={handleReportFormSubmit}
                        />
                    )
                }

                {
                    reportData &&
                    (
                        <AdminTable
                            items={createTableItems(reportData)}
                            columns={createTableColumns(reportData, reportDefinition, appContext)}
                            footerCells={createTableFooterCells(reportData, reportDefinition, appContext)}
                        />
                    )
                }
            </AdminContentWrapper>
        </AdminPage>
    );
};

export default Reports;
