import React from 'react';
import PropTypes from 'prop-types';
import { useAppContext } from 'context/AppContext';
import { mustBeNumber } from 'form/validation';
import { formatNumber, convertToNumber } from 'helpers/StringHelper';
import { makeStyles } from 'styles/util';

import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Typography from '@material-ui/core/Typography';
import Form from 'form/Form';
import { TextField, Checkboxes, showErrorOnBlur } from 'mui-rff';
import Amount from 'common/Amount';

const formatAmountOptions = { displayPriceRounding: false, toLocale: false };
const formatNumberOptions = { numberOfDecimals: 2, toLocale: false };

const useStyles = makeStyles(({ colors }) => ({
    storage365WillLose: {
        color: colors.attention
    }
}));

const RefundPaymentDialog = ({ booking, payment, refundInfo, open, onSubmit, onCancel }) => {
    const classes = useStyles();
    const { appContext } = useAppContext();

    const initialValues = {
        ownerAmountToRefund: formatNumber(refundInfo.maxRefundableOwnerAmount, appContext, formatNumberOptions),
        commissionFeeToRefund: formatNumber(refundInfo.maxRefundableCommissionFee - refundInfo.paymentProviderFee, appContext, formatNumberOptions),
        cancelBooking: false,
        endSubscription: false
    };

    const handleFormSubmit = formValues => {
        onSubmit({
            ...formValues,
            refundInfo,
            payment,
            ownerAmountToRefund: convertToNumber(formValues.ownerAmountToRefund),
            commissionFeeToRefund: convertToNumber(formValues.commissionFeeToRefund)
        });
    };

    return (
        <Dialog fullWidth maxWidth="md" open={open} onClose={onCancel}>
            <Form
                initialValues={initialValues}
                onSubmit={handleFormSubmit}
            >
                {({ handleSubmit, values, invalid }) => {
                    const ownerAmountToRefund = convertToNumber(values.ownerAmountToRefund);
                    const commissionFeeToRefund = convertToNumber(values.commissionFeeToRefund);
                    const totalAmountToRefund = ownerAmountToRefund + commissionFeeToRefund;
                    const invalidAmount = !(
                        ownerAmountToRefund >= 0 && ownerAmountToRefund <= refundInfo.maxRefundableOwnerAmount &&
                        commissionFeeToRefund >= 0 && commissionFeeToRefund <= refundInfo.maxRefundableCommissionFee &&
                        totalAmountToRefund > 0 && totalAmountToRefund <= refundInfo.maxRefundableAmount
                    );
                    const storage365Result = refundInfo.maxRefundableAmount - refundInfo.paymentProviderFee - totalAmountToRefund;
                    const currency = refundInfo.currency;
                    return (
                        <form onSubmit={handleSubmit}>
                            <DialogTitle disableTypography>
                                <Typography variant="h5">
                                    Refund booking
                                </Typography>
                            </DialogTitle>
                            <DialogContent>
                                <Typography variant="body1" gutterBottom>
                                    Specify the amount to refund to the tenant, divided in owner and Storage365 parts. Storage365 still has to pay the payment provider fee for the original payment. Please note that this action cannot be undone.
                                </Typography>

                                {
                                    refundInfo.hasAlreadyBeenRefunded &&
                                    (
                                        <>
                                            <Typography variant="body1" gutterBottom>
                                                Initial payment amount: <strong><Amount value={refundInfo.originalAmount} currency={currency} {...formatAmountOptions}/></strong>
                                            </Typography>
                                            <Typography variant="body1" gutterBottom>
                                                Initial owner amount: <strong><Amount value={refundInfo.originalOwnerAmount} currency={currency} {...formatAmountOptions}/></strong>
                                            </Typography>
                                            <Typography variant="body1" gutterBottom>
                                                Initial Storage365 amount, excluding payment provider fee: <strong><Amount value={refundInfo.originalCommissionFee - refundInfo.paymentProviderFee} currency={currency} {...formatAmountOptions}/></strong>
                                            </Typography>
                                            <Typography variant="body1" gutterBottom>
                                                Initial payment provider fee: <strong><Amount value={refundInfo.paymentProviderFee} currency={currency} {...formatAmountOptions}/></strong>
                                            </Typography>
                                            <Typography variant="body1" gutterBottom>
                                                Already refunded amount: <strong><Amount value={refundInfo.alreadyRefundedAmount} currency={currency} {...formatAmountOptions}/></strong>
                                            </Typography>
                                            <Typography variant="body1" gutterBottom>
                                                Already refunded owner amount: <strong><Amount value={refundInfo.alreadyRefundedOwnerAmount} currency={currency} {...formatAmountOptions}/></strong>
                                            </Typography>
                                            <Typography variant="body1" gutterBottom>
                                                Already refunded Storage365 amount: <strong><Amount value={refundInfo.alreadyRefundedCommissionFee} currency={currency} {...formatAmountOptions}/></strong>
                                            </Typography>
                                        </>
                                    )
                                }

                                {
                                    !refundInfo.hasAlreadyBeenRefunded &&
                                    (
                                        <>
                                            <Typography variant="body1" gutterBottom>
                                                Payment amount: <strong><Amount value={refundInfo.originalAmount} currency={currency} {...formatAmountOptions}/></strong>
                                            </Typography>
                                            <Typography variant="body1" gutterBottom>
                                                Owner amount: <strong><Amount value={refundInfo.originalOwnerAmount} currency={currency} {...formatAmountOptions}/></strong>
                                            </Typography>
                                            <Typography variant="body1" gutterBottom>
                                                Storage365 amount, excluding payment provider fee: <strong><Amount value={refundInfo.originalCommissionFee - refundInfo.paymentProviderFee} currency={currency} {...formatAmountOptions}/></strong>
                                            </Typography>
                                            <Typography variant="body1" gutterBottom>
                                                Payment provider fee: <strong><Amount value={refundInfo.paymentProviderFee} currency={currency} {...formatAmountOptions}/></strong>
                                            </Typography>
                                        </>
                                    )
                                }

                                <TextField
                                    name="ownerAmountToRefund"
                                    label="Owner amount to refund to tenant"
                                    variant="outlined"
                                    showError={showErrorOnBlur}
                                    required
                                    fieldProps={{ validate: mustBeNumber }}
                                />

                                <TextField
                                    name="commissionFeeToRefund"
                                    label="Storage365 amount to refund to tenant"
                                    variant="outlined"
                                    showError={showErrorOnBlur}
                                    required
                                    fieldProps={{ validate: mustBeNumber }}
                                />

                                <Typography variant="body1" gutterBottom>
                                    Total amount to refund to tenant: <strong><Amount value={totalAmountToRefund} currency={currency} {...formatAmountOptions}/></strong>
                                </Typography>

                                {
                                    !invalidAmount && storage365Result < 0 &&
                                    (
                                        <Typography variant="body1" className={classes.storage365WillLose} gutterBottom>
                                            <strong>NOTE! Storage365 will lose <Amount value={-storage365Result} currency={currency} {...formatAmountOptions}/> on this refund.</strong>
                                        </Typography>
                                    )
                                }

                                {
                                    refundInfo.bookingCanBeCanceled &&
                                    (
                                        <Checkboxes
                                            name="cancelBooking"
                                            data={{
                                                label: 'Cancel booking',
                                                value: true
                                            }}
                                        />
                                    )
                                }

                                {
                                    !values.cancelBooking && booking.subscriptionBooking &&
                                    (
                                        <Checkboxes
                                            name="endSubscription"
                                            data={{
                                                label: 'End subscription',
                                                value: true
                                            }}
                                        />
                                    )
                                }

                            </DialogContent>
                            <DialogActions>
                                <Button
                                    type="submit"
                                    variant="contained"
                                    color="primary"
                                    disabled={invalid || invalidAmount}
                                >
                                    Refund
                                </Button>
                                <Button
                                    variant="outlined"
                                    color="primary"
                                    onClick={onCancel}
                                >
                                    Cancel
                                </Button>
                            </DialogActions>
                        </form>
                    );
                }}
            </Form>
        </Dialog>
    );
};

RefundPaymentDialog.propTypes = {
    booking: PropTypes.object.isRequired,
    payment: PropTypes.object.isRequired,
    refundInfo: PropTypes.object.isRequired,
    open: PropTypes.bool.isRequired,
    onSubmit: PropTypes.func.isRequired,
    onCancel: PropTypes.func.isRequired
};

export default RefundPaymentDialog;
