import strings from 'localization/strings';

const bookingTimeModes = {
    period: {},
    subscription: {}
};

Object.keys(bookingTimeModes).forEach(key => {
    bookingTimeModes[key].key = key;
});

strings.addLoadCallback(() => {
    Object.values(bookingTimeModes).forEach(o => {
        o.title = strings.bookingTimeModes[o.key];
    });
});

export default bookingTimeModes;
