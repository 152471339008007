import { RSAA } from 'redux-api-middleware';
import { createRsaaTypes, createDownloadFileTypes, createHeaders } from './actionHelpers';
import queryString from 'query-string';

export const PURCHASES_FETCH = 'PURCHASES_FETCH';
export const PURCHASES_CREATE_PREVIEW = 'PURCHASES_CREATE_PREVIEW';
export const PURCHASES_DOWNLOAD_INVOICE = 'PURCHASES_DOWNLOAD_INVOICE';

export const fetchPurchase = (purchaseId, token, includeBookingTenantQrCode) => ({
    [RSAA]: {
        types: createRsaaTypes(PURCHASES_FETCH),
        endpoint: `/api/purchases/${purchaseId}?${queryString.stringify({ token, includeBookingTenantQrCode: !!includeBookingTenantQrCode })}`,
        headers: createHeaders(false),
        method: 'GET'
    }
});

export const createPreviewPurchase = request => ({
    [RSAA]: {
        types: createRsaaTypes(PURCHASES_CREATE_PREVIEW),
        endpoint: '/api/purchases/preview',
        method: 'POST',
        headers: createHeaders(true),
        body: JSON.stringify(request)
    }
});

export const downloadPurchaseInvoice = (purchaseId, token) => ({
    [RSAA]: {
        types: createDownloadFileTypes(PURCHASES_DOWNLOAD_INVOICE),
        endpoint: `/api/purchases/${purchaseId}/invoice?token=${token}`,
        method: 'GET',
        headers: createHeaders(false)
    }
});
