import React from 'react';
import PropTypes from 'prop-types';

const VideoPlayer = ({ src, ...props }) => {
    return (
        <video
            controls
            preload="metadata"
            {...props}
        >
            <source src={src}/>
        </video>
    );
};

VideoPlayer.propTypes = {
    src: PropTypes.string
};

export default VideoPlayer;
