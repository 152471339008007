import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import strings from 'localization/strings';
import { convertToNumber } from 'helpers/StringHelper';
import { required } from 'form/validation';

import Typography from '@material-ui/core/Typography';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '@material-ui/core/Button';
import Form from 'form/Form';
import { Radios } from 'mui-rff';

const ActorSelectorDialog = ({ title, description, open, onOk }) => {
    const authenticationContext = useSelector(state => state.authentication.context);
    const { selectedActor, actors } = authenticationContext;

    const handleFormSubmit = formValues => {
        if(onOk) {
            onOk(convertToNumber(formValues.actorId));
        }
    };

    const actorItems = actors
        .map(o => ({
            label: o.name,
            value: o.id + ''
        }));

    const initialValues = {
        actorId: selectedActor ? selectedActor.id + '' : null
    };

    return (
        <Dialog open={open}>
            <Form
                initialValues={initialValues}
                onSubmit={handleFormSubmit}
            >
                {({ invalid, handleSubmit }) => (
                    <form onSubmit={handleSubmit}>
                        <DialogTitle disableTypography>
                            <Typography variant="h5">
                                {title}
                            </Typography>
                        </DialogTitle>
                        <DialogContent>
                            {
                                description &&
                                (
                                    <Typography variant="body1" gutterBottom>
                                        {description}
                                    </Typography>
                                )
                            }
                            <Radios
                                name="actorId"
                                data={actorItems}
                                required
                                fieldProps={{ validate: required }}
                            />
                        </DialogContent>
                        <DialogActions>
                            <Button
                                type="submit"
                                variant="contained"
                                color="primary"
                                disabled={invalid}
                            >
                                {strings.ok}
                            </Button>
                        </DialogActions>
                    </form>
                )}
            </Form>
        </Dialog>
    );
};

ActorSelectorDialog.propTypes = {
    title: PropTypes.string.isRequired,
    description: PropTypes.string,
    open: PropTypes.bool.isRequired,
    onOk: PropTypes.func
};

export default ActorSelectorDialog;
