import React, {useState} from 'react';
import PropTypes from 'prop-types';
import { useAppContext } from 'context/AppContext';
import { makeStyles } from 'styles/util';
import strings from 'localization/strings';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { roundToDecimals } from 'helpers/SearchHelper';
import { toPascalCase } from 'helpers/StringHelper';
import { getParams, createInitialValues, getActiveFilters } from 'helpers/StorageSearchFormHelper';

import Container from 'common/Container';
import ReadMoreText from 'components/ReadMoreText';
import LocationSelector from 'form/LocationSelector';
import FilterButton from './FilterButton';
import Box from '@material-ui/core/Box/Box';
import Typography from '@material-ui/core/Typography';
import FilterPopovers from 'components/storageSearchForm/FilterPopovers';
import FilterModal from 'components/storageSearchForm/FilterModal';

const useStyles = makeStyles(({ theme, colors, styles }) => ({
    outerContainer: {
        background: colors.lightButtonColor,
        paddingTop: theme.spacing(6),
    },
    innerContainer: {
        width: '100%',
        display: 'flex',
        alignItems: 'flex-end',
        justifyContent: 'space-between',
        gap: '100px'
    },
    landingPageSearchForm: {
        flex: '1 1 auto',
        paddingBottom: theme.spacing(6)
    },
    assistance: {
        backgroundColor: colors.white,
        width: '445px',
        flex: '0 0 auto',
        display: 'flex',
        flexDirection: 'column',
        gap: theme.spacing(3),
        alignItems: 'center',
        textAlign: 'center',
        padding: '40px 40px 20px 40px',
        borderTopLeftRadius: `${styles.borderRadius}px`,
        borderTopRightRadius: `${styles.borderRadius}px`,
        [theme.breakpoints.down('sm')]: {
            display: 'none'
        }
    },
    searchContainer: {
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(4)
    },
    buttonWrapper: {
        display: 'flex',
        gap: theme.spacing(2)
    },
    filters: {
        background: colors.secondaryColor,
        marginTop: theme.spacing(1),
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center'
    },
    filterButton: {
        border: `0.5px solid ${colors.white}`,
        color: colors.white
    }
}));

const LandingPageSearchForm = ({ landingPage, onSubmit, searchParams, seoGeoItem }) => {
    const classes = useStyles();
    const isMobile = !useMediaQuery(theme => theme.breakpoints.up('sm'));
    const { appContext } = useAppContext();

    const [popoverOpen, setPopoverOpen] = useState(false);
    const [type, setType] = useState(undefined);
    const [anchorEl, setAnchorEl] = useState(undefined);
    const [modalOpen, setModalOpen] = useState(false);
    const initialValues = createInitialValues(searchParams);
    const activeFilters = getActiveFilters(landingPage, appContext, initialValues);

    const handleFormSubmit = values => {
        if(popoverOpen) {
            handleTogglePopover();
        }
        if(modalOpen) {
            handleToggleModal();
        }

        const params = getParams({ ...initialValues, ...values, page: 1 });

        ['goodsTypes', 'storageTypes', 'facilities', 'ownerTypes']
            .forEach(p => {
                params[p] = params[p].filter(o => o !== undefined);
                if(params[p].length === 0) {
                    params[p] = undefined;
                }
            });

        onSubmit(params);
    };

    const handleLocationSearch = searchResult => {
        const parameters = { lat: undefined, lng: undefined };
        parameters.locationText = searchResult.locationText;
        parameters.location = searchResult.location;
        if(searchResult.location && searchResult.locationText) {
            parameters.lat = roundToDecimals(searchResult.location.lat, 5);
            parameters.lng = roundToDecimals(searchResult.location.lng, 5);
        }
        handleFormSubmit(parameters);
    };

    const handleTogglePopover = (event, popoverType) => {
        setPopoverOpen(!popoverOpen);
        setAnchorEl(event ? event.currentTarget : null);
        setType(popoverType);
    };

    const handleToggleModal = () => {
        setModalOpen(!modalOpen);
    };

    const getPageH1 = () => {
        return seoGeoItem && seoGeoItem.h1 ? seoGeoItem.h1 : landingPage.searchTitle;
    };

    const setReadMoreTextHeight = (visibleLines = 1) => {
        const lineHeight = visibleLines * appContext.theme.typography.body1.lineHeight * appContext.theme.typography.htmlFontSize;
        return `${lineHeight}px`;
    };

    return (
        <Box className={classes.outerContainer}>
            <Container className={classes.innerContainer} smMaxWidth="585px">
                <Box className={classes.landingPageSearchForm}>
                    <Typography variant="h1">{getPageH1()}</Typography>
                    {seoGeoItem && seoGeoItem.content && <ReadMoreText text={seoGeoItem.content} fadeBackgroundColor={appContext.colors.lightButtonColor} maxHeight={setReadMoreTextHeight()}/>}
                    <Box className={classes.searchContainer}>
                        <LocationSelector
                            address={searchParams.locationText}
                            location={location}
                            onSearch={handleLocationSearch}
                            border={false}
                            background={false}
                        />
                    </Box>
                    <Box className={classes.buttonWrapper}>
                        <FilterButton
                            show={!isMobile}
                            filtersOpen={popoverOpen && type === 'size'}
                            label={strings.size}
                            badgeCount={activeFilters.sizeFiltersCount}
                            onClick={(e) => handleTogglePopover(e, 'size')} />
                        <FilterButton
                            show={!isMobile}
                            filtersOpen={popoverOpen && type === 'price'}
                            label={strings.price}
                            badgeCount={activeFilters.priceFiltersCount}
                            onClick={(e) => handleTogglePopover(e, 'price')} />
                        <FilterButton
                            filtersOpen={popoverOpen && type === 'additionalFilters'}
                            label={strings.storageSearchForm.otherFilters}
                            badgeCount={isMobile ? activeFilters.totalFiltersCount : activeFilters.additionalFiltersCount}
                            onClick={(e) => isMobile ? handleToggleModal() : handleTogglePopover(e, 'additionalFilters')}/>
                    </Box>
                </Box>
                {
                    searchParams.locationText &&
                    (
                        <Box className={classes.assistance}>
                            <Typography variant="h2">{strings.formatString(landingPage.assistanceToFindCaption, toPascalCase(searchParams.locationText))}</Typography>
                            <Typography variant="body1">{strings.formatString(landingPage.assistanceToFindBody, toPascalCase(searchParams.locationText))}</Typography>
                        </Box>
                    )
                }
                <FilterModal
                    landingPage={landingPage}
                    open={modalOpen}
                    initialValues={initialValues}
                    onClose={handleToggleModal}
                    onSubmit={handleFormSubmit} />
                <FilterPopovers
                    filterType={type}
                    anchorEl={anchorEl}
                    open={popoverOpen}
                    onClose={handleTogglePopover}
                    onSubmit={handleFormSubmit}
                    initialValues={initialValues}
                    landingPage={landingPage}
                />
            </Container>
        </Box>
    );
};

LandingPageSearchForm.propTypes = {
    landingPage: PropTypes.object.isRequired,
    onSubmit: PropTypes.func,
    searchParams: PropTypes.object,
    seoGeoItem: PropTypes.object,
    // h1: PropTypes.string,
    // storageSitesLoading: PropTypes.bool
};

export default LandingPageSearchForm;

