import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from 'styles/util';

import Box from '@material-ui/core/Box';


const TwoColumnTable = ({ items, background, nowrap = false }) => {
    const useStyles = makeStyles(({ theme, colors, fonts }) => ({
        container: {
            backgroundColor: background ?? colors.lightGrey,
            margin: theme.spacing(4, 0),
            padding: theme.spacing(4)
        },
	    row: {
            display: 'flex',
            lineHeight: '200%'
        },
        left: {
            flex: '60%',
            textAlign: 'left'
        },
        right: {
            flex: '40%',
            textAlign: 'right'
        },
        bold: {
            '& *': {
                fontFamily: fonts.bold
            }
        },
        nowrap: {
            whiteSpace: 'nowrap'
        }
	}));
    const classes = useStyles();

    return (
        <Box className={classes.container}>
            {
                items.map((item, index) => {
                    let rowClassName = classes.row;
                    if (item.bold) {
                        rowClassName += ' ' + classes.bold;
                    }
                    return (
                        <Box key={index} className={rowClassName}>
                            <Box className={classes.left}>{item.cells[0]}</Box>
                            <Box className={classes.right + (nowrap ? ' ' + classes.nowrap : '')}>{item.cells[1]}</Box>
                        </Box>
                    );
                })
            }
        </Box>
    );
};

TwoColumnTable.propTypes = {
    items: PropTypes.array.isRequired,
    background: PropTypes.string,
    nowrap: PropTypes.bool
};

export default TwoColumnTable;
