import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { setSelectedActor } from 'actions/authentication';
import { handleResponse } from 'actions/actionHelpers';
import strings from 'localization/strings';
import organizationTypes from 'enums/organizationTypes';
import routes from 'routes';

import PageTitle from 'common/PageTitle';
import ShadowBox from 'common/ShadowBox';
import MarginBox from 'common/MarginBox';
import LoadingWrapper from 'form/LoadingWrapper';
import Typography from '@material-ui/core/Typography';
import LinkButton from 'common/LinkButton';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';

const SelectActor = () => {
    const dispatch = useDispatch();
    const history = useHistory();

    const authenticationContext = useSelector(state => state.authentication.context);
    const { selectedActor, actors } = authenticationContext;

    const [isLoading, setIsLoading] = useState(false);

    const handleButtonClick = actor => {
        setIsLoading(true);
        dispatch(setSelectedActor(actor.id))
            .then(handleResponse(
                () => {
                    setIsLoading(false);
                    history.push(routes.account.overview);
                },
                () => setIsLoading(false)
            ));
    };

    const title = strings.accountTabs.selectActor;

    return (
        <>
            <PageTitle>{title}</PageTitle>

            <ShadowBox
                title={title}
            >
                <LoadingWrapper
                    isLoading={isLoading}
                >
                    <MarginBox bottom={4}>
                        <Typography variant="body1" gutterBottom>
                            {strings.selectActorDescription}
                        </Typography>
                    </MarginBox>

                    <MarginBox bottom={2}>
                        <Typography variant="h6">
                            {strings.selectedActorTitle}
                        </Typography>

                        <List>
                            <ListItem disableGutters>
                                <ListItemText>
                                    {selectedActor.name} ({organizationTypes[selectedActor.organizationType].title})
                                </ListItemText>
                            </ListItem>
                        </List>
                    </MarginBox>

                    <Typography variant="h6">
                        {strings.otherActorsTitle}
                    </Typography>

                    <List>
                        {
                            actors.filter(actor => actor.id !== selectedActor.id).map(actor => (
                                <ListItem key={actor.id} disableGutters>
                                    <ListItemText>
                                        <LinkButton
                                            onClick={() => handleButtonClick(actor)}
                                        >
                                            {actor.name} ({organizationTypes[actor.organizationType].title})
                                        </LinkButton>
                                    </ListItemText>
                                </ListItem>
                            ))
                        }
                    </List>
                </LoadingWrapper>
            </ShadowBox>

        </>
    );
};

export default SelectActor;
