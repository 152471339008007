import React from 'react';
import PropTypes from 'prop-types';
import adminBookingStatuses from 'enums/adminBookingStatuses';

import Button from '@material-ui/core/Button';
import Form from 'form/Form';
import { TextField } from 'mui-rff';
import AdminFormWrapper from '../../presentational/AdminFormWrapper';
import AdminFormButtons from '../../presentational/AdminFormButtons';
import AdminFormFields from '../../presentational/AdminFormFields';

const SearchBookingsForm = ({ searchParameters, onSubmit, isSubmitting = false }) => {
    const { status } = searchParameters;

    const createButton = (buttonStatus, buttonText, form) => {
        return (
            <Button
                type="submit"
                variant="contained"
                color={status === buttonStatus ? 'primary' : 'secondary'}
                onClick={() => { form.change('status', buttonStatus); }}
            >
                {buttonText}
            </Button>
        );
    };

    return (
        <AdminFormWrapper isSubmitting={isSubmitting}>
            <Form
                initialValues={searchParameters}
                onSubmit={onSubmit}
            >
                {({ handleSubmit, form }) => (
                    <form onSubmit={handleSubmit}>
                        <AdminFormButtons>
                            { createButton('all', 'All', form) }
                            { createButton(adminBookingStatuses.requested.key, 'Requested', form) }
                            { createButton(adminBookingStatuses.confirmed.key, 'Confirmed', form) }
                            { createButton(adminBookingStatuses.canceled.key, 'Canceled before purchase', form) }
                            { createButton(adminBookingStatuses.notStarted.key, 'Not yet started', form) }
                            { createButton(adminBookingStatuses.active.key, 'Active', form) }
                            { createButton(adminBookingStatuses.subscriptionEnding.key, 'Ending subscriptions', form) }
                            { createButton(adminBookingStatuses.ended.key, 'Ended', form) }
                        </AdminFormButtons>

                        <AdminFormFields>
                            <TextField
                                name="bookingId"
                                label="Booking ID"
                                variant="outlined"
                            />
                            <TextField
                                name="ownerQuery"
                                label="Owner"
                                variant="outlined"
                                placeholder="Whole or part of owner name, email, or phone number"
                            />
                            <TextField
                                name="tenantQuery"
                                label="Tenant"
                                variant="outlined"
                                placeholder="Whole or part of tenant name, email, or phone number"
                            />
                            <TextField
                                name="storageSiteTitle"
                                label="Storage site title"
                                variant="outlined"
                                placeholder="Whole or part of storage site title"
                            />
                        </AdminFormFields>

                        <AdminFormButtons>
                            <Button
                                type="submit"
                                variant="contained"
                                color="primary"
                            >
                                Search
                            </Button>
                        </AdminFormButtons>
                    </form>
                )}
            </Form>
        </AdminFormWrapper>
    );
};

SearchBookingsForm.propTypes = {
    searchParameters: PropTypes.object,
    onSubmit: PropTypes.func,
    isSubmitting: PropTypes.bool
};

export default SearchBookingsForm;
