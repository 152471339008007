import React from 'react';
import PropTypes from 'prop-types';
import { formatShortDateTime } from 'helpers/DateHelper';

import DateDisplay from './DateDisplay';

const ShortDateTime = props => <DateDisplay formatter={formatShortDateTime} {...props}/>;

ShortDateTime.propTypes = {
    value: PropTypes.oneOfType([PropTypes.instanceOf(Date), PropTypes.string]),
    defaultString: PropTypes.string
};

export default ShortDateTime;
