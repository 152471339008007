import React from 'react';
import PropTypes from 'prop-types';
import { formatShortDate } from 'helpers/DateHelper';

import DateDisplay from './DateDisplay';

const ShortDate = props => <DateDisplay formatter={formatShortDate} {...props}/>;

ShortDate.propTypes = {
    value: PropTypes.oneOfType([PropTypes.instanceOf(Date), PropTypes.string]),
    defaultString: PropTypes.string
};

export default ShortDate;
