import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from 'styles/util';
import { getResizedImageUrl } from 'helpers/ImageHelper';

import MediaList from './MediaList';

const imageWidth = 160;
const imageHeight = 100;

const useStyles = makeStyles(({ theme, colors }) => ({
    media: {
        border: `1.5px solid ${colors.mediumGrey}`,
        userSelect: 'none'
    },
    loading: {
        display: 'none'
    },
    imageContainer: {
        width: `${imageWidth}px`,
        height: `${imageHeight}px`,
        margin: theme.spacing(0, 2, 2, 0)
    }
}));

const ImageList = ({ fields, onImageClick }) => {
    const classes = useStyles();
    const handleImageClick = (image, index) => {
        if(onImageClick && !image.loading) {
            onImageClick(image, index);
        }
    };

    return (
        <MediaList fields={fields} containerClassName={classes.imageContainer}>
            {
                (image, name, index) => (
                    <img
                        src={getResizedImageUrl(image.mediaUrl, { width: imageWidth, height: imageHeight, bgcolor: 'f5f5f5' })}
                        width={imageWidth}
                        height={imageHeight}
                        className={image.loading ? classes.loading : classes.media}
                        onClick={() => handleImageClick(image, index)}
                    />
                )
            }
        </MediaList>
    );
};

ImageList.propTypes = {
    fields: PropTypes.object.isRequired,
    onImageClick: PropTypes.func
};

export default ImageList;
