import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from 'styles/util';
import { useField } from 'react-final-form';
import strings from 'localization/strings';

import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import { Radios } from 'mui-rff';
import OpeningTimeSpanRuleList from '../OpeningTimeSpanRuleList';

const useStyles = makeStyles(({ theme }) => ({
    grid: {
        marginBottom: theme.spacing(4)
    },
    marginTop: {
        marginTop: theme.spacing(2)
    },
    marginBottom: {
        marginBottom: theme.spacing(2)
    }
}));

const EditStep4 = ({ showStepNumber = true }) => {
    const classes = useStyles();

    const fieldNamePrefix = 'steps[4]';

    const getName = suffix => suffix ? `${fieldNamePrefix}.${suffix}` : fieldNamePrefix;
    const getValue = suffix => useField(getName(suffix)).input.value;

    const useOpeningTimeSpanRules = getValue('useOpeningTimeSpanRules') === 'true';

    const useOpeningTimeSpanRulesItems = [
        { label: strings.doNotUseOpeningTimeSpanRules, value: 'false' },
        { label: strings.useOpeningTimeSpanRules, value: 'true' }
    ];

    return (
        <Box>
            <Typography variant="h5" className={classes.marginBottom}>
                { showStepNumber && '5. ' }
                {strings.openingTimeSpanRules}
            </Typography>

            <Grid container spacing={2} className={classes.grid}>
                <Grid item xs={12}>
                    <Radios
                        name={getName('useOpeningTimeSpanRules')}
                        data={useOpeningTimeSpanRulesItems}
                    />

                    {
                        useOpeningTimeSpanRules &&
                        (
                            <Box className={classes.marginTop}>
                                <Typography variant="body1" gutterBottom>
                                    {strings.openingTimeSpanRulesDescription}
                                </Typography>
                                <OpeningTimeSpanRuleList
                                    fieldNamePrefix={getName('openingTimeSpanRules')}
                                />
                            </Box>
                        )
                    }
                </Grid>
            </Grid>

        </Box>
    );
};

EditStep4.propTypes = {
    showStepNumber: PropTypes.bool
};

export default EditStep4;
