import React from 'react';
import PropTypes from 'prop-types';
import strings from 'localization/strings';

import Typography from '@material-ui/core/Typography';
import PriceSpecificationTableNew from 'common/PriceSpecification/PriceSpecificationTableNew';

const PurchasePaymentSpecification = ({ booking, purchaseItems }) => {
    const showVat = purchaseItems.some(o => o.vat);

    return (
        <>
            <Typography variant="h6" gutterBottom>{strings.specification}</Typography>
            <PriceSpecificationTableNew booking={booking} purchaseItems={purchaseItems} showVat={showVat} />
        </>
    );
};

PurchasePaymentSpecification.propTypes = {
    booking: PropTypes.object.isRequired,
    purchaseItems: PropTypes.array.isRequired
};

export default PurchasePaymentSpecification;
