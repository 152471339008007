import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from 'styles/util';
import useMediaQuery from '@material-ui/core/useMediaQuery';

import StorageSiteForm from './StorageSiteForm';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import strings from 'localization/strings';

const useStyles = makeStyles(({ theme }) => ({
    buttons: {
        marginTop: theme.spacing(2),
        '& > *': {
            marginRight: theme.spacing(2)
        }
    },
    fullWidthButtons: {
        marginTop: theme.spacing(2),
        '& > *': {
            marginBottom: theme.spacing(1)
        },
        '& > *:last-child': {
            marginBottom: 0
        }
    }
}));

const Wizard = ({ initialValues, children, lastStepButtons, onSubmit, onCancel, step, onStepChanged }) => {
    const classes = useStyles();

    const buttonFullWidth = !useMediaQuery(theme => theme.breakpoints.up('md'));

    const next = values => onStepChanged(Math.min(step + 1, children.length - 1), values);
    const previous = values => onStepChanged(Math.max(step - 1, 0), values);

    const validate = values => {
        const activeStep = React.Children.toArray(children)[step];
        return activeStep.props.validate
            ? activeStep.props.validate(values)
            : {};
    };

    const handleFormSubmit = values => {
        const isLastStep = step === React.Children.count(children) - 1;
        if (isLastStep) {
            onSubmit(values);
        } else {
            next(values);
        }
    };

    const activeStep = React.Children.toArray(children)[step];
    const isLastStep = step === React.Children.count(children) - 1;

    const defaultLastStepButtons = [
        { label: strings.save }
    ];

    const changeToValidStepIfNeeded = (stepValidities) => {
        let lastValidStep = -1;
        stepValidities.forEach((valid, i) => {
            if(valid) {
                lastValidStep = i;
            }
        });
        if(step > lastValidStep + 1) {
            onStepChanged(lastValidStep + 1);
        }
    };

    return (
        <StorageSiteForm
            initialValues={initialValues}
            validate={validate}
            onSubmit={handleFormSubmit}
        >
            {({ handleSubmit, submitting, form, values, valid }) => {
                if(valid !== values.stepValidities[step]) {
                    form.change(`stepValidities[${step}]`, valid);
                }
                changeToValidStepIfNeeded(values.stepValidities);
                const createLastStepButtons = () => {
                    return (
                        <>
                            {
                                (lastStepButtons || defaultLastStepButtons).map((item, index) => (
                                    <Button
                                        key={index}
                                        variant="contained"
                                        color="primary"
                                        type="submit"
                                        fullWidth={buttonFullWidth}
                                        disabled={submitting || !valid}
                                        onClick={() => item.onClick && item.onClick(form)}
                                    >
                                        {item.label}
                                    </Button>
                                ))
                            }
                        </>
                    );
                };

                return (
                    <form onSubmit={handleSubmit}>
                        {activeStep}
                        <Box className={buttonFullWidth ? classes.fullWidthButtons : classes.buttons}>
                            {
                                step > 0 &&
                                (
                                    <Button
                                        variant="outlined"
                                        color="primary"
                                        type="button"
                                        fullWidth={buttonFullWidth}
                                        onClick={() => previous(values)}
                                    >
                                        {strings.previous}
                                    </Button>
                                )
                            }
                            {
                                !isLastStep &&
                                (
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        type="submit"
                                        fullWidth={buttonFullWidth}
                                        disabled={!valid}
                                    >
                                        {strings.next}
                                    </Button>
                                )
                            }
                            {
                                isLastStep && createLastStepButtons()
                            }
                           <Button
                                variant="outlined"
                                color="primary"
                                type="button"
                                fullWidth={buttonFullWidth}
                                onClick={onCancel}
                            >
                                {strings.cancel}
                            </Button>
                        </Box>
                    </form>
                );
            }}
        </StorageSiteForm>
    );
};

Wizard.propTypes = {
    initialValues: PropTypes.object.isRequired,
    onSubmit: PropTypes.func.isRequired,
    onCancel: PropTypes.func.isRequired,
    step: PropTypes.number.isRequired,
    onStepChanged: PropTypes.func.isRequired,
    children: PropTypes.array.isRequired,
    lastStepButtons: PropTypes.array
};

Wizard.Step = ({ children }) => children;

export default Wizard;
