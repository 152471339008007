import React from 'react';
import PropTypes from 'prop-types';
import strings from 'localization/strings';

import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';

const MediaUploadErrorDialog = ({ uploadErrors, open, onClose }) => (
    <Dialog open={open} onClose={onClose}>
        <DialogTitle disableTypography>
            <Typography variant="h5">
                {strings.errorDialogTitle}
            </Typography>
        </DialogTitle>
        <DialogContent>
            {
                uploadErrors.map((errorItem, i) => (
                    <React.Fragment key={i}>
                        {
                            errorItem.errors.map((error, j) => (
                                <Typography key={j} variant="body1" gutterBottom>
                                    {error.message}
                                </Typography>
                            ))
                        }
                    </React.Fragment>
                ))
            }
        </DialogContent>
        <DialogActions>
            <Button
                variant="outlined"
                color="primary"
                onClick={onClose}
            >
                {strings.close}
            </Button>
        </DialogActions>
    </Dialog>
);

MediaUploadErrorDialog.propTypes = {
    uploadErrors: PropTypes.array.isRequired,
    open: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired
};

export default MediaUploadErrorDialog;
