import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { createPrivateActorBasedOnUser } from 'actions/users';
import { setSelectedActor } from 'actions/authentication';
import { handleResponse } from 'actions/actionHelpers';
import strings from 'localization/strings';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import organizationTypes from 'enums/organizationTypes';
import { required } from 'form/validation';
import routes from 'routes';

import PageTitle from 'common/PageTitle';
import ShadowBox from 'common/ShadowBox';
import LoadingWrapper from 'form/LoadingWrapper';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import ButtonContainer from 'common/ButtonContainer';
import Form from 'form/Form';
import { TextField, showErrorOnBlur } from 'mui-rff';

const CreatePrivateActor = () => {
    const isMobile = !useMediaQuery(theme => theme.breakpoints.up('md'));
    const dispatch = useDispatch();
    const history = useHistory();

    const authenticationContext = useSelector(state => state.authentication.context);
    const { user, actors } = authenticationContext;

    const [isLoading, setIsLoading] = useState(false);

    const canCreatePrivateActor = actors.find(o => o.organizationType === organizationTypes.private.key) === undefined;

    const initialValues = {
        mobileTelephone: undefined
    };

    const handleFormSubmit = formValues => {
        setIsLoading(true);
        const createPrivateActorRequest = {
            ...formValues
        };
        dispatch(createPrivateActorBasedOnUser(user.id, createPrivateActorRequest))
            .then(handleResponse(
                response => dispatch(setSelectedActor(response.payload.id))
                    .then(handleResponse(
                        () => {
                            setIsLoading(false);
                            history.push(`${routes.account.settings}?tab=combinedUserActor&privateActorCreated=true`);
                        },
                        () => setIsLoading(false)
                    )),
                () => setIsLoading(false)
            ));
    };

    if(!canCreatePrivateActor) {
        return null;
    }

    const title = strings.accountTabs.createPrivateActor;

    return (
        <>
            <PageTitle>{title}</PageTitle>

            <ShadowBox
                title={title}
            >
                <LoadingWrapper
                    isLoading={isLoading}
                >
                    <Form
                        initialValues={initialValues}
                        onSubmit={handleFormSubmit}
                    >
                        {({ handleSubmit, invalid }) => (
                            <form onSubmit={handleSubmit}>
                                <Typography variant="body1" gutterBottom>
                                    {strings.createPrivateActorDescription}
                                </Typography>
                                <TextField
                                    name="mobileTelephone"
                                    label={strings.phoneNumber}
                                    variant="outlined"
                                    showError={showErrorOnBlur}
                                    required
                                    fieldProps={{ validate: required }}
                                />

                                <ButtonContainer>
                                    <Button
                                        type="submit"
                                        color="primary"
                                        variant="contained"
                                        fullWidth={isMobile}
                                        disabled={invalid}
                                    >
                                        {strings.createPrivateActorButton}
                                    </Button>
                                </ButtonContainer>
                            </form>
                        )}
                    </Form>
                </LoadingWrapper>
            </ShadowBox>
        </>
    );
};

export default CreatePrivateActor;
