import strings from 'localization/strings';

const transactionReportDateTypes = {
    payment: {},
    payoutArrival: {}
};

Object.keys(transactionReportDateTypes).forEach(key => {
    transactionReportDateTypes[key].key = key;
});

strings.addLoadCallback(() => {
    Object.values(transactionReportDateTypes).forEach(o => {
        o.title = strings.transactionReportDateTypes[o.key];
    });
});

export default transactionReportDateTypes;
