import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router';

import AdminContentWrapper from '../../presentational/AdminContentWrapper';
import AdminFormButtons from '../../presentational/AdminFormButtons';
import AdminFormFields from '../../presentational/AdminFormFields';
import AdminFormWrapper from '../../presentational/AdminFormWrapper';
import AdminPage from '../../presentational/AdminPage';
import Button from '@material-ui/core/Button';
import Loader from 'common/Loader';
import Text from '../../../common/Text';
import ReadOnlyField from '../../presentational/ReadOnlyField';
import { getMessageTemplate } from '../../../actions/admin/messageTemplates';
import { handleResponse } from '../../../actions/actionHelpers';
import { Link } from 'react-router-dom';

const MessageTemplate = () => {
    const dispatch = useDispatch();
    const params = useParams();

    const[messageTemplate, setMessageTemplate] = useState(undefined);
    const[isLoading, setIsLoading] = useState(true);
    const {type, messageTemplateId} = params;

    useEffect(() => {
        loadMessageTemplate();
    }, [type, messageTemplateId]);

    const loadMessageTemplate = () => {
      setIsLoading(true);
      dispatch(getMessageTemplate(type, messageTemplateId))
          .then(handleResponse(
              response => {
                  setMessageTemplate(response.payload);
                  setIsLoading(false);
              },
              () => setIsLoading(false)
          ));
    };

    const title = 'Message Template: ' + (messageTemplate ? `${messageTemplateId} (${type})` : 'Loading...');
    const template = Text({ text: undefined, html: messageTemplate });

    return (
        <AdminPage title={title}>
            {
                isLoading && <Loader />
            }
            {
                !isLoading && messageTemplate && (
                    <AdminContentWrapper type="detail">
                        <AdminFormWrapper>
                            <AdminFormFields>
                                <ReadOnlyField label="Message Template">
                                    {template}
                                </ReadOnlyField>
                            </AdminFormFields>
                            <AdminFormButtons>
                                <Button
                                    target="_blank"
                                    href={ `https://poeditor.com/projects/view_terms?id=366189&per_page=20&search=${messageTemplateId}` }
                                    variant="contained"
                                    color="primary"
                                >
                                    Edit
                                </Button>
                                <Button
                                    component={Link}
                                    to="/admin/messageTemplates"
                                    variant="outlined"
                                    color="primary"
                                >
                                    Back
                                </Button>
                            </AdminFormButtons>
                        </AdminFormWrapper>
                    </AdminContentWrapper>
                )
            }
        </AdminPage>
    );
};

export default MessageTemplate;
