import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from 'styles/util';

import Typography from '@material-ui/core/Typography';

const useStyles = makeStyles(({ theme }) => ({
    title: {
        fontWeight: 'bold',
        marginBottom: theme.spacing(1)
    }
}));

const BookingDetailsSectionCaption = ({ children }) => {
    const classes = useStyles();
    return (
        <Typography className={classes.title} variant="body1">
            {children}
        </Typography>
    );
};

BookingDetailsSectionCaption.propTypes = {
    children: PropTypes.string.isRequired
};

export default BookingDetailsSectionCaption;
