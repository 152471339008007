import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from 'styles/util';

import CheckCircleRoundedIcon from '@material-ui/icons/CheckCircleRounded';
import Typography from '@material-ui/core/Typography';

const useStyles = makeStyles(({ theme, colors }) => ({
    list: {
        margin: theme.spacing(0, 0, 2, 0),
        padding: 0,
        '& li': {
            listStyle: 'none',
            lineHeight: '2rem !important',
            display: 'flex',
            alignItems: 'center'
        }
    },
    checkboxIcon: {
        paddingRight: theme.spacing(1),
        display: 'inline',
        color: colors.icon
    }
}));

const ReadOnlyCheckboxList = ({ items, emptyLabel = '-' }) => {
    const classes = useStyles();

    if(items.length === 0) {
        return <Typography variant="body1">{emptyLabel}</Typography>;
    }

    return (
        <ul className={classes.list}>
            {
                items.map(item => (
                    <li key={item}>
                        <CheckCircleRoundedIcon className={classes.checkboxIcon}/>
                        {item}
                    </li>
                ))
            }
        </ul>
    );
};

ReadOnlyCheckboxList.propTypes = {
    items: PropTypes.array.isRequired,
    emptyLabel: PropTypes.string
};

export default ReadOnlyCheckboxList;
