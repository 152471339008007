import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from 'styles/util';
import { Link } from 'react-router-dom';

const useStyles = makeStyles(({ theme, colors, styles }) => ({
    card: {
        borderRadius: `${styles.cardBorderRadius}px`,
        border: `1px solid ${colors.lightGreyBorder ?? colors.mediumGrey}`,
        padding: theme.spacing(2.5),
        color: colors.textColor,
        textDecoration: 'none',
        display: 'flex',
        flexDirection: 'column',
        gap: theme.spacing(2.5),
        height: '100%',
        boxShadow: styles.boxShadow,
        transition: `box-shadow ${styles.transitionEffect}, transform ${styles.transitionEffect}`,
        '&:hover': {
            boxShadow: styles.boxShadowHover,
            transform: styles.transformHover
        }
    }
}));

const CardLink = ({ to, children }) => {
    const classes = useStyles();
    return (
        <Link to={to} className={classes.card}>
            {children}
        </Link>
    );
};

CardLink.propTypes = {
    to: PropTypes.string.isRequired,
    children: PropTypes.node.isRequired
};

export default CardLink;
