import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from 'styles/util';
import { requiredIf } from 'form/validation';
import { useField } from 'react-final-form';
import { getLongDateFormat, formatIsoDate } from 'helpers/DateHelper';
import strings from 'localization/strings';
import storageStatuses from 'enums/storageStatuses';

import Grid from '@material-ui/core/Grid';
import { TextField, showErrorOnBlur } from 'mui-rff';
import MenuItem from '@material-ui/core/MenuItem';
import DatePicker from 'form/DatePicker';
import NullableDatePicker from 'form/NullableDatePicker';

const useStyles = makeStyles(() => ({
    hidden: {
        display: 'none'
    }
}));

const Storage = ({ fieldNamePrefix, validate, visible = true, statusEditable = false }) => {
    const classes = useStyles();
    const className = visible ? undefined : classes.hidden;

    const getName = suffix => suffix ? `${fieldNamePrefix}.${suffix}` : fieldNamePrefix;
    const getValue = suffix => useField(getName(suffix)).input.value;

    const startDate = getValue('startDate');
    const endDate = getValue('endDate');

    const datePickerWidthXs = statusEditable ? 5 : 6;
    const datePickerWidthMd = statusEditable ? 3 : 4;

    return (
        <>
            <Grid item xs={12} md={4} className={className}>
                <TextField
                    name={getName('title')}
                    label={strings.nameOrNumber}
                    variant="outlined"
                    showError={showErrorOnBlur}
                    required={validate}
                    fieldProps={{ validate: requiredIf(validate) }}
                    // we need a key prop, see https://codesandbox.io/s/changing-field-level-validators-zc8ei?fontsize=14&file=/src/index.js:1722-1771
                    key={validate ? 10 : 0}
                />
            </Grid>

            <Grid item xs={datePickerWidthXs} md={datePickerWidthMd} className={className}>
                <DatePicker
                    name={getName('startDate')}
                    label={strings.availableFrom}
                    formatFunc={getLongDateFormat}
                    inputVariant="outlined"
                    showError={showErrorOnBlur}
                    required={validate}
                    fieldProps={{ maxDate: formatIsoDate(endDate), validate: requiredIf(validate) }}
                    // we need a key prop, see https://codesandbox.io/s/changing-field-level-validators-zc8ei?fontsize=14&file=/src/index.js:1722-1771
                    key={validate ? 11 : 1}
                />
            </Grid>

            <Grid item xs={datePickerWidthXs} md={datePickerWidthMd} className={className}>
                <NullableDatePicker
                    name={getName('endDate')}
                    label={strings.availableTo}
                    formatFunc={getLongDateFormat}
                    inputVariant="outlined"
                    nullString={strings.untilFurther}
                    showError={showErrorOnBlur}
                    fieldProps={{ minDate: formatIsoDate(startDate) }}
                />
            </Grid>

            {
                statusEditable &&
                (
                    <Grid item xs={2} md={2} className={className}>
                        <TextField
                            select
                            name={getName('status')}
                            label={strings.status}
                            variant="outlined"
                        >
                            {
                                Object.values(storageStatuses).map(status => (
                                    <MenuItem key={status.key} value={status.key}>
                                        {status.title}
                                    </MenuItem>
                                ))
                            }
                        </TextField>
                    </Grid>
                )
            }
        </>
    );
};

Storage.propTypes = {
    fieldNamePrefix: PropTypes.string.isRequired,
    validate: PropTypes.bool.isRequired,
    visible: PropTypes.bool,
    statusEditable: PropTypes.bool
};

export default Storage;
