import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from 'styles/util';
import { parseIsoDate } from 'helpers/DateHelper';

import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import TimeAgo from 'common/TimeAgo';
import LinkButton from 'common/LinkButton';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Text from 'common/Text';
import Separator from 'common/Separator';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import AttachmentIcon from '@material-ui/icons/Attachment';

const useStyles = makeStyles(({ theme }) => ({
    actionsOuterContainer: {
        marginTop: theme.spacing(2),
        borderTop: '1px solid rgba(0, 0, 0, 0.125)'
    },
    actionsInnerContainer: {
        margin: theme.spacing(2)
    },
    caption: {
        marginBottom: theme.spacing(2)
    },
    actionList: {
        margin: 0,
        padding: 0,
        listStyle: 'none',
        '& li': {
            marginBottom: theme.spacing(2)
        },
    },
    actionType: {
        display: 'inline-block',
    },
    actionMetadata: {
        display: 'inline-block',
        opacity: 0.6,
        marginLeft: theme.spacing(1)
    },
    createdTime: {
        display: 'inline-block'
    }
}));

const getActionTypeString = action => {
    if(action.type === 'messageSent' && action.messageQueueItem) {
        return action.messageQueueItem.receiverAddress
            ? `${action.messageQueueItem.messageType}Sent to ${action.messageQueueItem.receiverAddress}`
            : `${action.messageQueueItem.messageType}Sent`;
    }
    return action.type;
};

const hasAttachments = action => action.messageQueueItem && action.messageQueueItem.attachments && action.messageQueueItem.attachments.length > 0;

const getAttachmentFileName = attachment => {
    const atoms = attachment.url.split('/');
    return decodeURI(atoms[atoms.length - 1] || '');
};

const BookingActions = ({ booking }) => {
    const classes = useStyles();
    const [messageQueueItemDialogOpen, setMessageQueueItemDialogOpen] = useState(false);
    const [selectedAction, setSelectedAction] = useState(undefined);

    const onMessageQueueItemDialogClose = () => {
        setMessageQueueItemDialogOpen(false);
    };

    const onShowMessageQueueItemLinkClicked = action => {
        setSelectedAction(action);
        setMessageQueueItemDialogOpen(true);
    };

    const formatBody = action => {
        if(action.messageQueueItem.messageType === 'sms') {
            return <Text text={action.messageQueueItem.body} />;
        }

        const startBodyStartPos = action.messageQueueItem.body.indexOf('<body');
        let body = action.messageQueueItem.body;
        if(startBodyStartPos > -1) {
            const startBodyEndPos = action.messageQueueItem.body.indexOf('>', startBodyStartPos);
            const endBodyStartPos = action.messageQueueItem.body.indexOf('</body>', startBodyStartPos);
            if(endBodyStartPos > -1) {
                body = action.messageQueueItem.body.substr(startBodyEndPos + 1, endBodyStartPos - startBodyEndPos - 1);
            }
        }
        return <Text component="div" html={body} />;
    };

    if(booking.actions.length === 0) {
        return null;
    }

    const sortedBookingActions = [...booking.actions];
    sortedBookingActions.sort((a, b) => -(parseIsoDate(a.time).getTime() - parseIsoDate(b.time).getTime()));

    return (
        <>
            <Box className={classes.actionsOuterContainer}>
                <Box className={classes.actionsInnerContainer}>
                    <Typography variant="h6" display="block" className={classes.caption}>Actions</Typography>

                    <ul className={classes.actionList}>
                        {
                            sortedBookingActions.map((action, i) =>
                                (
                                    <li key={i}>
                                        <Typography variant="caption" display="block">
                                            <span className={classes.actionType}>
                                                {
                                                    action.messageQueueItem &&
                                                    (
                                                        <LinkButton onClick={() => onShowMessageQueueItemLinkClicked(action)}>{getActionTypeString(action)}</LinkButton>
                                                    )
                                                }
                                                {
                                                    !action.messageQueueItem && getActionTypeString(action)
                                                }
                                            </span>
                                            <Box className={classes.actionMetadata}>
                                                {
                                                    action.userName &&
                                                    (
                                                        <>
                                                            {action.userName}{', '}
                                                        </>
                                                    )
                                                }
                                                <TimeAgo date={action.time} className={classes.createdTime}/>
                                            </Box>
                                        </Typography>
                                    </li>
                                )
                            )
                        }
                    </ul>
                </Box>
            </Box>
            <Dialog fullWidth maxWidth="md" open={messageQueueItemDialogOpen} onClose={onMessageQueueItemDialogClose}>
                <DialogTitle disableTypography>
                    <Typography variant="h5">
                        Message
                    </Typography>
                </DialogTitle>
                <DialogContent>
                    {
                        selectedAction &&
                        (
                            <>
                                {
                                    selectedAction.subject &&
                                    (
                                        <Typography variant="h6" display="block" gutterBottom>{selectedAction.subject}</Typography>
                                    )
                                }
                                {formatBody(selectedAction)}
                                {
                                    hasAttachments(selectedAction) &&
                                    (
                                        <>
                                            <Separator/>
                                            <List>
                                                {
                                                    selectedAction.messageQueueItem.attachments.map(attachment => (
                                                        <ListItem key={attachment.url}>
                                                            <ListItemIcon>
                                                                <AttachmentIcon/>
                                                            </ListItemIcon>
                                                            <ListItemText>
                                                                <a href={attachment.url} target="_blank">{getAttachmentFileName(attachment)}</a>
                                                            </ListItemText>
                                                        </ListItem>
                                                    ))
                                                }
                                            </List>
                                        </>
                                    )
                                }
                            </>
                        )
                    }
                </DialogContent>
                <DialogActions>
                    <Button
                        variant="outlined"
                        color="primary"
                        onClick={onMessageQueueItemDialogClose}
                    >
                        Close
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
};

BookingActions.propTypes = {
    booking: PropTypes.object.isRequired
};

export default BookingActions;
