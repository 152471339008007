import React, { useEffect, useState } from 'react';
import strings from 'localization/strings';
import { useSelector, useDispatch } from 'react-redux';
import { useParams, useLocation } from 'react-router-dom';
import { makeStyles } from 'styles/util';
import { fetchViewStorageSite } from 'actions/viewStorageSites';
import { storeBookingRequestValues } from 'actions/bookingRequest';
import { getFormValues } from 'selectors/bookingRequest';
import storageGroupCategories from 'enums/storageGroupCategories';
import landingPages from 'enums/landingPages';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { isInvalidBookingRequestFormValues, sanitizeBookingRequestFormValues } from 'helpers/SearchHelper';
import { getStorageGroupCategories, getStorageSiteDetailsUrl } from 'helpers/StorageSiteHelper';
import queryString from 'query-string';
import { userAgentIsBot } from 'helpers/ConfigurationHelper';
import { scrollToTop } from 'helpers/BrowserHelper';

import Box from '@material-ui/core/Box';
import ContentBox from 'common/ContentBox';
import StorageSiteDetails from './StorageSiteDetails';
import StorageSiteNotFound from './StorageSiteNotFound';
import Loader from 'common/Loader';
import RelevantStorageSites from 'components/RelevantStorageSites';
import { Redirect } from 'react-router-dom';

const useStyles = makeStyles(({ theme }) => ({
    container: {
        marginBottom: theme.spacing(7)
    },
    relevantStorageSitesContainer: {
        [theme.breakpoints.down('sm')]: {
            padding: theme.spacing(0, 2)
        }
    }
}));

const StorageSiteDetailsPage = () => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const params = useParams();
    const location = useLocation();

    const bookingRequestFormValues = useSelector(getFormValues);

    const [storageSiteNotFound, setStorageSiteNotFound] = useState(undefined);
    const [storageSite, setStorageSite] = useState(undefined);

    const querystringParameters = queryString.parse(location.search);

    useEffect(() => {
        scrollToTop();
        setStorageSite(undefined);

        // make sure that data is not in the past (can happen when loading the site yesterday but navigating here today)
        if(isInvalidBookingRequestFormValues(bookingRequestFormValues)) {
            dispatch(storeBookingRequestValues(sanitizeBookingRequestFormValues(bookingRequestFormValues)));
        }
        dispatch(fetchViewStorageSite(params.storageSiteId, true /* includeOpeningTimeSpansConfiguration */, true /* includeOpeningTimeSpanRules */))
            .then(response => {
                if (response.error) {
                    setStorageSiteNotFound(true);
                } else {
                    if(response.payload.storageGroups.length === 0) {
                        setStorageSiteNotFound(true);
                    } else {
                        setStorageSite(response.payload);
                    }
                }
            });
    }, [params.storageSiteId]);

    const isMobile = !useMediaQuery(theme => theme.breakpoints.up('md'));

    const selectedCategory = storageGroupCategories[querystringParameters.category]; // undefined if no querystring parameter is given

    const getTitle = landingPage => {
        if (isMobile) {
            return landingPage.shortLabel;
        }
        return strings.formatString(landingPage.nearTitle, strings.closeToYou);
    };

    if(storageSite) {
        const showRelevanStorageSites = !isMobile && !userAgentIsBot();

        // redirect to canonical url (storageSiteDetailsExtended) if needed
        const url = getStorageSiteDetailsUrl(storageSite, selectedCategory?.key, { ...querystringParameters, category: undefined });
        if (!url.startsWith(location.pathname)) {
            return <Redirect to={url} />;
        }

        return (
            <Box className={classes.container}>
                <StorageSiteDetails
                    storageSite={storageSite}
                    selectedCategory={selectedCategory}
                    isMobile={isMobile}
                />
                {
                    showRelevanStorageSites && getStorageGroupCategories(storageSite).map(category => (
                        <ContentBox themeMaxWidth="md" className={classes.relevantStorageSitesContainer} key={category.key}>
                            <RelevantStorageSites
                                storageGroupCategory={category}
                                title={getTitle(landingPages[category.key])}
                                linkTo={landingPages[category.key].url}
                                location={storageSite.location}
                                excludeStorageSite={storageSite}
                            />
                        </ContentBox>
                    ))
                }
            </Box>
        );
    }
    if(storageSiteNotFound) {
        return <StorageSiteNotFound/>;
    }
    return <Loader />;
};

export default StorageSiteDetailsPage;
