import { useState, useEffect } from 'react';
import { useAppContext } from 'context/AppContext';
import { userAgentIsBot } from 'helpers/ConfigurationHelper';
import PropTypes from 'prop-types';
import { semverGreaterThan } from 'helpers/EnvironmentHelper';
import environments from 'enums/environments';

const refreshCacheAndReload = () => {
    if (window.caches) {
        // Service worker cache should be cleared with caches.delete()
        window.caches.keys()
        .then(names => {
            for (const name of names) {
                window.caches.delete(name);
            }
        });
    }

    // delete browser cache and hard reload
    window.location.reload(true);
};

// see https://dev.to/flexdinesh/cache-busting-a-react-app-22lk
const CacheBuster = ({ enabled = true, children }) => {
    const { appContext } = useAppContext();
    const useCacheBusting = enabled && appContext.environment !== environments.local.key && !userAgentIsBot() && window.location.hostname !== 'localhost';
    const [isLoading, setIsLoading] = useState(useCacheBusting);
    const [isLatestVersion, setIsLatestVersion] = useState(!useCacheBusting);

    useEffect(() => {
        const time = new Date().getTime();
        if(useCacheBusting) {
            fetch(`/meta.json?t=${time}`) // use time parameter to avoid caching
                .then(response => response.json())
                .then(meta => {
                    const latestVersion = meta.version;
                    const currentVersion = appContext.appVersion;
                    const shouldForceRefresh = semverGreaterThan(latestVersion, currentVersion);
                    setIsLatestVersion(!shouldForceRefresh);
                    setIsLoading(false);
                });
        }
    }, []);

    return children({ isLoading, isLatestVersion, refreshCacheAndReload });
};

CacheBuster.propTypes = {
    enabled: PropTypes.bool,
    children: PropTypes.func.isRequired
};

export default CacheBuster;
