import React from 'react';
const versionHistoryItems = [
    {
        version: '4.5.0',
        date: '2021-02-19',
        description: <>Support for annual price adjustments. Prices are updated with a given percentage (positive, or &ndash; less likely &ndash; negative) at a given date each year. This information is edited under storage site advertisements, step 3.</>
    },
    {
        version: '4.6.0',
        date: '2021-02-19',
        description: 'Sending booking lock codes on the day of check-in, at 07:00 UTC, by email and SMS. Added instructions for how to add a home screen link on the booking lock code page.'
    },
    {
        version: '4.7.0',
        date: '2021-02-22',
        description: 'Showing image in fullscreen in details page. Cropping dialog shown after uploading images in edit storage site advertisements.'
    },
    {
        version: '4.8.0',
        date: '2021-03-02',
        description: 'Showing personal identity numbers in owner receipts if the OwnerConfiguration.ExposeTenantPersonalIdentityInformation flag is set.'
    },
    {
        version: '4.9.0',
        date: '2021-03-05',
        description: 'Added an "About the owner" text box in the owner profile settings. The text is shown in every storage site advertisement for the owner.'
    },
    {
        version: '4.10.0',
        date: '2021-03-08',
        description: 'Added storage site section in backoffice. Possible to search storage sites by title and owner name, and to click on a storage site to log in as the owner and edit it.'
    },
    {
        version: '4.11.0',
        date: '2021-03-11',
        description: 'Ceiling height now on storage group level instead of storage site level. Improved structure in profile editor, grouping owner settings in a separate section shown when ticking an "I\'m an owner" checkbox.'
    },
    {
        version: '4.12.0',
        date: '2021-03-12',
        description: 'Support for multiple Telkey lock codes per storage group.'
    },
    {
        version: '4.13.0',
        date: '2021-03-12',
        description: 'User section in backoffice: support for parent companies and notes.'
    },
    {
        version: '4.14.0',
        date: '2021-03-16',
        description: 'Sending check-out summary messages to owners that have bookings ending today at 8:00 each day.'
    },
    {
        version: '4.15.0',
        date: '2021-03-19',
        description: 'Search distance adjustment feature makes it possible to prioritize storage sites in the search results. Capacity utilization report in backoffice moved to Reports section.'
    },
    {
        version: '4.16.0',
        date: '2021-03-29',
        description: 'Many small improvements: scroll to map link, location required for search, remove indoor/outdoor filter for store houses, etc.'
    },
    {
        version: '4.17.0',
        date: '2021-03-29',
        description: 'Direct payment of bookings for storage sites where superadmins have activated this feature.'
    },
    {
        version: '4.18.0',
        date: '2021-03-30',
        description: 'Introduced search priority value for storage site. Refund possibility for recently paid bookings in backoffice.'
    },
    {
        version: '4.19.0',
        date: '2021-03-30',
        description: 'Showing messages sent between tenant and owner in backoffice booking view.'
    },
    {
        version: '4.20.0',
        date: '2021-03-31',
        description: 'Book again next season feature. Logging search queries and number of storage site views.'
    },
    {
        version: '4.21.0',
        date: '2021-04-08',
        description: 'Added Facebook data feed.'
    },
    {
        version: '4.22.0',
        date: '2021-04-12',
        description: 'New booking message sending flow and deadlines. Introduced booking action logs.'
    },
    {
        version: '4.23.0',
        date: '2021-04-12',
        description: 'Booking confirmation and initial payment deadlines can be edited in backoffice.'
    },
    {
        version: '4.24.0',
        date: '2021-04-19',
        description: 'Storing Stripe fees for payments. Showing email attachments in booking message dialog.'
    },
    {
        version: '4.25.0',
        date: '2021-04-20',
        description: 'Introduced partial refunds.'
    },
    {
        version: '4.26.0',
        date: '2021-04-27',
        description: 'Accrual information added to transaction report.'
    },
    {
        version: '4.27.0',
        date: '2021-04-28',
        description: 'Lock codes not related to Telkey on storage site level.'
    },
    {
        version: '4.28.0',
        date: '2021-04-30',
        description: 'Showing number of views for superadmin in storage site listing.'
    },
    {
        version: '4.29.0',
        date: '2021-05-05',
        description: 'Support for geographical regions. Storage sites are connected to the region matching its geographical location. When listing storage sites for a geographical location, storage sites in the region for that location having search priority > 0 will be shown at top.'
    },
    {
        version: '4.30.0',
        date: '2021-05-05',
        description: 'Support for versioned terms of services.'
    },
    {
        version: '4.31.0',
        date: '2021-05-11',
        description: 'New facilities: ground level and elevator.'
    },
    {
        version: '5.0.0',
        date: '2021-06-29',
        description: 'VAT handling rewritten from scratch.'
    },
    {
        version: '5.1.0',
        date: '2021-06-30',
        description: 'User type / user authentication type refactoring.'
    },
    {
        version: '5.2.0',
        date: '2021-07-13',
        description: 'Transaction reports with support for refunds.'
    },
    {
        version: '5.3.0',
        date: '2021-07-13',
        description: 'Storage365 admins can send messages in a conversation between tenant and owner.'
    },
    {
        version: '5.4.0',
        date: '2021-08-09',
        description: 'Error message shown when trying to extend a booking that cannot be extended due to lack of availability.'
    },
    {
        version: '5.5.0',
        date: '2021-08-24',
        description: 'Payouts handled by Storage365 instead of Stripe, with a delay of 15 days.'
    },
    {
        version: '5.6.0',
        date: '2021-08-27',
        description: 'Booking overview for a storage site displayed in an interactive timeline view.'
    },
    {
        version: '5.7.0',
        date: '2021-08-30',
        description: 'Payout listing in backoffice.'
    },
    {
        version: '5.8.0',
        date: '2021-08-30',
        description: 'Title for storage groups.'
    },
    {
        version: '5.9.0',
        date: '2021-08-31',
        description: 'Refunds are now more granular: can refund monthly subscriptions, set arbitrary fees for Storage365 and owner, and handle booking cancellations and subscription endings.'
    },
    {
        version: '5.10.0',
        date: '2021-09-06',
        description: 'Reintroduced capacity utilization view, now also for owners.'
    },
    {
        version: '5.11.0',
        date: '2021-09-29',
        description: 'Added check-in and check-out dates and times for storage sites.'
    },
    {
        version: '5.12.0',
        date: '2021-10-04',
        description: 'Redesign of the conversation section in backoffice.'
    },
    {
        version: '5.13.0',
        date: '2021-10-05',
        description: 'Sending messages in conversations from backoffice.'
    },
    {
        version: '5.14.0',
        date: '2021-10-11',
        description: 'Certified partner checkbox in backoffice.'
    },
    {
        version: '5.15.0',
        date: '2021-10-14',
        description: 'Added report for check-ins and check-outs.'
    },
    {
        version: '6.0.0',
        date: '2021-10-26',
        description: 'New design for start page and for listing and desplaying storage sites.'
    },
    {
        version: '7.0.0',
        date: '2021-12-01',
        description: 'New user model: user accounts are now splitted into users and actors.'
    },
    {
        version: '7.1.0',
        date: '2021-12-15',
        description: 'Changing prices, storage groups, storage allocations and additional services for subscription bookings.'
    },
    {
        version: '8.0.0',
        date: '2022-02-07',
        description: 'Multi-market support.'
    },
    {
        version: '8.1.0',
        date: '2022-03-03',
        description: 'Takeovers: migrating exising tenants for an owner into the Storage365 platform.'
    },
    {
        version: '8.2.0',
        date: '2022-03-23',
        description: 'New data model for prices related to bookings, additional services and discounts.'
    },
    {
        version: '8.3.0',
        date: '2022-03-24',
        description: 'New purchases section in backoffice.'
    },
    {
        version: '8.4.0',
        date: '2022-03-29',
        description: 'New capacity utilization reporting and charts.'
    },
    {
        version: '8.5.0',
        date: '2022-04-07',
        description: 'Booking overview report for owners.'
    },
    {
        version: '8.6.0',
        date: '2022-04-12',
        description: 'Changing tenant for booking in backoffice. Listing of sent takeover emails in backoffice.'
    },
    {
        version: '8.7.0',
        date: '2022-04-14',
        description: 'Changing owner for storage site in backoffice. Introduced allowed booking time modes (period, subscription) for storage site.'
    },
    {
        version: '8.8.0',
        date: '2022-06-14',
        description: 'Some storage site information can now be given only to tenants that have purchased a booking.'
    },
    {
        version: '8.9.0',
        date: '2022-07-04',
        description: 'Specifying whether only check-in, only check-out, or both check-in and check-out are allowed at the opening time spans for a storage site.'
    },
    {
        version: '9.0.0',
        date: '2022-12-14',
        description: 'Multi-branch support. Initial support for storage and office branches.'
    },
    {
        version: '9.1.0',
        date: '2023-01-26',
        description: 'Credit reports and business information using Creditsafe. Modified login user flow for businesses.'
    },
    {
        version: '9.2.0',
        date: '2023-02-07',
        description: 'Introduced Storage365.Engine for periodic tasks and queue items.'
    }
];

export default versionHistoryItems;
