import * as actionTypes from 'actions/admin/bookings';

const initialState = {
    searchResult: {
        isLoading: false
    },
    searchParameters: {}
};

const bookings = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.ADMIN_BOOKINGS_SEARCH:
            return { ...state, searchResult: { isLoading: true } };
        case actionTypes.ADMIN_BOOKINGS_SEARCH_SUCCESS:
            return { ...state, searchResult: action.payload };
        case actionTypes.ADMIN_BOOKINGS_SEARCH_FAILURE:
            return { ...state, searchResult: { isLoading: false } };
        case actionTypes.ADMIN_BOOKINGS_SET_SEARCH_PARAMETERS:
            return { ...state, searchParameters: action.searchParameters };
        default:
            return state;
    }
};

export default bookings;
