import adminBookingStatuses from 'enums/adminBookingStatuses';
import strings from 'localization/strings';
import { getBusinessOrPrivateName } from 'helpers/ActorHelper';
import { formatTimePeriod } from 'helpers/StringHelper';

export const getTabs = (excludeCanceledBookings = false) => {
    const tabs = [
        { key: 'all' },
        { adminBookingStatus: adminBookingStatuses.requested },
        { adminBookingStatus: adminBookingStatuses.confirmed },
        excludeCanceledBookings ? undefined : { adminBookingStatus: adminBookingStatuses.canceled },
        { adminBookingStatus: adminBookingStatuses.notStarted },
        { adminBookingStatus: adminBookingStatuses.active },
        { adminBookingStatus: adminBookingStatuses.subscriptionEnding },
        { adminBookingStatus: adminBookingStatuses.ended }
    ].filter(o => o);

    tabs.forEach(tab => {
        tab.key = tab.key || tab.adminBookingStatus.key;
        tab.filter = booking => tab.key === 'all'
            ? true
            : booking.adminBookingStatus === tab.adminBookingStatus.key;
        tab.label = tab.adminBookingStatus
            ? tab.adminBookingStatus.title
            : strings.all;
    });

    return tabs;
};

export const getSelectedTab = (tabs, filterParams) => tabs.find(tab => tab.key === filterParams.status) || tabs[0];

export const getFilteredBookings = (bookings, filterParams) => {
    if(!bookings) {
        return undefined;
    }

    const adminBookingStatusFilterer = booking => !filterParams.status || filterParams.status === booking.adminBookingStatus;

    const storageSiteIdFilterer = booking => !filterParams.storageSiteId || filterParams.storageSiteId === booking.storageGroup.storageSite.id + '';

    const bookingSearchTextFilterer = booking => {
        if(!filterParams.q) {
            return true;
        }
        const lowerQ = filterParams.q.toLowerCase();
        return (booking.registrationNumber || '').toLowerCase().indexOf(lowerQ) !== -1 ||
            (booking.description ?? '').toLowerCase().indexOf(lowerQ) !== -1 ||
            (getBusinessOrPrivateName(booking.tenantActor) ?? '').toLowerCase().indexOf(lowerQ) !== -1 ||
            (booking.storage && (booking.storage.title || '').toLowerCase().indexOf(lowerQ) !== -1);
    };

    return bookings.filter(booking => adminBookingStatusFilterer(booking) && storageSiteIdFilterer(booking) && bookingSearchTextFilterer(booking));
};

export const isStale = fetchedTime => {
    // we're caching bookings for two minutes
    const now = new Date().getTime();
    if(fetchedTime === undefined) {
        return false;
    }
    return now - fetchedTime > 120000;
};

export const getBookingChipText = (booking) => {
    const prefix = strings.bookingLabelPrefixes[booking.adminBookingStatus];
    const type = booking.subscriptionBooking
        ? strings.subscriptionBookingLowercase
        : strings.periodBookingLowercase;
    return `${prefix} ${type}`;
};

export const getBookingPeriod = (booking) => {
    return formatTimePeriod(booking);
};
