import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from 'styles/util';

import Box from '@material-ui/core/Box';

const useStyles = makeStyles(({ theme, colors }) => ({
    qrCodeContainer: {
        display: 'flex',
        alignItems: 'center',
        gap: theme.spacing(4),
        backgroundColor: colors.mediumGrey,
        borderRadius: theme.spacing(2),
        padding: theme.spacing(3)
    },
    qrCodeImageContainer: {
        flex: '0 0 auto',
        backgroundColor: colors.white,
        borderRadius: theme.spacing(1),
        padding: theme.spacing(1),
        lineHeight: 0
    },
    qrCode: {
        width: '9em'
    },
    link: {
        flex: '1 1 auto',
        wordBreak: 'break-all'
    }
}));

const QrCodeContainer = ({ qrCode, text, url }) => {
    const classes = useStyles();

    const qrCodeSrc = `data:image/png;base64,${qrCode}`;

    return (
        <Box className={classes.qrCodeContainer}>
            <Box className={classes.qrCodeImageContainer}>
                <img src={qrCodeSrc} className={classes.qrCode} />
            </Box>
            <Box className={classes.link}>
                {text}:
                <br/>
                <a href={url}>{url}</a>
            </Box>
        </Box>
    );
};

QrCodeContainer.propTypes = {
    qrCode: PropTypes.string.isRequired,
    text: PropTypes.string.isRequired,
    url: PropTypes.string.isRequired
};

export default QrCodeContainer;
