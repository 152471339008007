import React from 'react';
import PropTypes from 'prop-types';
import { required } from 'form/validation';
import { getLongDateFormat, getMonthAndYearDateFormat } from 'helpers/DateHelper';

import DatePicker from 'form/DatePicker';
import { TextField, Checkboxes, showErrorOnBlur } from 'mui-rff';
import MenuItem from '@material-ui/core/MenuItem';

const createInputField = (reportDefinition, inputField) => {
    if(inputField.visible === false) {
        return undefined;
    }
    const name = `inputFields.${reportDefinition.key}.${inputField.name}`;
    switch(inputField.type) {
        case 'month':
            return (
                <DatePicker
                    name={name}
                    label={inputField.label}
                    helperText={inputField.helperText}
                    inputVariant="outlined"
                    openTo="year"
                    views={['year', 'month']}
                    formatFunc={getMonthAndYearDateFormat}
                />
            );

        case 'date':
            return (
                <DatePicker
                    name={name}
                    label={inputField.label}
                    helperText={inputField.helperText}
                    inputVariant="outlined"
                    formatFunc={getLongDateFormat}
                />
            );

        case 'checkboxes':
            return (
                <Checkboxes
                    name={name}
                    label={inputField.label}
                    helperText={inputField.helperText}
                    data={inputField.data}
                />
            );

        case 'dropdown':
            return (
                <TextField
                    select
                    name={name}
                    label={inputField.label}
                    variant="outlined"
                >
                    {
                        inputField.data.map(o => (
                            <MenuItem key={o.value} value={o.value}>
                                {o.label}
                            </MenuItem>
                        ))
                    }
                </TextField>
            );

        default:
            return (
                <TextField
                    name={name}
                    label={inputField.label}
                    helperText={inputField.helperText}
                    variant="outlined"
                />
            );
    }
};

const ReportInputFields = ({ reportDefinitions, selectedReportDefinition, selectReportLabel }) => {
    return (
        <>
            <TextField
                select
                name="report"
                label={selectReportLabel ?? 'Select report'}
                variant="outlined"
                showError={showErrorOnBlur}
                required
                fieldProps={{ validate: required }}
            >
                {
                    Object.values(reportDefinitions).map(reportDefinition => (
                        <MenuItem key={reportDefinition.key} value={reportDefinition.key}>
                            {reportDefinition.title}
                        </MenuItem>
                    ))
                }
            </TextField>

            {
                selectedReportDefinition && selectedReportDefinition.inputFields.map(inputField => <React.Fragment key={inputField.name}>{createInputField(selectedReportDefinition, inputField)}</React.Fragment>)
            }
        </>
    );
};

ReportInputFields.propTypes = {
    reportDefinitions: PropTypes.object.isRequired,
    selectedReportDefinition: PropTypes.object,
    selectReportLabel: PropTypes.string
};

export default ReportInputFields;
