import React from 'react';
import { useAppContext } from 'context/AppContext';
import { makeStyles } from 'styles/util';
import { useForm } from 'react-final-form';
import strings from 'localization/strings';
import { getGoogleMapsScript } from 'helpers/MapHelper';

import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import { GoogleMap, Marker } from '@react-google-maps/api';

const useStyles = makeStyles(({ theme }) => ({
    mapContainer: {
        width: '100%',
        height: '300px',
        margin: theme.spacing(2, 0, 4, 0)
    }
}));

const DisplayStep2 = () => {
    const classes = useStyles();
    const formValues = useForm().getState().values;
    const values = formValues.steps[2];
    const { appContext } = useAppContext();

    const googleMapsScript = getGoogleMapsScript();

    if (!values.location) {
        return <Typography variant="body1" display="block">{strings.noAddressSpecified}</Typography>;
    }

    const options = {
        streetViewControl: false
    };

    return (
        <Box>
            <Typography variant="body1" display="block" gutterBottom>
                {`${values.street}, ${values.postalCode} ${values.city}`}
            </Typography>
            {
                appContext.usePropertyReferences && values.propertyReference &&
                (
                    <Typography variant="body1" display="block" gutterBottom>
                        {strings.propertyReference}: {values.propertyReference}
                    </Typography>
                )
            }
            {
                googleMapsScript.isLoaded &&
                (
                    <GoogleMap
                        mapContainerClassName={classes.mapContainer}
                        center={values.location}
                        zoom={12}
                        options={options}
                    >
                        <Marker position={values.location} />
                    </GoogleMap>
                )
            }
        </Box>
    );
};

export default DisplayStep2;
