import React from 'react';
import PropTypes from 'prop-types';
import strings from 'localization/strings';

import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';

const DeletePostInvoiceDialog = ({ open, onOk, onCancel }) => {
    return (
        <Dialog open={open} onClose={onCancel}>
            <DialogTitle disableTypography>
                <Typography variant="h5">
                    {strings.postInvoiceConfirmDeleteTitle}
                </Typography>
            </DialogTitle>
            <DialogContent>
                <Typography variant="body1" gutterBottom>
                    {strings.postInvoiceConfirmDeleteBody}
                </Typography>
            </DialogContent>
            <DialogActions>
                <Button
                    variant="contained"
                    color="primary"
                    onClick={onOk}
                >
                    {strings.postInvoiceDelete}
                </Button>
                <Button
                    variant="outlined"
                    color="primary"
                    onClick={onCancel}
                >
                    {strings.cancel}
                </Button>
            </DialogActions>
        </Dialog>
    );
};

DeletePostInvoiceDialog.propTypes = {
    open: PropTypes.bool.isRequired,
    onOk: PropTypes.func.isRequired,
    onCancel: PropTypes.func.isRequired
};

export default DeletePostInvoiceDialog;
