import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from 'styles/util';
import clsx from 'clsx';
import { useAppContext } from 'context/AppContext';
import strings from 'localization/strings';
import { createPaymentFormInitialValues, sanitizePaymentFormValues, stripeFormIsIncomplete, getPurchaseAmountIncludingInvoiceFeeAndRoundingCorrection, getPurchaseItemsIncludingInvoiceFeeAndRoundingCorrection, getAvailableAndCurrentPaymentProviders, getSelectedPaymentProvider } from 'logic/paymentLogic';
import { getContact, getAddress } from 'helpers/ActorHelper';
import contactTypes from 'enums/contactTypes';
import purchaseTypes from 'enums/purchaseTypes';
import purchaseStatuses from 'enums/purchaseStatuses';
import allPaymentProviders from 'enums/paymentProviders';
import addressTypes from 'enums/addressTypes';

import StripeElementsWrapper from 'components/stripe/StripeElementsWrapper';
import Form from 'form/Form';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import PaymentElement from 'components/payment/PaymentElement';
import FullScreenLoader from 'common/FullScreenLoader';
import Amount from 'common/Amount';
import MarginBox from 'common/MarginBox';
import PurchasePaymentSpecification from './PurchasePaymentSpecification';

const useStyles = makeStyles(({theme}) => ({
    actions: {
        flexWrap: 'wrap',
        gap: theme.spacing(2)
    },
    btnSmall: {
        height: '40px',
    },
    halfWidth: {
        flex: `0 0 calc(50% - ${theme.spacing(1)}px)`
    },
    mobileWidth: {
        [theme.breakpoints.down('sm')]: {
            width: 'calc(100% - 40px)',
            margin: '20px'
        }
    }
}));

const PurchasePaymentDialog = ({ booking, purchase, paymentProviders, open, onOk, onCancel }) => {
    const { appContext } = useAppContext();
    const classes = useStyles();
    const [isSaving, setIsSaving] = useState(false);
    const [paymentElementProvider, setPaymentElementProvider] = useState(undefined);
    const [paymentSetupComplete, setPaymentSetupComplete] = useState(false);

    if(!open || !purchase) {
        return null;
    }

    const availablePaymentProviders = getAvailableAndCurrentPaymentProviders(booking)
        .filter(o => !paymentProviders || paymentProviders.includes(o));

    const selectedPaymentProvider = getSelectedPaymentProvider(booking, availablePaymentProviders);

    const createStripePaymentIntentRequest = availablePaymentProviders.includes(allPaymentProviders.stripe.key)
        ? {
            type: purchase.type,
            bookingId: booking.id,
            purchaseId: purchase.id
        }
        : undefined;

    const isFailedSubscriptionPayment =
        purchase.type === purchaseTypes.subscriptionBookingRecurringPurchase.key &&
        (purchase.status === purchaseStatuses.failed.key || purchase.status === purchaseStatuses.actionRequired.key);

    const getAmountToPay = paymentProvider => getPurchaseAmountIncludingInvoiceFeeAndRoundingCorrection(purchase, paymentProvider, appContext);

    const getPurchaseItems = paymentProvider => getPurchaseItemsIncludingInvoiceFeeAndRoundingCorrection(purchase, paymentProvider, appContext);

    const getPayButtonText = paymentProvider => {
        if(!paymentProvider) {
            return strings.pay;
        }

        return strings.formatString(allPaymentProviders[paymentProvider].payAmountLabel, <Amount value={getAmountToPay(paymentProvider)} currency={purchase.currency}/>);
    };

    const handleFormSubmit = values => {
        setIsSaving(true);
        onOk({
            values,
            onCompleted: () => setIsSaving(false)
        });
    };

    const handleSelectedPaymentProvider = (provider) => {
        setPaymentElementProvider(provider);
    };

    const handlePaymentSetupComplete = (isComplete) => {
        setPaymentSetupComplete(isComplete);
    };

    const getTitle = () => {
        if(purchase.type === purchaseTypes.postInvoice.key) {
            return strings.postInvoice;
        }
        if(isFailedSubscriptionPayment) {
            return strings.retrySubscriptionPaymentDialogTitle;
        }
        return purchase.type === purchaseTypes.subscriptionBookingInitialPurchase.key
            ? strings.startSubscriptionBookingPayment
            : strings.pay;
    };

    const getBody = () => {
        return isFailedSubscriptionPayment
            ? strings.retrySubscriptionPaymentDialogBody
            : undefined;
    };

    const initialValues = {
        payment: createPaymentFormInitialValues({
            paymentProvider: selectedPaymentProvider,
            billecta: {
                collectAddress: true,
                name: booking.tenantActor.name,
                email: getContact(booking.tenantActor, [contactTypes.invoiceEmail, contactTypes.mainEmail]),
                address: booking.invoiceAddress ?? getAddress(booking.tenantActor, addressTypes.invoice)
            }
        }, appContext)
    };

    return (
        <StripeElementsWrapper createStripePaymentIntentRequest={createStripePaymentIntentRequest}>
            {
                ({ stripePaymentContext }) => (
                    <Dialog open onClose={onCancel} disableEnforceFocus /* to be able to set focus to elements outside the dialog, e.g. when authorizing credit card in an iframe provided by Stripe */>
                        <FullScreenLoader open={isSaving} />
                        <Form
                            initialValues={initialValues}
                            initialValuesEqual={() => true}
                            onSubmit={values => handleFormSubmit(sanitizePaymentFormValues(values, stripePaymentContext))}
                        >
                            {({ handleSubmit, values }) => (
                                <form onSubmit={handleSubmit}>
                                    <DialogTitle disableTypography>
                                        <Typography variant="h5">
                                            {getTitle()}
                                        </Typography>
                                    </DialogTitle>
                                    <DialogContent>
                                        {
                                            getBody() &&
                                            (
                                                <MarginBox bottom={4}>
                                                    <Typography variant="body1">
                                                        {getBody()}
                                                    </Typography>
                                                </MarginBox>
                                            )
                                        }
                                        <PaymentElement
                                            name="payment"
                                            paymentProviders={availablePaymentProviders}
                                            stripePaymentContext={stripePaymentContext}
                                            onSelectedPaymentProvider={handleSelectedPaymentProvider}
                                            paymentSetupComplete={paymentSetupComplete}
                                        >
                                            {
                                                values.payment.paymentProvider && <PurchasePaymentSpecification booking={booking} purchaseItems={getPurchaseItems(values.payment.paymentProvider)} />
                                            }
                                        </PaymentElement>
                                    </DialogContent>
                                    <DialogActions disableSpacing className={classes.actions}>
                                        {
                                        paymentSetupComplete &&
                                            (
                                                <>
                                                    <Button
                                                        type="submit"
                                                        variant="contained"
                                                        color="primary"
                                                        fullWidth
                                                        disabled={stripeFormIsIncomplete(values.payment, stripePaymentContext)}
                                                    >
                                                        {getPayButtonText(values.payment.paymentProvider)}
                                                    </Button>
                                                    <Button
                                                        className={clsx(classes.btnSmall, classes.halfWidth)}
                                                        type="button"
                                                        variant="contained"
                                                        color="secondary"
                                                        onClick={() => handlePaymentSetupComplete(false)}
                                                    >
                                                        <ArrowBackIcon />{strings.goBack}
                                                    </Button>
                                                </>
                                            )
                                        }
                                        {
                                        !paymentSetupComplete &&
                                            (
                                                <Button
                                                    type="button"
                                                    variant="contained"
                                                    color="primary"
                                                    fullWidth
                                                    onClick={() => handlePaymentSetupComplete(true)}
                                                    disabled={!paymentElementProvider}
                                                >
                                                    {strings.continue}
                                                </Button>
                                            )
                                        }
                                        <Button
                                            className={clsx(classes.btnSmall, {[classes.halfWidth]: paymentSetupComplete})}
                                            variant="contained"
                                            color="secondary"
                                            fullWidth={!paymentSetupComplete}
                                            onClick={onCancel}
                                        >
                                            {strings.cancel}
                                        </Button>
                                    </DialogActions>
                                </form>
                            )}
                        </Form>
                    </Dialog>
                )
            }
        </StripeElementsWrapper>
    );
};

PurchasePaymentDialog.propTypes = {
    booking: PropTypes.object.isRequired,
    purchase: PropTypes.object,
    paymentProviders: PropTypes.array,
    open: PropTypes.bool.isRequired,
    onOk: PropTypes.func.isRequired,
    onCancel: PropTypes.func.isRequired
};

export default PurchasePaymentDialog;
