import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from 'styles/util';

import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';

const useStyles = makeStyles(({ theme }) => ({
    normalWidth: {
        maxWidth: '1200px',
        margin: '0 auto'
    },
    titleContainer: {
        display: 'flex',
        marginBottom: theme.spacing(2),
        width: '100%'
    },
    adminTitle: {
        flex: '1 0 auto'
    },
    actionPanel: {
        flex: '0 0 auto',
        marginLeft: theme.spacing(4)
    }
}));

const AdminPage = ({ children, title, actionPanel, fullWidth = false }) => {
    const classes = useStyles();
    const className = fullWidth ? classes.fullWidth : classes.normalWidth;

    return (
        <Box className={className}>
            <Box className={classes.titleContainer}>
                <Typography variant="h5" className={classes.adminTitle}>
                    {title}
                </Typography>
                {
                    actionPanel &&
                    (
                        <Box className={classes.actionPanel}>
                            {actionPanel}
                        </Box>
                    )
                }
            </Box>

            {children}
        </Box>
    );
};

AdminPage.propTypes = {
    children: PropTypes.oneOfType([
        PropTypes.arrayOf(PropTypes.node),
        PropTypes.node
    ]),
    title: PropTypes.string.isRequired,
    actionPanel: PropTypes.node,
    fullWidth: PropTypes.bool
};

export default AdminPage;
