import React from 'react';
import strings from 'localization/strings';
import { makeStyles } from 'styles/util';
import { scrollToTop } from 'helpers/BrowserHelper';

import Box from '@material-ui/core/Box';
import ArrowForward from '@material-ui/icons/ArrowForward';

const useStyles = makeStyles(({ theme, colors, styles }) => ({
    backToTop: {
        position: 'fixed',
        right: `-${theme.spacing(3)}px`,
        bottom: theme.spacing(12.5),
        textAlign: 'center',
        color: colors.white,
        cursor: 'pointer',
        zIndex: 1,
        [theme.breakpoints.down('sm')]: {
            right: `-${theme.spacing(4)}px`,
            bottom: theme.spacing(10),
        }
    },
    inner: {
        transform: 'rotate(-90deg) !important',
        backgroundColor: colors.primaryColor,
        borderRadius: `${styles.buttonBorderRadius}px`,
        padding: theme.spacing(0.75),
        display: 'inline-flex',
        alignItems: 'center',
        margin: '0 auto',
        '& > *': {
            margin: theme.spacing(0, 1)
        }
    }
}));

const BackToTop = props => {
    const classes = useStyles();
    return (
        <Box className={classes.backToTop} onClick={scrollToTop} {...props}>
            <Box className={classes.inner}>
                <span>{strings.backToTop}</span>
                <ArrowForward />
            </Box>
        </Box>
    );
};

export default BackToTop;
