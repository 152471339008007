import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from 'styles/util';

import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';

const useStyles = makeStyles(({ theme }) => ({
    informationContainer: {
        flex: 1
    },
    item: {
        display: 'flex',
        justifyContent: 'space-between',
        marginBottom: theme.spacing(0.75)
    },
    title: {
        fontWeight: 'bold',
        flex: 0,
        whiteSpace: 'nowrap'
    },
    gap: {
        flex: '1 1 auto',
        borderBottom: '1px dashed rgba(0, 0, 0, 0.33)',
        margin: theme.spacing(0, 0.75, 0.5, 0.75)
    },
    value: {
        flex: 0,
        whiteSpace: 'nowrap'
    },
    separateLineItem: {
        display: 'flex',
        flexDirection: 'column',
        marginBottom: theme.spacing(1.5)
    },
    separateLineTitle: {
        fontWeight: 'bold'
    },
    separateLineValue: {
    }
}));

const Information = ({ title, titleVariant, items, onSeparateLines = false, ...rest }) => {
    const classes = useStyles();
    if(items.length === 0) {
        return null;
    }
    const showTitle = items.filter(o => o.title).length > 0;
    return (
        <Box className={classes.informationContainer} {...rest}>
            <Typography variant={titleVariant} gutterBottom>{title}</Typography>
            {
                items.map((item, i) => {
                    return onSeparateLines
                        ? (
                            <Box key={i} className={classes.separateLineItem}>
                                {
                                    showTitle &&
                                    (
                                        <Box className={classes.separateLineTitle}>{item.title}</Box>
                                    )
                                }
                                <Box className={classes.separateLineValue}>{item.value}</Box>
                            </Box>
                        )
                        :
                        (
                            <Box key={i} className={classes.item}>
                                {
                                    showTitle &&
                                    (
                                        <>
                                            <Box className={classes.title}>{item.title}</Box>
                                            <Box className={classes.gap}/>
                                        </>
                                    )
                                }
                                <Box className={classes.value}>{item.value}</Box>
                            </Box>
                        );
                })
            }
        </Box>
    );
};

Information.propTypes = {
    title: PropTypes.string.isRequired,
    titleVariant: PropTypes.string.isRequired,
    items: PropTypes.array.isRequired,
    containerClasses: PropTypes.string,
    onSeparateLines: PropTypes.bool
};

export default Information;
