import React, { useEffect, useRef } from 'react';
import { useAppContext } from 'context/AppContext';
import strings from 'localization/strings';
import { useScrollToTop } from 'helpers/BrowserHelper';

import PageTitle from 'common/PageTitle';
import Container from 'common/Container';

const CookiePolicyPage = () => {
    const { appContext } = useAppContext();
    useScrollToTop();

    useEffect(() => {
        const script = document.createElement('script');
        script.src = `https://consent.cookiebot.com/${appContext.cookiebot.identifier}/cd.js`;
        script.id = 'CookieDeclaration';
        script.async = true;
        scriptContainerRef.current.appendChild(script);

        return () => {
            scriptContainerRef.current.removeChild(script);
        };
    }, []);

    const scriptContainerRef = useRef();

    return (
        <Container withPageMargins>
            <PageTitle>{strings.cookiePolicy}</PageTitle>
            <h1>{strings.cookiePolicy}</h1>
            <div ref={scriptContainerRef}/>
        </Container>
    );
};

export default CookiePolicyPage;
