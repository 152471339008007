const takeoverStatuses = {
    draft: {},
    active: {},
    completed: {}
};

Object.keys(takeoverStatuses).forEach(key => {
    takeoverStatuses[key].key = key;
});

export default takeoverStatuses;
