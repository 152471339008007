import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from 'styles/util';
import strings from 'localization/strings';

import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Text from 'common/Text';

const useStyles = makeStyles(() => ({
    shortText: {
        fontSize: '12px',
    },
    longText: {
        fontSize: '12px',
        marginTop: '1em'
    }
}));

const MessageConsent = ({ onConsentToggled }) => {
    const classes = useStyles();
    const [checked, setChecked] = useState(false);
    const [longTextVisible, setLongTextVisible] = useState(false);

    const handleChange = event => {
        const value = event.target.checked;

        if(onConsentToggled) {
            onConsentToggled(value);
        }

        setChecked(value);
    };

    const handleReadMoreClick = () => {
        setLongTextVisible(!longTextVisible);
    };

    const getCheckbox = () => (
        <Checkbox
            name="checked"
            checked={checked}
            onChange={handleChange}
        />
    );

    const getLabel = () => (
        <span className={classes.shortText}>
            {strings.messageConsent.shortText}
            {' '}
            <a href="#" onClick={handleReadMoreClick}>{strings.messageConsent.readMore}</a>
        </span>
    );

    return (
        <div className="messageConsent">
            <FormControlLabel
                control={getCheckbox()}
                label={getLabel()}
            />
            {
                longTextVisible && <Text className={classes.longText} component="div" html={strings.messageConsent.longText} />
            }
        </div>
    );
};

MessageConsent.propTypes = {
    onConsentToggled: PropTypes.func,
};

export default MessageConsent;
