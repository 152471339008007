const bookingStatuses = {
    purchased: {
        chipType: 'success'
    },
    canceled: {
        chipType: 'danger'
    },
    confirmed: {
        chipType: 'success'
    },
    requested: {
        chipType: 'warning'
    }
};

Object.keys(bookingStatuses).forEach(key => {
    bookingStatuses[key].key = key;
});

export default bookingStatuses;
