import { RSAA } from 'redux-api-middleware';
import { createRsaaTypes, createHeaders } from './actionHelpers';

export const SEO_GEO_ITEMS_FETCH = 'SEO_GEO_ITEMS_FETCH';
export const SEO_GEO_ITEMS_UPDATE = 'SEO_GEO_ITEMS_UPDATE';

export const fetchSeoGeoItems = storageGroupCategory => {
    return {
        [RSAA]: {
            types: createRsaaTypes(SEO_GEO_ITEMS_FETCH),
            endpoint: `/api/seo/geo-items/${storageGroupCategory}`,
            headers: createHeaders(false),
            method: 'GET'
        }
    };
};

export const updateSeoGeoItems = (storageGroupCategory, seoGeoItems) => ({
    [RSAA]: {
        types: createRsaaTypes(SEO_GEO_ITEMS_UPDATE),
        endpoint: `/api/seo/geo-items/${storageGroupCategory}`,
        headers: createHeaders(true),
        method: 'PUT',
        body: JSON.stringify(seoGeoItems)
    }
});
