import React from 'react';
import PropTypes from 'prop-types';
import strings from 'localization/strings';

import Information from './Information';

const Address = ({ storageSite, ...rest }) => {
    const items = [
        {
            value: storageSite.address
        },
        {
            value: `${storageSite.postalCode} ${storageSite.city}`
        }
    ];

    return (
        <Information title={strings.address} titleVariant="h3" items={items} {...rest} />
    );
};

Address.propTypes = {
    storageSite: PropTypes.object.isRequired
};

export default Address;
