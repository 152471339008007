import { createStorageMuiTheme } from 'styles/storageMuiTheme';
import { createOfficeMuiTheme } from 'styles/officeMuiTheme';
import { storageColors, officeColors } from 'styles/colors';
import { storageStyles, officeStyles } from 'styles/styles';
import { storageFonts, officeFonts } from 'styles/fonts';
import { storageImages, officeImages } from 'styles/images';
import { storageAnimations, officeAnimations } from 'styles/animations';

const branches = {
    storage: {
        colors: storageColors,
        fonts: storageFonts,
        images: storageImages,
        animations: storageAnimations,
        styles: storageStyles,
        createTheme: createStorageMuiTheme
    },
    office: {
        colors: officeColors,
        fonts: officeFonts,
        images: officeImages,
        animations: officeAnimations,
        styles: officeStyles,
        createTheme: createOfficeMuiTheme
    }
};
// more properties will be populated by AppContextProvider, see function enrichBranches

Object.keys(branches).forEach(key => {
    branches[key].key = key;
});

export default branches;

