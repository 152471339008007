import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { makeStyles } from 'styles/util';
import useMediaQuery from '@material-ui/core/useMediaQuery';

import Snackbar from '@material-ui/core/Snackbar';

const SuccessSnackbar = () => {
    const isMobile = !useMediaQuery(theme => theme.breakpoints.up('md'));
    const useStyles = makeStyles(({ theme }) => ({
        container: {
            marginBottom: isMobile
                ? theme.spacing(6)
                : undefined
        }
    }));
    const classes = useStyles();

    const [open, setOpen] = useState(false);
    const [messageToShow, setMessageToShow] = useState(false);
    const timestamp = useSelector(state => state.responses.successTimestamp);
    const message = useSelector(state => state.responses.successResponse ? state.responses.successResponse.meta.message : undefined);

    useEffect(() => {
        if(message) {
            setMessageToShow(message);
            setOpen(true);
        }
    }, [timestamp]);

    const snackbarClose = () => {
        setOpen(false);
    };

    return (
        <Snackbar
            className={classes.container}
            open={open}
            message={messageToShow}
            autoHideDuration={4000}
            onClose={snackbarClose}
        />
    );
};

export default SuccessSnackbar;
