import strings from 'localization/strings';

const storageSiteFacilities = {
    electricity: {},
    water: {},
    heating: {},
    indoor: {},
    outdoor: {},
    alarm: {},
    video: {},
    fenced: {},
    coverage: {},
    groundLevel: {},
    elevator: {},
    coffee: {},
    cleaning: {},
    wifi: {},
    meetingRooms: {},
    kitchen: {},
    printer: {},
    furniture: {},
    digitalAccess: {},
    outdoorIllumination: {}
};

Object.keys(storageSiteFacilities).forEach(key => {
    storageSiteFacilities[key].key = key;
});

strings.addLoadCallback(() => {
    Object.values(storageSiteFacilities).forEach(o => {
        o.title = strings.storageSiteFacilities[o.key];
    });
    storageSiteFacilities.electricity.titleShort = strings.storageSiteFacilities.electricityShort;
});

export default storageSiteFacilities;
