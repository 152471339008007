import React from 'react';
import strings from 'localization/strings';

import { Helmet } from 'react-helmet-async';
import Typography from '@material-ui/core/Typography';
import Container from 'common/Container';

const PageNotFoundPage = () => {
    return (
        <>
            <Helmet defer={false}>
                <title>{strings.headerTitlePageNotFound}</title>
                <meta name="robots" content="noindex" />
            </Helmet>
            <Container withPageMargins>
                <Typography variant="h1" gutterBottom>{strings.pageNotFoundTitle}</Typography>
                <Typography variant="body1">{strings.pageNotFoundMessage}</Typography>
            </Container>
        </>
    );
};

export default PageNotFoundPage;
