import React from 'react';
import PropTypes from 'prop-types';
import { useAppContext } from 'context/AppContext';
import { getAddressLines, getGenericAddressLines } from 'helpers/AddressHelper';
import { required } from 'form/validation';

import { TextField, showErrorOnBlur } from 'mui-rff';
import MenuItem from '@material-ui/core/MenuItem';

const AddressEditor = ({ name, useGenericAddress = false }) => {
    const { appContext } = useAppContext();

    const getField = line => {
        if(line.key === 'countryCode') {
            return (
                <TextField
                    select
                    key={line.key}
                    name={`${name}.${line.key}`}
                    label={line.title}
                    variant="outlined"
                    showError={showErrorOnBlur}
                    required={line.isRequired}
                    fieldProps={line.isRequired ? { validate: required } : undefined}
                >
                    {
                        appContext.countries.map(country => (
                            <MenuItem key={country.code} value={country.code}>
                                {country.name}
                            </MenuItem>
                        ))
                    }
                </TextField>
            );
        }

        return (
            <TextField
                key={line.key}
                name={`${name}.${line.key}`}
                label={line.title}
                variant="outlined"
                showError={showErrorOnBlur}
                required={line.isRequired}
                fieldProps={line.isRequired ? { validate: required } : undefined}
            />
        );
    };

    return (useGenericAddress
            ? getGenericAddressLines()
            : getAddressLines(undefined, appContext))
        .map(line => getField(line));
};

AddressEditor.propTypes = {
    name: PropTypes.string.isRequired,
    useGenericAddress: PropTypes.bool
};

export default AddressEditor;
