import { setApiVersion } from 'actions/versions';

const apiVersionHeaderName = 'X-Api-Version';

const apiResponseMiddleware = store => next => action => {
    // we want to be able to use store.dispatch in actionHelpers.js later on
    action.meta = action.meta || {};
    action.meta.store = store;

    // store API version
    if(isSuccessfulResponse(action.type) && action.meta.response?.headers.has(apiVersionHeaderName)) {
        const currentApiVersion = store.getState().versions.apiVersion;
        const apiVersionFromHeader = action.meta.response.headers.get(apiVersionHeaderName);
        if(apiVersionFromHeader && apiVersionFromHeader !== currentApiVersion) {
            store.dispatch(setApiVersion(apiVersionFromHeader));
        }
    }

    return next(action);
};

export const isSuccessfulResponse = type => {
    return type.substr(type.length - 8) === '_SUCCESS';
};

export default apiResponseMiddleware;
