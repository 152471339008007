import React from 'react';
import { useAppContext } from 'context/AppContext';
import { makeStyles } from 'styles/util';
import { useForm } from 'react-final-form';
import strings from 'localization/strings';
import { convertToNumber, formatYesNo, formatPercentage } from 'helpers/StringHelper';
import { getAvailablePaymentProviders } from 'logic/paymentLogic';
import paymentProviders from 'enums/paymentProviders';

import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import Number from 'common/Number';

const useStyles = makeStyles(({ theme }) => ({
    marginBottom: {
        margin: theme.spacing(0, 0, 4, 0)
    }
}));

const DisplayStep6 = () => {
    const { appContext } = useAppContext();
    const classes = useStyles();
    const formValues = useForm().getState().values;
    const values = formValues.steps[6];

    return (
        <Box>
            {
                convertToNumber(values.commissionRate) !== undefined &&
                (
                    <>
                        <Typography variant="h6" gutterBottom>
                            {strings.commissionRate}
                        </Typography>

                        <Typography variant="body1" className={classes.marginBottom}>
                            {formatPercentage(convertToNumber(values.commissionRate) / 100, appContext)}
                        </Typography>
                    </>
                )
            }
            <Typography variant="h6" gutterBottom>
                {strings.storageSiteIsSearchable}
            </Typography>

            <Typography variant="body1" className={classes.marginBottom}>
                {formatYesNo(values.searchable)}
            </Typography>

            <Typography variant="h6" gutterBottom>
                {strings.searchPriority}
            </Typography>

            <Typography variant="body1" className={classes.marginBottom}>
                { values.searchPriority || 0 }
            </Typography>

            <Typography variant="h6" gutterBottom>
                {strings.regionSearchPriority}
            </Typography>

            <Typography variant="body1" className={classes.marginBottom}>
                { values.regionSearchPriority || 0 }
            </Typography>

            <Typography variant="h6" gutterBottom>
                {strings.distanceSearchAdjustment}
            </Typography>

            <Typography variant="body1" className={classes.marginBottom}>
                {
                    strings.formatString(strings.xKm, <Number value={convertToNumber(values.distanceSearchAdjustment) || 0}/>)
                }
            </Typography>

            <Typography variant="h6" gutterBottom>
                {strings.paymentProvidersTitle}
            </Typography>

            {
                getAvailablePaymentProviders(appContext).length > 1 &&
                (
                    <Typography variant="body1" className={classes.marginBottom}>
                        {
                            values.paymentProviders.length === 0 && '-'
                        }
                        {
                            values.paymentProviders.length > 0 && values.paymentProviders.map(o => paymentProviders[o].shortTitle).join(', ')
                        }
                    </Typography>
                )
            }

            <Typography variant="h6" gutterBottom>
                {strings.allowImmediateBookings}
            </Typography>

            <Typography variant="body1" className={classes.marginBottom}>
                {formatYesNo(values.allowImmediateBookings)}
            </Typography>

            <Typography variant="h6" gutterBottom>
                {strings.considerBookingsAsServices}
            </Typography>

            <Typography variant="body1" className={classes.marginBottom}>
                {formatYesNo(values.considerBookingsAsServices)}
            </Typography>

            {
                values.roundReportEmails &&
                (
                    <>
                        <Typography variant="h6" gutterBottom>
                            {strings.roundReportEmails}
                        </Typography>

                        <Typography variant="body1" className={classes.marginBottom}>
                            {values.roundReportEmails}
                        </Typography>
                    </>
                )
            }

            {
                values.parakeyDomainId &&
                (
                    <>
                        <Typography variant="h6" gutterBottom>
                            {strings.parakeyDomain}
                        </Typography>

                        <Typography variant="body1" className={classes.marginBottom}>
                            {values.parakeyDomainId}
                        </Typography>
                    </>
                )
            }
        </Box>
    );
};

export default DisplayStep6;
