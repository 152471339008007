import { RSAA } from 'redux-api-middleware';
import { createRsaaTypes, createHeaders } from './actionHelpers';

export const APPCONTEXT_FETCH_APPCONTEXT_INFORMATION = 'APPCONTEXT_FETCH_APPCONTEXT_INFORMATION';

export const fetchAppContextInformation = () => ({
    [RSAA]: {
        types: createRsaaTypes(APPCONTEXT_FETCH_APPCONTEXT_INFORMATION),
        endpoint: '/api/appContext/information',
        headers: createHeaders(false),
        method: 'GET'
    }
});
