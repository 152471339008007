import { RSAA } from 'redux-api-middleware';
import { createRsaaTypes, createHeaders } from './actionHelpers';
import queryString from 'query-string';

export const STORAGESITES_FETCH = 'STORAGESITES_FETCH';
export const STORAGESITES_CREATE = 'STORAGESITES_CREATE';
export const STORAGESITES_UPDATE = 'STORAGESITES_UPDATE';
export const STORAGESITES_FETCH_BOOKINGS = 'STORAGESITES_FETCH_BOOKINGS';
export const STORAGESITES_FETCH_LOCK_CODE_REQUESTS = 'STORAGESITES_FETCH_LOCK_CODE_REQUESTS';
export const STORAGESITES_UPDATE_LOCK_CONFIGURATIONS = 'STORAGESITES_UPDATE_LOCK_CONFIGURATIONS';

export const fetchStorageSite = (storageSiteId, includeBookingCalendarFrom, includeBookingCalendarTo, includeStorageGroupBookingCalendars, includeOpeningTimeSpansConfiguration, bookingCalendarBookingIdToExclude) => {
    const searchParameters = {
        includeBookingCalendarFrom,
        includeBookingCalendarTo,
        includeStorageGroupBookingCalendars,
        includeOpeningTimeSpansConfiguration,
        bookingCalendarBookingIdToExclude
    };
    return {
        [RSAA]: {
            types: createRsaaTypes(STORAGESITES_FETCH),
            endpoint: `/api/storageSites/${storageSiteId}?${queryString.stringify(searchParameters)}`,
            headers: createHeaders(false),
            method: 'GET'
        }
    };
};

export const createStorageSite = createStorageSiteRequest => ({
    [RSAA]: {
        types: createRsaaTypes(STORAGESITES_CREATE),
        endpoint: '/api/storageSites',
        headers: createHeaders(true),
        method: 'POST',
        body: JSON.stringify(createStorageSiteRequest)
    }
});

export const updateStorageSite = (storageSiteId, updateStorageSiteRequest) => ({
    [RSAA]: {
        types: createRsaaTypes(STORAGESITES_UPDATE),
        endpoint: `/api/storageSites/${storageSiteId}`,
        headers: createHeaders(true),
        method: 'PUT',
        body: JSON.stringify(updateStorageSiteRequest)
    }
});

export const fetchStorageSiteBookings = (storageSiteId, from, to) => {
    const qs = queryString.stringify({
        from,
        to
    });
    return {
        [RSAA]: {
            types: createRsaaTypes(STORAGESITES_FETCH_BOOKINGS),
            endpoint: `/api/storageSites/${storageSiteId}/bookings?${qs}`,
            headers: createHeaders(false),
            method: 'GET'
        }
    };
};

export const fetchStorageSiteLockCodeRequests = (storageSiteId, from, to) => {
    const qs = queryString.stringify({
        from,
        to
    });
    return {
        [RSAA]: {
            types: createRsaaTypes(STORAGESITES_FETCH_LOCK_CODE_REQUESTS),
            endpoint: `/api/storageSites/${storageSiteId}/lockCodeRequests?${qs}`,
            headers: createHeaders(false),
            method: 'GET'
        }
    };
};

export const updateStorageSiteLockConfigurations = (storageSiteId, updateStorageSiteLockConfigurationsRequest) => ({
    [RSAA]: {
        types: createRsaaTypes(STORAGESITES_UPDATE_LOCK_CONFIGURATIONS),
        endpoint: `/api/storageSites/${storageSiteId}/lockConfigurations`,
        headers: createHeaders(true),
        method: 'PUT',
        body: JSON.stringify(updateStorageSiteLockConfigurationsRequest)
    }
});
