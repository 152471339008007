import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useAppContext } from 'context/AppContext';
import { useSelector } from 'react-redux';
import { makeStyles } from 'styles/util';

import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Form from 'form/Form';
import { TextField } from 'mui-rff';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import MenuItem from '@material-ui/core/MenuItem';
import LinkButton from 'common/LinkButton';

const useStyles = makeStyles(({ theme }) => ({
    market: {
        paddingRight: theme.spacing(2)
    }
}));

const MarketSelector = ({ onMarketChange }) => {
    const classes = useStyles();

    const { appContext, markets } = useAppContext();

    if(markets.length < 2) {
        return null;
    }

    const { user } = useSelector(state => state.authentication.context);

    const [ dialogOpen, setDialogOpen ] = useState(false);

    const initialValues = {
        marketId: appContext.marketId
    };

    const marketString = `Market: ${appContext.marketName}`;

    const handleOpenDialogButtonClick = () => {
        setDialogOpen(true);
    };

    const handleDialogOk = formValues => {
        setDialogOpen(false);
        if(formValues.marketId !== appContext.marketId) {
            onMarketChange(markets.find(o => o.id === formValues.marketId));
        }
    };

    const handleDialogCancel = () => {
        setDialogOpen(false);
    };

    if(user.superAdminMarketIds.length === 1) {
        return <>{marketString}</>;
    }

    return (
        <>
            <Box component="span" className={classes.market}>{marketString}</Box>

            <LinkButton
                onClick={handleOpenDialogButtonClick}
            >
                Change
            </LinkButton>
            <Dialog fullWidth maxWidth="sm" open={dialogOpen} onClose={handleDialogCancel}>
                <Form
                    initialValues={initialValues}
                    onSubmit={handleDialogOk}
                >
                    {({ handleSubmit }) => (
                        <form onSubmit={handleSubmit}>
                            <DialogTitle disableTypography>
                                <Typography variant="h5">
                                    Select market
                                </Typography>
                            </DialogTitle>
                            <DialogContent>
                                <TextField
                                    select
                                    name="marketId"
                                    variant="outlined"
                                >
                                    {
                                        markets
                                            .filter(o => user.superAdminMarketIds.includes(o.id))
                                            .map(o => (
                                                <MenuItem key={o.id} value={o.id}>
                                                    {o.name}
                                                </MenuItem>
                                            ))
                                    }
                                </TextField>
                            </DialogContent>
                            <DialogActions>
                                <Button
                                    type="submit"
                                    variant="contained"
                                    color="primary"
                                >
                                    OK
                                </Button>
                                <Button
                                    variant="outlined"
                                    color="primary"
                                    onClick={handleDialogCancel}
                                >
                                    Cancel
                                </Button>
                            </DialogActions>
                        </form>
                    )}
                </Form>
            </Dialog>
        </>
    );
};

MarketSelector.propTypes = {
    onMarketChange: PropTypes.func.isRequired
};

export default MarketSelector;
