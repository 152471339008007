import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useAppContext } from 'context/AppContext';
import { useSelector } from 'react-redux';
import rentalTypes from 'enums/rentalTypes';
import { requiredIf, mustBeGreaterThan, mustBeGreaterThanIf, mustBeNullOrGreaterThan, mustBeNullOrGreaterThanOrEqualToIf } from 'form/validation';
import { useForm, useField } from 'react-final-form';
import { makeStyles } from 'styles/util';
import { createFormDataForNewStorage } from 'logic/editStorageSiteLogic';
import { getLongDateFormat, formatIsoDate } from 'helpers/DateHelper';
import { calculateArea } from 'helpers/StorageAreaCalculator';
import { formatArea } from 'helpers/StringHelper';
import { createEndAdornment } from 'helpers/FormHelper';
import storageGroupCategories from 'enums/storageGroupCategories';
import storageGroupStatuses from 'enums/storageGroupStatuses';
import storageStatuses from 'enums/storageStatuses';
import strings from 'localization/strings';

import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import { TextField, Checkboxes, showErrorOnBlur } from 'mui-rff';
import DatePicker from 'form/DatePicker';
import NullableDatePicker from 'form/NullableDatePicker';
import MenuItem from '@material-ui/core/MenuItem';
import GridSeparator from 'common/GridSeparator';
import StorageList from './StorageList';
import LockConfigurationEditor from './LockConfigurationEditor';

const useStyles = makeStyles(() => ({
    hidden: {
        display: 'none'
    },
    heading: {
        paddingBottom: '0 !important',
    }
}));

const StorageGroupEditor = ({ fieldNamePrefix, storageSiteFormData, category, storageSiteExists = false }) => {
    const classes = useStyles();
    const form = useForm();
    const { appContext } = useAppContext();
    const authenticationContext = useSelector(state => state.authentication.context);

    const considerFixedAreaAsWholeSite = category.key === storageGroupCategories.buildingsAndLand.key;

    const getName = suffix => suffix ? `${fieldNamePrefix}.${suffix}` : fieldNamePrefix;
    const getValue = suffix => useField(getName(suffix)).input.value;
    const parakeyDomainId = storageSiteFormData.steps[6].parakeyDomainId;

    const title = getValue('title');
    const rentalType = getValue('rentalType');
    const quantity = getValue('quantity');
    const enumerateStorages = getValue('enumerateStorages');
    const existingStorages = getValue('storages');
    const startDate = getValue('startDate');
    const endDate = getValue('endDate');
    const allowCustomArea = getValue('allowCustomArea');
    const storageLength = getValue('storageLength');
    const storageWidth = getValue('storageWidth');
    const showDeletedStoragesCheckboxVisible = existingStorages && existingStorages.filter(s => s.status === storageStatuses.deleted.key).length > 0 && enumerateStorages;
    const hasOnlyActiveStorages = existingStorages && existingStorages.filter(s => s.status !== storageStatuses.active.key).length > 0 && enumerateStorages;

    useEffect(() => {
        handleQuantityChange();
    }, [enumerateStorages]);

    const handleQuantityChange = () => {
        // update the array holding the storages
        let quantityAfterValidation;
        if(enumerateStorages) {
            // we shouldn't use more than 100 storages
            quantityAfterValidation = Math.max(0, Math.min(100, quantity));
            if(quantity !== quantityAfterValidation) {
                form.change(getName('quantity'), quantityAfterValidation);
            }
        } else {
            quantityAfterValidation = 1;
        }

        const newStorages = [];
        for(let i = 0; i < quantityAfterValidation; i++) {
            const storageTitle = strings.formatString(category.storageEnumerationPrefix, i + 1);
            const newStorage = existingStorages[i]
                ? { ...existingStorages[i] }
                : createFormDataForNewStorage();
            if(enumerateStorages && !newStorage.title) {
                newStorage.title = storageTitle;
            } else if(!enumerateStorages) {
                newStorage.title = undefined;
            }
            newStorages.push(newStorage);
        }
        form.change(getName('storages'), newStorages);
    };

    const handleTitleFocus = event => {
        if(!title) {
            form.change(getName('title'), ' ' + strings.squareMeter);
            // need to delay setting the caret position a bit to let the new title  be persisted
            window.setTimeout(() => {
                event.target.setSelectionRange(0, 0);
            }, 100);
        }
    };
    const handleTitleBlur = () => {
        if(title === ' ' + strings.squareMeter) {
            form.change(getName('title'), '');
        }
    };

    const isCustomArea = rentalType === rentalTypes.customArea.key;
    const isFixedArea = rentalType !== rentalTypes.customArea.key;
    const hasQuantity = isFixedArea && category.allowStorageQuantity;
    const visibleForCustomArea = isCustomArea ? undefined : classes.hidden;
    const visibleForQuantity = hasQuantity ? undefined : classes.hidden;
    const isNonEnumeratedStorages = !enumerateStorages;
    const visibleForNonEnumeratedStorages = isNonEnumeratedStorages ? undefined : classes.hidden;
    const enumerateStoragesCheckboxVisible = isFixedArea && category.allowStorageEnumeration;
    const storageListVisible = isFixedArea && !!enumerateStorages && existingStorages.length > 0;
    const areaCalculation = calculateArea(storageLength, storageWidth);
    const showLockConfiguration = authenticationContext.isSuperAdminOrImpersonated;
    const storageStatusEditable = !hasOnlyActiveStorages || authenticationContext.isSuperAdminOrImpersonated;

    let validationKeyCount = 0;

    const showStoragesAreRectangularCheckbox = isFixedArea && category.key !== storageGroupCategories.vehicle.key;

    const storagesAreRectangular = !!getValue('storagesAreRectangular');

    const visibleForOnlyAreaMode = !storagesAreRectangular && showStoragesAreRectangularCheckbox
        ? undefined
        : classes.hidden;

    const visibleForLengthAndWidthMode = (storagesAreRectangular && showStoragesAreRectangularCheckbox) || category.key === storageGroupCategories.vehicle.key
        ? undefined
        : classes.hidden;

    return (
        <Box>
            <Grid container spacing={2}>
                <Grid item xs={12} md={6}>
                    <TextField
                        select
                        name={getName('rentalType')}
                        label={strings.size}
                        variant="outlined"
                    >
                        <MenuItem value={considerFixedAreaAsWholeSite ? rentalTypes.wholeSite.key : rentalTypes.fixedArea.key}>{rentalTypes.fixedArea.description}</MenuItem>
                        {
                            allowCustomArea && <MenuItem value={rentalTypes.customArea.key}>{rentalTypes.customArea.description}</MenuItem>
                        }
                    </TextField>
                </Grid>

                <Grid item xs={12} md={6}>
                    <TextField
                        name={getName('title')}
                        label={strings.title}
                        variant="outlined"
                        helperText={strings.storageGroupTitleHelperText}
                        onFocus={handleTitleFocus}
                        onBlur={handleTitleBlur}
                        maxLength={64}
                    />
                </Grid>

                <GridSeparator />

                {
                    showStoragesAreRectangularCheckbox &&
                    (
                        <Grid item xs={12}>
                            <Checkboxes
                                name={getName('storagesAreRectangular')}
                                data={
                                    { label: category.storagesAreRectangularLabel, value: true }
                                }
                            />
                        </Grid>
                    )
                }

                <Grid item xs={4} className={visibleForLengthAndWidthMode}>
                    <TextField
                        name={getName('storageLength')}
                        label={strings.length}
                        variant="outlined"
                        showError={showErrorOnBlur}
                        helperText={strings.lengthHelperText}
                        required={isFixedArea && storagesAreRectangular}
                        fieldProps={{ validate: mustBeGreaterThanIf(isFixedArea && storagesAreRectangular, 0) }}
                        InputProps={createEndAdornment(strings.meterAbbreviated)}
                        // we need a key prop, see https://codesandbox.io/s/changing-field-level-validators-zc8ei?fontsize=14&file=/src/index.js:1722-1771
                        key={isFixedArea && storagesAreRectangular ? validationKeyCount++ : -(validationKeyCount++)}
                    />
                </Grid>
                <Grid item xs={4} className={visibleForLengthAndWidthMode}>
                    <TextField
                        name={getName('storageWidth')}
                        label={strings.width}
                        variant="outlined"
                        showError={showErrorOnBlur}
                        helperText={strings.widthHelperText}
                        required={isFixedArea && storagesAreRectangular}
                        fieldProps={{ validate: mustBeGreaterThanIf(isFixedArea && storagesAreRectangular, 0) }}
                        InputProps={createEndAdornment(strings.meterAbbreviated)}
                        // we need a key prop, see https://codesandbox.io/s/changing-field-level-validators-zc8ei?fontsize=14&file=/src/index.js:1722-1771
                        key={isFixedArea && storagesAreRectangular ? validationKeyCount++ : -(validationKeyCount++)}
                    />
                </Grid>
                <Grid item xs={4} className={visibleForLengthAndWidthMode}>
                    <TextField
                        name={getName('calculatedStorageArea')}
                        value={areaCalculation.adjustedArea ? formatArea(areaCalculation.adjustedArea, appContext, { maxNumberOfDecimals: 1 }) : ''}
                        label={strings.area}
                        variant="outlined"
                        helperText={strings.areaHelperText}
                        disabled
                    />
                </Grid>

                <Grid item xs={12} className={visibleForOnlyAreaMode}>
                    <TextField
                        name={getName('storageArea')}
                        label={strings.area}
                        variant="outlined"
                        showError={showErrorOnBlur}
                        helperText={strings.areaHelperText}
                        required={isFixedArea && !storagesAreRectangular}
                        fieldProps={{ validate: mustBeGreaterThanIf(isFixedArea && !storagesAreRectangular, 0) }}
                        InputProps={createEndAdornment(strings.squareMeter)}
                        // we need a key prop, see https://codesandbox.io/s/changing-field-level-validators-zc8ei?fontsize=14&file=/src/index.js:1722-1771
                        key={isFixedArea && !storagesAreRectangular ? validationKeyCount++ : -(validationKeyCount++)}
                    />
                </Grid>

                <Grid item xs={6} md={4}>
                    <TextField
                        name={getName('ceilingHeight')}
                        label={strings.ceilingHeight}
                        variant="outlined"
                        showError={showErrorOnBlur}
                        helperText={strings.ceilingHeightHelperText}
                        fieldProps={{ validate: mustBeNullOrGreaterThan(0) }}
                        InputProps={createEndAdornment(strings.meterAbbreviated)}
                    />
                </Grid>
                <Grid item xs={6} md={4} className={visibleForCustomArea}>
                    <TextField
                        name={getName('minBookableArea')}
                        label={strings.smallestArea}
                        variant="outlined"
                        showError={showErrorOnBlur}
                        helperText={strings.smallestAreaHelperText}
                        fieldProps={{ validate: mustBeNullOrGreaterThanOrEqualToIf(isCustomArea, 0) }}
                        InputProps={createEndAdornment(strings.squareMeter)}
                        // we need a key prop, see https://codesandbox.io/s/changing-field-level-validators-zc8ei?fontsize=14&file=/src/index.js:1722-1771
                        key={isCustomArea ? validationKeyCount++ : -(validationKeyCount++)}
                    />
                </Grid>
                <Grid item xs={12} md={4}>
                    <TextField
                        name={getName('price')}
                        label={isFixedArea ? strings.pricePerMonth : strings.pricePerMonthAndSquareMeter}
                        variant="outlined"
                        showError={showErrorOnBlur}
                        helperText={strings.enterAmountExcludingVat}
                        required
                        fieldProps={{ validate: mustBeGreaterThan(0) }}
                        InputProps={createEndAdornment(appContext.currency.label)}
                    />
                </Grid>

                <Grid item xs={12} md={4} className={visibleForQuantity}>
                    <TextField
                        name={getName('quantity')}
                        label={strings.quantity}
                        type="number"
                        variant="outlined"
                        showError={showErrorOnBlur}
                        helperText={strings.quantityHelperText}
                        required={hasQuantity}
                        fieldProps={{ validate: mustBeGreaterThanIf(hasQuantity, 0) }}
                        // we need a key prop, see https://codesandbox.io/s/changing-field-level-validators-zc8ei?fontsize=14&file=/src/index.js:1722-1771
                        key={isFixedArea ? validationKeyCount++ : -(validationKeyCount++)}
                        onBlur={handleQuantityChange}
                    />
                </Grid>

                {
                    showLockConfiguration &&
                    (
                        <>
                            <GridSeparator />

                            <Grid item xs={12}>
                                <Typography variant="h6" display="block" gutterBottom>
                                    {strings.lockConfigurations}
                                </Typography>
                                <LockConfigurationEditor
                                    name={getName('lockConfigurations')}
                                    parakeyDomainId={parakeyDomainId}
                                />
                            </Grid>
                        </>
                    )
                }

                <GridSeparator className={visibleForNonEnumeratedStorages} />

                <Grid item xs={12} className={visibleForNonEnumeratedStorages + ' ' + classes.heading}>
                    <Typography variant="h6" display="block" gutterBottom>
                        {strings.availability}
                    </Typography>
                </Grid>

                <Grid item xs={6} md={6} className={visibleForNonEnumeratedStorages}>
                    <DatePicker
                        name={getName('startDate')}
                        label={strings.availableFrom}
                        formatFunc={getLongDateFormat}
                        inputVariant="outlined"
                        showError={showErrorOnBlur}
                        required={isNonEnumeratedStorages}
                        fieldProps={{ maxDate: formatIsoDate(endDate), validate: requiredIf(isNonEnumeratedStorages)}}
                        // we need a key prop, see https://codesandbox.io/s/changing-field-level-validators-zc8ei?fontsize=14&file=/src/index.js:1722-1771
                        key={isNonEnumeratedStorages ? validationKeyCount++ : -(validationKeyCount++)}
                    />
                </Grid>

                <Grid item xs={6} md={6} className={visibleForNonEnumeratedStorages}>
                    <NullableDatePicker
                        name={getName('endDate')}
                        label={strings.availableTo}
                        formatFunc={getLongDateFormat}
                        inputVariant="outlined"
                        nullString={strings.untilFurther}
                        showError={showErrorOnBlur}
                        fieldProps={{ minDate: formatIsoDate(startDate) }}
                    />
                </Grid>

                {
                    storageSiteExists &&
                    (
                        <>
                            <GridSeparator />

                            <Grid item xs={12}>
                                <TextField
                                    select
                                    name={getName('status')}
                                    label={strings.status}
                                    variant="outlined"
                                >
                                    {Object.values(storageGroupStatuses).map(o => <MenuItem key={o.key} value={o.key}>{o.title}</MenuItem>)}
                                </TextField>
                            </Grid>
                        </>
                    )
                }

                { (enumerateStoragesCheckboxVisible || showDeletedStoragesCheckboxVisible) && <GridSeparator /> }

                <Grid item xs={12} className={enumerateStoragesCheckboxVisible ? undefined : classes.hidden}>
                    <Typography variant="body1" display="block" gutterBottom>
                        {strings.enumerateStoragesDescription}
                    </Typography>
                    <Checkboxes
                        name={getName('enumerateStorages')}
                        data={
                            { label: category.enumerateStoragesLabel, value: true }
                        }
                    />
                </Grid>

                <Grid item xs={12} className={showDeletedStoragesCheckboxVisible ? undefined : classes.hidden}>
                    <Checkboxes
                        name={getName('showDeletedStorages')}
                        data={
                            { label: strings.showDeletedObjects, value: true }
                        }
                    />
                </Grid>

                <StorageList
                    fieldNamePrefix={getName('storages')}
                    validate={storageListVisible}
                    visible={storageListVisible}
                    showDeleted={!!getValue('showDeletedStorages')}
                    statusEditable={storageStatusEditable}
                />

            </Grid>
        </Box>
    );
};

StorageGroupEditor.propTypes = {
    fieldNamePrefix: PropTypes.string.isRequired,
    storageSiteFormData: PropTypes.object.isRequired,
    category: PropTypes.object.isRequired,
    storageSiteExists: PropTypes.bool
};

export default StorageGroupEditor;
