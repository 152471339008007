import React, { useState }  from 'react';
import { makeStyles } from 'styles/util';
import { Link, useHistory } from 'react-router-dom';
import strings from 'localization/strings';
import { useSelector } from 'react-redux';

import Box from '@material-ui/core/Box';
import Hidden from '@material-ui/core/Hidden';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import Drawer from '@material-ui/core/Drawer';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import Typography from '@material-ui/core/Typography';

import AccountNavigationCompact from 'account/AccountNavigationCompact';

const StorageDesktopNavigation = ({ links }) => {
    const useStyles = makeStyles(({ theme, colors, styles, fonts }) => ({
        desktopContainer: {
            display: 'flex',
            justifyContent: 'flex-end',
            flex: '1 0 auto',
            textAlign: 'right',
            height: '100%',
            marginLeft: theme.spacing(4)
        },
        navigationTopItemLink: {
            cursor: 'pointer',
            color: colors.darkBlue,
            transition: 'border 100ms ease-out',
            '&:hover': {
                backgroundColor: colors.lightBackgroundColor
            },
            '&.active': {
                backgroundColor: colors.lightBackgroundColor
            }
        },
        navigationTopItem: {
            verticalAlign: 'middle',
            display: 'inline-block',
            padding: theme.spacing(0, 2),
            lineHeight: '80px'
        },
        navigationTopButtonItem: {
            verticalAlign: 'middle',
            display: 'inline-block',
            lineHeight: '80px'
        },
        buttonItem: {
            fontFamily: fonts.medium,
            fontSize: '16px',
            color: colors.primaryColor,
            backgroundColor: colors.white,
            padding: theme.spacing(1.25, 3.25),
            '&:hover': {
                backgroundColor: colors.whiteHover
            }
        },
        badge: {
            backgroundColor: colors.primaryColor,
            color: colors.white,
            borderRadius: '50%',
            fontSize: '0.75rem',
            right: '10px',
            top: '50%',
            height: '1.25rem',
            width: '1.25rem',
            lineHeight: '1.25rem',
            textAlign: 'center',
            position: 'absolute',
            transform: 'translateY(-50%)'
        },
        menuIcon: {
            borderRadius: 0,
            color: colors.darkBlue,
            backgroundImage: `linear-gradient(90deg, #fff 50%, ${colors.lightBackgroundColor} 50%)`,
            backgroundSize: '200% 100%',
            backgroundPosition: '0 0',
            paddingRight: '40px',
            transition: `background-position ${styles.transitionEffect}`,
            '& p': {
                fontFamily: fonts.bold
            },
            '& svg': {
                fontSize: '24px',
                marginRight: '10px',
                color: colors.primaryColor
            },
            '&.active': {
                backgroundPosition: '100% 0',
                paddingRight: '57px',
                '& $badge': {
                    right: '27px'
                }
            }
        },
        relativeDrawer: {
            boxShadow: 'unset'
        },
        paper: {
            width: '375px',
            backgroundColor: colors.lightBackgroundColor,
            padding: '45px 50px'
        }
    }),
    ({ theme }) => ({
        storage: {
            relativeDrawer: {
                top: '80px !important',
            }
        },
        office: {
            relativeDrawer: {
                top: '80px',
                [theme.breakpoints.down('xs')]: {
                    top: '60px'
                }
            },
        }
    }));

    const classes = useStyles();
    const history = useHistory();
    const [desktopDrawerOpen, setDesktopDrawerOpen] = useState(false);
    const isAuthenticated = useSelector(state => !!state.authentication.token);

    const notifications = useSelector(state => state.notifications);

    const unreadNotifications = Object.keys(notifications).reduce((acc, key) => {
        return typeof notifications[key] === 'number' ? acc + notifications[key] : acc;
    }, 0);

    const handleToggle = () => {
        setDesktopDrawerOpen(!desktopDrawerOpen);
    };

    const handleClose = () => {
        setDesktopDrawerOpen(false);
    };

    const handleLinkClick = link => {
        if (link.onClick) {
            link.onClick();
        } else {
            history.push(link.url);
        }
        handleClose();
    };

    return (
        <Hidden smDown>
            <Box className={classes.desktopContainer}>
                {links && links.filter(link => link.visible).map(link => (
                    <React.Fragment key={link.url ?? link.text}>
                        {link.isButton ? (
                            <Box className={classes.navigationTopButtonItem}>
                                <Button component={Link} to={link.url} className={link.className}>
                                    {link.text}
                                </Button>
                            </Box>
                        ) : (
                            <Link to={link.url ?? '#'} onClick={link.onClick} className={link.className} >
                                <Box className={`${classes.navigationTopItem} ${classes.navigationTopItemLink} ${link.selected ? 'active' : ''} `} onClick={link.onClick} style={link.style}>
                                    {link.text}
                                </Box>
                            </Link>
                        )}
                    </React.Fragment>
                ))}
                {
                    isAuthenticated &&
                    <>
                        <IconButton
                            className={`${classes.menuIcon} ${desktopDrawerOpen ? 'active' : ''}`}
                            aria-label="menu"
                            onClick={handleToggle}
                            disableRipple
                        >
                            <MenuIcon/>
                            <Typography>{strings.myPages}</Typography>
                            {unreadNotifications > 0 &&
                                <Box className={classes.badge} component="span">
                                    {unreadNotifications}
                                </Box>
                            }
                        </IconButton>
                        <Drawer
                            anchor="right"
                            open={desktopDrawerOpen}
                            onClose={handleClose}
                            className={classes.relativeDrawer}
                            ModalProps={{
                                className: classes.relativeDrawer,
                            }}
                            PaperProps={{ className: `${classes.relativeDrawer} ${classes.paper}` }}
                            BackdropProps={{
                                className: classes.relativeDrawer
                            }}
                        >
                            <Box className={classes.menuContainer}>
                                <AccountNavigationCompact handleLinkClick={handleLinkClick} />
                            </Box>
                        </Drawer>
                    </>
                }
                </Box>
        </Hidden>
    );
};

StorageDesktopNavigation.propTypes = {
    links: PropTypes.array,
};

export default StorageDesktopNavigation;
