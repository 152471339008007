import React from 'react';
import PropTypes from 'prop-types';
import { getResizedImageUrl } from 'helpers/ImageHelper';
import DefaultImage from 'assets/images/storage365_object_defaultplacementimage.jpg';

const StorageSiteImage = ({ storageSite, imageUrl, width, height, mode = 'crop' }) => {
    if (imageUrl) {
        const imageOptions = { width, height, mode };
        return (
            <img src={getResizedImageUrl(imageUrl, imageOptions)} alt={storageSite.title} width={width} height={height} />
        );
    }

    return (
        <img src={DefaultImage} width={width} height={height} />
    );
};

StorageSiteImage.propTypes = {
    storageSite: PropTypes.object.isRequired,
    imageUrl: PropTypes.string,
    width: PropTypes.number.isRequired,
    height: PropTypes.number.isRequired,
    mode: PropTypes.string
};

export default StorageSiteImage;

