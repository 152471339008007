import React  from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from 'styles/util';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { formatClassNames } from 'helpers/ComponentHelper';

import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';

const useStyles = makeStyles(({ theme }) => ({
    button: {
        [theme.breakpoints.up('md')]: {
            alignSelf: 'center',
            minWidth: '315px',
        }
    }
}));

const CircularProgressButton = ({ children, isLoading, ...rest }) => {
    const classes = useStyles();
    const buttonFullWidth = !useMediaQuery(theme => theme.breakpoints.up('md'));

    return (
        <Button
            variant="contained"
            fullWidth={buttonFullWidth}
            {...rest}
            type={rest.type ?? 'button'}
            disabled={rest.disabled || isLoading}
            color={rest.color ?? 'primary'}
            className={formatClassNames([classes.button, rest.className])}
        >
            {!isLoading && children}
            {isLoading && <CircularProgress size={16} />}
        </Button>
    );
};

CircularProgressButton.propTypes = {
    children: PropTypes.node.isRequired,
    isLoading: PropTypes.bool
};

export default CircularProgressButton;
