import React, { useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useHistory } from 'react-router-dom';
import { searchConversations, setSearchParameters } from 'actions/admin/conversations';
import { performSearch, mergeSearchParameters } from 'components/adminTable/AdminTableHelper';
import { shorten } from 'helpers/StringHelper';
import { makeStyles } from 'styles/util';
import routes from 'routes';

import { Link } from 'react-router-dom';
import AdminPage from '../../presentational/AdminPage';
import AdminContentWrapper from '../../presentational/AdminContentWrapper';
import SearchConversationsForm from './SearchConversationsForm';
import AdminTable from 'components/adminTable/AdminTable';
import Loader from 'common/Loader';
import TimeAgo from 'common/TimeAgo';
import FlagIcon from '@material-ui/icons/Flag';

const useStyles = makeStyles(({ colors }) => ({
    role: {
        opacity: 0.6
    },
    contactDetailsIncluded: {
        color: colors.attention
    },
    flag: {
        color: `${colors.attentionBackground} !important`
    }
}));

const Conversations = () => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const location = useLocation();
    const history = useHistory();

    const searchResult = useSelector(state => state.admin.conversations.searchResult);
    const searchParametersFromState = useSelector(state => state.admin.conversations.searchParameters);

    const columns = [
        {
            title: '',
            id: 'flagged',
            render: (conversation) => conversation.flagged ?  <Link to={routes.admin.conversation.replace(':conversationId', conversation.id)} className={classes.flag}><FlagIcon/></Link> : undefined
        },
        {
            title: 'Storage site',
            id: 'storageSiteTitle',
            render: (conversation) => <Link to={routes.storageSiteDetails.replace(':storageSiteId', conversation.storageSiteId)} target="_blank">{conversation.storageSiteTitle}</Link>
        },
        {
            title: 'Sender',
            id: 'senderName',
            render: (conversation) => <span><Link to={routes.admin.actor.replace(':actorId', conversation.senderActorId)}>{conversation.senderName}</Link> <span className={classes.role}>({conversation.senderIsOwner ? 'owner' : 'tenant'})</span></span>
        },
        {
            title: 'Recipient',
            id: 'recipientName',
            render: (conversation) => <span><Link to={routes.admin.actor.replace(':actorId', conversation.recipientActorId)}>{conversation.recipientName}</Link> <span className={classes.role}>({conversation.senderIsOwner ? 'tenant' : 'owner'})</span></span>
        },
        {
            title: 'Text',
            id: 'text',
            render: (conversation) => <Link to={routes.admin.conversation.replace(':conversationId', conversation.id)}>{shorten(conversation.text, 30)}</Link>
        },
        {
            title: 'Contact details included',
            id: 'contactDetailsIncluded',
            render: (conversation) => conversation.contactDetailsIncluded ? <span className={classes.contactDetailsIncluded}>yes</span> : 'no'
        },
        {
            title: 'Time',
            id: 'lastMessageCreatedTime',
            render: (conversation) => <TimeAgo date={conversation.lastMessageCreatedTime}/>
        }
    ];

    const defaultParameters = {
        query: undefined,
        page: 0,
        pageSize: 20,
        sortByColumn: 'lastMessageCreatedTime',
        sortDirection: 'desc'
    };

    const search = parameters => {
        const searchParameters = mergeSearchParameters(parameters, location, searchParametersFromState, defaultParameters);
        performSearch(performSearchObject, searchParameters);
    };

    const performSearchObject = {
        searchParametersFromState,
        setSearchParametersToState: parameters => dispatch(setSearchParameters(parameters)),
        searchResult,
        search,
        apiCall: parameters => dispatch(searchConversations(parameters)),
        location,
        history,
        isInitializedRef: useRef()
    };

    useEffect(() => {
        const searchParameters = mergeSearchParameters({}, location, searchParametersFromState, defaultParameters);
        performSearch(performSearchObject, searchParameters);
    }, []);

    const handleSearchFormSubmit = (formValues) => {
        search({ ...defaultParameters, ...formValues, page: 0 });
    };

    const handleSort = (sortingParameters) => {
        search(sortingParameters);
    };

    const handlePageChange = (newPage) => {
        search({ page: newPage });
    };

    const handlePageChangeSize = (newPageSize) => {
        search({ page: 0, pageSize: newPageSize });
    };

    return (
        <AdminPage title="Conversations">
            <AdminContentWrapper type="listing">

                <SearchConversationsForm
                    searchParameters={searchParametersFromState}
                    onSubmit={handleSearchFormSubmit}
                />

                <div>
                    {
                        searchResult.isLoading && <Loader />
                    }
                    {
                        searchResult.items && (
                            <AdminTable
                                isPaged
                                items={searchResult.items}
                                count={searchResult.count}
                                page={searchResult.page}
                                pageSize={searchResult.pageSize}
                                columns={columns}
                                sortByColumn={columns.find(c => c.id === searchParametersFromState.sortByColumn)}
                                sortDirection={searchParametersFromState.sortDirection}
                                onSort={handleSort}
                                onPageChange={handlePageChange}
                                onPageChangeSize={handlePageChangeSize}
                            />
                        )
                    }
                </div>

            </AdminContentWrapper>
        </AdminPage>
    );
};

export default Conversations;
