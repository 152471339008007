import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from 'styles/util';

import { TextField } from 'mui-rff';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import DeleteOutlineOutlinedIcon from '@material-ui/icons/DeleteOutlineOutlined';

const useStyles = makeStyles(({ theme, colors }) => ({
    item: {
        padding: theme.spacing(2, 1),
        marginBottom: theme.spacing(2),
        border: `1px solid ${colors.darkGrey}`,
        borderRadius: theme.spacing(1),
        backgroundColor: colors.lightestGrey,
        '& > *': {
            padding: theme.spacing(0, 1)
        }
    },
    deleteButtonContainer: {
        textAlign: 'right',
        alignSelf: 'end'
    }
}));

const SeoGeoItemEditor = ({ name, onRemoveItem, onBlur }) => {
    const classes = useStyles();
    return (
        <Grid container className={classes.item}>
            <Grid item xs={12} md={6}>
                <TextField
                    name={`${name}.location`}
                    label="Location"
                    variant="outlined"
                    size="small"
                    onBlur={onBlur}
                />
            </Grid>
            <Grid item xs={12} md={6}>
                <TextField
                    name={`${name}.h1`}
                    label="H1"
                    variant="outlined"
                    size="small"
                    onBlur={onBlur}
                />
            </Grid>
            <Grid item xs={12} md={6}>
                <TextField
                    name={`${name}.title`}
                    label="Title"
                    variant="outlined"
                    size="small"
                    onBlur={onBlur}
                />
            </Grid>
            <Grid item xs={12} md={6}>
                <TextField
                    name={`${name}.metaDescription`}
                    label="Meta description"
                    variant="outlined"
                    size="small"
                    onBlur={onBlur}
                />
            </Grid>
            <Grid item xs={10} md={11}>
                <TextField
                    name={`${name}.content`}
                    label="Content"
                    variant="outlined"
                    size="small"
                    multiline
                    rows={4}
                    onBlur={onBlur}
                />
            </Grid>
            <Grid item xs={2} md={1} className={classes.deleteButtonContainer}>
                <IconButton
                    onClick={onRemoveItem}
                >
                    <DeleteOutlineOutlinedIcon/>
                </IconButton>
            </Grid>
        </Grid>
    );
};

SeoGeoItemEditor.propTypes = {
    name: PropTypes.string.isRequired,
    onRemoveItem: PropTypes.func.isRequired,
    onBlur: PropTypes.func.isRequired
};

export default SeoGeoItemEditor;
