export const nestedMerge = (...args) => {
    const result = { ...args[0] };
    for (let i = 1; i < args.length; i++) {
        const obj = args[i];
        Object.keys(obj).forEach(key => {
            if (typeof obj[key] === 'object' && !Array.isArray(obj[key])) {
                if (result[key] === undefined || typeof result[key] !== 'object') {
                    result[key] = {};
                }
                result[key] = nestedMerge(result[key], obj[key]);
            } else {
                result[key] = obj[key];
            }
        });
    }

    return result;
};
