import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import strings from 'localization/strings';
import { useSelector } from 'react-redux';
import { makeStyles } from 'styles/util';
import routes from 'routes';
import PropTypes from 'prop-types';
import organizationTypes from 'enums/organizationTypes';

import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import AccountNavigationLinks from 'account/AccountNavigationLinks';

import logoutIcon from 'assets/icons/logout.svg';

const AccountNavigationCompact = ({ handleLinkClick }) => {
    const useStyles = makeStyles(({ theme, colors, styles }) => ({
        container: {
            paddingBottom: theme.spacing(4)
        },
        navigationSection: {
            display: 'flex',
            flexDirection: 'column',
            gap: theme.spacing(2),
            paddingTop: theme.spacing(4),
            marginLeft: theme.spacing(3.75)
        },
        accountInfoSection: {
            padding: theme.spacing(2, 0),
            marginTop: theme.spacing(4),
            borderTop: `1px solid ${colors.lightGreyBorder ?? colors.lightGrey}`,
            borderBottom: `1px solid ${colors.lightGreyBorder ?? colors.lightGrey}`
        },
        accountInfoText: {
            '& span:first-child': {
                fontFamily: theme.overrides.MuiTypography.h4.fontFamily
            }
        },
        accountMenuItem: {
            position: 'relative',
            textDecoration: 'none',
            color: colors.secondaryColor,
            '& span': {
                display: 'inline-block',
                position: 'relative'
            },
            '& span::after': {
                content: '""',
                position: 'absolute',
                bottom: '-3px',
                left: 0,
                width: 0,
                height: '2px',
                backgroundColor: colors.primaryColor,
                transition: `width ${styles.transitionEffect}`
            },
            '&:hover span::after, &.active span::after': {
                width: '100%'
            },
            '&:hover span, &.active span': {
                fontFamily: theme.overrides.MuiTypography.h3.fontFamily
            }
        },
        logOut: {
            display: 'flex',
            alignItems: 'center',
            textDecoration: 'none',
            color: colors.secondaryColor,
            gap: theme.spacing(1),
            '&:visited': {
                textDecoration: 'none',
                color: colors.secondaryColor
            }
        }
    }));

    const classes = useStyles();
    const [ownerLinks, setOwnerLinks] = useState([]);
    const [tenantLinks, setTenantLinks] = useState([]);
    const [superAdminLinks, setSuperAdminLinks] = useState([]);
    const [selectActorLinks, setSelectActorLinks] = useState([]);
    const isAuthenticated = useSelector(state => !!state.authentication.token);

    const { user, selectedActor, actors, isSuperAdmin, isUserImpersonated, isActorImpersonated } = useSelector(state => state.authentication.context);

    const { unreadMessageCount, ownerBookingAwaitingActionCount, tenantBookingAwaitingActionCount } = useSelector(state => state.notifications);

    const hasTenantBookings = useSelector(state => state.account.tenantBookingsSection.bookings)?.length > 0;

    const isOwner = selectedActor?.isOwner;
    const isTenant = selectedActor?.isTenant || hasTenantBookings;

    useEffect(() => {
        setOwnerLinks(buildOwnerLinks());
        setTenantLinks(buildTenantLinks());
        setSuperAdminLinks(buildSuperAdminLinks());
        setSelectActorLinks(buildSelectActorLinks());
    }, [isAuthenticated]);

    const getLoggedInText = () => {
        const items = [];

        if(isUserImpersonated) {
            items.push(<><span>{strings.accountNavigation.loggedInAsUser}:</span> {user?.name}</>);
            if (selectedActor?.organizationType === organizationTypes.business.key) {
                items.push(<><span>{strings.accountNavigation.loggedInAsActor}:</span> {selectedActor?.name}</>);
            }
        } else {
            items.push(<><span>{strings.accountNavigation.user}:</span> {user?.name}</>);
            if(isActorImpersonated) {
                items.push(<><span>{strings.accountNavigation.loggedInAsActor}:</span> {selectedActor?.name}</>);
            } else if (selectedActor?.organizationType === organizationTypes.business.key) {
                items.push(<><span>{strings.accountNavigation.actorCompany}:</span> {selectedActor?.name}</>);
            }
        }

        return items.map((item, i) => <Typography key={i} className={classes.accountInfoText}>{item}</Typography>);
    };

    const buildOwnerLinks = () => {
        const items = [
            {
                url: routes.account.conversations,
                text: strings.accountNavigation.conversations,
                ...unreadMessageCount > 0 && { badge: unreadMessageCount }
            },
            {
                url: routes.account.ownerBookings,
                text: strings.accountNavigation.bookings,
                ...ownerBookingAwaitingActionCount > 0 && { badge: ownerBookingAwaitingActionCount }
            },
            {
                url: routes.account.storageSites,
                text: strings.accountNavigation.storageAds,
            },
            {
                url: routes.account.settings,
                text: strings.accountNavigation.settings,
            }
        ];
        return items;
    };

    const buildTenantLinks = () => {
        const items = [
            {
                url: routes.account.conversations,
                text: strings.accountNavigation.conversations,
                ...unreadMessageCount > 0 && { badge: unreadMessageCount }
            }
        ];
        if(isTenant) {
            items.push({
                url: routes.account.tenantBookings,
                text: strings.accountNavigation.bookings,
                ...tenantBookingAwaitingActionCount > 0 && { badge: tenantBookingAwaitingActionCount }
            });
        }
        items.push({
            url: routes.account.settings,
            text: strings.accountNavigation.settings
        });
        return items;
    };

    const buildSuperAdminLinks = () => {
        const items = [
            {
                url: routes.admin.index,
                text: strings.accountTabs.backoffice
            }
        ];
        return items;
    };

    const buildSelectActorLinks = () => {
        const items = [];
        if(actors.length > 1) {
            items.push({
                url: routes.account.selectActor,
                text: strings.accountTabs.selectActor
            });
        }
        return items;
    };

    return (
        isAuthenticated &&
        <Box className={classes.container}>
            <Link to={routes.account.overview} onClick={handleLinkClick} className={classes.accountMenuItem}><Typography variant="h3" component="span">{strings.myPages}</Typography></Link>
            {isOwner &&
                <Box className={classes.navigationSection}>
                    {isTenant && <Typography variant="h4">{strings.accountNavigation.ownerHeading}</Typography>}
                    <AccountNavigationLinks links={ownerLinks} handleLinkClick={handleLinkClick} />
                </Box>
            }
            {(isTenant || !isOwner) &&
                <Box className={classes.navigationSection}>
                    {isOwner && <Typography variant="h4">{strings.accountNavigation.tenantHeading}</Typography>}
                    <AccountNavigationLinks links={tenantLinks} handleLinkClick={handleLinkClick} />
                </Box>
            }
            {
                isSuperAdmin &&
                <Box className={classes.navigationSection}>
                    <Typography variant="h4">{strings.accountNavigation.superAdminHeading}</Typography>
                    <AccountNavigationLinks links={superAdminLinks} handleLinkClick={handleLinkClick} />
                </Box>
            }
            <Box className={`${classes.navigationSection} ${classes.accountInfoSection}`}>
                {getLoggedInText()}
                <AccountNavigationLinks links={selectActorLinks} handleLinkClick={handleLinkClick} />
            </Box>
            <Box className={classes.navigationSection}>
                <Link to={routes.logOut} className={classes.logOut} onClick={handleLinkClick}>{strings.logout}<img src={logoutIcon} height="24" width="24" alt={strings.logout} /></Link>
            </Box>
        </Box>
    );
};

AccountNavigationCompact.propTypes = {
    handleLinkClick: PropTypes.func,
};

export default AccountNavigationCompact;

