import { createTheme } from '@material-ui/core/styles';

const createAdminTheme = appContext => {
    const { colors, fonts } = appContext;

    const theme = {
        palette: {
            primary: {
                main: colors.primaryColor
            },
            secondary: {
                main: colors.secondaryColor
            },
            text: {
                primary: colors.textColor,
                secondary: colors.textColor,
                dark: colors.textColorDark
            }
        },
        overrides: {
            MuiTypography: {
                h5: {
                    fontFamily: fonts.regular
                },
                h6: {
                    fontFamily: fonts.regular
                },
                body1: {
                    fontFamily: fonts.regular
                },
                body2: {
                    fontFamily: fonts.regular
                },
                caption: {
                    fontFamily: fonts.regular
                }
            },
            MuiFormLabel: {
                root: {
                    fontFamily: fonts.regular
                }
            },
            MuiInputBase: {
                root: {
                    fontFamily: fonts.regular
                }
            },
            MuiFormControl: {
                root: {
                    margin: '12px 0',
                    width: '100%'
                }
            },
            MuiTableCell: {
                root: {
                    fontFamily: fonts.regular
                }
            },
            MuiButton: {
                contained: {
                    color: `${colors.white} !important`
                }
            },
        }
    };

    return createTheme(theme);
};

export default createAdminTheme;
