import React from 'react';
import PropTypes from 'prop-types';
import { useAppContext } from 'context/AppContext';
import { makeStyles } from 'styles/util';
import { isProduction } from 'helpers/EnvironmentHelper';
import strings from 'localization/strings';

import MarginBox from 'common/MarginBox';
import Box from '@material-ui/core/Box';
import Alert from '@material-ui/lab/Alert';
import Text from 'common/Text';
import { PaymentElement as ReactStripePaymentElement, useElements } from '@stripe/react-stripe-js';

const useStyles = makeStyles(({ theme, colors }) => ({
    container: {
        padding: theme.spacing(2),
        border: `1.5px solid ${colors.darkGrey}`,
        borderRadius: '4px'
    },
    hidden: {
        display: 'none'
    }
}));

const StripePaymentField = ({ visible = true }) => {
    const classes = useStyles();
    const { appContext } = useAppContext();
    const hiddenIfRequiredClass = (visible ? '' : ' ' + classes.hidden);
    const elements = useElements();

    // propagate the change event to stripePaymentContext/stripeSetupContext
    const handleChange = event => elements.onElementChange(event);

    return (
        <>
            {
                !isProduction(appContext) &&
                (
                    <MarginBox bottom={4}>
                        <Alert className={classes.testCardInformation + hiddenIfRequiredClass} severity="info">
                            <Text html={strings.testCardInformation}/>
                        </Alert>
                    </MarginBox>
                )
            }
            <Box className={classes.container + hiddenIfRequiredClass}>
                <ReactStripePaymentElement
                    onChange={handleChange}
                />
            </Box>
        </>
    );
};

StripePaymentField.propTypes = {
    visible: PropTypes.bool
};

export default StripePaymentField;
