import React from 'react';
import PropTypes from 'prop-types';
import { useAppContext } from 'context/AppContext';
import strings from 'localization/strings';
import { makeStyles } from 'styles/util';
import { getStorageSiteAndStorageTitle } from 'helpers/StorageSiteHelper';
import { formatLengthAndWidth } from 'helpers/StringHelper';
import purchaseTypes from 'enums/purchaseTypes';
import goodsTypes from 'enums/goodsTypes';
import { getContact, getBusinessOrPrivateName } from 'helpers/ActorHelper';
import contactTypes from 'enums/contactTypes';

import Box from '@material-ui/core/Box';
import Area from 'common/Area';
import ShortDate from 'common/ShortDate';
import TimeSpan from 'common/TimeSpan';
import Caption from './Caption';

const useStyles = makeStyles(({ theme, colors }) => ({
    container: {
        '@media print': {
            pageBreakInside: 'avoid'
        }
    },
    informationItems: {
        backgroundColor: colors.mediumGrey,
        borderRadius: theme.spacing(2),
        padding: theme.spacing(3),
        display: 'flex',
        flexDirection: 'column',
        gap: theme.spacing(1)
    },
    informationItem: {
        display: 'flex'
    },
    title: {
        flex: '0 0 30%',
        fontWeight: 'bold'
    },
    value: {
        flex: '0 0 70%'
    }
}));

const InformationItems = ({ caption, purchase }) => {
    const classes = useStyles();
    const { appContext } = useAppContext();

    const booking = purchase.booking;
    const storageGroup = booking.storageGroup;
    const storageSite = storageGroup.storageSite;
    const storage = booking.storage;
    const area = booking.area ?? storageGroup.storageArea;
    const length = booking.length ?? storageGroup.storageLength;
    const width = booking.width ?? booking.storageGroup.storageWidth;
    const isInitialPurchase = purchase.type === purchaseTypes.periodBookingPurchase.key || purchase.type === purchaseTypes.subscriptionBookingInitialPurchase.key;
    const isSubscriptionEndPurchase = purchase.type === purchaseTypes.endSubscriptionBookingPurchase.key;

    const informationItems = [
        {
            title: strings.paymentDocuments.bookingNumber,
            value: booking.id
        },
        {
            title: strings.storage,
            value: getStorageSiteAndStorageTitle(storage, storageGroup)
        },
        {
            title: strings.owner,
            value: [
                    getBusinessOrPrivateName(booking.ownerActor),
                    storageSite.address,
                    storageSite.postalCode + ' ' + storageSite.city,
                    getContact(booking.ownerActor, [contactTypes.checkInMobileTelephone, contactTypes.mainMobileTelephone]),
                    getContact(booking.ownerActor, [contactTypes.checkInEmail, contactTypes.mainEmail])
                ]
                .filter(o => o)
                .map((o, i) => <React.Fragment key={i}>{o}<br/></React.Fragment>)
        },
        {
            title: strings.paymentDocuments.storageSubject,
            value: booking.description
        },
        {
            title: strings.vehicleType,
            value: booking.goodsType ? goodsTypes[booking.goodsType].title : undefined
        },
        {
            title: strings.registrationNumber,
            value: booking.registrationNumber
        },
        {
            title: strings.area,
            value: area > 0 ? <Area value={area} maxNumberOfDecimals={1} /> : undefined
        },
        {
            title: strings.dimensions,
            value: length > 0 && width > 0 ? formatLengthAndWidth(length, width, appContext, { maxNumberOfDecimals: 1 }) : undefined
        },
        {
            title: strings.checkIn,
            value: booking.isTakeoverBooking
                ? undefined
                : (
                    <>
                        <ShortDate value={booking.startDate} />
                        {
                            booking.checkInOpeningTimeSpan && isInitialPurchase &&
                            (
                                <>
                                    <br/>
                                    <TimeSpan value={booking.checkInOpeningTimeSpan} />
                                </>
                            )
                        }
                    </>
                )
        },
        {
            title: strings.checkOut,
            value: (
                <>
                    {
                        booking.endDate ? <ShortDate value={booking.endDate} /> : strings.paymentDocuments.subscription
                    }
                    {
                        booking.checkOutOpeningTimeSpan && (isInitialPurchase || isSubscriptionEndPurchase) &&
                        (
                            <>
                                <br />
                                <TimeSpan value={booking.checkOutOpeningTimeSpan} />
                            </>
                        )
                    }
                </>
            )
        },
        {
            title: strings.paymentDocuments.descriptionGivenAfterPurchasePaymentDocumentTitle,
            value: booking.storageGroup.storageSite.descriptionGivenAfterPurchase
        }
    ];

    return (
        <Box className={classes.container}>
            <Caption text={caption}/>
            <Box className={classes.informationItems}>
                {
                    informationItems
                        .filter(o => o.value)
                        .map((o, i) => (
                            <Box className={classes.informationItem} key={i}>
                                <Box className={classes.title}>{o.title}</Box>
                                <Box className={classes.value}>{o.value}</Box>
                            </Box>
                        ))
                }
            </Box>
        </Box>
    );
};

InformationItems.propTypes = {
    caption: PropTypes.string.isRequired,
    purchase: PropTypes.object.isRequired
};

export default InformationItems;
