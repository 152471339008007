import strings from 'localization/strings';

export const getOwnerExpectedReplyTimeItems = () => [
    {
        value: 3600,
        text: strings.oneHour,
    },
    {
        value: 6 * 3600,
        text: strings.sixHours,
    },
    {
        value: 24 * 3600,
        text: strings.oneDay,
    }
];

export const getBusinessOrPrivateName = actor => {
    if(!actor.name && !actor.firstName && !actor.lastName) {
        // sometimes the actor name is hidden
        return undefined;
    }
    return actor.name ?? `${actor.firstName} ${actor.lastName}`;
};

export const getContact = (actor, contactTypes) => {
    if(!actor) {
        return undefined;
    }
    const contactTypeArray = Array.isArray(contactTypes)
        ? contactTypes
        : [contactTypes];
    let contact = undefined;
    contactTypeArray.forEach(contactType => {
        const matchingContact = actor.contacts?.find(c => c.type === contactType || c.type === contactType.key);
        if(!contact && matchingContact) {
            contact = matchingContact;
        }
    });

    return contact?.value;
};

export const getAddress = (actor, addressTypes) => {
    if(!actor) {
        return undefined;
    }
    const addressTypeArray = Array.isArray(addressTypes)
        ? addressTypes
        : [addressTypes];
    let address = undefined;
    addressTypeArray.forEach(addressType => {
        const matchingAddress = actor.addresses?.find(a => a.type === addressType || a.type === addressType.key);
        if(!address && matchingAddress) {
            address = matchingAddress;
        }
    });
    return address?.address;
};
