// process.env.NODE_ENV is 'development', 'test' or 'production'
// for process.env.NODE_ENV, see https://create-react-app.dev/docs/adding-custom-environment-variables/
// export const isProduction = process.env.NODE_ENV === 'production';

// appContext.environment is 'local', 'test' or 'production'
// window.configuration.environment is 'development', 'test' or 'production'

import environments from 'enums/environments';

export const isProductionFromWindowConfiguration = window.configuration.environment === 'production';

export const isDevelopmentFromWindowConfiguration = window.configuration.environment === 'development';

export const isProduction = appContext => appContext.environment === environments.production.key;

export const isLocal = appContext => appContext.environment === environments.local.key;

export const semverGreaterThan = (versionA, versionB) => {
    const versionsA = versionA.split(/\./g);
    const versionsB = versionB.split(/\./g);
    while (versionsA.length || versionsB.length) {
        const a = Number(versionsA.shift());
        const b = Number(versionsB.shift());
        if (a === b) {
            continue;
        }
        return a > b || isNaN(b);
    }
    return false;
};
