const payoutReceiverTypes = {
    storage365: {},
    owner: {}
};

Object.keys(payoutReceiverTypes).forEach(key => {
    payoutReceiverTypes[key].key = key;
});

export default payoutReceiverTypes;
