import { svSE, fiFI } from '@material-ui/core/locale';
import * as dateFnsLocale from 'date-fns/locale';
import { swedenImages, finlandImages } from 'styles/images';

const markets = {
    sweden: {
        materialUiLocale: svSE,
        dateFnsLocale: dateFnsLocale.sv,
        images: swedenImages
    },
    finland: {
        materialUiLocale: fiFI,
        dateFnsLocale: dateFnsLocale.fi,
        images: finlandImages
    }
};
// more properties will be populated by AppContextProvider, see function enrichMarkets

Object.keys(markets).forEach(key => {
    markets[key].key = key;
});

export default markets;
