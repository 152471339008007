import React, { useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useHistory } from 'react-router-dom';
import { searchPayouts, setSearchParameters } from 'actions/admin/payouts';
import { formatIsoDate } from 'helpers/DateHelper';
import { performSearch, mergeSearchParameters } from 'components/adminTable/AdminTableHelper';
import routes from 'routes';
import { add } from 'date-fns';

import { Link } from 'react-router-dom';
import Loader from 'common/Loader';
import Box from '@material-ui/core/Box';
import AdminPage from '../../presentational/AdminPage';
import AdminContentWrapper from '../../presentational/AdminContentWrapper';
import AdminTable from 'components/adminTable/AdminTable';
import SearchPayoutsForm from './SearchPayoutsForm';
import TimeAgo from 'common/TimeAgo';
import ShortDate from 'common/ShortDate';
import Amount from 'common/Amount';

const formatAmountOptions = { numberOfDecimals: 2 };

const Payouts = () => {
    const dispatch = useDispatch();
    const location = useLocation();
    const history = useHistory();

    const searchResult = useSelector(state => state.admin.payouts.searchResult);
    const searchParametersFromState = useSelector(state => state.admin.payouts.searchParameters);

    const defaultParameters = {
        status: 'all',
        ownerName: undefined,
        createdDateFrom: formatIsoDate(add(new Date(), { months: -1 })),
        createdDateTo: undefined,
        page: 0,
        pageSize: 20,
        sortByColumn: 'createdtime',
        sortDirection: 'desc'
    };

    const columns = [
        {
            title: 'ID',
            id: 'id',
            render: payout => <Link to={routes.admin.payout.replace(':payoutId', payout.id)}>{payout.id}</Link>,
            align: 'right'
        },
        {
            title: 'Owner',
            id: 'ownerName',
            render: payout => payout.ownerActorId
                ? <Link to={routes.admin.actor.replace(':actorId', payout.ownerActorId)}>{payout.ownerName}</Link>
                : 'Storage365'
        },
        {
            title: 'Amount',
            id: 'amount',
            render: payout => <Amount value={payout.amount} currency={payout.currency} {...formatAmountOptions}/>,
            align: 'right',
            whiteSpace: 'nowrap'
        },
        {
            title: 'Status',
            id: 'status',
            render: payout => payout.status
        },
        {
            title: 'Created time',
            id: 'createdTime',
            render: payout => <TimeAgo date={payout.createdTime}/>
        },
        {
            title: 'Modified time',
            id: 'modifiedTime',
            render: payout => <TimeAgo date={payout.modifiedTime}/>
        },
        {
            title: 'Arrival date',
            id: 'arrivalDate',
            render: payout => <ShortDate value={payout.arrivalDate}/>
        },
        {
            title: 'Number of purchases',
            id: 'numberOfPurchases',
            render: payout => payout.numberOfPurchases,
            align: 'right'
        }
    ];

    const search = (parameters) => {
        const sanitizedParameters = { ...parameters };
        if(parameters.createdDateFrom) {
            sanitizedParameters.createdDateFrom = formatIsoDate(parameters.createdDateFrom);
        }
        if(parameters.createdDateTo) {
            sanitizedParameters.createdDateTo = formatIsoDate(parameters.createdDateTo);
        }
        const searchParameters = mergeSearchParameters(sanitizedParameters, location, searchParametersFromState, defaultParameters);
        performSearch(performSearchObject, searchParameters);
    };

    const getParametersForApiCall = parameters => ({
        ...parameters,
        status: parameters.status === 'all'
            ? undefined
            : parameters.status
    });

    const performSearchObject = {
        searchParametersFromState,
        setSearchParametersToState: parameters => dispatch(setSearchParameters(parameters)),
        searchResult,
        search,
        apiCall: parameters => dispatch(searchPayouts(getParametersForApiCall(parameters))),
        location,
        history,
        isInitializedRef: useRef()
    };

    useEffect(() => {
        const searchParameters = mergeSearchParameters({}, location, searchParametersFromState, defaultParameters);
        performSearch(performSearchObject, searchParameters);
    }, []);

    const handleSearchFormSubmit = (formValues) => {
        search({ ...defaultParameters, ...formValues, page: 0 });
    };

    const handleSort = (sortingParameters) => {
        search(sortingParameters);
    };

    const handlePageChange = (newPage) => {
        search({ page: newPage });
    };

    const handlePageChangeSize = (newPageSize) => {
        search({ page: 0, pageSize: newPageSize });
    };

    return (
        <AdminPage title="Payouts">

            <AdminContentWrapper type="listing">

                <SearchPayoutsForm
                    searchParameters={searchParametersFromState}
                    onSubmit={handleSearchFormSubmit}
                />

                <Box>
                    {
                        searchResult.isLoading && <Loader />
                    }
                    {
                        searchResult.items && (
                            <AdminTable
                                isPaged
                                items={searchResult.items}
                                count={searchResult.count}
                                page={searchResult.page}
                                pageSize={searchResult.pageSize}
                                columns={columns}
                                sortByColumn={columns.find(c => c.id === searchParametersFromState.sortByColumn)}
                                sortDirection={searchParametersFromState.sortDirection}
                                onSort={handleSort}
                                onPageChange={handlePageChange}
                                onPageChangeSize={handlePageChangeSize}
                            />
                        )
                    }
                </Box>

            </AdminContentWrapper>
        </AdminPage>
    );
};

export default Payouts;
