import React from 'react';
import { makeStyles } from 'styles/util';
import strings from 'localization/strings';

import Box from '@material-ui/core/Box';
import Form from 'form/Form';
import SizeSelector from 'components/storageSearchForm/SizeSelector';
import AdditionalFiltersSelector from 'components/storageSearchForm/AdditionalFiltersSelector';
import PropTypes from 'prop-types';
import MaxPricePerAreaUnitSelector from 'components/storageSearchForm/MaxPriceSelectors';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import Modal from '@material-ui/core/Modal';
import LinkButton from 'common/LinkButton';

const useStyles = makeStyles(({ theme, colors }) => ({
    storageSearchFormModalRow: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        '& a': {
            lineHeight: 4
        },
        [theme.breakpoints.down('md')]: {
            display: 'flex',
            justifyContent: 'space-between',
            position: 'sticky',
            bottom: 0,
            background: colors.white,
            width: '100%',
            padding: theme.spacing(2, 4)
        }
    },
    storageSearchFormModalRowTop: {
        zIndex: 50,
        [theme.breakpoints.down('md')]: {
            top: 0
        }
    },
    storageSearchFormModal: {
        background: colors.white,
        overflow: 'scroll',
        padding: theme.spacing(0, 2)
    },
    sizeSelectorRow: {
        padding: theme.spacing(0)
    },
    modalButtonWrapper: {
        paddingBottom: theme.spacing(2),
        width: '100%'
    }
}));

const FilterModal = ({ open, initialValues, landingPage, onClose, onSubmit }) => {
    const classes = useStyles();
    return (
        <Modal
            open={open}
            onClose={onClose}
            style={{ overflow: 'scroll' }}
        >
            <Box>
                <Form
                    onSubmit={onSubmit}
                    initialValues={initialValues}
                >
                    {({ handleSubmit }) => (
                        <form onSubmit={handleSubmit}>
                            <Box className={`${classes.storageSearchFormModalRow} ${classes.storageSearchFormModalRowTop}`}>
                                <Typography variant="h4">{strings.searchFilter}</Typography>
                                <LinkButton onClick={onClose}>{strings.cancel}</LinkButton>
                            </Box>
                            <Box className={classes.storageSearchFormModal}>
                                <SizeSelector showButton={false} className={classes.sizeSelectorRow}/>
                                <MaxPricePerAreaUnitSelector showButton={false} />
                                <AdditionalFiltersSelector landingPage={landingPage} showButton={false} />
                            </Box>
                            <Box className={classes.storageSearchFormModalRow}>
                                <Box className={classes.modalButtonWrapper}>
                                    <Button
                                        fullWidth
                                        type="submit"
                                        variant="contained"
                                        color="primary"
                                    >
                                        {strings.search}
                                    </Button>
                                </Box>
                            </Box>
                        </form>
                    )}
                </Form>
            </Box>
        </Modal>
    );
};

FilterModal.propTypes = {
    open: PropTypes.bool.isRequired,
    initialValues: PropTypes.object.isRequired,
    landingPage: PropTypes.object.isRequired,
    onClose: PropTypes.func.isRequired,
    onSubmit: PropTypes.func.isRequired,
};

export default FilterModal;
