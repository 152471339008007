import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useAppContext } from 'context/AppContext';
import strings from 'localization/strings';
import { makeStyles } from 'styles/util';
import { getGeocodeResultForSinglePlace, locationToAddress } from 'helpers/GeocodeHelper';
import { getGoogleMapsScript } from 'helpers/MapHelper';

import { Autocomplete } from '@react-google-maps/api';
import Button from '@material-ui/core/Button';
import Box from '@material-ui/core/Box';
import Clear from '@material-ui/icons/Clear';

const getLocationString = l => l ? `${l.lat || l.latitude},${l.lng || l.longitude}` : undefined;


const useStyles = makeStyles(({ theme, colors, styles }) => ({
    inputContainer: {
        display: 'flex',
        flexWrap: 'wrap',
        height: '70px',
        [theme.breakpoints.down('xs')]: {
            gap: theme.spacing(1.5),
            height: 'auto'
        }
    },
    locationSearchAutoComplete: {
        backgroundColor: colors.white,
        flex: '1 1 auto',
        display: 'flex',
        padding: theme.spacing(2, 1, 2, 2),
        borderTopLeftRadius: `${styles.inputBorderRadius}px`,
        borderBottomLeftRadius: `${styles.inputBorderRadius}px`,
        '& input': {
            backgroundColor: 'transparent'
        },
        [theme.breakpoints.up('sm')]: {
            borderRight: 'none'
        },
        [theme.breakpoints.down('xs')]: {
            borderTopRightRadius: `${styles.inputBorderRadius}px`,
            borderBottomRightRadius: `${styles.inputBorderRadius}px`,
            '& input': {
                width: '100%'
            }
        },
        '& img': {
            alignSelf: 'center',
            marginLeft: theme.spacing(0.5)
        },
    },
    border: {
        border: '1px solid rgba(0, 0, 0, 0.15)'
    },
    button: {
        flex: '0 0 auto',
        [theme.breakpoints.up('sm')]: {
            paddingLeft: theme.spacing(5.8),
            paddingRight: theme.spacing(5.8),
            borderTopLeftRadius: 'inherit',
            borderBottomLeftRadius: 'inherit',
        },
        [theme.breakpoints.down('xs')]: {
            width: '100%',
            height: '60px'
        },
        '& span': {
            fontSize: theme.spacing(2.5),
            lineHeight: 1.4
        }
    },
    clearButton: {
        color: colors.black,
        alignSelf: 'center',
        '&:hover': {
            cursor: 'pointer'
        }
    },
    inputField: {
        flex: '1 1 auto',
        fontSize: theme.typography.bodyMedium.fontSize,
        lineHeight: '1',
        letterSpacing: '-0.01em',
        margin: theme.spacing(0, 1),
        border: 'none',
        outline: 'none',
        '&::placeholder': {
            color: colors.black,
            fontSize: theme.typography.bodyMedium,
        },
        [theme.breakpoints.down('xs')]: {
            '&::placeholder': {
                fontSize: theme.typography.body1.fontSize
            }
        }
    }
}),
({ colors }) => ({
    storage: {
        border: {
            backgroundColor: colors.lightButtonColor
        }
    }
}));

const LocationSelector = ({ location: initialLocation, address: initialAddress, border = true, onSearch }) => {
    const classes = useStyles();
    const { appContext } = useAppContext();
    const googleMapsScript = getGoogleMapsScript();

    const [text, setText] = useState(initialAddress || '');
    const [oldText, setOldText] = useState(initialAddress || '');
    const [address, setAddress] = useState(initialAddress || '');
    const [location, setLocation] = useState(initialLocation);
    const [addressAutocomplete, setAddressAutocomplete] = useState(undefined);

    useEffect(() => {
        if(initialLocation && !initialAddress) {
            locationToAddress(initialLocation, appContext, handleResult);
        }
    }, [getLocationString(initialLocation)]);

    const handleAddressAutocompletePlaceChange = place => {
        getGeocodeResultForSinglePlace(place, appContext, result => {
            onSearch(handleResult(result));
        });
    };

    const handleResult = result => {
        let newAddress;
        let newLocation;
        if(result.address && (result.address.sublocality || result.address.city)) {
            newAddress = [
                result.address.sublocality,
                result.address.city
            ]
            .filter(o => o)
            .join(', ');
        }
        if(result.location) {
            newLocation = {
                lat: result.location.latitude,
                lng: result.location.longitude
            };
        }
        setText(result.locationText || result.requestedAddress);
        setOldText(result.locationText || result.requestedAddress);
        setAddress(newAddress || '');
        const locationChanged = getLocationString(location) !== getLocationString(newLocation);
        if(locationChanged) {
            setLocation(newLocation);
        }
        return {
            locationText: result.locationText,
            address: newAddress,
            location: newLocation
        };
    };

    const handleTextChange = event => {
        setText(event.target.value);
    };

    const handleTextKeyPress = event => {
        if(event.charCode === 13) {
            // when pressing enter, we don't use data from the autocomplete dropdown, but rather just the text in the textbox
            // see https://react-google-maps-api-docs.netlify.app/#!/Autocomplete
            handleSearch();
        }
    };

    const handleClear = () => {
        setText('');
        setOldText('');
        setAddress('');
        setLocation(undefined);
    };

    const handleSearch = () => {
        if(text !== oldText) {
            // different text in textbox compared to the old address text that we have found
            // we need to lookup and then execute callback
            getGeocodeResultForSinglePlace({ name: text }, appContext, result => {
                const newValues = handleResult(result);
                onSearch(newValues);
            });
        } else {
            // execute immediately
            onSearch({
                locationText: text,
                address,
                location
            });
        }
    };

    return (
        <Box className={classes.inputContainer}>
            {
                googleMapsScript.isLoaded &&
                (
                    <Autocomplete
                        // onLoad
                        // This callback is called when the autocomplete instance has loaded. It is called with the autocomplete instance.
                        // autocompleteInstance is an object from Google Maps, see https://developers.google.com/maps/documentation/javascript/reference/places-widget#Autocomplete
                        onLoad={autocompleteInstance => setAddressAutocomplete(autocompleteInstance)}
                        // onPlaceChanged
                        // This event is fired when a PlaceResult is made available for a Place the user has selected.
                        // If the user enters the name of a Place that was not suggested by the control and presses the Enter key,
                        // or if a Place Details request fails, the PlaceResult contains the user input in the name property, with no other properties defined.
                        onPlaceChanged={() => handleAddressAutocompletePlaceChange(addressAutocomplete.getPlace())}
                        restrictions={{ country: appContext.countryCode }}
                        types={[ '(regions)' ]}
                        className={classes.locationSearchAutoComplete + ' ' + (border ? classes.border : '')}
                    >
                        <>
                            <img src={appContext.images.placeIcon} />
                            <input
                                type="text"
                                value={text}
                                onChange={handleTextChange}
                                onKeyPress={handleTextKeyPress}
                                placeholder={strings.searchLocationPlaceholder}
                                className={classes.inputField}
                            />
                            {text && <Clear className={classes.clearButton} onClick={handleClear}/>}
                        </>
                    </Autocomplete>
                )
            }
            <Button
                className={classes.button}
                color="primary"
                variant="contained"
                type="submit"
                onClick={handleSearch}
            >
                {strings.search}
            </Button>
        </Box>
    );
};

LocationSelector.propTypes = {
    address: PropTypes.string,
    location: PropTypes.object,
    border: PropTypes.bool,
    onSearch: PropTypes.func
};

export default LocationSelector;
