import * as actionTypes from 'actions/admin/general';

const initialState = {
    snackbarMessage: undefined,
    snackbarMessageOptions: undefined,
    snackbarMessageTime: undefined
};

const general = (state = initialState, action) => {
    const now = new Date().getTime();
    switch (action.type) {
        case actionTypes.ENQUEUE_ADMIN_SNACKBAR_MESSAGE:
            return { ...state, snackbarMessage: action.message, snackbarMessageOptions: action.messageOptions, snackbarMessageTime: now };
        default:
            return state;
    }
};

export default general;
