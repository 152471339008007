import { RSAA } from 'redux-api-middleware';
import { createRsaaTypes, createHeaders } from '../actionHelpers';
import queryString from 'query-string';

export const ADMIN_USERS_SEARCH = 'ADMIN_USERS_SEARCH';
export const ADMIN_USERS_SEARCH_SUCCESS = 'ADMIN_USERS_SEARCH_SUCCESS';
export const ADMIN_USERS_SEARCH_FAILURE = 'ADMIN_USERS_SEARCH_FAILURE';
export const ADMIN_USERS_GET = 'ADMIN_USERS_GET';
export const ADMIN_USERS_GET_CURRENT_LOGIN_TOKEN = 'ADMIN_USERS_GET_CURRENT_LOGIN_TOKEN';
export const ADMIN_USERS_UPDATE = 'ADMIN_USERS_UPDATE';
export const ADMIN_USERS_SET_SEARCH_PARAMETERS = 'ADMIN_USERS_SET_SEARCH_PARAMETERS';

export const searchUsers = (searchParameters) => ({
    [RSAA]: {
        types: createRsaaTypes(ADMIN_USERS_SEARCH),
        endpoint: `/api/admin/users?${queryString.stringify(searchParameters)}`,
        headers: createHeaders(false),
        method: 'GET'
    }
});

export const getUser = (id) => ({
    [RSAA]: {
        types: createRsaaTypes(ADMIN_USERS_GET),
        endpoint: `/api/admin/users/${id}`,
        headers: createHeaders(false),
        method: 'GET'
    }
});

export const getCurrentUserLoginToken = () => ({
    [RSAA]: {
        types: createRsaaTypes(ADMIN_USERS_GET_CURRENT_LOGIN_TOKEN),
        endpoint: '/api/admin/users/current/loginToken',
        headers: createHeaders(false),
        method: 'GET'
    }
});

export const updateUser = (id, updateUserData) => ({
    [RSAA]: {
        types: createRsaaTypes(ADMIN_USERS_UPDATE),
        endpoint: `/api/admin/users/${id}`,
        headers: createHeaders(true),
        method: 'PUT',
        body: JSON.stringify(updateUserData)
    }
});

export const setSearchParameters = (searchParameters) => ({
    type: ADMIN_USERS_SET_SEARCH_PARAMETERS,
    searchParameters
});
