import { RSAA } from 'redux-api-middleware';
import { createRsaaTypes, createHeaders } from './actionHelpers';

export const IP_LOCATIONS_GET_IP_LOCATION = 'IP_LOCATIONS_GET_IP_LOCATION';
export const IP_LOCATIONS_GET_IP_LOCATION_SUCCESS = 'IP_LOCATIONS_GET_IP_LOCATION_SUCCESS';
export const IP_LOCATIONS_GET_IP_LOCATION_FAILURE = 'IP_LOCATIONS_GET_IP_LOCATION_FAILURE';

export const getIpLocation = () => ({
    [RSAA]: {
        types: createRsaaTypes(IP_LOCATIONS_GET_IP_LOCATION),
        endpoint: '/api/ipLocations',
        headers: createHeaders(false),
        method: 'GET'
    }
});
