import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from 'styles/util';

import Box from '@material-ui/core/Box';
import CircularProgress from '@material-ui/core/CircularProgress';

const Loader = ({ left = 0, top = 100, size = 70, minHeight = 300, color }) => {
    const useStyles = makeStyles(({ theme }) => ({
        container: {
            minHeight: `${minHeight}px`,
            position: 'relative',
            textAlign: 'center',
            padding: theme.spacing(2),
            margin: '0 auto'
        },
        circularProgress: {
            display: 'inline-block',
            position: 'relative',
            color: color
        }
    }));
    const classes = useStyles();

    return (
        <Box className={classes.container}>
            <CircularProgress
                size={size}
                top={top}
                left={left}
                color="primary"
                className={classes.circularProgress}
            />
        </Box>
    );
};

Loader.propTypes = {
    left: PropTypes.number,
    top: PropTypes.number,
    size: PropTypes.number,
    minHeight: PropTypes.number,
    color: PropTypes.string
};

export default Loader;
