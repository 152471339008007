import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from 'styles/util';
import strings from 'localization/strings';
import { useField } from 'react-final-form';
import dateRuleTypes from 'enums/dateRuleTypes';
import openingModes from 'enums/openingModes';
import daysOfWeek from 'enums/daysOfWeek';
import openingTimeSpanActions from 'enums/openingTimeSpanActions';
import { getLongDateFormat, formatIsoDate } from 'helpers/DateHelper';
import { capitalizeFirstLetter } from 'helpers/StringHelper';
import { getWeekIntervalTitle } from 'helpers/OpeningTimeSpanHelper';
import { required, requiredIf, timeOfDayIf } from 'form/validation';

import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import MenuItem from '@material-ui/core/MenuItem';
import { TextField, Checkboxes, showErrorOnBlur } from 'mui-rff';
import DatePicker from 'form/DatePicker';
import NullableDatePicker from 'form/NullableDatePicker';
import Avatar from '@material-ui/core/Avatar';
import { FieldArray } from 'react-final-form-arrays';
import AddIcon from '@material-ui/icons/Add';
import IconButton from '@material-ui/core/IconButton';
import DeleteOutlineOutlinedIcon from '@material-ui/icons/DeleteOutlineOutlined';

const useStyles = makeStyles(({ theme, colors }) => ({
    ruleContainer: {
        display: 'flex',
        marginBottom: theme.spacing(2)
    },
    labelContainer: {
        flex: '0 0 auto',
        marginRight: theme.spacing(2),
        paddingLeft: theme.spacing(2),
        paddingTop: theme.spacing(1.5),
        borderLeft: `2px solid ${colors.primaryColor}`
    },
    ruleLines: {
        flex: '1 1 auto'
    },
    ruleLine1: {
        display: 'flex',
        '& > :not(:last-child)': {
            paddingRight: theme.spacing(2)
        }
    },
    ruleLine2: {
        display: 'flex',
        flexWrap: 'wrap',
        '& > :not(:last-child)': {
            paddingRight: theme.spacing(2)
        }
    },
    typeContainer: {
        flex: '1 1 auto',
    },
    modeContainer: {
        flex: '1 1 auto',
    },
    timeSpanContainer: {
        flex: '1 1 auto',
        display: 'flex'
    },
    actionContainer: {
        flex: '1 1 auto',
    },
    timeContainer: {
        flex: '1 1 auto',
    },
    dashContainer: {
        flex: '0 0 auto',
        padding: theme.spacing(0, 2),
        alignSelf: 'center'
    },
    removeButton: {
        flex: '0 0 auto',
        alignSelf: 'center',
        minWidth: 'auto',
        marginLeft: theme.spacing(1),
        color: colors.danger,
        borderColor: colors.danger
    },

    dayOfWeekPatternDaysOfWeekContainer: {
        flex: '0 0 auto',
        alignSelf: 'center'
    },
    dayOfWeekPatternFromContainer: {
        flex: '0 0 auto'
    },
    dayOfWeekPatternToContainer: {
        flex: '0 0 auto'
    },
    dayOfWeekPatternWeekIntervalContainer: {
        flex: '0 0 auto'
    },

    dateIntervalFromContainer: {
        flex: '0 0 auto'
    },
    dateIntervalToContainer: {
        flex: '0 0 auto'
    },

    datesDatesContainer: {
        flex: '0 0 auto'
    },
    datesDateRow: {
        display: 'flex',
        alignItems: 'center'
    },
    datesDatePicker: {
        flex: '0 0 12em'
    },
    datesDateRemoveButton: {
        flex: '0 0 auto',
        minWidth: 'auto',
        marginLeft: theme.spacing(1),
        color: colors.danger,
        borderColor: colors.danger
    },
    datesAddButton: {
        minWidth: 'auto'
    },

    label: {
        backgroundColor: colors.primaryColor
    }
}));

const OpeningTimeSpanRuleEditor = ({ label, fieldNamePrefix, onRemove }) => {
    const classes = useStyles();
    const getName = suffix => suffix ? `${fieldNamePrefix}.${suffix}` : fieldNamePrefix;
    const getValue = suffix => useField(getName(suffix)).input.value;

    const values = getValue();

    const weekIntervals = [1, 2, 3, 4]
        .map(value => ({ value, title: capitalizeFirstLetter(getWeekIntervalTitle(value)) }));

    const actions = [{
        value: 'all',
        title: strings.bothCheckInAndCheckOut
    }].concat(Object.values(openingTimeSpanActions).map(o => ({
        value: o.key,
        title: o.title
    })));

    const hasTypeSpecificFields = () => {
        switch(values.type) {
            case dateRuleTypes.dayOfWeekPattern.key:
            case dateRuleTypes.dateInterval.key:
            case dateRuleTypes.dates.key:
                return true;
            default:
                return false;
        }
    };

    const atLeastOneDayOfWeekValidator = value => {
        if(value.length > 0) {
            return undefined;
        }
        return 'strings.atLeastOneDayOfWeekMustBeGiven';
    };

    const handleAddDate = fields => {
        fields.push('');
    };

    const handleRemoveDate = (fields, index) => {
        fields.remove(index);
    };

    const renderTypeSpecificFields = () => {
        switch(values.type) {
            case dateRuleTypes.dayOfWeekPattern.key:
                return (
                    <>
                        <Box className={classes.dayOfWeekPatternDaysOfWeekContainer}>
                            <Checkboxes
                                formGroupProps={{ row: true }}
                                name={getName('dayOfWeekPattern.daysOfWeek')}
                                label={strings.daysOfWeek}
                                data={Object.values(daysOfWeek).map(o => ({
                                    label: o.shortTitle,
                                    value: o.key
                                }))}
                                fieldProps={{ validate: atLeastOneDayOfWeekValidator }}
                                showError={showErrorOnBlur}
                            />
                        </Box>
                        <Box className={classes.dayOfWeekPatternWeekIntervalContainer}>
                            <TextField
                                size="small"
                                select
                                name={getName('dayOfWeekPattern.weekInterval')}
                                variant="outlined"
                            >
                                {
                                    weekIntervals.map(o => (
                                        <MenuItem key={o.value} value={o.value}>
                                            {o.title}
                                        </MenuItem>
                                    ))
                                }
                            </TextField>
                        </Box>
                        <Box className={classes.dayOfWeekPatternFromContainer}>
                            <NullableDatePicker
                                name={getName('dayOfWeekPattern.from')}
                                label={strings.fromUppercase}
                                formatFunc={getLongDateFormat}
                                size="small"
                                inputVariant="outlined"
                                nullString={''}
                                nullButtonLabel={strings.remove}
                                showError={showErrorOnBlur}
                                fieldProps={{ maxDate: formatIsoDate(values.dateInterval.to), validate: requiredIf(values.dayOfWeekPattern.weekInterval > 1) }}
                            />
                        </Box>
                        <Box className={classes.dayOfWeekPatternToContainer}>
                            <NullableDatePicker
                                name={getName('dayOfWeekPattern.to')}
                                label={strings.toUppercase}
                                formatFunc={getLongDateFormat}
                                size="small"
                                inputVariant="outlined"
                                nullString={''}
                                nullButtonLabel={strings.remove}
                                showError={showErrorOnBlur}
                                fieldProps={{ minDate: formatIsoDate(values.dateInterval.from) }}
                            />
                        </Box>
                    </>
                );
            case dateRuleTypes.dateInterval.key:
                return (
                    <>
                        <Box className={classes.dateIntervalFromContainer}>
                            <DatePicker
                                name={getName('dateInterval.from')}
                                label={strings.fromUppercase}
                                formatFunc={getLongDateFormat}
                                size="small"
                                inputVariant="outlined"
                                showError={showErrorOnBlur}
                                fieldProps={{ maxDate: formatIsoDate(values.dateInterval.to), validate: required }}
                            />
                        </Box>
                        <Box className={classes.dateIntervalToContainer}>
                            <DatePicker
                                name={getName('dateInterval.to')}
                                label={strings.toUppercase}
                                formatFunc={getLongDateFormat}
                                size="small"
                                inputVariant="outlined"
                                showError={showErrorOnBlur}
                                fieldProps={{ minDate: formatIsoDate(values.dateInterval.from), validate: required }}
                            />
                        </Box>
                    </>
                );
            case dateRuleTypes.dates.key:
                return (
                    <Box className={classes.datesDatesContainer}>
                        <FieldArray name={getName('dates.dates')}>
                            {({ fields }) =>
                                <>
                                    {
                                        fields.map((name, index) => (
                                            <Box key={index} className={classes.datesDateRow}>
                                                <Box className={classes.datesDatePicker}>
                                                    <DatePicker
                                                        name={name}
                                                        label={strings.date}
                                                        formatFunc={getLongDateFormat}
                                                        size="small"
                                                        inputVariant="outlined"
                                                        showError={showErrorOnBlur}
                                                        fieldProps={{ validate: required, initialFocusedDate: index === 0 ? undefined : formatIsoDate(fields.value[index - 1]) }}
                                                    />
                                                </Box>
                                                {
                                                    values.dates.dates.length > 1 &&
                                                    (
                                                        <IconButton
                                                            className={classes.datesDateRemoveButton}
                                                            onClick={() => handleRemoveDate(fields, index)}
                                                        >
                                                            <DeleteOutlineOutlinedIcon/>
                                                        </IconButton>
                                                    )
                                                }
                                            </Box>
                                        ))
                                    }
                                    <Button
                                        className={classes.datesAddButton}
                                        size="small"
                                        variant="outlined"
                                        color="primary"
                                        onClick={() => handleAddDate(fields)}
                                    >
                                        <AddIcon/>
                                    </Button>
                                </>
                            }
                        </FieldArray>
                    </Box>
                );
            default:
                return undefined;
        }
    };

    return (
        <Box className={classes.ruleContainer}>
            <Box className={classes.labelContainer}>
                <Avatar className={classes.label}>
                    {label}
                </Avatar>
            </Box>
            <Box className={classes.ruleLines}>
                <Box className={classes.ruleLine1}>

                    <Box className={classes.typeContainer}>
                        <TextField
                            select
                            name={getName('type')}
                            size="small"
                            variant="outlined"
                        >
                            {
                                Object.values(dateRuleTypes).map(o => (
                                    <MenuItem key={o.key} value={o.key}>
                                        {o.title}
                                    </MenuItem>
                                ))
                            }
                        </TextField>
                    </Box>

                    <Box className={classes.modeContainer}>
                        <TextField
                            select
                            name={getName('mode')}
                            size="small"
                            variant="outlined"
                        >
                            {
                                Object.values(openingModes).map(o => (
                                    <MenuItem key={o.key} value={o.key}>
                                        {o.title}
                                    </MenuItem>
                                ))
                            }
                        </TextField>
                    </Box>

                    <IconButton onClick={onRemove} className={classes.removeButton}>
                        <DeleteOutlineOutlinedIcon/>
                    </IconButton>
                </Box>

                {
                    values.mode === openingModes.open.key &&
                    (
                        <Box className={classes.ruleLine1}>
                            <Box className={classes.timeSpanContainer}>
                                <Box className={classes.timeContainer}>
                                    <TextField
                                        name={getName('fromTime')}
                                        label={strings.fromTime}
                                        size="small"
                                        variant="outlined"
                                        showError={showErrorOnBlur}
                                        fieldProps={{ validate: timeOfDayIf(values.toTime) }}
                                    />
                                </Box>
                                <Box className={classes.dashContainer}>
                                    &ndash;
                                </Box>
                                <Box className={classes.timeContainer}>
                                    <TextField
                                        name={getName('toTime')}
                                        label={strings.toTime}
                                        size="small"
                                        variant="outlined"
                                        showError={showErrorOnBlur}
                                        fieldProps={{ validate: timeOfDayIf(values.fromTime) }}
                                    />
                                </Box>
                            </Box>

                            <Box className={classes.actionContainer}>
                                <TextField
                                    select
                                    name={getName('restrictedToAction')}
                                    size="small"
                                    variant="outlined"
                                >
                                    {
                                        actions.map(o => (
                                            <MenuItem key={o.value} value={o.value}>
                                                {o.title}
                                            </MenuItem>
                                        ))
                                    }
                                </TextField>
                            </Box>
                        </Box>
                    )
                }

                {
                    hasTypeSpecificFields() &&
                    (
                        <Box className={classes.ruleLine2}>
                            {renderTypeSpecificFields()}
                        </Box>
                    )
                }
            </Box>
        </Box>
    );
};

OpeningTimeSpanRuleEditor.propTypes = {
    label: PropTypes.any.isRequired,
    fieldNamePrefix: PropTypes.string.isRequired,
    onRemove: PropTypes.func.isRequired
};

export default OpeningTimeSpanRuleEditor;
