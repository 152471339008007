import strings from 'localization/strings';

export const enhanceStripeErrorMessage = response => {
    const error = response.error;
    if (error?.type === 'card_error' &&
        error?.code === 'card_declined' &&
        error?.decline_code === 'generic_decline') {
        return `${strings.stripeGenericDeclineInformation} ${error.message}`;
    }
    return error?.message;
};
