import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { useSnackbar } from 'notistack';

const SnackbarWrapper = ({ children }) => {
    const { enqueueSnackbar } = useSnackbar();

    const snackbarMessage = useSelector(state => state.admin.general.snackbarMessage);
    const snackbarMessageOptions = useSelector(state => state.admin.general.snackbarMessageOptions);
    const snackbarMessageTime = useSelector(state => state.admin.general.snackbarMessageTime);

    useEffect(() => {
        if(snackbarMessageTime) {
            enqueueSnackbar(snackbarMessage, snackbarMessageOptions);
        }
    }, [snackbarMessageTime]);

    return <>{children}</>;
};

SnackbarWrapper.propTypes = {
    children: PropTypes.oneOfType([
        PropTypes.arrayOf(PropTypes.node),
        PropTypes.node
    ])
};

export default SnackbarWrapper;
