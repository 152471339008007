import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router';
import strings from 'localization/strings';
import { makeStyles } from 'styles/util';
import landingPages from 'enums/landingPages';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { handleResponse } from 'actions/actionHelpers';
import { fetchGeographicalLocations } from 'actions/geographicalLocations';
import geographicalLocationTypes from 'enums/geographicalLocationTypes';
import routes from 'routes';
import { useScrollToTop } from 'helpers/BrowserHelper';

import PageTitle from 'common/PageTitle';
import Container from 'common/Container';
import Box from '@material-ui/core/Box';
import { Link } from 'react-router-dom';
import Loader from 'common/Loader';
import Typography from '@material-ui/core/Typography';

const GeographyListPage = () => {
    const isMobile = !useMediaQuery(theme => theme.breakpoints.up('md'));
    const useStyles = makeStyles(({ theme }) => ({
        geographyBox: {
            marginBottom: theme.spacing(4)
        },
        geographyList: {
            display: 'flex',
            flexWrap: 'wrap',
            '& > *': {
                flex: isMobile
                    ? '0 0 100%'
                    : '0 0 25%'
            }
        }
    }));
    const classes = useStyles();
    const dispatch = useDispatch();
    const params = useParams();

    const [geographicalLocations, setGeographicalLocations] = useState(undefined);
    const [isLoading, setIsLoading] = useState(true);

    const landingPage = Object.values(landingPages).find(o => o.url === '/' + params.landingPage);

    const getUrl = geographyLocation => routes.landingPageWithLocation.replace('/:landingPage', landingPage.url).replace(':locationText', geographyLocation.name);

    const createGeographyLinkList = geographyLocations => (
        <ul className={classes.geographyList}>
            {
                geographyLocations.map(geographyLocation => (
                    <li key={geographyLocation.name}>
                        <Link to={getUrl(geographyLocation)}>{strings.formatString(landingPage.geographyListTitle, geographyLocation.name)}</Link>
                    </li>
                ))
            }
        </ul>
    );

    useScrollToTop();

    useEffect(() => {
        dispatch(fetchGeographicalLocations())
            .then(handleResponse(
                response => {
                    setGeographicalLocations(response.payload);
                    setIsLoading(false);
                },
                () => {
                    setIsLoading(false);
                }
            ));
    }, []);

    return (
        <Container withPageMargins>
            <PageTitle>{strings.geographyListTitles[landingPage.key]}</PageTitle>
            <Typography variant="h1" gutterBottom>{strings.geographyListTitles[landingPage.key]}</Typography>
            {
                isLoading && <Loader />
            }
            {
                geographicalLocations &&
                (
                    <>
                        <Box className={classes.geographyBox}>
                            <Typography variant="h2" gutterBottom>{strings.regions}</Typography>
                            {createGeographyLinkList(geographicalLocations.filter(o => o.type === geographicalLocationTypes.region.key))}
                        </Box>
                        <Box className={classes.geographyBox}>
                            <Typography variant="h2" gutterBottom>{strings.municipalities}</Typography>
                            {createGeographyLinkList(geographicalLocations.filter(o => o.type === geographicalLocationTypes.municipality.key))}
                        </Box>
                    </>
                )
            }
        </Container>
    );
};

export default GeographyListPage;
