import React from 'react';
import PropTypes from 'prop-types';
import arrayMutators from 'final-form-arrays';
import { changeValueMutator } from 'helpers/FormHelper';

import Form from 'form/Form';

const StorageSiteForm = ({ initialValues, validate, onSubmit, children }) => {
    return (
        <Form
            initialValues={initialValues}
            mutators={{
                ...arrayMutators,
                changeValue: changeValueMutator
            }}
            validate={validate}
            validateOnBlur
            onSubmit={onSubmit}
        >
            {children}
        </Form>
    );
};

StorageSiteForm.propTypes = {
    initialValues: PropTypes.object.isRequired,
    validate: PropTypes.func,
    onSubmit: PropTypes.func.isRequired,
    children: PropTypes.func.isRequired,
};

export default StorageSiteForm;
