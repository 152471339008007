import React from 'react';
import { makeStyles } from 'styles/util';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { acceptTermsOfService } from 'actions/users';
import strings from 'localization/strings';
import { handleResponse } from 'actions/actionHelpers';
import routes from 'routes';

import Container from 'common/Container';
import PageTitle from 'common/PageTitle';
import ShadowBox from 'common/ShadowBox';
import ButtonContainer from 'common/ButtonContainer';
import Button from '@material-ui/core/Button';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import Loader from 'common/Loader';
import Form from 'form/Form';
import AcceptTermsOfServiceCheckbox from 'components/AcceptTermsOfServiceCheckbox';
import Text from 'common/Text';
import Separator from 'common/Separator';

const useStyles = makeStyles(({ theme }) => ({
    weHaveUpdatedOurPolicies: {
        marginBottom: theme.spacing(2)
    }
}));

const AcceptTermsOfServicePage = () => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const history = useHistory();

    const loading = useSelector(state => state.authentication.loading);
    const authenticationContext = useSelector(state => state.authentication.context);
    const user = authenticationContext.user;
    const redirectUrl = useSelector(state => state.authentication.redirectUrl);

    const handleFormSubmit = values => {
        dispatch(acceptTermsOfService(user.id, values))
            .then(handleResponse(
                () => {
                    if (redirectUrl) {
                        history.push(redirectUrl);
                    } else {
                        history.push(routes.account.settings);
                    }
                }
            ));
    };

    const handleCancelAndLogOut = () => {
        history.push(routes.logOut);
    };

    // approve updated terms of service
    const initialValues = {
        accept: false
    };

    const title = strings.updatedGeneralPoliciesStorage365;

    return (
        <Container withPageMargins>
            <PageTitle>{title}</PageTitle>
            <ShadowBox title={title}>
                { loading && <Loader/> }

                <Form
                    initialValues={initialValues}
                    onSubmit={handleFormSubmit}
                >
                    {({ handleSubmit, invalid }) => (
                        <form onSubmit={handleSubmit} className="x">
                            {
                                !loading && user &&
                                (
                                    <>
                                        <Box className={classes.weHaveUpdatedOurPolicies}>
                                            <Typography variant="body1" gutterBottom>
                                                {strings.weHaveUpdatedOurPolicies}
                                            </Typography>

                                            <Separator spacing={4}/>

                                            <Text html={strings.mostRecentGeneralPoliciesUpdateInformation} />

                                            <Typography variant="body1">
                                                <a href={routes.policies.general} target="_blank">{strings.generalPoliciesLinkText}</a>
                                            </Typography>

                                            <Separator spacing={4}/>
                                        </Box>

                                        <Box>
                                            <AcceptTermsOfServiceCheckbox
                                                name="accept"
                                                label={strings.iAcceptUpdatedPoliciesForStorage365}
                                                showLink={false}
                                            />
                                        </Box>

                                        <ButtonContainer>
                                            <Button
                                                type="submit"
                                                variant="contained"
                                                disabled={invalid}
                                                color="primary"
                                            >
                                                {strings.acceptUserPolicies}
                                            </Button>
                                            <Button
                                                onClick={handleCancelAndLogOut}
                                                variant="outlined"
                                                color="primary"
                                            >
                                                {strings.cancelAndLogout}
                                            </Button>
                                        </ButtonContainer>
                                    </>
                                )
                            }
                        </form>
                    )}
                </Form>
            </ShadowBox>
        </Container>
    );
};

export default AcceptTermsOfServicePage;
