import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { makeStyles } from 'styles/util';
import { handleResponse } from 'actions/actionHelpers';
import routes from 'routes';
import { getTakeover, updateTakeoverTenant, deleteTakeoverTenant } from 'actions/admin/takeovers';
import { getTakeoverTenantExcelFile } from 'actions/reports';
import { getBusinessOrPrivateName } from 'helpers/ActorHelper';
import paymentProviders from 'enums/paymentProviders';

import AdminPage from '../../presentational/AdminPage';
import AdminContentWrapper from '../../presentational/AdminContentWrapper';
import Button from '@material-ui/core/Button';
import { Link } from 'react-router-dom';
import Box from '@material-ui/core/Box';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Loader from 'common/Loader';
import Amount from 'common/Amount';
import LinkButton from 'common/LinkButton';
import EditTenantDialog from './EditTenantDialog';

const useStyles = makeStyles(({ theme }) => ({
    actionPanel: {
        display: 'flex',
        gap: theme.spacing(1)
    }
}));

const Tenants = () => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const params = useParams();
    const history = useHistory();

    const [takeover, setTakeover] = useState(undefined);
    const [isLoading, setIsLoading] = useState(true);
    const [selectedTenant, setSelectedTenant] = useState(undefined);
    const [editTenantDialogOpen, setEditTenantDialogOpen] = useState(false);
    const [editTenantDialogIsSubmitting, setEditTenantDialogIsSubmitting] = useState(false);

    const backUrl = routes.admin.takeover.replace(':takeoverId', params.takeoverId);

    useEffect(() => {
        dispatch(getTakeover(params.takeoverId))
            .then(handleResponse(
                response => {
                    setTakeover(response.payload);
                    setIsLoading(false);
                },
                () => setIsLoading(false)
            ));
    }, []);

    const handleTenantClick = (tenant, index) => {
        setSelectedTenant({ ...tenant, index });
        setEditTenantDialogOpen(true);
    };

    const handleEditTenantDialogOk = updatedTenant => {
        setEditTenantDialogIsSubmitting(true);
        dispatch(updateTakeoverTenant(params.takeoverId, selectedTenant.id, updatedTenant))
            .then(handleResponse(
                response => {
                    const updatedTakeover = { ...takeover };
                    updatedTakeover.tenants[selectedTenant.index] = response.payload;
                    setTakeover(updatedTakeover);
                    setEditTenantDialogOpen(false);
                    setEditTenantDialogIsSubmitting(false);
                },
                () => {
                    setEditTenantDialogOpen(false);
                    setEditTenantDialogIsSubmitting(false);
                }
            ));
    };

    const handleEditTenantDialogDelete = () => {
        setEditTenantDialogIsSubmitting(true);
        dispatch(deleteTakeoverTenant(params.takeoverId, selectedTenant.id))
            .then(handleResponse(
                () => {
                    const updatedTakeover = { ...takeover };
                    updatedTakeover.tenants.splice(selectedTenant.index, 1);
                    setTakeover(updatedTakeover);
                    setEditTenantDialogOpen(false);
                    setEditTenantDialogIsSubmitting(false);
                    if(updatedTakeover.tenants.length === 0) {
                        history.push(backUrl);
                    }
                },
                () => {
                    setEditTenantDialogOpen(false);
                    setEditTenantDialogIsSubmitting(false);
                }
            ));
    };

    const handleEditTenantDialogCancel = () => {
        setEditTenantDialogOpen(false);
    };

    const title = 'Tenants: ' + (takeover ? takeover.storageSite.title : 'Loading...');

    const handleExport = () => {
        setIsLoading(true);
        dispatch(getTakeoverTenantExcelFile({ takeoverId: params.takeoverId }))
            .then(handleResponse(
                () => setIsLoading(false),
                () => setIsLoading(false)
            ));
    };

    const actionPanel = (
        <Box className={classes.actionPanel}>
            <Button
                type="button"
                variant="contained"
                color="primary"
                onClick={handleExport}
            >
                Export to Excel
            </Button>
            <Button
                type="button"
                variant="contained"
                color="primary"
                component={Link}
                to={backUrl}
            >
                Back
            </Button>
        </Box>
    );

    return (
        <AdminPage title={title} actionPanel={actionPanel}>
            {
                isLoading && <Loader />
            }
            {
                takeover &&
                (
                    <AdminContentWrapper type="listing">
                        <Table size="small">
                            <TableHead>
                                <TableRow>
                                    <TableCell>Identifier</TableCell>
                                    <TableCell>Name</TableCell>
                                    <TableCell>Storage</TableCell>
                                    <TableCell align="right">Price excl VAT</TableCell>
                                    <TableCell align="right">Price incl VAT</TableCell>
                                    <TableCell>Status</TableCell>
                                    <TableCell>Paid</TableCell>
                                    <TableCell>Initial purchase payment provider</TableCell>
                                    <TableCell>Booking payment provider</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {
                                    takeover.tenants.map((tenant, index) => (
                                        <TableRow key={index}>
                                            <TableCell>{tenant.externalIdentifier}</TableCell>
                                            <TableCell>
                                                <LinkButton onClick={() => handleTenantClick(tenant, index)}>
                                                    {getBusinessOrPrivateName(tenant)}
                                                </LinkButton>
                                            </TableCell>
                                            <TableCell>{tenant.storageTitle}</TableCell>
                                            <TableCell align="right"><Amount value={tenant.subscriptionPeriodPrice} currency={tenant.currency}/></TableCell>
                                            <TableCell align="right"><Amount value={tenant.subscriptionPeriodPrice + tenant.subscriptionPeriodVat} currency={tenant.currency}/></TableCell>
                                            <TableCell>{tenant.status}</TableCell>
                                            <TableCell>{tenant.hasPaid ? 'yes' : 'no'}</TableCell>
                                            <TableCell>{tenant.bookingPaymentProvider ? paymentProviders[tenant.bookingPaymentProvider].providerName : ''}</TableCell>
                                            <TableCell>{tenant.initialPurchasePaymentProvider ? paymentProviders[tenant.initialPurchasePaymentProvider].providerName : ''}</TableCell>
                                        </TableRow>
                                    ))
                                }
                            </TableBody>
                        </Table>

                        <EditTenantDialog
                            tenant={selectedTenant}
                            takeover={takeover}
                            open={editTenantDialogOpen}
                            isSubmitting={editTenantDialogIsSubmitting}
                            onOk={handleEditTenantDialogOk}
                            onDelete={handleEditTenantDialogDelete}
                            onCancel={handleEditTenantDialogCancel}
                        />
                    </AdminContentWrapper>
                )
            }
        </AdminPage>
    );
};

export default Tenants;
