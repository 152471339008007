import React from 'react';
import PropTypes from 'prop-types';
import { useAppContext } from 'context/AppContext';
import strings from 'localization/strings';
import { formatDateTime } from 'helpers/DateHelper';
import { makeStyles } from 'styles/util';

import { DatePicker as MuiRffDatePicker } from 'mui-rff';

const useStyles = makeStyles(({ theme }) => ({
    datePicker: {
        // zero padding to enable header background
        '& .MuiPickersModal-dialogRoot': {
            padding: 0
        },
        // margin for button container at the bottom of the picker dialog
        '& .MuiDialogActions-root': {
            margin: theme.spacing(0, 1, 1, 1)
        }
    }
}));

const DatePicker = ({ fieldProps = {}, ...rest }) => {
    const classes = useStyles();
    const { appContext } = useAppContext();

    const additionalFieldProps = {
        clearable: false,
        okLabel: strings.ok,
        cancelLabel: strings.cancel,
        labelFunc: date => formatDateTime(date, rest.formatFunc, appContext)
    };
    const muiRffDatePickerProps = {
        ...rest,
        format: rest.formatFunc(appContext)
    };
    delete muiRffDatePickerProps.formatFunc;
    return (
        <MuiRffDatePicker
            {...muiRffDatePickerProps}
            fieldProps={{ ...fieldProps, ...additionalFieldProps }}
            DialogProps={{ className: classes.datePicker}}
        />
    );
};

DatePicker.propTypes = {
    fieldProps: PropTypes.object
};

export default DatePicker;
