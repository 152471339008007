import strings from 'localization/strings';
import VehicleStoragesImage from 'assets/images/landing-pages/vehicle.jpg';
import StoreHouseImage from 'assets/images/landing-pages/store-house.jpg';
import BuildingsAndLandImage from 'assets/images/landing-pages/buildings-and-land.jpg';
import OfficeImage from 'assets/images/landing-pages/office.jpg';
import goodsTypes from 'enums/goodsTypes';
import storageTypes from 'enums/storageTypes';
import storage from 'assets/icons/storage_icon.svg';
import vehicle from 'assets/icons/vehicle_icon.svg';
import buildingsAndLand from 'assets/icons/buildings_land_icon.svg';
import office from 'assets/icons/storage_icon.svg'; // TODO
import mockStorageAdImage from 'assets/images/mock-ad-image-storage.jpg';
import mockVehicleAdImage from 'assets/images/mock-ad-image-vehicle.jpg';
import mockBuildingsAndLandAdImage from 'assets/images/mock-ad-image-building.jpg';

const sanitizeKeys = (keys, validObjects) => {
    return validObjects && keys
        ? validObjects.filter(o => keys.indexOf(o.key) !== -1).map(o => o.key)
        : undefined;
};

const arraysAreEqual = (first, second) => {
    if(first.length !== second.length) {
        return false;
    }
    for(let i = 0; i < first.length; i++) {
        if(first[i] !== second[i]) {
            return false;
        }
    }
    return true;
};
const landingPages = {
    storeHouse: {
        imageSrc: StoreHouseImage,
        useMainFilter: false,
        icon: storage,
        mockDataAdImage: mockStorageAdImage
    },

    vehicle: {
        imageSrc: VehicleStoragesImage,
        useMainFilter: true,
        icon: vehicle,
        goodsTypes: [
            goodsTypes.car,
            goodsTypes.boat,
            goodsTypes.caravan,
            goodsTypes.motorcycle,
            goodsTypes.snowmobile
        ],
        trimParams: params => {
            if (Array.isArray(params.goodsTypes) && arraysAreEqual(landingPages.vehicle.goodsTypes.map(o => o.key).sort(), params.goodsTypes.sort())) {
                params.goodsTypes = undefined;
            }
            params.goodsTypes = sanitizeKeys(params.goodsTypes, landingPages.vehicle.goodsTypes);
        },
        aliasKeys: ['vehiclePlace', 'winterVehicleStorage'],
        mockDataAdImage: mockVehicleAdImage
    },

    buildingsAndLand: {
        imageSrc: BuildingsAndLandImage,
        useMainFilter: true,
        icon: buildingsAndLand,
        storageTypes: Object.values(storageTypes),
        trimParams: params => {
            if (Array.isArray(params.storageTypes) && arraysAreEqual(landingPages.buildingsAndLand.storageTypes.map(o => o.key).sort(), params.storageTypes.sort())) {
                params.storageTypes = undefined;
            }
            params.storageTypes = sanitizeKeys(params.storageTypes, landingPages.buildingsAndLand.storageTypes);
        },
        mockDataAdImage: mockBuildingsAndLandAdImage
    },

    office: {
        imageSrc: OfficeImage,
        useMainFilter: false,
        icon: office
    }
};

Object.keys(landingPages).forEach(key => {
    landingPages[key].key = key;
});

const populateLandingPageTexts = (landingPage, key) => {
    landingPage.pageTitle = strings.storageGroupCategories[key].pageTitle ?? landingPage.pageTitle;
    landingPage.pageTitleWithGeography = strings.storageGroupCategories[key].pageTitleWithGeography ?? landingPage.pageTitleWithGeography;
    landingPage.geographyListTitle = strings.storageGroupCategories[key].geographyListTitle ?? landingPage.geographyListTitle;
    landingPage.label = strings.storageGroupCategories[key].title ?? landingPage.label;
    landingPage.shortLabel = strings.storageGroupCategories[key].shortTitle ?? landingPage.label;
    landingPage.shortestLabel = strings.storageGroupCategories[key].shortestTitle ?? landingPage.shortLabel;
    landingPage.nearTitle = strings.storageGroupCategories[key].nearTitle ?? landingPage.nearTitle;
    landingPage.allTitle = strings.storageGroupCategories[key].allTitle ?? landingPage.allTitle;
    landingPage.searchTitle = strings.storageGroupCategories[key].searchTitle;
    landingPage.url = `/${strings.storageGroupCategories[key].url}`;
    landingPage.metaDescription = strings.storageGroupCategories[key].metaDescription;
    landingPage.rentingTitle = strings.storageGroupCategories[key].rentingTitle ?? landingPage.rentingTitle;
    landingPage.assistanceToFindCaption = strings.storageGroupCategories[key].assistanceToFindCaption ?? landingPage.assistanceToFindCaption;
    landingPage.assistanceToFindBody = strings.storageGroupCategories[key].assistanceToFindBody ?? landingPage.assistanceToFindBody;
    landingPage.aliases = (landingPage.aliasKeys ?? [])
        .filter(aliasKey => strings.storageGroupCategories[aliasKey])
        .map(aliasKey => {
            const aliasLandingPage = {
                ...landingPage,
                aliasKeys: []
            };
            populateLandingPageTexts(aliasLandingPage, aliasKey);
            return aliasLandingPage;
        });
};

const populateMockDataAds = (landingPage, key) => {
    landingPage.mockDataAd = {
        id: 1,
        title: strings.storageGroupCategories[key].mockDataAd.title ?? '',
        address: strings.storageGroupCategories[key].mockDataAd.address ?? '',
        city: strings.storageGroupCategories[key].mockDataAd.city ?? '',
        createdTime: '2024-01-01T10:00:00.12Z',
        facilities: [
            'electricity',
            'indoor',
            'heating',
            'fenced'
        ],
        images: [
            {
                id: 1,
                mediaUrl: landingPage.mockDataAdImage,
                order: 0
            }
        ],
        attentionText: strings.storageGroupCategories[key].mockDataAd.attentionText ?? null,
        storageGroups: [
            {
                id: 1,
                category: key,
                rentalType: 'wholeSite',
                status: 'active',
                storageWidth: 3,
                storageLength: 4,
                ceilingHeight: 2.5,
                storageArea: strings.storageGroupCategories[key].mockDataAd.storageGroup?.storageArea || 12,
                pricePerStorage: strings.storageGroupCategories[key].mockDataAd.storageGroup?.pricePerStorage || 990,
                nonDiscountedPricePerStorage: strings.storageGroupCategories[key].mockDataAd.storageGroup?.nonDiscountedPricePerStorage || 990,
                currency: 'SEK',
                vatRate: 0,
                vatMode: 'notEligibleForVat',
                enumerateStorages: false,
                createdTime: '2024-01-01T13:00:00.8Z',
                lockConfigurations: []
            }
        ],
        discounts: [],
    };
};

strings.addLoadCallback(() => {
    Object.keys(landingPages).forEach(key => {
        populateLandingPageTexts(landingPages[key], key);
        populateMockDataAds(landingPages[key], key);
    });
});

export default landingPages;
