import React from 'react';
import { useLocation } from 'react-router-dom';
import strings from 'localization/strings';
import { makeStyles } from 'styles/util';
import queryString from 'query-string';

import Container from 'common/Container';
import VisibleFor from 'common/VisibleFor';
import Text from 'common/Text';

const useStyles = makeStyles(() => ({
    container: {
        fontSize: '12px',
        '& p': {
            fontSize: '12px'
        }
    }
}));

const DefaultAgreementPage = () => {
    const classes = useStyles();
    const location = useLocation();
    const { margin } = queryString.parse(location.search);
    const pageBreak = '<div style="page-break-after: always;"></div>';

    const wrapInContainer = children => margin
        ? <Container withPageMargins>{children}</Container>
        : children;

    return wrapInContainer(
        <>
            <VisibleFor branch="storage">
                <Text component="div" className={classes.container} html={strings.pages.generalPolicy + pageBreak + strings.pages.storingPolicy + pageBreak + strings.pages.notAllowedPolicy} ignoreNewlines />
            </VisibleFor>
            <VisibleFor branch="office">
                <Text component="div" className={classes.container} html={strings.pages.defaultAgreement + pageBreak + strings.pages.notAllowedPolicy} ignoreNewlines />
            </VisibleFor>
        </>
    );
};

export default DefaultAgreementPage;
