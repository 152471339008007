const eidProviders = {
    swedishBankId: {},
    eIdent: {},
    mock: {}
};

Object.keys(eidProviders).forEach(key => {
    eidProviders[key].key = key;
});

export default eidProviders;
