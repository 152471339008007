import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from 'styles/util';
import strings from 'localization/strings';
import { formFieldKeys } from 'helpers/StorageSearchFormHelper';
import { createEndAdornment } from 'helpers/FormHelper';

import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import { TextField } from 'mui-rff';
import WithStoragePopoverWrapper from 'components/storageSearchForm/WithStoragePopoverWrapper';

const useStyles = makeStyles(({ theme }) => ({
    sizeSelectorWrapper: {
        display: 'flex',
        paddingBottom: theme.spacing(1)
    },
    storageSearchFormRow: {
        flex: '1',
        '& .MuiOutlinedInput-root': {
            borderRadius: theme.spacing(1)
        }
    },
    caption: {
        fontWeight: 'bold',
        fontSize: '80%',
        marginBottom: theme.spacing(1)
    }
}));

// workaround for including empty values
const identity = value => value;

const SizeSelector = () => {
    const classes = useStyles();
    return (
        <>
            <Typography className={classes.caption}>{strings.size}</Typography>
            <Box className={classes.sizeSelectorWrapper}>
                <Box className={classes.storageSearchFormRow}>
                    <TextField
                        label={strings.storageSearchForm.minStorageSize}
                        name={formFieldKeys.minArea}
                        size="small"
                        variant="outlined"
                        fieldProps={{ parse: identity }}
                        InputLabelProps={{ shrink: true }}
                        InputProps={createEndAdornment(strings.squareMeter)}
                    />
                </Box>
            </Box>
            <Box className={classes.sizeSelectorWrapper}>
                <Box className={classes.storageSearchFormRow}>
                    <TextField
                        label={strings.storageSearchForm.maxStorageSize}
                        name={formFieldKeys.maxArea}
                        size="small"
                        variant="outlined"
                        fieldProps={{ parse: identity }}
                        InputLabelProps={{ shrink: true }}
                        InputProps={createEndAdornment(strings.squareMeter)}
                    />
                </Box>
            </Box>
        </>
    );
};

SizeSelector.propTypes = {
    className: PropTypes.string
};

export default WithStoragePopoverWrapper(SizeSelector);
