import React from 'react';
import PropTypes from 'prop-types';
import { useAppContext } from 'context/AppContext';
import { mustBeNullOrNumber } from 'form/validation';
import strings from 'localization/strings';
import { getAvailablePaymentProviders } from 'logic/paymentLogic';
import paymentProviders from 'enums/paymentProviders';
import { formatPercentage } from 'helpers/StringHelper';

import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import { TextField, Checkboxes, showErrorOnBlur } from 'mui-rff';

const EditStep6 = ({ showStepNumber = true, actor }) => {
    const { appContext } = useAppContext();
    const fieldNamePrefix = 'steps[6]';
    const getName = suffix => suffix ? `${fieldNamePrefix}.${suffix}` : fieldNamePrefix;

    const paymentProviderItems = appContext.payment.availablePaymentProviders
        .map(key => ({ label: paymentProviders[key].shortTitle, value: key }));

    return (
        <Box>
            <Typography variant="h5" display="block" gutterBottom>
                { showStepNumber && '6b. ' }
                {strings.storageSiteSettingsForSuperAdmin}
            </Typography>

            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <TextField
                        label={strings.commissionRate}
                        name={getName('commissionRate')}
                        variant="outlined"
                        showError={showErrorOnBlur}
                        fieldProps={{ validate: mustBeNullOrNumber }}
                        helperText={strings.formatString(strings.storageSiteCommissionRateHelperText, formatPercentage(actor.commissionRate, appContext, { numberOfDecimals: 1}))}
                    />
                </Grid>

                <Grid item xs={12}>
                    <Checkboxes
                        label={strings.searchability}
                        name={getName('searchable')}
                        data={
                            { label: strings.storageSiteIsSearchable, value: true }
                        }
                    />
                </Grid>

                <Grid item xs={12}>
                    <TextField
                        label={strings.searchPriority}
                        name={getName('searchPriority')}
                        variant="outlined"
                        showError={showErrorOnBlur}
                        fieldProps={{ validate: mustBeNullOrNumber }}
                        helperText={strings.searchPriorityHelperText}
                    />
                </Grid>

                <Grid item xs={12}>
                    <TextField
                        label={strings.regionSearchPriority}
                        name={getName('regionSearchPriority')}
                        variant="outlined"
                        showError={showErrorOnBlur}
                        fieldProps={{ validate: mustBeNullOrNumber }}
                        helperText={strings.regionSearchPriorityHelperText}
                    />
                </Grid>

                <Grid item xs={12}>
                    <TextField
                        label={strings.distanceSearchAdjustment}
                        name={getName('distanceSearchAdjustment')}
                        variant="outlined"
                        showError={showErrorOnBlur}
                        fieldProps={{ validate: mustBeNullOrNumber }}
                        helperText={strings.distanceSearchAdjustmentHelperText}
                    />
                </Grid>

                {
                    getAvailablePaymentProviders(appContext).length > 1 &&
                    (
                        <Grid item xs={12}>
                            <Checkboxes
                                label={strings.paymentProvidersTitle}
                                name={getName('paymentProviders')}
                                data={paymentProviderItems}
                            />
                        </Grid>
                    )
                }

                <Grid item xs={12}>
                    <Checkboxes
                        label={strings.immediateBookings}
                        name={getName('allowImmediateBookings')}
                        data={
                            { label: strings.allowImmediateBookings, value: true }
                        }
                        helperText={strings.allowImmediateBookingsHelperText}
                    />
                </Grid>

                <Grid item xs={12}>
                    <Checkboxes
                        label={strings.considerBookingsAsServicesTitle}
                        name={getName('considerBookingsAsServices')}
                        data={
                            { label: strings.considerBookingsAsServices, value: true }
                        }
                    />
                </Grid>

                <Grid item xs={12}>
                    <TextField
                        label={strings.roundReportEmails}
                        name={getName('roundReportEmails')}
                        variant="outlined"
                        helperText={strings.roundReportEmailsHelperText}
                    />
                </Grid>

                <Grid item xs={12}>
                    <TextField
                        label={strings.parakeyDomain}
                        name={getName('parakeyDomainId')}
                        variant="outlined"
                        helperText={strings.parakeyDomainHelperText}
                    />
                </Grid>
            </Grid>
        </Box>
    );
};

EditStep6.propTypes = {
    showStepNumber: PropTypes.bool,
    actor: PropTypes.object
};

export default EditStep6;
