import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from 'styles/util';
import { useDispatch } from 'react-redux';
import { handleResponse } from 'actions/actionHelpers';
import { eidentCreateIdentificationRequestUrl } from 'actions/authentication';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import strings from 'localization/strings';

import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Loader from 'common/Loader';

const useStyles = makeStyles(({ theme }) => ({
    container: {
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        gap: theme.spacing(3.75)
    }
}));

const EidentLogin = ({ redirectUrl, clientState }) => {
    const isMobile = !useMediaQuery(theme => theme.breakpoints.up('md'));
    const classes = useStyles();
    const dispatch = useDispatch();

    const [ isLoading, setIsLoading ] = useState(false);

    const handleLoginButtonClick = () => {
        // store callback (redirectUrl) and redirect to EIdent login page
        setIsLoading(true);
        dispatch(eidentCreateIdentificationRequestUrl({ redirectUrl, clientState }))
            .then(handleResponse(
                response => {
                    window.location.href = response.payload.url;
                },
                () => {
                    setIsLoading(false);
                }
            ));
    };

    return (
        <Box className={classes.container}>
            {
                isLoading && <Loader />
            }
            {
                !isLoading &&
                (
                    <Button
                        type="button"
                        color="primary"
                        variant="contained"
                        fullWidth={isMobile}
                        onClick={handleLoginButtonClick}
                    >
                        {strings.bankId.loginTitle}
                    </Button>
                )
            }
        </Box>
    );
};

EidentLogin.propTypes = {
    title: PropTypes.string,
    redirectUrl: PropTypes.string,
    clientState: PropTypes.object
};

export default EidentLogin;
