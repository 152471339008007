import strings from 'localization/strings';

const adminBookingStatuses = {
    requested: {
        color: '#EF476F',
        subColor: '#EF476F',
        chipType: 'warning'
    },
    confirmed: {
        color: '#FFD166',
        subColor: '#FFD166',
        chipType: 'success'
    },
    canceled: {
        color: '#073B4C',
        chipType: 'danger'
    },
    notStarted: {
        color: '#06D6A0',
        subColor: '#073B4C',
        chipType: 'success'
    },
    active: {
        color: '#06D6A0',
        subColor: '#06D6A0',
        chipType: 'success'
    },
    subscriptionEnding: {
        color: '#06D6A0',
        subColor: '#118AB2',
        chipType: 'hidden'
    },
    ended: {
        color: '#118AB2',
        chipType: 'hidden'
    }
};

Object.keys(adminBookingStatuses).forEach(key => {
    adminBookingStatuses[key].key = key;
});

strings.addLoadCallback(() => {
    Object.values(adminBookingStatuses).forEach(o => {
        o.title = strings.adminBookingStatuses[o.key];
        o.singleTemplate = strings.adminBookingStatuses.templates.single[o.key];
        o.multipleTemplate = strings.adminBookingStatuses.templates.multiple[o.key];
    });
});

export default adminBookingStatuses;
