import React from 'react';
import PropTypes from 'prop-types';
import { useAppContext } from 'context/AppContext';
import strings from 'localization/strings';
import routes from 'routes';
import { formatShortDate } from 'helpers/DateHelper';
import { distinct } from 'helpers/ArrayHelper';
import lockTypes from 'enums/lockTypes';

import MarginBox from 'common/MarginBox';
import Text from 'common/Text';
import Caption from './Caption';
import InformationItems from './InformationItems';
import QrCodeContainer from './QrCodeContainer';

const TakeoverInvoiceInformation = ({ purchase, takeoverTenant }) => {
    const { appContext } = useAppContext();

    const booking = purchase.booking;
    const storageGroup =  booking.storageGroup;
    const storageSite =  storageGroup.storageSite;

    const isFirstPurchase = booking.purchases[0].id === purchase.id;

    const takeoverNewAgreement = strings.formatString(strings.invoiceInformation.takeoverNewAgreement, storageSite.title, appContext.siteUrl + routes.policies.storing, formatShortDate(purchase.dueDate, appContext));
    const lockInformations = distinct(storageGroup.lockConfigurations.map(o => o.lockType)).map(o => lockTypes[o].takeoverInformation).filter(o => o);
    const contactUs = strings.formatString(strings.invoiceInformation.contactUs, <a href={`mailto:${appContext.company.email}`}>{appContext.company.email}</a>, appContext.company.telephone);

    return (
        <>
            {
                isFirstPurchase &&
                (
                    <>
                        <MarginBox top={3} bottom={3}>
                            <Caption text={strings.invoiceInformation.takeoverIntroductionCaption}/>
                            <MarginBox>
                                {strings.invoiceInformation.takeoverIntroduction}
                            </MarginBox>
                        </MarginBox>
                        <MarginBox bottom={3}>
                            <Caption text={strings.invoiceInformation.takeoverNewAgreementCaption}/>
                            <Text html={takeoverNewAgreement} />
                        </MarginBox>
                    </>
                )
            }

            <MarginBox top={isFirstPurchase ? 0 : 3} bottom={3}>
                <Caption text={strings.invoiceInformation.manageBookingCaption}/>
                <MarginBox bottom={2}>
                    {
                        isFirstPurchase
                            ? strings.invoiceInformation.takeoverAccountInformation
                            : strings.invoiceInformation.manageBooking
                    }
                </MarginBox>
                <MarginBox bottom={2}>
                    {
                        takeoverTenant.isConnectedToUser
                            ? strings.invoiceInformation.takeoverFirstInvoiceFeeInformation
                            : strings.invoiceInformation.invoiceFeeInformation
                    }
                </MarginBox>
                <MarginBox>
                    <QrCodeContainer
                        qrCode={takeoverTenant.isConnectedToUser ? booking.tenantQrCode : takeoverTenant.qrCode}
                        text={strings.invoiceInformation.manageBookingCaption}
                        url={takeoverTenant.isConnectedToUser ? booking.tenantUrl : takeoverTenant.url}
                    />
                </MarginBox>
            </MarginBox>

            {
                isFirstPurchase && lockInformations.length > 0 &&
                (
                    <>
                        <MarginBox top={3} bottom={3}>
                            <Caption text={strings.invoiceInformation.lockInformationCaption}/>
                            <MarginBox>
                                {lockInformations.join(' ')}
                            </MarginBox>
                        </MarginBox>
                    </>
                )
            }

            <MarginBox bottom={3}>
                <Caption text={strings.contactUsCaption}/>
                <MarginBox>
                    {contactUs}
                </MarginBox>
            </MarginBox>

            <InformationItems caption={strings.yourBooking} purchase={purchase} />
        </>
    );
};

TakeoverInvoiceInformation.propTypes = {
    purchase: PropTypes.object.isRequired,
    takeoverTenant: PropTypes.object.isRequired
};

export default TakeoverInvoiceInformation;
