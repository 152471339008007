import React from 'react';
import PropTypes from 'prop-types';
import { useAppContext } from 'context/AppContext';
import { makeStyles } from 'styles/util';
import { getResizedImageUrl } from 'helpers/ImageHelper';

import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import StorageTypeIconAndText from 'common/StorageTypeIconAndText';
import placeIcon from 'assets/icons/place.svg';

const logotypeHeight = 48;

const StorageSiteDetailsTitle = ({ storageSite }) => {
    const useStyles = makeStyles(({ theme }) => ({
        titleContainer: {
            flexDirection: 'column'
        },
        storageTypeIconAndText: {
            display: 'flex',
            alignItems: 'center',
            marginTop: theme.spacing(2),
            gap: theme.spacing(2),
            [theme.breakpoints.down('sm')]: {
                flexDirection: storageSite.ownerActor && storageSite.ownerActor.logotypeUrl ? 'column' : 'row',
                alignItems: storageSite.ownerActor && storageSite.ownerActor.logotypeUrl ? 'flex-start' : 'center',
            }
        },
        logotypeWrapper: {
            flex: '1 0 auto',
            textAlign: 'right',
            height: logotypeHeight
        },
        placeContainer: {
            display: 'flex',
            gap: theme.spacing(1.5)
        }
    }));
    const classes = useStyles();
    const { appContext } = useAppContext();

    return (
        <Box className={classes.titleContainer}>
            <Typography variant="h1">{storageSite.title}</Typography>
            <Box className={classes.storageTypeIconAndText}>
                {appContext.useStorageTypes && <StorageTypeIconAndText storageType={storageSite.storageType} iconColor={appContext.colors.icon}/>}

                <Box className={classes.placeContainer}>
                    <img src={placeIcon} />
                    {storageSite.city}
                </Box>
                {
                    storageSite.ownerActor && storageSite.ownerActor.logotypeUrl &&
                    (
                        <Box className={classes.logotypeWrapper}>
                            <img src={getResizedImageUrl(storageSite.ownerActor.logotypeUrl, { height: logotypeHeight })} />
                        </Box>
                    )
                }
            </Box>
        </Box>
    );
};

StorageSiteDetailsTitle.propTypes = {
    storageSite: PropTypes.object.isRequired
};

export default StorageSiteDetailsTitle;
