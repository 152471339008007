const displayPriceModes = {
    contextSpecific: {},
    excludingVat: {}
};

Object.keys(displayPriceModes).forEach(key => {
    displayPriceModes[key].key = key;
});

export default displayPriceModes;
