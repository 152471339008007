import storageSuccess from 'assets/animations/storage/success.json';

export const storageAnimations = {
    success: storageSuccess
};

export const officeAnimations = {
};

export const swedenAnimations = {
};

export const finlandAnimations = {
};
