import { RSAA } from 'redux-api-middleware';
import { getApiSearchUrl } from 'helpers/SearchHelper';
import { createRsaaTypes, createHeaders } from './actionHelpers';
import { userAgentIsBot } from 'helpers/ConfigurationHelper';

import queryString from 'query-string';

export const VIEWSTORAGESITES_FETCH = 'VIEWSTORAGESITES_FETCH';

export const VIEWSTORAGESITES_LIST = 'VIEWSTORAGESITES_LIST';
export const VIEWSTORAGESITES_LIST_SUCCESS = 'VIEWSTORAGESITES_LIST_SUCCESS';
export const VIEWSTORAGESITES_LIST_FAILURE = 'VIEWSTORAGESITES_LIST_FAILURE';

export const VIEWSTORAGESITES_FETCH_RELEVANT = 'VIEWSTORAGESITES_FETCH_RELEVANT';
export const VIEWSTORAGESITES_FETCH_RELEVANT_SUCCESS = 'VIEWSTORAGESITES_FETCH_RELEVANT_SUCCESS';
export const VIEWSTORAGESITES_FETCH_RELEVANT_FAILURE = 'VIEWSTORAGESITES_FETCH_RELEVANT_FAILURE';

export const VIEWSTORAGESITES_FETCH_OPENING_DATES = 'VIEWSTORAGESITES_FETCH_OPENING_DATES';

const isBot = userAgentIsBot() ? true : undefined;

export const fetchViewStorageSite = (storageSiteId, includeBookingCalendar, includeOpeningTimeSpansConfiguration, bookingCalendarStartDate, extendBookingMode) => {
    const searchParameters = {
        includeBookingCalendar: !!includeBookingCalendar,
        includeOpeningTimeSpansConfiguration: !!includeOpeningTimeSpansConfiguration,
        bookingCalendarStartDate,
        extendBookingMode: !!extendBookingMode,
        isBot
    };
    return {
        [RSAA]: {
            types: createRsaaTypes(VIEWSTORAGESITES_FETCH),
            endpoint: `/api/viewstoragesites/${storageSiteId}?${queryString.stringify(searchParameters)}`,
            method: 'GET',
            headers: createHeaders(false)
        }
    };
};

export const fetchViewStorageSites = params => ({
    [RSAA]: {
        types: createRsaaTypes(VIEWSTORAGESITES_LIST),
        endpoint: getApiSearchUrl({ ...params, isBot }),
        method: 'GET',
        headers: createHeaders(false)
    }
});

export const fetchRelevantStorageSites = (lat, lng, orderBy, storageGroupCategory, pageSize, excludeStorageSiteId) => {
    const searchParameters = {
        lat,
        lng,
        excludeStorageSiteId,
        page: 1,
        pageSize,
        orderBy,
        storageGroupCategories: storageGroupCategory,
        excludeStorageSitesWithoutImages: true
    };

    return {
        [RSAA]: {
            types: [{
                type: VIEWSTORAGESITES_FETCH_RELEVANT,
                meta: { storageGroupCategory  }
            }, {
                type: VIEWSTORAGESITES_FETCH_RELEVANT_SUCCESS,
                meta: { storageGroupCategory }
            }, {
                type: VIEWSTORAGESITES_FETCH_RELEVANT_FAILURE,
                meta: { storageGroupCategory }
            }],
            endpoint: `/api/viewstoragesites?${queryString.stringify(searchParameters)}`,
            method: 'GET',
            headers: createHeaders(false)
        }
    };
};

export const fetchOpeningTimeSpansForStorageSite = (storageSiteId, from, to) => {
    const searchParameters = {
        from,
        to
    };
    return {
        [RSAA]: {
            types: createRsaaTypes(VIEWSTORAGESITES_FETCH_OPENING_DATES),
            endpoint: `/api/viewstoragesites/${storageSiteId}/openingTimeSpans?${queryString.stringify(searchParameters)}`,
            method: 'GET',
            headers: createHeaders(false)
        }
    };
};
