import strings from 'localization/strings';

const storageStatuses = {
    active: {},
    inactive: {},
    deleted: {}
};

Object.keys(storageStatuses).forEach(key => {
    storageStatuses[key].key = key;
});

strings.addLoadCallback(() => {
    Object.values(storageStatuses).forEach(o => {
        o.title = strings.storageStatuses[o.key];
    });
});

export default storageStatuses;
