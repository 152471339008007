import * as actionTypes from 'actions/account/storageSitesSection';
import * as authenticationActionTypes from 'actions/authentication';

const initialState =  {
    storageSites: undefined,
    isLoading: false,
    loadingError: undefined
};

const storageSitesSection = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.ACCOUNT_STORAGE_SITES_SECTION_FETCH_STORAGE_SITES:
            return { ...state, isLoading: true, storageSites: undefined, loadingError: undefined };
        case actionTypes.ACCOUNT_STORAGE_SITES_SECTION_FETCH_STORAGE_SITES_SUCCESS:
            return { ...state, isLoading: false, storageSites: action.payload };
        case actionTypes.ACCOUNT_STORAGE_SITES_SECTION_FETCH_STORAGE_SITES_FAILURE:
            return { ...state, isLoading: false, storageSites: undefined, loadingError: action.payload };
        case actionTypes.ACCOUNT_STORAGE_SITES_SECTION_CLEAR_STORAGE_SITES:
            return { ...state, isLoading: false, storageSites: undefined };

        case authenticationActionTypes.AUTHENTICATION_LOGIN:
        case authenticationActionTypes.AUTHENTICATION_LOGOUT:
        case authenticationActionTypes.AUTHENTICATION_IMPERSONATIONLOGOUT:
        case authenticationActionTypes.AUTHENTICATION_GET_CONTEXT:
        case authenticationActionTypes.AUTHENTICATION_REFRESH_CONTEXT_SUCCESS:
            return { ...initialState };

        default:
            return state;
    }
};

export default storageSitesSection;
