import React from 'react';
import PropTypes from 'prop-types';
import { mustBeNumber } from 'form/validation';
import { convertToNumber } from 'helpers/StringHelper';

import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Typography from '@material-ui/core/Typography';
import Form from 'form/Form';
import { TextField, showErrorOnBlur } from 'mui-rff';

const ConnectToUnmatchedPaymentDialog = ({ open, onSubmit, onCancel }) => {
    const initialValues = {
        paymentId: null
    };

    const handleFormSubmit = formValues => {
        onSubmit({
            paymentId: convertToNumber(formValues.paymentId)
        });
    };

    return (
        <Dialog maxWidth="md" open={open} onClose={onCancel}>
            <Form
                initialValues={initialValues}
                onSubmit={handleFormSubmit}
            >
                {({ handleSubmit, invalid }) => (
                    <form onSubmit={handleSubmit}>
                        <DialogTitle disableTypography>
                            <Typography variant="h5">
                                Connect purchase to unmatched payment
                            </Typography>
                        </DialogTitle>
                        <DialogContent>
                            <Typography variant="body1" gutterBottom>
                                Enter the ID of the payment to connect to this purchase. Make sure that the storage has not been booked by someone else before proceeding.
                            </Typography>

                            <TextField
                                name="paymentId"
                                label="Payment ID"
                                variant="outlined"
                                showError={showErrorOnBlur}
                                required
                                fieldProps={{ validate: mustBeNumber }}
                            />
                        </DialogContent>
                        <DialogActions>
                            <Button
                                type="submit"
                                variant="contained"
                                color="primary"
                                disabled={invalid}
                            >
                                Connect
                            </Button>
                            <Button
                                variant="outlined"
                                color="primary"
                                onClick={onCancel}
                            >
                                Cancel
                            </Button>
                        </DialogActions>
                    </form>
                )}
            </Form>
        </Dialog>
    );
};

ConnectToUnmatchedPaymentDialog.propTypes = {
    open: PropTypes.bool.isRequired,
    onSubmit: PropTypes.func.isRequired,
    onCancel: PropTypes.func.isRequired
};

export default ConnectToUnmatchedPaymentDialog;
