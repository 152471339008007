import React from 'react';
import PropTypes from 'prop-types';
import PriceSpecificationTableNew from 'common/PriceSpecification/PriceSpecificationTableNew';

const AcceptPriceSpecification = ({ booking, bookingPriceInfo }) => {
    const showVat = [
        ...(bookingPriceInfo.oneTime?.purchaseItems ?? []),
        ...(bookingPriceInfo.perMonth?.purchaseItems ?? []),
        ...(bookingPriceInfo.wholePeriod?.purchaseItems ?? [])
    ].filter(o => o.vat).length > 0;

    return (
        <PriceSpecificationTableNew booking={booking} purchaseItems={booking.bookingItems} showVat={showVat} />
    );
};

AcceptPriceSpecification.propTypes = {
    booking: PropTypes.object.isRequired,
    bookingPriceInfo: PropTypes.object.isRequired
};

export default AcceptPriceSpecification;
