import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from 'styles/util';

const useStyles = makeStyles(({ theme }) => ({
    adminFormButtons: {
        padding: theme.spacing(2),
        borderTop: '1px solid rgba(0, 0, 0, 0.125)',
        '& > *': {
            marginRight: theme.spacing(1)
        }
    }
}));

const AdminFormButtons = (props) => {
    const classes = useStyles();
    return (
        <div className={classes.adminFormButtons}>
            {props.children}
        </div>
    );
};

AdminFormButtons.propTypes = {
    children: PropTypes.oneOfType([
        PropTypes.arrayOf(PropTypes.node),
        PropTypes.node
    ])
};

export default AdminFormButtons;
