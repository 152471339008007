import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from 'styles/util';
import storageStatuses from 'enums/storageStatuses';

import Hidden from '@material-ui/core/Hidden';
import Storage from './Storage';
import GridSeparator from 'common/GridSeparator';
import { FieldArray } from 'react-final-form-arrays';

const useStyles = makeStyles(() => ({
    hidden: {
        display: 'none'
    }
}));

const StorageList = ({ fieldNamePrefix, validate, visible = true, showDeleted = false, statusEditable = false }) => {
    const classes = useStyles();
    const className = visible ? undefined : classes.hidden;

    return (
        <FieldArray name={fieldNamePrefix}>
            {({ fields }) => (
                <>
                    <Hidden mdUp>
                        <GridSeparator className={className} />
                    </Hidden>
                    {
                        fields.map((name, index) => {
                            const calculatedVisible = visible && (showDeleted || fields.value[index].status !== storageStatuses.deleted.key);
                            const lineClassName =  calculatedVisible ? undefined : classes.hidden;
                            return (
                                <React.Fragment key={name}>
                                    <Storage
                                        fieldNamePrefix={name}
                                        validate={validate}
                                        visible={calculatedVisible}
                                        statusEditable={statusEditable}
                                    />
                                    <Hidden mdUp>
                                        <GridSeparator className={lineClassName} />
                                    </Hidden>
                                </React.Fragment>
                            );
                        })
                    }
                </>
            )}
        </FieldArray>
    );
};

StorageList.propTypes = {
    fieldNamePrefix: PropTypes.string.isRequired,
    validate: PropTypes.bool.isRequired,
    visible: PropTypes.bool,
    showDeleted: PropTypes.bool,
    statusEditable: PropTypes.bool
};

export default StorageList;
