import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useLocation } from 'react-router-dom';
import { useParams } from 'react-router';
import { useDispatch } from 'react-redux';
import { handleResponse } from 'actions/actionHelpers';
import { fetchPurchase, createPreviewPurchase } from 'actions/purchases';
import strings from 'localization/strings';
import { makeStyles } from 'styles/util';
import queryString from 'query-string';

import PageTitle from 'common/PageTitle';
import Container from 'common/Container';
import Box from '@material-ui/core/Box';
import Loader from 'common/Loader';
import Header from 'components/paymentDocument/Header';
import PostInvoiceTopContainer from 'components/paymentDocument/PostInvoiceTopContainer';
import PostInvoiceSpecificationList from 'components/paymentDocument/PostInvoiceSpecificationList';
import PostInvoiceVatInformation from 'components/paymentDocument/PostInvoiceVatInformation';
import PostInvoiceSellerDetails from 'components/paymentDocument/PostInvoiceSellerDetails';
import PostInvoiceAmountToPayOwner from 'components/paymentDocument/PostInvoiceAmountToPayOwner';
import PostInvoiceAmountToPayTenant from 'components/paymentDocument/PostInvoiceAmountToPayTenant';

const useStyles = makeStyles(({ colors }) => ({
    container: {
        fontSize: '12px'
    },
    specificationBox: {
        minHeight: '48em',
        position: 'relative',
        marginBottom: '1em',
        border: `3px solid ${colors.black}`,
        borderRadius: '0.5em',
        padding: '1em'
    },
    specificationSummary: {
        position: 'absolute',
        bottom: 0,
        left: 0,
        right: 0
    }
}));

const PostInvoicePage = ({ isOwner }) => {
    const classes = useStyles();
    const params = useParams();
    const dispatch = useDispatch();
    const location = useLocation();
    const previewPurchaseJson = queryString.parse(location.search).purchase;
    const previewPurchase = previewPurchaseJson
        ? JSON.parse(previewPurchaseJson)
        : undefined;

    const [purchase, setPurchase] = useState(undefined);
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        if(previewPurchase) {
            dispatch(createPreviewPurchase(previewPurchase))
                .then(handleResponse(
                    response => {
                        setPurchase(response.payload);
                        setIsLoading(false);
                    },
                    () => {
                        setIsLoading(false);
                    }
                ));
        } else {
            dispatch(fetchPurchase(params.purchaseId, params.token))
                .then(handleResponse(
                    response => {
                        setPurchase(response.payload);
                        setIsLoading(false);
                    },
                    () => {
                        setIsLoading(false);
                    }
                ));
        }
    }, []);

    if(isLoading) {
        return <Loader />;
    }

    return (
        <Container className={classes.container}>
            <PageTitle>{strings.postInvoice}</PageTitle>

            <Header text={strings.paymentDocuments.invoice} />

            <PostInvoiceTopContainer purchase={purchase} />

            <Box className={classes.specificationBox}>
                <PostInvoiceSpecificationList purchase={purchase} />

                <Box className={classes.specificationSummary}>
                    <PostInvoiceVatInformation purchase={purchase} />
                    { isOwner && <PostInvoiceAmountToPayOwner purchase={purchase} /> }
                    { !isOwner && <PostInvoiceAmountToPayTenant purchase={purchase} /> }
                </Box>
            </Box>

            <PostInvoiceSellerDetails purchase={purchase} />
        </Container>
    );
};

PostInvoicePage.propTypes = {
    isOwner: PropTypes.bool.isRequired
};


export default PostInvoicePage;
