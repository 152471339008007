import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation, useHistory } from 'react-router-dom';
import { handleResponse } from 'actions/actionHelpers';
import queryString from 'query-string';
import { fetchEidTemporaryData } from 'actions/authentication';
import { addQuerystringParametersToUrl } from 'helpers/BrowserHelper';

import Loader from 'common/Loader';

const EidentLoginCallbackPage = () => {
    const dispatch = useDispatch();
    const location = useLocation();
    const history = useHistory();

    const { eidentToken, eidentFailure } = queryString.parse(location.search);
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        if(eidentToken) {
            // we've been redirected back from EIdent
            // eidentToken is the reference to temporary data needed to complete the login
            // redirect to the generic eId flow using eidLoginResult and eidTemporaryDataToken parameters
            dispatch(fetchEidTemporaryData(eidentToken, true /* keepData */))
                .then(handleResponse(
                    response => {
                        const parameters = {
                            eidLoginResult: eidentFailure ? 'failure' : 'success',
                            eidTemporaryDataToken: eidentFailure ? undefined : eidentToken
                        };
                        history.push(addQuerystringParametersToUrl(response.payload.redirectUrl, parameters));
                    },
                    () => {
                        setIsLoading(false);
                    }
                ));
        }
    }, []);

    if(isLoading) {
        return <Loader />;
    }

    return null;
};

export default EidentLoginCallbackPage;
