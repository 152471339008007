import React from 'react';
import PropTypes from 'prop-types';
import strings from 'localization/strings';

import Typography from '@material-ui/core/Typography';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '@material-ui/core/Button';

const EidUserDoesNotExistDialog = ({ open, onOk, onCancel }) => {
    return (
        <Dialog open={open}>
            <DialogTitle disableTypography>
                <Typography variant="h5">
                    {strings.authentication.eidUserDoesNotExistDialogTitle}
                </Typography>
            </DialogTitle>
            <DialogContent>
                {strings.authentication.eidUserDoesNotExistDialogBody}
            </DialogContent>
            <DialogActions>
                <Button
                    variant="contained"
                    color="primary"
                    onClick={onOk}
                >
                    {strings.createAccount}
                </Button>
                <Button
                    variant="outlined"
                    color="primary"
                    onClick={onCancel}
                >
                    {strings.notNow}
                </Button>
            </DialogActions>
        </Dialog>
    );
};

EidUserDoesNotExistDialog.propTypes = {
    open: PropTypes.bool.isRequired,
    onOk: PropTypes.func.isRequired,
    onCancel: PropTypes.func.isRequired
};

export default EidUserDoesNotExistDialog;
