import { ApplicationInsights } from '@microsoft/applicationinsights-web';
import { ReactPlugin } from '@microsoft/applicationinsights-react-js';

let reactPlugin = null;
let appInsights = null;

/**
 * Create the App Insights Telemetry Service
 * @return {{getReactPlugin: Function<ReactPlugin>, initialize: Function}} - Object
 */
const createTelemetryService = () => {
    /**
     * Initialize the Application Insights class
     * @param {string} connectionString - Application Insights connection string
     * @param {Object} browserHistory - client's browser history, supplied by the withRouter HOC
     * @return {void}
     */
    const initialize = (connectionString, browserHistory) => {
        if (!browserHistory) {
            throw new Error('Could not initialize Telemetry Service');
        }
        if (!connectionString) {
            throw new Error('Instrumentation key not provided in TelemetryProvider.js');
        }

        reactPlugin = new ReactPlugin();

        appInsights = new ApplicationInsights({
            config: {
                connectionString: connectionString,
                maxBatchInterval: 0,
                disableFetchTracking: false,
                extensions: [reactPlugin],
                extensionConfig: {
                    [reactPlugin.identifier]: {
                        history: browserHistory
                    }
                }
            }
        });

        appInsights.loadAppInsights();
    };

    return {
        initialize,
        getReactPlugin: () => reactPlugin || { trackMetric: () => {} } // to avoid throwing errors when the plugin is not initialized
    };
};

export const ai = createTelemetryService();
export const getAppInsights = () => appInsights;
