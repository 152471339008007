import * as actionTypes from 'actions/authentication';
import * as userActionTypes from 'actions/users';
import * as ipLocationActionTypes from 'actions/ipLocations';
import { addTokenToSessionStorage, removeTokenFromSessionStorage, getTokenFromSessionStorage } from 'security/token';
import store from 'store2';
import { setDataLayerVariable } from 'integration/google-tag-manager/googleTagManagerHelper';

const redirectUrlKey = 'redirectUrl';

const notLoggedInAuthenticationContext = { isAuthenticated: false, isSuperAdminOrImpersonated: false, actors: [] };

const initialState = {
    token: getTokenFromSessionStorage(),
    loading: false,
    context: notLoggedInAuthenticationContext,
    redirectUrl: store.get(redirectUrlKey), // the url to redirect to after a login
    logInPageMessage: undefined, // the message to show when being asked to log in
    ipLocation: undefined,
    loadingIpLocation: false,
    initialLoginDetails: undefined
};

store.remove(redirectUrlKey);

const createToken = token => {
    if(!token) {
        return undefined;
    }
    const tokenWithBearer = `Bearer ${token}`;
    addTokenToSessionStorage(tokenWithBearer);
    return tokenWithBearer;
};

const authentication = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.AUTHENTICATION_LOGIN:
        case actionTypes.AUTHENTICATION_SIGN_UP:
        case actionTypes.AUTHENTICATION_GET_CONTEXT:
        case userActionTypes.USERS_ACCEPT_TERMS_OF_SERVICE:
            return { ...state, context: notLoggedInAuthenticationContext, loading: true };
        case actionTypes.AUTHENTICATION_LOGIN_SUCCESS:
        case actionTypes.AUTHENTICATION_SIGN_UP_SUCCESS:
        case actionTypes.AUTHENTICATION_SET_SELECTED_ACTOR_SUCCESS:
        case actionTypes.AUTHENTICATION_GET_CONTEXT_SUCCESS:
        case userActionTypes.USERS_ACCEPT_TERMS_OF_SERVICE_SUCCESS:
            return { ...state, token: createToken(action.payload.token), context: action.payload, loading: false };
        case actionTypes.AUTHENTICATION_LOGIN_FAILURE:
        case actionTypes.AUTHENTICATION_SIGN_UP_FAILURE:
        case actionTypes.AUTHENTICATION_SET_SELECTED_ACTOR_FAILURE:
        case actionTypes.AUTHENTICATION_GET_CONTEXT_FAILURE:
        case actionTypes.AUTHENTICATION_IMPERSONATIONLOGOUT:
            removeTokenFromSessionStorage();
            return { ...state, loading: false, token: undefined };
        case actionTypes.AUTHENTICATION_REFRESH_CONTEXT_SUCCESS:
            return { ...state, context: action.payload };
        case actionTypes.AUTHENTICATION_SETTOKEN:
            return { ...state, token: createToken(action.token), context: notLoggedInAuthenticationContext, loading: true };
        case actionTypes.AUTHENTICATION_IMPERSONATIONLOGOUT_SUCCESS:
            return { ...state, token: createToken(action.payload.token), context: action.payload };
        case actionTypes.AUTHENTICATION_SETREDIRECTURL:
            store.set(redirectUrlKey, action.url);
            return { ...state, redirectUrl: action.url };
        case actionTypes.AUTHENTICATION_CLEARREDIRECTURL:
            store.remove(redirectUrlKey);
            return { ...state, redirectUrl: undefined };
        case actionTypes.AUTHENTICATION_SETLOGINPAGEMESSAGE:
            return { ...state, logInPageMessage: action.message };
        case actionTypes.AUTHENTICATION_CLEARLOGINPAGEMESSAGE:
            return { ...state, logInPageMessage: undefined };
        case actionTypes.AUTHENTICATION_SETINITIALLOGINDETAILS:
            return { ...state, initialLoginDetails: action.initialLoginDetails };
        case actionTypes.AUTHENTICATION_LOGOUT:
            setDataLayerVariable('userId', null);
            setDataLayerVariable('actorId', null);
            removeTokenFromSessionStorage();
            return { ...state, token: undefined, context: notLoggedInAuthenticationContext };
        case ipLocationActionTypes.IP_LOCATIONS_GET_IP_LOCATION:
            return { ...state, ipLocation: undefined, loadingIpLocation: true };
        case ipLocationActionTypes.IP_LOCATIONS_GET_IP_LOCATION_SUCCESS:
            return { ...state, ipLocation: action.payload, loadingIpLocation: false };
        case ipLocationActionTypes.IP_LOCATIONS_GET_IP_LOCATION_FAILURE:
            return { ...state, ipLocation: undefined, loadingIpLocation: false };
        default:
            return state;
    }
};

export default authentication;
