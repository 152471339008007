import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from 'styles/util';
import { useDispatch } from 'react-redux';
import strings from 'localization/strings';
import { handleResponse } from 'actions/actionHelpers';
import { required } from 'form/validation';
import { parseIsoDate, getLongDateFormat, formatIsoDate } from 'helpers/DateHelper';
import { add, isBefore, isAfter } from 'date-fns';
import { fetchViewStorageSite } from 'actions/viewStorageSites';
import { showErrorOnBlur } from 'mui-rff';
import { maxDateString } from 'logic/bookingLogic';

import Form from 'form/Form';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import CircularProgress from '@material-ui/core/CircularProgress';
import LoadingError from 'common/LoadingError';
import DatePicker from 'form/DatePicker';
import { OnChange } from 'react-final-form-listeners';
import DateCandidateSelector from 'components/DateCandidateSelector';
import ShortDate from 'common/ShortDate';

const useStyles = makeStyles(({theme}) => ({
    actions: {
        flexWrap: 'wrap',
        gap: theme.spacing(2)
    },
    btnSmall: {
        height: '40px',
    }
}));


const ExtendBookingStep1Form = ({ booking, extendBookingPreview, loadExtendBookingPreview, isLoadingExtendBookingPreview, onOk, onCancel, onError }) => {
    const dispatch = useDispatch();
    const classes = useStyles();
    const [bookingCalendar, setBookingCalendar] = useState(undefined);
    const [isLoadingBookingCalendar, setIsLoadingBookingCalendar] = useState(false);
    const [loadingError, setLoadingError] = useState(undefined);

    const startDate = add(parseIsoDate(booking.endDate), { days: 1 });

    const initialValues = {
        requestedEndDate: null,
        selectedEndDate: null
    };

    useEffect(() => {
        setLoadingError(undefined);
        setIsLoadingBookingCalendar(true);
        dispatch(fetchViewStorageSite(booking.storageGroup.storageSite.id, true /* includeBookingCalendar */, false /* includeOpeningTimeSpansConfiguration */, formatIsoDate(startDate), true /* extendBookingMode */))
            .then(handleResponse(
                response => {
                    const bc = response.payload.storageGroups
                        .find(sg => sg.id === booking.storageGroup.id)
                        .bookingCalendar.storageBookingCalendars
                        .find(o => o.storageId === booking.storageId);
                    if(bc?.availableBookingIntervals.length === 0) {
                        onError(strings.bookingCannotBeExtendedDueToLackOfAvailability);
                    }
                    setBookingCalendar(bc);
                    setIsLoadingBookingCalendar(false);
                },
                () => {
                    setLoadingError(true);
                    setIsLoadingBookingCalendar(false);
                }
            ));
    }, []);

    const handleFormSubmit = values => {
        onOk({
            ...values,
            endDate: getEndDate(values)
        });
    };

    const getEndDate = values => {
        if(isLoadingExtendBookingPreview) {
            return undefined;
        }
        if(requestedEndDateIsAvailable(values)) {
            // the requested end date is open for checkout
            return values.requestedEndDate;
        }
        // user must select another end date
        return values.selectedEndDate;
    };

    const requestedEndDateIsAvailable = formValues => extendBookingPreview && extendBookingPreview.availableEndDateCandidates.find(endDateCandidate => formatIsoDate(endDateCandidate.date) === formatIsoDate(formValues.requestedEndDate)) !== undefined;

    const shouldDisableDate = date => {
        if(!bookingCalendar) {
            return false;
        }
        if(isBefore(date, startDate)) {
            return true;
        }
        const foundInterval = bookingCalendar.availableBookingIntervals.find(o => !isBefore(date, parseIsoDate(o.startDate)) && !isAfter(date, parseIsoDate(o.endDate ?? maxDateString)));
        return !foundInterval;
    };

    return (
        <Form
            initialValues={initialValues}
            onSubmit={handleFormSubmit}
        >
            {({ handleSubmit, values, invalid }) => {
                const endDate = getEndDate(values);
                const showEndDateCandidateSelector = extendBookingPreview && !requestedEndDateIsAvailable(values);

                return (
                    <form onSubmit={handleSubmit}>
                        <DialogTitle disableTypography>
                            <Typography variant="h5">
                                {strings.extendBookingDialogTitle}
                            </Typography>
                        </DialogTitle>
                        <DialogContent>
                            {
                                !isLoadingBookingCalendar &&
                                (
                                    <>
                                        {
                                            !showEndDateCandidateSelector &&
                                            (
                                                <DatePicker
                                                    name="requestedEndDate"
                                                    label={strings.extendedEndDate}
                                                    formatFunc={getLongDateFormat}
                                                    inputVariant="outlined"
                                                    showError={showErrorOnBlur}
                                                    required
                                                    fieldProps={{ validate: required, shouldDisableDate, okLabel: strings.ok, cancelLabel: strings.cancel }}
                                                    autoFocus
                                                />
                                            )
                                        }
                                        <OnChange name="requestedEndDate">
                                            {() => loadExtendBookingPreview(values)}
                                        </OnChange>

                                        {
                                            showEndDateCandidateSelector &&
                                            (
                                                <>
                                                    <Typography variant="h6" gutterBottom>
                                                        {strings.selectCheckOutDateTitle}
                                                    </Typography>
                                                    <Typography variant="body1" gutterBottom>
                                                        {strings.formatString(strings.selectCheckOutDateDescription, <ShortDate value={values.requestedEndDate}/>)}
                                                    </Typography>
                                                    <DateCandidateSelector
                                                        availableDateCandidates={extendBookingPreview.availableEndDateCandidates}
                                                        name="selectedEndDate"
                                                        label={strings.endDate}
                                                    />
                                                </>
                                            )
                                        }
                                    </>
                                )
                            }
                            {
                                loadingError && <LoadingError />
                            }
                        </DialogContent>
                        <DialogActions disableSpacing className={classes.actions}>
                            {
                                (isLoadingBookingCalendar || isLoadingExtendBookingPreview) &&
                                (
                                    <CircularProgress
                                        size={24}
                                        color="primary"
                                    />
                                )
                            }
                            <Button
                                type="submit"
                                variant="contained"
                                color="primary"
                                fullWidth
                                disabled={invalid || !endDate || isLoadingBookingCalendar || loadingError}
                            >
                                {strings.goToPayment}
                            </Button>
                            <Button
                                className={classes.btnSmall}
                                variant="contained"
                                color="secondary"
                                fullWidth
                                onClick={onCancel}
                            >
                                {strings.cancel}
                            </Button>
                        </DialogActions>
                    </form>
                );
            }}
        </Form>
    );
};

ExtendBookingStep1Form.propTypes = {
    booking: PropTypes.object.isRequired,
    extendBookingPreview: PropTypes.object,
    loadExtendBookingPreview: PropTypes.func.isRequired,
    isLoadingExtendBookingPreview: PropTypes.bool,
    onOk: PropTypes.func.isRequired,
    onCancel: PropTypes.func.isRequired,
    onError: PropTypes.func.isRequired
};

export default ExtendBookingStep1Form;
