const subscriptionStatuses = {
    active: {},
    ended: {}
};

Object.keys(subscriptionStatuses).forEach(key => {
    subscriptionStatuses[key].key = key;
});

export default subscriptionStatuses;
