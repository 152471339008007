import routes from 'routes';
import userAuthenticationTypes from 'enums/userAuthenticationTypes';
import queryString from 'query-string';
import { addQuerystringParametersToUrl } from 'helpers/BrowserHelper';
import { handleResponse } from 'actions/actionHelpers';
import organizationTypes from 'enums/organizationTypes';
import contactTypes from 'enums/contactTypes';
import { signUp, setSelectedActor, setToken } from 'actions/authentication';
import { connectTakeoverBooking } from 'actions/takeoverTenants';
import { acceptUserInvitation } from 'actions/userInvitations';

export const filterUserModes = (userModes, appContext) => {
    if(!appContext.authentication.allowedUserAuthenticationTypes.find(o => o === userAuthenticationTypes.eid.key)) {
        delete(userModes.eid);
    }
    if(!appContext.authentication.allowedUserAuthenticationTypes.find(o => o === userAuthenticationTypes.usernameAndPassword.key)) {
        delete(userModes.usernameAndPassword);
    }
    if(appContext.authentication.allowedUserAuthenticationTypes.find(o => o === userAuthenticationTypes.eid.key) && appContext.authentication.allowedUserAuthenticationTypes.length === 1) {
        delete(userModes.existingUser);
    }
};

export const enhanceRedirectUrl = (redirectUrl, isInitialLogin) =>
    redirectUrl ?? (routes.account.overview + (isInitialLogin ? '?showWelcomeMessage=true' : ''));

export const appendQuerystringParametersForAuthentication = (url, search) => {
    const allowedParameterNames = [ 'userInvitationId', 'userInvitationToken', 'takeoverTenantId', 'takeoverTenantToken', 'eidTemporaryDataToken', 'createAccountFromEidUserInfo' ];
    const parameters = typeof search === 'object'
        ? search
        : queryString.parse(search);
    const filteredParameters = {};
    Object.keys(parameters)
        .filter(parameterName => allowedParameterNames.includes(parameterName) && parameters[parameterName])
        .forEach(parameterName => {
            filteredParameters[parameterName] = parameters[parameterName];
        });
    return addQuerystringParametersToUrl(url, filteredParameters);
};

export const createObjectFromAuthenticationQuerystringParameters = location => {
    const { eidLoginResult, eidTemporaryDataToken, userInvitationId, userInvitationToken, takeoverTenantId, takeoverTenantToken, createAccountFromEidUserInfo, allowCreateUsernameAndPasswordAccount } = queryString.parse(location.search);
    return {
        eidLoginResult,
        eidTemporaryDataToken,
        userInvitation: userInvitationId
            ? {
                id: userInvitationId,
                token: userInvitationToken
            }
            : undefined,
        takeoverTenant: takeoverTenantId
            ? {
                id: takeoverTenantId,
                token: takeoverTenantToken
            }
            : undefined,
        createAccountFromEidUserInfo,
        allowCreateUsernameAndPasswordAccount: allowCreateUsernameAndPasswordAccount === 'true'
    };
};

const defaultInitialClientState = {
    business: {
        actorName: null,
        actorOrganizationNumber: null,
        actorEmail: null,
        actorMobileTelephone: null,
        userFirstName: null,
        userLastName: null,
        userEmail: null,
        userPassword: null,
        userConfirmPassword: null,
        userAuthenticationType: null,
        userTermsOfServiceAccepted: false,
        userAuthenticationTypeAssociatedWithEmail: undefined,
        userIdAssociatedWithEmail: undefined
    },
    private: {
        userAuthenticationType: null,
        firstName: null,
        lastName: null,
        email: null,
        mobileTelephone: null,
        password: null,
        confirmPassword: null,
        termsOfServiceAccepted: false
    },
    eidLoginResult: null,
    eidTemporaryDataToken: null,
    userInvitation: null,
    takeoverTenant: null,
    createAccountFromEidUserInfo: null,
    allowCreateUsernameAndPasswordAccount: false
};

export const createInitialClientState = location => ({
    ...defaultInitialClientState,
    ...createObjectFromAuthenticationQuerystringParameters(location)
});

export const createBusinessActor = ({ clientState, dispatch, onSuccess, onFailure }) => {
    const signUpRequest = {
        actor: {
            name: clientState.business.actorName,
            organizationNumber: clientState.business.actorOrganizationNumber,
            contacts: [
                { type: contactTypes.mainEmail.key, value: clientState.business.actorEmail },
                { type: contactTypes.mainMobileTelephone.key, value: clientState.business.actorMobileTelephone }
            ],
            organizationType: organizationTypes.business.key
        },
        user: {
            eidTemporaryDataToken: clientState.eidTemporaryDataToken,
            firstName: clientState.business.userFirstName,
            lastName: clientState.business.userLastName,
            email: clientState.business.userEmail,
            password: clientState.business.userPassword,
            logInAsExistingUser: clientState.business.userAuthenticationTypeAssociatedWithEmail === userAuthenticationTypes.usernameAndPassword.key
        }
    };
    dispatch(signUp(signUpRequest))
        .then(handleResponse(onSuccess, onFailure));
};

export const createPrivateActor = ({ clientState, dispatch, onSuccess, onFailure }) => {
    const actor = clientState.userInvitation
        ? undefined
        : {
            firstName: clientState.private.firstName,
            lastName: clientState.private.lastName,
            contacts: [
                { type: contactTypes.mainEmail.key, value: clientState.private.email },
                { type: contactTypes.mainMobileTelephone.key, value: clientState.private.mobileTelephone }
            ],
            organizationType: organizationTypes.private.key
        };
    const signUpRequest = {
        actor,
        user: {
            eidTemporaryDataToken: clientState.eidTemporaryDataToken,
            firstName: clientState.private.firstName,
            lastName: clientState.private.lastName,
            email: clientState.private.email,
            password: clientState.private.password,
        },
        userInvitation: clientState.userInvitation
    };
    dispatch(signUp(signUpRequest))
        .then(handleResponse(onSuccess, onFailure));
};

export const handleSuccessfulLogIn = params => {
    const { takeoverTenant, userInvitation, setIsLoading, redirectUrl, dispatch, history } = params;
    if(takeoverTenant) {
        // handle takeover tenant
        history.push(getConnectTakeoverTenantRoute(takeoverTenant));
    } else if (userInvitation) {
        // handle user invitation
        performAcceptUserInvitation({ userInvitation, setIsLoading, dispatch, history });
    } else {
        history.push(enhanceRedirectUrl(redirectUrl));
    }

    return params;
};

export const getConnectTakeoverTenantRoute = takeoverTenant =>
    `${routes.takeoverTenant.replace(':takeoverTenantId', takeoverTenant.id).replace(':token', takeoverTenant.token)}?${queryString.stringify({ connect: true })}`;

export const performConnectTakeoverBooking = ({ takeoverTenant, userId, actorId, setIsLoading, dispatch, history }) => {
    if(setIsLoading) {
        setIsLoading(true);
    }
    const request = {
        userId,
        actorId
    };
    dispatch(connectTakeoverBooking(takeoverTenant.id, takeoverTenant.token, request))
        .then(handleResponse(
            response => {
                const updatedTakeoverTenant = response.payload;
                // authentication context might have changed, reauthenticate
                dispatch(setSelectedActor(actorId))
                    .then(handleResponse(
                        () => {
                            // redirect to booking details page
                            history.push(`${routes.account.tenantBookingDetails.replace(':bookingId', updatedTakeoverTenant.booking.id)}?${queryString.stringify({ takeoverBookingCompleted: true })}`);
                        },
                        () => setIsLoading(false)
                    ));
            },
            () => setIsLoading(false)
        ));
};

export const performAcceptUserInvitation = ({ userInvitation, setIsLoading, dispatch, history, onFailure }) => {
    if(setIsLoading) {
        setIsLoading(true);
    }
    dispatch(acceptUserInvitation(userInvitation.id, { token: userInvitation.token }))
        .then(handleResponse(
            response => {
                // TODO: login: redirect to user invitation completed page
                dispatch(setToken(response.token));
                history.push(enhanceRedirectUrl(undefined, false));
            },
            response => {
                if(onFailure) {
                    return onFailure(response);
                }
                return undefined;
            }
        ));
};
