import React from 'react';
import PropTypes from 'prop-types';
import { useAppContext } from 'context/AppContext';
import { useForm } from 'react-final-form';
import { createStorageSiteRequestFromFormData } from 'logic/editStorageSiteLogic';
import { useSelector } from 'react-redux';
import strings from 'localization/strings';
import useMediaQuery from '@material-ui/core/useMediaQuery';

import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import StorageSiteDetails from 'pages/storageSiteDetails/StorageSiteDetails';

const EditStep7 = ({ showStepNumber = true }) => {
    const isMobile = !useMediaQuery(theme => theme.breakpoints.up('md'));
    const formValues = useForm().getState().values;
    const authenticationContext = useSelector(state => state.authentication.context);
    const selectedActor = authenticationContext.selectedActor;
    const { appContext } = useAppContext();
    const baseValues = {
        ownerActorId: selectedActor.id,
        ownerType: selectedActor.organizationType
    };
    const storageSiteForSave = createStorageSiteRequestFromFormData(formValues, baseValues, selectedActor, appContext);

    return (
        <Box>
            <Typography variant="h5" display="block" gutterBottom>
                { showStepNumber && '7. ' }
                {strings.publishTitle}
            </Typography>

            <Typography variant="body1" display="block" gutterBottom>
                {strings.publishDescription}
            </Typography>
            <StorageSiteDetails storageSite={storageSiteForSave} isMobile={isMobile} isPreview />
        </Box>
    );
};

EditStep7.propTypes = {
    showStepNumber: PropTypes.bool
};

export default EditStep7;
