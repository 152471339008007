import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from 'styles/util';
import useMediaQuery from '@material-ui/core/useMediaQuery';

import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';

const ShadowBox = ({ title, actionPanel, children }) => {
    const isMobile = !useMediaQuery(theme => theme.breakpoints.up('md'));
    const useStyles = makeStyles(({ theme, colors }) => ({
        container: {
            marginTop: isMobile ? 0 : theme.spacing(4),
            marginBottom: isMobile ? 0 : theme.spacing(4),
            padding: isMobile ? 0 : theme.spacing(0, 4),
            backgroundColor: colors.white,
            boxShadow: isMobile ? 'none' : '0 0 4px rgba(0, 0, 0, 0.25)',
            borderBottom: isMobile ? `1.5px solid ${colors.mediumGrey}` : 'none',
            '&:last-child': {
                borderBottom: 'none'
            }
        },
        header: {
            padding: theme.spacing(4, 0),
            display: 'flex',
            alignItems: 'center'
        },
        caption: {
            flex: '1 1 auto'
        },
        actionPanel: {
            flex: '0 0 auto'
        },
        body: {
            paddingTop: theme.spacing(title ? 0 : 4),
            paddingBottom: theme.spacing(4)
        }
    }));
    const classes = useStyles();

    return (
        <Box className={classes.container}>
            {
                title &&
                (
                    <Box className={classes.header}>
                        <Typography variant="h5" display="inline" className={classes.caption}>
                            {title}
                        </Typography>
                        {
                            actionPanel &&
                            (
                                <Box className={classes.actionPanel}>
                                    {actionPanel}
                                </Box>
                            )
                        }
                    </Box>
                )
            }
            <Box className={classes.body}>
                {children}
            </Box>
        </Box>
    );
};

ShadowBox.propTypes = {
    title: PropTypes.string,
    actionPanel: PropTypes.node,
    children: PropTypes.node.isRequired
};

export default ShadowBox;
