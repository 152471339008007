import React from 'react';
import PropTypes from 'prop-types';
import strings from 'localization/strings';
import { makeStyles } from 'styles/util';

import Box from '@material-ui/core/Box';
import Amount from 'common/Amount';

const useStyles = makeStyles(({ colors }) => ({
    amountToPayContainer: {
        padding: '1em',
        textAlign: 'right',
        borderTop: `1px solid ${colors.black}`
    },
    amountToPay: {
        display: 'table',
        margin: '0 0 0 auto',
        fontSize: '12px'
    },
    amountToPayRow: {
        display: 'table-row'
    },
    amountToPayLabel: {
        display: 'table-cell',
        padding: '0.15em 2em 0.15em 0',
        textAlign: 'right'
    },
    amountToPayValue: {
        display: 'table-cell',
        padding: '0.15em 0 0.15em 0',
        textAlign: 'right'
    },
    bold: {
        fontWeight: 'bold'
    }
}));

const PostInvoiceAmountToPayTenant = ({ purchase }) => {
    const classes = useStyles();

    return (
        <Box className={classes.amountToPayContainer}>
            <Box className={classes.amountToPay}>
                <Box className={classes.amountToPayRow}>
                    <Box className={classes.amountToPayLabel}>
                        {strings.paymentDocuments.amountToPay}
                    </Box>
                    <Box className={classes.amountToPayValue}>
                        <Amount value={purchase.amount} currency={purchase.currency} displayPriceRounding={false} />
                    </Box>
                </Box>
            </Box>
            <Box>
                {strings.paymentDocuments.paymentAtWebsite}
            </Box>
        </Box>
    );
};

PostInvoiceAmountToPayTenant.propTypes = {
    purchase: PropTypes.object.isRequired
};

export default PostInvoiceAmountToPayTenant;
