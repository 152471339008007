import React from 'react';
import takeoverStatuses from 'enums/takeoverStatuses';

import AdminFormWrapper from '../../presentational/AdminFormWrapper';
import AdminFormFields from '../../presentational/AdminFormFields';
import AdminFormButtons from '../../presentational/AdminFormButtons';
import Form from 'form/Form';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import { TextField, Checkboxes } from 'mui-rff';

const SearchTakeoversForm = ({ searchParameters, onSubmit, isSubmitting = false }) => {
    const statuses = Object.values(takeoverStatuses)
        .map(o => ({
            label: o.key,
            value: o.key
        }));

    return (
        <AdminFormWrapper isSubmitting={isSubmitting}>
            <Form
                initialValues={searchParameters}
                onSubmit={onSubmit}
            >
                {({ handleSubmit }) => (
                    <form onSubmit={handleSubmit}>
                        <AdminFormFields>
                            <TextField
                                name="query"
                                type="search"
                                label="Search takeover"
                                placeholder="Owner name or storage site title"
                                variant="outlined"
                            />

                            <Checkboxes
                                name="statuses"
                                label="Statuses"
                                data={statuses}
                            />
                        </AdminFormFields>

                        <AdminFormButtons>
                            <Button
                                type="submit"
                                variant="contained"
                                color="primary"
                            >
                                Search
                            </Button>
                        </AdminFormButtons>
                    </form>
                )}
            </Form>
        </AdminFormWrapper>
    );
};

SearchTakeoversForm.propTypes = {
    searchParameters: PropTypes.object,
    onSubmit: PropTypes.func,
    isSubmitting: PropTypes.bool
};

export default SearchTakeoversForm;
