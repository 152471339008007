import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from 'styles/util';
import { makeOnDragEndFunction } from './ListHelper';

import Box from '@material-ui/core/Box';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import IconButton from '@material-ui/core/IconButton';
import CircularProgress from '@material-ui/core/CircularProgress';

const useStyles = makeStyles(({ colors }) => ({
    mediaListDroppable: {
        display: 'flex'
    },
    mediaListHorizontal: {
        flexDirection: 'row',
        flexWrap: 'wrap',
        overflow: 'auto'
    },
    mediaListVertical: {
        flexDirection: 'column'
    },
    removeIconButton: {
        zIndex: 1000,
        position: 'absolute',
        top: '2px',
        right: '2px',
        background: 'rgba(255, 255, 255, 0.5)',
        padding: '2px',
        '&:hover': {
            background: 'rgba(255, 255, 255, 0.75)',
        }
    },
    loadingContainer: {
        border: `1.5px solid ${colors.mediumGrey}`,
        width: '100%',
        height: '100%'
    },
    circularProgress: {
        position: 'absolute',
        left: '50%',
        top: '50%',
        margin: '-16px'
    },
    media: {
        border: `1.5px solid ${colors.mediumGrey}`,
        userSelect: 'none'
    },
    loading: {
        display: 'none'
    },
    mediaContainer: {
        position: 'relative',
        cursor: 'grab'
    }
}));

const MediaList = ({ fields, containerClassName, direction = 'horizontal', children }) => {
    const classes = useStyles();
    return (
        <DragDropContext onDragEnd={makeOnDragEndFunction(fields)}>
            <Droppable droppableId="imageDroppable" direction={direction}>
                {
                    outerProvided => (
                        <Box
                            className={classes.mediaListDroppable + ' ' + (direction === 'vertical' ? classes.mediaListVertical : classes.mediaListHorizontal)}
                            ref={outerProvided.innerRef}
                        >
                            {
                                fields.map((name, index) => (
                                    <Draggable
                                        key={name}
                                        draggableId={name}
                                        index={index}
                                    >
                                        {
                                            provided => {
                                                const item = fields.value[index];
                                                return (
                                                    <Box
                                                        ref={provided.innerRef}
                                                        {...provided.draggableProps}
                                                        {...provided.dragHandleProps}
                                                    >
                                                        <Box className={containerClassName + ' ' + classes.mediaContainer}>
                                                            <IconButton
                                                                className={classes.removeIconButton}
                                                                onClick={() => fields.remove(index)}
                                                            >
                                                                <HighlightOffIcon />
                                                            </IconButton>
                                                            {
                                                                item.loading &&
                                                                (
                                                                    <Box className={classes.loadingContainer}>
                                                                        <CircularProgress
                                                                            size={32}
                                                                            color="primary"
                                                                            className={classes.circularProgress}
                                                                        />
                                                                    </Box>
                                                                )
                                                            }
                                                            {children(item, name, index)}
                                                        </Box>
                                                    </Box>
                                                );
                                            }
                                        }
                                    </Draggable>
                                ))
                            }
                            {outerProvided.placeholder}
                        </Box>
                    )
                }
            </Droppable>
        </DragDropContext>
    );
};

MediaList.propTypes = {
    fields: PropTypes.object.isRequired,
    containerClassName: PropTypes.string,
    direction: PropTypes.string,
    children: PropTypes.func.isRequired
};

export default MediaList;
