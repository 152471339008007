import React from 'react';
import strings from 'localization/strings';
import { useAppContext } from 'context/AppContext';
import { makeStyles } from 'styles/util';
import { formFieldKeys } from 'helpers/StorageSearchFormHelper';
import { createEndAdornment } from 'helpers/FormHelper';

import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import { TextField } from 'mui-rff';
import WithStoragePopoverWrapper from 'components/storageSearchForm/WithStoragePopoverWrapper';

const useStyles = makeStyles(({ theme }) => ({
    maxPriceWrapper: {
        display: 'flex',
        paddingBottom: theme.spacing(1)
    },
    fieldWrapper: {
        flex: '1',
        '& .MuiOutlinedInput-root': {
            borderRadius: theme.spacing(1)
        }
    },
        caption: {
        fontWeight: 'bold',
        fontSize: '80%'
    }
}));

// workaround for including empty values
const identity = value => value;

const MaxPriceSelectors = () => {
    const classes = useStyles();
    const { appContext } = useAppContext();

    return (
        <>
            <Typography className={classes.caption}>{strings.price}</Typography>
            <Box className={classes.maxPriceWrapper}>
                <Box className={classes.fieldWrapper}>
                    <TextField
                        label={strings.storageSearchForm.maxPricePerAreaUnitLabel}
                        name={formFieldKeys.maxPricePerAreaUnit}
                        size="small"
                        variant="outlined"
                        fieldProps={{ parse: identity }}
                        InputLabelProps={{ shrink: true }}
                        InputProps={createEndAdornment(strings.formatString(strings.currencyUnitPerSquareMeterAndMonth, appContext.currency.label))}
                    />
                </Box>
            </Box>
            <Box className={classes.maxPriceWrapper}>
                <Box className={classes.fieldWrapper}>
                    <TextField
                        label={strings.storageSearchForm.maxPricePerStorageLabel}
                        name={formFieldKeys.maxPricePerStorage}
                        size="small"
                        variant="outlined"
                        fieldProps={{ parse: identity }}
                        InputLabelProps={{ shrink: true }}
                        InputProps={createEndAdornment(strings.formatString(strings.currencyUnitPerMonth, appContext.currency.label))}
                    />
                </Box>
            </Box>
        </>
    );
};

export default WithStoragePopoverWrapper(MaxPriceSelectors);
