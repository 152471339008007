import React from 'react';
import PropTypes from 'prop-types';

import Button from '@material-ui/core/Button';
import Form from 'form/Form';
import { TextField } from 'mui-rff';
import AdminFormWrapper from '../../presentational/AdminFormWrapper';
import AdminFormButtons from '../../presentational/AdminFormButtons';
import AdminFormFields from '../../presentational/AdminFormFields';

const SearchStorageSitesForm = ({ searchParameters, onSubmit, isSubmitting = false }) => {
    return (
        <AdminFormWrapper isSubmitting={isSubmitting}>
            <Form
                initialValues={searchParameters}
                onSubmit={onSubmit}
            >
                {({ handleSubmit }) => (
                    <form onSubmit={handleSubmit}>
                        <AdminFormFields>
                            <TextField
                                name="title"
                                label="Search title"
                                variant="outlined"
                                placeholder="Whole or part of storage site title"
                            />
                            <TextField
                                name="ownerName"
                                label="Search owner name"
                                variant="outlined"
                                placeholder="Whole or part of owner name"
                            />
                        </AdminFormFields>

                        <AdminFormButtons>
                            <Button
                                type="submit"
                                variant="contained"
                                color="primary"
                            >
                                Search
                            </Button>
                        </AdminFormButtons>
                    </form>
                )}
            </Form>
        </AdminFormWrapper>
    );
};

SearchStorageSitesForm.propTypes = {
    searchParameters: PropTypes.object,
    onSubmit: PropTypes.func,
    isSubmitting: PropTypes.bool
};

export default SearchStorageSitesForm;
