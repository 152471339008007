import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useAppContext } from 'context/AppContext';
import { makeStyles } from 'styles/util';
import { required } from 'form/validation';
import { useForm, useField } from 'react-final-form';
import { useDispatch, useSelector } from 'react-redux';
import { handleResponse } from 'actions/actionHelpers';
import strings from 'localization/strings';
import { uploadMedia, cropImage } from 'actions/media';
import { getResizedImageUrl } from 'helpers/ImageHelper';
import mediaTypes from 'enums/mediaTypes';

import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { TextField, showErrorOnBlur } from 'mui-rff';
import { FieldArray } from 'react-final-form-arrays';
import ImageList from './ImageList';
import ImageDropzone from './ImageDropzone';
import VideoList from './VideoList';
import VideoDropzone from './VideoDropzone';
import DocumentList from './DocumentList';
import DocumentDropzone from './DocumentDropzone';
import AgreementList from './AgreementList';
import AgreementDropzone from './AgreementDropzone';
import ImageCropDialog from './ImageCropDialog';
import MediaUploadErrorDialog from 'common/MediaUploadErrorDialog';
import NullableDatePicker from 'form/NullableDatePicker';
import { getShortDateFormat } from 'helpers/DateHelper';
import MarginBox from 'common/MarginBox';
import Text from 'common/Text';

const useStyles = makeStyles(({ theme, colors }) => ({
    floatClearer: {
        clear: 'both'
    },
    marginBottom: {
        marginBottom: theme.spacing(2)
    },
    mediaSection: {
        marginBottom: theme.spacing(4)
    },
    asterisk: {
        color: colors.asterisk,
        opacity: 0.67
    },
    attentionTextSection: {
        display: 'flex'
    },
    attentionText: {
        flex: '80%'
    },
    attentionTextVisibilityDeadline: {
        flex: '20%',
        marginLeft: theme.spacing(1)
    }
}));

const EditStep5 = ({ showStepNumber = true }) => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const form = useForm();
    const { appContext } = useAppContext();

    const authenticationContext = useSelector(state => state.authentication.context);

    const fieldNamePrefix = 'steps[5]';

    const [ uploadErrors, setUploadErrors ] = useState([]);
    const [ imageCropDialogOpen, setImageCropDialogOpen ] = useState(false);
    const [ imageBeingCroppedData, setImageBeingCroppedData ] = useState(undefined);
    const [ uploadErrorDialogOpen, setUploadErrorDialogOpen ] = useState(false);

    const getName = suffix => suffix ? `${fieldNamePrefix}.${suffix}` : fieldNamePrefix;
    const getValue = suffix => useField(getName(suffix)).input.value;
    const changeValue = (suffix, newValue) => form.mutators.changeValue({ name: getName(suffix), value: newValue });

    const showAttentionText = authenticationContext.isSuperAdminOrImpersonated;

    const handleUploadErrorDialogClose = () => {
        setUploadErrorDialogOpen(false);
    };

    const handleMediaDrop = (acceptedFiles, fileRejections, fields, mediaType, maxFiles, onUploadSuccess) => {
        const acceptedMedias = acceptedFiles.map((o, i) => ({
            loading: true,
            index: fields.length + i
        }));
        setUploadErrors(fileRejections);
        setUploadErrorDialogOpen(fileRejections.length > 0);

        acceptedMedias.forEach((media, i) => {
            const file = acceptedFiles[i];
            fields.push(media);

            dispatch(uploadMedia(file, mediaType))
                .then(handleResponse(
                    response => {
                        const json = response.payload;
                        const mediaUrl = json.location;
                        const baseUrl = json.baseUrl;
                        if (json.success && mediaUrl) {
                            const newMedia = {
                                mediaUrl,
                                baseUrl,
                                loading: false
                            };
                            if(onUploadSuccess) {
                                onUploadSuccess(json, newMedia);
                            }
                            fields.update(media.index, newMedia);
                            if(media.index >= maxFiles) {
                                fields.remove(0);
                            }
                            if(mediaType === mediaTypes.image.key && acceptedMedias.length === 1) {
                                // if a single image, open crop dialog
                                setImageBeingCroppedData({ image: newMedia, index: media.index, fields, removeOnCancel: true });
                                setImageCropDialogOpen(true);
                            }
                        } else {
                            fields.remove(media.index);
                        }
                    }
                ));
        });
    };

    const predefinedAttentionTexts = strings.predefinedAttentionTexts.split(';');

    const handleImageClick = (image, index, fields) => {
        setImageBeingCroppedData({ image, index, fields });
        setImageCropDialogOpen(true);
    };

    const handleImageCropDialogOk = imageCropInfo => {
        setImageCropDialogOpen(false);
        const loadingMedia = {
            ...imageBeingCroppedData.fields[imageBeingCroppedData.index],
            loading: true
        };
        imageBeingCroppedData.fields.update(imageBeingCroppedData.index, loadingMedia);
        const request = {
            mediaUrl: imageBeingCroppedData.image.mediaUrl,
            crop: {
                x: imageCropInfo.crop.x / 100,
                y: imageCropInfo.crop.y / 100,
                width: imageCropInfo.crop.width / 100,
                height: imageCropInfo.crop.height / 100
            }
        };
        dispatch(cropImage(request))
            .then(handleResponse(
                response => {
                    const mediaUrl = response.payload.location;
                    const baseUrl = response.payload.baseUrl;
                    if (response.payload.success && mediaUrl) {
                        const newMedia = {
                            mediaUrl,
                            baseUrl,
                            loading: false
                        };
                        imageBeingCroppedData.fields.update(imageBeingCroppedData.index, newMedia);
                    }
                    setImageBeingCroppedData(undefined);
                }
            ));
    };

    const handleImageCropDialogCancel = () => {
        if(imageBeingCroppedData.removeOnCancel) {
            imageBeingCroppedData.fields.remove(imageBeingCroppedData.index);
        }
        setImageCropDialogOpen(false);
        setImageBeingCroppedData(undefined);
    };

    const getImageBeingCroppedUrl = () => {
        if(!imageBeingCroppedData || !imageBeingCroppedData.image) {
            return undefined;
        }
        return getResizedImageUrl(imageBeingCroppedData.image.mediaUrl, { width: 800 });
    };

    return (
        <Box>
            <Typography variant="h5" gutterBottom>
                { showStepNumber && '6. ' }
                {strings.storageSiteTextAndImagesTitle}
            </Typography>

            <Typography variant="body1" className={classes.marginBottom}>
                {strings.storageSiteTextAndImagesDescription}
            </Typography>

            <TextField
                name={getName('title')}
                label={strings.title}
                variant="outlined"
                showError={showErrorOnBlur}
                maxLength={256}
                required
                fieldProps={{ validate: required }}
            />

            <TextField
                name={getName('description')}
                label={strings.description}
                variant="outlined"
                multiline
                rows={8}
                maxLength={1000}
                required
                fieldProps={{ validate: required }}
            />

            <TextField
                name={getName('descriptionGivenAfterPurchase')}
                label={strings.descriptionGivenAfterPurchase}
                helperText={strings.descriptionGivenAfterPurchaseHelperText}
                variant="outlined"
                multiline
                rows={4}
                maxLength={1000}
            />

            <TextField
                name={getName('lockCode')}
                label={strings.storageSiteLockCode}
                helperText={strings.storageSiteLockCodeHelperText}
                variant="outlined"
                maxLength={256}
            />

            {
                showAttentionText &&
                (
                    <Box className={classes.attentionTextSection}>
                        <Box className={classes.attentionText}>
                            <Autocomplete
                                freeSolo
                                value={getValue('attentionText')}
                                onChange={(event, newValue) => {
                                    changeValue('attentionText', newValue);
                                }}
                                options={predefinedAttentionTexts}
                                renderInput={params =>
                                    <TextField
                                        {...params}
                                        name={getName('attentionText')}
                                        label={strings.attentionText}
                                        helperText={strings.attentionTextHelperText}
                                        variant="outlined"
                                        maxLength={128}
                                    />
                                }
                            />
                        </Box>
                        <Box className={classes.attentionTextVisibilityDeadline}>
                            <NullableDatePicker
                                name={getName('attentionTextVisibilityDeadline')}
                                label={strings.lastVisibilityDate}
                                formatFunc={getShortDateFormat}
                                inputVariant="outlined"
                                showError={showErrorOnBlur}
                                nullString={strings.untilFurther}
                            />
                        </Box>
                    </Box>
                )
            }

            <Box className={classes.mediaSection}>
                <Typography variant="h6" gutterBottom>
                    {strings.images} <Box component="span" className={classes.asterisk}>*</Box>
                </Typography>

                <FieldArray name={getName('images')}>
                    {({ fields }) => (
                        <>
                            <ImageList fields={fields} onImageClick={(image, index) => handleImageClick(image, index, fields)}/>
                            <Box className={classes.floatClearer}/>
                            <ImageDropzone fields={fields} onDrop={handleMediaDrop} />
                        </>
                    )}
                </FieldArray>
            </Box>

            <Box className={classes.mediaSection}>
                <Typography variant="h6" gutterBottom>
                    {strings.videos}
                </Typography>

                <FieldArray name={getName('videos')}>
                    {({ fields }) => (
                        <>
                            <VideoList fields={fields}/>
                            <Box className={classes.floatClearer}/>
                            <VideoDropzone fields={fields} onDrop={handleMediaDrop} />
                        </>
                    )}
                </FieldArray>
            </Box>

            <Box className={classes.mediaSection}>
                <Typography variant="h6" gutterBottom>
                    {strings.documents}
                </Typography>

                <FieldArray name={getName('documents')}>
                    {({ fields }) => (
                        <>
                            <DocumentList fields={fields}/>
                            <Box className={classes.floatClearer}/>
                            <DocumentDropzone fields={fields} onDrop={handleMediaDrop} />
                        </>
                    )}
                </FieldArray>
            </Box>

            {
                appContext.useAgreements &&
                (
                    <Box className={classes.mediaSection}>
                        <Typography variant="h6" gutterBottom>
                            {strings.agreement}
                        </Typography>

                        {
                            strings.uploadAgreementHelpText &&
                            (
                                <MarginBox bottom={2}>
                                    <Text html={strings.uploadAgreementHelpText} />
                                </MarginBox>
                            )
                        }

                        <FieldArray name={getName('agreements')}>
                            {({ fields }) => (
                                <>
                                    <AgreementList fields={fields}/>
                                    <Box className={classes.floatClearer}/>
                                    <AgreementDropzone fields={fields} onDrop={handleMediaDrop} />
                                </>
                            )}
                        </FieldArray>
                    </Box>
                )
            }
            <ImageCropDialog
                imageUrl={getImageBeingCroppedUrl()}
                open={imageCropDialogOpen}
                onOk={handleImageCropDialogOk}
                onCancel={handleImageCropDialogCancel}
            />

            <MediaUploadErrorDialog
                uploadErrors={uploadErrors}
                open={uploadErrorDialogOpen}
                onClose={handleUploadErrorDialogClose}
            />

        </Box>
    );
};

EditStep5.propTypes = {
    showStepNumber: PropTypes.bool
};

export default EditStep5;
