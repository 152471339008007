import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from 'styles/util';

const IconSendMessage = ({fill}) => {
    const useStyles = makeStyles(() => ({
        svg: {
            fill: fill
        }
    }));

    const classes = useStyles();
    return (
        <svg className={classes.svg} width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
            <path d="M3.67148 20.4003L21.1215 12.9203C21.9315 12.5703 21.9315 11.4303 21.1215 11.0803L3.67148 3.60026C3.01148 3.31026 2.28148 3.80026 2.28148 4.51026L2.27148 9.12026C2.27148 9.62026 2.64148 10.0503 3.14148 10.1103L17.2715 12.0003L3.14148 13.8803C2.64148 13.9503 2.27148 14.3803 2.27148 14.8803L2.28148 19.4903C2.28148 20.2003 3.01148 20.6903 3.67148 20.4003Z"/>
        </svg>
    );
};

IconSendMessage.propTypes = {
    fill: PropTypes.string.isRequired
};

export default IconSendMessage;
