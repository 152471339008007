export const storageFonts = {
    black: '"Circular Std Black", sans-serif',
    bold: '"Circular Std Bold", sans-serif',
    regular: '"Circular Std Book", sans-serif',
    medium: '"Circular Std Medium", sans-serif'
};

export const officeFonts = {
    black: '"DM Serif Display", sans-serif',
    bold: '"DM Serif Display", sans-serif',
    regular: '"Manrope Regular", sans-serif',
    medium: '"Manrope Extra Bold", sans-serif'
};
