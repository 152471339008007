import React from 'react';
import { useAppContext } from 'context/AppContext';
import PropTypes from 'prop-types';

import { GTMProvider } from '@elgorditosalsero/react-gtm-hook';

const GoogleTagManagerProvider = ({ enabled = true, children }) => {
    if(!enabled) {
        return children;
    }

    const { appContext } = useAppContext();

    const l = document.location;
    const googleTagManagerParams = {
        id: appContext.google.tagManagerKey,
        dataLayer: {
            originalLocation: l.protocol + '//' + l.hostname + l.pathname + l.search
        }
    };
    return (
        <>
            { /* Google Tag Manager (noscript) */ }
            <noscript><iframe src={`https://www.googletagmanager.com/ns.html?id=${appContext.google.tagManagerKey}`} height="0" width="0" style="display:none;visibility:hidden" /></noscript>
            <GTMProvider state={googleTagManagerParams}>
                {children}
            </GTMProvider>
        </>
    );
};

GoogleTagManagerProvider.propTypes = {
    enabled: PropTypes.bool,
    children: PropTypes.node.isRequired
};

export default GoogleTagManagerProvider;
