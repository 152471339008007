import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from 'styles/util';
import Box from '@material-ui/core/Box';
import PurchaseItem from 'common/PriceSpecification/PurchaseItem';

const useStyles = makeStyles(({ theme }) => ({
    tableContent: {
        padding: theme.spacing(1.875),
        '$row:not(:last-child)': {
            paddingBottom: 0
        }
    }
}));

const PriceSpecificationContent = ({purchaseItems, currency, formatAmountOptions, showVat, expandVat, rowClassName}) => {
    const classes = useStyles();
    return (
        <Box className={classes.tableContent}>
            {purchaseItems.map((purchaseItem, i) => (
                <PurchaseItem
                    key={i}
                    purchaseItem={purchaseItem}
                    currency={currency}
                    formatAmountOptions={formatAmountOptions}
                    showVat={showVat}
                    expandVat={expandVat}
                    rowClassName={rowClassName}
                />
            ))}
        </Box>
    );
};

PriceSpecificationContent.propTypes = {
    purchaseItems: PropTypes.array.isRequired,
    currency: PropTypes.string.isRequired,
    formatAmountOptions: PropTypes.object,
    showVat: PropTypes.bool,
    expandVat: PropTypes.bool,
    rowClassName: PropTypes.string
};

export default PriceSpecificationContent;
