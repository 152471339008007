import React from 'react';
import PropTypes from 'prop-types';
import { getDate } from 'date-fns';
import { formatIsoDate } from 'helpers/DateHelper';
import { useAppContext } from 'context/AppContext';
import { makeStyles } from 'styles/util';
import { getAllColumns } from  'logic/capacityUtilizationLogic';

import { LineChart, Line, CartesianGrid, XAxis, YAxis, Tooltip } from 'recharts';
import Box from '@material-ui/core/Box';

const useStyles = makeStyles(({ colors }) => ({
    tooltip: {
        backgroundColor: colors.tooltipGrey,
        color: colors.white,
        padding: '4px 8px',
        borderRadius: '4px'
    }
}));

const CapacityUtilizationChart = ({ width, height, data, property }) => {
    const classes = useStyles();
    const { appContext } = useAppContext();

    const getXAxisTicks = () => {
        const tickValues = [];
        data.forEach((o, i) => {
            if(getDate(o.date) === 1) {
                tickValues.push(i);
            }
        });
        return tickValues;
    };

    const getCurrency = () => {
        const item = data.find(x => x.currency !== undefined);
        return item === undefined
            ? ''
            : item.currency;
    };

    const formatDateLabel = value => data[value]
        ? formatIsoDate(data[value].date)
        : '';

    const formatValueForTooltip = (p, value) => {
        return p.formatValueFromObject(value, appContext);
    };

    const formatValueForYAxis = property.formatValueCreator(appContext, { round: true, currency: getCurrency() });

    const axisStyle = {
        fontSize: '80%'
    };

    const dataWithXValues = data.map((o, i) => ({ ...o, x: i, y: property.getNumericValue(o) }));

    const renderTooltip = ({ active, payload, label }) => {
        if (active && payload?.length > 0) {
            const allColumns = getAllColumns(property);
            return (
                <Box className={classes.tooltip}>
                    <Box>
                        {formatDateLabel(label)}
                    </Box>
                    {
                        allColumns.map(p => (
                            <Box key={p.key}>
                                {allColumns.length > 1 && (p.title + ': ')}{formatValueForTooltip(p, payload[0].payload)}
                            </Box>
                        ))
                    }
                </Box>
            );
        }
        return null;
    };

    return (
        <LineChart
            width={width}
            height={height}
            data={dataWithXValues}
        >
            <CartesianGrid stroke="#ccc" />
            <XAxis
                dataKey="x"
                ticks={getXAxisTicks()}
                tickFormatter={formatDateLabel}
                style={axisStyle}
            />
            <YAxis
                tickFormatter={formatValueForYAxis}
                width={80}
                style={axisStyle}
            />
            <Line
                type="linear"
                dataKey="y"
                stroke={appContext.colors.primaryColor}
                strokeWidth={3}
                dot={false}
                animationDuration={300}
            />
            <Tooltip
                content={renderTooltip}
                animationDuration={300}
            />
        </LineChart>
    );
};

CapacityUtilizationChart.propTypes = {
    width: PropTypes.number.isRequired,
    height: PropTypes.number.isRequired,
    data: PropTypes.array.isRequired,
    property: PropTypes.object.isRequired
};

export default CapacityUtilizationChart;
