import React from 'react';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet-async';

const OpenGraphImage = ({ url, width, height, alt }) => {
    if(!url) {
        return null;
    }

    return (
        <Helmet defer={false}>
            <meta property="og:image" content={document.location.origin + url}/>
            <meta property="og:image:secure_url" content={document.location.origin + url}/>
            { width && <meta property="og:image:width" content={width} /> }
            { height && <meta property="og:image:height" content={height} /> }
            { alt && <meta property="og:image:alt" content={alt} /> }
        </Helmet>
    );
};

OpenGraphImage.propTypes = {
    url: PropTypes.string,
    width: PropTypes.number,
    height: PropTypes.number,
    alt: PropTypes.string
};

export default OpenGraphImage;
