import React from 'react';
import PropTypes from 'prop-types';
import { useAppContext } from 'context/AppContext';
import { makeStyles } from 'styles/util';

import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';

const useStyles = makeStyles(({ theme, colors }) => ({
    header: {
        marginTop: theme.spacing(2),
        display: 'flex',
        alignItems: 'center'
    },
    logo: {
        flex: '0 0 auto',
        width: '20%',
        marginRight: '40px'
    },
    text: {
        flex: '1 1 auto',
        color: `${colors.white} !important`,
        backgroundColor: colors.primaryColor,
        fontSize: '26px',
        margin: 0,
        paddingRight: '56px',
        height: '100px',
        lineHeight: '100px',
        whiteSpace: 'nowrap',
        fontWeight: 'bold',
        textAlign: 'right'
    }
}));

const Header = ({ text }) => {
    const classes = useStyles();
    const { appContext } = useAppContext();

    return (
        <Box className={classes.header}>
            <img className={classes.logo} src={appContext.images.topLogo} />
            <Typography variant="h2" className={classes.text}>{text}</Typography>
        </Box>
    );
};

Header.propTypes = {
    text: PropTypes.string.isRequired
};

export default Header;
