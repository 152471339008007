import queryString from 'query-string';

const extractSearchParametersFromLocation = (location) => {
    const searchParameters = queryString.parse(location.search);
    if(searchParameters.page) {
        searchParameters.page = parseInt(searchParameters.page, 10) || 0;
    }
    if(searchParameters.pageSize) {
        searchParameters.pageSize = parseInt(searchParameters.pageSize, 10) || 0;
    }
    return searchParameters;
};

export const sanitizePage = (page, pageSize, count) => {
    return pageSize
        ? Math.max(0, Math.min(Math.floor((count - 1) / pageSize), page || 0))
        : 0;
};

export const performSearch = (obj, searchParameters) => {
    // validate page number
    if (obj.searchResult.items) {
        const sanitizedPage = sanitizePage(searchParameters.page, searchParameters.pageSize, obj.searchResult.count);
        if (sanitizedPage !== searchParameters.page || 0) {
            obj.search({ page: sanitizedPage });
            return;
        }
    }

    const previousQueryString = queryString.stringify(queryString.parse(obj.location.search));
    const currentQueryString = queryString.stringify(obj.createSearchParametersForQuerystring ? obj.createSearchParametersForQuerystring(searchParameters) : searchParameters);
    if (currentQueryString !== previousQueryString || !obj.isInitializedRef.current) {
        // keep URL in synch with search
        // this will trigger another execution of this function, but as currentQueryString === previousQueryString this block will not be executed
        // TODO: handle non-paged tables with frontend sorting and url updates without any API calls
        obj.history.push(obj.location.pathname + '?' + currentQueryString);
        obj.apiCall(searchParameters);
        obj.setSearchParametersToState(searchParameters);
        obj.isInitializedRef.current = true;
    }
};

export const mergeSearchParameters = (parameters, location, searchParametersFromState, defaultParameters) => {
    const currentParameters = extractSearchParametersFromLocation(location);
    const newParameters = { ...defaultParameters, ...searchParametersFromState, ...currentParameters, ...parameters };
    return newParameters;
};
