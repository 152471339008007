import React, { useEffect, useState } from 'react';
import { useAppContext } from 'context/AppContext';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useHistory } from 'react-router-dom';
import { fetchEidTemporaryData, logIn } from 'actions/authentication';
import { handleResponse } from 'actions/actionHelpers';
import strings from 'localization/strings';
import { makeStyles } from 'styles/util';
import { useScrollToTop } from 'helpers/BrowserHelper';
import userAuthenticationTypes from 'enums/userAuthenticationTypes';
import organizationTypes from 'enums/organizationTypes';
import routes from 'routes';
import { appendQuerystringParametersForAuthentication, createObjectFromAuthenticationQuerystringParameters, handleSuccessfulLogIn } from 'logic/authenticationLogic';
import { isProduction } from 'helpers/EnvironmentHelper';

import PageTitle from 'common/PageTitle';
import EidLoginContainer from 'components/eid/EidLoginContainer';
import MuiContainer from '@material-ui/core/Container';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import ContentBox from 'common/ContentBox';
import Loader from 'common/Loader';
import { Link } from 'react-router-dom';
import EidUserDoesNotExistDialog from './EidUserDoesNotExistDialog';
import Alert from '@material-ui/lab/Alert';

const useStyles = makeStyles(({ theme }) => ({
    pageWrapper: {
        display: 'flex',
        flexDirection: 'column',
        gap: theme.spacing(3.75),
        marginTop: theme.spacing(10),
        marginBottom: theme.spacing(3.75),
        [theme.breakpoints.down('sm')]: {
            marginTop: theme.spacing(3.75)
        }
    }
}));

const EidLoginPage = () => {
    const classes = useStyles();
    const location = useLocation();
    const history = useHistory();
    const dispatch = useDispatch();
    const { appContext } = useAppContext();
    const redirectUrl = useSelector(state => state.authentication.redirectUrl);
    const isAuthenticated = useSelector(state => !!state.authentication.token);
    const { eidLoginResult, eidTemporaryDataToken } = createObjectFromAuthenticationQuerystringParameters(location);
    const [ isLoading, setIsLoading ] = useState(false);
    const [ createAccountParameters, setCreateAccountParameters ] = useState(false);
    const [ eidUserDoesNotExistDialogOpen, setEidUserDoesNotExistDialogOpen ] = useState(false);

    const title = strings.login;

    const eidAllowed = !!appContext.authentication.allowedUserAuthenticationTypes.find(o => o === userAuthenticationTypes.eid.key);
    const usernameAndPasswordAllowed = !!appContext.authentication.allowedUserAuthenticationTypes.find(o => o === userAuthenticationTypes.usernameAndPassword.key);

    const showUsernameAndPasswordLink = usernameAndPasswordAllowed && eidLoginResult !== 'success';

    const privateAllowed = !!appContext.allowedOrganizationTypes.find(o => o === organizationTypes.private.key) && usernameAndPasswordAllowed;

    const eidDescription = privateAllowed
        ? undefined
        : strings.bankId.loginBodyBusinessOnly;

    const useMockLogin = !isProduction(appContext);

    useScrollToTop();

    useEffect(() => {
        if(eidLoginResult === 'success') {
            setIsLoading(true);
            dispatch(fetchEidTemporaryData(eidTemporaryDataToken, true /* keepData */))
                .then(handleResponse(
                    response => {
                        const { userInfo, clientState } = response.payload;
                        if(userInfo.storage365User) {
                            // the user has already logged in
                            const logInRequest = {
                                eidLogInRequest: {
                                    eidTemporaryDataToken
                                }
                            };
                            dispatch(logIn(logInRequest))
                                .then(handleResponse(
                                    () => handleSuccessfulLogIn({
                                        takeoverTenant: clientState?.takeoverTenant,
                                        userInvitation: clientState?.userInvitation,
                                        redirectUrl,
                                        setIsLoading,
                                        dispatch,
                                        history
                                    })
                                ));
                        } else {
                            // a first-time login
                            // redirect to create user flow after showing a dialog
                            setIsLoading(false);
                            setCreateAccountParameters({
                                eidTemporaryDataToken,
                                userInvitationId: clientState?.userInvitation?.id,
                                userInvitationToken: clientState?.userInvitation?.token,
                                takeoverTenantId: clientState?.takeoverTenant?.id,
                                takeoverTenantToken: clientState?.takeoverTenant?.token,
                                createAccountFromEidUserInfo: true
                            });
                            setEidUserDoesNotExistDialogOpen(true);
                        }
                    },
                    response => {
                        if(response.meta.response.status === 404 && isAuthenticated) {
                            // this is probably due to a token being used twice, caused by back button navigation
                            history.push(routes.account.overview);
                            return false;
                        }
                        return undefined;
                    }
                ));
        }
    }, [eidLoginResult, eidTemporaryDataToken]);

    const handleEidUserDoesNotExistDialogOk = () => {
        setEidUserDoesNotExistDialogOpen(false);
        history.push(appendQuerystringParametersForAuthentication(routes.createAccount, createAccountParameters));
    };

    const handleEidUserDoesNotExistDialogCancel = () => {
        history.push(routes.index);
    };

    if(!eidAllowed) {
        return null;
    }

    return (
        <>
            <PageTitle>{title}</PageTitle>
            <MuiContainer maxWidth={false}>
                <ContentBox className={classes.pageWrapper} themeMaxWidth="xs">
                    <Typography variant="h2">
                        {strings.login}
                    </Typography>

                    {
                        isLoading &&
                        (
                            <Loader />
                        )
                    }

                    {
                        !isLoading &&
                        (
                            <>
                                {
                                    eidLoginResult === 'failure' &&
                                    (
                                        <Alert severity="error">
                                            Authentication failed.
                                        </Alert>
                                    )
                                }
                                {
                                    eidLoginResult !== 'success' &&
                                    (
                                        <EidLoginContainer
                                            redirectUrl={appendQuerystringParametersForAuthentication(routes.eidLogIn, location.search)}
                                            description={eidDescription}
                                        />
                                    )
                                }

                                {
                                    showUsernameAndPasswordLink &&
                                    (
                                        <Box>
                                            <Link to={appendQuerystringParametersForAuthentication(routes.usernameAndPasswordLogIn, location.search)}>
                                                {strings.passwordLoginInstead}
                                            </Link>
                                        </Box>
                                    )
                                }
                                {
                                    useMockLogin &&
                                    (
                                        <Box>
                                            <Link to={appendQuerystringParametersForAuthentication(routes.mockLogIn, location.search)}>
                                                Select person to log in as (test environment only)
                                            </Link>
                                        </Box>
                                    )
                                }
                            </>
                        )
                    }
                </ContentBox>
            </MuiContainer>
            <EidUserDoesNotExistDialog
                open={eidUserDoesNotExistDialogOpen}
                onOk={handleEidUserDoesNotExistDialogOk}
                onCancel={handleEidUserDoesNotExistDialogCancel}
            />
        </>
    );
};

export default EidLoginPage;
