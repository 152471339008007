import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from 'styles/util';
import { useAppContext } from 'context/AppContext';
import adminBookingStatuses from 'enums/adminBookingStatuses';
// import { Link } from 'react-router-dom';
import { getStorageSiteAndStorageTitle } from 'helpers/StorageSiteHelper';
import strings from 'localization/strings';
import routes from 'routes';
// import { getBusinessOrPrivateName } from 'helpers/ActorHelper';
import { formatTimePeriod } from 'helpers/StringHelper';

import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import LocationOnIcon from '@material-ui/icons/LocationOn';
// import Button from '@material-ui/core/Button';
// import IconButton from '@material-ui/core/IconButton';
// import KeyboardArrowRightIcon from '@material-ui/icons/KeyboardArrowRight';
import StorageSiteImage from '../StorageSiteImage';
// import Hidden from '@material-ui/core/Hidden';
// import BookingChip from './BookingChip';
// import BookingNextAction from './BookingNextAction';
import CardLink from 'common/CardLink';
import IconPrefix from 'common/IconPrefix';
import StatusChip from 'common/StatusChip';
import ChevronRight from 'common/ChevronRight';

const useStyles = makeStyles(({ theme, colors }) => ({
    location: {
        marginLeft: '-4px'
    },
    information: {
        textDecoration: 'none',
        display: 'flex',
        alignItems: 'center',
        '& > :first-child': {
            flex: '1 1 auto'
        },
        '& > :last-child': {
            display: 'flex',
            flex: '0 0 auto'
        }
    },
    bookingContent: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        padding: theme.spacing(0, 1, 0, 1),
        gap: theme.spacing(1.875)
    },
    image: {
        display: 'flex',
        '& img': {
            height: '100%',
            width: '100%',
            objectFit: 'cover'
        }
    },
    chevronRight: {
        fill: colors.secondaryColor
    }
}));

const TenantBookingListItem = ({ booking }) => {
    const { appContext } = useAppContext();
    const classes = useStyles();
    const storageGroup = booking.storageGroup;
    const storageSite = storageGroup.storageSite;
    const title = getStorageSiteAndStorageTitle(booking.storage, storageGroup);

    const imageUrl = storageSite.images[0]
        ? storageSite.images[0].mediaUrl
        : undefined;

    const manageUrl = routes.account.tenantBookingDetails.replace(':bookingId', booking.id);

    const getLabelForBooking = () => {
        const prefix = strings.bookingLabelPrefixes[booking.adminBookingStatus];
        const type = booking.subscriptionBooking
            ? strings.subscriptionBookingLowercase
            : strings.periodBookingLowercase;
        return <>{prefix} {type}</>;
    };

    const getPeriodForBooking = () => {
        return formatTimePeriod(booking);
    };

    return (
        <CardLink to={manageUrl}>
            <Box className={classes.image}>
                <StorageSiteImage storageSite={storageSite} imageUrl={imageUrl} width={385} height={182} />
            </Box>
            <Box className={classes.information}>
                <Typography variant="h5" component="p">
                    {title}
                </Typography>
                <ChevronRight className={classes.chevronRight} />
            </Box>
            <Box className={classes.bookingContent}>
                {
                    storageSite.city &&
                    (
                        <IconPrefix className={classes.location} icon={<LocationOnIcon htmlColor={appContext.colors.icon}/>}>
                            {storageSite.city}
                        </IconPrefix>
                    )
                }
                <StatusChip text={getPeriodForBooking()} heading={getLabelForBooking()} status={adminBookingStatuses[booking.adminBookingStatus].chipType}/>
            </Box>
        </CardLink>
    );
};

TenantBookingListItem.propTypes = {
    booking: PropTypes.object.isRequired
};

export default TenantBookingListItem;

