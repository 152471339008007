import store from 'store2';

const _geolocationEnabledKey = 'geolocationManager.enabled';
const _latitudeKey = 'geolocationManager.latitude';
const _longitudeKey = 'geolocationManager.longitude';

const getCurrentLocation = (onSuccess, onFailure, options) => {
    if ('geolocation' in navigator) {
        let fallbackTimeout;

        const successCallback = args => {
            if (fallbackTimeout) {
                window.clearTimeout(fallbackTimeout);
            }
            store.set(_geolocationEnabledKey, true);
            store.set(_latitudeKey, args.coords.latitude);
            store.set(_longitudeKey, args.coords.longitude);
            if (onSuccess) {
                onSuccess({
                    latitude: args.coords.latitude,
                    longitude: args.coords.longitude
                });
            }
        };

        const failureCallback = args => {
            if (fallbackTimeout) {
                window.clearTimeout(fallbackTimeout);
            }
            store.set(_geolocationEnabledKey, true);
            if (onFailure) {
                onFailure(args);
            }
        };

        navigator.geolocation.getCurrentPosition(successCallback, failureCallback, options);

        // if navigator.geolocation.getCurrentPosition does not trigger any success or failure, which seems to happen sometimes on e.g. iPhone,
        // trigger a timeout failure at the desired timeout by using a standard window.setTimeout
        if (options && options.timeout) {
            fallbackTimeout = window.setTimeout(() => {
                // simulate a geolocation request timeout
                const failureArgs = {
                    code: 3 // timeout
                };
                failureCallback(failureArgs);
            }, options.timeout);
        }
    } else {
        store.set(_geolocationEnabledKey, false);
        store.remove(_latitudeKey);
        store.remove(_longitudeKey);
        if (onFailure) {
            onFailure();
        }
    }
};

export default class GeolocationManager {
    isEnabled() {
        // Returns true if the geolocation has been actively enabled by the user. Returns false if geolocation has been actively disabled or if the user has not yet decided whether to activate geolocation or not.
        return store.get(_geolocationEnabledKey);
    }

    isDisabled() {
        // Returns true if the geolocation has been actively disabled by the user. Returns false if geolocation has been actively enabled or if the user has not yet decided whether to activate geolocation or not.
        return !store.get(_geolocationEnabledKey);
    }

    askToEnableForFirstTime(successCallback, failureCallback) {
        // Asks the user to enable geolocation, if the user has has not yet decided whether to activate geolocation or not.
        //   successCallback: Function to be called when a location has been received. Can be omitted.
        //   failureCallback: Function to be called when no location could be received. Can be omitted.
        if (!this.isEnabled() && !this.isDisabled()) {
            this.askToEnable(successCallback, failureCallback);
        }
    }

    askToEnable(successCallback, failureCallback) {
        // Asks the user to enable geolocation, if the user has actively disabled geolocation or the user has not yet decided whether to activate geolocation or not.
        //   successCallback: Function to be called when a location has been received. Can be omitted.
        //   failureCallback: Function to be called when no location could be received. Can be omitted.
        if (!this.isEnabled()) {
            getCurrentLocation(successCallback, failureCallback);
        }
    }

    getLastLocation() {
        // Synchronously returns the last location that was received. Might be different from the current location.
        if (this.isEnabled()) {
            const latitude = store.get(_latitudeKey);
            const longitude = store.get(_longitudeKey);

            // fire-and-forget call that receives a fresh location that can be used next time
            getCurrentLocation();

            if (latitude && longitude) {
                return {
                    latitude: parseFloat(latitude),
                    longitude: parseFloat(longitude)
                };
            }
        }
        return null;
    }

    getCurrentLocation(successCallback, failureCallback, timeout) {
        // Asynchronously returns the current location.
        //   successCallback: Function to be called when a location has been received. Can be omitted.
        //   failureCallback: Function to be called when no location could be received. Can be omitted.
        //   timeout: The timeout in milliseconds. Can be null. If a timeout occurs, the last location is used.
        let options = null;
        if (timeout) {
            options = {
                timeout: timeout
            };
        }

        const failureWrapper = args => {
            if (args && args.code === 3 /* timeout */) {
                // we timed out
                const location = this.getLastLocation();
                if (successCallback && location) {
                    successCallback(location);
                    return;
                }
            }
            if (failureCallback) {
                failureCallback();
            }
        };

        getCurrentLocation(successCallback, failureWrapper, options);
    }
}
