import React, { useState } from 'react';
import PropTypes from 'prop-types';
import strings from 'localization/strings';
import { makeStyles } from 'styles/util';

import ReactCrop from 'react-image-crop';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Loader from 'common/Loader';

import 'react-image-crop/dist/ReactCrop.css';

const useStyles = makeStyles(({ theme }) => ({
    dialog: {
        '& .MuiDialog-paper': {
            maxWidth: '848px'
        }
    },
    information: {
        marginBottom: theme.spacing(2)
    }
}));

const initialCrop = {
    unit: '%',
    width: 100,
    aspect: 8 / 5,
};

const ImageCropDialog = ({ imageUrl, open, onOk, onCancel }) => {
    const classes = useStyles();

    const [crop, setCrop] = useState(initialCrop);
    const [isLoading, setIsLoading] = useState(true);

    const handleCropChange = (newCrop, newPercentCrop) => {
        setCrop(newPercentCrop);
    };

    const handleImageLoaded = image => {
        const aspect = initialCrop.aspect;
        const width = image.width / aspect < image.height * aspect ? 100 : ((image.height * aspect) / image.width) * 100;
        const height = image.width / aspect > image.height * aspect ? 100 : (image.width / aspect / image.height) * 100;
        const y = (100 - height) / 2;
        const x = (100 - width) / 2;

        setCrop({
            ...initialCrop,
            width,
            height,
            x,
            y
        });

        setIsLoading(false);

        return false;
    };

    const handleOk = () => {
        onOk({
            imageUrl,
            crop
        });
        setIsLoading(true);
    };

    const handleCancel = () => {
        setIsLoading(true);
        onCancel();
    };

    return (
        <Dialog fullWidth open={open} onClose={handleCancel} className={classes.dialog}>
            <DialogTitle disableTypography>
                <Typography variant="h5">
                    {strings.cropImageDialogTitle}
                </Typography>
            </DialogTitle>
            <DialogContent>
                <Typography variant="body1" className={classes.information}>
                    {strings.cropImageDialogInformation}
                </Typography>
                {
                    isLoading && <Loader />
                }
                {
                    imageUrl &&
                    (
                        <ReactCrop
                            src={imageUrl}
                            crop={crop}
                            onChange={handleCropChange}
                            onImageLoaded={handleImageLoaded}
                        />
                    )
                }
            </DialogContent>
            <DialogActions>
                <Button
                    variant="contained"
                    color="primary"
                    onClick={handleOk}
                    disabled={isLoading}
                >
                    {strings.ok}
                </Button>
                <Button
                    variant="outlined"
                    color="primary"
                    onClick={handleCancel}
                >
                    {strings.cancel}
                </Button>
            </DialogActions>
        </Dialog>
    );
};

ImageCropDialog.propTypes = {
    imageUrl: PropTypes.string,
    open: PropTypes.bool.isRequired,
    onOk: PropTypes.func.isRequired,
    onCancel: PropTypes.func
};

export default ImageCropDialog;
