import React from 'react';
import PropTypes from 'prop-types';
import strings from 'localization/strings';
import { makeStyles } from 'styles/util';

import Amount from 'common/Amount';
import RoundingCorrection from './RoundingCorrection';

const useStyles = makeStyles(() => ({
    caption: {
        '& td': {
            paddingBottom: '0.5em'
        }
    },
    mainTitle: {
        fontWeight: 'bold',
        fontSize: '16px'
    },
    right: {
        textAlign: 'right'
    }
}));

const ReceiptSpecification = ({ purchase }) => {
    const classes = useStyles();

    const formatDescription = purchaseItem => purchaseItem.description + (purchaseItem.timePeriodSpecification ? `, ${purchaseItem.timePeriodSpecification}` : '');

    return (
        <>
            <tr className={classes.caption}>
                <td className={classes.mainTitle}>{strings.paymentDocuments.specification}</td>
            </tr>
            {
                purchase.purchaseItems.map(purchaseItem =>
                (
                    <tr key={purchaseItem.id}>
                        <td colSpan={2}>
                            {formatDescription(purchaseItem)}
                        </td>
                        <td colSpan={2} className={classes.right}>
                            <Amount value={purchaseItem.amount + purchaseItem.vat} currency={purchaseItem.currency} booking={purchase.booking} displayPriceRounding={false} />
                        </td>
                    </tr>
                ))
            }
            <RoundingCorrection purchase={purchase} />
        </>
    );
};

ReceiptSpecification.propTypes = {
    purchase: PropTypes.object.isRequired
};

export default ReceiptSpecification;
