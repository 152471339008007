import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { makeStyles } from 'styles/util';
import strings from 'localization/strings';
import { fetchStorageGroupBookingCalendar } from 'actions/storageGroups';
import { handleResponse } from 'actions/actionHelpers';
import { getBookableStorages } from 'logic/bookingLogic';

import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Form from 'form/Form';
import { TextField } from 'mui-rff';
import MenuItem from '@material-ui/core/MenuItem';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import Loader from 'common/Loader';
import LoadingError from 'common/LoadingError';

const useStyles = makeStyles(({ theme, colors }) => ({
    loader: {
        marginTop: theme.spacing(2)
    },
    notAvailable: {
        color: colors.textColorDisabled
    }
}));

const ChangeStorageDialog = ({ booking, open, onOk, onCancel }) => {
    const classes = useStyles();
    const dispatch = useDispatch();

    const initialValues = {
        storageId: booking.storage
            ? booking.storage.id
            : undefined
    };

    const [bookingCalendar, setBookingCalendar] = useState(undefined);
    const [isLoading, setIsLoading] = useState(false);
    const [loadingError, setLoadingError] = useState(undefined);

    useEffect(() => {
        if(open) {
            setIsLoading(true);
            dispatch(fetchStorageGroupBookingCalendar(booking.storageGroup.id, booking.id))
                .then(handleResponse(
                    response => {
                        setBookingCalendar(response.payload);
                        setIsLoading(false);
                    },
                    () => {
                        setLoadingError(true);
                        setIsLoading(false);
                    }
                ));
        }
    }, [open]);

    const bookableStorages = bookingCalendar
        ? getBookableStorages(booking.storageGroup, bookingCalendar, booking.startDate, booking.endDate)
        : undefined;
    const storageInfos = bookableStorages
        ? booking.storageGroup.storages
            .map(storage => ({
                storage,
                isBookable: bookableStorages.find(o => o.id === storage.id) !== undefined || storage.id === booking.storage.id
            }))
        : undefined;

    const handleFormSubmit = values => {
        onOk(values);
    };

    const formatStorageInfo = storageInfo => storageInfo.isBookable
        ? storageInfo.storage.title
        : <span className={classes.notAvailable}>{storageInfo.storage.title} - {strings.notAvailable}</span>;

    return (
        <Dialog fullWidth maxWidth="md" open={open} onClose={onCancel}>
            <Form
                initialValues={initialValues}
                onSubmit={handleFormSubmit}
            >
                {({ handleSubmit }) => (
                    <form onSubmit={handleSubmit}>
                        <DialogTitle disableTypography>
                            <Typography variant="h5">
                                {strings.changeStorageDialogTitle}
                            </Typography>
                        </DialogTitle>
                        <DialogContent>
                            <Typography variant="body1" gutterBottom>
                                {strings.changeStorageDialogContent}
                            </Typography>
                            { isLoading && <Loader /> }
                            { loadingError && <LoadingError /> }
                            {
                                storageInfos &&
                                (
                                    <TextField
                                        select
                                        name="storageId"
                                        label={strings.selectStorageLabel}
                                        variant="outlined"
                                        autoFocus
                                    >
                                        {
                                            storageInfos.map(storageInfo => (
                                                <MenuItem key={storageInfo.storage.id} value={storageInfo.storage.id}>
                                                    {formatStorageInfo(storageInfo)}
                                                </MenuItem>
                                            ))
                                        }
                                    </TextField>
                                )
                            }
                        </DialogContent>
                        <DialogActions>
                            <Button
                                type="submit"
                                variant="contained"
                                color="primary"
                                disabled={isLoading || loadingError}
                            >
                                {strings.save}
                            </Button>
                            <Button
                                variant="outlined"
                                color="primary"
                                onClick={onCancel}
                            >
                                {strings.cancel}
                            </Button>
                        </DialogActions>
                    </form>
                )}
            </Form>
        </Dialog>
    );
};

ChangeStorageDialog.propTypes = {
    booking: PropTypes.object.isRequired,
    open: PropTypes.bool.isRequired,
    onOk: PropTypes.func.isRequired,
    onCancel: PropTypes.func.isRequired
};

export default ChangeStorageDialog;
