import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { makeStyles } from 'styles/util';
import { handleResponse } from 'actions/actionHelpers';
import { getTakeover, convertTakeoverTenantFile, updateTakeover } from 'actions/admin/takeovers';
import routes from 'routes';
import arrayMutators from 'final-form-arrays';
import { changeValueMutator } from 'helpers/FormHelper';
import { enqueueAdminSnackbarMessage } from 'actions/admin/general';
import { getBusinessOrPrivateName } from 'helpers/ActorHelper';

import AdminPage from '../../presentational/AdminPage';
import AdminFormWrapper from '../../presentational/AdminFormWrapper';
import AdminFormFields from '../../presentational/AdminFormFields';
import ReadOnlyField from '../../presentational/ReadOnlyField';
import AdminFormButtons from '../../presentational/AdminFormButtons';
import Form from 'form/Form';
import Button from '@material-ui/core/Button';
import Dropzone from 'react-dropzone';
import { FieldArray } from 'react-final-form-arrays';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import Separator from 'common/Separator';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Loader from 'common/Loader';
import Amount from 'common/Amount';
import { Link } from 'react-router-dom';

const useStyles = makeStyles(({ theme, colors }) => ({
    dropzone: {
        border: `1px solid ${colors.primaryColor}`,
        borderRadius: '4px',
        padding: theme.spacing(2),
        cursor: 'pointer',
        marginTop: theme.spacing(2)
    }
}));

const ImportTenants = () => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const params = useParams();
    const history = useHistory();

    const [takeover, setTakeover] = useState(undefined);
    const [isLoading, setIsLoading] = useState(true);
    const [isConverting, setIsConverting] = useState(false);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [conversionCompleted, setConversionCompleted] = useState(false);
    const [initialValues, setInitialValues] = useState(undefined);

    useEffect(() => {
        dispatch(getTakeover(params.takeoverId))
            .then(handleResponse(
                response => {
                    setTakeover(response.payload);
                    setInitialValues({
                        tenants: response.payload.tenants
                    });
                    setIsLoading(false);
                },
                () => setIsLoading(false)
            ));
    }, []);

    const handleDrop = (form, fields, acceptedFiles) => {
        if(acceptedFiles.length === 0) {
            dispatch(enqueueAdminSnackbarMessage('Invalid file format.', { variant: 'error' }));
        } else {
            setIsConverting(true);
            dispatch(convertTakeoverTenantFile(takeover.id, acceptedFiles[0]))
            .then(handleResponse(
                response => {
                    setIsConverting(false);
                    setConversionCompleted(true);
                    form.mutators.changeValue({ name: 'tenants', value: [] });
                    response.payload.takeoverTenants.forEach(tenant => {
                        fields.push(tenant);
                    });
                },
                () => {
                    setIsConverting(false);
                }
            ));
        }
    };

    const handleFormSubmit = formValues => {
        setIsSubmitting(true);
        const request = {
            ...takeover,
            tenants: formValues.tenants
        };
        dispatch(updateTakeover(params.takeoverId, request))
            .then(handleResponse(
                () => {
                    history.push(routes.admin.takeover.replace(':takeoverId', params.takeoverId));
                    return { snackbar: { message: 'Tenants were successfully imported.' } };
                },
                () => setIsSubmitting(false)
            ));
    };

    const title = 'Import tenants: ' + (takeover ? takeover.storageSite.title : 'Loading...');

    return (
        <AdminPage title={title}>
            {
                isLoading && <Loader />
            }
            {
                initialValues &&
                (
                    <AdminFormWrapper isSubmitting={isSubmitting}>
                        <Form
                            initialValues={initialValues}
                            mutators={{
                                ...arrayMutators,
                                changeValue: changeValueMutator
                            }}
                            onSubmit={handleFormSubmit}
                        >
                            {({ form, values, handleSubmit }) => (
                                <form onSubmit={handleSubmit}>
                                    <AdminFormFields>
                                        <FieldArray name="tenants">
                                            {({ fields }) => (
                                                <>
                                                    <Dropzone
                                                        accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                                                        maxSize={16000000}
                                                        onDrop={acceptedFiles => handleDrop(form, fields, acceptedFiles)}
                                                    >
                                                        {({ getRootProps, getInputProps }) => (
                                                            <Box {...getRootProps()} className={classes.dropzone}>
                                                                <input {...getInputProps()} />
                                                                <Typography variant="body1">
                                                                    Click to upload Excel file with tenants
                                                                </Typography>
                                                            </Box>
                                                        )}
                                                    </Dropzone>

                                                    {
                                                        isConverting && <Loader />
                                                    }

                                                    {
                                                        !isConverting && values.tenants.length > 0 &&
                                                        (
                                                            <>
                                                                <Separator spacing={2} />

                                                                <ReadOnlyField label="Tenants">
                                                                    <Table size="small">
                                                                        <TableHead>
                                                                            <TableRow>
                                                                                <TableCell>Identifier</TableCell>
                                                                                <TableCell>Name</TableCell>
                                                                                <TableCell>Storage</TableCell>
                                                                                <TableCell align="right">Price excl VAT</TableCell>
                                                                                <TableCell align="right">Price incl VAT</TableCell>
                                                                            </TableRow>
                                                                        </TableHead>
                                                                        <TableBody>
                                                                            {
                                                                                values.tenants.map((tenant, index) => (
                                                                                    <TableRow key={index}>
                                                                                        <TableCell>{tenant.externalIdentifier}</TableCell>
                                                                                        <TableCell>{getBusinessOrPrivateName(tenant)}</TableCell>
                                                                                        <TableCell>{tenant.storageTitle}</TableCell>
                                                                                        <TableCell align="right"><Amount value={tenant.subscriptionPeriodPrice} currency={tenant.currency}/></TableCell>
                                                                                        <TableCell align="right"><Amount value={tenant.subscriptionPeriodPrice + tenant.subscriptionPeriodVat} currency={tenant.currency}/></TableCell>
                                                                                    </TableRow>
                                                                                ))
                                                                            }
                                                                        </TableBody>
                                                                    </Table>
                                                                </ReadOnlyField>
                                                            </>
                                                        )
                                                    }
                                                </>
                                                )
                                            }
                                        </FieldArray>
                                    </AdminFormFields>

                                    <AdminFormButtons>
                                        <Button
                                            type="submit"
                                            variant="contained"
                                            color="primary"
                                            disabled={!conversionCompleted}
                                        >
                                            Import
                                        </Button>
                                        <Button
                                            type="button"
                                            variant="outlined"
                                            color="primary"
                                            component={Link}
                                            to={routes.admin.takeover.replace(':takeoverId', params.takeoverId)}
                                        >
                                            Cancel
                                        </Button>
                                    </AdminFormButtons>
                                </form>
                            )}
                        </Form>
                    </AdminFormWrapper>
                )
            }
        </AdminPage>
    );
};

export default ImportTenants;
