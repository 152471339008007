import { RSAA } from 'redux-api-middleware';
import { createRsaaTypes, createHeaders } from './actionHelpers';

export const GEOGRAPHICALLOCATIONS_LIST = 'GEOGRAPHICALLOCATIONS_LIST';

export const fetchGeographicalLocations = () => ({
    [RSAA]: {
        types: createRsaaTypes(GEOGRAPHICALLOCATIONS_LIST),
        endpoint: '/api/geographicalLocations',
        headers: createHeaders(false),
        method: 'GET'
    }
});
