import React from 'react';
import PropTypes from 'prop-types';
import strings from 'localization/strings';
import { useField } from 'react-final-form';

import Typography from '@material-ui/core/Typography';
import { TextField } from 'mui-rff';
import AddressEditor from 'form/AddressEditor';

const BillectaPaymentElement = ({ name }) => {
    const getName = suffix => suffix ? `${name}.${suffix}` : name;
    const getValue = suffix => useField(getName(suffix)).input.value;

    if(!getValue('collectAddress')) {
        return null;
    }

    return (
        <>
            <Typography variant="h6" gutterBottom>
                {strings.invoiceDetails}
            </Typography>

            <TextField
                name={getName('name')}
                label={strings.name}
                variant="outlined"
                disabled
            />

            <TextField
                name={getName('email')}
                label={strings.email}
                variant="outlined"
                disabled
            />

            <AddressEditor
                name={getName('address')}
            />
        </>
    );
};

BillectaPaymentElement.propTypes = {
    name: PropTypes.string.isRequired
};

export default BillectaPaymentElement;
