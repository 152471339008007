import { RSAA } from 'redux-api-middleware';
import { createRsaaTypes, createHeaders } from './actionHelpers';
import queryString from 'query-string';

export const MEDIA_UPLOAD = 'MEDIA_UPLOAD';
export const MEDIA_CROPIMAGE = 'MEDIA_CROPIMAGE';

export const uploadMedia = (mediaFile, type) => {
    const data = new FormData();
    data.append('file', mediaFile);

    const parameters = {
        type
    };

    return {
        [RSAA]: {
            types: createRsaaTypes(MEDIA_UPLOAD),
            endpoint: `/api/media?${queryString.stringify(parameters)}`,
            headers: createHeaders(false),
            body: data,
            method: 'POST'
        }
    };
};

export const cropImage = cropImageRequest => {
    return {
        [RSAA]: {
            types: createRsaaTypes(MEDIA_CROPIMAGE),
            endpoint: '/api/media/cropImage',
            headers: createHeaders(true),
            method: 'POST',
            body: JSON.stringify(cropImageRequest)
        }
    };
};
