export const getFormValuesAtLoginRedirect = (state) => {
    const values = state.bookingRequest.formValuesAtLoginRedirect;
    if (values?.requestedStartDate) {
        values.requestedStartDate = typeof values.requestedStartDate === 'string'
            ? new Date(values.requestedStartDate) : values.requestedStartDate;
    }
    if (values?.requestedEndDate) {
        values.requestedEndDate = typeof values.requestedEndDate === 'string'
            ? new Date(values.requestedEndDate) : values.requestedEndDate;
    }

    return values;
};

export const getFormValues = (state) => state.bookingRequest.formValues;
