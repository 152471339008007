import React, { useState } from 'react';
import { useAppContext } from 'context/AppContext';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import { logIn } from 'actions/authentication';
import { resetPassword } from 'actions/users';
import { email, required } from 'form/validation';
import { handleResponse } from 'actions/actionHelpers';
import strings from 'localization/strings';
import { makeStyles } from 'styles/util';
import { useScrollToTop } from 'helpers/BrowserHelper';
import userAuthenticationTypes from 'enums/userAuthenticationTypes';
import { createObjectFromAuthenticationQuerystringParameters, handleSuccessfulLogIn } from 'logic/authenticationLogic';

import PageTitle from 'common/PageTitle';
import MuiContainer from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';
import ContentBox from 'common/ContentBox';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Form from 'form/Form';
import { TextField, showErrorOnBlur } from 'mui-rff';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import LinkButton from 'common/LinkButton';
import CircularProgressButton from 'common/CircularProgressButton';

const useStyles = makeStyles(({ theme }) => ({
    pageWrapper: {
        display: 'flex',
        flexDirection: 'column',
        gap: theme.spacing(3.75),
        marginTop: theme.spacing(10),
        marginBottom: theme.spacing(3.75),
        [theme.breakpoints.down('sm')]: {
            marginTop: theme.spacing(3.75)
        }
    },
    form: {
        display: 'flex',
        flexDirection: 'column',
        gap: theme.spacing(3.75),
    },
    iHaveForgottenMyPasswordLink: {
        alignSelf: 'center'
    }
}));

const UsernameAndPasswordLoginPage = () => {
    const classes = useStyles();
    const history = useHistory();
    const location = useLocation();
    const dispatch = useDispatch();
    const { appContext } = useAppContext();
    const [forgottenPasswordDialogOpen, setForgottenPasswordDialogOpen] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [forgottenPasswordInitialValues, setForgottenPasswordInitialValues] = useState({ email: null });
    const { takeoverTenant, userInvitation } = createObjectFromAuthenticationQuerystringParameters(location);

    const title = strings.login;

    const redirectUrl = useSelector(state => state.authentication.redirectUrl);

    const usernameAndPasswordAllowed = !!appContext.authentication.allowedUserAuthenticationTypes.find(o => o === userAuthenticationTypes.usernameAndPassword.key);

    const initialValues = {
        email: null,
        password: null
    };

    useScrollToTop();

    const handleFormSubmit = values => {
        setIsLoading(true);
        const logInRequest = {
            username: values.email,
            password: values.password
        };
        dispatch(logIn(logInRequest))
            .then(handleResponse(
                () => handleSuccessfulLogIn({
                    takeoverTenant,
                    userInvitation,
                    redirectUrl,
                    setIsLoading,
                    dispatch,
                    history
                }),
                response => {
                    const isUnauthorized = response.payload?.status === 401;
                    setIsLoading(false);
                    return {
                        dialog: {
                            title: strings.loginFailed,
                            body: isUnauthorized
                                ? strings.wrongEmailOrPassword
                                : undefined,
                            suppressServerErrorMessage: isUnauthorized
                        }
                    };
                }
            ));
    };

    const handleForgottenPasswordDialogOpen = (values) => {
        setForgottenPasswordInitialValues({ email: values.email });
        setForgottenPasswordDialogOpen(true);
    };

    const handleForgottenPasswordFormSubmit = values => {
        setIsLoading(true);
        setForgottenPasswordDialogOpen(false);
        dispatch(resetPassword(values.email))
            .then(handleResponse(
                () => {
                    setIsLoading(false);
                    return { dialog: { title: strings.newPasswordSentSuccessTitle, body: strings.newPasswordSentSuccessBody } };
                },
                () => {
                    setIsLoading(false);
                    return { dialog: { title: strings.newPasswordSentFailureTitle, body: strings.newPasswordSentFailureBody, suppressServerErrorMessage: true } };
                }
            ));
    };

    const handleForgottenPasswordDialogClose = () => {
        setForgottenPasswordDialogOpen(false);
    };

    if(!usernameAndPasswordAllowed) {
        return null;
    }

    return (
        <>
            <PageTitle>{title}</PageTitle>
            <MuiContainer maxWidth={false}>
                <ContentBox className={classes.pageWrapper} themeMaxWidth="xs">
                    <Typography variant="h2">
                        {title}
                    </Typography>

                    <Form
                        initialValues={initialValues}
                        onSubmit={handleFormSubmit}
                    >
                        {({ values, handleSubmit, invalid }) => (
                            <form className={classes.form} onSubmit={handleSubmit}>
                                <Box>
                                    <TextField
                                        name="email"
                                        label={strings.email}
                                        type="email"
                                        variant="outlined"
                                        showError={showErrorOnBlur}
                                        fieldProps={{ validate: email }}
                                    />
                                    <TextField
                                        name="password"
                                        label={strings.password}
                                        type="password"
                                        variant="outlined"
                                        showError={showErrorOnBlur}
                                        fieldProps={{ validate: required }}
                                    />
                                </Box>

                                <CircularProgressButton
                                    type="submit"
                                    className={classes.submitButton}
                                    disabled={invalid}
                                    isLoading={isLoading}
                                >
                                    {strings.login}
                                </CircularProgressButton>

                                <LinkButton
                                    className={classes.iHaveForgottenMyPasswordLink}
                                    type="button"
                                    onClick={() => handleForgottenPasswordDialogOpen(values)}
                                >
                                    {strings.iHaveForgottenMyPassword}
                                </LinkButton>
                            </form>
                        )}
                    </Form>

                    <Dialog open={forgottenPasswordDialogOpen} onClose={handleForgottenPasswordDialogClose}>
                        <Form
                            initialValues={forgottenPasswordInitialValues}
                            onSubmit={handleForgottenPasswordFormSubmit}
                        >
                            {({ handleSubmit, invalid }) => (
                                <form onSubmit={handleSubmit}>
                                    <DialogTitle disableTypography>
                                        <Typography variant="h5">
                                            {strings.forgottenPasswordDialogTitle}
                                        </Typography>
                                    </DialogTitle>
                                    <DialogContent>
                                        <Typography variant="body1" gutterBottom>
                                            {strings.forgottenPasswordDialogBody}
                                        </Typography>
                                        <TextField
                                            name="email"
                                            label={strings.email}
                                            type="email"
                                            variant="outlined"
                                            showError={showErrorOnBlur}
                                            fieldProps={{ validate: email }}
                                        />
                                    </DialogContent>
                                    <DialogActions>
                                        <Button
                                            type="submit"
                                            variant="contained"
                                            color="primary"
                                            disabled={invalid}
                                        >
                                            {strings.ok}
                                        </Button>
                                        <Button
                                            variant="outlined"
                                            color="primary"
                                            onClick={handleForgottenPasswordDialogClose}
                                        >
                                            {strings.cancel}
                                        </Button>
                                    </DialogActions>
                                </form>
                            )}
                        </Form>
                    </Dialog>
                </ContentBox>
            </MuiContainer>
        </>
    );
};

export default UsernameAndPasswordLoginPage;
