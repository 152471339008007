import strings from 'localization/strings';

const userAuthenticationTypes = {
    eid: {},
    usernameAndPassword: {}
};

Object.keys(userAuthenticationTypes).forEach(key => {
    userAuthenticationTypes[key].key = key;
});

strings.addLoadCallback(() => {
    Object.values(userAuthenticationTypes).forEach(o => {
        o.title = strings.userAuthenticationTypes[o.key];
    });
});

export default userAuthenticationTypes;
