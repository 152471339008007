import React, { useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useHistory } from 'react-router-dom';
import { performSearch, mergeSearchParameters } from 'components/adminTable/AdminTableHelper';

import AdminPage from '../../presentational/AdminPage';
import AdminContentWrapper from '../../presentational/AdminContentWrapper';
import AdminTable from 'components/adminTable/AdminTable';
import Box from '@material-ui/core/Box';
import SearchStatisticsForm from './SearchStatisticsForm';
import {getSearchStatistics, setSearchParameters} from 'actions/admin/searchStatistics';
import Loader from 'common/Loader';
import { formatIsoDate } from 'helpers/DateHelper';
import { add } from 'date-fns';

const SearchStatistics = () => {
    const dispatch = useDispatch();
    const location = useLocation();
    const history = useHistory();

    const searchResult = useSelector(state => state.admin.searchStatistics.searchResult);
    const searchParametersFromState = useSelector(state => state.admin.searchStatistics.searchParameters);

    const defaultParameters = {
        category: 'all',
        from: formatIsoDate(add(add(new Date(), { months: -1 }), { days: 1 })),
        to: formatIsoDate(new Date())
    };

    const columns = [
        {
            title: 'Name',
            id: 'name',
            render: searchStatistic => searchStatistic.name
        },
        {
            title: 'Count',
            id: 'count',
            render: searchStatistic => searchStatistic.count,
            align: 'right'
        }
    ];

    const search = (parameters) => {
        const sanitizedParameters = { ...parameters };
        if(parameters.from) {
            sanitizedParameters.from = formatIsoDate(parameters.from);
        }
        if(parameters.to) {
            sanitizedParameters.to = formatIsoDate(parameters.to);
        }
        const searchParameters = mergeSearchParameters(sanitizedParameters, location, searchParametersFromState, defaultParameters);
        performSearch(performSearchObject, searchParameters);
    };

    const getParametersForApiCall = parameters => ({
        ...parameters,
        category: parameters.category === 'all'
            ? undefined
            : parameters.category
    });

    const performSearchObject = {
        searchParametersFromState,
        setSearchParametersToState: parameters => dispatch(setSearchParameters(parameters)),
        searchResult,
        search,
        apiCall: parameters => dispatch(getSearchStatistics(getParametersForApiCall(parameters))),
        location,
        history,
        isInitializedRef: useRef()
    };

    useEffect(() => {
        const searchParameters = mergeSearchParameters({}, location, searchParametersFromState, defaultParameters);
        performSearch(performSearchObject, searchParameters);
    }, []);

    const handleSearchFormSubmit = (formValues) => {
        search({ ...defaultParameters, ...formValues });
    };

    return (
        <AdminPage title="Search statistics">

            <AdminContentWrapper type="listing">

                <SearchStatisticsForm
                    searchParameters={searchParametersFromState}
                    onSubmit={handleSearchFormSubmit}
                />

                <Box>
                    {
                        searchResult.isLoading && <Loader />
                    }
                    {
                        searchResult.items && (
                            <AdminTable
                                isPaged={false}
                                items={searchResult.items}
                                columns={columns}
                                sortByColumn={columns.find(c => c.id === searchParametersFromState.sortByColumn)}
                                sortDirection={searchParametersFromState.sortDirection}
                            />
                        )
                    }
                </Box>

            </AdminContentWrapper>
        </AdminPage>
    );
};

export default SearchStatistics;
