import React from 'react';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import { makeStyles } from 'styles/util';
import KeyboardArrowDown from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUp from '@material-ui/icons/KeyboardArrowUp';
import Badge from '@material-ui/core/Badge';

const useStyles = makeStyles(({ theme, colors, fonts }) => ({
    filterButton: {
        backgroundColor: colors.white,
        padding: theme.spacing(1, 2.5),
        '& .MuiButton-label': {
             fontFamily: fonts.regular
        },
        '& .MuiSvgIcon-root': {
            fontSize: theme.spacing(2.875),
        },
        '&:hover': {
            color: colors.white,
            backgroundColor: colors.lightButtonHoverColor
        }
    }
}));

const FilterButton = ({ show = true, filtersOpen, label, onClick, badgeCount }) => {
    const classes = useStyles();
    return show && (
        <Badge badgeContent={badgeCount} invisible={badgeCount === 0} color="primary">
            <Button
                aria-describedby="simple-popover"
                className={classes.filterButton}
                type="submit"
                endIcon={filtersOpen ? <KeyboardArrowUp style={{ fontSize: 22 }} /> : <KeyboardArrowDown style={{ fontSize: '22px' }} /> }
                onClick={onClick}
            >
                {label}
            </Button>
        </Badge>
    );
};

FilterButton.propTypes = {
    show: PropTypes.bool,
    filtersOpen: PropTypes.bool,
    label: PropTypes.string,
    onClick: PropTypes.func,
    badgeCount: PropTypes.number
};

export default FilterButton;
