import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from 'styles/util';
import routes from 'routes';

import { Link } from 'react-router-dom';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import ReadOnlyField from '../../presentational/ReadOnlyField';
import Amount from 'common/Amount';
import ShortDate from 'common/ShortDate';
import ShortDateTime from 'common/ShortDateTime';

const useStyles = makeStyles(() => ({
    nowrap: {
        whiteSpace: 'nowrap'
    }
}));

const PayoutList = ({ payouts }) => {
    const classes = useStyles();

    if(payouts.length === 0) {
        return null;
    }

    return (
        <ReadOnlyField label="Payouts">
            <Table size="small">
                <TableHead>
                    <TableRow>
                        <TableCell align="right">Payout ID</TableCell>
                        <TableCell>Receiver</TableCell>
                        <TableCell align="right">Amount</TableCell>
                        <TableCell>Status</TableCell>
                        <TableCell>Created time</TableCell>
                        <TableCell>Modified time</TableCell>
                        <TableCell>Arrival date</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {
                        payouts.map(payout => (
                            <TableRow key={payout.id}>
                                <TableCell align="right"><Link to={routes.admin.payout.replace(':payoutId', payout.id)}>{payout.id}</Link></TableCell>
                                <TableCell>
                                    {
                                        payout.ownerActorId
                                            ? <Link to={routes.admin.actor.replace(':actorId', payout.ownerActorId)}>{payout.ownerName}</Link>
                                            : 'Storage365'
                                    }
                                </TableCell>
                                <TableCell align="right" className={classes.nowrap}><Amount value={payout.amount} currency={payout.currency}/></TableCell>
                                <TableCell>{payout.status}</TableCell>
                                <TableCell><ShortDateTime value={payout.createdTime}/></TableCell>
                                <TableCell><ShortDateTime value={payout.modifiedTime}/></TableCell>
                                <TableCell><ShortDate value={payout.arrivalDate}/></TableCell>
                            </TableRow>
                        ))
                    }
                </TableBody>
            </Table>
        </ReadOnlyField>
    );
};

PayoutList.propTypes = {
    payouts: PropTypes.array.isRequired
};

export default PayoutList;
