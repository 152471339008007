import React from 'react';
import strings from 'localization/strings';

import Typography from '@material-ui/core/Typography';
import {Popover} from '@material-ui/core';
import { makeStyles } from 'styles/util';
import PropTypes from 'prop-types';
import Box from '@material-ui/core/Box';

const VehicleInfoText = ({ anchorEl, open, onClose }) => {
    const useStyles = makeStyles(({ theme }) => ({
        innerContainer: {
            padding: theme.spacing(1)
        },
        popoverContainer: {
            '& .MuiPopover-paper': {
                width: `${anchorEl?.parentNode?.offsetWidth}px`
            }
        }
    }));
    const classes = useStyles();

    return (
        <Popover
            anchorEl={anchorEl}
            aria-labelledby="simple-modal-title"
            aria-describedby="simple-modal-description"
            open={open}
            onClose={() => onClose()}
            anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'right',
            }}
            transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
            }}
            className={classes.popoverContainer}
        >
            <Box className={classes.innerContainer}>
                <Typography variant="body1" gutterBottom>
                    {strings.vehicleInfoTextLine1}
                </Typography>

                <Typography variant="body1">
                    <a href="https://fu-regnr.transportstyrelsen.se/extweb/UppgifterAnnatFordon" target="_blank">{strings.vehicleInfoTextLine2}</a> ({strings.linkOpenedInNewTab})
                </Typography>
            </Box>
        </Popover>
    );
};

VehicleInfoText.propTypes = {
    anchorEl: PropTypes.object,
    open: PropTypes.bool,
    onClose: PropTypes.func
};

export default VehicleInfoText;
