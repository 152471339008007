import React from 'react';
import PropTypes from 'prop-types';
import strings from 'localization/strings';
import { makeStyles } from 'styles/util';
import { required } from 'form/validation';
import { useForm, useField } from 'react-final-form';

import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import { TextField, showErrorOnBlur } from 'mui-rff';
import CircularProgressButton from 'common/CircularProgressButton';

const useStyles = makeStyles(({ theme }) => ({
    container: {
        display: 'flex',
        flexDirection: 'column',
        gap: theme.spacing(2.25)
    }
}));

const OrganizationNumberForm = ({ name, onSubmit, isLoading }) => {
    const classes = useStyles();
    const value = useField(name).input.value;
    const formState = useForm().getState();

    const handleSubmit = () => {
        onSubmit(value);
    };

    return (
        <Box className={classes.container}>
            <Typography variant="body1">
                {strings.authentication.createBusinessActorOrganizationNumber}
            </Typography>
            <TextField
                name={name}
                label={strings.organizationNumber}
                variant="outlined"
                showError={showErrorOnBlur}
                required
                fieldProps={{ validate: required }}
            />
            <CircularProgressButton
                disabled={formState.invalid}
                isLoading={isLoading}
                onClick={handleSubmit}
            >
                {strings.next}
            </CircularProgressButton>
        </Box>
    );
};

OrganizationNumberForm.propTypes = {
    name: PropTypes.string.isRequired,
    onSubmit: PropTypes.func.isRequired,
    isLoading: PropTypes.bool
};

export default OrganizationNumberForm;
