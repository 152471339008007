import { RSAA } from 'redux-api-middleware';
import { createRsaaTypes, createHeaders } from './actionHelpers';
import queryString from 'query-string';

export const USERS_CREATE = 'USERS_CREATE';

export const USERS_UPDATE = 'USERS_UPDATE';

export const USERS_UPDATE_COMBINED_USER_ACTOR = 'USERS_UPDATE_COMBINED_USER_ACTOR';

export const USERS_ACCEPT_TERMS_OF_SERVICE = 'USERS_ACCEPT_TERMS_OF_SERVICE';
export const USERS_ACCEPT_TERMS_OF_SERVICE_SUCCESS = 'USERS_ACCEPT_TERMS_OF_SERVICE_SUCCESS';

export const USERS_RESET_PASSWORD = 'USERS_RESET_PASSWORD';

export const USERS_CREATE_PRIVATE_ACTOR = 'USERS_CREATE_PRIVATE_ACTOR';

export const USERS_FETCH_AUTHENTICATION_INFO = 'USERS_FETCH_AUTHENTICATION_INFO';

export const createUser = createUserRequest => {
    return {
        [RSAA]: {
            types: createRsaaTypes(USERS_CREATE),
            endpoint: '/api/users',
            headers: createHeaders(true),
            body: JSON.stringify(createUserRequest),
            method: 'POST'
        }
    };
};

export const updateUser = (userId, updateUserRequest) => ({
    [RSAA]: {
        types: createRsaaTypes(USERS_UPDATE),
        endpoint: `/api/users/${userId}`,
        headers: createHeaders(true),
        body: JSON.stringify(updateUserRequest),
        method: 'PUT'
    }
});

export const updateCombinedUserActor = (userId, updateCombinedUserActorRequest) => ({
    [RSAA]: {
        types: createRsaaTypes(USERS_UPDATE_COMBINED_USER_ACTOR),
        endpoint: `/api/users/${userId}/combinedUserActor`,
        headers: createHeaders(true),
        body: JSON.stringify(updateCombinedUserActorRequest),
        method: 'PUT'
    }
});

export const acceptTermsOfService = userId => {
    return {
        [RSAA]: {
            types: createRsaaTypes(USERS_ACCEPT_TERMS_OF_SERVICE),
            endpoint: `/api/users/${userId}/acceptTermsOfService`,
            headers: createHeaders(false),
            method: 'POST'
        }
    };
};

export const resetPassword = email => {
    return {
        [RSAA]: {
            types: createRsaaTypes(USERS_RESET_PASSWORD),
            endpoint: '/api/users/password/reset',
            headers: createHeaders(true),
            method: 'PUT',
            body: JSON.stringify({ email })
        }
    };
};

export const createPrivateActorBasedOnUser = (userId, createPrivateActorRequest) => {
    return {
        [RSAA]: {
            types: createRsaaTypes(USERS_CREATE_PRIVATE_ACTOR),
            endpoint: `/api/users/${userId}/actors`,
            headers: createHeaders(true),
            method: 'POST',
            body: JSON.stringify(createPrivateActorRequest)
        }
    };
};

export const fetchUserAuthenticationInfo = email => {
    return {
        [RSAA]: {
            types: createRsaaTypes(USERS_FETCH_AUTHENTICATION_INFO),
            endpoint: `/api/users/authenticationInfos?${queryString.stringify({ email })}`,
            headers: createHeaders(false),
            method: 'GET'
        }
    };
};
