import React from 'react';
import { useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';
import { makeStyles } from 'styles/util';
import strings from 'localization/strings';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import queryString from 'query-string';

import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import Alert from '@material-ui/lab/Alert';
import ButtonContainer from 'common/ButtonContainer';

const useStyles = makeStyles(({ theme }) => ({
    paragraph: {
        marginBottom: theme.spacing(2)
    }
}));

const StripeAccountInfo = ({ stripeAccountInfo, onCreateStripeAccountLink }) => {
    const classes = useStyles();
    const isMobile = !useMediaQuery(theme => theme.breakpoints.up('md'));
    const location = useLocation();

    let stripeAccountLinkType = 'update';
    let stripeAccountNeedsUpdate = undefined;
    if(stripeAccountInfo && stripeAccountInfo.requirements && (stripeAccountInfo.requirements.currentlyDue.length > 0 || stripeAccountInfo.requirements.pastDue.length > 0)) {
        stripeAccountLinkType = 'verification';
        stripeAccountNeedsUpdate = <Alert severity="error" className={classes.paragraph}>{strings.additionalAccountInfoRequired}</Alert>;
    }

    const getStripeCallbackResult = () => {
        const searchParams = queryString.parse(location.search);
        const response = searchParams.stripeConnectOnboardingResponse;
        let stripeMessage = '';

        if(response === 'success') {
            stripeMessage = <Alert severity="success" className={classes.paragraph}>{strings.accountInfoUpdateSucceeded}</Alert>;
        } else if(response === 'failure') {
            stripeMessage = <Alert severity="error" className={classes.paragraph}>{strings.accountInfoUpdateFailed}</Alert>;
        }

        return stripeMessage;
    };

    return (
        <>
            <Typography variant="h5" className={classes.paragraph}>
                {strings.accountInfoTitle}
            </Typography>
            <Typography variant="body1" className={classes.paragraph}>
                {strings.accountInfoBody}
            </Typography>
            {stripeAccountNeedsUpdate}
            {getStripeCallbackResult()}

            <ButtonContainer>
                <Button
                    color="primary"
                    variant="contained"
                    onClick={() => onCreateStripeAccountLink(stripeAccountLinkType)}
                    fullWidth={isMobile}
                >
                    {strings.updateDetails}
                </Button>
            </ButtonContainer>
        </>
    );
};

StripeAccountInfo.propTypes = {
    stripeAccountInfo: PropTypes.object,
    onCreateStripeAccountLink: PropTypes.func.isRequired
};

export default StripeAccountInfo;
