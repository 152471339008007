import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useAppContext } from 'context/AppContext';
import { emailOptional, mustBeNumber } from 'form/validation';
import { createEndAdornment } from 'helpers/FormHelper';
import { formatNumber, formatAmount, convertToNumber } from 'helpers/StringHelper';
import { calculateVat } from 'helpers/MonetaryHelper';
import { getShortDateFormat } from 'helpers/DateHelper';
import takeoverTenantStatuses from 'enums/takeoverTenantStatuses';
import routes from 'routes';
import { makeStyles } from 'styles/util';
import goodsTypes from 'enums/goodsTypes';

import Form from 'form/Form';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import { TextField, showErrorOnBlur } from 'mui-rff';
import ReadOnlyField from '../../presentational/ReadOnlyField';
import MenuItem from '@material-ui/core/MenuItem';
import LoadingWrapper from 'form/LoadingWrapper';
import NullableDatePicker from 'form/NullableDatePicker';
import AddressEditor from 'form/AddressEditor';

const useStyles = makeStyles(() => ({
    takeoverLink: {
        wordBreak: 'break-word'
    }
}));

const formatAmountOptions = { skipCurrencySuffix: true, skipThousandSeparators: true };
const formatNumberOptions = { skipThousandSeparators: true, sanitizeMinusSign: true };

const EditTenantDialog = ({ tenant, takeover, open, isSubmitting, onOk, onDelete, onCancel }) => {
    const classes = useStyles();
    const [initialValues, setInitialValues] = useState({});

    const { appContext } = useAppContext();

    useEffect(() => {
        if(tenant) {
            setInitialValues({
                ...tenant,
                subscriptionPeriodPrice: formatAmount(tenant.subscriptionPeriodPrice, tenant.currency, appContext, formatAmountOptions),
                vatRate: formatNumber(100 * tenant.vatRate, appContext, formatNumberOptions)
            });
        }
    }, [tenant]);

    const handleFormSubmit = values => {
        const updatedTenant = {
            ...values,
            subscriptionPeriodPrice: convertToNumber(values.subscriptionPeriodPrice),
            vatRate: convertToNumber(values.vatRate) / 100
        };
        updatedTenant.subscriptionPeriodVat = calculateVat(updatedTenant.subscriptionPeriodPrice, updatedTenant.vatRate, appContext);
        onOk(updatedTenant);
    };

    const storageTitles = [];
    takeover.storageSite.storageGroups.forEach(storageGroup => {
        storageGroup.storages.forEach(storage => {
            storageTitles.push(storage.title);
        });
    });

    const showDeleteButton =
        tenant?.status === takeoverTenantStatuses.unsaved.key ||
        tenant?.status === takeoverTenantStatuses.draft.key;

    const takeoverUrl = tenant?.token
        ? appContext.siteUrl + routes.takeoverTenant.replace(':takeoverTenantId', tenant?.id).replace(':token', tenant?.token)
        : undefined;

    const goodsTypeSelectListItems = [{ title: '' }].concat(Object.values(goodsTypes));

    return (
        <Dialog open={open} onClose={onCancel}>
            <Form
                initialValues={initialValues}
                onSubmit={handleFormSubmit}
            >
                {({ handleSubmit }) => (
                    <form onSubmit={handleSubmit}>
                        <DialogTitle disableTypography>
                            <Typography variant="h5">
                                Edit tenant
                            </Typography>
                        </DialogTitle>
                        <DialogContent>
                            <LoadingWrapper isSaving={isSubmitting}>
                                <TextField
                                    name="firstName"
                                    label="First name"
                                    type="text"
                                    variant="outlined"
                                    helperText="Only to be provided when tenant is an individual."
                                />

                                <TextField
                                    name="lastName"
                                    label="Last name"
                                    type="text"
                                    variant="outlined"
                                    helperText="Only to be provided when tenant is an individual."
                                />

                                <TextField
                                    name="name"
                                    label="Company name"
                                    type="text"
                                    variant="outlined"
                                    helperText="Only to be provided when tenant is a company."
                                />

                                <TextField
                                    name="identityNumber"
                                    label="Identity number"
                                    type="text"
                                    variant="outlined"
                                    helperText="Personal identity number or organization number."
                                />

                                <TextField
                                    name="email"
                                    label="Email"
                                    type="text"
                                    variant="outlined"
                                    showError={showErrorOnBlur}
                                    fieldProps={{ validate: emailOptional }}
                                />

                                <TextField
                                    name="mobileTelephone"
                                    label="Mobile telephone number"
                                    type="text"
                                    variant="outlined"
                                />

                                <AddressEditor
                                    name="invoiceAddress"
                                    useGenericAddress
                                />

                                <TextField
                                    select
                                    name="storageTitle"
                                    label="Storage"
                                    variant="outlined"
                                >
                                    {
                                        storageTitles.map(storageTitle => (
                                            <MenuItem key={storageTitle} value={storageTitle}>
                                                {storageTitle}
                                            </MenuItem>
                                        ))
                                    }
                                </TextField>

                                <TextField
                                    name="subscriptionPeriodPrice"
                                    label="Monthly price"
                                    helperText="Price excluding VAT."
                                    type="text"
                                    variant="outlined"
                                    showError={showErrorOnBlur}
                                    required
                                    fieldProps={{ validate: mustBeNumber }}
                                    InputProps={createEndAdornment(tenant?.currency)}
                                />

                                <TextField
                                    name="vatRate"
                                    label="VAT rate"
                                    variant="outlined"
                                    type="text"
                                    showError={showErrorOnBlur}
                                    required
                                    fieldProps={{ validate: mustBeNumber }}
                                    InputProps={createEndAdornment('%')}
                                />

                                <NullableDatePicker
                                    name="paidUntilDate"
                                    label="Paid until date"
                                    formatFunc={getShortDateFormat}
                                    inputVariant="outlined"
                                    nullString={''}
                                    nullButtonLabel="Clear"
                                />

                                <TextField
                                    name="goods"
                                    label="Goods"
                                    type="text"
                                    variant="outlined"
                                />

                                <TextField
                                    select
                                    name="goodsType"
                                    label="Goods type"
                                    variant="outlined"
                                >
                                    {
                                        goodsTypeSelectListItems.map((goodsType, i) => (
                                            <MenuItem key={(goodsType.key ?? i)} value={goodsType.key}>
                                                {goodsType.title}
                                            </MenuItem>
                                        ))
                                    }
                                </TextField>

                                <TextField
                                    name="registrationNumber"
                                    label="Registration number"
                                    type="text"
                                    variant="outlined"
                                />

                                <TextField
                                    multiline
                                    name="note"
                                    label="Storage365 note"
                                    type="text"
                                    variant="outlined"
                                    rows={3}
                                />

                                <ReadOnlyField
                                    label="Status"
                                >
                                    {tenant?.status}
                                </ReadOnlyField>

                                {
                                    takeoverUrl &&
                                    (
                                        <ReadOnlyField
                                            label="Takeover link"
                                        >
                                            <a className={classes.takeoverLink} href={takeoverUrl} target="_blank">
                                                {takeoverUrl}
                                            </a>
                                        </ReadOnlyField>
                                    )
                                }
                            </LoadingWrapper>
                        </DialogContent>
                        <DialogActions>
                            <Button
                                type="submit"
                                variant="contained"
                                color="primary"
                            >
                                Ok
                            </Button>
                            {
                                showDeleteButton &&
                                (
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        onClick={onDelete}
                                    >
                                        Delete
                                    </Button>
                                )
                            }
                            <Button
                                variant="outlined"
                                color="primary"
                                onClick={onCancel}
                            >
                                Cancel
                            </Button>
                        </DialogActions>
                    </form>
                )}
            </Form>
        </Dialog>
    );
};

EditTenantDialog.propTypes = {
    tenant: PropTypes.object,
    takeover: PropTypes.object.isRequired,
    open: PropTypes.bool.isRequired,
    isSubmitting: PropTypes.bool.isRequired,
    onOk: PropTypes.func.isRequired,
    onDelete: PropTypes.func.isRequired,
    onCancel: PropTypes.func.isRequired
};

export default EditTenantDialog;
