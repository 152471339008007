import { RSAA } from 'redux-api-middleware';
import { createRsaaTypes, createHeaders, createDownloadFileTypes } from './actionHelpers';
import queryString from 'query-string';

const REPORTS_EARNINGS = 'REPORTS_EARNINGS';
const REPORTS_EARNINGS_EXCEL_FILE = 'REPORTS_EARNINGS_EXCEL_FILE';

const REPORTS_NEW_ACTORS = 'REPORTS_NEW_ACTORS';
const REPORTS_NEW_ACTORS_EXCEL_FILE = 'REPORTS_NEW_ACTORS_EXCEL_FILE';

const REPORTS_KPIS = 'REPORTS_KPIS';
const REPORTS_KPIS_EXCEL_FILE = 'REPORTS_KPIS_EXCEL_FILE';

const REPORTS_TRANSACTIONS = 'REPORTS_TRANSACTIONS';
const REPORTS_TRANSACTIONS_EXCEL_FILE = 'REPORTS_TRANSACTIONS_EXCEL_FILE';

const REPORTS_BOOKING_OVERVIEW = 'REPORTS_BOOKING_OVERVIEW';
const REPORTS_BOOKING_OVERVIEW_EXCEL_FILE = 'REPORTS_BOOKING_OVERVIEW_EXCEL_FILE';

const REPORTS_ROUND = 'REPORTS_ROUND';
const REPORTS_ROUND_EXCEL_FILE = 'REPORTS_ROUND_EXCEL_FILE';

const REPORTS_STORAGE365_PAYOUTS = 'REPORTS_STORAGE365_PAYOUTS';
const REPORTS_STORAGE365_PAYOUTS_EXCEL_FILE = 'REPORTS_STORAGE365_PAYOUTS_EXCEL_FILE';

const REPORTS_TAKEOVER_TENANTS_EXCEL_FILE = 'REPORTS_TAKEOVER_TENANTS_EXCEL_FILE';

export const getEarningsReport = parameters => ({
    [RSAA]: {
        types: createRsaaTypes(REPORTS_EARNINGS),
        endpoint: `/api/reports/earnings?${queryString.stringify(parameters)}`,
        headers: createHeaders(false),
        method: 'GET'
    }
});

export const getEarningsReportExcelFile = parameters => ({
    [RSAA]: {
        types: createDownloadFileTypes(REPORTS_EARNINGS_EXCEL_FILE),
        endpoint: `/api/reports/earnings/excel?${queryString.stringify(parameters)}`,
        headers: createHeaders(false),
        method: 'GET'
    }
});

export const getNewActorsReport = parameters => ({
    [RSAA]: {
        types: createRsaaTypes(REPORTS_NEW_ACTORS),
        endpoint: `/api/reports/newActors?${queryString.stringify(parameters)}`,
        headers: createHeaders(false),
        method: 'GET'
    }
});

export const getNewActorsReportExcelFile = parameters => ({
    [RSAA]: {
        types: createDownloadFileTypes(REPORTS_NEW_ACTORS_EXCEL_FILE),
        endpoint: `/api/reports/newActors/excel?${queryString.stringify(parameters)}`,
        headers: createHeaders(false),
        method: 'GET'
    }
});

export const getKpisReport = parameters => ({
    [RSAA]: {
        types: createRsaaTypes(REPORTS_KPIS),
        endpoint: `/api/reports/kpis?${queryString.stringify(parameters)}`,
        headers: createHeaders(false),
        method: 'GET'
    }
});

export const getKpisReportExcelFile = parameters => ({
    [RSAA]: {
        types: createDownloadFileTypes(REPORTS_KPIS_EXCEL_FILE),
        endpoint: `/api/reports/kpis/excel?${queryString.stringify(parameters)}`,
        headers: createHeaders(false),
        method: 'GET'
    }
});

export const getBookingBoundariesReport = parameters => ({
    [RSAA]: {
        types: createRsaaTypes(REPORTS_KPIS),
        endpoint: `/api/reports/bookingBoundaries?${queryString.stringify(parameters)}`,
        headers: createHeaders(false),
        method: 'GET'
    }
});

export const getBookingBoundariesReportExcelFile = parameters => ({
    [RSAA]: {
        types: createDownloadFileTypes(REPORTS_KPIS_EXCEL_FILE),
        endpoint: `/api/reports/bookingBoundaries/excel?${queryString.stringify(parameters)}`,
        headers: createHeaders(false),
        method: 'GET'
    }
});

export const getTransactionsReport = parameters => ({
    [RSAA]: {
        types: createRsaaTypes(REPORTS_TRANSACTIONS),
        endpoint: `/api/reports/transactions?${queryString.stringify(parameters)}`,
        headers: createHeaders(false),
        method: 'GET'
    }
});

export const getTransactionsReportExcelFile = parameters => ({
    [RSAA]: {
        types: createDownloadFileTypes(REPORTS_TRANSACTIONS_EXCEL_FILE),
        endpoint: `/api/reports/transactions/excel?${queryString.stringify(parameters)}`,
        headers: createHeaders(false),
        method: 'GET'
    }
});

export const getBookingOverviewReport = parameters => ({
    [RSAA]: {
        types: createRsaaTypes(REPORTS_BOOKING_OVERVIEW),
        endpoint: `/api/reports/bookingOverview?${queryString.stringify(parameters)}`,
        headers: createHeaders(false),
        method: 'GET'
    }
});

export const getBookingOverviewReportExcelFile = parameters => ({
    [RSAA]: {
        types: createDownloadFileTypes(REPORTS_BOOKING_OVERVIEW_EXCEL_FILE),
        endpoint: `/api/reports/bookingOverview/excel?${queryString.stringify(parameters)}`,
        headers: createHeaders(false),
        method: 'GET'
    }
});

export const getRoundReport = parameters => ({
    [RSAA]: {
        types: createRsaaTypes(REPORTS_ROUND),
        endpoint: `/api/reports/round?${queryString.stringify(parameters)}`,
        headers: createHeaders(false),
        method: 'GET'
    }
});

export const getRoundReportExcelFile = parameters => ({
    [RSAA]: {
        types: createDownloadFileTypes(REPORTS_ROUND_EXCEL_FILE),
        endpoint: `/api/reports/round/excel?${queryString.stringify(parameters)}`,
        headers: createHeaders(false),
        method: 'GET'
    }
});

export const getStorage365PayoutReport = parameters => ({
    [RSAA]: {
        types: createRsaaTypes(REPORTS_STORAGE365_PAYOUTS),
        endpoint: `/api/reports/storage365Payouts?${queryString.stringify(parameters)}`,
        headers: createHeaders(false),
        method: 'GET'
    }
});

export const getStorage365PayoutReportExcelFile = parameters => ({
    [RSAA]: {
        types: createDownloadFileTypes(REPORTS_STORAGE365_PAYOUTS_EXCEL_FILE),
        endpoint: `/api/reports/storage365Payouts/excel?${queryString.stringify(parameters)}`,
        headers: createHeaders(false),
        method: 'GET'
    }
});

export const getTakeoverTenantExcelFile = parameters => ({
    [RSAA]: {
        types: createDownloadFileTypes(REPORTS_TAKEOVER_TENANTS_EXCEL_FILE),
        endpoint: `/api/reports/takeoverTenants/excel?${queryString.stringify(parameters)}`,
        headers: createHeaders(false),
        method: 'GET'
    }
});
