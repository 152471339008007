import React from 'react';
import PropTypes from 'prop-types';
import { useAppContext } from 'context/AppContext';
import routes from 'routes';
import userAuthenticationTypes from 'enums/userAuthenticationTypes';
import { getBusinessOrPrivateName } from 'helpers/ActorHelper';

import AdminFormWrapper from '../../presentational/AdminFormWrapper';
import AdminFormFields from '../../presentational/AdminFormFields';
import ReadOnlyField from '../../presentational/ReadOnlyField';
import AdminFormButtons from '../../presentational/AdminFormButtons';
import Form from 'form/Form';
import Button from '@material-ui/core/Button';
import { TextField, Checkboxes } from 'mui-rff';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import { Link } from 'react-router-dom';
import ShortDateTime from 'common/ShortDateTime';

const EditUserForm = ({ user, isSubmitting = false, onSubmit, onCancel }) => {
    const { appContext } = useAppContext();

    const initialValues = {
        firstName: user.firstName,
        lastName: user.lastName,
        email: user.email,
        isSuperAdmin: (user.superAdminMarketIds ?? []).includes(appContext.id)
    };

    return (
        <AdminFormWrapper isSubmitting={isSubmitting}>
            <Form
                initialValues={initialValues}
                onSubmit={onSubmit}
            >
                {({ handleSubmit }) => (
                    <form onSubmit={handleSubmit}>
                        <AdminFormFields>

                            <ReadOnlyField label="Id">
                                {user.id}
                            </ReadOnlyField>

                            <ReadOnlyField label="Authentication type">
                                {user.authenticationType}
                            </ReadOnlyField>

                            <ReadOnlyField label="Terms of service acceptance time">
                                <ShortDateTime value={user.termsOfServiceAcceptanceTime} defaultString="-" />
                            </ReadOnlyField>

                            <TextField
                                name="firstName"
                                label="First name"
                                type="text"
                                variant="outlined"
                            />

                            <TextField
                                name="lastName"
                                label="Last name"
                                type="text"
                                variant="outlined"
                            />

                            {
                                user.personalIdentity &&
                                (
                                    <ReadOnlyField label="Personal identity">
                                        {user.personalIdentityType}: {user.personalIdentity}
                                    </ReadOnlyField>
                                )
                            }

                            <TextField
                                name="email"
                                label="Email"
                                type="email"
                                variant="outlined"
                            />

                            <Checkboxes
                                name="isSuperAdmin"
                                data={{
                                    label: 'Superadmin',
                                    value: true
                                }}
                            />

                            {
                                user.authenticationType === userAuthenticationTypes.usernameAndPassword.key &&
                                (
                                    <TextField
                                        name="newPassword"
                                        label="New password"
                                        type="password"
                                        variant="outlined"
                                    />
                                )
                            }

                            <ReadOnlyField label="Actors">
                                <List disablePadding>
                                    {
                                        user.actors.map(actor => (
                                            <ListItem key={actor.id} disableGutters>
                                                <ListItemText>
                                                    <Link to={routes.admin.actor.replace(':actorId', actor.id)}>{getBusinessOrPrivateName(actor)}</Link>
                                                </ListItemText>
                                            </ListItem>
                                        ))
                                    }
                                </List>
                            </ReadOnlyField>
                        </AdminFormFields>

                        <AdminFormButtons>
                            <Button
                                type="submit"
                                variant="contained"
                                color="primary"
                            >
                                Save
                            </Button>
                            <Button
                                type="button"
                                variant="outlined"
                                color="primary"
                                onClick={onCancel}
                            >
                                Cancel
                            </Button>
                        </AdminFormButtons>
                    </form>
                )}
            </Form>
        </AdminFormWrapper>
    );
};

EditUserForm.propTypes = {
    user: PropTypes.object.isRequired,
    isSubmitting: PropTypes.bool,
    onSubmit: PropTypes.func.isRequired,
    onCancel: PropTypes.func.isRequired
};

export default EditUserForm;
