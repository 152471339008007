import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from 'styles/util';
import { createInitialValues, getParams, formFieldKeys, getOrderByItems } from 'helpers/StorageSearchFormHelper';
import strings from 'localization/strings';

import { Form } from 'react-final-form';
import Typography from '@material-ui/core/Typography';
import DropdownButton from 'form/DropdownButton';

const useStyles = makeStyles(({ theme, colors, fonts }) => ({
    orderBy: {
        display: 'flex',
        alignItems: 'center',
        gap: theme.spacing(2)
    },
    storageSearchFormLabel: {
        whiteSpace: 'nowrap'
    },
    backgroundColor: colors.white,
    padding: theme.spacing(1, 2.5),
    '& .MuiButton-label': {
         fontFamily: fonts.regular
    },
    '& .MuiSvgIcon-root': {
        fontSize: theme.spacing(2.875),
    },
    '&:hover': {
        color: colors.white,
        backgroundColor: colors.lightButtonHoverColor
    }
}));

const OrderByButton = ({ onSubmit, searchParams }) => {
    const classes = useStyles();
    const initialValues = createInitialValues(searchParams);
    const handleFormSubmit = values => {
        const params = getParams({ ...initialValues, ...values, page: 1 });

        ['goodsTypes', 'storageTypes', 'facilities', 'ownerTypes']
            .forEach(p => {
                params[p] = params[p].filter(o => o !== undefined);
                if(params[p].length === 0) {
                    params[p] = undefined;
                }
            });

        onSubmit(params);
    };

    return (
        <Form
            onSubmit={handleFormSubmit}
            initialValues={initialValues}
        >
            {({ handleSubmit }) => (
                <form onSubmit={handleSubmit} className={classes.orderBy}>
                    <Typography variant="body1" className={classes.storageSearchFormLabel}>{strings.storageSearchForm.orderByLabel}</Typography>
                    <DropdownButton
                        name={formFieldKeys.orderBy}
                        items={getOrderByItems()}
                        onChange={event => handleFormSubmit({ [formFieldKeys.orderBy]: event.target.value })}
                    />
                </form>
            )}
        </Form>
    );
};

OrderByButton.propTypes = {
    onSubmit: PropTypes.func.isRequired,
    searchParams: PropTypes.object.isRequired
};

export default OrderByButton;
