import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import strings from 'localization/strings';
import { makeStyles } from 'styles/util';
import { calculateSum } from 'helpers/ArrayHelper';
import Amount from 'common/Amount';
import VatDetails from 'common/PriceSpecification/VatDetails';

import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';

const useStyles = makeStyles(({ theme, colors }) => ({
    tableFooter: {
        borderTop: `1px solid ${colors.lightGreyBorder ?? colors.mediumGrey}`,
        padding: theme.spacing(1.875),
        '& *': {
            fontSize: theme.typography.body2.fontSize
        }
    },
    vatInfo: {
        '& > *': {
            color: colors.mediumDarkGrey
        }
    }
}));

const PriceSpecificationFooter = ({purchaseItems, currency, formatAmountOptions, showVat = false, expandVat = false, rowClassName}) => {
    const classes = useStyles();
    return (
        <Box className={clsx(classes.tableFooter, rowClassName)}>
            <Typography variant="h5" component="p">{strings.sum}</Typography>
            <Box>
                <Typography variant="h5" component="p"><Amount value={calculateSum(purchaseItems, item => item.amount + (item.vat ?? 0))} currency={currency} {...formatAmountOptions}/></Typography>
                {
                    showVat && <VatDetails vat={calculateSum(purchaseItems, item => item.vat ?? 0)} currency={currency} {...formatAmountOptions} expandVat={expandVat}  />
                }
            </Box>
        </Box>
    );
};

PriceSpecificationFooter.propTypes = {
    purchaseItems: PropTypes.array.isRequired,
    currency: PropTypes.string.isRequired,
    formatAmountOptions: PropTypes.object,
    showVat: PropTypes.bool,
    expandVat: PropTypes.bool,
    rowClassName: PropTypes.string
};

export default PriceSpecificationFooter;
