import React from 'react';
import versionHistoryItems from '../../../versionHistoryItems';

import AdminPage from '../../presentational/AdminPage';
import AdminContentWrapper from '../../presentational/AdminContentWrapper';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';

const VersionHistory = () => {
    return (
        <AdminPage title="Version history" fullWidth>
            <AdminContentWrapper type="listing">
                <List>
                    {
                        versionHistoryItems.reverse().map((version, i) => (
                            <ListItem key={i}>
                                <ListItemText
                                    primary={version.date}
                                    secondary={version.description}
                                />
                            </ListItem>
                        ))
                    }
                </List>
            </AdminContentWrapper>
        </AdminPage>
    );
};

export default VersionHistory;
