import React from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { setRedirectUrl } from 'actions/authentication';
import routes from 'routes';

import { Route, Redirect } from 'react-router-dom';
import Loader from 'common/Loader';

const PrivateRoute = props => {
    const dispatch = useDispatch();
    const location = useLocation();
    const Component = props.component;

    const hasToken = useSelector(state => !!state.authentication.token);
    const redirectUrl = useSelector(state => state.authentication.redirectUrl);
    const authenticationContext = useSelector(state => state.authentication.context);
    const { isAuthenticated } = authenticationContext;

    const routeRenderer = (routeProps) => {
        if(hasToken) {
            if(!isAuthenticated) {
                return <Loader />;
            }
            return <Component {...routeProps} />;
        }
        if(!redirectUrl) {
            dispatch(setRedirectUrl(location.pathname));
        }
        return <Redirect to={routes.logIn}/>;
    };

    const newProps = {
        ...props,
        component: undefined,
        render: routeRenderer
    };

    return <Route {...newProps} />;
};

PrivateRoute.propTypes = {
    component: PropTypes.oneOfType([
        PropTypes.object,
        PropTypes.func
    ]).isRequired
};

export default PrivateRoute;
