import React, {useState, useEffect} from 'react';
import PropTypes from 'prop-types';
import strings from 'localization/strings';
import { searchActors } from 'actions/admin/actors';
import { useDispatch } from 'react-redux';
import { handleResponse } from 'actions/actionHelpers';
import { getContact, getBusinessOrPrivateName } from 'helpers/ActorHelper';
import contactTypes from 'enums/contactTypes';


import Loader from 'common/Loader';
import Typography from '@material-ui/core/Typography';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '@material-ui/core/Button';
import AdminActorSelectorDialogForm from './AdminActorSelectorDialogForm';
import AdminTable from 'components/adminTable/AdminTable';
import LinkButton from 'common/LinkButton';

const AdminActorSelectorDialog = ({ title = 'Search actor', open, onOk, onClose, ownersOnly = false }) => {
    const dispatch = useDispatch();

    const defaultParameters = {
        query: undefined,
        isOwner: ownersOnly ? ownersOnly : undefined,
        page: 0,
        pageSize: 20,
        sortByColumn: 'name',
        sortDirection: 'asc'
    };

    const [isLoading, setIsLoading] = useState(false);
    const [actors, setActors] = useState(undefined);
    const [parameters, setParameters] = useState(defaultParameters);

    useEffect(() => {
        search(parameters);
    }, [parameters]);

    useEffect(() => {
        setActors(undefined);
    }, [open]);

    const columns = [
        {
            title: 'ID',
            id: 'id',
            render: actor => (
                <LinkButton
                    onClick={() => handleActorClicked(actor)}
                >
                    {actor.id}
                </LinkButton>
            ),
            align: 'right'
        },
        {
            title: 'Name',
            id: 'name'
        },
        {
            title: 'Email',
            id: 'email',
            render: actor => getContact(actor, contactTypes.mainEmail)
        },
        {
            title: 'Mobile',
            id: 'mobileTelephone',
            render: actor => getContact(actor, contactTypes.mainMobileTelephone)
        }
    ];

    const search = searchParameters => {
        if (searchParameters.query !== undefined) {
            setIsLoading(true);
            dispatch(searchActors(searchParameters, false))
                .then(handleResponse(
                    response => {
                        setActors({
                            ...response.payload,
                            items: response.payload.items.map(createActor)
                        });
                        setIsLoading(false);
                    },
                    () => setIsLoading(false)
                ));
        }
    };

    const handleSearchFormSubmit = formValues => {
        setParameters(
            {
                ...defaultParameters,
                query: formValues.actorName
            }
        );
    };

    const handleClose = () => {
        onClose();
    };

    const handleActorClicked = actor => {
        onOk(actor);
    };

    const handleSort = (sortingParameters) => {
        setParameters(
            {
                ...parameters,
                sortByColumn: sortingParameters.sortByColumn,
                sortDirection: sortingParameters.sortDirection
            }
        );
    };

    const handlePageChange = (newPage) => {
        setParameters(
            {
                ...parameters,
                page: newPage
            }
        );
    };

    const handlePageChangeSize = (newPageSize) => {
        setParameters(
            {
                ...parameters,
                page: 0,
                pageSize: newPageSize
            }
        );
    };

    const createActor = actor => ({
        ...actor,
        name: getBusinessOrPrivateName(actor)
    });

    return (
        <Dialog fullWidth maxWidth="md" open={open} onClose={handleClose}>
            <DialogTitle disableTypography>
                <Typography variant="h5">
                    {title}
                </Typography>
            </DialogTitle>
            <DialogContent>
                <AdminActorSelectorDialogForm
                    onSubmit={handleSearchFormSubmit}
                />
                {
                    isLoading && <Loader />
                }
                {
                    !isLoading && actors && (
                        <AdminTable
                            isPaged
                            items={actors.items}
                            count={actors.count}
                            page={actors.page}
                            pageSize={actors.pageSize}
                            columns={columns}
                            sortByColumn={columns.find(c => c.id === parameters.sortByColumn)}
                            sortDirection={parameters.sortDirection}
                            onSort={handleSort}
                            onPageChange={handlePageChange}
                            onPageChangeSize={handlePageChangeSize}
                        />
                    )
                }
            </DialogContent>
            <DialogActions>
                <Button
                    variant="contained"
                    color="primary"
                    onClick={handleClose}
                >
                    {strings.close}
                </Button>
            </DialogActions>
        </Dialog>
    );
};

AdminActorSelectorDialog.propTypes = {
    title: PropTypes.string,
    open: PropTypes.bool.isRequired,
    onOk: PropTypes.func.isRequired,
    onClose: PropTypes.func.isRequired,
    ownersOnly: PropTypes.bool
};

export default AdminActorSelectorDialog;
