import strings from 'localization/strings';

const lockTypes = {
    telkey: {
        format: lockConfiguration => lockConfiguration.data,
        useLockCodePage: true
    },
    parakey: {
        format: lockConfiguration => lockConfiguration.data,
        useLockCodePage: false
    },
    plaintext: {
        format: lockConfiguration => lockConfiguration.data,
        useLockCodePage: true
    }
};

Object.keys(lockTypes).forEach(key => {
    lockTypes[key].key = key;
});

strings.addLoadCallback(() => {
    Object.values(lockTypes).forEach(o => {
        o.title = strings.lockTypes[o.key].title;
        o.dataLabel = strings.lockTypes[o.key].dataLabel;
        o.takeoverInformation = strings.lockTypes[o.key].takeoverInformation;
    });
});

export default lockTypes;
