import strings from 'localization/strings';

const openingTimeSpanActions = {
    checkIn: {},
    checkOut: {}
};

Object.keys(openingTimeSpanActions).forEach(key => {
    openingTimeSpanActions[key].key = key;
});

strings.addLoadCallback(() => {
    Object.values(openingTimeSpanActions).forEach(o => {
        o.lowercaseTitle = strings.openingTimeSpanActions[o.key].lowercaseTitle;
        o.title = strings.openingTimeSpanActions[o.key].title;
    });
});

export default openingTimeSpanActions;
