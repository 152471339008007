import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from 'styles/util';
import useMediaQuery from '@material-ui/core/useMediaQuery';

import MediaList from './MediaList';
import VideoPlayer from 'common/VideoPlayer';

const useStyles = makeStyles(({ theme, colors }) => ({
    media: {
        border: `1.5px solid ${colors.mediumGrey}`,
        userSelect: 'none'
    },
    loading: {
        display: 'none'
    },
    videoContainer: {
        margin: theme.spacing(0, 2, 2, 0),
        paddingTop: '16px'
    }
}));

const VideoList = ({ fields }) => {
    const classes = useStyles();
    const isMobile = !useMediaQuery(theme => theme.breakpoints.up('md'));

    const videoWidth = isMobile ? '100%' : 300;
    const videoHeight = isMobile ? undefined : 188;

    return (
        <MediaList fields={fields} containerClassName={classes.videoContainer}>
            {
                video => (
                    <VideoPlayer
                        src={(video.baseUrl ? video.baseUrl + video.mediaUrl : undefined)}
                        width={videoWidth}
                        height={videoHeight}
                        className={video.loading ? classes.loading : classes.media}
                    />
                )
            }
        </MediaList>
    );
};

VideoList.propTypes = {
    fields: PropTypes.object.isRequired
};

export default VideoList;
