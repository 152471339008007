import strings from 'localization/strings';

const storageGroupStatuses = {
    active: {},
    inactive: {},
    deleted: {}
};

Object.keys(storageGroupStatuses).forEach(key => {
    storageGroupStatuses[key].key = key;
});

strings.addLoadCallback(() => {
    Object.values(storageGroupStatuses).forEach(o => {
        o.title = strings.storageGroupStatuses[o.key];
    });
});

export default storageGroupStatuses;
