const purchaseItemTypes = {
    storage: {},
    service: {},
    postInvoicedService: {},
    storage365RefundFee: {},
    ownerRefundFee: {},
    prorationCorrection: {},
    discount: {},
    invoiceFee: {},
    roundingCorrection: {}
};

Object.keys(purchaseItemTypes).forEach(key => {
    purchaseItemTypes[key].key = key;
});

export default purchaseItemTypes;
