import React, { useEffect, useState, useRef } from 'react';
import { useAppContext } from 'context/AppContext';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory, useLocation } from 'react-router';
import { useParams } from 'react-router';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { fetchViewStorageSites } from 'actions/viewStorageSites';
import { googleTagManagerConstants, createGoogleTagManagerUserAction } from 'integration/google-tag-manager/googleTagManagerHelper';
import {
    getBrowserSearchUrl,
    getSearchQueryString,
    roundToDecimals,
    saveSearchParametersToLocalStorage,
    convertStringToCommaSeparatedStringArray,
    loadSearchParametersFromLocalStorage,
    decodeCoordinate
} from 'helpers/SearchHelper';
import { addressToLocation } from 'helpers/GeocodeHelper';
import { makeStyles } from 'styles/util';
import { getFilteredLandingPages, getLandingPageFromUrlParam } from 'helpers/LandingPageHelper';
import storageOrderBys from 'enums/storageOrderBys';
import pageSizes from 'enums/pageSizes';
import queryString from 'query-string';
import { convertToNumber, toPascalCase } from 'helpers/StringHelper';
import storageGroupCategories from 'enums/storageGroupCategories';
import strings from 'localization/strings';
import { getStorageSiteDetailsUrl } from 'helpers/StorageSiteHelper';

import Container from 'common/Container';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import LandingPageSearchForm from 'components/storageSearchForm/LandingPageSearchForm';
import StorageSiteList from 'components/storageSiteList/StorageSiteList';
import BackToTop from './BackToTop';
import OrderByButton from 'components/storageSearchForm/OrderByButton';
import PaginationWrapper from './PaginationWrapper';
import PageNotFoundPage from 'pages/pageNotFound/PageNotFoundPage';
import PageTitle from 'common/PageTitle';
import Loader from 'common/Loader';
import BookingSteps from './BookingSteps';
import { Zoom } from '@material-ui/core';
import Tab from '@material-ui/core/Tab';
import Tabs from '@material-ui/core/Tabs';
import Hidden from '@material-ui/core/Hidden';
import { Helmet } from 'react-helmet-async';

const useStyles = makeStyles(({ theme, colors }) => ({
    contentAndStepsContainer: {
        display: 'flex',
        gap: theme.spacing(4)
    },
    contentContainer: {
        display: 'flex',
        flexDirection: 'column',
        gap: theme.spacing(3),
        flex: '1 0 0',
        marginBottom: theme.spacing(4)
    },
    orderByContainer: {
        width: '100%',
        display: 'flex',
        gap: theme.spacing(2),
        flexWrap: 'wrap',
        alignItems: 'center',
        justifyContent: 'space-between'
    },
    bookingSteps: {
        backgroundColor: colors.white,
        width: '200px'
    },
    storageType: {
        flex: '1 1 auto',
        minHeight: theme.spacing(7),
        borderBottom: `1px solid ${colors.mediumGrey}`,
        '& span': {
            fontSize: theme.spacing(2),
            color: colors.black,
            fontStyle: 'normal',
            fontWeight: 'normal',
            lineHeight: '150%',
            whiteSpace: 'nowrap'
        }
    },
    aboutOurPrices: {
        padding: theme.spacing(6, 0),
        color: colors.white,
        [theme.breakpoints.down('sm')]: {
            padding: theme.spacing(4, 0),
        }
    }
}),
({ colors }) => ({
    storage: {
        aboutOurPrices: {
            backgroundColor: colors.secondaryColor
        }
    },
    office: {
        aboutOurPrices: {
            backgroundColor: colors.primaryColor
        }
    }
}));

// input:
// from pathname:
//   landingPage           string
//   location              string (geographic location)
// from querystring:
//   goodsTypes            comma-separated string of strings
//   storageTypes          comma-separated string of strings
//   facilities            comma-separated string of strings
//   ownerTypes            comma-separated string of strings
//   lat                   float (deprecated)
//   lng                   float (deprecated)
//   radius                float
//   maxPricePerStorage    float
//   maxPricePerAreaUnit   float
//   maxArea               float
//   minArea               float
//   page                  int
//   pageSize              int
//   orderBy               string

const LandingPage = () => {
    const classes = useStyles();
    const isMobile = !useMediaQuery(theme => theme.breakpoints.up('md'));
    const { appContext } = useAppContext();
    const [showButton, setShowButton] = useState(false);

    const routerLocation = useLocation();
    const history = useHistory();
    const dispatch = useDispatch();
    const params = useParams();
    const filteredLandingPages = getFilteredLandingPages(appContext);
    const landingPage = getLandingPageFromUrlParam(params.landingPage, filteredLandingPages);
    const scrollListener = () => {
        setShowButton(window.pageYOffset > 700);
    };
    const searchResultsRef = useRef();

    if(!landingPage) {
        return <PageNotFoundPage/>;
    }

    const [geocodingNow, setGeocodingNow] = useState(false);
    const [hasSearched, setHasSearched] = useState(false);

    const { storageSites, totalNumberOfStorageSites, storageSitesLoading, seoGeoItem } = useSelector(state => ({
        storageSites: state.viewStorageSites.viewStorageSites.items,
        totalNumberOfStorageSites: state.viewStorageSites.viewStorageSites.totalCount,
        storageSitesLoading: state.viewStorageSites.viewStorageSites.loading,
        seoGeoItem: state.viewStorageSites.viewStorageSites.seoGeoItem
    }));

    useEffect(() => {
        if(!storageSitesLoading && isMobile) {
            // on mobile, auto-scroll to search result when loaded
            window.scrollTo({ left: 0, top: searchResultsRef.current.offsetTop - 24, behavior: 'smooth' });
        }
    }, [storageSitesLoading]);

    // returns object with the following properties:
    // { locationText, goodsTypes, storageTypes, facilities, ownerTypes, lat, lng, radius, maxPricePerStorage, maxPricePerAreaUnit, maxArea, minArea, page, pageSize, orderBy, gad, gclid }
    const getSearchParams = () => {
        const queryStringParams = queryString.parse(routerLocation.search);

        // we need to pass on the Google Search parameters to measure referral
        const googleSearchParameters = {
            gad: queryStringParams.gad,
            gclid: queryStringParams.gclid
        };

        const searchParams = {
            locationText: params.locationText ?? loadSearchParametersFromLocalStorage().locationText,
            ...googleSearchParameters
        };

        if(queryStringParams.c) {
            const coordinate = decodeCoordinate(queryStringParams.c);
            queryStringParams.lat = coordinate?.lat;
            queryStringParams.lng = coordinate?.lng;
        }

        // populate searchParams with valid data keys from browser's location
        searchParams.goodsTypes = convertStringToCommaSeparatedStringArray(queryStringParams.goodsTypes);
        searchParams.storageTypes = convertStringToCommaSeparatedStringArray(queryStringParams.storageTypes);
        searchParams.facilities = convertStringToCommaSeparatedStringArray(queryStringParams.facilities);
        searchParams.ownerTypes = convertStringToCommaSeparatedStringArray(queryStringParams.ownerTypes);

        // make sure float parameters are parsed to float
        [
            { name: 'lat', decimals: 5 },
            { name: 'lng', decimals: 5 },
            { name: 'radius', decimals: 0 },
            { name: 'maxPricePerStorage', decimals: 0 },
            { name: 'maxPricePerAreaUnit', decimals: 0 },
            { name: 'maxArea', decimals: 0 },
            { name: 'minArea', decimals: 0 },
            { name: 'page', decimals: 0 },
            { name: 'pageSize', decimals: 0 }
        ].forEach(p => {
            if (typeof queryStringParams[p.name] === 'string') {
                searchParams[p.name] = convertToNumber(queryStringParams[p.name]);
            }
            if (typeof queryStringParams[p.name] === 'number') {
                searchParams[p.name] = queryStringParams[p.name];
            }
            if (typeof searchParams[p.name] === 'number') {
                searchParams[p.name] = roundToDecimals(searchParams[p.name], p.decimals);
            }
        });

        // listing order (enums from enums/storageOrderBys)
        if(Object.values(storageOrderBys).map(o => o.key).indexOf(queryStringParams.orderBy) === -1) {
            searchParams.orderBy = storageOrderBys.weightedDistance.key;
        } else {
            searchParams.orderBy = queryStringParams.orderBy;
        }


        if(Object.values(pageSizes).map(p => p.value).indexOf(searchParams.pageSize) === -1) {
            searchParams.pageSize = pageSizes.twenty.value;
        }

        if(!searchParams.page) {
            searchParams.page = 1;
        }

        return searchParams;
    };

    const searchParams = getSearchParams();
    const isLocationSearch = !!searchParams.locationText;
    const searchQueryString = getSearchQueryString(searchParams);

    const updateUrl = (newLandingPage, newSearchParams) => {
        const { path, query } = getBrowserSearchUrl(newLandingPage, newSearchParams);
        if (path !== routerLocation.pathname || '?' + query !== routerLocation.search) {
            // the processed search parameters are different than the actual parameters in the url,
            // so update the url
            const url = `${path}?${query}`;
            history.replace(url);
        }
    };

    const useEffectStorageUpdate = () => {
        if (searchParams.locationText && (!searchParams.lat || !searchParams.lng)) {
            setGeocodingNow(true);
            addressToLocation(
                searchParams.locationText,
                appContext,
                result => {
                    if(result.location) {
                        const l = {
                            lat: roundToDecimals(result.location.latitude, 5),
                            lng: roundToDecimals(result.location.longitude, 5)
                        };

                        updateUrl(landingPage, { ...searchParams, ...l });
                        getStorageSites(l);
                    } else {
                        getStorageSites(undefined);
                    }
                    setGeocodingNow(false);
                },
                true // useCache
            );
        } else {
            updateUrl(landingPage, searchParams);
            getStorageSites({
                lat: searchParams.lat,
                lng: searchParams.lng
            });
        }
    };

    useEffect(() => {
        useEffectStorageUpdate();
    }, [landingPage.key, searchParams.locationText, searchQueryString]);

    useEffect(() => {
        window.addEventListener('scroll', scrollListener);
        return () => {
            window.removeEventListener('scroll', scrollListener);
        };
    }, []);

    const getStorageSites = coordinate => {
        saveSearchParametersToLocalStorage(searchParams);
        dispatch(fetchViewStorageSites({
            ...searchParams,
            location: searchParams.locationText,
            // backend needs longitude and latitude
            lat: coordinate?.lat,
            lng: coordinate?.lng,
            storageGroupCategories: landingPage.key,
            radius: storageGroupCategories[landingPage.key].searchRadius
        }));
        dispatch(createGoogleTagManagerUserAction(googleTagManagerConstants.userActions.search));
    };

    const handleSearchFormSubmit = parameters => {
        const { path, query } = getBrowserSearchUrl(landingPage, parameters);
        history.push(`${path}?${query}`);
        setHasSearched(true);
    };

    const storageSiteLinkCreator = storageSite => {
        const category = storageGroupCategories[landingPage.key];
        return getStorageSiteDetailsUrl(storageSite, category.key);
    };

    const getPageTitle = () => {
        if(isLocationSearch) {
            if ( seoGeoItem && seoGeoItem.title ) {
                return seoGeoItem.title;
            }
            return strings.formatString(landingPage.pageTitleWithGeography, toPascalCase(searchParams.locationText));
        }
        return landingPage.pageTitle;
    };
    const handleLandingPageChange = (event, newValue) => {
        updateUrl(filteredLandingPages[newValue], searchParams);
    };

    const numberOfStorageSites = totalNumberOfStorageSites || 0;

    const getPageMetaDescription = () => {
        return seoGeoItem && seoGeoItem.metaDescription ? seoGeoItem.metaDescription : landingPage.metaDescription;
    };

    if ( !hasSearched && (storageSitesLoading || geocodingNow)) {
        return <Loader />;
    }

    return (
        <>
            <Box>
                <PageTitle prependCompanyName={false}>{getPageTitle()}</PageTitle>
                <Helmet defer={false}>
                    <meta name="description" content={getPageMetaDescription()}/>
                </Helmet>
                {
                    Object.values(filteredLandingPages).length > 1 &&
                    (
                        <Hidden smUp>
                            <Tabs value={landingPage.key} onChange={handleLandingPageChange} className={classes.typeContainer} classes={{ indicator: classes.hideIndicator }}>
                                {
                                    Object.values(filteredLandingPages).map(lp => (
                                        <Tab key={lp.key} value={lp.key} label={lp.shortLabel} classes={{ root: classes.storageType, selected: classes.selected }}/>
                                    ))
                                }
                            </Tabs>
                        </Hidden>
                    )
                }
                <LandingPageSearchForm
                    landingPage={landingPage}
                    onSubmit={handleSearchFormSubmit}
                    searchParams={searchParams}
                    seoGeoItem={seoGeoItem}
                />
                <Box ref={searchResultsRef}>
                    <Container marginTop={4} marginBottom={6}>
                        <PaginationWrapper
                            onChange={handleSearchFormSubmit}
                            isTop
                            numberOfStorageSites={numberOfStorageSites}
                            searchParams={searchParams}
                    />
                    </Container>
                </Box>
                <Container className={classes.contentAndStepsContainer}>
                    <Box className={classes.contentContainer}>
                        {
                            numberOfStorageSites !== 0 &&
                            (
                                <Box className={classes.orderByContainer}>
                                    <Typography variant="h4">{strings.recommendedBasedOnSearch}</Typography>
                                    <OrderByButton onSubmit={handleSearchFormSubmit} searchParams={searchParams}/>
                                </Box>
                            )
                        }
                        <StorageSiteList
                            storageSites={storageSites}
                            storageGroupCategory={storageGroupCategories[landingPage.key]}
                            loading={storageSitesLoading || geocodingNow}
                            showDistances={isLocationSearch}
                            storageSiteLinkCreator={storageSiteLinkCreator}
                            landingPage={landingPage}
                        />
                        <PaginationWrapper
                            onChange={handleSearchFormSubmit}
                            isTop={false}
                            numberOfStorageSites={numberOfStorageSites}
                            searchParams={searchParams}
                        />
                    </Box>
                    <Hidden smDown>
                        <Box className={classes.bookingSteps}>
                            <BookingSteps landingPage={landingPage} />
                        </Box>
                    </Hidden>
                </Container>
                <Box className={classes.aboutOurPrices}>
                    <Container>
                        <Typography variant="h4" gutterBottom> {strings.aboutOurPricesTitle}</Typography>
                        <Typography variant="body1">{strings.aboutOurPricesMessage}</Typography>
                    </Container>
                </Box>
            </Box>
            <Zoom in={showButton}>
                <BackToTop />
            </Zoom>
        </>
    );
};

export default LandingPage;
