const payoutStatuses = {
    notStarted: {},
    zeroAmount: {},
    pending: {},
    paid: {},
    failed: {},
    canceled: {},
    failedAndArchived: {}
};

Object.keys(payoutStatuses).forEach(key => {
    payoutStatuses[key].key = key;
});

export default payoutStatuses;
