import store from 'store2';
import queryString from 'query-string';
import { startOfDay } from 'date-fns';
import { convertToNumber } from 'helpers/StringHelper';

export const convertStringToCommaSeparatedStringArray = str => {
    if (str === null || str === undefined) {
        return undefined;
    }
    return str.split(/[\+\ ]/);
};

export const roundToDecimals = (value, decimals) => {
    const factor = Math.pow(10, decimals);
    return Math.round(factor * value) / factor;
};

export const getSearchQueryString = (params, arraySeparator = ',') => {
    const queryStringParameters = {};
    Object.keys(params)
        .sort()
        .forEach(key => {
            if(Array.isArray(params[key])) {
                queryStringParameters[key] = params[key].join(arraySeparator);
            } else if(params[key]) {
                queryStringParameters[key] = params[key];
            }
        });
    return queryString.stringify(queryStringParameters);
};

export const getBrowserSearchUrl = (landingPage, params) => {
    const clonedParams = {
        ...params,
        locationText: undefined,
        address: undefined,
        lat: undefined,
        lng: undefined,
        c: params.locationText && params.lat && params.lng
            ? encodeCoordinate(params)
            : undefined
    };

    if (landingPage.trimParams) {
        landingPage.trimParams(clonedParams);
    }

    return {
        path: landingPage.url + (params.locationText ? '/' + encodeURI(params.locationText) : ''),
        query: getSearchQueryString(clonedParams, '+')
    };
};

export const getApiSearchUrl = params => {
    return '/api/viewstoragesites?' + getSearchQueryString(params, ',');
};

export const saveSearchParametersToLocalStorage = parameters => {
    store.set('searchParameters', JSON.stringify(parameters));
};

export const loadSearchParametersFromLocalStorage = () => {
    const json = store.get('searchParameters');
    if(json) {
        return JSON.parse(json);
    }
    return {};
};

export const sanitizeBookingRequestFormValues = formValues => {
    if(isInvalidBookingRequestFormValues(formValues)) {
        const clonedFormValues = { ...formValues };
        const today = startOfDay(new Date());
        if(clonedFormValues.requestedStartDate && clonedFormValues.requestedStartDate < today) {
            clonedFormValues.requestedStartDate = today;
        }
        if(clonedFormValues.requestedEndDate && clonedFormValues.requestedEndDate < today) {
            clonedFormValues.requestedEndDate = today;
        }
        return clonedFormValues;
    }
    return formValues;
};

export const isInvalidBookingRequestFormValues = formValues => {
    const today = startOfDay(new Date());
    return (formValues.requestedStartDate && formValues.requestedStartDate < today) ||
           (formValues.requestedEndDate && formValues.requestedEndDate < today);
};

const encodeCoordinate = coordinate => {
    if(!coordinate) {
        return undefined;
    }

    return window.btoa(`${coordinate.lat}:${coordinate.lng}`);
};

export const decodeCoordinate = encryptedCoordinateString => {
    const atoms = window.atob(encryptedCoordinateString).split(':');
    if(atoms.length === 2) {
        const coordinate = {
            lat: convertToNumber(atoms[0]),
            lng: convertToNumber(atoms[1])
        };
        if(coordinate.lat && coordinate.lng) {
            return coordinate;
        }
    }
    return undefined;
};

