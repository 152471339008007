import strings from 'localization/strings';

const bookingBoundaryTypes = {
    checkIn: {},
    checkOut: {}
};
Object.keys(bookingBoundaryTypes).forEach(key => {
    bookingBoundaryTypes[key].key = key;
});

strings.addLoadCallback(() => {
    Object.values(bookingBoundaryTypes).forEach(o => {
        o.title = strings.bookingBoundaryTypes[o.key];
    });
});

export default bookingBoundaryTypes;
