import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from 'styles/util';

const IconDelete = ({fill}) => {
    const useStyles = makeStyles(() => ({
        svg: {
            fill: fill
        }
    }));

    const classes = useStyles();
    return (
        <svg className={classes.svg} width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
            <path d="M16 9V19H8V9H16ZM14.5 3H9.5L8.5 4H5V6H19V4H15.5L14.5 3ZM18 7H6V19C6 20.1 6.9 21 8 21H16C17.1 21 18 20.1 18 19V7Z"/>
        </svg>
    );
};

IconDelete.propTypes = {
    fill: PropTypes.string.isRequired
};

export default IconDelete;
