import React from 'react';
import PropTypes from 'prop-types';
import strings from 'localization/strings';
import { makeStyles } from 'styles/util';
import { parseIsoDate, getLongDateFormat, formatIsoDate } from 'helpers/DateHelper';
import { getSimplifiedAvailableBookingIntervals } from 'logic/bookingLogic';
import { isBefore, isAfter } from 'date-fns';
import { showErrorOnBlur } from 'mui-rff';
import { maxDateString } from 'logic/bookingLogic';

import Event from '@material-ui/icons/Event';
import TooltipIcon from 'common/TooltipIcon';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import DatePicker from 'form/DatePicker';
import NullableDatePicker from 'form/NullableDatePicker';

const useStyles = makeStyles(({ theme, colors, styles }) => ({
    datePicker: {
        justifyContent: 'center',
        width: '100%',
        margin: theme.spacing(1, 0, 2),
        '& .MuiSvgIcon-root': {
            color: colors.black
        },
        '& .MuiOutlinedInput-root': {
            borderRadius: `${styles.inputBorderRadius}px`
        },
        '& .MuiOutlinedInput-input': {
            padding: theme.spacing(1.875, 1.75),
            color: colors.black
        },
        [theme.breakpoints.down('sm')]: {
            width: '100%',
            marginRight: 0
        },
    },
    infoIcon: {
        margin: '0 !important'
    },
    fullWidthBox: {
        width: '100%'
    }
}));

const DateSelector = ({ formValues, selectedStorageGroup, endDateIsNullable, endDateMustBeNull, isPreview = false }) => {
    const classes = useStyles();
    const simplifiedAvailableBookingIntervals = getSimplifiedAvailableBookingIntervals(selectedStorageGroup);

    const shouldDisableDate = date => {
        if(!selectedStorageGroup) {
            return false;
        }
        const foundInterval = simplifiedAvailableBookingIntervals.find(o => !isAfter(parseIsoDate(o.startDate), date) && !isBefore(parseIsoDate(o.endDate ?? maxDateString), date));
        return !foundInterval;
    };

    const requestedEndDatePickerProps = {
        name: 'requestedEndDate',
        formatFunc: getLongDateFormat,
        inputVariant: 'outlined',
        showError: showErrorOnBlur,
        disablePast: true,
        className: classes.datePicker,
        placeholder: endDateIsNullable ? undefined : strings.selectADate,
        fieldProps: { minDate: formatIsoDate(formValues.requestedStartDate), shouldDisableDate },
        InputProps: {
            endAdornment: endDateMustBeNull
                ? <TooltipIcon text={strings.storageSiteOnlyAllowsSubscriptionBookings} className={classes.infoIcon} />
                : <Event />
        },
        nullString: endDateIsNullable ? strings.untilFurther : undefined,
        disabled: isPreview || endDateMustBeNull
    };

    return (
        <>
            <Box className={classes.fullWidthBox}>
                <Typography variant="body2">{strings.startDate}</Typography>
                <DatePicker
                    name="requestedStartDate"
                    formatFunc={getLongDateFormat}
                    inputVariant="outlined"
                    showError={showErrorOnBlur}
                    disablePast
                    className={classes.datePicker}
                    placeholder={strings.selectADate}
                    fieldProps={{ shouldDisableDate, okLabel: strings.ok, cancelLabel: strings.cancel }}
                    InputProps={{ endAdornment: <Event /> }}
                    disabled={isPreview}
                />
            </Box>
            <Box className={classes.fullWidthBox}>
                <Typography variant="body2">{strings.endDate}</Typography>
                { endDateIsNullable && <NullableDatePicker {...requestedEndDatePickerProps} /> }
                { !endDateIsNullable && <DatePicker {...requestedEndDatePickerProps} /> }
            </Box>
        </>
    );
};

DateSelector.propTypes = {
    formValues: PropTypes.object.isRequired,
    selectedStorageGroup: PropTypes.object,
    endDateIsNullable: PropTypes.bool.isRequired,
    endDateMustBeNull: PropTypes.bool.isRequired,
    isPreview: PropTypes.bool
};

export default DateSelector;
