import React from 'react';
import ReactDOM from 'react-dom';

import Root from 'components/Root';

import './styles/index.scss';

ReactDOM.render(
    <Root/>,
    document.getElementById('root')
);

if(module.hot) {
    module.hot.accept();
}
