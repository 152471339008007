import React, { useState } from 'react';
import strings from 'localization/strings';
import { makeStyles } from 'styles/util';
import { questionAndAnswerItemsTenant, questionAndAnswerItemsOwner } from 'helpers/QuestionAndAnswersHelper';
import { useScrollToTop } from 'helpers/BrowserHelper';

import PageTitle from 'common/PageTitle';
import Container from 'common/Container';
import ExpandableTitleDescriptionList from 'components/expandableTitleDescriptionList/ExpandableTitleDescriptionList';
import TwoColumnButtonTabs from './TwoColumnButtonTabs';
import ContactUs from './ContactUs';
import QuestionsAndAnswersImage from 'assets/images/q_and_a.jpg';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';

const useStyles = makeStyles(({ theme }) => ({
    pageTitle: {
        marginBottom: theme.spacing(2)
    },
    questionsImageHero: {
        objectFit: 'cover',
        width: '100%',
        height: '400px',
        marginBottom: theme.spacing(4)
    }
}));

const QuestionAndAnswersPage = () => {
    const classes = useStyles();
    const [ownerActivated, setOwnerActivated] = useState(false);

    const getTwoColumnButtons = () => ({
        left: {
            ownerActivated: false,
            text: strings.tenant,
            isBlue: false,
            isActive: !ownerActivated,
        },
        right: {
            ownerActivated: true,
            text: strings.owner,
            isBlue: true,
            isActive: ownerActivated,
        }
    });

    const onTwoColumnButtonClick = button => {
        setOwnerActivated(button.ownerActivated);
    };

    useScrollToTop();

    return (
        <Container withPageMargins>
            <PageTitle>{strings.headerTitleQuestionsAndAnswers}</PageTitle>
            <Typography variant="h1" className={classes.pageTitle}>{strings.questionsAndAnswersShort}</Typography>
            <Typography variant="body1" className={classes.introDescription}>{strings.questionsAndAnswersText1}</Typography>
            <Box marginTop={4} marginBottom={4}>
                <ContactUs/>
            </Box>
            <img className={classes.questionsImageHero} src={QuestionsAndAnswersImage}/>
            <TwoColumnButtonTabs
                buttonData={getTwoColumnButtons()}
                onButtonClick={onTwoColumnButtonClick}
            />
            <ExpandableTitleDescriptionList items={ownerActivated ? questionAndAnswerItemsOwner : questionAndAnswerItemsTenant}/>
        </Container>
    );
};

export default QuestionAndAnswersPage;
