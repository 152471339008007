import React from 'react';
import PropTypes from 'prop-types';
import { useAppContext } from 'context/AppContext';
import eidProviders from 'enums/eidProviders';

import EidentLogin from './EidentLogin';
import SwedishBankIdLogin from './SwedishBankIdLogin';
import MockEidLogin from './MockEidLogin';

// componen that will let a user authenticate using an eID
// on successful authentication:
// - redirection will be made to redirectUrl?eidTemporaryDataToken={eidTemporaryDataToken}
// - eidTemporaryDataToken can be used to fetch user data
// on failed authentication:
// - redirection will be made to redirectUrl?eidLoginFailure={eidLoginFailure}
const EidLoginContainer = ({ title, redirectUrl, clientState, onStatusChange }) => {
    const { appContext } = useAppContext();

    const props = { title, redirectUrl, clientState, onStatusChange };

    const eidProviderComponents = {
        [eidProviders.swedishBankId.key]: <SwedishBankIdLogin {...props} />,
        [eidProviders.eIdent.key]: <EidentLogin {...props} />,
        [eidProviders.mock.key]: <MockEidLogin {...props} />
    };

    return (
        <>
            {
                appContext.authentication.eidProviders
                    .map(key => <React.Fragment key={key}>{eidProviderComponents[key]}</React.Fragment>)
            }
        </>
    );
};

EidLoginContainer.propTypes = {
    title: PropTypes.string,
    redirectUrl: PropTypes.string,
    clientState: PropTypes.object,
    onStatusChange: PropTypes.func
};

export default EidLoginContainer;
