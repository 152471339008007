import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import strings from 'localization/strings';
import { useAppContext } from 'context/AppContext';
import { handleResponse } from 'actions/actionHelpers';
import { makeStyles } from 'styles/util';
import { useHistory, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useForm } from 'react-final-form';
import { updateEidTemporaryData } from 'actions/authentication';
import { fetchActorInformation } from 'actions/actors';
import { fetchUserAuthenticationInfo } from 'actions/users';
import { getFormValue } from 'helpers/FormHelper';
import organizationTypes from 'enums/organizationTypes';
import userAuthenticationTypes from 'enums/userAuthenticationTypes';
import routes from 'routes';
import { enhanceRedirectUrl, createBusinessActor, appendQuerystringParametersForAuthentication } from 'logic/authenticationLogic';

import OrganizationNumberForm from './OrganizationNumberForm';
import BusinessActorForm from './BusinessActorForm';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import StepIndicator from 'common/StepIndicator';
import CreateEidUser from './CreateEidUser';
import CreateUser from './CreateUser';
import BusinessUserPasswordForm from './BusinessUserPasswordForm';
import { Link } from 'react-router-dom';
import CircularProgressButton from 'common/CircularProgressButton';
import EidLoginContainer from 'components/eid/EidLoginContainer';
import Text from 'common/Text';

const useStyles = makeStyles(({ theme }) => ({
    container: {
        display: 'flex',
        flexDirection: 'column',
        gap: theme.spacing(3.75)
    }
}));

const CreateBusinessActor = ({ fieldNamePrefix }) => {
    const classes = useStyles();
    const { appContext } = useAppContext();
    const location = useLocation();
    const history = useHistory();
    const dispatch = useDispatch();
    const form = useForm();
    const [showUsernameAndPasswordLink, setShowUsernameAndPasswordLink] = useState(true);
    const [isLoading, setIsLoading] = useState(false);
    const formState = form.getState();
    const formValues = formState.values;
    const redirectUrl = useSelector(state => state.authentication.redirectUrl);
    const contactSupportToGetUsernameAndPasswordLink = !!appContext.authentication.allowedUserAuthenticationTypes.find(o => o === userAuthenticationTypes.eid.key) && !formValues.allowCreateUsernameAndPasswordAccount;

    const getStep = () => {
        if(location.pathname === routes.createAccountBusinessDetails) {
            return 1;
        }
        if(location.pathname === routes.createAccountBusinessEid || location.pathname === routes.createAccountBusinessUsernameAndPassword || location.pathname === routes.createAccountBusinessEmailAlreadyInUse) {
            return 2;
        }
        return 0;
    };

    const step = getStep();

    useEffect(() => {
        if(step === 0) {
            form.change(getName('actorOrganizationNumber'), null);
        }
        if(step > 0 && !getFormValue(form, getName('actorOrganizationNumber'))) {
            history.push(routes.createAccountBusiness);
        }
        if(getUserAuthenticationTypeFromLocation() === userAuthenticationTypes.eid.key && step === 2 && formValues.eidTemporaryDataToken) {
            // eid details already present
            dispatch(updateEidTemporaryData(formValues.eidTemporaryDataToken, { clientState: createClientState() }))
                .then(handleResponse(
                    () => {
                        const parameters = {
                            eidLoginResult: 'success',
                            eidTemporaryDataToken: formValues.eidTemporaryDataToken,
                            userInvitation: formValues.userInvitation,
                            takeoverTenant: formValues.takeoverTenant
                        };
                        history.push(appendQuerystringParametersForAuthentication(routes.eidLogIn, parameters));
                    }));
        }
    }, [step]);

    const getName = suffix => suffix ? `${fieldNamePrefix}.${suffix}` : fieldNamePrefix;

    const handleCreateEidUserStatusChange = status => {
        setShowUsernameAndPasswordLink(status !== 'started');
    };

    const handleOrganizationNumberFormSubmit = organizationNumber => {
        if(!appContext.actorInformation.enabled) {
            history.push(routes.createAccountBusinessDetails);
            return;
        }
        setIsLoading(true);
        dispatch(fetchActorInformation(organizationNumber))
            .then(handleResponse(
                response => {
                    setIsLoading(false);
                    if(response.payload.exists) {
                            return {
                            dialog: {
                                title: strings.errorDialogTitle,
                                body: strings.organizationNumberAlreadyExists
                            }
                        };
                    }
                    form.change(getName('actorName'), response.payload.name);
                    form.change(getName('actorOrganizationNumber'), response.payload.organizationNumber);
                    form.change(getName('actorMobileTelephone'), response.payload.telephone);
                    form.change(getName('actorEmail'), null);
                    form.change(getName('userEmail'), null);
                    history.push(routes.createAccountBusinessDetails);
                    return undefined;
                },
                () => {
                    setIsLoading(false);
                    return {
                        dialog: {
                            body: strings.failedToFetchActorInformation,
                            suppressServerErrorMessage: true
                        }
                    };
                }
            ));
    };

    const handleBusinessActorFormSubmit = () => {
        // check whether the email address is already in use
        setIsLoading(true);
        dispatch(fetchUserAuthenticationInfo(formValues.business.userEmail))
            .then(handleResponse(
                response => {
                    setIsLoading(false);
                    history.push(routes.createAccountBusinessEmailAlreadyInUse);
                    form.change(getName('userAuthenticationTypeAssociatedWithEmail'), response.payload.authenticationType);
                    form.change(getName('userIdAssociatedWithEmail'), response.payload.userId);
                },
                () => {
                    // the email address was not found
                    setIsLoading(false);
                    form.change(getName('userAuthenticationTypeAssociatedWithEmail'), undefined);
                    form.change(getName('userIdAssociatedWithEmail'), undefined);
                    history.push(routes.createAccountBusinessEid);
                    return false; // show no dialog
                }
            ));
    };

    const handleCreateUsernameAndPasswordUserSubmit = () => {
        setIsLoading(true);
        createBusinessActor({
            clientState: formValues,
            dispatch,
            onSuccess: () => history.push(enhanceRedirectUrl(redirectUrl, true)),
            onFailure: response => {
                setIsLoading(false);
                const isUnauthorized = response.meta.response.status === 401;
                if(isUnauthorized) {
                    return {
                        dialog: {
                            title: strings.loginFailed,
                            body: strings.wrongEmailOrPassword,
                            suppressServerErrorMessage: true
                        }
                    };
                }
                return undefined;
            }
        });
    };

    const getUserAuthenticationTypeFromLocation = () => {
        if(location.pathname.startsWith(routes.createAccountBusinessEid)) {
            return userAuthenticationTypes.eid.key;
        }
        if(location.pathname.startsWith(routes.createAccountBusinessUsernameAndPassword)) {
            return userAuthenticationTypes.usernameAndPassword.key;
        }
        return undefined;
    };

    const createClientState = () => ({
        ...formValues,
        organizationType: organizationTypes.business.key
    });

    return (
        <Box className={classes.container}>
            <StepIndicator
                currentStep={step}
                numberOfSteps={3}
            />

            {
                // fill in organization number
                step === 0 &&
                (
                    <OrganizationNumberForm
                        name={getName('actorOrganizationNumber')}
                        onSubmit={handleOrganizationNumberFormSubmit}
                        isLoading={isLoading}
                    />
                )
            }

            {
                // fill in business details
                step === 1 &&
                (
                    <BusinessActorForm
                        fieldNamePrefix={fieldNamePrefix}
                        allowAlterActorInformation={!appContext.actorInformation.enabled}
                        approveImmediately
                        onSubmit={handleBusinessActorFormSubmit}
                    />
                )
            }

            {
                // eID authentication: authenticate using eID
                getUserAuthenticationTypeFromLocation() === userAuthenticationTypes.eid.key && step === 2 &&
                (
                    <>
                        <CreateEidUser
                            description={strings.authentication.createBusinessActorEidAuthentication}
                            redirectUrl={routes.createAccount}
                            onStatusChange={handleCreateEidUserStatusChange}
                            clientState={createClientState()}
                        />
                        {
                            showUsernameAndPasswordLink &&
                            (
                                <Box>
                                    <Typography variant="body1">
                                        {strings.authentication.noEidAuthentication}
                                        {' '}
                                        {
                                            contactSupportToGetUsernameAndPasswordLink &&
                                            (
                                                <Text html={strings.formatString(strings.authentication.contactSupportToGetUsernameAndPasswordLink, routes.about)}/>
                                            )
                                        }
                                        {
                                            !contactSupportToGetUsernameAndPasswordLink &&
                                            (
                                                <Link to={routes.createAccountBusinessUsernameAndPassword}>
                                                    {strings.authentication.createUsernamePasswordUser}
                                                </Link>
                                            )
                                        }
                                    </Typography>
                                </Box>
                            )
                        }
                    </>
                )
            }

            {
                // username and password authentication: fill in user details
                getUserAuthenticationTypeFromLocation() === userAuthenticationTypes.usernameAndPassword.key && step === 2 && !contactSupportToGetUsernameAndPasswordLink &&
                (
                    <>
                        <Typography component="span" variant="body1">
                            {strings.authentication.createBusinessUserDetails}
                        </Typography>

                        <CreateUser
                            fieldNamePrefix={fieldNamePrefix}
                            fieldNameImmediatePrefix="user"
                            showPasswordFields
                        />

                        <CircularProgressButton
                            disabled={formState.invalid}
                            isLoading={isLoading}
                            onClick={handleCreateUsernameAndPasswordUserSubmit}
                        >
                            {strings.save}
                        </CircularProgressButton>
                    </>
                )
            }

            {
                // username and password authentication: need to contact support to get a link
                getUserAuthenticationTypeFromLocation() === userAuthenticationTypes.usernameAndPassword.key && step === 2 && contactSupportToGetUsernameAndPasswordLink &&
                (
                    <Text html={strings.formatString(strings.authentication.contactSupportToGetUsernameAndPasswordLink, routes.about)}/>
                )
            }

            {
                // the email that the user provided is already in use - must log in as this user to continue
                location.pathname === routes.createAccountBusinessEmailAlreadyInUse &&
                (
                    <>
                        <Typography component="span" variant="body1">
                            {strings.formatString(strings.authentication.userEmailAlreadyInUse, formValues.business.userEmail)}
                        </Typography>

                        {
                            // the email belongs to an eid user
                            formValues.business.userAuthenticationTypeAssociatedWithEmail === userAuthenticationTypes.eid.key &&
                            (
                                <EidLoginContainer
                                    clientState={createClientState()}
                                    redirectUrl={routes.createAccount}
                                />
                            )
                        }

                        {
                            // the email belongs to a username and password user
                            formValues.business.userAuthenticationTypeAssociatedWithEmail === userAuthenticationTypes.usernameAndPassword.key &&
                            (
                                <>
                                    <BusinessUserPasswordForm
                                        fieldNamePrefix={fieldNamePrefix}
                                    />

                                    <CircularProgressButton
                                        disabled={formState.invalid}
                                        isLoading={isLoading}
                                        onClick={handleCreateUsernameAndPasswordUserSubmit}
                                    >
                                        {strings.login}
                                    </CircularProgressButton>
                                </>
                            )
                        }
                    </>
                )
            }
        </Box>
    );
};

CreateBusinessActor.propTypes = {
    fieldNamePrefix: PropTypes.string
};

export default CreateBusinessActor;
