import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from 'styles/util';

import MarginBox from 'common/MarginBox';

const useStyles = makeStyles(() => ({
    caption: {
        fontSize: '15px',
        fontWeight: 'bold'
    },
}));

const Caption = ({ text }) => {
    const classes = useStyles();
    return (
        <MarginBox className={classes.caption} bottom={1}>
            {text}
        </MarginBox>
    );
};

Caption.propTypes = {
    text: PropTypes.string.isRequired
};

export default Caption;
