import React from 'react';
import PropTypes from 'prop-types';
import { useAppContext } from 'context/AppContext';
import strings from 'localization/strings';
import { formatShortDate } from 'helpers/DateHelper';

import BookingDetailsSectionCaption from './BookingDetailsSectionCaption';
import Text from 'common/Text';

const SubscriptionBookingEndingInfo = ({ booking }) => {
    const { appContext } = useAppContext();

    const checkOutDate = booking.subscriptionInfo.earliestPossibleCheckOutOpeningTimeSpan?.date ?? booking.subscriptionInfo.earliestPossibleEndDate;
    const title = strings.endBookingTitle;
    let message = strings.formatString(strings.endBookingMessage, formatShortDate(checkOutDate, appContext));

    const checkOutDayDiffersFromEndDay =
        booking.subscriptionInfo.earliestPossibleCheckOutOpeningTimeSpan &&
        booking.subscriptionInfo.earliestPossibleCheckOutOpeningTimeSpan?.date !== booking.subscriptionInfo.earliestPossibleEndDate;
    if(checkOutDayDiffersFromEndDay) {
        // TODO: instead of explaining that there is a difference, refund the tenant
        message += ' ' + strings.formatString(strings.lastAvailableCheckOutDateExplanation, formatShortDate(booking.subscriptionInfo.earliestPossibleEndDate, appContext));
    }

    return (
        <>
            <BookingDetailsSectionCaption>
                {title}
            </BookingDetailsSectionCaption>
            <Text html={message} />
        </>
    );
};

SubscriptionBookingEndingInfo.propTypes = {
    booking: PropTypes.object.isRequired
};

export default SubscriptionBookingEndingInfo;
