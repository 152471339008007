import React from 'react';
import strings from 'localization/strings';
import { useScrollToTop } from 'helpers/BrowserHelper';

import PageTitle from 'common/PageTitle';
import Container from 'common/Container';
import Text from 'common/Text';

const StoringPolicyPage = () => {
    useScrollToTop();

    return (
        <Container withPageMargins>
            <PageTitle>{strings.storagePolicies}</PageTitle>
            <Text component="div" html={strings.pages.storingPolicy} ignoreNewlines />
        </Container>
    );
};

export default StoringPolicyPage;
