import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from 'styles/util';

import Box from '@material-ui/core/Box';

const MarginBox = ({ top = 0, bottom = 0, left = 0, right = 0, className, children }) => {
    const useStyles = makeStyles(({ theme }) => ({
        marginBox: {
            marginTop: theme.spacing(top),
            marginBottom: theme.spacing(bottom),
            marginLeft: theme.spacing(left),
            marginRight: theme.spacing(right)
        }
    }));
    const classes = useStyles();

    const appliedClassName = classes.marginBox + (className ? ' ' + className : '');

    return (
        <Box className={appliedClassName}>
            {children}
        </Box>
    );
};

MarginBox.propTypes = {
    top: PropTypes.number,
    bottom: PropTypes.number,
    left: PropTypes.number,
    right: PropTypes.number,
    className: PropTypes.string,
    children: PropTypes.node.isRequired
};

export default MarginBox;
