import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { handleResponse } from 'actions/actionHelpers';
import { createMockTemporaryData } from 'actions/authentication';
import { addQuerystringParametersToUrl } from 'helpers/BrowserHelper';
import { makeStyles } from 'styles/util';

import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import MuiTextField from '@material-ui/core/TextField';
import Loader from 'common/Loader';

const useStyles = makeStyles(({ theme }) => ({
    container: {
        display: 'flex',
        flexDirection: 'column',
        gap: theme.spacing(1),
        border: '2px solid red',
        padding: theme.spacing(2)
    }
}));

const MockEidLogin = ({ redirectUrl, clientState }) => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const history = useHistory();

    const [ isLoading, setIsLoading ] = useState(false);
    const [ personalIdentity, setPersonalIdentity ] = useState('');

    const onPersonalIdentityChange = event => {
        setPersonalIdentity(event.target.value);
    };

    const handleLoginButtonClick = () => {
        setIsLoading(true);
        dispatch(createMockTemporaryData({ personalIdentity, clientState }))
            .then(handleResponse(
                response => history.push(addQuerystringParametersToUrl(redirectUrl, { eidLoginResult: 'success', eidTemporaryDataToken: response.payload.eidTemporaryDataToken } ))
            ));
    };

    return (
        <>
            {
                isLoading && <Loader />
            }
            {
                !isLoading &&
                (
                    <Box className={classes.container}>
                        <MuiTextField
                            variant="outlined"
                            label="Mock personal identity"
                            value={personalIdentity}
                            onChange={onPersonalIdentityChange}
                        />

                        <Button
                            type="button"
                            color="primary"
                            variant="contained"
                            onClick={handleLoginButtonClick}
                        >
                            Log in using mock personal identity
                        </Button>
                    </Box>
                )
            }
        </>
    );
};

MockEidLogin.propTypes = {
    createActorIfNotFound: PropTypes.bool,
    redirectUrl: PropTypes.string,
    clientState: PropTypes.object
};

export default MockEidLogin;
