import React from 'react';
import PropTypes from 'prop-types';
import strings from 'localization/strings';
import { makeStyles } from 'styles/util';

import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import PriceSpecificationTable from 'common/PriceSpecificationTable';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Amount from 'common/Amount';

const useStyles = makeStyles(() => ({
    accordion: {
        border: 'none',
        '&:before': {
            backgroundColor: 'transparent'
        }
    },
    accordionSummary: {
        padding: 0
    },
    accordionDetails: {
        paddingLeft: 0,
        paddingRight: 0
    },
    accordionDetailsInner: {
        width: '100%'
    }
}));

const PriceSpecificationAccordion = ({ booking }) => {
    const classes = useStyles();

    const showVat = [
        ...(booking.priceInfo.oneTime?.purchaseItems ?? []),
        ...(booking.priceInfo.perMonth?.purchaseItems ?? []),
        ...(booking.priceInfo.wholePeriod?.purchaseItems ?? [])
    ].filter(o => o.vat).length > 0;

    const getAmount = amounts => amounts.totalAmount + amounts.totalVat + amounts.roundingCorrection;

    const getOneTimeCostLabel = amounts => getAmount(amounts) < 0
        ? strings.discountOneTime
        : strings.costOneTime;

    const getOneTimePaymentSpecificationLabel = amounts => getAmount(amounts) < 0
        ? strings.bookingOneTimeDiscountSpecification
        : strings.bookingOneTimePaymentSpecification;

    const hasOneTimePurchaseItems = booking.priceInfo.oneTime.purchaseItems.length > 0;

    let title;
    if (booking.subscriptionBooking) {
        title = (
            <>
                <Typography gutterBottom={hasOneTimePurchaseItems}>
                    {strings.costPerMonth}: <Amount value={getAmount(booking.priceInfo.perMonth.amounts)} currency={booking.priceInfo.currency} displayPriceRounding />
                </Typography>
                {
                    hasOneTimePurchaseItems &&
                    (
                        <Typography>
                            {getOneTimeCostLabel(booking.priceInfo.oneTime.amounts)}: <Amount value={getAmount(booking.priceInfo.oneTime.amounts)} currency={booking.priceInfo.currency} displayPriceRounding />
                        </Typography>
                    )
                }
            </>
        );
    } else {
        title = (
            <Typography>
                {strings.cost}: <Amount value={getAmount(booking.priceInfo.wholePeriod.amounts)} currency={booking.priceInfo.currency} displayPriceRounding />
            </Typography>
        );
        }

    return (
        <Accordion variant="outlined" className={classes.accordion}>
            <AccordionSummary className={classes.accordionSummary} expandIcon={<ExpandMoreIcon />}>
                <Box>
                    {title}
                    {
                        booking.priceInfo.hasAdditionalServicesWithUnknownPrice && <Typography>{strings.hasAdditionalServicesWithUnknownPrice}</Typography>
                    }
                </Box>
            </AccordionSummary>
            <AccordionDetails className={classes.accordionDetails}>
                <Box className={classes.accordionDetailsInner}>
                    {
                        booking.subscriptionBooking &&
                        (
                            <>
                                <PriceSpecificationTable booking={booking} purchaseItems={booking.priceInfo.perMonth.purchaseItems} showVat={showVat} caption={hasOneTimePurchaseItems ? strings.bookingMonthlyPaymentSpecification : undefined} />
                                {
                                    hasOneTimePurchaseItems &&
                                    (
                                        <PriceSpecificationTable booking={booking} purchaseItems={booking.priceInfo.oneTime.purchaseItems} showVat={showVat} caption={getOneTimePaymentSpecificationLabel(booking.priceInfo.oneTime.amounts)} />
                                    )
                                }
                            </>
                        )
                    }

                    {
                        !booking.subscriptionBooking &&
                        (
                            <PriceSpecificationTable booking={booking} purchaseItems={booking.priceInfo.wholePeriod.purchaseItems} showVat={showVat} />
                        )
                    }
                </Box>
            </AccordionDetails>
        </Accordion>
    );
};

PriceSpecificationAccordion.propTypes = {
    booking: PropTypes.object.isRequired,
    paymentProvider: PropTypes.string,
    options: PropTypes.object
};

export default PriceSpecificationAccordion;
