import strings from 'localization/strings';

const storageOrderBys = {
    weightedDistance: {},
    pricePerStorage: {},
    pricePerAreaUnit: {},
    createdTime: {},
    area: {}
};

Object.keys(storageOrderBys).forEach(key => {
    storageOrderBys[key].key = key;
});

strings.addLoadCallback(() => {
    Object.values(storageOrderBys).forEach(o => {
        o.title = strings.storageOrderBys[o.key];
    });
});

export default storageOrderBys;
