import React from 'react';
import PropTypes from 'prop-types';
import strings from 'localization/strings';
import storageGroupCategories from 'enums/storageGroupCategories';
import { makeStyles } from 'styles/util';
import goodsTypes from 'enums/goodsTypes';

import Box from '@material-ui/core/Box';
import Text from 'common/Text';
import BookingDetailsSectionCaption from './BookingDetailsSectionCaption';
import LinkButton from 'common/LinkButton';

const useStyles = makeStyles(({ theme }) => ({
    goodsType: {
        marginTop: theme.spacing(1)
    },
    registrationNumber: {
        marginTop: theme.spacing(1)
    },
    changeBooking: {
        paddingLeft: theme.spacing(2)
    }
}));

const BookingDescription = ({ booking, onChangeBooking }) => {
    const classes = useStyles();
    const title = storageGroupCategories[booking.storageGroup.category].goodsTitle ?? strings.description;

    return (
        <Box>
            <BookingDetailsSectionCaption>
                {title}
            </BookingDetailsSectionCaption>
            {
                booking.description &&
                (
                    <Box>
                        <Text text={booking.description}/>
                    </Box>
                )
            }
            {
                booking.goodsType &&
                (
                    <Box className={classes.goodsType}>
                        {strings.vehicleType}: {goodsTypes[booking.goodsType].title}
                        <LinkButton className={classes.changeBooking} onClick={onChangeBooking}>
                            {strings.change}
                        </LinkButton>
                    </Box>
                )
            }
            {
                booking.registrationNumber &&
                (
                    <Box className={classes.registrationNumber}>
                        {strings.registrationNumber}: {booking.registrationNumber}
                        <LinkButton className={classes.changeBooking} onClick={onChangeBooking}>
                            {strings.change}
                        </LinkButton>
                    </Box>
                )
            }
        </Box>
    );
};

BookingDescription.propTypes = {
    booking: PropTypes.object.isRequired,
    onChangeBooking: PropTypes.func.isRequired
};

export default BookingDescription;
