import React, { useEffect, useState } from 'react';
import { makeStyles } from 'styles/util';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { useParams } from 'react-router';
import { fetchUserInvitation } from 'actions/userInvitations';
import { handleResponse } from 'actions/actionHelpers';
import userInvitationStatuses from 'enums/userInvitationStatuses';
import strings from 'localization/strings';
import routes from 'routes';
import { addQuerystringParametersToUrl } from 'helpers/BrowserHelper';
import { performAcceptUserInvitation } from 'logic/authenticationLogic';

import MuiContainer from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';
import ContentBox from 'common/ContentBox';
import Loader from 'common/Loader';
import PageTitle from 'common/PageTitle';
import Alert from '@material-ui/lab/Alert';
import Form from 'form/Form';
import { Radios } from 'mui-rff';
import Box from '@material-ui/core/Box';
import CircularProgressButton from 'common/CircularProgressButton';

const useStyles = makeStyles(({ theme, fonts }) => ({
    pageWrapper: {
        display: 'flex',
        flexDirection: 'column',
        gap: theme.spacing(3.75),
        marginTop: theme.spacing(10),
        marginBottom: theme.spacing(3.75),
        [theme.breakpoints.down('sm')]: {
            marginTop: theme.spacing(2.5)
        }
    },
    form: {
        display: 'flex',
        flexDirection: 'column',
        gap: theme.spacing(3.75)
    },
    button: {
        alignSelf: 'start'
    },
    caption: {
        fontFamily: fonts.bold
    }
}));

// this is the page where an user that was invited to a business actor account accepts the invitation
const AcceptUserInvitationPage = () => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const history = useHistory();
    const { userInvitationId, token } = useParams();

    const { isAuthenticated, user } = useSelector(state => state.authentication.context);
    const { isLoadingAuthenticationContext } = useSelector(state => state.authentication.loading);

    const [userInvitation, setUserInvitation] = useState(undefined);
    const [isLoading, setIsLoading] = useState(true);
    const [accountModeSelected, setAccountModeSelected] = useState(false);

    const accountModes = {
        authenticated: { enabled: isAuthenticated },
        another: { enabled: isAuthenticated},
        existing: { enabled: !isAuthenticated },
        new: { enabled: true }
    };

    const propertiesToCopy = ['radioLabel', 'submitLabel'];
    Object.keys(accountModes).forEach(key => {
        accountModes[key].key = key;
        propertiesToCopy.forEach(p => {
            accountModes[key][p] = strings.accountModes[key][p];
        });
    });

    if(isAuthenticated) {
        accountModes.authenticated.radioLabel = strings.formatString(accountModes.authenticated.radioLabel, user.name);
        accountModes.authenticated.submitLabel = strings.formatString(accountModes.authenticated.submitLabel, user.name);
    }

    const accountModeOptions = Object.values(accountModes)
        .filter(o => o.enabled)
        .map(o => ({
            value: o.key,
            label: o.radioLabel
        }));

    useEffect(() => {
        dispatch(fetchUserInvitation(userInvitationId, token))
            .then(handleResponse(
                response => {
                    setUserInvitation({
                        ...response.payload,
                        id: userInvitationId,
                        token
                    });
                    setIsLoading(false);
                },
                () => {
                    setIsLoading(false);
                    return {
                        dialog: {
                            body: strings.authentication.invalidUserInvitation,
                            onClose: () => history.push(routes.index)
                        }
                    };
                }
            ));
    }, [userInvitationId, token]);

    const handleFormSubmit = values => {
        const parameters = {
            userInvitationId,
            userInvitationToken: token
        };
        switch(values.accountMode) {
            case accountModes.authenticated.key:
                setAccountModeSelected(true);
                performAcceptUserInvitation({
                    userInvitation,
                    setIsLoading,
                    dispatch,
                    history,
                    onFailure: () => setAccountModeSelected(false)
                });
                break;
            case accountModes.another.key:
            case accountModes.existing.key:
                history.push(addQuerystringParametersToUrl(routes.eidLogIn, parameters));
                break;
            case accountModes.new.key:
                // redirect to create private account flow, although it is just a private user that should be created
                history.push(addQuerystringParametersToUrl(routes.createAccountPrivate, parameters));
                break;
            default:
                break;
        }
    };

    const title = strings.authentication.acceptUserInvitationTitle;

    const initialValues = {
        accountMode: null
    };

    return (
        <>
            <PageTitle>{title}</PageTitle>
            <MuiContainer maxWidth={false}>
                <ContentBox className={classes.pageWrapper} themeMaxWidth="xs">
                    <Typography variant="h2">
                        {title}
                    </Typography>

                    {
                        (isLoading || isLoadingAuthenticationContext) &&
                        (
                            <Loader />
                        )
                    }

                    {
                        !(isLoading || isLoadingAuthenticationContext) && userInvitation?.status === userInvitationStatuses.accepted.key && // the invitation has already been accepted, show error message
                        (
                            <Alert severity="error">
                                {strings.authentication.userInvitationAlreadyAccepted}
                            </Alert>
                        )
                    }

                    {
                        !(isLoading || isLoadingAuthenticationContext) && userInvitation?.status === userInvitationStatuses.pending.key && // the invitation is pending, let the user accept it
                        (
                            <Form
                                initialValues={initialValues}
                                onSubmit={handleFormSubmit}
                            >
                                {({ values, handleSubmit }) => (
                                    <form onSubmit={handleSubmit} className={classes.form}>
                                        <Typography variant="body1">
                                            {strings.formatString(strings.authentication.acceptUserInvitationBody, userInvitation.actorName, userInvitation.invitedByUserName)}
                                        </Typography>

                                        <Box>
                                            <Typography variant="body1" className={classes.caption} gutterBottom>
                                                {strings.selectAccountMode}
                                            </Typography>

                                            <Radios
                                                name="accountMode"
                                                data={accountModeOptions}
                                                disabled={accountModeSelected}
                                            />
                                        </Box>

                                        {
                                            values.accountMode &&
                                            (
                                                <CircularProgressButton
                                                    type="submit"
                                                    className={classes.button}
                                                    isLoading={accountModeSelected}
                                                >
                                                    {accountModes[values.accountMode]?.submitLabel}
                                                </CircularProgressButton>
                                            )
                                        }
                                    </form>
                                )}
                            </Form>
                        )
                    }
                </ContentBox>
            </MuiContainer>
        </>
    );
};

export default AcceptUserInvitationPage;
