import React from 'react';
import PropTypes from 'prop-types';
import { useAppContext } from 'context/AppContext';
import { getShortDateFormat } from 'helpers/DateHelper';
import { required } from 'form/validation';
import { add, startOfDay } from 'date-fns';
import { changeValueMutator } from 'helpers/FormHelper';

import AdminFormWrapper from '../../presentational/AdminFormWrapper';
import AdminFormFields from '../../presentational/AdminFormFields';
import AdminFormButtons from '../../presentational/AdminFormButtons';
import Form from 'form/Form';
import Button from '@material-ui/core/Button';
import { TextField, showErrorOnBlur } from 'mui-rff';
import Autocomplete from '@material-ui/lab/Autocomplete';
import MenuItem from '@material-ui/core/MenuItem';
import DatePicker from 'form/DatePicker';
import Grid from '@material-ui/core/Grid';

const ownerFilterItems = {
    private: {
        title: 'Private'
    },
    business: {
        title: 'Business'
    },
    certifiedPartner: {
        title: 'Certified partner'
    }
};
Object.keys(ownerFilterItems).forEach(key => {
    ownerFilterItems[key].key = key;
});

const CapacityUtilizationForm = ({ regions, ownerActors, onSubmit, isSubmitting = false }) => {
    const { appContext } = useAppContext();

    const initialValues = {
        regionId: 'all',
        storageGroupCategory: 'all',
        ownerFilter: 'all',
        ownerActors: [],
        fromDate: startOfDay(add(add(new Date(), { years: -1 }), { days: 1 })),
        toDate: startOfDay(new Date())
    };

    const handleFormSubmit = formValues => {
        onSubmit({
            fromDate: formValues.fromDate,
            toDate: formValues.toDate,
            storageSiteId: formValues.storageSiteId,
            regionId: allToUndefined(formValues.regionId),
            ownerActorIds: formValues.ownerActors.map(o => o.id),
            storageGroupCategory: allToUndefined(formValues.storageGroupCategory),
            ownerFilter: allToUndefined(formValues.ownerFilter),
        });
    };

   const addAllAsFirstItem = items => [{ key: 'all', title: '[all]' }].concat(items);

   const allToUndefined = value => value === 'all' ? undefined : value;

   return (
        <AdminFormWrapper isSubmitting={isSubmitting}>
            <Form
                initialValues={initialValues}
                onSubmit={handleFormSubmit}
                mutators={{
                    changeValue: changeValueMutator
                }}
            >
                {({ handleSubmit, values, form }) => {
                    const changeValue = (name, newValue) => form.mutators.changeValue({ name, value: newValue });
                    return (
                        <form onSubmit={handleSubmit}>
                            <AdminFormFields>

                                <Grid container spacing={2}>
                                    <Grid item sm={6} xs={12}>
                                        <TextField
                                            select
                                            name="regionId"
                                            label="Region"
                                            variant="outlined"
                                        >
                                            {
                                                addAllAsFirstItem(regions.map(o => ({ key: o.id, title: o.name }))).map(item =>
                                                    <MenuItem key={item.key ?? 0} value={item.key}>{item.title}</MenuItem>
                                                )
                                            }
                                        </TextField>
                                    </Grid>

                                    <Grid item sm={6} xs={12}>
                                        <TextField
                                            select
                                            name="storageGroupCategory"
                                            label="Category"
                                            variant="outlined"
                                        >
                                            {
                                                addAllAsFirstItem(appContext.storageGroupCategories.map(o => ({ key: o.key, title: o.key }))).map(item =>
                                                    <MenuItem key={item.key ?? 0} value={item.key}>{item.title}</MenuItem>
                                                )
                                            }
                                        </TextField>
                                    </Grid>

                                    <Grid item sm={6} xs={12}>
                                        <TextField
                                            select
                                            name="ownerFilter"
                                            label="Owner type"
                                            variant="outlined"
                                        >
                                            {
                                                addAllAsFirstItem(Object.values(ownerFilterItems)).map(item =>
                                                    <MenuItem key={item.key ?? 0} value={item.key}>{item.title}</MenuItem>
                                                )
                                            }
                                        </TextField>
                                    </Grid>

                                    <Grid item sm={6} xs={12}>
                                        <Autocomplete
                                            multiple
                                            value={values.ownerActorIds}
                                            onChange={(event, newValues) => {
                                                changeValue('ownerActors', newValues);
                                            }}
                                            options={ownerActors}
                                            getOptionLabel={option => option.name}
                                            renderInput={params =>
                                                <TextField
                                                    {...params}
                                                    name="ownerActors"
                                                    label="Owner"
                                                    variant="outlined"
                                                />
                                            }
                                        />
                                    </Grid>

                                    <Grid item sm={6} xs={12}>
                                        <DatePicker
                                            name="fromDate"
                                            label="From date"
                                            formatFunc={getShortDateFormat}
                                            inputVariant="outlined"
                                            showError={showErrorOnBlur}
                                            fieldProps={{ validate: required }}
                                        />
                                    </Grid>

                                    <Grid item sm={6} xs={12}>
                                        <DatePicker
                                            name="toDate"
                                            label="To date"
                                            formatFunc={getShortDateFormat}
                                            inputVariant="outlined"
                                            showError={showErrorOnBlur}
                                            fieldProps={{ validate: required }}
                                        />
                                    </Grid>
                                </Grid>
                            </AdminFormFields>

                            <AdminFormButtons>
                                <Button
                                    type="submit"
                                    variant="contained"
                                    color="primary"
                                >
                                    Show
                                </Button>
                            </AdminFormButtons>
                        </form>
                    );
                }}
            </Form>
        </AdminFormWrapper>
    );
};

CapacityUtilizationForm.propTypes = {
    regions: PropTypes.array.isRequired,
    ownerActors: PropTypes.array.isRequired,
    onSubmit: PropTypes.func.isRequired,
    isSubmitting: PropTypes.bool
};

export default CapacityUtilizationForm;
