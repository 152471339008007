import React from 'react';
import PropTypes from 'prop-types';
import strings from 'localization/strings';
import { makeStyles } from 'styles/util';

import Box from '@material-ui/core/Box';
import Amount from 'common/Amount';

const useStyles = makeStyles(() => ({
    footer: {
        width: '100%',
        '& td': {
            verticalAlign: 'top'
        }
    },
    itemTitle: {
        fontWeight: 'bold'
    },
    fieldTotalValue: {
        fontSize: '16px'
    },
    right: {
        textAlign: 'right'
    }
}));

const TenantFooter = ({ purchase }) => {
    const classes = useStyles();

    return (
        <table className={classes.footer}>
            <tbody>
                <tr>
                    <td colSpan={3} className={classes.right}>
                        <Box className={classes.itemTitle + ' ' + classes.fieldTotalValue}>
                            {strings.paymentDocuments.totalPaid}
                        </Box>
                        <Box className={classes.fieldTotalValue}>
                            <Amount value={purchase.amount} currency={purchase.currency} displayPriceRounding={false} />
                        </Box>
                    </td>
                </tr>
            </tbody>
        </table>
    );
};

TenantFooter.propTypes = {
    purchase: PropTypes.object.isRequired
};

export default TenantFooter;
