import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from 'styles/util';
import useMediaQuery from '@material-ui/core/useMediaQuery';

import Box from '@material-ui/core/Box';

const TwoColumnButtonTabs = ({ buttonData, onButtonClick }) => {
    const isMobile = !useMediaQuery(theme => theme.breakpoints.up('md'));
    const useStyles = makeStyles(({ theme, colors }) => ({
        twoColumnButtonTabs: {
            display: 'flex',
            marginLeft: '-15px',
            marginRight: '-15px',
            marginBottom: theme.spacing(2)
        },
        buttonHolder: {
            flex: '0 0 50%',
            textAlign: 'center'
        },
        button: {
            cursor: 'pointer',
            margin: '0 15px',
            paddingTop: isMobile ? '15px' : '27px',
            paddingBottom: isMobile ? '15px' : '27px',
            borderRadius: '3px',
            border: `3px solid ${colors.primaryColor}`,
            color: colors.black,
            backgroundColor: colors.white,

            '&:hover': {
                boxShadow: '0 2px 13px 0 rgba(0, 0, 0, 0.22)',
                '&:not(.active)': {
                    backgroundColor: colors.whiteHover
                }
            },

            '&.active': {
                backgroundColor: colors.primaryColor,
                color: colors.white,
            },

            '&.blue': {
                borderColor: colors.secondaryColor,
                color: colors.secondaryColor,
                '&.active': {
                    backgroundColor: colors.secondaryColor,
                    color: colors.white
                }
            }
        }
    }));
    const classes = useStyles();

    const getButton = (button) => {
        let buttonClassName = classes.button;
        if (button.isBlue) {
            buttonClassName += ' blue';
        }
        if (button.isActive) {
            buttonClassName += ' active';
        }
        return (
            <Box className={classes.buttonHolder}>
                <Box className={buttonClassName} onClick={() => onButtonClick(button)}>
                    {button.text}
                </Box>
            </Box>
        );
    };

    return (
        <Box className={classes.twoColumnButtonTabs}>
            {getButton(buttonData.left)}
            {getButton(buttonData.right)}
        </Box>
    );
};

TwoColumnButtonTabs.propTypes = {
    buttonData: PropTypes.object,
    onButtonClick: PropTypes.func,
};

export default TwoColumnButtonTabs;
