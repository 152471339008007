import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from 'styles/util';

import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';

const useStyles = makeStyles(({ theme, colors }) => ({
    backdrop: {
        zIndex: theme.zIndex.drawer + 1
    },
    circularProgress: {
        color: colors.white
    }
}));

const FullScreenLoader = ({ open }) => {
    const classes = useStyles();

    return (
        <Backdrop className={classes.backdrop} open={open}>
            <CircularProgress
                className={classes.circularProgress}
                size={70}
            />
        </Backdrop>
    );
};

FullScreenLoader.propTypes = {
    open: PropTypes.bool.isRequired
};

export default FullScreenLoader;
