import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from 'styles/util';

import Grid from '@material-ui/core/Grid';

const GridSeparator = ({ className, verticalSpacing = 1, horizontalSpacing = 1, topSpacing = undefined, bottomSpacing = undefined, leftSpacing = undefined, rightSpacing = undefined }) => {
    const useStyles = makeStyles(({ theme, colors }) => ({
        gridSeparator: {
            borderBottom: `1.5px solid ${colors.mediumGrey}`,
            margin: theme.spacing(topSpacing ?? verticalSpacing, rightSpacing ?? horizontalSpacing, bottomSpacing ?? verticalSpacing, leftSpacing ?? horizontalSpacing),
            paddingTop: '0 !important',
            paddingBottom: '0 !important',
        }
    }));
    const classes = useStyles();

    return <Grid item xs={12} className={classes.gridSeparator + (className ? ' ' + className : '')}/>;
};

GridSeparator.propTypes = {
    className: PropTypes.string,
    verticalSpacing: PropTypes.number,
    horizontalSpacing: PropTypes.number,
    topSpacing: PropTypes.number,
    bottomSpacing: PropTypes.number,
    leftSpacing: PropTypes.number,
    rightSpacing: PropTypes.number,
};

export default GridSeparator;
