import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { handleResponse } from 'actions/actionHelpers';
import { makeStyles } from 'styles/util';
import routes from 'routes';
import { getBusinessOrPrivateName } from 'helpers/ActorHelper';

import PropTypes from 'prop-types';
import AdminFormWrapper from '../../presentational/AdminFormWrapper';
import AdminFormFields from '../../presentational/AdminFormFields';
import AdminFormButtons from '../../presentational/AdminFormButtons';
import ReadOnlyField from '../../presentational/ReadOnlyField';
import { Link, useHistory } from 'react-router-dom';
import Button from '@material-ui/core/Button';
import { logIn } from 'actions/authentication';
import Form from 'form/Form';
import MaterialUiLink from '@material-ui/core/Link';
import AdminActorSelectorDialog from 'components/adminActorSelector/AdminActorSelectorDialog';
import LinkButton from 'common/LinkButton';

const useStyles = makeStyles(({ theme }) => ({
    ownerActorName: {
        paddingRight: theme.spacing(1)
    }
}));

const EditStorageSiteForm = ({ storageSite, onSubmit, onCancel, isSubmitting = false }) => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const history = useHistory();

    const [ownerActor, setOwnerActor] = useState({
        id: storageSite.ownerActorId,
        name: storageSite.ownerName
    });
    const [actorSelectorDialogOpen, setActorSelectorDialogOpen] = useState(false);

    const initialValues = {
        ownerActorId: storageSite.ownerActorId
    };

    const handleEditClick = editStorageSite => {
        dispatch(logIn({
            impersonationActorId: editStorageSite.ownerActorId
        }))
        .then(handleResponse(
            () => history.push(routes.account.editStorageSite.replace(':storageSiteId', editStorageSite.id))
        ));
    };

    const handleFormSubmit = formValues => {
        onSubmit({
            ...formValues
        });
    };

    const handleActorSelectorDialogClose = () => {
        setActorSelectorDialogOpen(false);
    };

    const handleChangeTenantActorClick = () => {
        setActorSelectorDialogOpen(true);
    };

    return (
        <AdminFormWrapper isSubmitting={isSubmitting}>
            <Form
                initialValues={initialValues}
                onSubmit={handleFormSubmit}
            >
                {({ form, handleSubmit }) => {
                    const handleActorSelectorDialogOk = actor => {
                        setOwnerActor(actor);
                        form.change('ownerActorId', actor.id);
                        setActorSelectorDialogOpen(false);
                    };

                    return (
                        <form onSubmit={handleSubmit}>
                            <AdminFormFields>

                                <ReadOnlyField label="Storage site">
                                    <MaterialUiLink
                                        component="button"
                                        onClick={() => handleEditClick(storageSite)}
                                    >
                                        {storageSite.title}
                                    </MaterialUiLink>
                                </ReadOnlyField>

                                <ReadOnlyField label="Owner">
                                    <Link className={classes.ownerActorName} to={routes.admin.actor.replace(':actorId', ownerActor.id)}>{getBusinessOrPrivateName(ownerActor)}</Link>
                                    <LinkButton onClick={handleChangeTenantActorClick}>Change</LinkButton>
                                </ReadOnlyField>

                            </AdminFormFields>

                            <AdminFormButtons>
                                <Button
                                    type="submit"
                                    variant="contained"
                                    color="primary"
                                >
                                    Save
                                </Button>
                                <Button
                                    type="button"
                                    variant="outlined"
                                    color="primary"
                                    onClick={onCancel}
                                >
                                    Cancel
                                </Button>
                            </AdminFormButtons>

                            <AdminActorSelectorDialog
                                ownersOnly
                                title="Select new owner"
                                onOk={handleActorSelectorDialogOk}
                                onClose={handleActorSelectorDialogClose}
                                open={actorSelectorDialogOpen}
                            />
                        </form>
                    );
                }}
            </Form>
        </AdminFormWrapper>
    );
};

EditStorageSiteForm.propTypes = {
    storageSite: PropTypes.object.isRequired,
    isSubmitting: PropTypes.bool,
    onSubmit: PropTypes.func.isRequired,
    onCancel: PropTypes.func.isRequired
};

export default EditStorageSiteForm;
