const paymentStatuses = {
    failed: {},
    succeeded: {},
    refunded: {},
};

Object.keys(paymentStatuses).forEach(key => {
    paymentStatuses[key].key = key;
});

export default paymentStatuses;
