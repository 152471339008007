import React from 'react';
import PropTypes from 'prop-types';
import strings from 'localization/strings';
import cancellationReasons from 'enums/cancellationReasons';
import actorTypes from 'enums/actorTypes';

import Form from 'form/Form';
import { TextField } from 'mui-rff';
import MenuItem from '@material-ui/core/MenuItem';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';

const initialValues = {
    cancellationReason: undefined,
    cancellationReasonText: undefined
};

const RejectDialog = ({ open, onOk, onCancel, isOwner = false }) => {
    const handleFormSubmit = values => {
        onOk(values);
    };

    const selectedActorType = isOwner
        ? actorTypes.owner
        : actorTypes.tenant;
    const availableCancellationReasons = Object.values(cancellationReasons)
        .filter(o => o.availableFor.filter(af => af === selectedActorType).length > 0);

    return (
        <Dialog fullWidth maxWidth="md" open={open} onClose={onCancel}>
            <Form
                initialValues={initialValues}
                onSubmit={handleFormSubmit}
            >
                {({ handleSubmit, values }) => {
                    const disabled =
                        !values.cancellationReason ||
                        (values.cancellationReason === cancellationReasons.other.key && !values.cancellationReasonText);
                    return (
                        <form onSubmit={handleSubmit}>
                            <DialogTitle disableTypography>
                                <Typography variant="h5">
                                    {strings.rejectDialogTitle}
                                </Typography>
                            </DialogTitle>
                            <DialogContent>
                                <Typography variant="body1" gutterBottom>
                                    {isOwner ? strings.ownerRejectDialogBody : strings.tenantRejectDialogBody}
                                </Typography>

                                <TextField
                                    select
                                    name="cancellationReason"
                                    label={strings.cancellationReasonPlaceholder}
                                    variant="outlined"
                                >
                                    {
                                        availableCancellationReasons.map(o => (
                                            <MenuItem key={o.key} value={o.key}>{o.title}</MenuItem>
                                        ))
                                    }
                                </TextField>

                                {
                                    values.cancellationReason === cancellationReasons.other.key &&
                                    (
                                        <TextField
                                            name="cancellationReasonText"
                                            variant="outlined"
                                            maxLength={1024}
                                            multiline
                                            rows={3}
                                        />
                                    )
                                }
                            </DialogContent>
                            <DialogActions>
                                <Button
                                    variant="contained"
                                    color="primary"
                                    type="submit"
                                    disabled={disabled}
                                >
                                    {strings.reject}
                                </Button>
                                <Button
                                    variant="outlined"
                                    color="primary"
                                    onClick={onCancel}
                                >
                                    {strings.cancel}
                                </Button>
                            </DialogActions>
                        </form>
                    );
                }}
            </Form>
        </Dialog>
    );
};

RejectDialog.propTypes = {
    open: PropTypes.bool.isRequired,
    onOk: PropTypes.func.isRequired,
    onCancel: PropTypes.func.isRequired,
    isOwner: PropTypes.bool
};

export default RejectDialog;
