import { setRedirectUrl, logOut } from 'actions/authentication';
import { createBrowserHistory } from 'history';
import routes from 'routes';

const apiErrorMiddleware = store => next => action => {
    if (action.payload && action.payload.name === 'ApiError') {
        if (action.payload.status === 401) {
            if (action.error) {
                if (store.getState().authentication.token) {
                    const history = createBrowserHistory();
                    const relativeUrl = history.location.pathname + history.location.search;
                    store.dispatch(logOut());
                    store.dispatch(setRedirectUrl(relativeUrl));
                    history.push(routes.logIn);
                }
            }
        }
    }

    return next(action);
};

export default apiErrorMiddleware;
