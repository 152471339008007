import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { handleResponse } from 'actions/actionHelpers';
import arrayMutators from 'final-form-arrays';
import storageGroupCategories from 'enums/storageGroupCategories';
import { fetchSeoGeoItems, updateSeoGeoItems } from 'actions/seo';

import PageTitle from 'common/PageTitle';
import PasswordProtector from 'common/PasswordProtector';
import ShadowBox from 'common/ShadowBox';
import Loader from 'common/Loader';
import Container from 'common/Container';
import Form from 'form/Form';
import Button from '@material-ui/core/Button';
import ButtonContainer from 'common/ButtonContainer';
import { FieldArray } from 'react-final-form-arrays';
import { OnChange } from 'react-final-form-listeners';
import { TextField } from 'mui-rff';
import MenuItem from '@material-ui/core/MenuItem';
import SeoGeoItemEditor from './SeoGeoItemEditor';

const SeoGeoItemsEditorPage = () => {
    const dispatch = useDispatch();

    const [isLoading, setIsLoading] = useState(true);
    const [initialValues, setInitialValues] = useState();

    useEffect(() => {
        fetchData(storageGroupCategories.storeHouse.key);
    }, []);

    const fetchData = storageGroupCategory => {
        setIsLoading(true);
        dispatch(fetchSeoGeoItems(storageGroupCategory))
            .then(handleResponse(
                response => {
                    setInitialValues({ storageGroupCategory, items: [ ...response.payload, createNewItem() ] });
                    setIsLoading(false);
                },
                () => setIsLoading(false)
            ));
    };

    const isEmptyItem = item => !item.location && !item.h1 && !item.title && !item.metaDescription && !item.content;

    const createNewItem = () => ({
        location: null,
        h1: null,
        title: null,
        metaDescription: null,
        content: null
    });

    const removeEmptyItems = items => items.filter(o => !isEmptyItem(o));

    const handleRemoveItem = (fields, index) => {
        fields.remove(index);
    };

    const handleItemBlur = (fields, index) => {
        if(fields.length - 1 === index && !isEmptyItem(fields.value[index])) {
            fields.push(createNewItem());
        }
    };

    const handleFormSubmit = values => {
        setIsLoading(true);
        setInitialValues(values);
        dispatch(updateSeoGeoItems(values.storageGroupCategory, removeEmptyItems(values.items)))
            .then(handleResponse(
                () => {
                    setIsLoading(false);
                    return { snackbar: { message: 'Saved!' }};
                },
                () => setIsLoading(false)
            ));
    };

    const title = 'Edit SEO geo items';

    return (
        <Container>
            <PageTitle>{title}</PageTitle>
            <PasswordProtector passwordType="seoGeoItemsEditor">
                <ShadowBox title={title}>
                    {
                        isLoading && <Loader />
                    }
                    {
                        // logged in
                        !isLoading &&
                        (
                            <Form
                                initialValues={initialValues}
                                mutators={arrayMutators}
                                onSubmit={handleFormSubmit}
                            >
                                {({ values, handleSubmit, submitting }) => (
                                    <form onSubmit={handleSubmit}>
                                        <TextField
                                            select
                                            label="Storage group category"
                                            name="storageGroupCategory"
                                            variant="outlined"
                                        >
                                            {
                                                Object.values(storageGroupCategories)
                                                    .map(o => (
                                                        <MenuItem key={o.key} value={o.key}>
                                                            {o.title}
                                                        </MenuItem>
                                                    ))
                                            }
                                        </TextField>
                                        <OnChange name="storageGroupCategory">
                                            {() => fetchData(values.storageGroupCategory)}
                                        </OnChange>

                                        <FieldArray name="items">
                                            {({ fields }) => (
                                                fields.map((name, index) => (
                                                    <SeoGeoItemEditor
                                                        key={index}
                                                        name={name}
                                                        onRemoveItem={() => handleRemoveItem(fields, index)}
                                                        onBlur={() => handleItemBlur(fields, index)}
                                                    />
                                                ))
                                            )}
                                        </FieldArray>

                                        <ButtonContainer>
                                            <Button
                                                type="submit"
                                                color="primary"
                                                variant="contained"
                                                disabled={submitting}
                                            >
                                                Save
                                            </Button>
                                        </ButtonContainer>
                                    </form>
                                )}
                            </Form>
                        )
                    }
                </ShadowBox>
            </PasswordProtector>
        </Container>
    );
};

export default SeoGeoItemsEditorPage;
