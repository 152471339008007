import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from 'styles/util';

import Box from '@material-ui/core/Box';

const useStyles = makeStyles(({ theme, fonts }) => ({
    informationItem: {
        display: 'flex',
        margin: theme.spacing(1, 0)
    },
    informationItemTitle: {
        flex: '0 0 40%',
        fontFamily: fonts.medium
    },
    informationItemValue: {
        flex: '0 0 60%'
    }
}));

const InformationItem = ({ title, value = '-' }) => {
    const classes = useStyles();

    return (
        <Box className={classes.informationItem}>
            <Box className={classes.informationItemTitle}>{title}</Box>
            <Box className={classes.informationItemValue}>{value}</Box>
        </Box>
    );
};

InformationItem.propTypes = {
    title: PropTypes.string.isRequired,
    value: PropTypes.any
};

export default InformationItem;
