import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useAppContext } from 'context/AppContext';
import strings from 'localization/strings';
import { isDevelopmentFromWindowConfiguration } from 'helpers/EnvironmentHelper';
import { nestedMerge } from 'helpers/ObjectHelper';

import Loader from 'common/Loader';

// component loading string resources from the backend
// in test and local environment, resources in the sv.json file will override POEditor resources
const ResourceProvider = ({ children }) => {
    const [isLoading, setIsLoading] = useState(true);
    const { appContext } = useAppContext();
    const language = appContext.language;

    useEffect(() => {
            if(language) {
            fetch(`/api/resources/${appContext.frontendResourcesHash}`)
                .then(response => response.json())
                .then(resources => {
                    setStrings(mergeWithLocalResources(resources));
                });
        }
    }, [language]);

    const setStrings = resources => {
        strings.setStrings({
            [language]: resources
        }, appContext);
        setIsLoading(false);
    };

    const mergeWithLocalResources = resources => {
        if(language === 'sv' && isDevelopmentFromWindowConfiguration) {
            let localResources = {};
            try {
                // we must use a constant value here
                localResources = require('./../../app/localization/sv.json');
            } catch {
                console.log('Could not load resource file sv.json.');
            }
            transformBranchSpecificResources(localResources);
            return nestedMerge(resources, localResources);
        }
        return resources;
    };

    const transformBranchSpecificResources = resources => {
        const branchSuffix = `_${appContext.branchKey}`;
        Object.keys(resources).forEach(key => {
            const branchAgnosticKey = key.endsWith(branchSuffix)
                ? key.substring(0, key.length - branchSuffix.length)
                : key;
            resources[branchAgnosticKey] = resources[key];

            if(typeof resources[branchAgnosticKey] !== 'string') {
                transformBranchSpecificResources(resources[key]);
            }
        });
    };

    return isLoading
        ? <Loader/>
        : children;
};

ResourceProvider.propTypes = {
    children: PropTypes.node.isRequired
};

export default ResourceProvider;
