// https://ourcodeworld.com/articles/read/317/how-to-check-if-a-javascript-promise-has-been-fulfilled-rejected-or-resolved
export const makeQueryablePromise = promise => {
    // Don't modify any promise that has been already modified.
    if (promise.isFulfilled) {
        return promise;
    }

    // Set initial state
    let isPending = true;
    let isRejected = false;
    let isFulfilled = false;

    // Observe the promise, saving the fulfillment in a closure scope.
    const result = promise.then(
        v => {
            isFulfilled = true;
            isPending = false;
            return v;
        },
        e => {
            isRejected = true;
            isPending = false;
            throw e;
        }
    );

    result.isFulfilled = () => isFulfilled;
    result.isPending = () => isPending;
    result.isRejected = () => isRejected;
    return result;
};
