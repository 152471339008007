import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from 'styles/util';

import Box from '@material-ui/core/Box';
import Text from 'common/Text';
import { Accordion, AccordionDetails, AccordionSummary, Typography } from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';


const ExpandableTitleDescriptionList = ({ items = [] }) => {
    const useStyles = makeStyles(({ theme, colors, fonts }) => ({
        accordion: {
            borderBottom: `1px solid ${theme.palette.divider}`,
            padding: theme.spacing(2.5, 0),
            '&:before': {
                display: 'none',
            }
        },
        accordionHeading: {
            fontFamily: fonts.bold
        },
        accordionSummary: {
            padding: 0,
            minHeight: '24px',
            '& .MuiAccordionSummary-content': {
                margin: 0
            },
            '& .MuiIconButton-root': {
                padding: 0
            },
            '& .MuiIconButton-edgeEnd': {
                margin: 0,
            },
            '& .MuiSvgIcon-root': {
                width: theme.spacing(3),
                height: theme.spacing(3),
                color: colors.black
            },
            '& p': {
                fontSize: '20px',
                color: colors.black,
                fontStyle: 'normal',
                fontWeight: 'bold',
                lineHeight: '140%'
            }
        },
        accordionDetails: {
            display: 'flex',
            flexDirection: 'column',
            padding: theme.spacing(2),
            fontSize: theme.typography.body1.fontSize,
            color: colors.black,
            fontStyle: 'normal',
            fontWeight: 'normal',
            lineHeight: '160%',
            '& span+span': {
                marginTop: theme.spacing(1)
            },
            [theme.breakpoints.down('sm')]: {
                padding: 0,
            }
        }
    }));
    const classes = useStyles();
    return (
        <Box>
            {
                items.map((item, index) => (
                        <Accordion key={index} className={classes.accordion} elevation={0} square>
                            <AccordionSummary
                                className={classes.accordionSummary}
                                expandIcon={<ExpandMoreIcon />}
                            >
                                <Typography className={classes.accordionHeading} variant="body1" component="h2">{item.title}</Typography>
                            </AccordionSummary>
                            <AccordionDetails className={classes.accordionDetails}>
                                <Text html={item.description} />
                            </AccordionDetails>
                        </Accordion>
                    )
                )
            }
        </Box>
    );
};

ExpandableTitleDescriptionList.propTypes = {
    items: PropTypes.array
};

export default ExpandableTitleDescriptionList;
