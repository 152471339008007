import React from 'react';
import PropTypes from 'prop-types';
import strings from 'localization/strings';
import { makeStyles } from 'styles/util';
import { getContact, getBusinessOrPrivateName } from 'helpers/ActorHelper';
import contactTypes from 'enums/contactTypes';

const useStyles = makeStyles(() => ({
    caption: {
        '& td': {
            paddingBottom: '0.5em'
        }
    },
    body: {
        '& td': {
            paddingBottom: '1em'
        }
    },
    mainTitle: {
        fontWeight: 'bold',
        fontSize: '16px'
    },
    right: {
        textAlign: 'right'
    }
}));

const ReceiptParties = ({ purchase, isOwner = false }) => {
    const classes = useStyles();

    const booking = purchase.booking;
    const storageSite = booking.storageGroup.storageSite;
    const ownerActor = booking.ownerActor;
    const tenantActor = booking.tenantActor;

    return (
        <>
            <tr className={classes.caption}>
                <td colSpan={2} className={classes.mainTitle}>{strings.owner}</td>
                <td colSpan={2} className={classes.mainTitle + ' ' + classes.right}>{strings.paymentDocuments.contact}</td>
            </tr>
            <tr className={classes.body}>
                <td colSpan={2}>
                    {getBusinessOrPrivateName(ownerActor)}<br />
                    {storageSite.address}<br />
                    {storageSite.postalCode} {storageSite.city}
                    {
                        ownerActor.vatIdentificationNumber &&
                        (
                            <>
                                <br />
                                {strings.paymentDocuments.vatIdentificationNumber}: {ownerActor.vatIdentificationNumber}
                            </>
                        )
                    }
                </td>
                <td colSpan={2} className={classes.right}>
                    {getContact(ownerActor, [contactTypes.checkInMobileTelephone, contactTypes.mainMobileTelephone])}<br/>
                    {getContact(ownerActor, [contactTypes.checkInEmail, contactTypes.mainEmail])}
                </td>
            </tr>

            <tr>
                <td colSpan={4}><hr /></td>
            </tr>
            <tr className={classes.caption}>
                <td colSpan={2} className={classes.mainTitle}>{strings.tenant}</td>
                <td colSpan={2} className={classes.mainTitle + ' ' + classes.right}>{strings.paymentDocuments.contact}</td>
            </tr>
            <tr className={classes.body}>
                <td colSpan={2}>
                    {getBusinessOrPrivateName(tenantActor)}<br />
                    {
                        isOwner && tenantActor.personalIdentity &&
                        (
                            <>
                                {strings.personalIdentityNumber}: {tenantActor.personalIdentity}
                                <br />
                            </>
                        )
                    }
                </td>
                <td colSpan={2} className={classes.right}>
                    {getContact(tenantActor, contactTypes.mainMobileTelephone)}<br />
                    {getContact(tenantActor, contactTypes.mainEmail)}
                </td>
            </tr>
        </>
    );
};

ReceiptParties.propTypes = {
    purchase: PropTypes.object.isRequired,
    isOwner: PropTypes.bool
};

export default ReceiptParties;
