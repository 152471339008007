import { RSAA } from 'redux-api-middleware';
import { createRsaaTypes, createHeaders } from '../actionHelpers';

export const ADMIN_REGIONS_LIST = 'ADMIN_REGIONS_LIST';

export const getRegions = () => ({
    [RSAA]: {
        types: createRsaaTypes(ADMIN_REGIONS_LIST),
        endpoint: '/api/admin/regions',
        headers: createHeaders(false),
        method: 'GET'
    }
});
