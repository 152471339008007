import { RSAA } from 'redux-api-middleware';
import { createRsaaTypes, createHeaders } from './actionHelpers';

const CALCULATE_OPENING_TIME_SPANS_FOR_OPENING_TIME_SPAN_RULES = 'CALCULATE_OPENING_TIME_SPANS_FOR_OPENING_TIME_SPAN_RULES';

export const calculateOpeningTimeSpansForOpeningTimeSpanRules = request => ({
    [RSAA]: {
        types: createRsaaTypes(CALCULATE_OPENING_TIME_SPANS_FOR_OPENING_TIME_SPAN_RULES),
        endpoint: '/api/openingTimeSpans',
        headers: createHeaders(true),
        method: 'PUT',
        body: JSON.stringify(request)
    }
});
