import React from 'react';
import PropTypes from 'prop-types';
import strings from 'localization/strings';

import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';

const DraftSubmittedDialog = ({ open, onClose }) => {
    return (
        <Dialog open={open} onClose={onClose} aria-labelledby="form-dialog-title">
            <DialogTitle id="form-dialog-title" disableTypography>
                <Typography variant="h5">
                    {strings.draftSubmittedDialogTitle}
                </Typography>
            </DialogTitle>
            <DialogContent>
                {strings.draftSubmittedDialogBody}
            </DialogContent>
            <DialogActions>
                <Button
                    variant="contained"
                    color="primary"
                    onClick={onClose}
                >
                    {strings.close}
                </Button>
            </DialogActions>
        </Dialog>
    );
};

DraftSubmittedDialog.propTypes = {
    open: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired
};

export default DraftSubmittedDialog;


