import React from 'react';
import PropTypes from 'prop-types';
import strings from 'localization/strings';
import { makeStyles } from 'styles/util';

import Amount from 'common/Amount';

const useStyles = makeStyles(() => ({
    right: {
        textAlign: 'right'
    }
}));

const RoundingCorrection = ({ purchase }) => {
    const classes = useStyles();

    if(purchase.roundingCorrection === 0) {
        return null;
    }

    return (
        <tr>
            <td colSpan={2}>
                {strings.roundingCorrection}
            </td>
            <td colSpan={2} className={classes.right}><Amount value={purchase.roundingCorrection} currency={purchase.currency} displayPriceRounding={false} /></td>
        </tr>
    );
};

RoundingCorrection.propTypes = {
    purchase: PropTypes.object.isRequired
};

export default RoundingCorrection;
