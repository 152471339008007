import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useAppContext } from 'context/AppContext';
import strings from 'localization/strings';
import { makeStyles } from 'styles/util';
import { calculatePriceForPeriod } from 'helpers/PriceCalculator';
import { alpha } from '@material-ui/core/styles';
import { convertToNumber } from 'helpers/StringHelper';
import rentalTypes from 'enums/rentalTypes';
import { changeValueMutator } from 'helpers/FormHelper';
import {
    getSelectedStorageGroupId,
    storageGroupIsBookable,
    updateSelectedStorageGroupId,
    updateSelectedStorageGroupIdRentalType
} from 'logic/bookingLogic';
import { isAfter, add } from 'date-fns';
import { getStorageGroupTitle, onlyPeriodBookingsAllowed, onlySubscriptionBookingsAllowed, getAboutOurPricesText } from 'helpers/StorageSiteHelper';

import ImportExport from '@material-ui/icons/ImportExport';
import groupBy from 'lodash.groupby';
import DateSelector from './DateSelector';
import Form from 'form/Form';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import Alert from '@material-ui/lab/Alert';
import { OnChange } from 'react-final-form-listeners';
import KeyboardArrowUp from '@material-ui/icons/KeyboardArrowUp';
import KeyboardArrowDown from '@material-ui/icons/KeyboardArrowDown';
import StorageGroupSelectorPopover from './StorageGroupSelectorPopover';
import storageGroupCategories from 'enums/storageGroupCategories';
import MenuItem from '@material-ui/core/MenuItem';
import { TextField} from 'mui-rff';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import CustomStorageAreaFields from './CustomStorageAreaFields';
import Close from '@material-ui/icons/Close';
import Amount from 'common/Amount';

const useStyles = makeStyles(({ theme, colors, styles, fonts }) => ({
    storageBookingForm: {
        position: 'sticky',
        top: theme.spacing(8),
        backgroundColor: colors.secondaryColor,
        color: colors.white,
        marginTop: 'auto',
        borderRadius: `${styles.borderRadius / 1.5}px`,
        [theme.breakpoints.down('sm')]: {
            borderBottomLeftRadius: 0,
            borderBottomRightRadius: 0
        }
    },
    container: {
        display: 'flex',
        alignItems: 'center',
        flexDirection: 'column',
        justifyContent: 'space-evenly',
    },
    totalPrice: {
        textAlign: 'center',
        display: 'flex',
        width: '100%',
        justifyContent: 'space-between',
        flexDirection: 'row',
        alignItems: 'center',
        marginBottom: theme.spacing(1)
    },
    estimatedPrice: {
        flex: '1 0 auto'
    },
    totalAmountWrapper: {
        display: 'flex',
        flexDirection: 'column',
        flex: '1 1 auto',
        alignItems: 'start'
    },
    totalAmount: {
        fontSize: '22px',
        lineHeight: '30.8px',
        fontFamily: fonts.medium,
        marginTop: theme.spacing(0)
    },
    bookingButton: {
        width: '100%',
        minHeight: '50px',
        color: colors.white,
        fontSize: theme.spacing(2),
        fontStyle: 'normal',
        fontWeight: 'normal',
        lineHeight: '150%',
        '&.Mui-disabled': {
            backgroundColor: colors.darkGrey,
            color: `${colors.textColorDisabled} !important`
        }
    },
    bookingDescription: {
        opacity: 0.8,
        marginTop: theme.spacing(2),
        zoom: 0.75,
        textAlign: 'center'
    },
    aboutOurPrices: {
        opacity: 0.8,
        marginTop: theme.spacing(2),
        zoom: 0.75,
        textAlign: 'center'
    },
    errors: {
        margin: theme.spacing(2, 0),
    },
    highlight: {
        fontWeight: 'bold'
    },
    selectedStorageGroupButton: {
        width: '100%',
        backgroundColor: colors.white,
        border: `1px solid ${colors.mediumGrey}`,
        color: colors.darkBlue,
        fontSize: theme.spacing(2),
        fontStyle: 'normal',
        fontWeight: 'normal',
        lineHeight: '160%',
        padding: theme.spacing(1.25, 2),
        margin: theme.spacing(1, 0, 2, 0),
        '& .MuiButton-label': {
            justifyContent: 'space-between',
            paddingLeft: 0,
            paddingRight: 0,
        },
        '& .MuiSvgIcon-root': {
            fontSize: theme.spacing(2.875),
        },
        '&:hover': {
            backgroundColor: colors.whiteHover
        }
    },
    selectedStorageGroupButtonTextNotSelected: {
        color: colors.darkGrey,
    },
    textField: {
        margin: theme.spacing(1, 0, 2),
        '& .MuiOutlinedInput-root': {
            borderRadius: theme.spacing(1),
            color: colors.darkBlue,
        },
        '& .MuiTextField-root': {

        },
        '& .MuiOutlinedInput-input': {
            padding: theme.spacing(1.875, 1.75)
        },
        '& .MuiOutlinedInput-notchedOutline': {
            border: `2px solid ${colors.darkBlue}`
        },
        '& .MuiSvgIcon-root': {
            color: colors.darkBlue,
            fontSize: theme.spacing(2.875)
        }
    },
    menuItem: {
        padding: theme.spacing(1, 2)
    },
    titleContainer: {
        display: 'flex',
        justifyContent: 'space-between',
        padding: theme.spacing(3, 4, 2, 4),
        top: theme.spacing(-0.125),
        zIndex: 1300,
        '& button': {
            color: colors.white,
            padding: 0
        },
        [theme.breakpoints.down('sm')]: {
            position: 'sticky',
        }
    },
    title: {
        color: colors.white
    },
    contentContainer: {
        padding: theme.spacing(0, 4, 3, 4),
        '& .MuiTypography-root': {
            color: colors.white
        },
        '& .MuiInputAdornment-root .MuiTypography-root': {
            color: colors.textColor
        }
    },
    iWantToRentContainer: {
        display: 'flex',
        justifyContent: 'space-between'
    },
    submitContainer: {
        zIndex: 1200,
        padding: theme.spacing(1, 0),
        bottom: 0,
        width: '100%',
        background: alpha(colors.secondaryColor, 0.5),
        position: 'sticky'
    },
    sizeContainer: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        position: 'relative',
        '& button': {
            position: 'absolute',
            padding: 0,
            right: 0,
            color: colors.white
        }
    }
}),
// branch-specific styling
({ colors }) => ({
    office: {
        storageBookingForm: {
            backgroundColor: colors.beige
        },
        title: {
            color: colors.textColor
        },
        contentContainer: {
            '& .MuiTypography-root': {
                color: colors.textColor
            }
        },
        submitContainer: {
            background: colors.beige ? alpha(colors.beige, 0.5) : undefined
        },
        totalAmount: {
            color: colors.primaryColor
        },
        arrowButton: {
            color: colors.primaryColor
        }
    }
}));

const StorageBookingForm = ({ storageSite, selectedCategory, initialValues, onSubmit, errors = [], isPreview = false, closeModal }) => {
    const classes = useStyles();
    const { appContext } = useAppContext();

    const [isSelectStorageOpen, setIsSelectStorageOpen ] = useState(false);
    const [isVehicleTextOpen, setIsVehicleTextOpen ] = useState(false);
    const [vehicleTextAnchorEl, setVehicleTextAnchorEl] = useState(null);
    const [storageGroupCategoryAnchorEl, setStorageGroupCategoryAnchorEl] = useState(null);

    const handleSelectStorageGroupCategoryClick = (event) => {
        handlePopoverClicked(event, setStorageGroupCategoryAnchorEl, setIsSelectStorageOpen);
    };

    const handleVehicleTextClick = (event) => {
        handlePopoverClicked(event, setVehicleTextAnchorEl, setIsVehicleTextOpen);
    };

    const handlePopoverClicked = (event, setAnchorEl, setPopoverOpen) => {
        setAnchorEl(event ? event.currentTarget : null);
        setPopoverOpen(prevState => !prevState);
    };

    return (
        <>
            <Form
                onSubmit={onSubmit}
                initialValues={initialValues}
                mutators={{
                    changeValue: changeValueMutator
                }}
            >
                {
                    ({ handleSubmit, form, valid }) => {
                        const formValues = form.getState().values;
                        const selectedStorageGroupId = getSelectedStorageGroupId(formValues);
                        const selectedStorageGroup = isPreview
                            ? undefined
                            : storageSite.storageGroups.find(storageGroup => storageGroup.id === selectedStorageGroupId);
                        const subscriptionBooking = formValues.requestedStartDate && !formValues.requestedEndDate && !onlyPeriodBookingsAllowed(storageSite);

                        const priceCalculationParameters = selectedStorageGroup
                            ? {
                                startDate: formValues.requestedStartDate,
                                endDate: formValues.requestedEndDate,
                                width: convertToNumber(formValues.categories[selectedStorageGroup.category].width),
                                length: convertToNumber(formValues.categories[selectedStorageGroup.category].length),
                                tenantOrganizationType: formValues.tenantOrganizationType
                            }
                            : undefined;
                        const { displayPrice, currency } = selectedStorageGroup
                            ? calculatePriceForPeriod(storageSite, selectedStorageGroup, priceCalculationParameters, appContext)
                            : { displayPrice: undefined, currency: undefined };

                        const amountFormatString = subscriptionBooking
                            ? strings.currencyUnitPerMonth
                            : undefined;

                        const handleBookingIntervalChange = () => {
                            // make sure a bookable storage group is selected
                            updateSelectedStorageGroupId(storageSite, form, undefined, appContext);
                            // make sure dates are valid
                            if(formValues.requestedStartDate && formValues.requestedEndDate && isAfter(formValues.requestedStartDate, formValues.requestedEndDate)) {
                                form.mutators.changeValue({
                                    name: 'requestedEndDate',
                                    value: add(add(formValues.requestedStartDate, { months: 1 }), { days: -1 })
                                });
                            }
                        };

                        const handleStorageGroupCategoryChange = selectedStorageGroupCategory => {
                            // check that StorageGroupId belongs to selected storage group category
                            form.mutators.changeValue({
                                name: 'categories',
                                value: updateSelectedStorageGroupId(storageSite, form, selectedStorageGroupCategory, appContext)
                            });
                        };

                        const categories = groupBy(storageSite.storageGroups, 'category');
                        const hasCustomAreaStorage = categories[formValues.storageGroupCategory]?.find(storageGroup => storageGroup?.rentalType === rentalTypes.customArea.key && storageGroupIsBookable(storageGroup, formValues.requestedStartDate, formValues.requestedEndDate));
                        const hasBookableStorage = categories[formValues.storageGroupCategory]?.find(storageGroup => storageGroupIsBookable(storageGroup, formValues.requestedStartDate, formValues.requestedEndDate));

                        const sendBookingRequestButtonText = storageSite.allowImmediateBookings
                            ? strings.sendBooking
                            : strings.sendBookingRequest;

                        const selectedStorageGroupText = selectedStorageGroup
                            ? getStorageGroupTitle(selectedStorageGroup, appContext, { useSpecialCustomAreaLabel: true })
                            : hasBookableStorage || isPreview ? strings.chooseSize : strings.notBookable;

                        const storageGroupCategory = storageGroupCategories[formValues.storageGroupCategory];

                        const isCustomArea = selectedStorageGroup?.rentalType === rentalTypes.customArea.key;

                        return (
                            <form onSubmit={handleSubmit} className={classes.storageBookingForm}>
                                <Box className={classes.titleContainer}>
                                    <Typography variant="h3" className={classes.title}>{storageGroupCategory?.rentTitle}</Typography>
                                    {
                                        closeModal &&
                                        (
                                            <IconButton onClick={closeModal}>
                                                <Close className={classes.arrowButton} />
                                            </IconButton>
                                        )
                                    }
                                </Box>
                                <Box className={classes.contentContainer}>
                                    {
                                        Object.keys(categories).length > 1 &&
                                        (
                                            <>
                                                <Box className={classes.iWantToRentContainer}>
                                                    <Typography variant="body1">{strings.iWantToRent}</Typography>
                                                    {
                                                        storageGroupCategory?.infoLinkRenderer && storageGroupCategory?.infoLinkRenderer({ onClick: handleVehicleTextClick })
                                                    }
                                                    {
                                                        storageGroupCategory?.infoTextRenderer && storageGroupCategory?.infoTextRenderer({ anchorEl: vehicleTextAnchorEl, open: isVehicleTextOpen, onClose: handleVehicleTextClick })
                                                    }
                                                </Box>

                                                <TextField
                                                    select
                                                    name="storageGroupCategory"
                                                    variant="outlined"
                                                    SelectProps={{ IconComponent: KeyboardArrowDown }}
                                                    className={classes.textField}
                                                >
                                                    {
                                                        Object.keys(categories).map(category => (
                                                            <MenuItem key={category} value={category} className={classes.menuItem}>
                                                                {storageGroupCategories[category].singleTitle}
                                                            </MenuItem>
                                                        ))
                                                    }
                                                </TextField>
                                            </>
                                        )
                                    }
                                    <Box className={classes.sizeContainer}>
                                        <Typography variant="body2">{selectedStorageGroup?.rentalType === rentalTypes.customArea.key ? strings.customSize : strings.size}</Typography>
                                        {
                                            hasCustomAreaStorage && storageSite.storageGroups.length !== 1 &&
                                            (
                                                <Tooltip
                                                    title={isCustomArea ? strings.changeToFixedSizes : strings.changeToCustomSize}>
                                                    <IconButton aria-label="switch" style={{padding: 0}} onClick={() => {
                                                        form.mutators.changeValue({
                                                            name: 'categories',
                                                            value: updateSelectedStorageGroupIdRentalType(storageSite, form, !selectedStorageGroup || !isCustomArea ? rentalTypes.customArea.key : undefined, appContext)
                                                        });
                                                    }}>
                                                        <ImportExport/>
                                                    </IconButton>
                                                </Tooltip>
                                            )
                                        }
                                    </Box>
                                    {
                                        isCustomArea &&
                                        (
                                            <CustomStorageAreaFields
                                                storageGroup={selectedStorageGroup}
                                                value={isPreview ? 0 : selectedStorageGroup.id}
                                                checked
                                                disabled={isPreview}
                                            />
                                        )
                                    }
                                    {
                                        (!selectedStorageGroup || !isCustomArea) &&
                                        (
                                            <>
                                                <Button
                                                    aria-describedby="simple-popover"
                                                    className={`${classes.selectedStorageGroupButton} ${!selectedStorageGroup && classes.selectedStorageGroupButtonTextNotSelected}`}
                                                    type="button"
                                                    endIcon={isSelectStorageOpen ? <KeyboardArrowUp style={{ fontSize: 22 }} /> : <KeyboardArrowDown style={{ fontSize: 22 }} /> }
                                                    onClick={handleSelectStorageGroupCategoryClick}
                                                >
                                                    {selectedStorageGroupText}
                                                </Button>
                                                <StorageGroupSelectorPopover
                                                    anchorEl={storageGroupCategoryAnchorEl}
                                                    onClose={handleSelectStorageGroupCategoryClick}
                                                    open={isSelectStorageOpen}
                                                    storageSite={storageSite}
                                                    selectedCategory={selectedCategory}
                                                    form={form}
                                                    isPreview={isPreview}
                                                />
                                            </>
                                        )
                                    }
                                    <Box className={classes.container}>
                                        <DateSelector
                                            formValues={formValues}
                                            selectedStorageGroup={selectedStorageGroup}
                                            endDateIsNullable={!onlyPeriodBookingsAllowed(storageSite)}
                                            endDateMustBeNull={onlySubscriptionBookingsAllowed(storageSite)}
                                            isPreview={isPreview}
                                        />

                                        <Box className={classes.totalPrice}>
                                            {
                                                !isPreview &&
                                                (
                                                    <Box className={classes.totalAmountWrapper}>
                                                        <Typography className={classes.estimatedPrice} variant="body2">{strings.estimatedPrice}</Typography>
                                                        <Typography variant="body2" className={classes.totalAmount}>
                                                            {
                                                                displayPrice && <Amount value={displayPrice} currency={currency} formatString={amountFormatString}/>
                                                            }
                                                            {
                                                                !displayPrice && <>&ndash;</>
                                                            }
                                                        </Typography>
                                                    </Box>
                                                )
                                            }
                                        </Box>
                                        {
                                            errors.length > 0 &&
                                            (
                                                <Alert severity="error" className={classes.errors}>
                                                    {
                                                        errors.map((err, i) => {
                                                            return (
                                                                <Box key={i}>
                                                                    {err(classes.highlight)}
                                                                </Box>
                                                            );
                                                        })
                                                    }
                                                </Alert>
                                            )
                                        }
                                    </Box>

                                    <Box className={classes.submitContainer}>
                                        <Button
                                            type="submit"
                                            color="primary"
                                            variant="contained"
                                            className={classes.bookingButton}
                                            disabled={!valid || isPreview || !selectedStorageGroupId}
                                        >
                                            {sendBookingRequestButtonText}
                                        </Button>
                                    </Box>

                                    {
                                        !storageSite.allowImmediateBookings &&
                                        (
                                            <Typography variant="body1" className={classes.bookingDescription}>
                                                {strings.bookingButtonDescription}
                                            </Typography>
                                        )
                                    }
                                    <Typography variant="body1" className={classes.aboutOurPrices}>
                                        {getAboutOurPricesText(storageSite, selectedCategory, selectedStorageGroup, appContext)}
                                    </Typography>
                                </Box>

                                <OnChange name="storageGroupCategory">{handleStorageGroupCategoryChange}</OnChange>
                                <OnChange name="requestedStartDate">{handleBookingIntervalChange}</OnChange>
                                <OnChange name="requestedEndDate">{handleBookingIntervalChange}</OnChange>
                            </form>
                        );
                    }
                }
            </Form>
        </>
    );
};

StorageBookingForm.propTypes = {
    storageSite: PropTypes.object.isRequired,
    selectedCategory: PropTypes.object,
    initialValues: PropTypes.object.isRequired,
    onSubmit: PropTypes.func.isRequired,
    errors: PropTypes.array,
    isPreview: PropTypes.bool,
    closeModal: PropTypes.func,
};

export default StorageBookingForm;
