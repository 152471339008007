import { applyMiddleware, combineReducers, compose, createStore } from 'redux';
import thunkMiddleware from 'redux-thunk';
import { apiMiddleware } from 'redux-api-middleware';
import apiErrorMiddleware from 'middlewares/apiErrorMiddleware';
import apiResponseMiddleware from 'middlewares/apiResponseMiddleware';
import reducers from 'reducers';
import { createMiddleware } from 'redux-beacon';
import GoogleTagManager from '@redux-beacon/google-tag-manager';
import { googleTagManagerConstants } from 'integration/google-tag-manager/googleTagManagerHelper';
import { isProductionFromWindowConfiguration } from 'helpers/EnvironmentHelper';

const composeEnhancers = isProductionFromWindowConfiguration
    ? compose
    : window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

// Google Tag Manager config
// a user action is caught by the custom event trigger "UserAction" in GTM
const userActionEvent = action => ({
    hitType: googleTagManagerConstants.hitTypes.userAction, // 'user-action' is the event name in GTM
    action: action.userAction,                              // 'action' is set up as a custom variable in GTM
    actionMetadata: action.userActionMetadata,              // 'actionMetadata' is set up as a custom variable in GTM
    actionValue: action.userActionValue,                    // 'actionValue' is set up as a custom variable in GTM
    actionCurrency: action.userActionCurrency               // 'actionCurrency' is set up as a custom variable in GTM
});
const eventsMap = {
    [googleTagManagerConstants.events.userAction]: userActionEvent
};

const gtm = GoogleTagManager();

const beaconMiddleware = createMiddleware(eventsMap, gtm);

const configureStore = initialState => {
    return createStore(
        combineReducers(reducers),
        initialState,
        composeEnhancers(
            applyMiddleware(
                beaconMiddleware,
                thunkMiddleware,
                apiMiddleware,
                apiResponseMiddleware,
                apiErrorMiddleware
            )
        )
    );
};

export default configureStore;
