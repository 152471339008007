import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from 'styles/util';
import strings from 'localization/strings';
import additionalServiceStatuses from 'enums/additionalServiceStatuses';
import documentTypes from 'enums/documentTypes';

import Box from '@material-ui/core/Box';
import WithDividerWrapper from 'common/WithDividerWrapper';
import AdditionalServices from './AdditionalServices';
import Documents from './Documents';

const useStyles = makeStyles(({ theme }) => ({
    wrapper: {
        display: 'flex',
        width: '100%',
        [theme.breakpoints.down('sm')]: {
            display: 'flex',
            flexDirection: 'column',
            width: '100%',
            margin: theme.spacing(2, 0)
        }
    },
}));

const exists = list => list.length > 0;

const DocumentsAndServicesWithDivider = WithDividerWrapper(({documents, additionalServices}) => {
    const classes = useStyles();
    const servicesExist = exists(additionalServices);
    const documentsExist = exists(documents);

    return (
        <Box className={classes.wrapper}>
            <Documents
                show={documentsExist}
                showDivider={false}
                documents={documents} />
            <AdditionalServices
                show={servicesExist}
                showDivider={documentsExist}
                orientation={'vertical'}
                flexItem
                additionalServices={additionalServices} />
        </Box>
    );
});

const DocumentsAndServices = ({ storageSite }) => {
    const additionalServices = storageSite.additionalServices.filter(additionalService => additionalService.status !== additionalServiceStatuses.deleted.key);
    const documents = storageSite.documents.map(document => {
        const clone = { ...document };
        if(document.type === documentTypes.agreement.key) {
            clone.description = strings.rentalAgreement;
        }
        return clone;
    });
    const servicesExist = exists(additionalServices);
    const documentsExist = exists(documents);

    return (
        <DocumentsAndServicesWithDivider
            show={documentsExist || servicesExist}
            documents={documents}
            additionalServices={additionalServices}
        />
    );
};

DocumentsAndServices.propTypes = {
    storageSite: PropTypes.object.isRequired
};

export default DocumentsAndServices;
