import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from 'styles/util';

import Box from '@material-ui/core/Box';

const StepIndicator = ({ currentStep /* zero-based */, numberOfSteps }) => {
    const useStyles = makeStyles(({ theme, colors }) => ({
        stepContainer: {
            width: '100%',
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center'
        },
        round: {
            flex: '0 0 auto',
            color: colors.white,
            borderRadius: '50%',
            width: theme.spacing(3.5),
            height: theme.spacing(3.5),
            lineHeight: theme.spacing(3.5) + 'px',
            textAlign: 'center'
        },
        line: {
            flex: '1 1 auto',
            height: '4px',
            '&:first-child': {
                width: theme.spacing(4),
                flex: '0 0 auto',
                borderTopLeftRadius: '2px',
                borderBottomLeftRadius: '2px'
            }
        },
        visitedRound: {
            backgroundColor: colors.primaryColor
        },
        futureRound: {
            backgroundColor: colors.primaryDisabledColor
        },
        visitedLine: {
            backgroundColor: colors.primaryColor
        },
        futureLine: {
            backgroundColor: colors.primaryDisabledColor
        }
    }));
    const classes = useStyles();

    const steps = [];
    for(let i = 0; i < numberOfSteps; i++) {
        steps.push(i);
    }

    const getClassNameForRound = step => step <= currentStep
        ? classes.visitedRound
        : classes.futureRound;

    const getClassNameForLine = step => step <= currentStep
        ? classes.visitedLine
        : classes.futureLine;

    return (
        <Box className={classes.container}>
            <Box className={classes.stepContainer}>
                {
                    steps.map(step => (
                        <React.Fragment key={step}>
                            <Box className={classes.line + ' ' + getClassNameForLine(step, false)}/>
                            <Box className={classes.round + ' ' + getClassNameForRound(step, false)}>
                                {step + 1}
                            </Box>
                        </React.Fragment>
                    ))
                }
            </Box>
        </Box>
    );
};

StepIndicator.propTypes = {
    currentStep: PropTypes.number.isRequired,
    numberOfSteps: PropTypes.number.isRequired
};

export default StepIndicator;
