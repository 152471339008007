import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { createStripeAccountInfo, updateStripeAccountInfo, fetchStripeAccountInfo, createStripeAccountLink } from 'actions/stripe';
import { handleResponse } from 'actions/actionHelpers';
import strings from 'localization/strings';
import routes from 'routes';

import BankAccountEditor from './BankAccountEditor';
import StripeAccountInfo from './StripeAccountInfo';
import LoadingWrapper from 'form/LoadingWrapper';
import Separator from 'common/Separator';

const BankSettings = () => {
    const dispatch = useDispatch();

    const [isSaving, setIsSaving] = useState(false);
    const [isUpdatingStripeAccount, setIsUpdatingStripeAccount] = useState(false);

    const stripeAccountInfo = useSelector(state => state.stripe.stripeAccountInfo);
    const uninitializedStripeAccount = !stripeAccountInfo;

    const handleSubmit = values => {
        setIsSaving(true);

        const externalAccount = {
            iban: values.iban
        };

        const stripeAccountInfoFunction = uninitializedStripeAccount
            ? createStripeAccountInfo
            : updateStripeAccountInfo;

        dispatch(stripeAccountInfoFunction(externalAccount))
            .then(handleResponse(
                () => {
                    dispatch(fetchStripeAccountInfo())
                        .then(handleResponse(
                            () => {
                                setIsSaving(false);
                                return {
                                    snackbar: {
                                        message: strings.stripeAccountUpdated
                                    }
                                };
                            }
                        ));
                },
                () => {
                    setIsSaving(false);
                    return {
                        dialog: {
                            body: strings.stripeAccountUpdateFailed
                        }
                    };
                }
            ));
    };

    const handleCreateStripeAccountLink = type => {
        setIsUpdatingStripeAccount(true);
        const data = {
            successUrl: `${window.location.origin}${routes.account.settings}?tab=owner&stripeConnectOnboardingResponse=success#stripeAccountInfo`,
            failureUrl: `${window.location.origin}${routes.account.settings}?tab=owner&stripeConnectOnboardingResponse=failure#stripeAccountInfo`,
            type: type === 'verification' ? 'CustomAccountVerification' : 'CustomAccountUpdate'
        };

        dispatch(createStripeAccountLink(data))
            .then(handleResponse(
                response => {
                    window.location.href = response.payload.url;
                },
                () => setIsUpdatingStripeAccount(false)
            ));
    };

    return (
        <div id="stripeAccountInfo">
            <LoadingWrapper
                isLoading={isUpdatingStripeAccount}
                isSaving={isSaving}
            >
                <BankAccountEditor
                    stripeAccountInfo={stripeAccountInfo}
                    onSubmit={handleSubmit}
                />

                {
                    !uninitializedStripeAccount &&
                    (
                        <>
                            <Separator spacing={4}/>
                            <StripeAccountInfo
                                stripeAccountInfo={stripeAccountInfo}
                                onCreateStripeAccountLink={handleCreateStripeAccountLink}
                            />
                        </>
                    )
                }

            </LoadingWrapper>
        </div>
    );
};

export default BankSettings;
