import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from 'styles/util';
import { formatClassNames } from 'helpers/ComponentHelper';

const StyledList = ({ children, className, listType = 'number', ...rest }) => {
    const useStyles = makeStyles(({ theme }) => ({
        list: {
            listStyle: 'none',
            counterReset: 'list-counter',
            margin: 0,
            padding: 0,
            '& > li:not(:last-child)': {
                paddingBottom: theme.spacing(3.75)
            },
            [theme.breakpoints.down('sm')]: {
                '& > li:not(:last-child)': {
                    paddingBottom: theme.spacing(4.375)
                }
            },
            [theme.breakpoints.down('xs')]: {
                '& > li:not(:last-child)': {
                    paddingBottom: theme.spacing(3.125)
                }
            },
        },
        olList: {
            '& > li::before': {
                counterIncrement: 'list-counter',
                content: 'counter(list-counter)',
            },
        },
        ulList: {
            // Case for unordered lists
        }
    }));

    const classes = useStyles();

    return (
        listType === 'number' ?
            <ol className={formatClassNames([classes.list, classes.olList, className])} {...rest}>
                {children}
            </ol>
            :
            <ul className={formatClassNames([classes.list, classes.olList, className])} {...rest}>
                {children}
            </ul>
    );
};

StyledList.propTypes = {
    children: PropTypes.node.isRequired,
    className: PropTypes.string,
    listType: PropTypes.oneOf(['number', 'bullet'])
};

export default StyledList;
