import React from 'react';
import PropTypes from 'prop-types';
import strings from 'localization/strings';
import { required } from 'form/validation';
import routes from 'routes';

import Box from '@material-ui/core/Box';
import { Checkboxes, showErrorOnBlur } from 'mui-rff';
import { Link } from 'react-router-dom';

const AcceptTermsOfServiceCheckbox = ({ name, label = strings.iAcceptPoliciesForStorage365, showLink = true }) => {
    return (
        <Checkboxes
            name={name}
            showError={showErrorOnBlur}
            required
            fieldProps={{ validate: required }}
            data={{
                label: (
                    <Box component="span">
                        {label}
                        {
                            showLink && <> (<Link to={routes.policies.general} target="_blank">{strings.readPoliciesHere}</Link>)</>
                        }
                    </Box>
                ),
                value: true
            }}
        />
    );
};

AcceptTermsOfServiceCheckbox.propTypes = {
    name: PropTypes.string.isRequired,
    label: PropTypes.string,
    showLink: PropTypes.bool
};

export default AcceptTermsOfServiceCheckbox;
