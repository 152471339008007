import React from 'react';
import { useAppContext } from 'context/AppContext';
import { useForm } from 'react-final-form';
import { formatStorageGroupPrice, formatStorageGroupArea } from './FormatHelper';
import { useSelector } from 'react-redux';
import storageGroupCategories from 'enums/storageGroupCategories';
import strings from 'localization/strings';
import { showOptionToTax, showExclusiveVatSuffix } from 'logic/editStorageSiteLogic';
import { formatYesNo } from 'helpers/StringHelper';

import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';

const DisplayStep1Single = () => {
    const formValues = useForm().getState().values;
    const value = formValues.steps[1].single;

    const authenticationContext = useSelector(state => state.authentication.context);
    const { selectedActor } = authenticationContext;
    const { appContext } = useAppContext();

    const categoryKeys = Object.keys(storageGroupCategories).filter(key => value[key] && value[key].storageGroups.length > 0);
    const optionToTaxVisible = showOptionToTax(selectedActor, categoryKeys);
    const exclusiveVatSuffixVisible = showExclusiveVatSuffix(selectedActor, categoryKeys, value.optionToTax);
    const lockConfigurationVisible = authenticationContext.isSuperAdminOrImpersonated;

    return (
        <Box>
            <Typography variant="body1" display="block" gutterBottom>
                {formatStorageGroupArea(value, appContext)}
                {', '}
                {formatStorageGroupPrice(value, exclusiveVatSuffixVisible, appContext)}
            </Typography>
            <Typography variant="body1" display="block">
                {strings.areaOfUsage}:
                {' '}
                {value.categories.map(category => storageGroupCategories[category].singleTitle).join(', ')}
            </Typography>
            {
                optionToTaxVisible &&
                (
                    <Typography variant="body1" display="block">
                        {strings.optionToTax}:
                        {' '}
                        {formatYesNo(value.optionToTax)}
                    </Typography>
                )
            }
            {
                lockConfigurationVisible && value.lockConfigurations.length > 0 &&
                (
                    <Typography variant="body1" display="block">
                        {strings.lockConfiguration}:
                        {' '}
                        {value.lockConfigurations}
                    </Typography>
                )
            }
        </Box>
    );
};

export default DisplayStep1Single;
