import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { fetchRelevantStorageSites } from 'actions/viewStorageSites';
import strings from 'localization/strings';
import { makeStyles } from 'styles/util';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { getStorageSiteDetailsUrl } from 'helpers/StorageSiteHelper';

import StorageSiteList from 'components/storageSiteList/StorageSiteList';
import ArrowRight from '@material-ui/icons/ArrowForward';
import Box from '@material-ui/core/Box';
import ButtonLink from 'common/ButtonLink';
import Typography from '@material-ui/core/Typography';

const useStyles = makeStyles(({ theme }) => ({
    titleContainer: {
        position: 'relative',
        display: 'flex',
        height: '40px',
        justifyContent: 'space-around'
    },
    title: {
        alignSelf: 'center'
    },
    link: {
        position: 'absolute',
        right: 0,
        top: 0,
        whiteSpace: 'nowrap',
        [theme.breakpoints.down('xs')]: {
            position: 'static'
        }
    },
    arrowRight: {
        width: theme.spacing(2),
        height: theme.spacing(2),
        marginLeft: theme.spacing(0.5)
    }
}));

const RelevantStorageSites = ({ storageGroupCategory, title, linkTo, location, excludeStorageSite }) => {
    const classes = useStyles();
    const isMobile = !useMediaQuery(theme => theme.breakpoints.up('md'));

    const dispatch = useDispatch();

    let storageSites = useSelector(state => state.viewStorageSites.relevantStorageSites[storageGroupCategory.key].items);

    if(storageSites) {
        storageSites = storageSites.filter((item, i) => isMobile ? i < 2 : true);
    }
    const loading = useSelector(state => state.viewStorageSites.relevantStorageSites[storageGroupCategory.key].loading);

    const lat = location ? location.latitude || location.lat : undefined;
    const lng = location ? location.longitude || location.lng : undefined;

    useEffect(() => {
        dispatch(fetchRelevantStorageSites(lat, lng, lat && lng ? 'distance' : 'createdTime', storageGroupCategory.key, 3, excludeStorageSite ? excludeStorageSite.id : undefined));
    }, [JSON.stringify({ lat, lng, storageGroupCategoryKey: storageGroupCategory.key })]);

    const storageSiteLinkCreator = storageSite => getStorageSiteDetailsUrl(storageSite, storageGroupCategory.key);

    return (
        <>
            <Box className={classes.titleContainer}>
                <Typography variant="h4" className={classes.title}>{title}</Typography>
                <ButtonLink className={classes.link} to={linkTo} variant="white">
                    {strings.showAll}<ArrowRight className={classes.arrowRight}/>
                </ButtonLink>
            </Box>
            <StorageSiteList
                storageSites={storageSites}
                storageGroupCategory={storageGroupCategory}
                loading={loading || !storageSites}
                storageSiteLinkCreator={storageSiteLinkCreator}
                distanceIsFromCurrentPlace
                relevantStorageSites
            />
        </>
    );
};

RelevantStorageSites.propTypes = {
    storageGroupCategory: PropTypes.object,
    title: PropTypes.string,
    linkTo: PropTypes.string,
    location: PropTypes.object,
    excludeStorageSite: PropTypes.object,
};

export default RelevantStorageSites;
