import React from 'react';
import strings from 'localization/strings';
import { useScrollToTop } from 'helpers/BrowserHelper';

import PageTitle from 'common/PageTitle';
import Container from 'common/Container';
import Text from 'common/Text';

const NotAllowedPolicyPage = () => {
    useScrollToTop();

    return (
        <Container withPageMargins>
            <PageTitle>{strings.notAllowedPolicy}</PageTitle>
            <Text component="div" html={strings.pages.notAllowedPolicy} ignoreNewlines />
        </Container>
    );
};

export default NotAllowedPolicyPage;
