import React from 'react';
import PropTypes from 'prop-types';
import { useField } from 'react-final-form';
import { required, email, isPassword, isSamePassword } from 'form/validation';
import strings from 'localization/strings';
import { toPascalCase } from 'helpers/StringHelper';

import { TextField, showErrorOnBlur } from 'mui-rff';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import AcceptTermsOfServiceCheckbox from 'components/AcceptTermsOfServiceCheckbox';

const CreateUser = ({ fieldNamePrefix, disableNameFields = false, showEmailField = false, showMobileTelephoneField = false, showPasswordFields = false, showAcceptTermsOfServiceCheckbox = false, fieldNameImmediatePrefix = '' }) => {
    const getName = suffix => suffix
        ? `${fieldNamePrefix}.${formatSuffix(suffix)}`
        : fieldNamePrefix;
    const getValue = suffix => useField(getName(suffix)).input.value;
    const formatSuffix = suffix => fieldNameImmediatePrefix
        ? fieldNameImmediatePrefix + toPascalCase(suffix)
        : suffix;

    const renderNameFields = () => (
        <Grid container spacing={2}>
            <Grid item sm={6} xs={12}>
                <TextField
                    name={getName('firstName')}
                    label={strings.firstName}
                    variant="outlined"
                    showError={showErrorOnBlur}
                    required
                    disabled={disableNameFields}
                    fieldProps={{ validate: required }}
                />
            </Grid>
            <Grid item sm={6} xs={12}>
                <TextField
                    name={getName('lastName')}
                    label={strings.lastName}
                    variant="outlined"
                    showError={showErrorOnBlur}
                    required
                    disabled={disableNameFields}
                    fieldProps={{ validate: required }}
                />
            </Grid>
        </Grid>
    );

    const renderContactFields = () => (
        <Grid container spacing={2}>
            {
                showEmailField &&
                (
                    <Grid item sm={6} xs={12}>
                        <TextField
                            name={getName('email')}
                            label={strings.email}
                            variant="outlined"
                            showError={showErrorOnBlur}
                            required
                            fieldProps={{ validate: email }}
                        />
                    </Grid>
                )
            }
            {
                showMobileTelephoneField &&
                (
                    <Grid item sm={6} xs={12}>
                        <TextField
                            name={getName('mobileTelephone')}
                            label={strings.phoneNumber}
                            variant="outlined"
                            showError={showErrorOnBlur}
                            required
                            fieldProps={{ validate: required }}
                        />
                    </Grid>
                )
            }
        </Grid>
    );

    const renderPasswordFields = () => (
        <Grid container spacing={2}>
            <Grid item sm={6} xs={12}>
                <TextField
                    name={getName('password')}
                    type="password"
                    label={strings.password}
                    variant="outlined"
                    showError={showErrorOnBlur}
                    required
                    fieldProps={{ validate: isPassword }}
                />
            </Grid>

            <Grid item sm={6} xs={12}>
                <TextField
                    name={getName('confirmPassword')}
                    type="password"
                    label={strings.confirmPassword}
                    variant="outlined"
                    showError={showErrorOnBlur}
                    required
                    fieldProps={{ validate: isSamePassword(getValue('password')) }}
                />
            </Grid>
        </Grid>
    );

    const renderAcceptTermsOfServiceCheckbox = () => (
        <Grid container spacing={2}>
            <Grid item xs={12}>
                <Typography variant="h6">
                    <AcceptTermsOfServiceCheckbox name={getName('termsOfServiceAccepted')}/>
                </Typography>
            </Grid>
        </Grid>
    );

    return (
        <>
            {
                renderNameFields()
            }
            {
                (showEmailField || showMobileTelephoneField) && renderContactFields()
            }
            {
                showPasswordFields && renderPasswordFields()
            }
            {
                showAcceptTermsOfServiceCheckbox && renderAcceptTermsOfServiceCheckbox()
            }
        </>
    );
};

CreateUser.propTypes = {
    fieldNamePrefix: PropTypes.string,
    disableNameFields: PropTypes.bool,
    showEmailField: PropTypes.bool,
    showMobileTelephoneField: PropTypes.bool,
    showPasswordFields: PropTypes.bool,
    showAcceptTermsOfServiceCheckbox: PropTypes.bool,
    fieldNameImmediatePrefix: PropTypes.string
};

export default CreateUser;
