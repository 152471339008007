import React from 'react';
import PropTypes from 'prop-types';
import { required } from 'form/validation';

import Form from 'form/Form';
import Button from '@material-ui/core/Button';
import { TextField, Checkboxes, showErrorOnBlur } from 'mui-rff';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Typography from '@material-ui/core/Typography';

const EditNoteDialog = ({ open, flagged = false, onSubmit, onCancel }) => {
    const initialValues = {
        text: '',
        flagged
    };

    return (
        <Dialog fullWidth maxWidth="md" open={open} onClose={onCancel}>
            <Form
                initialValues={initialValues}
                onSubmit={onSubmit}
            >
                {({ handleSubmit, invalid }) => (
                    <form onSubmit={handleSubmit}>
                        <DialogTitle disableTypography>
                            <Typography variant="h5">
                                Add note
                            </Typography>
                        </DialogTitle>
                        <DialogContent>
                            <TextField
                                name="text"
                                label="Note"
                                type="text"
                                variant="outlined"
                                required
                                fieldProps={{ validate: required }}
                                showError={showErrorOnBlur}
                                multiline
                                rows={3}
                                maxRows={3}
                                maxLength={1024}
                                autoFocus
                            />
                            <Checkboxes
                                name="flagged"
                                data={{
                                    label: 'Flagged',
                                    value: true
                                }}
                            />
                        </DialogContent>
                        <DialogActions>
                            <Button
                                type="submit"
                                variant="contained"
                                color="primary"
                                disabled={invalid}
                            >
                                Save note
                            </Button>
                            <Button
                                type="button"
                                variant="outlined"
                                color="primary"
                                onClick={onCancel}
                            >
                                Cancel
                            </Button>
                        </DialogActions>
                    </form>
                )}
            </Form>
        </Dialog>
    );
};

EditNoteDialog.propTypes = {
    open: PropTypes.bool.isRequired,
    flagged: PropTypes.bool,
    onSubmit: PropTypes.func.isRequired,
    onCancel: PropTypes.func.isRequired
};

export default EditNoteDialog;
