import strings from 'localization/strings';

const storageSiteAccessAllowances = {
    free: {},
    chargeable: {},
    notAllowed: {}
};

Object.keys(storageSiteAccessAllowances).forEach(key => {
    storageSiteAccessAllowances[key].key = key;
});

strings.addLoadCallback(() => {
    Object.values(storageSiteAccessAllowances).forEach(o => {
        o.title = strings.storageSiteAccessAllowances[o.key];
    });
});

export default storageSiteAccessAllowances;
