import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { makeStyles } from 'styles/util';
import { useAppContext } from 'context/AppContext';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import IconChevronRight from 'common/IconChevronRight';

const useStyles = makeStyles(({ colors }) => ({
    breadcrumb: {
        display: 'flex',
        alignItems: 'center'
    },
    link: {
        cursor: 'pointer',
        textDecoration: 'none',
        color: colors.white
    }
}));

const Breadcrumb = ({to, label, isLast }) => {
    const { appContext } = useAppContext();
    const classes = useStyles();
    return (
        isLast ? (
            <Box className={classes.breadcrumb}>
                <Typography variant="h4" component="span">
                    {label}
                </Typography>
                {!isLast && <IconChevronRight fill={appContext.colors.white} />}
            </Box>
        ) : (
            <Box className={classes.breadcrumb}>
                <Link className={classes.link} to={to}>
                    <Typography variant="h4" component="span">
                        {label}
                    </Typography>
                </Link>
                {!isLast && <IconChevronRight fill={appContext.colors.white} />}
            </Box>
        )
    );
};

Breadcrumb.propTypes = {
    to: PropTypes.string,
    label: PropTypes.string,
    isLast: PropTypes.bool
};

export default Breadcrumb;
