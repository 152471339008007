import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { handleResponse } from 'actions/actionHelpers';
import { getRegions } from 'actions/admin/regions';
import { searchActors } from 'actions/admin/actors';
import { formatIsoDate } from 'helpers/DateHelper';
import { getStorageSitesWithCapacityUtilizations } from 'actions/capacityUtilization';
import routes from 'routes';
import { chainableDefaultCompare, defaultCompare } from 'helpers/SortHelper';
import { getBusinessOrPrivateName } from 'helpers/ActorHelper';

import CapacityUtilization from 'components/capacityUtilization/CapacityUtilization';
import AdminPage from '../../presentational/AdminPage';
import AdminContentWrapper from '../../presentational/AdminContentWrapper';
import Loader from 'common/Loader';
import CapacityUtilizationForm from './CapacityUtilizationForm';

const CapacityUtilizationContainer = () => {
    const dispatch = useDispatch();

    const [isSubmitting, setIsSubmitting] = useState(false);
    const [regions, setRegions] = useState(undefined);
    const [ownerActors, setOwnerActors] = useState(undefined);
    const [storageSites, setStorageSites] = useState(undefined);
    const [capacityUtilizationFormParameters, setCapacityUtilizationFormParameters] = useState(undefined);

    const isLoading = !regions || !ownerActors;

    useEffect(() => {
        dispatch(getRegions())
            .then(handleResponse(
                response => setRegions(response.payload)
            ));

        dispatch(searchActors({ isOwner: true, pageSize: 999999 }, false /* useRedux */))
            .then(handleResponse(
                response => {
                    response.payload.items.forEach(o => {
                        o.name = o.name ?? '';
                    });
                    setOwnerActors(response.payload.items);
                }
            ));
    }, []);

    const handleCapacityUtilizationFormSubmit = formValues => {
        setIsSubmitting(true);
        setCapacityUtilizationFormParameters(formValues);
        const request = {
            ...formValues,
            fromDate: formatIsoDate(formValues.fromDate),
            toDate: formatIsoDate(formValues.toDate)
        };
        dispatch(getStorageSitesWithCapacityUtilizations(request))
            .then(handleResponse(
                response => {
                    const sortedStorageSites = [...response.payload];
                    sortedStorageSites.sort((a, b) => chainableDefaultCompare(getBusinessOrPrivateName(a.ownerActor), getBusinessOrPrivateName(b.ownerActor)) ?? chainableDefaultCompare(a.title, b.title) ?? defaultCompare(a.id, b.id));
                    setStorageSites(sortedStorageSites);
                    setIsSubmitting(false);
                },
                () => setIsSubmitting(false)
            ));
    };

    const ownerLinkProvider = storageSite => routes.admin.actor.replace(':actorId', storageSite.ownerActor.id);

    return (
        <AdminPage title="Capacity utilization" fullWidth>
            <AdminContentWrapper type="listing">
                {
                    isLoading && <Loader/>
                }
                {
                    !isLoading &&
                    (
                        <CapacityUtilizationForm
                            regions={regions}
                            ownerActors={ownerActors}
                            isSubmitting={isSubmitting}
                            onSubmit={handleCapacityUtilizationFormSubmit}
                        />
                    )
                }
                {
                    storageSites && !isSubmitting &&
                    (
                        <CapacityUtilization
                            storageSites={storageSites}
                            fromDate={capacityUtilizationFormParameters.fromDate}
                            toDate={capacityUtilizationFormParameters.toDate}
                            showStorageSiteList
                            showOwner
                            ownerLinkProvider={ownerLinkProvider}
                        />
                    )
                }
            </AdminContentWrapper>
        </AdminPage>
    );
};

export default CapacityUtilizationContainer;
