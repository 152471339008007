import { useLoadScript } from '@react-google-maps/api';
import { userAgentIsBot } from './ConfigurationHelper';
import Geocode from 'react-geocode';

const googleMapsLibraries = ['places'];

// mechanism to load the Google Maps script only once
export const initializeGoogleMaps = appContext => {
    window.googleMapsScript = userAgentIsBot()
        ? {
            isLoaded: false
        }
        : useLoadScript({
            googleMapsApiKey: appContext.google.mapsApiKey,
            libraries: googleMapsLibraries,
            language: appContext.language
        });

    Geocode.setApiKey(appContext.google.mapsGeocodingApiKey);
    Geocode.setLocationType('GEOMETRIC_CENTER');
};

export const getGoogleMapsScript = () => {
    return window.googleMapsScript;
};
