import { createSetupIntent } from 'actions/stripe';
import { createBillectaInvoice } from 'actions/billecta';
import paymentProviders from 'enums/paymentProviders';
import purchaseItemTypes from 'enums/purchaseItemTypes';
import routes from 'routes';
import strings from 'localization/strings';
import { handleResponse } from 'actions/actionHelpers';
import { SeverityLevel } from '@microsoft/applicationinsights-web';
import { toAddressKeyValueObject, toDataContractAddress, getAddressOrDefault } from 'helpers/AddressHelper';
import { toCamelCase } from 'helpers/StringHelper';
import { removeQuerystringParameters } from 'helpers/BrowserHelper';
import { fetchPaymentIntent } from 'actions/stripe';
import queryString from 'query-string';
import { createGoogleTagManagerUserAction } from 'integration/google-tag-manager/googleTagManagerHelper';
import { updateTenantBooking } from 'actions/tenantBookings';
import { roundAmount, calculateVat } from 'helpers/MonetaryHelper';
import { calculateSum } from 'helpers/ArrayHelper';

export const makePayment = ({ payment, dispatch, type, booking, purchase, successUrlParameters, onFailure }) => {
    switch(payment.paymentProvider) {
        case paymentProviders.stripe.key:
            makeStripePayment({
                stripe: payment.stripe,
                type,
                booking,
                purchase,
                successUrlParameters,
                onFailure
            });
            break;

        case paymentProviders.billecta.key:
            makeBillectaPayment({
                billecta: payment.billecta,
                dispatch,
                type,
                booking,
                purchase,
                successUrlParameters,
                onFailure
            });
            break;

        default:
            break;
    }
};

const makeStripePayment = ({ stripe, type, booking, purchase, successUrlParameters, onFailure }) => {
    const reference = {
        type,
        bookingId: booking.id,
        immediateBookingRequestId: booking.immediateBookingRequestId,
        purchaseId: purchase?.id
    };
    const payWithSavedCard = stripe.paymentContext.savedCard && stripe.payWithSavedCard;

    // https://stripe.com/docs/js/payment_intents/confirm_payment
    const options = {
        confirmParams: {
            return_url: createPaymentSuccessUrl(booking, purchase, successUrlParameters, paymentProviders.stripe.key)
        }
    };

    if(payWithSavedCard) {
        options.clientSecret = stripe.paymentContext.clientSecret;
        options.confirmParams.payment_method = stripe.paymentContext.savedCard.paymentMethodId;
    } else {
        options.elements = stripe.paymentContext.elements;
    }

    stripe.paymentContext.stripe.confirmPayment(options)
        .then(response => {
            logErrorToApplicationInsights(response, reference);
            onFailure(response);
        }); // the promise only resolves on failure
};

const makeBillectaPayment = ({ billecta, dispatch, type, booking, purchase, successUrlParameters, onFailure }) => {
    const request = {
        type,
        bookingId: booking.id,
        purchaseId: purchase?.id,
        address: billecta.address
    };
    dispatch(createBillectaInvoice(request))
        .then(handleResponse(
            response => {
                const createdPurchase = response.payload.purchase;
                window.location.href = createPaymentSuccessUrl(booking, createdPurchase, successUrlParameters, paymentProviders.billecta.key);
            },
            response => {
                onFailure(response);
            }
        ));
};

export const updatePaymentSetup = ({ setup, dispatch, booking, successUrlParameters, onFailure }) => {
    const updateBookingRequest = {
        bookingStatus: booking.bookingStatus,
        storageId: booking.storageId,
        goodsType: booking.goodsType,
        registrationNumber: booking.registrationNumber,
        paymentProvider: setup.paymentProvider,
        invoiceAddress: setup.paymentProvider === paymentProviders.billecta.key
            ? setup.billecta.address
            : undefined
    };

    // first update the booking with the new payment provider
    dispatch(updateTenantBooking(booking.id, updateBookingRequest))
        .then(handleResponse(
            () => {
                switch(setup.paymentProvider) {
                    case paymentProviders.stripe.key:
                        // save the Stripe card details and redirect to success page
                        updateStripePaymentSetup({
                            setupContext: setup.stripe.setupContext,
                            dispatch,
                            booking,
                            successUrlParameters,
                            onFailure
                        });
                        break;
                    case paymentProviders.billecta.key:
                        // redirect to success page
                        window.location.href = createSetupSuccessUrl(booking, successUrlParameters, paymentProviders.billecta.key);
                        break;

                    default:
                        break;
                }
            },
            response => onFailure(response)
        ));
};

export const updateStripePaymentSetup = ({ setupContext, dispatch, booking, successUrlParameters, onFailure }) => {
    // first, we have to submit the Stripe elements
    setupContext.elements.submit()
        .then(response1 => {
            if(response1.error) {
                onFailure(response1);
            } else {
                // then, create the setup intent
                dispatch(createSetupIntent({ bookingId: booking.id }))
                    .then(handleResponse(
                        response2 => {
                            const setupIntent = response2.payload;
                            // finally, call confirmSetup, which will redirect to success page when completed
                            setupContext.stripe.confirmSetup({
                                elements: setupContext.elements,
                                clientSecret: setupIntent.clientSecret,
                                confirmParams: {
                                    return_url: createSetupSuccessUrl(booking, successUrlParameters, paymentProviders.stripe.key)
                                }
                            })
                            .then(response3 => {
                                onFailure(response3);
                            }); // the promise only resolves on failure
                        },
                        response2 => {
                            onFailure(response2);
                        }
                    ));
            }
        });
};


export const sanitizePaymentFormValues = (values, stripePaymentContext) => {
    if(!values.payment) {
        return values;
    }

    const sanitizedPayment = {
        ...values.payment,
        stripe: values.payment.paymentProvider === paymentProviders.stripe.key
            ? {
                ...values.payment.stripe,
                paymentContext: stripePaymentContext
            }
            : undefined,
        billecta: values.payment.paymentProvider === paymentProviders.billecta.key
            ? {
                ...values.payment.billecta,
                address: values.payment.billecta.collectAddress
                    ? toDataContractAddress(values.payment.billecta.address)
                    : undefined
            }
            : undefined
    };

    return {
        ...values,
        payment: sanitizedPayment
    };
};

export const sanitizeSetupFormValues = (values, stripeSetupContext) => {
    if(!values.setup) {
        return values;
    }

    return {
        ...values,
        setup: {
            ...values.setup,
            stripe: {
                ...values.setup.stripe,
                setupContext: stripeSetupContext
            },
            billecta: {
                ...values.setup.billecta,
                address: toDataContractAddress(values.setup.billecta.address)
            }
        }
    };
};

// returns an array of payment provider objects
export const getAvailablePaymentProviders = appContext => Object.values(paymentProviders)
    .filter(paymentProvider => appContext.payment.availablePaymentProviders.find(pp => toCamelCase(pp) === paymentProvider.key));

// returns an array of payment provider objects
export const getDefaultPaymentProviders = appContext => Object.values(paymentProviders)
    .filter(paymentProvider => appContext.payment.defaultPaymentProviders.find(pp => toCamelCase(pp) === paymentProvider.key));

export const createPaymentFormInitialValues = (options, appContext) => {
    const availablePaymentProviders = getAvailablePaymentProviders(appContext);
    const stripe = options.stripe ?? {};

    let paymentProviderKey = availablePaymentProviders.length === 1
        ? availablePaymentProviders[0].key
        : options.paymentProvider; // a string value

    if(paymentProviderKey && !availablePaymentProviders.find(paymentProvider => paymentProvider.key === paymentProviderKey)) {
        paymentProviderKey = availablePaymentProviders.length === 1
            ? availablePaymentProviders[0].key
            : undefined;
    }

    return {
        paymentProvider: paymentProviderKey ?? null,
        stripe: {
            ...stripe,
            payWithSavedCard: true
        },
        billecta: options.billecta
            ? {
                ...options.billecta,
                address: toAddressKeyValueObject(getAddressOrDefault(options.billecta.address, appContext))
            }
            : {}
    };
};

export const createSetupFormInitialValues = createPaymentFormInitialValues;

export const logErrorToApplicationInsights = (data, reference) => {
    if(window.applicationInsights) {
        window.applicationInsights.trackTrace({
            message: JSON.stringify(data),
            properties: { type: 'Payment', reference: JSON.stringify(reference) },
            severityLevel: SeverityLevel.Error
        });
    }
};

export const handlePaymentCompleted = ({ purchase, googleTagManagerMetadata, dispatch, history }) => {
    const querystringParameters = queryString.parse(history.location.search);
    removeQuerystringParameters(history, ['paymentCompleted', 'paymentProvider', 'purchaseId', 'immediateBookingRequestId', 'payment_intent', 'payment_intent_client_secret', 'redirect_status', 'action']); // cleanup URL parameters

    if(querystringParameters.action) {
        switch(querystringParameters.paymentProvider) {
            case paymentProviders.stripe.key:
                dispatch(fetchPaymentIntent(querystringParameters.payment_intent))
                    .then(handleResponse(
                        response => {
                            const paymentIntent = response.payload;
                            dispatch(createGoogleTagManagerUserAction(querystringParameters.action, googleTagManagerMetadata, paymentIntent.amount / 100 /* TODO: smallest currency unit; now assuming SEK or EUR */, paymentIntent.currency.toUpperCase()));
                        }
                    ));
                break;

            case paymentProviders.billecta.key:
                dispatch(createGoogleTagManagerUserAction(querystringParameters.action, googleTagManagerMetadata, purchase.amount, purchase.currency));
                break;

            default:
                break;
        }
    }
};

export const getPaymentCompletedDialogContent = querystringParameters => {
    switch(querystringParameters.paymentProvider) {
        case paymentProviders.stripe.key:
            return { title: strings.paymentSucceededTitle, body: strings.paymentSucceededBody, useAnimation: true };
        case paymentProviders.billecta.key:
            return { title: strings.billectaInvoiceCreationSucceededTitle, body: strings.billectaInvoiceCreationSucceededBody, useAnimation: true };
        default:
            return {};
    }
};

export const handleSetupCompleted = ({ history }) => {
    removeQuerystringParameters(history, ['setupCompleted', 'paymentProvider', 'setup_intent', 'setup_intent_client_secret', 'redirect_status']); // cleanup URL parameters
};

export const stripeFormIsIncomplete = (item, stripePaymentContext) => item.paymentProvider === paymentProviders.stripe.key && !stripePaymentContext.paymentElementComplete && !(item.stripe.payWithSavedCard && stripePaymentContext.savedCard);

const createPaymentSuccessUrl = (booking, purchase, parameters, paymentProvider) => {
    const url = `${window.location.protocol}//${window.location.host}${routes.account.tenantBookingDetails.replace(':bookingId', booking?.id)}`;
    const querystringParameters = {
        ...(parameters ?? {}),
        paymentCompleted: true,
        paymentProvider,
        purchaseId: purchase?.id,
        immediateBookingRequestId: booking.immediateBookingRequestId
    };
    return url + '?' + queryString.stringify(querystringParameters);
};

const createSetupSuccessUrl = (booking, parameters, paymentProvider) => {
    const url = `${window.location.protocol}//${window.location.host}${routes.account.tenantBookingDetails.replace(':bookingId', booking?.id)}`;
    const querystringParameters = {
        ...(parameters ?? {}),
        setupCompleted: true,
        paymentProvider
    };
    return url + '?' + queryString.stringify(querystringParameters);
};

export const getInvoiceFee = (paymentProvider, appContext) => {
    const zero = {
        amount: 0,
        vat: 0,
        currency: appContext.currency.code
    };
    let invoiceFee;

    if(!paymentProvider) {
        invoiceFee = zero;
    } else {
        const paymentProviderSettings = appContext.payment[paymentProvider];
        if(!paymentProviderSettings?.invoiceFee) {
            invoiceFee = zero;
        } else {
            invoiceFee =  {
                amount: paymentProviderSettings.invoiceFee,
                vat: calculateVat(paymentProviderSettings.invoiceFee, appContext.defaultVatRate, appContext)
            };
        }
    }
    return {
        ...invoiceFee,
        vatRate: appContext.defaultVatRate,
        amountIncludingVat: invoiceFee.amount + invoiceFee.vat
    };
};

export const getPurchaseAmountIncludingInvoiceFeeAndRoundingCorrection = (purchase, paymentProvider, appContext) => {
    const purchaseItems = getPurchaseItemsIncludingInvoiceFeeAndRoundingCorrection(purchase, paymentProvider, appContext);
    return calculateSum(purchaseItems, o => o.amount + o.vat);
};

export const getPurchaseItemsIncludingInvoiceFeeAndRoundingCorrection = (purchase, paymentProvider, appContext) => {
    return applyRoundingCorrection(
        applyInvoiceFee(purchase.purchaseItems, purchase.paymentProvider ?? paymentProvider, appContext),
        appContext
    );
};

export const getSelectedPaymentProvider = (booking, availablePaymentProviders) => {
    let selectedPaymentProvider = availablePaymentProviders.length === 1
        ? availablePaymentProviders[0]
        : booking?.paymentProvider;

    if(selectedPaymentProvider && !availablePaymentProviders.includes(selectedPaymentProvider)) {
        selectedPaymentProvider = undefined;
    }

    return selectedPaymentProvider;
};

export const getAvailableAndCurrentPaymentProviders = booking =>
    Object.keys(paymentProviders).filter(key => booking.storageGroup.storageSite.paymentProviders.includes(key) || key === booking.paymentProvider);

export const createRoundingCorrectionPurchaseItem = (roundingCorrection, appContext) => ({
    description: strings.roundingCorrection,
    amount: roundingCorrection,
    vat: 0,
    vatRate: 0,
    currency: appContext.currency.code,
    commissionRate: 1,
    type: purchaseItemTypes.roundingCorrection.key
});

const applyInvoiceFee = (purchaseItems, paymentProvider, appContext) => {
    const invoiceFee = getInvoiceFee(paymentProvider, appContext);
    const adjustedPurchaseItems = [...purchaseItems.filter(o => o.type !== purchaseItemTypes.invoiceFee.key)];

    if(invoiceFee.amount) {
        adjustedPurchaseItems.push({
            ...invoiceFee,
            commissionRate: 1,
            description: strings.invoiceFee,
            type: purchaseItemTypes.invoiceFee.key
        });
    }
    return adjustedPurchaseItems;
};

const applyRoundingCorrection = (purchaseItems, appContext) => {
    const adjustedPurchaseItems = [...purchaseItems.filter(o => o.type !== purchaseItemTypes.roundingCorrection.key)];
    const sum = calculateSum(adjustedPurchaseItems, o => o.amount + o.vat);
    const roundedAmount = roundAmount(sum, appContext, true /* useDisplayPriceRounding */);
    const roundingCorrection = roundedAmount - sum;

    if(roundingCorrection) {
        adjustedPurchaseItems.push(createRoundingCorrectionPurchaseItem(roundingCorrection, appContext));
    }
    return adjustedPurchaseItems;
};
