import React from 'react';
import PropTypes from 'prop-types';
import strings from 'localization/strings';
import { makeStyles } from 'styles/util';

import Box from '@material-ui/core/Box';
import BookingDetailsSectionCaption from './BookingDetailsSectionCaption';

const useStyles = makeStyles(({ theme, colors, fonts, styles }) => ({
    container: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        boxShadow: styles.boxShadow,
        borderRadius: styles.cardBorderRadius,
        backgroundColor: colors.lightestGrey,
        padding: theme.spacing(2),
        '& p': {
            margin: 0
        }
    },
    storageTitle: {
        fontSize: '22px',
        fontFamily: fonts.bold
    }
}));

const TenantStorageTitle = ({ booking }) => {
    const classes = useStyles();
    return (
        <Box className={classes.container}>
            <BookingDetailsSectionCaption>
                {strings.yourStorage}
            </BookingDetailsSectionCaption>
            <Box className={classes.storageTitle}>
                {booking.storage.title}
            </Box>
        </Box>
    );
};

TenantStorageTitle.propTypes = {
    booking: PropTypes.object.isRequired
};

export default TenantStorageTitle;
