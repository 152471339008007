import * as actionTypes from 'actions/stripe';

const initialState =  {
    stripeAccountInfo: undefined
};

const stripe = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.STRIPE_FETCH_ACCOUNT_INFO:
            return { ...state, stripeAccountInfo: undefined };
        case actionTypes.STRIPE_FETCH_ACCOUNT_INFO_SUCCESS:
            return { ...state, stripeAccountInfo: action.payload };

        default:
            return state;
    }
};

export default stripe;
