const contactTypes = {
    mainEmail: {},
    mainMobileTelephone: {},
    checkInEmail: {},
    checkInMobileTelephone: {},
    paymentDocumentEmail: {},
    invoiceEmail: {},
    monthlyTransactionReportEmail: {}
};

Object.keys(contactTypes).forEach(key => {
    contactTypes[key].key = key;
});

export default contactTypes;
