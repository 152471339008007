import { RSAA } from 'redux-api-middleware';
import { createRsaaTypes, createHeaders } from './actionHelpers';

export const FACILITIES_FETCH = 'FACILITIES_FETCH';
export const FACILITIES_FETCH_SUCCESS = 'FACILITIES_FETCH_SUCCESS';
export const FACILITIES_FETCH_FAILURE = 'FACILITIES_FETCH_FAILURE';

export const fetchFacilities = () => ({
    [RSAA]: {
        types: createRsaaTypes(FACILITIES_FETCH),
        endpoint: '/api/facilities',
        method: 'GET',
        headers: createHeaders(false)
    }
});
