import * as actionTypes from 'actions/facilities';

const initialState =  {
    loading: false,
    items: undefined
};

const facilities = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.FACILITIES_FETCH:
            return { ...state, loading: true, items: undefined };
        case actionTypes.FACILITIES_FETCH_SUCCESS:
            return { ...state, loading: false, items: action.payload };
        case actionTypes.FACILITIES_FETCH_FAILURE:
            return { ...state, loading: false, items: undefined };
        default:
            return state;
    }
};

export default facilities;
