import React from 'react';
import PropTypes from 'prop-types';
import strings from 'localization/strings';
import { makeStyles } from 'styles/util';

import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';

const useStyles = makeStyles(({ theme }) => ({
    sizeSelectorRow: {
        display: 'flex',
        padding: theme.spacing(1)
    },
    popover: {
        margin: theme.spacing(2),
    },
    buttonContainer: {
        display: 'flex',
        justifyContent: 'flex-end'
    }
}));

const WithStoragePopoverWrapper = (PopoverComponent) => {
    return props => {
        const classes = useStyles();
        return (
            <Box className={classes.popover}>
                <PopoverComponent {...props} />
                <Box className={classes.buttonContainer}>
                    <Button
                        aria-describedby="simple-popover"
                        className={classes.button}
                        type="submit"
                        color="primary"
                        variant="contained"
                    >
                        {strings.executeFiltering}
                    </Button>
                </Box>
            </Box>
        );
    };
};

WithStoragePopoverWrapper.propTypes = {
    children: PropTypes.node.isRequired
};

export default WithStoragePopoverWrapper;
