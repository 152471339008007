import React from 'react';
import strings from 'localization/strings';
import { convertToNumber, formatLength, formatArea } from 'helpers/StringHelper';
import rentalTypes from 'enums/rentalTypes';
import storageSiteFacilities from 'enums/storageSiteFacilities';
import { getPriceInfo, storageSiteHasBusinessesOnlyEligibleForVat } from './PriceCalculator';

import Number from 'common/Number';

const WIDTH_ADDITION = 0.5;
const LENGTH_ADDITION = 0.5;

export const calculateArea = (length, width, storageGroupCategory) => {
    const parsedLength = convertToNumber(length);
    const parsedWidth = convertToNumber(width);
    let adjustedLength = parsedLength;
    let adjustedWidth = parsedWidth;

    if(adjustedLength > 0) {
        adjustedLength += storageGroupCategory?.addPaddingWhenCalculatingArea ? LENGTH_ADDITION : 0;
    } else {
        adjustedLength = undefined;
    }
    if(adjustedWidth > 0) {
        adjustedWidth += storageGroupCategory?.addPaddingWhenCalculatingArea ? WIDTH_ADDITION : 0;
    } else {
        adjustedWidth = undefined;
    }
    return {
        length: parsedLength,
        width: parsedWidth,
        adjustedLength,
        adjustedWidth,
        area: parsedLength && parsedWidth
            ? parsedLength * parsedWidth
            : undefined,
        adjustedArea: adjustedLength && adjustedWidth
            ? adjustedLength * adjustedWidth
            : undefined,
        get areaDiffer() {
            return this.adjustedArea !== this.area;
        }
    };
};

export const getAreaAndPrice = (storageSite, storageGroupCategory, appContext) => {
    let areaRange;
    let ceilingHeightRange;
    let price;
    let pricePrefix = '';
    let storageGroup;
    const items = [];
    const indoor = !!storageSite.facilities.find(o => o === storageSiteFacilities.indoor.key);
    const storageGroups = storageSite.storageGroups.filter(sg => !storageGroupCategory || sg.category === storageGroupCategory.key);

    if(storageGroups.length === 1) {
        storageGroup = storageGroups[0];
        if(storageGroup.rentalType === rentalTypes.customArea.key) {
            areaRange = [storageGroup.minBookableArea, storageSite.area];
            pricePrefix = strings.from + ' ';
            price = (storageGroup.pricePerAreaUnit * storageGroup.minBookableArea);
        } else {
            areaRange = [storageGroup.storageArea, storageGroup.storageArea];
            price = storageGroup.pricePerStorage;
            if(storageGroup.storageLength && storageGroup.storageWidth) {
                items.push({title: strings.length, value: formatLength(storageGroup.storageLength, appContext, { numberOfDecimals: 1 })});
                items.push({title: strings.width, value: formatLength(storageGroup.storageWidth, appContext, { numberOfDecimals: 1 })});
            } else {
                items.push({title: strings.area, value: formatArea(storageGroup.storageArea, appContext, { numberOfDecimals: 1 })});
            }
        }
    } else {
        let minSize;
        let maxSize;
        let minCeilingHeight;
        let maxCeilingHeight;
        let minPrice;
        let hasUnlimitedMaxSize = false;

        storageGroups.forEach(sg => {
            if(sg.rentalType === rentalTypes.customArea.key) {
                const storagePriceByMinBooking = sg.pricePerAreaUnit * sg.minBookableArea;
                if(minSize === undefined || sg.minBookableArea < minSize) {
                    minSize = sg.minBookableArea;
                }
                if(minPrice === undefined || storagePriceByMinBooking < minPrice) {
                    minPrice = storagePriceByMinBooking;
                    storageGroup = sg;
                }
                hasUnlimitedMaxSize = true;
            } else {
                if(minSize === undefined || sg.storageArea < minSize) {
                    minSize = sg.storageArea;
                }
                if(maxSize === undefined || sg.storageArea > maxSize) {
                    maxSize = sg.storageArea;
                }
                if(minPrice === undefined || sg.pricePerStorage < minPrice) {
                    minPrice = sg.pricePerStorage;
                    storageGroup = sg;
                }
            }
            if(indoor) {
                if(minCeilingHeight === undefined || sg.ceilingHeight < minCeilingHeight) {
                    minCeilingHeight = sg.ceilingHeight;
                }
                if(maxCeilingHeight === undefined || sg.ceilingHeight > maxCeilingHeight) {
                    maxCeilingHeight = sg.ceilingHeight;
                }
            }
        });

        areaRange = [minSize, hasUnlimitedMaxSize ? storageSite.area : maxSize];
        ceilingHeightRange = [minCeilingHeight, maxCeilingHeight];
        pricePrefix = strings.from + ' ';
        price = minPrice;
    }

    if(!storageGroup) {
        return { items };
    }

    let areaString;
    if(areaRange[0] === areaRange[1]) {
        areaString = strings.formatString(strings.areaTemplate, <Number value={areaRange[0]} maxNumberOfDecimals={1}/>);
    } else if(!areaRange[1]) {
        areaString = <>{strings.formatString(strings.areaTemplate, <Number value={areaRange[0]} maxNumberOfDecimals={1}/>)} &ndash;</>;
    } else {
        areaString = strings.formatString(strings.areaTemplate, <><Number value={areaRange[0]} maxNumberOfDecimals={1}/> &ndash; <Number value={areaRange[1]} maxNumberOfDecimals={1}/></>);
    }

    let ceilingHeightString;
    if(ceilingHeightRange && ceilingHeightRange[0]) {
        if(ceilingHeightRange[0] === ceilingHeightRange[1]) {
            ceilingHeightString = strings.formatString(strings.lengthTemplate, <Number value={ceilingHeightRange[0]}/>);
        } else if(!ceilingHeightRange[1]) {
            ceilingHeightString = <>{strings.formatString(strings.lengthTemplate, <Number value={ceilingHeightRange[0]}/>)} &ndash;</>;
        } else {
            ceilingHeightString = strings.formatString(strings.lengthTemplate, <><Number value={ceilingHeightRange[0]}/> &ndash; <Number value={ceilingHeightRange[1]}/></>);
        }
    }
    if(ceilingHeightString) {
        items.push({ title: strings.ceilingHeight, value: ceilingHeightString });
    }

    if(indoor) {
        if(storageSite.doorHeight) {
            items.push({title: strings.doorHeight, value: formatLength(storageSite.doorHeight, appContext)});
        }
        if(storageSite.doorWidth) {
            items.push({title: strings.doorWidth, value: formatLength(storageSite.doorWidth, appContext)});
        }
        ceilingHeightRange = [storageGroup.ceilingHeight, storageGroup.ceilingHeight];
    }

    const priceInfo = getPriceInfo(price, appContext, storageGroup);

    const vatInformation = storageSiteHasBusinessesOnlyEligibleForVat(storageSite, storageGroup.category)
        ? strings.vatWillBeAddedForBusinesses
        : undefined;

    return {
        areaString,
        pricePrefix,
        vatInformation,
        items,
        ...priceInfo
    };
};
