import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from 'styles/util';

import Box from '@material-ui/core/Box';

const Container = ({ children, className, smMaxWidth = 'inherit', withPageMargins = false, ...rest }) => {
    const useStyles = makeStyles(({ theme }) => ({
        container: {
            maxWidth: '1440px',
            marginLeft: 'auto',
            marginRight: 'auto',
            marginTop: withPageMargins ? theme.spacing(7) : undefined,
            marginBottom: withPageMargins ? theme.spacing(7) : undefined,
            paddingLeft: '100px',
            paddingRight: '100px',
            width: '100%',
            [theme.breakpoints.down('sm')]: {
                maxWidth: smMaxWidth,
                paddingLeft: '30px',
                paddingRight: '30px',
                marginTop: withPageMargins ? theme.spacing(5) : undefined,
                marginBottom: withPageMargins ? theme.spacing(5) : undefined,
            },
            [theme.breakpoints.down('xs')]: {
                marginTop: withPageMargins ? theme.spacing(3) : undefined,
                marginBottom: withPageMargins ? theme.spacing(3) : undefined,
            }
        }
    }));

    const classes = useStyles();

    return (
        <Box {...rest} className={classes.container + ' ' + (className ?? '')}>
            {children}
        </Box>
    );
};

Container.propTypes = {
    className: PropTypes.string,
    smMaxWidth: PropTypes.string,
    withPageMargins: PropTypes.bool,
    children: PropTypes.node.isRequired
};

export default Container;
