import React from 'react';
import PropTypes from 'prop-types';
import { useAppContext } from 'context/AppContext';
import { makeStyles } from 'styles/util';
import { formatNumber } from 'helpers/StringHelper';

import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';

const FeatureHighlightNumber = ({ featureNumber, eyebrowText, numberDescription, ...rest }) => {
    const useStyles = makeStyles(({ theme, colors }) => ({
        root: {
            position: 'relative',
            display: 'flex',
            alignItems: 'flex-end',
            gap: theme.spacing(3.125),
            padding: theme.spacing(16, 0),
            [theme.breakpoints.down('xs')]: {
                padding: theme.spacing(10, 0),
            },
        },
        flexChild: {
            flex: `1 1 calc(50% - ${theme.spacing(3.125)}px / 2)`,
            position: 'relative',
        },
        featureNumber: {
            fontSize: theme.typography.pxToRem(80),
            lineHeight: 1,
            color: colors.white,
            textAlign: 'right',
            letterSpacing: '-3px',
            [theme.breakpoints.down('sm')]: {
                fontSize: theme.typography.pxToRem(55),
            },
            [theme.breakpoints.down('xs')]: {
                fontSize: theme.typography.pxToRem(40),
            },
        },
        eyebrow: {
            fontSize: theme.typography.pxToRem(25),
            fontWeight: 900,
            lineHeight: 1,
            [theme.breakpoints.down('sm')]: {
                fontSize: theme.typography.pxToRem(18),
            },
            [theme.breakpoints.down('xs')]: {
                fontSize: theme.typography.pxToRem(12),
            },
        },
        numberDescription: {
            fontSize: theme.typography.pxToRem(80),
            fontWeight: 400,
            lineHeight: 1,
            letterSpacing: '-2.337px',
            marginLeft: '-4px',
            color: colors.primaryColor,
            [theme.breakpoints.down('sm')]: {
                fontSize: theme.typography.pxToRem(55),
            },
            [theme.breakpoints.down('xs')]: {
                fontSize: theme.typography.pxToRem(40),
            },
        },
        irregularHexagon: {
            position: 'absolute',
            top: '50%',
            right: '-1rem',
            transform: 'translate3d(0, -50%, 0)',
            maxWidth: '100%',
            zIndex: -1,
            [theme.breakpoints.down('sm')]: {
                maxWidth: '210px'
            },
            [theme.breakpoints.down('xs')]: {
                maxWidth: '180px'
            },
            '@media (max-width: 400px)': {
                maxWidth: '110%'
            }
        }
    }));

    const classes = useStyles();
    const { appContext } = useAppContext();
    return (
        <Box className={classes.root} {...rest}>
            <Box className={classes.flexChild}>
                <img className={classes.irregularHexagon} src={appContext.images.irregularHexagon} alt="storage & office shape" width="300" height="192"/>
                <Typography variant="h2" className={classes.featureNumber}>{formatNumber(featureNumber, appContext)}</Typography>
            </Box>
            <Box className={classes.flexChild}>
                <Typography className={classes.eyebrow}>{eyebrowText}</Typography>
                <Typography className={classes.numberDescription}>{numberDescription}</Typography>
            </Box>
        </Box>
    );
};

FeatureHighlightNumber.propTypes = {
    featureNumber: PropTypes.number.isRequired,
    eyebrowText: PropTypes.string.isRequired,
    numberDescription: PropTypes.string.isRequired,
};

export default FeatureHighlightNumber;
