import React from 'react';
import PropTypes from 'prop-types';
import strings from 'localization/strings';
import { useAppContext } from 'context/AppContext';
import { formatAmount } from 'helpers/StringHelper';

const Amount = ({ value, currency, formatString = undefined, displayPriceRounding = undefined, toLocale = true, skipCurrencySuffix = false, skipThousandSeparators = false }) => {
    const { appContext } = useAppContext();
    let formattedAmount = formatAmount(value, currency, appContext, { displayPriceRounding, toLocale, skipCurrencySuffix, skipThousandSeparators });
    if(formatString) {
        formattedAmount = strings.formatString(formatString, formattedAmount);
    }
    return <>{formattedAmount}</>;
};

Amount.propTypes = {
    value: PropTypes.number,
    currency: PropTypes.string,
    formatString: PropTypes.string,
    displayPriceRounding: PropTypes.bool,
    toLocale: PropTypes.bool,
    skipCurrencySuffix: PropTypes.bool,
    skipThousandSeparators: PropTypes.bool
};

export default Amount;
