export const RESPONSES_SHOW_SUCCESS_DIALOG = 'RESPONSES_SHOW_SUCCESS_DIALOG';
export const RESPONSES_CLOSE_SUCCESS_DIALOG = 'RESPONSES_CLOSE_SUCCESS_DIALOG';
export const RESPONSES_SHOW_ERROR_DIALOG = 'RESPONSES_SHOW_ERROR_DIALOG';
export const RESPONSES_CLOSE_ERROR_DIALOG = 'RESPONSES_CLOSE_ERROR_DIALOG';
export const RESPONSES_SHOW_SWITCH_USER_DIALOG = 'RESPONSES_SHOW_SWITCH_USER_DIALOG';
export const RESPONSES_CLOSE_SWITCH_USER_DIALOG = 'RESPONSES_CLOSE_SWITCH_USER_DIALOG';
export const RESPONSES_SET_SUCCESS = 'RESPONSES_SET_SUCCESS';
export const RESPONSES_SET_ERROR = 'RESPONSES_SET_ERROR';

// response: an API response or a plain string
// dialogData: nullable, { title, body, onClose }
export const showSuccessDialog = dialogData => ({
    type: RESPONSES_SHOW_SUCCESS_DIALOG,
    dialogData
});

export const closeSuccessDialog = () => ({
    type: RESPONSES_CLOSE_SUCCESS_DIALOG
});

// response: an API response or a plain string
// dialogData: { title, body, suppressServerErrorMessage, onClose }
export const showErrorDialog = (response, dialogData) => ({
    type: RESPONSES_SHOW_ERROR_DIALOG,
    response,
    dialogData
});

export const closeErrorDialog = () => ({
    type: RESPONSES_CLOSE_ERROR_DIALOG
});

export const showSwitchActorDialog = dialogData => ({
    type: RESPONSES_SHOW_SWITCH_USER_DIALOG,
    dialogData
});

export const closeSwitchActorDialog = () => ({
    type: RESPONSES_CLOSE_SWITCH_USER_DIALOG
});

export const setSuccessResponse = message => ({
    type: RESPONSES_SET_SUCCESS,
    message
});

export const setErrorResponse = message => ({
    type: RESPONSES_SET_ERROR,
    message
});
