import React from 'react';
import PropTypes from 'prop-types';
import { useAppContext } from 'context/AppContext';
import { useForm } from 'react-final-form';
import { makeStyles } from 'styles/util';
import { getStorageGroupTitle, getStorageTitle } from 'helpers/StorageSiteHelper';
import { mustBeNumber, mustBeGreaterThan } from 'form/validation';
import { formatIsoDate } from 'helpers/DateHelper';
import rentalTypes from 'enums/rentalTypes';
import bookingStatuses from 'enums/bookingStatuses';
import { formatNumber, formatAmount } from 'helpers/StringHelper';
import { createEndAdornment } from 'helpers/FormHelper';

import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import { TextField, showErrorOnBlur } from 'mui-rff';
import MenuItem from '@material-ui/core/MenuItem';
import { OnChange } from 'react-final-form-listeners';

const useStyles = makeStyles(({ colors }) => ({
    notAvailable: {
        color: colors.textColorDisabled
    }
}));

const formatAmountOptions = { skipCurrencySuffix: true, skipThousandSeparators: true };
const formatNumberOptions = { skipThousandSeparators: true, sanitizeMinusSign: true };

const StorageEditor = ({ booking, storageSite }) => {
    const classes = useStyles();
    const form = useForm();
    const formValues = form.getState().values;

    const { appContext } = useAppContext();

    const selectedStorageGroup = storageSite.storageGroups
        .find(storageGroup => storageGroup.id === formValues.storageGroupId);

    const storageGroupListItems = storageSite.storageGroups
        .filter(storageGroup => storageGroup.rentalType !== rentalTypes.customArea.key)
        .map(storageGroup => ({
            value: storageGroup.id,
            title: getStorageGroupTitle(storageGroup, appContext, { useSpecialCustomAreaLabel: true })
        }));

    const availableStorageIds = storageSite.bookingCalendar.storageGroupBookingCalendars
        .find(sgbc => sgbc.storageGroupId === selectedStorageGroup.id)
        .storageBookingCalendars.filter(sbc => sbc.availableBookingIntervals.length === 1 && sbc.availableBookingIntervals[0].startDate === formatIsoDate(new Date()))
        .map(sbc => sbc.storageId);

    const storageListItems = [{ value: 0, title: '--- select ---' }]
        .concat(selectedStorageGroup.storages
            .map(storage => ({
                value: storage.id,
                title: getStorageTitle(storage, selectedStorageGroup, appContext),
                isAvailable: availableStorageIds.includes(storage.id)
            }))
        );

    const isCustomArea = selectedStorageGroup.rentalType === rentalTypes.customArea.key;

    const handleStorageGroupChange = newValue => {
        const newStorageGroup = storageSite.storageGroups.find(storageGroup => storageGroup.id === newValue);
        form.mutators.changeValue({ name: 'storageId', value: 0 });
        form.mutators.changeValue({ name: 'subscriptionPeriodStoragePrice', value: formatAmount(newStorageGroup.pricePerStorage, booking.currency, appContext, formatAmountOptions) });
        form.mutators.changeValue({ name: 'storageVatRate', value: formatNumber(100 * newStorageGroup.vatRate, appContext, formatNumberOptions) });
    };

    const getStorageIdRequired = () => formValues.bookingStatus !== bookingStatuses.requested.key;

    const getStorageIdFieldProps = () => formValues.bookingStatus === bookingStatuses.requested.key
        ? undefined
        : { validate: mustBeGreaterThan(0) };

    return (
        <Grid container spacing={2}>
            {
                !isCustomArea &&
                (
                    <>
                        <Grid item xs={12} md={6}>
                            <TextField
                                select
                                name="storageGroupId"
                                label="Storage group"
                                variant="outlined"
                            >
                                {
                                    storageGroupListItems.map(o => (
                                        <MenuItem key={o.value} value={o.value}>{o.title}</MenuItem>
                                    ))
                                }
                            </TextField>
                            <OnChange name="storageGroupId">
                                {handleStorageGroupChange}
                            </OnChange>
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <TextField
                                select
                                name="storageId"
                                label="Storage"
                                variant="outlined"
                                required={getStorageIdRequired()}
                                fieldProps={getStorageIdFieldProps()}
                            >
                                {
                                    storageListItems.map(o => (
                                        <MenuItem key={o.value} value={o.value}>
                                            <Box component="span" className={o.isAvailable ? '' : classes.notAvailable}>
                                                {o.title}
                                                {!o.isAvailable && o.value !== 0 && ' - not available'}
                                            </Box>
                                        </MenuItem>
                                    ))
                                }
                            </TextField>
                        </Grid>
                    </>
                )
            }

            <Grid item xs={12} md={6}>
                <TextField
                    name="subscriptionPeriodStoragePrice"
                    label="Monthly price"
                    helperText="Price excluding VAT."
                    variant="outlined"
                    showError={showErrorOnBlur}
                    required
                    fieldProps={{ validate: mustBeNumber }}
                    InputProps={createEndAdornment(booking.currency)}
                />
            </Grid>
            <Grid item xs={12} md={6}>
                <TextField
                    name="storageVatRate"
                    label="VAT rate"
                    variant="outlined"
                    showError={showErrorOnBlur}
                    required
                    fieldProps={{ validate: mustBeNumber }}
                    InputProps={createEndAdornment('%')}
                />
            </Grid>
        </Grid>
    );
};

StorageEditor.propTypes = {
    booking: PropTypes.object.isRequired,
    storageSite: PropTypes.object.isRequired
};

export default StorageEditor;
