import React from 'react';
import PropTypes from 'prop-types';
import { useAppContext } from 'context/AppContext';
import strings from 'localization/strings';
import { makeStyles } from 'styles/util';
import discountTypes from 'enums/discountTypes';
import { required, mustBeNumber } from 'form/validation';

import Box from '@material-ui/core/Box';
import { TextField, showErrorOnBlur } from 'mui-rff';
import MenuItem from '@material-ui/core/MenuItem';
import IconButton from '@material-ui/core/IconButton';
import DeleteOutlineOutlinedIcon from '@material-ui/icons/DeleteOutlineOutlined';

const useStyles = makeStyles(({ theme }) => ({
    row: {
        display: 'flex',
        flexWrap: 'wrap',
        marginBottom: theme.spacing(2),
        alignItems: 'center'
    },
    text: {
        flex: '1 0 40%',
        paddingRight: theme.spacing(2),
        [theme.breakpoints.down('sm')]: {
            flex: '1 0 100%',
            paddingRight: 0
        }
    },
    value: {
        flex: '1 0 20%',
        paddingRight: theme.spacing(2)
    },
    type: {
        flex: '1 0 20%',
        paddingRight: theme.spacing(2)
    },
    delete: {
        flex: '0 0 auto'
    }
}));

const DiscountEditor = ({ booking, discount, fieldNamePrefix, onRemove }) => {
    const classes = useStyles();
    const { appContext } = useAppContext();

    let valueLabel;
    let valuePlaceholder;
    if(discount.type === discountTypes.amount.key) {
        valueLabel = booking.subscriptionBooking ? strings.oneTimeAmount : strings.amount;
        valuePlaceholder = strings.enterAmountExcludingVatPlaceholder; // TODO: strings.enterAmountPlaceholder if not eligibleForVat
    } else {
        valueLabel = booking.subscriptionBooking ? strings.percentagePerMonth : strings.percentage;
    }

    const discountTypeListItems = [
        { key: discountTypes.amount.key, title: appContext.currency.label },
        { key: discountTypes.rate.key, title: '%' }
    ];

    return (
        <Box className={classes.row}>
           <Box className={classes.text}>
               <TextField
                    name={`${fieldNamePrefix}.description`}
                    label={strings.description}
                    variant="outlined"
                    showError={showErrorOnBlur}
                    required
                    fieldProps={{ validate: required }}
                />
            </Box>
            <Box className={classes.value}>
                {/* amount excluding VAT, or percentage  */}
                <TextField
                    name={`${fieldNamePrefix}.value`}
                    label={valueLabel}
                    placeholder={valuePlaceholder}
                    variant="outlined"
                    showError={showErrorOnBlur}
                    required
                    fieldProps={{ validate: mustBeNumber }}
                />
            </Box>
            {
                // TODO: add "eligible for VAT" checkbox, but only for business users
            }
            <Box className={classes.type}>
                <TextField
                    select
                    name={`${fieldNamePrefix}.type`}
                    label={strings.discountType}
                    variant="outlined"
                    disabled={!discount.typeChangeable}
                >
                    {
                        discountTypeListItems.map(o => (
                            <MenuItem key={o.key} value={o.key}>
                                {o.title}
                            </MenuItem>
                        ))
                    }
                </TextField>
            </Box>
            <IconButton
                className={classes.delete}
                onClick={onRemove}
            >
                <DeleteOutlineOutlinedIcon/>
            </IconButton>
        </Box>
    );
};

DiscountEditor.propTypes = {
    booking: PropTypes.object.isRequired,
    discount: PropTypes.object.isRequired,
    fieldNamePrefix: PropTypes.string.isRequired,
    onRemove: PropTypes.func
};

export default DiscountEditor;
