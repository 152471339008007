import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from 'styles/util';
import strings from 'localization/strings';
import bookingStatuses from 'enums/bookingStatuses';

import Box from '@material-ui/core/Box';
import Alert from '@material-ui/lab/Alert';
import LocalizedTimeAgo from 'common/LocalizedTimeAgo';

const BookingNextAction = ({ booking, className, isOwner = false, fullWidth = false }) => {
    const useStyles = makeStyles(() => ({
        nextAction: {
            display: fullWidth ? 'flex' : 'inline-flex'
        },
        bold: {
            fontWeight: 'bold'
        }
    }));
    const classes = useStyles();

    const formatNextAction = () => {
        let deadline;
        switch(booking.bookingStatus) {
            case bookingStatuses.requested.key:
                deadline = booking.confirmationDeadline;
                break;
            case bookingStatuses.confirmed.key:
                deadline = booking.initialPaymentDeadline;
                break;
            default:
                break;
        }

        const text = strings.formatString(strings.nextActions[isOwner ? 'owner' : 'tenant'][booking.bookingStatus], deadline ? <LocalizedTimeAgo date={deadline} className={classes.bold} /> : '');
        return text
            ? <Alert severity="info" className={classes.nextAction}>{text}</Alert>
            : null;
    };

    const nextAction = formatNextAction();

    return (
        nextAction && <Box className={className}>{nextAction}</Box>
    );
};

BookingNextAction.propTypes = {
    booking: PropTypes.object.isRequired,
    className: PropTypes.string,
    isOwner: PropTypes.bool,
    fullWidth: PropTypes.bool,
};

export default BookingNextAction;
