import React from 'react';
import strings from 'localization/strings';
import { makeStyles } from 'styles/util';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import {Divider} from '@material-ui/core';

const useStyles = makeStyles(({ theme, colors }) => ({
    list: {
        display: 'flex',
        gap: theme.spacing(2),
        flexDirection: 'column',
        margin: 0,
        padding: 0
    },
    step: {
        display: 'flex',
        flexDirection: 'column',
        gap: theme.spacing(1)
    },
    numberCircle: {
        backgroundColor: colors.primaryColor,
        color: colors.white,
        borderRadius: '16px',
        width: '32px',
        height: '32px',
        lineHeight: '32px',
        textAlign: 'center',
        fontWeight: 'bold',
        marginRight: theme.spacing(2),
        flex: '0 0 32px'
    },
    stepDescription: {
        flex: '1 0 0',
        display: 'flex',
        gap: theme.spacing(1),
        flexDirection: 'column'
    },
    divider: {
        margin: theme.spacing(3, 0)
    }
}));

const BookingSteps = () => {
    const classes = useStyles();
    const bookingSteps = strings.pages.bookingSteps;
    const steps = [bookingSteps.steps['1'], bookingSteps.steps['2'], bookingSteps.steps['3']];
    return (
        <Box paddingBottom={4}>
            <Typography variant="h4" gutterBottom>{bookingSteps.title}</Typography>
            <Typography variant="body2">{bookingSteps.body}</Typography>
            <Divider className={classes.divider} />
            <Typography variant="h4" gutterBottom>{bookingSteps.whyStorage365}</Typography>
            <ol className={classes.list}>
                {steps.map((step, index) => (
                    <li className={classes.step} key={index}>
                        <Box className={classes.numberCircle}>{index + 1}</Box>
                        <Box className={classes.stepDescription}>
                            <Typography variant="body2"><strong>{step.title}</strong></Typography>
                            <Typography variant="body2">{step.body}</Typography>
                        </Box>
                    </li>
                ))}
            </ol>
            <Divider className={classes.divider} />
            <Typography variant="body2" className={classes.paragraph}>{bookingSteps.contactUs}</Typography>
        </Box>
    );
};

export default BookingSteps;
