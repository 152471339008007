import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from 'styles/util';
import useMediaQuery from '@material-ui/core/useMediaQuery';

import Box from '@material-ui/core/Box';

const useStyles = (textColor, backgroundColor, isMobile, isTablet) => makeStyles(({ theme, fonts }) => ({
    container: {
        display: 'flex',
        flexDirection: 'column',
        flex: '1 1 50%',
        justifyContent: 'center',
        backgroundColor: backgroundColor,
        paddingLeft: theme.spacing(2.5),
        paddingRight: theme.spacing(2.5),
        paddingTop: isMobile ? 0 : theme.spacing(2),
        paddingBottom: isMobile ? 0 : theme.spacing(2)
    },
    title: {
        fontFamily: fonts.medium,
        color: textColor
    },
    infoText: {
        fontFamily: fonts.medium,
        fontSize: isTablet ? '18px' : '22px',
        lineHeight: isTablet ? '25.2px' : '30.8px',
        letterSpacing: isTablet ? '-0.54px' : '-0.66px',
        color: textColor
    }
}),
({ theme }) => ({
    storage: {
        container: {
            paddingTop: isMobile ? theme.spacing(1) : theme.spacing(2),
            paddingBottom: isMobile ? theme.spacing(1) : theme.spacing(2)
        }
    }
}));

const InfoLabel = ({ title, text, textColor, backgroundColor, forceSmall = false }) => {
    const isMobile = useMediaQuery(theme => theme.breakpoints.down('xs')) || forceSmall;
    const isTablet = useMediaQuery(theme => theme.breakpoints.down('sm'));

    const classes = useStyles(textColor, backgroundColor, isMobile, isTablet)();
    return (
        <Box className={classes.container}>
            {
                title &&
                (
                    <Box className={classes.title}>
                        {title}
                    </Box>
                )
            }
            <Box className={classes.infoText}>
                {text}
            </Box>
        </Box>
    );
};

InfoLabel.propTypes = {
    title: PropTypes.string,
    text: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.node
    ]),
    textColor: PropTypes.string,
    backgroundColor: PropTypes.string,
    forceSmall: PropTypes.bool
};


export default InfoLabel;
