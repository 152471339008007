import React, { useRef, useEffect, useState } from 'react';
import { useAppContext } from 'context/AppContext';
import { useParams, useHistory } from 'react-router';
import { makeStyles } from 'styles/util';
import { createFormDataForNewStorageSite, createStorageSiteRequestFromFormData, getAllStorageGroups } from 'logic/editStorageSiteLogic';
import { useDispatch, useSelector } from 'react-redux';
import { clearStorageSites } from 'actions/account/storageSitesSection';
import { createStorageSite } from 'actions/storageSites';
import storageSiteStatuses from 'enums/storageSiteStatuses';
import { handleResponse } from 'actions/actionHelpers';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import strings from 'localization/strings';
import { googleTagManagerConstants, createGoogleTagManagerUserAction } from 'integration/google-tag-manager/googleTagManagerHelper';
import routes from 'routes';
import multiplicities from 'enums/multiplicities';
import { scrollToTop } from 'helpers/BrowserHelper';

import Wizard from './Wizard';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import Container from 'common/Container';
import StepIndicator from './StepIndicator';
import EditStep0 from './EditStep0';
import EditStep1 from './EditStep1';
import EditStep2 from './EditStep2';
import EditStep3 from './EditStep3';
import EditStep4 from './EditStep4';
import EditStep5 from './EditStep5';
import EditStep7 from './EditStep7';
import FullScreenLoader from 'common/FullScreenLoader';
import DraftSubmittedDialog from './DraftSubmittedDialog';

const useStyles = makeStyles(({ theme, colors }) => ({
    headerContainer: {
        padding: theme.spacing(4, 0)
    },
    stepIndicatorBackground: {
        display: 'flex',
        '& > *': {
            height: '4px',
            flex: '0 0 50%'
        },
        '& > :first-child': {
            backgroundColor: colors.primaryColor,
        },
        '& > :last-child': {
            backgroundColor: colors.mediumGrey
        }
    },
    container: {
        marginTop: '-18px',
        marginBottom: theme.spacing(4),
    },
    outerStepContainer: {
        paddingTop: '18px'
    },
    innerStepContainer: {
        backgroundColor: colors.white,
        paddingTop: (theme.spacing(4) + 48) + 'px',
        paddingBottom: theme.spacing(4)
    }
}));

const CreateStorageSite = () => {
    const isMobile = !useMediaQuery(theme => theme.breakpoints.up('md'));
    const classes = useStyles();
    const { appContext } = useAppContext();

    const submitActions = {
        saveDraft: 'saveDraft',
        publish: 'publish'
    };

    const dispatch = useDispatch();
    const params = useParams();
    const step = parseInt(params.step || '1', 10) - 1; // zero-based
    const history = useHistory();
    const authenticationContext = useSelector(state => state.authentication.context);
    const selectedActor = authenticationContext.selectedActor;

    const [storageSiteFormData, setStorageSiteFormData] = useState(createFormDataForNewStorageSite(appContext));
    const [isSaving, setIsSaving] = useState(false);
    const [draftSubmittedDialogOpen, setDraftSubmittedDialogOpen] = useState(false);
    const [savedStorageSiteId, setSavedStorageSiteId] = useState(undefined);

    const scrollStartRef = useRef();
    useEffect(() => {
        if(isMobile && step !== 0) {
            const element = scrollStartRef.current;
            window.scrollTo(0, element.offsetTop + element.offsetHeight);
        }
    }, [step]);

    const handeStepChanged = (s, v) => {
        setStorageSiteFormData({...storageSiteFormData, ...v});
        scrollToTop();
        history.push(routes.account.createStorageSite.replace(':step', s + 1));
    };

    const handleSubmit = formValues => {
        setIsSaving(true);
        const baseValues = {
            ownerActorId: selectedActor.id,
            status: formValues.submitAction === submitActions.saveDraft
                ? storageSiteStatuses.unsubmittedDraft.key
                : storageSiteStatuses.submittedDraft.key,
            ownerType: selectedActor.organizationType
        };
        const createStorageSiteRequest = createStorageSiteRequestFromFormData(formValues, baseValues, selectedActor, appContext);

        dispatch(createStorageSite(createStorageSiteRequest))
            .then(handleResponse(
                response => {
                    dispatch(createGoogleTagManagerUserAction(googleTagManagerConstants.userActions.storageSiteCreated));
                    setIsSaving(false);
                    dispatch(clearStorageSites());
                    if(createStorageSiteRequest.status === storageSiteStatuses.submittedDraft.key) {
                        setDraftSubmittedDialogOpen(true);
                        setSavedStorageSiteId(response.payload.id);
                    } else {
                        redirectAfterSave(response.payload.id);
                    }
                    return { snackbar: { message: strings.storageSiteCreated }};
                },
                () => {
                    setIsSaving(false);
                }
            ));
    };

    const handleCancel = () => {
        history.push(routes.account.storageSites);
    };

    const handleDraftSubmittedDialogCloseClick = () => {
        setDraftSubmittedDialogOpen(false);
        redirectAfterSave(savedStorageSiteId);
    };

    const redirectAfterSave = storageSiteId => {
        history.push(routes.account.editStorageSite.replace(':storageSiteId', storageSiteId));
    };

    const stepTitles = [
        strings.stepTitles.typeOfStorage,
        strings.stepTitles.categoryAndSize,
        strings.stepTitles.location,
        strings.stepTitles.facilities,
        strings.stepTitles.openingDates,
        strings.stepTitles.textAndImage,
        strings.stepTitles.publish
    ];

    const lastStepButtons = [
        { label: strings.saveDraft, onClick: form => form.change('submitAction', submitActions.saveDraft) },
        { label: strings.publishStorageSite, onClick: form => form.change('submitAction', submitActions.publish) }
    ];

    const validateStep1 = values => {
        const noStorageGroupsAdded =
            values.steps[0].multiplicity === multiplicities.multiple &&
            getAllStorageGroups(values).filter(sg => !sg.isDraft).length === 0;

        if (noStorageGroupsAdded) {
            return { '': strings.atLeastOneCategoryIsRequired };
        }
        return undefined;
    };

    const validateStep2 = values => {
        if (!values.steps[2].location.lat || !values.steps[2].location.lng) {
            return { '': strings.locationIsRequired };
        }
        return undefined;
    };

    const validateStep5 = values => {
        if (values.steps[5].images.length === 0) {
            return { '': strings.atLeastOneImageIsRequired };
        }
        return undefined;
    };

    return (
        <>
            <FullScreenLoader open={isSaving} />
            <Box className={classes.headerContainer}>
                <Container>
                    <Box ref={scrollStartRef} className={classes.header}>
                        <Typography variant="h4" gutterBottom>
                            {strings.createStorageSite}
                        </Typography>
                        <Typography variant="body1" gutterBottom>
                            {strings.createStorageSiteDescription}
                        </Typography>
                    </Box>
                </Container>
            </Box>
            <Container className={classes.container}>
                {
                    storageSiteFormData && (
                        <>
                            <StepIndicator
                                currentStep={step}
                                numberOfSteps={7}
                                titles={stepTitles}
                            />
                            <Box className={classes.outerStepContainer}>
                                <Box className={classes.innerStepContainer}>
                                    <Wizard
                                        initialValues={storageSiteFormData}
                                        step={step}
                                        onStepChanged={handeStepChanged}
                                        onSubmit={handleSubmit}
                                        onCancel={handleCancel}
                                        lastStepButtons={lastStepButtons}
                                    >
                                        <Wizard.Step>
                                            <EditStep0 />
                                        </Wizard.Step>
                                        <Wizard.Step validate={validateStep1}>
                                            <EditStep1 />
                                        </Wizard.Step>
                                        <Wizard.Step validate={validateStep2}>
                                            <EditStep2 />
                                        </Wizard.Step>
                                        <Wizard.Step>
                                            <EditStep3 />
                                        </Wizard.Step>
                                        <Wizard.Step>
                                            <EditStep4 />
                                        </Wizard.Step>
                                        <Wizard.Step validate={validateStep5}>
                                            <EditStep5 />
                                        </Wizard.Step>
                                        <Wizard.Step>
                                            <EditStep7 />
                                        </Wizard.Step>
                                    </Wizard>
                                </Box>
                            </Box>
                        </>
                    )
                }
            </Container>

            <DraftSubmittedDialog open={draftSubmittedDialogOpen} onClose={handleDraftSubmittedDialogCloseClick} />
        </>
    );
};

CreateStorageSite.propTypes = {
};

export default CreateStorageSite;
