import * as actionTypes from 'actions/notifications';

const initialState = {
    unreadMessageCount: 0,
    ownerBookingAwaitingActionCount: 0,
    tenantBookingAwaitingActionCount: 0
};

const notifications = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.NOTIFICATIONS_FETCH_SUCCESS:
        case actionTypes.NOTIFICATIONS_SET_READ_SUCCESS:
            return { ...state, ...action.payload };
        default:
            return state;
    }
};

export default notifications;
