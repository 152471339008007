import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from 'styles/util';
import strings from 'localization/strings';


import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import DocumentList from 'common/DocumentList';
import WithDividerWrapper from 'common/WithDividerWrapper';

const useStyles = makeStyles(({ theme }) => ({
    documents: {
        flex: '1',
        '& h5': {
            marginBottom: theme.spacing(2),
            fontSize: theme.spacing(2.25),
            fontStyle: 'normal',
            fontWeight: 'bold',
            lineHeight: '140%',
            letterSpacing: '-0.01em',
        },
        [theme.breakpoints.down('sm')]: {
            borderTop: 'none',
            margin: 0,
            padding: theme.spacing(0, 2, 2, 2)
        }
    },
}));

const Documents = ({ documents }) => {
    const classes = useStyles();
    return (
        <Box className={classes.documents}>
            <Typography variant="h5" display="block" gutterBottom>{strings.documents}</Typography>
            <DocumentList documents={documents} />
        </Box>
    );
};

Documents.propTypes = {
    documents: PropTypes.array.isRequired
};

export default WithDividerWrapper(Documents);
