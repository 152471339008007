import React from 'react';
import PropTypes from 'prop-types';
import strings from 'localization/strings';

import Alert from '@material-ui/lab/Alert';
import MarginBox from 'common/MarginBox';

const ActorBlocker = ({ block = false, children }) => {
    if(!block) {
        return children;
    }
    return (
        <MarginBox top={4} bottom={4}>
            <Alert severity="error">
                {strings.userBlockedMessage}
            </Alert>
        </MarginBox>
    );
};

ActorBlocker.propTypes = {
    block: PropTypes.bool,
    children: PropTypes.node
};

export default ActorBlocker;
