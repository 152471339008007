import { RSAA } from 'redux-api-middleware';
import { createRsaaTypes, createHeaders } from './actionHelpers';
import queryString from 'query-string';

export const AUTHENTICATION_LOGIN = 'AUTHENTICATION_LOGIN';
export const AUTHENTICATION_LOGIN_SUCCESS = 'AUTHENTICATION_LOGIN_SUCCESS';
export const AUTHENTICATION_LOGIN_FAILURE = 'AUTHENTICATION_LOGIN_FAILURE';

export const AUTHENTICATION_IMPERSONATIONLOGOUT = 'AUTHENTICATION_IMPERSONATIONLOGOUT';
export const AUTHENTICATION_IMPERSONATIONLOGOUT_SUCCESS = 'AUTHENTICATION_IMPERSONATIONLOGOUT_SUCCESS';
export const AUTHENTICATION_IMPERSONATIONLOGOUT_FAILURE = 'AUTHENTICATION_IMPERSONATIONLOGOUT_FAILURE';

export const AUTHENTICATION_GET_CONTEXT = 'AUTHENTICATION_GET_CONTEXT';
export const AUTHENTICATION_GET_CONTEXT_SUCCESS = 'AUTHENTICATION_GET_CONTEXT_SUCCESS';
export const AUTHENTICATION_GET_CONTEXT_FAILURE = 'AUTHENTICATION_GET_CONTEXT_FAILURE';

export const AUTHENTICATION_SET_SELECTED_ACTOR = 'AUTHENTICATION_SET_SELECTED_ACTOR';
export const AUTHENTICATION_SET_SELECTED_ACTOR_SUCCESS = 'AUTHENTICATION_SET_SELECTED_ACTOR_SUCCESS';
export const AUTHENTICATION_SET_SELECTED_ACTOR_FAILURE = 'AUTHENTICATION_SET_SELECTED_ACTOR_FAILURE';

export const AUTHENTICATION_CREATE_EID_TEMPORARY_DATA = 'AUTHENTICATION_CREATE_EID_TEMPORARY_DATA';

export const AUTHENTICATION_UPDATE_EID_TEMPORARY_DATA = 'AUTHENTICATION_UPDATE_EID_TEMPORARY_DATA';

export const AUTHENTICATION_FETCH_EID_TEMPORARY_DATA = 'AUTHENTICATION_FETCH_EID_TEMPORARY_DATA';

export const AUTHENTICATION_SWEDISHBANKIDDETECTDEVICE = 'AUTHENTICATION_SWEDISHBANKIDDETECTDEVICE';

export const AUTHENTICATION_SWEDISHBANKIDAUTHENTICATE = 'AUTHENTICATION_SWEDISHBANKIDAUTHENTICATE';

export const AUTHENTICATION_SWEDISHBANKIDCOLLECT = 'AUTHENTICATION_SWEDISHBANKIDCOLLECT';

export const AUTHENTICATION_SWEDISHBANKIDCANCEL = 'AUTHENTICATION_SWEDISHBANKIDCANCEL';

export const AUTHENTICATION_EIDENTCREATEIDENTIFICATIONREQUESTURL = 'AUTHENTICATION_EIDENTCREATEIDENTIFICATIONREQUESTURL';

export const AUTHENTICATION_CREATE_MOCK_TEMPORARY_DATA = 'AUTHENTICATION_CREATE_MOCK_TEMPORARY_DATA';

export const AUTHENTICATION_SIGN_UP = 'AUTHENTICATION_SIGN_UP';
export const AUTHENTICATION_SIGN_UP_SUCCESS = 'AUTHENTICATION_SIGN_UP_SUCCESS';
export const AUTHENTICATION_SIGN_UP_FAILURE = 'AUTHENTICATION_SIGN_UP_FAILURE';

export const AUTHENTICATION_VALIDATE_PASSWORD = 'AUTHENTICATION_VALIDATE_PASSWORD';

export const AUTHENTICATION_LOGOUT = 'AUTHENTICATION_LOGOUT';

export const AUTHENTICATION_REFRESH_CONTEXT = 'AUTHENTICATION_REFRESH_CONTEXT';
export const AUTHENTICATION_REFRESH_CONTEXT_SUCCESS = 'AUTHENTICATION_REFRESH_CONTEXT_SUCCESS';

export const AUTHENTICATION_SETTOKEN = 'AUTHENTICATION_SETTOKEN';

export const AUTHENTICATION_SETREDIRECTURL = 'AUTHENTICATION_SETREDIRECTURL';
export const AUTHENTICATION_CLEARREDIRECTURL = 'AUTHENTICATION_CLEARREDIRECTURL';

export const AUTHENTICATION_SETLOGINPAGEMESSAGE = 'AUTHENTICATION_SETLOGINPAGEMESSAGE';
export const AUTHENTICATION_CLEARLOGINPAGEMESSAGE = 'AUTHENTICATION_CLEARLOGINPAGEMESSAGE';

export const AUTHENTICATION_SETINITIALLOGINDETAILS = 'AUTHENTICATION_SETINITIALLOGINDETAILS';

export const getAuthenticationContext = () => ({
    [RSAA]: {
        types: createRsaaTypes(AUTHENTICATION_GET_CONTEXT),
        endpoint: '/api/authentication/context',
        headers: createHeaders(false),
        method: 'GET'
    }
});

export const logIn = request => ({
    [RSAA]: {
        types: createRsaaTypes(AUTHENTICATION_LOGIN),
        endpoint: '/api/authentication/logIn',
        headers: createHeaders(true),
        method: 'POST',
        body: JSON.stringify(request)
    }
});

export const refreshAuthenticationContext = () => ({
    [RSAA]: {
        types: createRsaaTypes(AUTHENTICATION_REFRESH_CONTEXT),
        endpoint: '/api/authentication/context', // reusing the context endpoint
        headers: createHeaders(false),
        method: 'GET',
    }
});

export const impersonationLogOut = () => ({
    [RSAA]: {
        types: createRsaaTypes(AUTHENTICATION_IMPERSONATIONLOGOUT),
        endpoint: '/api/authentication/impersonationLogOut',
        headers: createHeaders(false),
        method: 'POST'
    }
});

export const setSelectedActor = actorId => ({
    [RSAA]: {
        types: createRsaaTypes(AUTHENTICATION_SET_SELECTED_ACTOR),
        endpoint: `/api/authentication/selectedActors/${actorId}`,
        headers: createHeaders(false),
        method: 'PUT'
    }
});

export const createEidTemporaryData = (provider, token, data) => ({
    [RSAA]: {
        types: createRsaaTypes(AUTHENTICATION_CREATE_EID_TEMPORARY_DATA),
        endpoint: `/api/authentication/eidTemporaryData/${provider}/${token}`,
        headers: createHeaders(true),
        method: 'POST',
        body: JSON.stringify(data)
    }
});

export const updateEidTemporaryData = (token, data) => ({
    [RSAA]: {
        types: createRsaaTypes(AUTHENTICATION_UPDATE_EID_TEMPORARY_DATA),
        endpoint: `/api/authentication/eidTemporaryData/${token}`,
        headers: createHeaders(true),
        method: 'PUT',
        body: JSON.stringify(data)
    }
});

export const fetchEidTemporaryData = (token, keepData = false) => ({
    [RSAA]: {
        types: createRsaaTypes(AUTHENTICATION_FETCH_EID_TEMPORARY_DATA),
        endpoint: `/api/authentication/eidTemporaryData/${token}?${queryString.stringify({ keepData })}`,
        headers: createHeaders(false),
        method: 'GET'
    }
});

export const swedishBankIdDetectDevice = (userAgent, platform, appVersion) => ({
    [RSAA]: {
        types: createRsaaTypes(AUTHENTICATION_SWEDISHBANKIDDETECTDEVICE),
        endpoint: `/api/swedishBankId/detectDevice/?${queryString.stringify({ userAgent, platform, appVersion })}`,
        headers: createHeaders(false),
        method: 'GET'
    }
});

export const swedishBankIdAuthenticate = request => ({
    [RSAA]: {
        types: createRsaaTypes(AUTHENTICATION_SWEDISHBANKIDAUTHENTICATE),
        endpoint: '/api/swedishBankId/authenticate',
        headers: createHeaders(true),
        method: 'POST',
        body: JSON.stringify(request)
    }
});

export const swedishBankIdCollect = request => ({
    [RSAA]: {
        types: createRsaaTypes(AUTHENTICATION_SWEDISHBANKIDCOLLECT),
        endpoint: '/api/swedishBankId/collect',
        headers: createHeaders(true),
        method: 'PUT',
        body: JSON.stringify(request)
    }
});

export const swedishBankIdCancel = protectedAuthenticationData => ({
    [RSAA]: {
        types: createRsaaTypes(AUTHENTICATION_SWEDISHBANKIDCANCEL),
        endpoint: `/api/swedishBankId/cancel/${encodeURIComponent(protectedAuthenticationData)}`,
        headers: createHeaders(false),
        method: 'PUT'
    }
});

export const eidentCreateIdentificationRequestUrl = request => ({
    [RSAA]: {
        types: createRsaaTypes(AUTHENTICATION_EIDENTCREATEIDENTIFICATIONREQUESTURL),
        endpoint: '/api/e-ident/identificationRequestUrl',
        headers: createHeaders(true),
        method: 'POST',
        body: JSON.stringify(request)
    }
});

export const createMockTemporaryData = request => ({
    [RSAA]: {
        types: createRsaaTypes(AUTHENTICATION_CREATE_MOCK_TEMPORARY_DATA),
        endpoint: '/api/authentication/mockTemporaryData',
        headers: createHeaders(true),
        method: 'POST',
        body: JSON.stringify(request)
    }
});

export const signUp = request => ({
    [RSAA]: {
        types: createRsaaTypes(AUTHENTICATION_SIGN_UP),
        endpoint: '/api/authentication/signUp',
        headers: createHeaders(true),
        method: 'POST',
        body: JSON.stringify(request)
    }
});

export const validatePassword = (type, password) => ({
    [RSAA]: {
        types: createRsaaTypes(AUTHENTICATION_VALIDATE_PASSWORD),
        endpoint: '/api/authentication/validatePassword',
        headers: createHeaders(true),
        method: 'PUT',
        body: JSON.stringify({ type, password })
    }
});

export const logOut = () => ({
    type: AUTHENTICATION_LOGOUT
});

export const setToken = token => ({
    type: AUTHENTICATION_SETTOKEN,
    token
});

export const setRedirectUrl = url => ({
    type: AUTHENTICATION_SETREDIRECTURL,
    url
});

export const clearRedirectUrl = () => ({
    type: AUTHENTICATION_CLEARREDIRECTURL
});

export const setLogInPageMessage = message => ({
    type: AUTHENTICATION_SETLOGINPAGEMESSAGE,
    message
});

export const clearLogInPageMessage = () => ({
    type: AUTHENTICATION_CLEARLOGINPAGEMESSAGE
});

export const setInitialLoginDetails = initialLoginDetails => ({
    type: AUTHENTICATION_SETINITIALLOGINDETAILS,
    initialLoginDetails
});
