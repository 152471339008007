import React from 'react';
import strings from 'localization/strings';
import { useDispatch, useSelector } from 'react-redux';
import { closeErrorDialog } from 'actions/responses';
import { getErrorMessage } from 'actions/actionHelpers';

import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import Alert from '@material-ui/lab/Alert';

const ErrorDialog = () => {
    const dispatch = useDispatch();
    const open = useSelector(state => state.responses.errorDialogOpen);
    const errorResponse = useSelector(state => state.responses.errorResponse);

    const { title, body, suppressServerErrorMessage = false, fullWidth = false, onClose } = useSelector(state => state.responses.errorDialogData) || {};

    const errorMessage = getErrorMessage(errorResponse);

    let adjustedBody;
    if(body) {
        adjustedBody = errorMessage && !suppressServerErrorMessage
            ? <>{body}<br/><br/><em>{errorMessage}</em></>
            : body;
    } else {
        adjustedBody = errorMessage;
    }

    const handleClose = () => {
        if(onClose) {
            onClose();
        }
        dispatch(closeErrorDialog());
    };

    return (
        <Dialog open={open} onClose={handleClose} fullWidth={fullWidth}>
            <DialogTitle disableTypography>
                <Typography variant="h5">
                    {title ?? strings.errorDialogTitle}
                </Typography>
            </DialogTitle>
            <DialogContent>
                <Alert severity="error">{adjustedBody}</Alert>
            </DialogContent>
            <DialogActions>
                <Button
                    type="button"
                    variant="contained"
                    color="primary"
                    onClick={handleClose}
                >
                    {strings.close}
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default ErrorDialog;
