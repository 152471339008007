import strings from 'localization/strings';

const goodsTypes = {
    car: {},
    motorcycle: {},
    recreationalVehicle: {},
    caravan: {},
    boat: {},
    snowmobile: {},
    jetski: {},
    trailer: {}
};

Object.keys(goodsTypes).forEach(key => {
    goodsTypes[key].key = key;
});

strings.addLoadCallback(() => {
    Object.values(goodsTypes).forEach(o => {
        o.title = strings.goodsTypes[o.key];
    });
});

export default goodsTypes;
