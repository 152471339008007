import { combineReducers } from 'redux';

import authentication from './authentication';
import bookingRequest from './bookingRequest';
import facilities from './facilities';
import localStorage from './localStorage';
import notificationWidgets from './notificationWidgets';
import notifications from './notifications';
import responses from './responses';
import stripe from './stripe';
import versions from './versions';
import viewStorageSites from './viewStorageSites';

import accountStorageSitesSection from './account/storageSitesSection';
import accountOwnerBookingsSection from './account/ownerBookingsSection';
import accountTenantBookingsSection from './account/tenantBookingsSection';
import accountConversationsSection from './account/conversationsSection';

import adminGeneral from './admin/general';
import adminUsers from './admin/users';
import adminActors from './admin/actors';
import adminBookings from './admin/bookings';
import adminConversations from './admin/conversations';
import adminPayouts from './admin/payouts';
import adminStorageSites from './admin/storageSites';
import adminTakeovers from './admin/takeovers';
import adminPurchases from './admin/purchases';
import adminSearchStatistics from './admin/searchStatistics';

export default {
    authentication,
    bookingRequest,
    facilities,
    localStorage,
    notificationWidgets,
    notifications,
    responses,
    stripe,
    versions,
    viewStorageSites,
    account: combineReducers({
        storageSitesSection: accountStorageSitesSection,
        ownerBookingsSection: accountOwnerBookingsSection,
        tenantBookingsSection: accountTenantBookingsSection,
        conversationsSection: accountConversationsSection
    }),
    admin: combineReducers({
        general: adminGeneral,
        users: adminUsers,
        actors: adminActors,
        bookings: adminBookings,
        conversations: adminConversations,
        payouts: adminPayouts,
        storageSites: adminStorageSites,
        takeovers: adminTakeovers,
        purchases: adminPurchases,
        searchStatistics: adminSearchStatistics
    })
};
