import React, { useEffect } from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { fetchTenantBookings } from 'actions/account/tenantBookingsSection';
import strings from 'localization/strings';
import queryString from 'query-string';
import { getTabs, getFilteredBookings, isStale } from './BookingsSectionHelper';
import routes from 'routes';

import BookingsSection from './BookingsSection';
import TenantBookingListItem from './TenantBookingListItem';

const TenantBookingsSection = () => {
    const dispatch = useDispatch();
    const history = useHistory();
    const location = useLocation();

    const authenticationContext = useSelector(state => state.authentication.context);
    const { selectedActor } = authenticationContext;
    const bookings = useSelector(state => state.account.tenantBookingsSection.bookings);
    const bookingsFetchedTime = useSelector(state => state.account.tenantBookingsSection.fetchedTime);
    const isLoading = useSelector(state => state.account.tenantBookingsSection.isLoading);
    const loadingError = !!useSelector(state => state.account.tenantBookingsSection.loadingError);

    useEffect(() => {
        if(isStale(bookingsFetchedTime)) {
            fetchBookings(true);
        }
    }, [isStale(bookingsFetchedTime)]);

    // reload every other minute, but without showing the loading indicator
    useEffect(() => {
        const timer = window.setInterval(() => fetchBookings(false), 120000);
        return () => window.clearInterval(timer);
    }, []);

    useEffect(() => {
        // if there is just one booking, redirect to that details page directly
        if(bookings?.length === 1) {
            history.push(routes.account.tenantBookingDetails.replace(':bookingId', bookings[0].id));
        }
    }, [bookings]);

    const fetchBookings = isLoadingValue => {
        dispatch(fetchTenantBookings(selectedActor.id, isLoadingValue));
    };

    const handleFilterChange = filter => {
        const q = queryString.stringify(filter);
        history.push(routes.account.tenantBookings + (q ? '?' + q : ''));
    };

    const filterParams = queryString.parse(location.search);

    const title = selectedActor.isTenant && selectedActor.isOwner
        ? strings.accountTabs.tenantBookings
        : strings.accountTabs.bookings;

    const tabs = getTabs();

    const filteredBookings = getFilteredBookings(bookings, filterParams);

    return (
        <BookingsSection
            bookings={bookings}
            filteredBookings={filteredBookings}
            tabs={tabs}
            isLoading={isLoading}
            loadingError={loadingError}
            onFilterChange={handleFilterChange}
            filterParams={filterParams}
            title={title}
            renderBooking={booking => <TenantBookingListItem booking={booking}/>}
        />
    );
};

export default TenantBookingsSection;
