import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useAppContext } from 'context/AppContext';
import { required } from 'form/validation';
import { makeStyles } from 'styles/util';
import { useForm, useField } from 'react-final-form';
import { locationToAddress, addressToLocation, getGeocodeResultForSinglePlace } from 'helpers/GeocodeHelper';
import strings from 'localization/strings';
import { getGoogleMapsScript } from 'helpers/MapHelper';

import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import { TextField, showErrorOnBlur } from 'mui-rff';
import { GoogleMap, Marker, Autocomplete } from '@react-google-maps/api';

const useStyles = makeStyles(({ theme }) => ({
    mapContainer: {
        width: '100%',
        height: '400px',
        margin: theme.spacing(2, 0, 4, 0)
    }
}));

const EditStep2 = ({ showStepNumber = true }) => {
    // for @react-google-maps/api docs, see https://react-google-maps-api-docs.netlify.app/
    const classes = useStyles();
    const form = useForm();
    const fieldNamePrefix = 'steps[2]';
    const googleMapsScript = getGoogleMapsScript();

    const { appContext } = useAppContext();

    const getName = suffix => suffix ? `${fieldNamePrefix}.${suffix}` : fieldNamePrefix;
    const getValue = suffix => useField(getName(suffix)).input.value;

    const street = getValue('street');
    const postalCode = getValue('postalCode');
    const city = getValue('city');
    const mapCenter = getValue('mapCenter');
    const mapZoom = getValue('mapZoom');
    const currentLocation = getValue('location');

    const [ map, setMap ] = useState(undefined);
    const [ addressAutocomplete, setAddressAutocomplete ] = useState(undefined);

    // keeping track of the previous values of the address fields helps us keep the number of requests to the geocode service down
    const [ streetBeforeBlur, setStreetBeforeBlur ] = useState(street);
    const [ postalCodeBeforeBlur, setPostalCodeBeforeBlur ] = useState(postalCode);
    const [ cityBeforeBlur, setCityBeforeBlur ] = useState(city);

    const onLoad = React.useCallback(mapInstance => setMap(mapInstance));

    const changeValue = (suffix, newValue) => {
        form.mutators.changeValue({ name: getName(suffix), value: newValue });
    };

    // setter for address fields in form
    const setAddress = address => {
        if(address) {
            changeValue('street', address.street);
            changeValue('postalCode', address.postalCode);
            changeValue('city', address.city);
        }
    };

    // setter for location marker
    const setLocation = (location, updateMapCenterAndZoom) => {
        if(location) {
            const sanitizedLocation = {
                lat: location.lat === undefined ? location.latitude : location.lat,
                lng: location.lng === undefined ? location.longitude : location.lng
            };
            if(sanitizedLocation.lat !== currentLocation.lat || sanitizedLocation.lng !== currentLocation.lng) {
                changeValue('location', sanitizedLocation);
                if (updateMapCenterAndZoom) {
                    changeValue('mapCenter', sanitizedLocation);
                    changeValue('mapZoom', 12);
                }
            }
        }
    };

    // event handler for address autocomplete item click
    const handleAddressAutocompletePlaceChange = place => {
        getGeocodeResultForSinglePlace(place, appContext, result => {
            setAddress(result.address);
            setLocation(result.location, true);
        });
    };

    // event handler for address field blur (street, postalCode, city)
    const handleAddressFieldBlur = () => {
        if(city || postalCode) {
            // which field was blurred?
            // ignore execution if setting to empty value
            let blurredField;
            if(street && street !== streetBeforeBlur) {
                blurredField = 'street';
            } else if(postalCode && postalCode !== postalCodeBeforeBlur) {
                blurredField = 'postalCode';
            } else if(city && city !== cityBeforeBlur) {
                blurredField = 'city';
            }

            if(blurredField) {
                addressToLocation(
                    (street || '') + ', ' + (postalCode || '') + ' ' + (city || ''), // concatenated address string to send to geocode service
                    appContext,
                    result => {
                        if (result.location) {
                            // only update location if the address field that was updated actually was recognized in the geocode service
                            if(result.address && !result.address[blurredField]) {
                                return;
                            }
                            setLocation({ lat: result.location.latitude, lng: result.location.longitude }, true);
                        }
                        // set fields that were empty before
                        if (result.address) {
                            if(!postalCode) {
                                changeValue('postalCode', result.address.postalCode);
                                setPostalCodeBeforeBlur(result.address.postalCode);
                            }
                            if(!city) {
                                changeValue('city', result.address.city);
                                setCityBeforeBlur(result.address.city);
                            }
                        }
                    }
                );
                setStreetBeforeBlur(street);
                setPostalCodeBeforeBlur(postalCode);
                setCityBeforeBlur(city);
            }
        }
    };

    const handleMapClick = e => {
        const newLocation = {
            lat: e.latLng.lat(),
            lng: e.latLng.lng()
        };
        locationToAddress(
            { latitude: newLocation.lat, longitude: newLocation.lng },
            appContext,
            result => setAddress(result.address)
        );
        setLocation(newLocation, false);
    };

    const handleMapCenterChange = () => {
        if(map) {
            const center = map.getCenter();
            const newMapCenter = { lat: center.lat(), lng: center.lng() };

            if(mapCenter.lat !== newMapCenter.lat || mapCenter.lng !== newMapCenter.lng) {
                changeValue('mapCenter', newMapCenter);
            }
        }
    };

    const handleMapZoomChange = () => {
        if(map) {
            changeValue('mapZoom', map.getZoom());
        }
    };

    let location = getValue('location');
    if (location.lat === undefined || location.lng === undefined) {
        location = undefined;
    }

    const renderMap = () => {
        const options = {
            streetViewControl: false
        };

        return (
            <GoogleMap
                mapContainerClassName={classes.mapContainer}
                center={mapCenter}
                zoom={mapZoom}
                options={options}
                onLoad={onLoad}
                onClick={handleMapClick}
                onCenterChanged={handleMapCenterChange}
                onZoomChanged={handleMapZoomChange}>
                {
                    location && <Marker position={location} />
                }
            </GoogleMap>
        );
    };

    return (
        <Box>
            <Typography variant="h5" display="block" gutterBottom>
                { showStepNumber && '3. ' }
                {strings.address}
            </Typography>

            <Grid container spacing={2}>
                <Grid item xs={12}>
                    {
                        googleMapsScript.isLoaded &&
                        (
                            <Autocomplete
                                onLoad={ref => setAddressAutocomplete(ref)}
                                onPlaceChanged={() => handleAddressAutocompletePlaceChange(addressAutocomplete.getPlace())}
                            >
                                <>
                                    <TextField
                                        name={getName('street')}
                                        label={strings.street}
                                        variant="outlined"
                                        showError={showErrorOnBlur}
                                        required
                                        fieldProps={{ validate: required }}
                                        onBlur={handleAddressFieldBlur}
                                    />
                                </>
                            </Autocomplete>
                        )
                    }
                </Grid>
            </Grid>
            <Grid container spacing={2}>
                <Grid item xs={12} md={3}>
                    <TextField
                        name={getName('postalCode')}
                        label={strings.postalCode}
                        variant="outlined"
                        showError={showErrorOnBlur}
                        required
                        fieldProps={{ validate: required }}
                        onBlur={handleAddressFieldBlur}
                    />
                </Grid>
                <Grid item xs={12} md={9}>
                    <TextField
                        name={getName('city')}
                        label={strings.city}
                        variant="outlined"
                        showError={showErrorOnBlur}
                        required
                        fieldProps={{ validate: required }}
                        onBlur={handleAddressFieldBlur}
                    />
                </Grid>
                {
                    appContext.usePropertyReferences &&
                    (
                        <Grid item xs={12}>
                            <TextField
                                name={getName('propertyReference')}
                                label={strings.propertyReference}
                                variant="outlined"
                            />
                        </Grid>
                    )
                }
            </Grid>

            { googleMapsScript.isLoaded && renderMap() }
        </Box>
    );
};

EditStep2.propTypes = {
    showStepNumber: PropTypes.bool
};

export default EditStep2;
