import React from 'react';
import PropTypes from 'prop-types';

import PasswordProtector from 'common/PasswordProtector';

const SitePasswordProtector = ({ children }) => {
    return (
        <PasswordProtector passwordType="site" localhostPassthrough>
            {children}
        </PasswordProtector>
    );
};

SitePasswordProtector.propTypes = {
    children: PropTypes.node.isRequired
};

export default SitePasswordProtector;
