import React from 'react';
import PropTypes from 'prop-types';
import strings from 'localization/strings';
import { makeStyles } from 'styles/util';
import { getContact, getBusinessOrPrivateName } from 'helpers/ActorHelper';
import contactTypes from 'enums/contactTypes';

import Box from '@material-ui/core/Box';

const useStyles = makeStyles(({ colors }) => ({
    sellerDetailsBox: {
        border: `3px solid ${colors.black}`,
        borderRadius: '0.5em',
        padding: '1em'
    },
    sellerColumns: {
        display: 'flex',
        marginBottom: '1em',
        '& > *': {
            flex: '1 1 auto',
            justifySelf: 'flex-start'
        }
    },
    sellerLabel: {
        fontWeight: 'bold'
    },
    sellerValue: {
        marginBottom: '0.25em',
    },
    taxInformation: {
        textAlign: 'right'
    }
}));

const PostInvoiceSellerDetails = ({ purchase }) => {
    const classes = useStyles();

    const ownerActor = purchase.booking.ownerActor;

    return (
        <Box className={classes.sellerDetailsBox}>
            <Box className={classes.sellerColumns}>
                <Box>
                    <Box className={classes.sellerLabel}>
                        {strings.paymentDocuments.sellerContactDetails}
                    </Box>
                    <Box className={classes.sellerValue}>
                        {getBusinessOrPrivateName(ownerActor)}
                    </Box>
                </Box>

                <Box>
                    <Box className={classes.sellerLabel}>
                        {strings.phone}
                    </Box>
                    <Box className={classes.sellerValue}>
                        {getContact(ownerActor, [contactTypes.checkInMobileTelephone, contactTypes.mainMobileTelephone])}
                    </Box>
                </Box>

                <Box>
                    {
                        ownerActor.organizationNumber &&
                        (
                            <>
                                <Box className={classes.sellerLabel}>
                                    {strings.organizationNumber}
                                </Box>
                                <Box className={classes.sellerValue}>
                                    {ownerActor.organizationNumber}
                                </Box>
                            </>
                        )
                    }
                    {
                        ownerActor.VatIdentificationNumber &&
                        (
                            <>
                                <Box className={classes.sellerLabel}>
                                    {strings.paymentDocuments.vatIdentificationNumber}
                                </Box>
                                <Box className={classes.sellerValue}>
                                    {ownerActor.vatIdentificationNumber}
                                </Box>
                            </>
                        )
                    }
                </Box>

                <Box>
                    <Box className={classes.sellerLabel}>
                        {strings.email}
                    </Box>
                    <Box className={classes.sellerValue}>
                        {getContact(ownerActor, [contactTypes.checkInEmail, contactTypes.mainEmail])}
                    </Box>
                </Box>
            </Box>

            <Box className={classes.taxInformation}>
                {strings.paymentDocuments.taxInformation}
            </Box>
        </Box>

    );
};

PostInvoiceSellerDetails.propTypes = {
    purchase: PropTypes.object.isRequired
};

export default PostInvoiceSellerDetails;
