import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from 'styles/util';
import pdfIcon from 'assets/icons/pdf.svg';

import Box from '@material-ui/core/Box';

const useStyles = makeStyles(({ theme }) => ({
    documentContainer: {
        display: 'flex',
        alignItems: 'center',
        marginBottom: theme.spacing(2),
    },
    pdfIcon: {
        flex: '0 0 auto',
        width: '3em',
        marginRight: theme.spacing(2)
    },
    description: {
        flex: '1 0 auto'
    }
}));

const getUrl = document => document.baseUrl && document.mediaUrl
    ? document.baseUrl + document.mediaUrl
    : document.mediaUrl;

const DocumentList = ({ documents }) => {
    const classes = useStyles();
    return (
        <Box>
            {
                documents.map((document, i) => (
                    <a key={i} href={getUrl(document)} target="_blank">
                        <Box className={classes.documentContainer}>
                            <img src={pdfIcon} className={classes.pdfIcon} />
                            {
                                document.description && <Box>{document.description}</Box>
                            }
                        </Box>
                    </a>
                ))
            }
        </Box>
    );
};

DocumentList.propTypes = {
    documents: PropTypes.array.isRequired
};

export default DocumentList;
