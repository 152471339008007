import React from 'react';
import { useAppContext } from 'context/AppContext';
import { makeStyles } from 'styles/util';
import { formFieldKeys } from 'helpers/StorageSearchFormHelper';

import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import { Checkboxes } from 'mui-rff';
import WithStoragePopoverWrapper from 'components/storageSearchForm/WithStoragePopoverWrapper';
import PropTypes from 'prop-types';
import additionalFilterTypes from 'enums/additionalFilterTypes';

const useStyles = makeStyles(({ theme }) => ({
    wrapper: {
        marginBottom: theme.spacing(1)
    },
    additionalFilterOptions: {
        display: 'flex',
        '& div': {
            paddingRight: theme.spacing(1)
        },
        '& .MuiFormControlLabel-root': {
            display: 'flex',
            flex: '0 1 150px',
            '& span': {
                whiteSpace: 'nowrap'
            }
        },
        '& .MuiFormGroup-root': {
            flexDirection: 'row',
            maxWidth: theme.spacing(80)
        },
        [theme.breakpoints.down('md')]: {
            flexDirection: 'column'
        }
    },
    caption: {
        fontWeight: 'bold',
        fontSize: '80%'
    }
}));

const AdditionalFiltersSelector = ({ landingPage }) => {
    const classes = useStyles();
    const { appContext } = useAppContext();

    return (
        <Box>
            {
                Object.entries(additionalFilterTypes(landingPage, appContext)).map(([key, type]) => {
                    return type.show && (
                        <Box key={key} className={classes.wrapper}>
                            <Typography className={classes.caption}>{type.title}</Typography>
                            <Box className={classes.additionalFilterOptions}>
                                {
                                    type.values.map((value, index) => {
                                        const isArray = Array.isArray(value);
                                        return value && <Checkboxes
                                            key={`${key}-${index}`}
                                            name={isArray ? formFieldKeys[key] : formFieldKeys[value.key]}
                                            data={isArray ?
                                                value.map(val => ({label: val.title, value: val.key})) :
                                                {label: value.title, value: true}
                                            }
                                        />;
                                    })
                                }
                            </Box>
                        </Box>
                    );
                })
            }
        </Box>
    );
};

AdditionalFiltersSelector.propTypes = {
    landingPage: PropTypes.object.isRequired
};

export default WithStoragePopoverWrapper(AdditionalFiltersSelector);
