import * as actionTypes from 'actions/versions';

const initialState = {
    apiVersion: undefined
};

const versions = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.VERSIONS_SET_API_VERSION:
            return { ...state, apiVersion: action.version };
        default:
            return state;
    }
};

export default versions;
