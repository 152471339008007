import React from 'react';
import PropTypes from 'prop-types';
import strings from 'localization/strings';
import { useAppContext } from 'context/AppContext';
import { makeStyles } from 'styles/util';

import StorageSiteListItem from 'components/storageSiteList/StorageSiteListItem';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';

const useStyles = makeStyles(({ theme, colors }) => ({
    container: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        position: 'relative',
        [theme.breakpoints.up('md')]: {
            marginTop: '-100px',
        },
        [theme.breakpoints.down('md')]: {
            flexDirection: 'column'
        },
        '& a': {
            pointerEvents: 'none',
            cursor: 'default'
        },
        '& div[class*="areaAndPriceContainer"] > div:last-child': {
            backgroundColor: colors.darkBlue
        },
        '& div[class*="placeLogoContainer"]': {
            paddingTop: theme.spacing(6)
        }
    },
    infoArrow: {
        position: 'absolute',
        left: '100%',
        bottom: 0,
        maxWidth: '100px',
        transform: 'translateX(-20%)',
        [theme.breakpoints.down('md')]: {
            display: 'none'
        }
    },
    infoText: {
        marginTop: theme.spacing(2),
        [theme.breakpoints.up('lg')]: {
            display: 'none'
        }
    }
}));

const MockStorageSite = ({ mockData, storageGroupCategory }) => {
    const { appContext } = useAppContext();
    const classes = useStyles();

    // Set anchor point instead of url since StorageSiteListItem uses Link component
    const storageSiteLinkCreator = () => {
        return '#mock-storage-site';
    };

    return (
        <Box className={classes.container}>
            <StorageSiteListItem
                storageSite={mockData}
                storageGroupCategory={storageGroupCategory}
                storageSiteLinkCreator={storageSiteLinkCreator}
                forceSmall={true}
                showStreetAddress
            />
            <Box className={classes.infoArrow}>
                <img src={appContext.images.curvedArrow} alt={strings.mockAdInfoArrowAlt} />
                <Typography variant="body1">{strings.mockAdInfoArrowText}</Typography>
            </Box>
            <Box className={classes.infoText}>
                <Typography variant="body1">{strings.mockAdInfoArrowText}</Typography>
            </Box>
        </Box>
    );
};

MockStorageSite.propTypes = {
    mockData: PropTypes.object,
    storageGroupCategory: PropTypes.object,
};

export default MockStorageSite;
