import React from 'react';
import PropTypes from 'prop-types';
import { useAppContext } from 'context/AppContext';
import strings from 'localization/strings';
import { createFormDataForNewAdditionalService } from 'logic/editStorageSiteLogic';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { makeStyles } from 'styles/util';

import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import AdditionalService from './AdditionalService';
import { FieldArray } from 'react-final-form-arrays';

const useStyles = makeStyles(({ theme, colors }) => ({
    container: {
        display: 'table',
        width: '100%',
        margin: theme.spacing(2, 0, 1, 0),
        '& > *': {
            display: 'table-row',
            '& > *': {
                display: 'table-cell',
                borderTop: `1.5px solid ${colors.mediumGrey}`,
                padding: theme.spacing(2, 2, 2, 0)
            },
            '& > :last-child': {
                paddingRight: 0
            },
            '&:last-child > *': {
                borderBottom: `1.5px solid ${colors.mediumGrey}`
            }
        }
    },
    xsContainer: {
        margin: theme.spacing(2, 0, 1, 0),
        '& > *': {
            borderTop: `1.5px solid ${colors.mediumGrey}`,
            padding: theme.spacing(2, 0),
            display: 'flex',
            alignItems: 'center',
            '& > :first-child': {
                flex: '1 1 auto',
                '& > :last-child': {
                    display: 'flex',
                    fontSize: '75%',
                    marginTop: theme.spacing(1),
                    '& > *': {
                        flex: '0 0 33.3%',
                        paddingRight: theme.spacing()
                    }
                }
            },
            '& > :last-child': {
                flex: '0 0 auto'
            }
        },
        '& > *:last-child': {
            borderBottom: `1.5px solid ${colors.mediumGrey}`
        }
    },
    addButton: {
        marginTop: theme.spacing(1)
    }
}));

const AdditionalServiceList = ({ fieldNamePrefix, actor, showExclusiveVatSuffix = false, editable = false }) => {
    const classes = useStyles();
    const { appContext } = useAppContext();

    const getName = suffix => suffix ? `${fieldNamePrefix}.${suffix}` : fieldNamePrefix;

    const handleAddAdditionalService = fields => {
        const newAdditionalService = createFormDataForNewAdditionalService(actor, appContext);
        newAdditionalService.editor = {...newAdditionalService};
        fields.push(newAdditionalService);
    };

    const handleRemoveAdditionalService = (fields, index) => {
        fields.remove(index);
    };

    const buttonFullWidth = !useMediaQuery(theme => theme.breakpoints.up('md'));

    const additionalServiceDoubleRows = !useMediaQuery(theme => theme.breakpoints.up('md'));

    return (
        <FieldArray name={getName('additionalServices')}>
            {({ fields }) => (
                <>
                    {
                        fields.length > 0 &&
                        (
                            <Box className={additionalServiceDoubleRows ? classes.xsContainer : classes.container}>
                                {
                                    fields.map((name, index) => (
                                        <AdditionalService
                                            key={name}
                                            fieldNamePrefix={name}
                                            doubleRows={additionalServiceDoubleRows}
                                            onRemove={() => handleRemoveAdditionalService(fields, index)}
                                            showExclusiveVatSuffix={showExclusiveVatSuffix}
                                            editable={editable}
                                            actor={actor}
                                        />
                                    ))
                                }
                            </Box>
                            )
                    }
                    {
                        editable &&
                        (
                            <Button
                                className={classes.addButton}
                                variant="contained"
                                color="secondary"
                                onClick={() => handleAddAdditionalService(fields)}
                                fullWidth={buttonFullWidth}
                            >
                                {strings.addAdditionalService}
                            </Button>
                        )
                    }
                </>
            )}
        </FieldArray>
    );
};

AdditionalServiceList.propTypes = {
    fieldNamePrefix: PropTypes.string.isRequired,
    actor: PropTypes.object.isRequired,
    showExclusiveVatSuffix: PropTypes.bool,
    editable: PropTypes.bool
};

export default AdditionalServiceList;
