import React from 'react';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';
import AccountLink from 'common/AccountLink';

const AccountNavigation = ({ routes }) => {
    return (
        <Grid container spacing={4}>
            {routes
                .filter((route) => route.visible)
                .map((route) => (
                    <Grid item xs={12} sm={6} key={route.path}>
                        {route.badge ? (
                            <AccountLink to={route.path} badge={route.badge}>
                                {route.label}
                            </AccountLink>
                        ) : (
                            <AccountLink to={route.path}>
                                {route.label}
                            </AccountLink>
                        )}
                    </Grid>
                ))}
        </Grid>
    );
};

AccountNavigation.propTypes = {
    routes: PropTypes.array.isRequired,
};

export default AccountNavigation;
