import * as actionTypes from 'actions/account/tenantBookingsSection';
import * as authenticationActionTypes from 'actions/authentication';

const initialState =  {
    bookings: undefined,
    isLoading: false,
    loadingError: undefined,
    fetchedTime: 0
};

const tenantBookingsSection = (state = initialState, action) => {
    const now = new Date().getTime();
    switch (action.type) {
        case actionTypes.ACCOUNT_TENANT_BOOKINGS_SECTION_LIST_BOOKINGS:
            return { ...state, isLoading: action.meta.isLoading, bookings: action.meta.isLoading ? undefined : state.bookings, loadingError: undefined, fetchedTime: undefined };
        case actionTypes.ACCOUNT_TENANT_BOOKINGS_SECTION_LIST_BOOKINGS_SUCCESS:
            return { ...state, isLoading: false, bookings: action.payload, fetchedTime: now };
        case actionTypes.ACCOUNT_TENANT_BOOKINGS_SECTION_LIST_BOOKINGS_FAILURE:
            return { ...state, isLoading: false, bookings: undefined, loadingError: action.payload, fetchedTime: undefined };

        case authenticationActionTypes.AUTHENTICATION_LOGIN:
        case authenticationActionTypes.AUTHENTICATION_LOGOUT:
        case authenticationActionTypes.AUTHENTICATION_IMPERSONATIONLOGOUT:
        case authenticationActionTypes.AUTHENTICATION_GET_CONTEXT:
        case authenticationActionTypes.AUTHENTICATION_REFRESH_CONTEXT_SUCCESS:
            return { ...initialState };

        default:
            return state;
    }
};

export default tenantBookingsSection;
