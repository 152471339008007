import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from 'styles/util';
import { formatClassNames } from 'helpers/ComponentHelper';

import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';

const FeatureHighlightIcon = ({
    icon,
    heading,
    text,
    className,
    headingVariant = 'h2',
    iconHeight = '150',
    iconWidth = '150',
    ...rest
}) => {
    const useStyles = makeStyles(({ theme }) => ({
        iconContainer: {
            maxWidth: '300px',
            margin: '0 auto',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            textAlign: 'center',
            gap: theme.spacing(3.125),
        },
        content: {
            '& > *:first-child': {
                marginBottom: theme.spacing(1.25)
            }
        }
    }));

    const classes = useStyles();
    return (
        <Box className={formatClassNames([classes.iconContainer, className])} {...rest}>
            <img src={icon} alt={heading} width={iconWidth} height={iconHeight} />
            <Box className={classes.content}>
                <Typography variant={headingVariant}>{heading}</Typography>
                <Typography variant="body1">{text}</Typography>
            </Box>
        </Box>
    );
};

FeatureHighlightIcon.propTypes = {
    icon: PropTypes.string,
    heading: PropTypes.string,
    text: PropTypes.string,
    className: PropTypes.string,
    headingVariant: PropTypes.string,
    iconHeight: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number
    ]),
    iconWidth: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number
    ])
};

export default FeatureHighlightIcon;
