const documentTypes = {
    document: {},
    agreement: {}
};

Object.keys(documentTypes).forEach(key => {
    documentTypes[key].key = key;
});

export default documentTypes;
