import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from 'styles/util';
import icRentOut1 from 'assets/images/ic_rent_out_1.svg';
import icRentOut2 from 'assets/images/ic_rent_out_2.svg';
import icRentOut3 from 'assets/images/ic_rent_out_3.svg';
import strings from 'localization/strings';
import pdfIcon from 'assets/icons/pdf.svg';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import VisibleFor from 'common/VisibleFor';

const useStyles = makeStyles(({ theme, colors, fonts }) => ({
    earnMoneyContainer: {
        display: 'flex',
        flexDirection: 'column',
        padding: theme.spacing(8, 20),
        [theme.breakpoints.down('sm')]: {
            padding: theme.spacing(8, 2),
        }
    },
    howItWorksTitle: {
        color: colors.white,
        textAlign: 'center'
    },
    iconsContainer: {
        display: 'flex',
        gap: theme.spacing(5),
        padding: theme.spacing(5, 0, 7.5),
        flexWrap: 'wrap',
        justifyContent: 'center'
    },
    iconItem: {
        position: 'relative',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        color: colors.white,
        maxWidth: '242px',
        textAlign: 'center'
    },
    number: {
        fontFamily: fonts.black,
        fontSize: '135px',
        lineHeight: 1,
        position: 'absolute',
        left: 0,
        top: 0,
        color: 'rgba(255, 255, 255, 0.5)'
    },
    buttonsContainer: {
        display: 'flex',
        justifyContent: 'center',
        gap: theme.spacing(2),
        flexWrap: 'wrap'
    },
    icon: {
        height: '32px'
    }
}));

const EarnMoneyWidget = ({ showDocuments = false }) => {
    const classes = useStyles();

    const documents = [
        { url: 'https://storage365.blob.core.windows.net/website-content/storage/se/plattform-tjansteutbud-och-priser.pdf', description: strings.serviceOfferingDocument },
        { url: 'https://storage365.blob.core.windows.net/website-content/storage/se/lathund-for-hyresvardar.pdf', description: strings.ownerInstructionDocument }
    ];

    return (
        <Box className={classes.earnMoneyContainer}>
            <Typography variant="h1" className={classes.howItWorksTitle}>{strings.howItWorksTitle1}</Typography>
            <Box className={classes.iconsContainer}>
                <Box className={classes.iconItem}>
                    <Box className={classes.number}>1</Box>
                    <img src={icRentOut1} />
                    <strong>{strings.earnMoneyText1}</strong>
                </Box>
                <Box className={classes.iconItem}>
                    <Box className={classes.number}>2</Box>
                    <img src={icRentOut2} />
                    <strong>{strings.earnMoneyText2}</strong>
                </Box>
                <Box className={classes.iconItem}>
                    <Box className={classes.number}>3</Box>
                    <img src={icRentOut3} />
                    <strong>{strings.earnMoneyText3}</strong>
                </Box>
            </Box>
            <VisibleFor branch="storage">
                {
                    showDocuments && (
                        <Box className={classes.buttonsContainer}>
                            {
                                documents.map((document, i) => (
                                    <Button
                                        key={i}
                                        variant="contained"
                                        color="primary"
                                        startIcon={<img className={classes.icon} src={pdfIcon} />}
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        href={document.url}
                                    >
                                        {document.description}
                                    </Button>
                                ))
                            }
                        </Box>
                    )
                }
            </VisibleFor>
        </Box>
    );
};

EarnMoneyWidget.propTypes = {
    showDocuments: PropTypes.bool
};

export default EarnMoneyWidget;
