import React, { useState } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import strings from 'localization/strings';
import { useAppContext } from 'context/AppContext';
import { createPaymentFormInitialValues, sanitizePaymentFormValues, stripeFormIsIncomplete, getAvailableAndCurrentPaymentProviders, getSelectedPaymentProvider } from 'logic/paymentLogic';
import { getContact } from 'helpers/ActorHelper';
import purchaseTypes from 'enums/purchaseTypes';
import contactTypes from 'enums/contactTypes';
import paymentProviders from 'enums/paymentProviders';
import { makeStyles } from 'styles/util';
import { formatShortDate } from 'helpers/DateHelper';

import StripeElementsWrapper from 'components/stripe/StripeElementsWrapper';
import FullScreenLoader from 'common/FullScreenLoader';
import Form from 'form/Form';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import PaymentElement from 'components/payment/PaymentElement';
import InitialPaymentPriceSpecification from './InitialPaymentPriceSpecification';
import Amount from 'common/Amount';
import { Checkboxes } from 'mui-rff';
import Text from 'common/Text';
import MarginBox from 'common/MarginBox';

const useStyles = makeStyles(({ theme, colors }) => ({
    actions: {
        flexWrap: 'wrap',
        gap: theme.spacing(2)
    },
    btnSmall: {
        height: '40px',
    },
    halfWidth: {
        flex: `0 0 calc(50% - ${theme.spacing(1)}px)`
    },
    mobileWidth: {
        [theme.breakpoints.down('sm')]: {
            width: 'calc(100% - 40px)',
            margin: '20px'
        }
    },
    startSubscriptionBookingFirstMonthlyPayment: {
        color: colors.mediumDarkGrey
    }
}));

const InitialPaymentDialog = ({ booking, open, onOk, onCancel }) => {
    const classes = useStyles();
    const { appContext } = useAppContext();
    const [isSaving, setIsSaving] = useState(false);
    const [paymentElementProvider, setPaymentElementProvider] = useState(undefined);
    const [paymentSetupComplete, setPaymentSetupComplete] = useState(false);

    const availablePaymentProviders = getAvailableAndCurrentPaymentProviders(booking);

    const selectedPaymentProvider = getSelectedPaymentProvider(booking, availablePaymentProviders);

    const createStripePaymentIntentRequest = availablePaymentProviders.includes(paymentProviders.stripe.key)
        ? {
            bookingId: booking.id === 0
                ? undefined
                : booking.id,
            immediateBookingRequestId: booking.immediateBookingRequestId,
            type: booking.subscriptionBooking
                ? purchaseTypes.subscriptionBookingInitialPurchase.key
                : purchaseTypes.periodBookingPurchase.key
        }
        : undefined;

    const useAgreements =
        appContext.useAgreements
        &&
        booking.agreementUrl; // if no agreement document is available, pretend there is no agreement to make

    const initialValues = {
        agreementAccepted: !useAgreements,
        payment: createPaymentFormInitialValues({
            paymentProvider: selectedPaymentProvider,
            billecta: {
                collectAddress: true,
                name: booking.tenantActor.name,
                email: getContact(booking.tenantActor, [contactTypes.invoiceEmail, contactTypes.mainEmail]),
                address: booking.tenantActor.invoiceAddress
            }
        }, appContext)
    };

    const title = booking.subscriptionBooking
        ? strings.startSubscriptionBookingPayment
        : strings.pay;

    const getFirstSubscriptionInvoiceDueDate = paymentProvider => {
        const invoiceInfo = booking.paymentProviderInvoiceInfos
            .find(o => o.paymentProvider === paymentProvider);
        return invoiceInfo?.firstSubscriptionInvoice.dueDate;
    };

    const getAmountToPay = paymentProvider => {
        const invoiceInfo = booking.paymentProviderInvoiceInfos
            .find(o => o.paymentProvider === paymentProvider);
        if(!invoiceInfo) {
            return undefined;
        }
        const amounts = invoiceInfo.initialInvoice.priceInfo.amounts;
        return amounts.totalAmount + amounts.totalVat + amounts.roundingCorrection;
    };

    const getPayButtonText = paymentProvider => {
        const amountToPay = getAmountToPay(paymentProvider);
        if(amountToPay === undefined) {
            return strings.pay;
        }
        return strings.formatString(paymentProviders[paymentProvider].payAmountLabel, <Amount value={amountToPay} currency={booking.currency}/>);
    };

    const paymentProviderInformations = {};

    if(availablePaymentProviders.includes(paymentProviders.billecta.key)) {
        const dueDateLaterThanStartDate = booking.paymentProviderInvoiceInfos.find(o => o.paymentProvider === paymentProviders.billecta.key)?.initialInvoice.dueDate > booking.startDate;
        if(dueDateLaterThanStartDate) {
            paymentProviderInformations[paymentProviders.billecta.key] = strings.invoiceMustBePaidToBeGivenAccess + (availablePaymentProviders.length > 1 ? ' ' + strings.selectOtherPaymentProviderForImmediateAccess : '');
        }
    }

    const handleFormSubmit = values => {
        setIsSaving(true);
        onOk({
            values,
            onCompleted: () => setIsSaving(false)
        });
    };

    const handleClose = (ev, reason) => {
        if(reason !== 'escapeKeyDown' && reason !== 'backdropClick') {
            onCancel();
            setPaymentSetupComplete(false);
            setPaymentElementProvider(undefined);
        }
    };

    const handleSelectedPaymentProvider = (provider) => {
        setPaymentElementProvider(provider);
    };

    const handlePaymentSetupComplete = (isComplete) => {
        setPaymentSetupComplete(isComplete);
    };

    if(!open) {
        return null;
    }

    return (
        <StripeElementsWrapper createStripePaymentIntentRequest={createStripePaymentIntentRequest}>
            {
                ({ stripePaymentContext }) => (
                    <Dialog fullWidth maxWidth="md" open onClose={handleClose} disableEnforceFocus /* to be able to set focus to elements outside the dialog, e.g. when authorizing credit card in an iframe provided by Stripe */>
                        <FullScreenLoader open={isSaving} />
                        <Form
                            initialValues={initialValues}
                            initialValuesEqual={() => true}
                            onSubmit={values => handleFormSubmit(sanitizePaymentFormValues(values, stripePaymentContext))}
                        >
                            {({ handleSubmit, values, invalid }) => (
                                <form onSubmit={handleSubmit}>
                                    <DialogTitle disableTypography>
                                        <Typography variant="h5">
                                            {title}
                                        </Typography>
                                    </DialogTitle>
                                    <DialogContent>
                                        {
                                            booking.subscriptionBooking && !booking.isTakeoverBooking &&
                                            (
                                                <MarginBox bottom={4}>
                                                    <Typography variant="body1">
                                                        {strings.startSubscriptionBookingPaymentDescription}
                                                    </Typography>
                                                </MarginBox>
                                            )
                                        }

                                        {
                                            useAgreements &&
                                            (
                                                <MarginBox bottom={4}>
                                                    <Checkboxes
                                                        name="agreementAccepted"
                                                        data={{
                                                            label: <Text inline html={strings.formatString(strings.iAcceptTheAgreement, booking.agreementUrl)} />,
                                                            value: true
                                                        }}
                                                    />
                                                </MarginBox>
                                            )
                                        }

                                        {
                                            values.agreementAccepted &&
                                            (
                                                <>
                                                    <PaymentElement
                                                        name="payment"
                                                        paymentProviders={availablePaymentProviders}
                                                        paymentProviderInformations={paymentProviderInformations}
                                                        stripePaymentContext={stripePaymentContext}
                                                        onSelectedPaymentProvider={handleSelectedPaymentProvider}
                                                        paymentSetupComplete={paymentSetupComplete}
                                                    >
                                                        <InitialPaymentPriceSpecification booking={booking} paymentProvider={values.payment.paymentProvider} />
                                                        {
                                                            booking.subscriptionBooking && values.payment.paymentProvider === paymentProviders.stripe.key &&
                                                            (
                                                                <MarginBox top={4}>
                                                                    <Typography variant="body1" className={classes.startSubscriptionBookingFirstMonthlyPayment}>
                                                                        {strings.formatString(strings.startSubscriptionBookingFirstMonthlyPayment, formatShortDate(getFirstSubscriptionInvoiceDueDate(values.payment.paymentProvider), appContext))}
                                                                    </Typography>
                                                                </MarginBox>
                                                            )
                                                    }
                                                    </PaymentElement>
                                                </>
                                            )
                                        }
                                    </DialogContent>
                                    <DialogActions disableSpacing className={classes.actions}>
                                        {
                                        paymentSetupComplete &&
                                            (
                                                <>
                                                    <Button
                                                        type="submit"
                                                        variant="contained"
                                                        color="primary"
                                                        fullWidth
                                                        disabled={!values.agreementAccepted || invalid || stripeFormIsIncomplete(values.payment, stripePaymentContext)}
                                                    >
                                                        {getPayButtonText(values.payment.paymentProvider)}
                                                    </Button>
                                                    <Button
                                                        className={clsx(classes.btnSmall, classes.halfWidth)}
                                                        type="button"
                                                        variant="contained"
                                                        color="secondary"
                                                        onClick={() => handlePaymentSetupComplete(false)}
                                                    >
                                                        <ArrowBackIcon />{strings.goBack}
                                                    </Button>
                                                </>
                                            )
                                        }
                                        {
                                        !paymentSetupComplete &&
                                            (
                                                <Button
                                                    type="button"
                                                    variant="contained"
                                                    color="primary"
                                                    fullWidth
                                                    onClick={() => handlePaymentSetupComplete(true)}
                                                    disabled={!paymentElementProvider}
                                                >
                                                    {strings.continue}
                                                </Button>
                                            )
                                        }
                                        <Button
                                            className={clsx(classes.btnSmall, {[classes.halfWidth]: paymentSetupComplete})}
                                            variant="contained"
                                            color="secondary"
                                            fullWidth={!paymentSetupComplete}
                                            onClick={handleClose}
                                        >
                                            {strings.cancel}
                                        </Button>
                                    </DialogActions>
                                </form>
                            )}
                        </Form>
                    </Dialog>
                )
            }
        </StripeElementsWrapper>
    );
};

InitialPaymentDialog.propTypes = {
    booking: PropTypes.object.isRequired,
    open: PropTypes.bool.isRequired,
    onOk: PropTypes.func.isRequired,
    onCancel: PropTypes.func.isRequired
};

export default InitialPaymentDialog;
