import React from 'react';
import PropTypes from 'prop-types';
import { useAppContext } from 'context/AppContext';
import storageGroupCategories from 'enums/storageGroupCategories';
import { storageGroupIsBookable, getOrderedStorageGroupCategories } from 'logic/bookingLogic';
import { convertToNumber } from 'helpers/StringHelper';
import { makeStyles } from 'styles/util';

import StorageGroupItem from './StorageGroupItem';
import { Field } from 'react-final-form';
import RadioGroup from '@material-ui/core/RadioGroup';
import { OnChange } from 'react-final-form-listeners';

const initialShowCategoryInfoText = {};
Object.keys(storageGroupCategories).forEach(o => {
    initialShowCategoryInfoText[o.key] = false;
});

const useStyles = makeStyles(({ theme, colors }) => ({
    radioContainer: {
        '& > div': {
            padding: theme.spacing(0.375, 2, 0.75, 0),
        },
        '& > div:not(:last-child)': {
            borderBottom: `1px solid ${colors.secondaryColor}`
        },
        '& > div:first-of-type': {
            padding: theme.spacing(0.75, 2, 0.375, 0)
        }
    }
}));

const StorageGroupSelector = ({ storageSite, selectedCategory, form, isPreview = false, closeModal }) => {
    const classes = useStyles();
    const formValues = form.getState().values;
    const { appContext } = useAppContext();

    const storageGroupsPerCategory = {};
    Object.keys(storageGroupCategories).forEach(key => {
        storageGroupsPerCategory[key] = [];
    });

    storageSite.storageGroups.forEach((storageGroup, i) => {
        if (formValues.storageGroupCategory === storageGroup.category) {
            storageGroupsPerCategory[storageGroup.category].push(
                <StorageGroupItem
                    key={storageGroup.id || i}
                    value={isPreview ? 0 : storageGroup.id}
                    storageGroup={storageGroup}
                    isBookable={isPreview || storageGroupIsBookable(storageGroup, formValues.requestedStartDate, formValues.requestedEndDate)}
                    disabled={isPreview}
                    onChange={form.mutators.changeValue}
                    closeModal={closeModal}
                />
            );
        }
    });

    const orderedStorageGroupCategories = getOrderedStorageGroupCategories(selectedCategory?.key, appContext);

    const items = orderedStorageGroupCategories
        .map(category => ({
            key: category.key,
            storageGroups: storageGroupsPerCategory[category.key],
            rentTitle: category.rentTitle,
            singleTitle: category.singleTitle,
            handleChange: () => handleChange(category),
            infoLinkRenderer: category.infoLinkRenderer,
            infoTextRenderer: category.infoTextRenderer
        }));

    const handleChange = category => {
        const newValue = convertToNumber(formValues.categories[category.key].storageGroupId);
        if(newValue !== -1) {
            form.mutators.changeValue({ name: `categories.${category.key}.storageGroupId`, value: newValue });
            orderedStorageGroupCategories.forEach(c => {
                if(c.key !== category.key && formValues.categories[c.key].storageGroupId !== -1) {
                    form.mutators.changeValue({ name: `categories.${c.key}.storageGroupId`, value: -1 });
                }
            });
        }
    };

    return (
        <>
            {
                items.map(item => (
                    <React.Fragment key={item.key}>
                        <Field name={`categories.${item.key}.storageGroupId`} value={formValues.categories[item.key].storageGroupId} type="radio">
                            {
                                ({ input }) => (
                                    <RadioGroup name={input.name} value={input.value} onChange={input.onChange} className={classes.radioContainer}>
                                        {item.storageGroups}
                                    </RadioGroup>
                                )
                            }
                        </Field>
                        <OnChange name={`categories.${item.key}.storageGroupId`}>
                            {item.handleChange}
                        </OnChange>
                    </React.Fragment>
                ))
            }
        </>
    );
};

StorageGroupSelector.propTypes = {
    storageSite: PropTypes.object.isRequired,
    selectedCategory: PropTypes.object,
    form: PropTypes.object.isRequired,
    isPreview: PropTypes.bool,
    closeModal: PropTypes.func
};

export default StorageGroupSelector;
