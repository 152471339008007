import React from 'react';
import InputAdornment from '@material-ui/core/InputAdornment';

export const changeValueMutator = (args, state, tools) => {
    const properties = Array.isArray(args[0])
        ? args[0]
        : [args[0]];
    properties.forEach(o => {
        tools.changeValue(state, o.name, () => o.value);
    });
};

export const createEndAdornment = label => ({ endAdornment: <InputAdornment position="end">{label}</InputAdornment> });

export const getFormValue = (form, name) => {
    const formValues = form.getState().values;
    let currentValue = formValues;
    const atoms = name.split('.');
    atoms.forEach(o => {
        if(currentValue) {
            currentValue = currentValue[o];
        }
    });
    return currentValue;
};
