import React, { useState } from 'react';
import PropTypes from 'prop-types';
import strings from 'localization/strings';
import { makeStyles } from 'styles/util';
import { useAppContext } from 'context/AppContext';
import { useHistory, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useForm } from 'react-final-form';
import userAuthenticationTypes from 'enums/userAuthenticationTypes';
import organizationTypes from 'enums/organizationTypes';
import routes from 'routes';
import { enhanceRedirectUrl, createPrivateActor, getConnectTakeoverTenantRoute } from 'logic/authenticationLogic';

import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import CreateEidUser from './CreateEidUser';
import CreateUser from './CreateUser';
import StepIndicator from 'common/StepIndicator';
import CircularProgressButton from 'common/CircularProgressButton';
import { Link } from 'react-router-dom';
import Text from 'common/Text';

const useStyles = makeStyles(({ theme }) => ({
    container: {
        display: 'flex',
        flexDirection: 'column',
        gap: theme.spacing(3.75)
    },
    eidButton: {
        [theme.breakpoints.up('md')]: {
            alignSelf: 'center',
            minWidth: '315px'
        }
    }
}));

const CreatePrivateActor = ({ fieldNamePrefix }) => {
    const classes = useStyles();
    const location = useLocation();
    const history = useHistory();
    const dispatch = useDispatch();
    const form = useForm();
    const { appContext } = useAppContext();
    const [isLoading, setIsLoading] = useState(false);
    const formState = form.getState();
    const formValues = formState.values;
    const showEidLink = appContext.authentication.allowedUserAuthenticationTypes.find(o => o === userAuthenticationTypes.eid.key);
    const redirectUrl = useSelector(state => state.authentication.redirectUrl);
    const contactSupportToGetUsernameAndPasswordLink = !!appContext.authentication.allowedUserAuthenticationTypes.find(o => o === userAuthenticationTypes.eid.key) && !formValues.allowCreateUsernameAndPasswordAccount;

    const getUserAuthenticationTypeFromLocation = () => {
        if(location.pathname.startsWith(routes.createAccountPrivateEid)) {
            return userAuthenticationTypes.eid.key;
        }
        if(location.pathname.startsWith(routes.createAccountPrivateUsernameAndPassword)) {
            return userAuthenticationTypes.usernameAndPassword.key;
        }
        return undefined;
    };

    const getStep = () => {
        if(!userAuthenticationType) {
            return 0;
        }
        return 1;
    };

    const userAuthenticationType = getUserAuthenticationTypeFromLocation();
    const step = getStep();
    const numberOfSteps = 2;

    const handleSave = () => {
        setIsLoading(true);
        createPrivateActor({
            clientState: formValues,
            dispatch,
            onSuccess: () => {
                if(formValues.takeoverTenant) {
                    history.push(getConnectTakeoverTenantRoute(formValues.takeoverTenant));
                } else {
                    history.push(enhanceRedirectUrl(redirectUrl, true));
                }
            },
            onFailure: () => setIsLoading(false)
        });
    };

    return (
        <Box className={classes.container}>
            <StepIndicator
                currentStep={step}
                numberOfSteps={numberOfSteps}
            />

            {
                // select authentication type
                step === 0 &&
                (
                    <>
                        <Box>
                            <Typography variant="body1">
                                {strings.authentication.selectUserAuthenticationType}
                            </Typography>
                        </Box>

                        <CreateEidUser
                            redirectUrl={routes.createAccount}
                            clientState={{ ...formValues, organizationType: organizationTypes.private.key }}
                        />

                        <Box>
                            <Typography variant="body1">
                                {strings.authentication.noEidAuthentication}
                                {' '}
                                {
                                    contactSupportToGetUsernameAndPasswordLink &&
                                    (
                                        <Text html={strings.formatString(strings.authentication.contactSupportToGetUsernameAndPasswordLink, routes.about)} inline/>
                                    )
                                }
                                {
                                    !contactSupportToGetUsernameAndPasswordLink &&
                                    (
                                        <Link to={routes.createAccountPrivateUsernameAndPassword}>
                                            {strings.authentication.createUsernamePasswordUser}
                                        </Link>
                                    )
                                }
                            </Typography>
                        </Box>
                    </>
                )
            }

            {
                // eID: fill in email and optionally phone
                userAuthenticationType === userAuthenticationTypes.eid.key && step === 1 &&
                (
                    <>
                        <CreateUser
                            fieldNamePrefix={fieldNamePrefix}
                            disableNameFields
                            showEmailField
                            showMobileTelephoneField={!formValues.userInvitation}
                            showAcceptTermsOfServiceCheckbox
                        />

                        <CircularProgressButton
                            disabled={formState.invalid}
                            isLoading={isLoading}
                            onClick={handleSave}
                        >
                            {strings.save}
                        </CircularProgressButton>
                    </>
                )
            }

            {
                // username and password: fill in user details
                userAuthenticationType === userAuthenticationTypes.usernameAndPassword.key && step === 1 && !contactSupportToGetUsernameAndPasswordLink &&
                (
                    <>
                        <CreateUser
                            fieldNamePrefix={fieldNamePrefix}
                            showEmailField
                            showMobileTelephoneField={!formValues.userInvitation}
                            showPasswordFields
                            showAcceptTermsOfServiceCheckbox
                        />

                        <CircularProgressButton
                            disabled={formState.invalid}
                            isLoading={isLoading}
                            onClick={handleSave}
                        >
                            {strings.save}
                        </CircularProgressButton>

                        {
                            showEidLink && !isLoading &&
                            (
                                <Box>
                                    <Typography component="span" variant="body1">
                                        {strings.authentication.useEidAuthentication}
                                    </Typography>
                                    {' '}
                                    <Link to={routes.createAccountPrivateEid}>
                                        {strings.clickHere}
                                    </Link>.
                                </Box>
                            )
                        }
                    </>
                )
            }

            {
                // username and password: need to contact support to get a link
                userAuthenticationType === userAuthenticationTypes.usernameAndPassword.key && step === 1 && contactSupportToGetUsernameAndPasswordLink &&
                (
                    <Text html={strings.formatString(strings.authentication.contactSupportToGetUsernameAndPasswordLink, routes.about)}/>
                )
            }
        </Box>
    );
};

CreatePrivateActor.propTypes = {
    fieldNamePrefix: PropTypes.string
};

export default CreatePrivateActor;
