import { RSAA } from 'redux-api-middleware';
import { createRsaaTypes, createHeaders } from './actionHelpers';

export const ACTORS_UPDATE = 'ACTORS_UPDATE';
export const ACTORS_UPDATE_OWNER = 'ACTORS_UPDATE_OWNER';
export const ACTORS_FETCH_ACTOR_USER_CONNECTIONS = 'ACTORS_FETCH_ACTOR_USER_CONNECTIONS';
export const ACTORS_FETCH_ACTOR_HAS_CREDIT_REPORTS = 'ACTORS_FETCH_ACTOR_HAS_CREDIT_REPORTS';
export const ACTORS_FETCH_ACTOR_INFORMATION = 'ACTORS_FETCH_ACTOR_INFORMATION';

export const updateActor = (actorId, updateActorRequest) => ({
    [RSAA]: {
        types: createRsaaTypes(ACTORS_UPDATE),
        endpoint: `/api/actors/${actorId}`,
        headers: createHeaders(true),
        body: JSON.stringify(updateActorRequest),
        method: 'PUT'
    }
});

export const updateOwner = (actorId, updateOwnerRequest) => ({
    [RSAA]: {
        types: createRsaaTypes(ACTORS_UPDATE_OWNER),
        endpoint: `/api/actors/${actorId}/owner`,
        headers: createHeaders(true),
        body: JSON.stringify(updateOwnerRequest),
        method: 'PUT'
    }
});

export const fetchActorUserConnections = actorId => ({
    [RSAA]: {
        types: createRsaaTypes(ACTORS_FETCH_ACTOR_USER_CONNECTIONS),
        endpoint: `/api/actors/${actorId}/userConnections`,
        headers: createHeaders(false),
        method: 'GET'
    }
});

export const fetchActorHasCreditReports = actorId => ({
    [RSAA]: {
        types: createRsaaTypes(ACTORS_FETCH_ACTOR_HAS_CREDIT_REPORTS),
        endpoint: `/api/actors/${actorId}/hasCreditReports`,
        headers: createHeaders(false),
        method: 'GET'
    }
});

export const fetchActorInformation = organizationNumber => ({
    [RSAA]: {
        types: createRsaaTypes(ACTORS_FETCH_ACTOR_INFORMATION),
        endpoint: `/api/actors/information/${encodeURI(organizationNumber)}`,
        headers: createHeaders(false),
        method: 'GET'
    }
});
