import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useAppContext } from 'context/AppContext';
import { useParams } from 'react-router';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { makeStyles } from 'styles/util';
import { clearStorageSites } from 'actions/account/storageSitesSection';
import { fetchStorageSite, updateStorageSite } from 'actions/storageSites';
import strings from 'localization/strings';
import { createFormDataForStorageSite, createStorageSiteRequestFromFormData } from 'logic/editStorageSiteLogic';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { handleResponse } from 'actions/actionHelpers';
import multiplicities from 'enums/multiplicities';
import { getResizedImageUrl } from 'helpers/ImageHelper';
import storageSiteStatuses from 'enums/storageSiteStatuses';
import { googleTagManagerConstants, createGoogleTagManagerUserAction } from 'integration/google-tag-manager/googleTagManagerHelper';
import { getStorageSiteDetailsUrl } from 'helpers/StorageSiteHelper';
import queryString from 'query-string';
import { startOfYear, endOfYear } from 'date-fns';
import { formatIsoDate } from 'helpers/DateHelper';
import routes from 'routes';

import StorageSiteForm from './StorageSiteForm';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import Loader from 'common/Loader';
import AccountLink from 'common/AccountLink';
import ContentBox from 'common/ContentBox';
import EditStep1 from './EditStep1';
import EditStep2 from './EditStep2';
import EditStep3 from './EditStep3';
import EditStep4 from './EditStep4';
import EditStep4b from './EditStep4b';
import EditStep5 from './EditStep5';
import EditStep6 from './EditStep6';
import DisplayStep1 from './DisplayStep1';
import DisplayStep2 from './DisplayStep2';
import DisplayStep3 from './DisplayStep3';
import DisplayStep4 from './DisplayStep4';
import DisplayStep4b from './DisplayStep4b';
import DisplayStep5 from './DisplayStep5';
import DisplayStep6 from './DisplayStep6';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import DraftSubmittedDialog from './DraftSubmittedDialog';
import Alert from '@material-ui/lab/Alert';

const EditStorageSite = () => {
    const useStyles = makeStyles(({ theme }) => ({
        editContainer: {
            position: 'relative'
        },
        show: {
            display: 'block'
        },
        hide: {
            display: 'none'
        },
        displayHeader: {
            display: 'flex',
            marginBottom: theme.spacing(2)
        },
        displayTitle: {
            flex: '1 1 auto'
        },
        editButton: {
            flex: '0 0 auto'
        },
        editStepButtonContainer: {
            marginTop: theme.spacing(2),
            textAlign: 'right',
            '& > *': {
                marginLeft: theme.spacing(2)
            }
        },
        editStepFullWidthButtonContainer: {
            marginTop: theme.spacing(2),
            '& > *': {
                marginBottom: theme.spacing(1)
            },
            '& > *:last-child': {
                marginBottom: 0
            }
        },
        disabledContainer: {
            border: 'none',
            margin: 0,
            padding: 0,
            '&:disabled .editContainer': {
                opacity: 0.333
            }
        },
        saveIndicator: {
            position: 'absolute',
            left: '50%',
            top: '50%',
            margin: '-50px'
        },
        mainImage: {
            width: '100%'
        },
        accountActions: {
            display: 'flex',
            flexWrap: 'wrap',
            gap: theme.spacing(1.875),
            '& > *': {
                flex: `1 1 calc(50% - ${theme.spacing(1.875)}px)`,
                [theme.breakpoints.down('xs')]: {
                    flex: '1 1 100%'
                }
            }
        },
        titleBoxButtons: {
            display: 'flex',
            gap: theme.spacing(1.875),
            flexWrap: 'wrap'
        }
    }));
    const classes = useStyles();

    const { appContext } = useAppContext();
    const params = useParams();
    const dispatch = useDispatch();
    const history = useHistory();

    const [isSaving, setIsSaving] = useState(false);
    const [storageSite, setStorageSite] = useState(undefined);
    const [storageSiteFormData, setStorageSiteFormData] = useState(undefined);
    const [storageSiteFormDataBeforeEdit, setStorageSiteFormDataBeforeEdit] = useState(undefined);
    const [stepBeingEdited, setStepBeingEdited] = useState(undefined); // zero-based
    const [confirmDeleteDialogOpen, setConfirmDeleteDialogOpen] = useState(false);
    const [draftSubmittedDialogOpen, setDraftSubmittedDialogOpen] = useState(false);
    const authenticationContext = useSelector(state => state.authentication.context);
    const selectedActor = authenticationContext.selectedActor;

    const buttonFullWidth = !useMediaQuery(theme => theme.breakpoints.up('md'));

    const storageSiteId = parseInt(params.storageSiteId, 10);

    useEffect(() => {
        const startDate = authenticationContext.isSuperAdminOrImpersonated
            ? formatIsoDate(startOfYear(new Date()))
            : undefined;
        const endDate = authenticationContext.isSuperAdminOrImpersonated
            ? formatIsoDate(endOfYear(new Date()))
            : undefined;
        dispatch(fetchStorageSite(storageSiteId, startDate, endDate, false /* includeStorageGroupBookingCalendars */, true /* includeOpeningTimeSpansConfiguration */))
            .then(handleResponse(
                response => {
                    const formData = createFormDataForStorageSite(response.payload, appContext);
                    setStorageSite(response.payload);
                    setStorageSiteFormData(formData);
                    setStorageSiteFormDataBeforeEdit(formData);
                }
            ));
    }, []);

    const storageSiteBookingOverviewUrl = routes.account.storageSiteBookingOverview.replace(':storageSiteId', params.storageSiteId);

    const showLockCodeRequestTableUrl = storageSite?.storageGroups.find(storageGroup => storageGroup.lockConfigurations.length > 0)
        ? routes.account.storageSiteLockCodeRequests.replace(':storageSiteId', params.storageSiteId)
        : undefined;

    const handleFormSubmit = () => {
        // we are saving at each step editing, so nothing to do here
    };

    const validate = values => {
        if (values.steps[5].images.length === 0) {
            return { '': strings.atLeastOneImageIsRequired };
        }
        return undefined;
    };

    const handleEditClick = (form, step) => {
        if(stepBeingEdited) {
            form.mutators.changeValue({ name: '', value: {...storageSiteFormDataBeforeEdit} });
        }
        setStepBeingEdited(step);
    };

    const handleSaveClick = (form, callback) => {
        setIsSaving(true);
        const formValues = form.getState().values;
        setStorageSiteFormDataBeforeEdit({...formValues});

        const baseValues = {
            ownerType: selectedActor.organizationType
        };
        const updateStorageSiteRequest = createStorageSiteRequestFromFormData(formValues, baseValues, selectedActor, appContext);
        dispatch(updateStorageSite(storageSiteId, updateStorageSiteRequest))
            .then(handleResponse(
                response => {
                    dispatch(createGoogleTagManagerUserAction(googleTagManagerConstants.userActions.storageSiteUpdated));
                    setIsSaving(false);
                    dispatch(clearStorageSites());
                    setStepBeingEdited(undefined);
                    const formData = createFormDataForStorageSite(response.payload, appContext);
                    setStorageSite(response.payload);
                    setStorageSiteFormData(formData);
                    setStorageSiteFormDataBeforeEdit(formData);
                    if(callback) {
                        callback();
                    }
                    return { snackbar: { message: strings.storageSiteUpdated } };
                },
                () => setIsSaving(false)
            ));
    };

    const handleSaveStorageGroup = form => {
        handleSaveClick(form);
    };

    const handleCancelEditClick = form => {
        form.mutators.changeValue({ name: '', value: {...storageSiteFormDataBeforeEdit} });
        setStepBeingEdited(undefined);
    };

    const handleConfirmDeleteDialogOkClick = form => {
        setConfirmDeleteDialogOpen(false);
        form.mutators.changeValue({ name: 'steps[0].status', value: storageSiteStatuses.deleted.key });
        handleSaveClick(form, () => history.push(routes.account.storageSites));
    };

    const handleConfirmDeleteDialogCancelClick = () => {
        setConfirmDeleteDialogOpen(false);
    };

    const handleDraftSubmittedDialogCloseClick = () => {
        setDraftSubmittedDialogOpen(false);
    };

    const handleDeleteClick = () => {
        setConfirmDeleteDialogOpen(true);
    };

    const handleCancelClick = () => {
        history.push(routes.account.storageSites);
    };

    const handleApproveClick = form => {
        form.mutators.changeValue({ name: 'steps[0].status', value: storageSiteStatuses.approved.key });
        handleSaveClick(form);
    };

    const handleSubmitForApprovalClick = form => {
        form.mutators.changeValue({ name: 'steps[0].status', value: storageSiteStatuses.submittedDraft.key });
        handleSaveClick(form, () => {
            setDraftSubmittedDialogOpen(true);
        });
    };

    const handleToggleActivateClick = form => {
        const formValues = form.getState().values;
        const newStatus = formValues.steps[0].status === storageSiteStatuses.approved.key
            ? storageSiteStatuses.hidden.key
            : storageSiteStatuses.approved.key;
        form.mutators.changeValue({ name: 'steps[0].status', value: newStatus });
        handleSaveClick(form);
    };

    const SavingOverlay = () => <Box className={classes.saveIndicator}><Loader left={0} top={0}/></Box>;

    const TitleBoxContainer = ({ form }) => {
        const formValues = form.getState().values;
        const mainImage = formValues.steps[5].images[0];
        const status = formValues.steps[0].status;

        return (
            <Box>
                <Box className={`editContainer ${classes.editContainer}`}>
                    { isSaving && <SavingOverlay/> }
                    <ContentBox widgetSpacing="30px" className={isSaving ? classes.isSaving : ''}>
                        {
                            mainImage &&
                            (
                                <img className={classes.mainImage} src={getResizedImageUrl(mainImage.mediaUrl, { width: 650, height: 407, bgcolor: 'ffffff' })} />
                            )
                        }

                        <Typography variant="h4" display="block">
                            {formValues.steps[5].title}
                        </Typography>

                        <Box className={classes.accountActions}>
                            {
                                storageSite &&
                                (
                                    <>
                                        <AccountLink to={getStorageSiteDetailsUrl(storageSite)}>
                                            {strings.showStorageSite}
                                        </AccountLink>
                                        <AccountLink to={storageSiteBookingOverviewUrl}>
                                            {strings.showBookingOverview}
                                        </AccountLink>
                                    </>
                                )
                            }
                            <AccountLink to={routes.account.ownerBookings + '?' + queryString.stringify({ storageSiteId: storageSiteId })}>
                                {strings.showBookings}
                            </AccountLink>
                            {
                                showLockCodeRequestTableUrl && <AccountLink to={showLockCodeRequestTableUrl}>{strings.showStorageSiteLockCodeRequests}</AccountLink>
                            }
                        </Box>

                        <Box className={classes.titleBoxButtons}>
                            <Button
                                variant="contained"
                                color="primary"
                                disabled={isSaving}
                                fullWidth={buttonFullWidth}
                                onClick={handleDeleteClick}
                            >
                                {strings.remove}
                            </Button>
                            {
                                status === storageSiteStatuses.submittedDraft.key && authenticationContext.isSuperAdminOrImpersonated &&
                                (
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        disabled={isSaving}
                                        fullWidth={buttonFullWidth}
                                        onClick={() => handleApproveClick(form)}
                                    >
                                        {strings.approve}
                                    </Button>
                                )
                            }
                            {
                                status === storageSiteStatuses.unsubmittedDraft.key &&
                                (
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        disabled={isSaving}
                                        fullWidth={buttonFullWidth}
                                        onClick={() => handleSubmitForApprovalClick(form)}
                                    >
                                        {strings.publishStorageSite}
                                    </Button>
                                )
                            }
                            {
                                (status === storageSiteStatuses.approved.key || status === storageSiteStatuses.hidden.key) &&
                                (
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        disabled={isSaving}
                                        fullWidth={buttonFullWidth}
                                        onClick={() => handleToggleActivateClick(form)}
                                    >
                                        {status === storageSiteStatuses.approved.key ? strings.inactivate : strings.activate}
                                    </Button>
                                )
                            }
                            <Button
                                variant="contained"
                                color="secondary"
                                fullWidth={buttonFullWidth}
                                onClick={handleCancelClick}
                            >
                                {strings.goBack}
                            </Button>

                            <Dialog open={confirmDeleteDialogOpen} onClose={handleConfirmDeleteDialogCancelClick} aria-labelledby="form-dialog-title">
                                <DialogTitle id="form-dialog-title" disableTypography>
                                    <Typography variant="h5">
                                        {strings.confirmDeleteStorageSiteTitle}
                                    </Typography>
                                </DialogTitle>
                                <DialogContent>
                                    {strings.confirmDeleteStorageSiteDescription}
                                </DialogContent>
                                <DialogActions>
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        onClick={() => handleConfirmDeleteDialogOkClick(form)}
                                    >
                                        {strings.remove}
                                    </Button>
                                    <Button
                                        variant="outlined"
                                        color="primary"
                                        onClick={handleConfirmDeleteDialogCancelClick}
                                    >
                                        {strings.cancel}
                                    </Button>
                                </DialogActions>
                            </Dialog>
                            <DraftSubmittedDialog open={draftSubmittedDialogOpen} onClose={handleDraftSubmittedDialogCloseClick} />
                        </Box>
                    </ContentBox>
                </Box>
            </Box>
        );
    };

    TitleBoxContainer.propTypes = {
        form: PropTypes.object.isRequired
    };

    const StepContainer = ({ displayTitle, displayComponent, editComponent, edit, form, valid, onEditClick }) => {
        return (
            <ContentBox widgetSpacing="30px">
                <Box className={'editContainer ' + classes.editContainer + ' ' + (edit ? classes.show : classes.hide)}>
                    <Box className={isSaving ? classes.isSaving : ''}>
                        {editComponent}
                    </Box>
                    { isSaving && <SavingOverlay/> }
                    <Box className={buttonFullWidth ? classes.editStepFullWidthButtonContainer : classes.editStepButtonContainer}>
                        <Button
                            variant="contained"
                            color="primary"
                            fullWidth={buttonFullWidth}
                            disabled={!valid || isSaving}
                            onClick={() => handleSaveClick(form)}
                        >
                            {strings.save}
                        </Button>
                        <Button
                            variant="contained"
                            color="secondary"
                            fullWidth={buttonFullWidth}
                            type="button"
                            disabled={isSaving}
                            onClick={() => handleCancelEditClick(form)}
                        >
                            {strings.cancel}
                        </Button>
                    </Box>
                </Box>
                <Box className={edit ? classes.hide : classes.show}>
                    <Box className={classes.displayHeader}>
                        <Typography className={classes.displayTitle} variant="h5">{displayTitle}</Typography>
                        <Button
                            className={classes.editButton}
                            variant="contained"
                            color="primary"
                            onClick={onEditClick}
                        >
                            {strings.change}
                        </Button>
                    </Box>
                    {displayComponent}
                </Box>
            </ContentBox>
        );
    };

    StepContainer.propTypes = {
        displayTitle: PropTypes.string.isRequired,
        displayComponent: PropTypes.object.isRequired,
        editComponent: PropTypes.object.isRequired,
        edit: PropTypes.bool.isRequired,
        form: PropTypes.object.isRequired,
        valid: PropTypes.bool.isRequired,
        onEditClick: PropTypes.func.isRequired,
    };

    return (
        <Box className={classes.container}>
            {
                !storageSiteFormData && <Loader />
            }
            {
                storageSiteFormData && (
                    <StorageSiteForm
                        initialValues={storageSiteFormData}
                        onSubmit={handleFormSubmit}
                        validate={validate}
                    >
                        {
                            ({ form, valid, values, invalid, errors, handleSubmit }) => {
                                const step1Title = values.steps[0].multiplicity === multiplicities.single
                                    ? strings.categoryAndSizeTitle
                                    : strings.categoryTitle;
                                return (
                                    <form onSubmit={handleSubmit}>
                                        <fieldset className={classes.disabledContainer} disabled={isSaving}>
                                            <ContentBox widgetSpacing="30px">
                                                <TitleBoxContainer form={form} />

                                                <StepContainer
                                                    displayTitle={step1Title}
                                                    displayComponent={<DisplayStep1 storageSiteId={storageSiteId} />}
                                                    editComponent={<EditStep1 showStepNumber={false} storageSiteExists onSaveStorageGroup={() => handleSaveStorageGroup(form)} />}
                                                    edit={stepBeingEdited === 1}
                                                    form={form}
                                                    valid={valid}
                                                    onEditClick={() => handleEditClick(form, 1)}
                                                />

                                                <StepContainer
                                                    displayTitle={strings.address}
                                                    displayComponent={<DisplayStep2/>}
                                                    editComponent={<EditStep2 showStepNumber={false} />}
                                                    edit={stepBeingEdited === 2}
                                                    form={form}
                                                    valid={valid}
                                                    onEditClick={() => handleEditClick(form, 2)}
                                                />

                                                <StepContainer
                                                    displayTitle={strings.propertiesAndFacilities}
                                                    displayComponent={<DisplayStep3/>}
                                                    editComponent={<EditStep3 showStepNumber={false} />}
                                                    edit={stepBeingEdited === 3}
                                                    form={form}
                                                    valid={valid}
                                                    onEditClick={() => handleEditClick(form, 3)}
                                                />

                                                <StepContainer
                                                    displayTitle={strings.openingTimeSpanRules}
                                                    displayComponent={<DisplayStep4/>}
                                                    editComponent={<EditStep4 showStepNumber={false} />}
                                                    edit={stepBeingEdited === 4}
                                                    form={form}
                                                    valid={valid}
                                                    onEditClick={() => handleEditClick(form, 4)}
                                                />

                                                <StepContainer
                                                    displayTitle={strings.discounts}
                                                    displayComponent={<DisplayStep4b/>}
                                                    editComponent={<EditStep4b showStepNumber={false} />}
                                                    edit={stepBeingEdited === '4b'}
                                                    form={form}
                                                    valid={valid}
                                                    onEditClick={() => handleEditClick(form, '4b')}
                                                />

                                                <StepContainer
                                                    displayTitle={strings.storageSiteTextAndImagesTitle}
                                                    displayComponent={<DisplayStep5/>}
                                                    editComponent={<EditStep5 showStepNumber={false} />}
                                                    edit={stepBeingEdited === 5}
                                                    form={form}
                                                    valid={valid}
                                                    onEditClick={() => handleEditClick(form, 5)}
                                                />

                                                {
                                                    authenticationContext.isSuperAdminOrImpersonated &&
                                                    (
                                                        <StepContainer
                                                            displayTitle={strings.storageSiteSettingsForSuperAdmin}
                                                            displayComponent={<DisplayStep6/>}
                                                            editComponent={<EditStep6 showStepNumber={false} actor={selectedActor} />}
                                                            edit={stepBeingEdited === 6}
                                                            form={form}
                                                            valid={valid}
                                                            onEditClick={() => handleEditClick(form, 6)}
                                                        />
                                                    )
                                                }
                                            </ContentBox>
                                        </fieldset>
                                        {
                                            invalid && authenticationContext.isSuperAdminOrImpersonated &&
                                            (
                                                <>
                                                    <Alert severity="error">
                                                        <Typography variant="body1" gutterBottom>
                                                            {JSON.stringify(errors)}
                                                        </Typography>
                                                        <Typography variant="body1">
                                                            {JSON.stringify(values)}
                                                        </Typography>
                                                    </Alert>
                                                </>
                                            )
                                        }
                                    </form>
                                );
                            }
                        }
                    </StorageSiteForm>
                )
            }
        </Box>
    );
};

export default EditStorageSite;
