import React from 'react';
import { useAppContext } from 'context/AppContext';
import { useForm } from 'react-final-form';
import strings from 'localization/strings';
import dateRuleTypes from 'enums/dateRuleTypes';

import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';

const DisplayStep4 = () => {
    const formValues = useForm().getState().values;
    const values = formValues.steps[4];
    const useOpeningTimeSpanRules = values.useOpeningTimeSpanRules === 'true';
    const { appContext } = useAppContext();

    return (
        <Grid container spacing={2}>
            <Grid item xs={12}>
                {
                    !useOpeningTimeSpanRules &&
                    (
                        <Typography variant="body1">{strings.doNotUseOpeningTimeSpanRules}</Typography>
                    )
                }
                {
                    useOpeningTimeSpanRules && values.openingTimeSpanRules.length === 0 &&
                    (
                        <Typography variant="body1">{strings.openAllDays}</Typography>
                    )
                }
                {
                    useOpeningTimeSpanRules && values.openingTimeSpanRules.length > 0 &&
                    (
                        <List>
                        {
                            values.openingTimeSpanRules.map((openingTimeSpanRule, i) => (
                                <ListItem key={i} disableGutters>
                                    <ListItemText>
                                        {dateRuleTypes[openingTimeSpanRule.type].getDescription(openingTimeSpanRule, { appContext })}
                                    </ListItemText>
                                </ListItem>
                            ))
                        }
                    </List>
                    )
                }
            </Grid>
        </Grid>
    );
};

export default DisplayStep4;
