import { parseIsoDate, formatIsoDate } from 'helpers/DateHelper';
import { add } from 'date-fns';
import capacityUtilizationProperties from 'enums/capacityUtilizationProperties';

export const calculateChartData = (storageSites, fromDate, toDate) => {
    const dailyDataDictionary = {}; // dateString -> { date, bookedArea, bookedStorageCount, totalArea, totalStorageCount, amount, totalValue, currency }
    let date;
    let currency = undefined;
    storageSites.forEach(storageSite => {
        storageSite.bookingCalendar.capacityUtilizations.forEach(capacityUtilization => {
            currency = getCurrency(capacityUtilization.amount, currency)
                       ??
                       getCurrency(capacityUtilization.totalValue, currency);
            const intervalStartDate = parseIsoDate(capacityUtilization.interval.startDate);
            const intervalEndDate = parseIsoDate(capacityUtilization.interval.endDate);
            date = intervalStartDate;
            while(date <= intervalEndDate) {
                const dateKey = formatIsoDate(date);
                if(!dailyDataDictionary[dateKey]) {
                    dailyDataDictionary[dateKey] = createDailyData(date);
                }
                const item = dailyDataDictionary[dateKey];
                item.bookedArea += (capacityUtilization.bookedArea ?? 0);
                item.bookedStorageCount += (capacityUtilization.bookedStorageCount ?? 0);
                item.totalArea += (capacityUtilization.totalArea ?? 0);
                item.totalStorageCount += (capacityUtilization.totalStorageCount ?? 0);
                addToAmountSum(item.amount, capacityUtilization.amount);
                addToAmountSum(item.totalValue, capacityUtilization.totalValue);
                item.currency = currency;
                date = add(date, { days: 1 });
            }
        });
    });

    const itemsToReturn = [];
    date = fromDate;
    while(date <= toDate) {
        const dateKey = formatIsoDate(date);
        itemsToReturn.push(dailyDataDictionary[dateKey] ?? createDailyData(date));
        date = add(date, { days: 1 });
    }
    return itemsToReturn;
};

export const getValueForDate = (storageSite, date) =>
    storageSite.bookingCalendar.capacityUtilizations.find(capacityUtilization => {
        const intervalStartDate = parseIsoDate(capacityUtilization.interval.startDate);
        const intervalEndDate = parseIsoDate(capacityUtilization.interval.endDate);
        return date >= intervalStartDate && date <= intervalEndDate;
    });

export const getAllColumns = property => [property].concat((property.additionalColumns ?? []).map(key => capacityUtilizationProperties[key]));

const createDailyData = date => ({
    date,
    bookedArea: 0,
    bookedStorageCount: 0,
    totalArea: 0,
    totalStorageCount: 0,
    amount: { currencySums: [] },
    totalValue: { currencySums: [] },
    currency: ''
});

export const getCurrency = (amount, defaultValue) => amount.currencySums.length === 0 || defaultValue !== undefined
    ? defaultValue
    : amount.currencySums[0].currency;

export const getValueForCurrency = (amount, currency) => {
    if (amount.currencySums.length === 0) {
        return 0;
    }
    const amountSum = amount.currencySums.find(x => x.currency === currency);
    return amountSum?.value ?? 0;
};

const addToAmountSum = (existingAmountSum, amountSumToAdd) => {
    amountSumToAdd.currencySums.forEach(cs => {
        let existingCurrencySum = existingAmountSum.currencySums.find(ecs => ecs.currency === cs.currency);
        if(!existingCurrencySum) {
            existingCurrencySum = {
                value: 0,
                currency: cs.currency
            };
            existingAmountSum.currencySums.push(existingCurrencySum);
        }
        existingCurrencySum.value += cs.value;
    });
};
