import { RSAA } from 'redux-api-middleware';
import { createRsaaTypes, createHeaders } from '../actionHelpers';
import queryString from 'query-string';

export const ADMIN_PURCHASES_GET = 'ADMIN_PURCHASES_GET';

export const ADMIN_PURCHASES_REGENERATE_PAYMENT_DOCUMENTS = 'ADMIN_PURCHASES_REGENERATE_PAYMENT_DOCUMENTS';

export const ADMIN_PURCHASES_CREATE_NOTE = 'ADMIN_PURCHASES_CREATE_NOTE';

export const ADMIN_PURCHASES_UPDATE = 'ADMIN_PURCHASES_UPDATE';

export const ADMIN_PURCHASES_SEARCH = 'ADMIN_PURCHASES_SEARCH';
export const ADMIN_PURCHASES_SEARCH_SUCCESS = 'ADMIN_PURCHASES_SEARCH_SUCCESS';
export const ADMIN_PURCHASES_SEARCH_FAILURE = 'ADMIN_PURCHASES_SEARCH_FAILURE';
export const ADMIN_PURCHASES_SET_SEARCH_PARAMETERS = 'ADMIN_PURCHASES_SET_SEARCH_PARAMETERS';

export const getPurchase = purchaseId => ({
    [RSAA]: {
        types: createRsaaTypes(ADMIN_PURCHASES_GET),
        endpoint: `/api/admin/purchases/${purchaseId}`,
        headers: createHeaders(false),
        method: 'GET'
    }
});

export const regeneratePurchasePaymentDocuments = purchaseId => ({
    [RSAA]: {
        types: createRsaaTypes(ADMIN_PURCHASES_REGENERATE_PAYMENT_DOCUMENTS),
        endpoint: `/api/admin/purchases/${purchaseId}/regeneratePaymentDocuments`,
        headers: createHeaders(false),
        method: 'PUT'
    }
});

export const createPurchaseNote = (purchaseId, request) => ({
    [RSAA]: {
        types: createRsaaTypes(ADMIN_PURCHASES_CREATE_NOTE),
        endpoint: `/api/admin/purchases/${purchaseId}/notes`,
        headers: createHeaders(true),
        method: 'POST',
        body: JSON.stringify(request)
    }
});

export const updatePurchase = (purchaseId, request) => ({
    [RSAA]: {
        types: createRsaaTypes(ADMIN_PURCHASES_UPDATE),
        endpoint: `/api/admin/purchases/${purchaseId}`,
        headers: createHeaders(true),
        method: 'PUT',
        body: JSON.stringify(request)
    }
});

export const setSearchParameters = searchParameters => ({
    type: ADMIN_PURCHASES_SET_SEARCH_PARAMETERS,
    searchParameters
});

export const searchPurchases = searchParameters => ({
    [RSAA]: {
        types: createRsaaTypes(ADMIN_PURCHASES_SEARCH),
        endpoint: `/api/admin/purchases?${queryString.stringify(searchParameters)}`,
        headers: createHeaders(false),
        method: 'GET'
    }
});
