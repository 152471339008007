import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router';
import { handleResponse } from 'actions/actionHelpers';
import { getTakeover } from 'actions/admin/takeovers';
import routes from 'routes';
import takeoverTenantSelections from 'enums/takeoverTenantSelections';
import { defaultCompare } from 'helpers/SortHelper';

import Loader from 'common/Loader';
import AdminPage from '../../presentational/AdminPage';
import AdminContentWrapper from '../../presentational/AdminContentWrapper';
import AdminFormWrapper from '../../presentational/AdminFormWrapper';
import AdminFormFields from '../../presentational/AdminFormFields';
import AdminFormButtons from '../../presentational/AdminFormButtons';
import ReadOnlyField from '../../presentational/ReadOnlyField';
import ShortDateTime from 'common/ShortDateTime';
import Button from '@material-ui/core/Button';
import Separator from 'common/Separator';
import Typography from '@material-ui/core/Typography';
import Text from 'common/Text';
import { Link } from 'react-router-dom';

const TakeoverMessages = () => {
    const dispatch = useDispatch();
    const params = useParams();

    const [isLoading, setIsLoading] = useState(true);
    const [takeover, setTakeover] = useState(undefined);

    const backUrl = routes.admin.takeover.replace(':takeoverId', params.takeoverId);

    useEffect(() => {
        dispatch(getTakeover(params.takeoverId, true))
            .then(handleResponse(
                response => {
                    setTakeover(response.payload);
                    setIsLoading(false);
                },
                () => setIsLoading(false)
            ));
    }, []);

    const sortDescending = messages => {
        const sortedMessages = [...messages];
        sortedMessages.sort((a, b) => -defaultCompare(a.createdTime, b.createdTime));
        return sortedMessages;
    };

    const title = 'Emails sent to tenants: ' + (takeover ? takeover.storageSite.title : 'Loading...');

    return (
        <AdminPage title={title}>
            {
                isLoading && <Loader />
            }
            {
                takeover &&
                (
                    <AdminContentWrapper type="detail">
                        <AdminFormWrapper>
                            <AdminFormFields>
                                {
                                    takeover.messages.length === 0 &&
                                    (
                                        <Typography variant="body1">
                                            No emails sent.
                                        </Typography>
                                    )
                                }
                                {
                                    sortDescending(takeover.messages).map((message, i) => (
                                        <React.Fragment key={i}>
                                            <ReadOnlyField label="Created time">
                                                <ShortDateTime value={message.createdTime}/>
                                            </ReadOnlyField>

                                            <ReadOnlyField label="Tenant selection">
                                                {takeoverTenantSelections[message.tenantSelection].label}
                                            </ReadOnlyField>

                                            <ReadOnlyField label="Number of recipients">
                                                {message.recipientCount}
                                            </ReadOnlyField>

                                            <ReadOnlyField label="Subject">
                                                {message.subject}
                                            </ReadOnlyField>

                                            <ReadOnlyField label="Body">
                                                <Text text={message.body}/>
                                            </ReadOnlyField>

                                            {
                                                i < takeover.messages.length - 1 && <Separator spacing={2}/>
                                            }
                                        </React.Fragment>
                                    ))
                                }
                            </AdminFormFields>
                            <AdminFormButtons>
                                <Button
                                    type="button"
                                    variant="outlined"
                                    color="primary"
                                    component={Link}
                                    to={backUrl}
                                >
                                    Back
                                </Button>
                            </AdminFormButtons>
                        </AdminFormWrapper>
                    </AdminContentWrapper>
                )
            }
        </AdminPage>
    );
};

export default TakeoverMessages;
