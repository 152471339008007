import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from 'styles/util';

import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';

const useStyles = makeStyles(({ theme, colors }) => ({
    textItemBox: {
        display: 'flex',
        flexDirection: 'column',
        gap: theme.spacing(2)
    },
    itemTitle: {
        color: colors.primaryColor
    }
}));

const TextItemBox = ({ items = [] }) => {
    const classes = useStyles();
    return (
        <Box className={classes.textItemBox}>
            {
                items.map((item, index) =>
                    (
                        <Box key={index} className={classes.item}>
                            <Typography variant="h3" className={classes.itemTitle}>{item.title}</Typography>
                            <Typography variant="body1" className={classes.itemDescription}>{item.description}</Typography>
                        </Box>
                    )
                )
            }
        </Box>
    );
};

TextItemBox.propTypes = {
    items: PropTypes.array
};

export default TextItemBox;
