import React from 'react';
import PropTypes from 'prop-types';
import paymentStatuses from 'enums/paymentStatuses';
import routes from 'routes';
import paymentProviders from 'enums/paymentProviders';
import { parseIsoDate } from 'helpers/DateHelper';

import { Link } from 'react-router-dom';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import ReadOnlyField from '../../presentational/ReadOnlyField';
import Amount from 'common/Amount';
import ShortDate from 'common/ShortDate';
import ShortDateTime from 'common/ShortDateTime';
import FlagIcon from '@material-ui/icons/Flag';

const PurchaseList = ({ purchases }) => {
    if(purchases.length === 0) {
        return null;
    }

    const sortedPurchases = [...purchases];
    sortedPurchases.sort((a, b) => -(parseIsoDate(a.createdTime).getTime() - parseIsoDate(b.createdTime).getTime()));

    const formatPurchasePeriod = purchase => purchase.periodStartDate && purchase.periodEndDate
        ? <><ShortDate value={purchase.periodStartDate} /> &ndash; <ShortDate value={purchase.periodEndDate}/></>
        : undefined;

    const hasFailures = sortedPurchases.find(purchase => purchase.payments.filter(payment => payment.status === paymentStatuses.failed.key).length > 0) !== undefined;

    const formatFailures = purchase => (
        <>
            {
                purchase.payments.filter(payment => payment.status === paymentStatuses.failed.key)
                    .map((payment, i) => <React.Fragment key={i}><ShortDateTime value={payment.time}/><br/></React.Fragment>)
            }
        </>
    );

    const showFlaggedColumn = sortedPurchases.find(purchase => purchase.flagged) !== undefined;

    return (
        <ReadOnlyField label="Purchases">
            <Table size="small">
                <TableHead>
                    <TableRow>
                        { showFlaggedColumn && <TableCell/> }
                        <TableCell>Purchase ID</TableCell>
                        <TableCell>Created date</TableCell>
                        <TableCell>Due date</TableCell>
                        <TableCell>Period</TableCell>
                        <TableCell>Payment provider</TableCell>
                        <TableCell align="right">Amount</TableCell>
                        <TableCell align="right">Status</TableCell>
                        { hasFailures && <TableCell align="right">Failures</TableCell> }
                    </TableRow>
                </TableHead>
                <TableBody>
                    {
                        sortedPurchases.map(purchase => (
                            <TableRow key={purchase.id}>
                                { showFlaggedColumn && <TableCell>{purchase.flagged ? <FlagIcon/> : undefined }</TableCell> }
                                <TableCell><Link to={routes.admin.purchase.replace(':purchaseId', purchase.id)}>{purchase.id}</Link></TableCell>
                                <TableCell><ShortDateTime value={purchase.createdTime}/></TableCell>
                                <TableCell><ShortDate value={purchase.dueTime}/></TableCell>
                                <TableCell>{formatPurchasePeriod(purchase)}</TableCell>
                                <TableCell>{paymentProviders[purchase.paymentProvider]?.providerName}</TableCell>
                                <TableCell align="right"><Amount value={purchase.amount} currency={purchase.currency}/></TableCell>
                                <TableCell align="right">{purchase.amount > 0 && purchase.status}</TableCell>
                                { hasFailures && <TableCell align="right">{formatFailures(purchase)}</TableCell> }
                            </TableRow>
                        ))
                    }
                </TableBody>
            </Table>
        </ReadOnlyField>
    );
};

PurchaseList.propTypes = {
    purchases: PropTypes.array.isRequired
};

export default PurchaseList;
