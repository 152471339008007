import React, { useEffect, useState } from 'react';
import { useAppContext } from 'context/AppContext';
import { useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import strings from 'localization/strings';
import { makeStyles } from 'styles/util';
import landingPages from 'enums/landingPages';
import routes from 'routes';

import StorageDesktopNavigation from 'components/header/StorageDesktopNavigation';
import StorageMobileNavigation from 'components/header/StorageMobileNavigation';

const useStyles = makeStyles(({ theme, colors, fonts }) => ({
    logInButtonItem: {
        fontFamily: fonts.medium,
        fontSize: '16px',
        border: 0,
        backgroundColor: colors.primaryColor,
        padding: '18px 24px',
        lineHeight: 1,
        marginLeft: '16px',
        marginRight: '32px',
        '&:hover': {
            backgroundColor: colors.primaryHoverColor,
        },
        '& span': {
            color: colors.white
        }
    },
    findStorageButtonItem: {
        fontFamily: fonts.medium,
        fontSize: '16px',
        color: colors.primaryColor,
        backgroundColor: colors.white,
        padding: '10px 26px',
        marginLeft: '8px',
        '&:hover': {
            backgroundColor: colors.whiteHover
        }
    },
    alignLeft: {
        marginRight: 'auto'
    },
    loggedInAsText: {
        maxWidth: '20rem',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis'
    },
    userImpersonated: {
        display: 'flex',
        flexDirection: 'column',
        height: '64px',
        alignItems: 'flex-start'
    },
    userImpersonatedLine1: {
        lineHeight: '36px'
    },
    userImpersonatedLine2: {
        lineHeight: '28px',
        fontSize: '75%'
    },
    notificationContainer: {
        position: 'relative'
    },
    menuText: {
        paddingBottom: theme.spacing(2),
        '&:first-child': {
            paddingTop: theme.spacing(2),
            borderTop: `1px solid ${colors.lightGreyBorder ?? colors.lightGrey}`
        },
        '&:last-child': {
            borderBottom: `1px solid ${colors.lightGreyBorder ?? colors.lightGrey}`
        }
    },
    menuHeading: {
        '& span': {
            fontSize: theme.typography.bodyMedium.fontSize,
            fontWeight: 'bold'
        }
    }
}));

const Navigation = () => {
    const classes = useStyles();
    const { appContext } = useAppContext();
    const location = useLocation();
    const { user } = useSelector(state => state.authentication.context);
    const isAuthenticated = useSelector(state => !!state.authentication.token);

    // TODO: Move to appContext?
    const itemTypes = {
        accountInfo: 'accountInfo',
        default: 'default'
    };

    useEffect(() => {
        setLinks(buildLinks());
    }, [location.pathname, isAuthenticated, user?.id]);

    const addDefaultLinkProperties = (item) => {
        if (location?.pathname?.startsWith(item.url)) {
            item.selected = true;
        }
        if (item.visible === undefined) {
            item.visible = true;
        }
        if (item.screen === undefined) {
            item.screen = 'both';
        }
        if (item.type === undefined) {
            item.type = itemTypes.default;
        }
    };

    const buildLinks = () => {
        let landingPageLinks = appContext.storageGroupCategories.map(o => landingPages[o.key]).map(landingPage => ({
            url: landingPage.url,
            text: landingPage.shortLabel
        }));
        if(landingPageLinks.length < 2) {
            landingPageLinks = [];
        } else {
            landingPageLinks[landingPageLinks.length - 1].className = classes.alignLeft;
        }

        const items = [
            ...landingPageLinks,
            {
                url: routes.howItWorks,
                text: strings.howItWorksTitle1
            },
            {
                url: routes.questionsAndAnswers,
                text: strings.questionsAndAnswersShort
            },
            {
                url: routes.about,
                text: strings.aboutUs,
                screen: 'mobile'
            },
            {
                url: routes.rentOutStorage,
                text: strings.rentOut,
            },
            {
                url: routes.logIn,
                text: strings.login,
                isButton: true,
                className: classes.logInButtonItem,
                visible: !isAuthenticated,
            }
        ];

        items.forEach(item => {
            addDefaultLinkProperties(item);
        });

        return items;
    };

    const [links, setLinks] = useState([]);

    return (
        <>
            <StorageDesktopNavigation links={links.filter(link => link.screen === 'desktop' || link.screen === 'both')} />
            <StorageMobileNavigation links={links.filter(link => link.screen === 'mobile' || link.screen === 'both')} />
        </>
    );
};

export default Navigation;
