import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from 'styles/util';

import Tooltip from '@material-ui/core/Tooltip';
import InfoIcon from '@material-ui/icons/Info';

const useStyles = makeStyles(({ theme }) => ({
    tooltip: {
        margin: theme.spacing(0, 1)
    }
}));

const TooltipIcon = ({ text, className }) => {
    const classes = useStyles();
    return (
        <Tooltip className={`${classes.tooltip} ${className}`} disableFocusListener title={text} enterTouchDelay={100}>
            <InfoIcon/>
        </Tooltip>
    );
};

TooltipIcon.propTypes = {
    text: PropTypes.string.isRequired,
    className: PropTypes.string
};

export default TooltipIcon;
