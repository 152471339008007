import store from 'store2';

const TOKEN_KEY = 'store.authentication.token';

export const addTokenToSessionStorage = token => {
    store.set(TOKEN_KEY, token);
};

export const removeTokenFromSessionStorage = () => {
    store.remove(TOKEN_KEY);
};

export const getTokenFromSessionStorage = () => {
    return store.get(TOKEN_KEY);
};
