import { RSAA } from 'redux-api-middleware';
import { createRsaaTypes, createHeaders } from './actionHelpers';
import queryString from 'query-string';

export const TAKEOVERTENANTS_FETCH = 'TAKEOVERTENANTS_FETCH';
export const TAKEOVERTENANTS_CONNECT_BOOKING = 'TAKEOVERTENANTS_CONNECT_BOOKING';

export const fetchTakeoverTenant = (takeoverTenantId, token, setLinkClickedStatus = false, includeQrCode = false) => ({
    [RSAA]: {
        types: createRsaaTypes(TAKEOVERTENANTS_FETCH),
        endpoint: `/api/takeoverTenants/${takeoverTenantId}/${token}?${queryString.stringify({ setLinkClickedStatus, includeQrCode })}`,
        headers: createHeaders(false),
        method: 'GET'
    }
});

export const connectTakeoverBooking = (takeoverTenantId, token, request) => ({
    [RSAA]: {
        types: createRsaaTypes(TAKEOVERTENANTS_CONNECT_BOOKING),
        endpoint: `/api/takeoverTenants/${takeoverTenantId}/${token}/bookings/connect`,
        headers: createHeaders(true),
        body: JSON.stringify(request),
        method: 'PUT'
    }
});
