import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router';
import { useHistory } from 'react-router-dom';
import { getBooking, updateBooking, createBookingNote, synchronizeParakeyAccess } from 'actions/admin/bookings';
import { fetchStorageSite } from 'actions/storageSites';
import { searchBookings } from 'actions/admin/bookings';
import { handleResponse } from 'actions/actionHelpers';
import routes from 'routes';
import { formatIsoDate } from 'helpers/DateHelper';
import { createUpdateBookingRequest } from './BookingHelper';
import lockTypes from 'enums/lockTypes';

import EditBookingForm from './EditBookingForm';
import Loader from 'common/Loader';
import AdminPage from '../../presentational/AdminPage';
import AdminContentWrapper from '../../presentational/AdminContentWrapper';
import AdminActionWrapper from '../../presentational/AdminActionWrapper';
import Button from '@material-ui/core/Button';
import { Link } from 'react-router-dom';
import EditNoteDialog from 'components/EditNoteDialog';

const Booking = () => {
    const dispatch = useDispatch();
    const history = useHistory();
    const params = useParams();

    const [booking, setBooking] = useState(undefined);
    const [storageSite, setStorageSite] = useState(undefined);
    const [isLoading, setIsLoading] = useState(true);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [bookingNoteDialogOpen, setBookingNoteDialogOpen] = useState(false);

    useEffect(() => {
        loadBooking();
    }, []);

    const loadBooking = () => {
        dispatch(getBooking(params.bookingId))
            .then(handleResponse(
                response1 => {
                    setBooking(response1.payload);
                    dispatch(fetchStorageSite(response1.payload.storageSiteId, formatIsoDate(new Date()), undefined, true, undefined, params.bookingId))
                        .then(handleResponse(
                            response2 => {
                                setStorageSite(response2.payload);
                                setIsLoading(false);
                            },
                            () => setIsLoading(false)
                        ));
                },
                () => setIsLoading(false)
            ));
    };

    const handleSubmit = formValues => {
        setIsSubmitting(true);
        const updateBookingRequest = createUpdateBookingRequest(formValues);
        dispatch(updateBooking(booking.id, updateBookingRequest))
            .then(handleResponse(
                () => {
                    history.push(routes.admin.bookings);
                    setIsSubmitting(false);
                    return { snackbar: { message: 'Booking was successfully saved.' } };
                },
                () => setIsSubmitting(false)
            ));
    };

    const handleCancel = () => {
        history.push(routes.admin.bookings);
    };

    const handleAddBookingNoteClick = () => {
        setBookingNoteDialogOpen(true);
    };

    const handleSynchronizeParakeyAccess = () => {
        setIsLoading(true);
        dispatch(synchronizeParakeyAccess(params.bookingId))
        .then(handleResponse(
            () => {
                setIsLoading(false);
                return { snackbar: { message: 'Parakey access was synchronized.' } };
            },
            () => {
                setIsLoading(false);
            }
        ));
    };

    const handleBookingNoteDialogSubmit = (formValues, form) => {
        const request = {
            text: formValues.text,
            flagged: formValues.flagged,
            isSuperAdminNote: true
        };
        dispatch(createBookingNote(params.bookingId, request))
            .then(handleResponse(
                response => {
                    const newBooking = { ...booking, flagged: request.flagged };
                    newBooking.notes.push(response.payload);
                    setBooking(newBooking);
                    form.reset();
                    setBookingNoteDialogOpen(false);
                    dispatch(searchBookings());
                    return { snackbar: { message: 'Note was successfully saved.' } };
                }
            ));
    };

    const handleBookingNoteDialogCancel = () => {
        setBookingNoteDialogOpen(false);
    };

    const title = 'Booking: ' + (booking && storageSite ? booking.id : 'Loading...');

    return (
        <AdminPage title={title}>
            {
                isLoading && <Loader />
            }
            {
                !isLoading && booking && storageSite && (
                    <AdminContentWrapper type="detail">

                        <EditBookingForm
                            booking={booking}
                            storageSite={storageSite}
                            onSubmit={handleSubmit}
                            onCancel={handleCancel}
                            isSubmitting={isSubmitting}
                        />

                        <AdminActionWrapper>
                            <Button
                                variant="contained"
                                color="primary"
                                onClick={handleAddBookingNoteClick}
                            >
                                Add note
                            </Button>
                            {
                                booking.lockCodeUrl &&
                                (
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        component={Link}
                                        to={{ pathname: booking.lockCodeUrl }}
                                        target="_blank"
                                    >
                                        Show lock code
                                    </Button>
                                )
                            }
                            {
                                booking.lockTypes.find(o => o === lockTypes.parakey.key) &&
                                (
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        onClick={handleSynchronizeParakeyAccess}
                                    >
                                        Synchronize Parakey access
                                    </Button>
                                )
                            }
                        </AdminActionWrapper>

                    </AdminContentWrapper>
                )
            }
            <EditNoteDialog
                open={bookingNoteDialogOpen}
                flagged={booking?.flagged}
                onSubmit={handleBookingNoteDialogSubmit}
                onCancel={handleBookingNoteDialogCancel}
            />
        </AdminPage>
    );
};

export default Booking;
