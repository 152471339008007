import React from 'react';
import PropTypes from 'prop-types';
import { useAppContext } from 'context/AppContext';
import { formatLength } from 'helpers/StringHelper';

const Length = ({ value, numberOfDecimals, maxNumberOfDecimals, toLocale }) => {
    const { appContext } = useAppContext();
    return <>{formatLength(value, appContext, { numberOfDecimals, maxNumberOfDecimals, toLocale })}</>;
};

Length.propTypes = {
    value: PropTypes.number.isRequired,
    numberOfDecimals: PropTypes.number,
    maxNumberOfDecimals: PropTypes.number,
    toLocale: PropTypes.bool,
    sanitizeMinusSign: PropTypes.bool,
    skipThousandSeparators: PropTypes.bool,
    defaultValue: PropTypes.string
};

export default Length;
