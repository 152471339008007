import * as actionTypes from 'actions/admin/users';

const initialState = {
    searchResult: {
        isLoading: false
    },
    searchParameters: {}
};

const users = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.ADMIN_USERS_SEARCH_SUCCESS:
            return { ...state, searchResult: action.payload };
        case actionTypes.ADMIN_USERS_SET_SEARCH_PARAMETERS:
            return { ...state, searchParameters: action.searchParameters };
        default:
            return state;
    }
};


export default users;
