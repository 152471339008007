import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from 'styles/util';
import { formatClassNames } from 'helpers/ComponentHelper';

import Box from '@material-ui/core/Box';

const ContentBox = ({ children, className, themeMaxWidth = 'xl', widgetSpacing = false, ...rest }) => {
    const useStyles = makeStyles(({ theme }) => ({
        container: {
            maxWidth: theme.themeContentWidth[themeMaxWidth],
            marginLeft: 'auto',
            marginRight: 'auto',
            width: '100%',
            ...widgetSpacing && {
                display: 'flex',
                flexDirection: 'column',
                gap: widgetSpacing
            }
        }
    }));

    const classes = useStyles();

    return (
        <Box {...rest} className={formatClassNames([classes.container, className])}>
            {children}
        </Box>
    );
};

ContentBox.propTypes = {
    className: PropTypes.string,
    themeMaxWidth: PropTypes.string,
    children: PropTypes.node.isRequired,
    widgetSpacing: PropTypes.oneOfType([PropTypes.string, PropTypes.bool])
};

export default ContentBox;
