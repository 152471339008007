import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import { makeStyles } from 'styles/util';
import { getStorageGroupTitle, storageGroupHasLockCodes } from 'helpers/StorageSiteHelper';
import { useAppContext } from 'context/AppContext';
import { handleResponse } from 'actions/actionHelpers';
import { fetchStorageGroupLockCodes } from 'actions/admin/storageGroups';

import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Form from 'form/Form';
import { TextField } from 'mui-rff';
import MenuItem from '@material-ui/core/MenuItem';
import Button from '@material-ui/core/Button';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import MarginBox from 'common/MarginBox';
import Loader from 'common/Loader';

const useStyles = makeStyles(({ theme }) => ({
    fieldContainer: {
        display: 'flex',
        ' & > *': {
            alignSelf: 'center'
        },
        '& > :first-child': {
            flex: '1 1 auto'
        },
        '& > :last-child': {
            flex: '0 0 auto',
            marginLeft: theme.spacing(2)
        }
    }
}));

const GetLockCodesDialog = ({ storageSite, open, onClose }) => {
    const classes = useStyles();
    const { appContext } = useAppContext();
    const dispatch = useDispatch();
    const [isLoading, setIsLoading] = useState(false);
    const [lockCodes, setLockCodes] = useState(undefined);

    const storageGroupsWithLockCodes = storageSite.storageGroups.filter(storageGroupHasLockCodes);

    const initialValues = {
        storageGroupId: storageGroupsWithLockCodes[0].id
    };

    const handleFormSubmit = values => {
        setIsLoading(true);
        setLockCodes(undefined);
        dispatch(fetchStorageGroupLockCodes(values.storageGroupId))
            .then(handleResponse(
                response => {
                    setLockCodes(response.payload);
                    setIsLoading(false);
                },
                () => setIsLoading(false)
            ));
    };

    const getLockCodeLabel = lockCode => lockCode.name
        ? lockCode.name + ': '
        : '';

    return (
        <Dialog fullWidth maxWidth="md" open={open} onClose={onClose}>
            <Form
                initialValues={initialValues}
                onSubmit={handleFormSubmit}
            >
                {({ handleSubmit }) => (
                    <form onSubmit={handleSubmit}>
                        <DialogTitle disableTypography>
                            <Typography variant="h5">
                                Get lock codes
                            </Typography>
                        </DialogTitle>
                        <DialogContent>
                            <Box className={classes.fieldContainer}>
                                <TextField
                                    select
                                    name="storageGroupId"
                                    label="Storage group"
                                    variant="outlined"
                                    autoFocus
                                >
                                    {
                                        storageGroupsWithLockCodes.map(o => (
                                            <MenuItem key={o.id} value={o.id}>
                                                {getStorageGroupTitle(o, appContext)}
                                            </MenuItem>
                                        ))
                                    }
                                </TextField>
                                <Box>
                                    <Button
                                        type="submit"
                                        variant="contained"
                                        color="primary"
                                    >
                                        Get codes
                                    </Button>
                                </Box>
                            </Box>

                            <MarginBox top={4}>
                                {
                                    isLoading && <Loader minHeight={0} />
                                }
                                {
                                    lockCodes?.map((lockCode, i) => (
                                        <Box key={i}>
                                            {getLockCodeLabel(lockCode)}{lockCode.code}
                                        </Box>
                                    ))
                                }
                            </MarginBox>
                        </DialogContent>
                        <DialogActions>
                            <Button
                                variant="outlined"
                                color="primary"
                                onClick={onClose}
                            >
                                Close
                            </Button>
                        </DialogActions>
                    </form>
                )}
            </Form>
        </Dialog>
    );
};

GetLockCodesDialog.propTypes = {
    storageSite: PropTypes.object.isRequired,
    open: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired
};

export default GetLockCodesDialog;
