import React from 'react';
import { useAppContext } from 'context/AppContext';
import { makeStyles } from 'styles/util';
import { useSelector } from 'react-redux';
import { useForm } from 'react-final-form';
import { getResizedImageUrl } from 'helpers/ImageHelper';
import strings from 'localization/strings';
import useMediaQuery from '@material-ui/core/useMediaQuery';

import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import Text from 'common/Text';
import VideoPlayer from 'common/VideoPlayer';
import DocumentList from 'common/DocumentList';
import ShortDate from 'common/ShortDate';

const useStyles = makeStyles(({ theme, colors }) => ({
    marginBottom: {
        margin: theme.spacing(0, 0, 4, 0)
    },
    mediaList: {
        margin: 0,
        padding: 0,
        '& li': {
            display: 'inline-block',
            margin: theme.spacing(0, 2, 2, 0),
            lineHeight: '0 !important'
        }
    },
    media: {
        border: `1.5px solid ${colors.mediumGrey}`
    },
    container: {
        marginBottom: theme.spacing(-2)
    }
}));

const DisplayStep5 = () => {
    const isMobile = !useMediaQuery(theme => theme.breakpoints.up('md'));
    const classes = useStyles();
    const { appContext } = useAppContext();

    const authenticationContext = useSelector(state => state.authentication.context);

    const formValues = useForm().getState().values;
    const values = formValues.steps[5];

    const imageWidth = 160;
    const imageHeight = 100;

    const videoWidth = isMobile ? '100%' : 300;
    const videoHeight = isMobile ? undefined : 188;

    const showAttentionText = authenticationContext.isSuperAdminOrImpersonated && values.attentionText;

    return (
        <Box className={classes.container}>
            <Typography variant="h6" gutterBottom>
                {strings.title}
            </Typography>

            <Typography variant="h4" className={classes.marginBottom}>
                {values.title}
            </Typography>

            <Typography variant="h6" gutterBottom>
                {strings.description}
            </Typography>

            <Typography variant="body1" className={classes.marginBottom}>
                <Text text={values.description}/>
            </Typography>

            {
                values.descriptionGivenAfterPurchase &&
                (
                    <>
                        <Typography variant="h6" gutterBottom>
                            {strings.descriptionGivenAfterPurchase}
                        </Typography>
                        <Typography variant="body1" className={classes.marginBottom}>
                            {values.descriptionGivenAfterPurchase}
                        </Typography>
                    </>
                )
            }
            {
                values.lockCode &&
                (
                    <>
                        <Typography variant="h6" gutterBottom>
                            {strings.storageSiteLockCode}
                        </Typography>
                        <Typography variant="body1" className={classes.marginBottom}>
                            {values.lockCode}
                        </Typography>
                    </>
                )
            }
            {
                showAttentionText &&
                (
                    <>
                        <Typography variant="h6" gutterBottom>
                            {strings.attentionText}
                        </Typography>
                        <Typography variant="body1" className={classes.marginBottom}>
                            {values.attentionText}
                            {
                                values.attentionTextVisibilityDeadline &&
                                (
                                    <>
                                        <br/>
                                        {strings.lastVisibilityDate}: <ShortDate value={values.attentionTextVisibilityDeadline}/>
                                    </>
                                )
                            }
                        </Typography>
                    </>
                )
            }

            <Typography variant="h6" gutterBottom>
                {strings.images}
            </Typography>

            {
                values.images.length === 0 && <Typography variant="body1" className={classes.marginBottom}>{strings.noImagesUploaded}</Typography>
            }
            {
                values.images.length > 0 &&
                (
                    <ul className={classes.mediaList}>
                        {
                            values.images.map((image, i) => (
                                <li key={i}>
                                    <img
                                        src={getResizedImageUrl(image.mediaUrl, { width: imageWidth, height: imageHeight, bgcolor: 'f5f5f5' })}
                                        width={imageWidth}
                                        height={imageHeight}
                                        className={classes.image}
                                    />
                                </li>
                            ))
                        }
                    </ul>
                )
            }

            <Typography variant="h6" gutterBottom>
                {strings.videos}
            </Typography>
            {
                values.videos.length === 0 && <Typography variant="body1" className={classes.marginBottom}>{strings.noVideosUploaded}</Typography>
            }
            {
                values.videos.length > 0 &&
                (
                    <ul className={classes.mediaList}>
                        {
                            values.videos.map((video, i) => (
                                <li key={i}>
                                    <VideoPlayer
                                        src={(video.baseUrl + video.mediaUrl)}
                                        width={videoWidth}
                                        height={videoHeight}
                                        className={classes.media}
                                    />
                                </li>
                            ))
                        }
                    </ul>
                )
            }

            <Typography variant="h6" gutterBottom>
                {strings.documents}
            </Typography>
            {
                values.documents.length === 0 && <Typography variant="body1" className={classes.marginBottom}>{strings.noDocumentsUploaded}</Typography>
            }
            {
                values.documents.length > 0 && <DocumentList documents={values.documents} />
            }

            {
                appContext.useAgreements &&
                (
                    <>
                        <Typography variant="h6" gutterBottom>
                            {strings.agreement}
                        </Typography>
                        {
                            values.agreements.length === 0 && <Typography variant="body1" className={classes.marginBottom}>{strings.noAgreementUploaded}</Typography>
                        }
                        {
                            values.agreements.length > 0 && <DocumentList documents={values.agreements.map(o => ({ ...o, description: undefined }))} />
                        }
                    </>
                )
            }
        </Box>
    );
};

export default DisplayStep5;
