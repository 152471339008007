import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import originalAdminReportDefinitions from './adminReportDefinitions';
import { createReportDefinitions } from 'helpers/ReportHelper';

import AdminFormWrapper from '../../presentational/AdminFormWrapper';
import AdminFormFields from '../../presentational/AdminFormFields';
import AdminFormButtons from '../../presentational/AdminFormButtons';
import Form from 'form/Form';
import Button from '@material-ui/core/Button';
import ReportInputFields from 'common/ReportInputFields';

const ReportForm = ({ parameters, onSubmit, isSubmitting = false }) => {
    const authenticationContext = useSelector(state => state.authentication.context);
    const [reportDefinitions, setReportDefinitions] = useState(undefined);
    const [initialValues, setInitialValues] = useState(undefined);

    useEffect(() => {
        const iv = {
            inputFields: {}
        };
        setReportDefinitions(createReportDefinitions(originalAdminReportDefinitions, iv, authenticationContext));
        setInitialValues(iv);
    }, []);

    const handleFormSubmit = formValues => {
        onSubmit({
            ...formValues,
            reportDefinition: reportDefinitions[formValues.report]
        });
    };

    if(!reportDefinitions) {
        return null;
    }

    return (
        <AdminFormWrapper isSubmitting={isSubmitting}>
            <Form
                initialValues={{ ...initialValues, ...parameters }}
                onSubmit={handleFormSubmit}
            >
                {({ form, values, invalid, handleSubmit }) => {
                    const selectedReportDefinition = reportDefinitions[values.report];
                    return (
                        <form onSubmit={handleSubmit}>
                            <AdminFormFields>
                                <ReportInputFields reportDefinitions={reportDefinitions} selectedReportDefinition={selectedReportDefinition} />
                            </AdminFormFields>

                            <AdminFormButtons>
                                <Button
                                    type="submit"
                                    variant="contained"
                                    color="primary"
                                    disabled={invalid}
                                    onClick={() => form.change('format', 'json')}
                                >
                                    Show report
                                </Button>
                                <Button
                                    type="submit"
                                    variant="contained"
                                    color="primary"
                                    disabled={invalid}
                                    onClick={() => form.change('format', 'excel')}
                                >
                                    Export report to Excel
                                </Button>
                            </AdminFormButtons>
                        </form>
                    );
                }}
            </Form>
        </AdminFormWrapper>
    );
};

ReportForm.propTypes = {
    parameters: PropTypes.object.isRequired,
    onSubmit: PropTypes.func.isRequired,
    isSubmitting: PropTypes.bool
};

export default ReportForm;
