import React from 'react';
import PropTypes from 'prop-types';

import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';

const GenericDialog = ({ open, title, body, okLabel, cancelLabel, onOk, onCancel }) => {
    const handleClose = () => {
        if(onCancel) {
            onCancel();
        } else {
            onOk();
        }
    };

    return (
        <Dialog open={open} onClose={handleClose}>
            <DialogTitle disableTypography>
                <Typography variant="h5">
                    {title}
                </Typography>
            </DialogTitle>
            <DialogContent>
                {body}
            </DialogContent>
            <DialogActions>
                <Button
                    type="button"
                    variant="contained"
                    color="primary"
                    onClick={onOk}
                >
                    {okLabel}
                </Button>
                {
                    onCancel &&
                    (
                        <Button
                            type="button"
                            variant="outlined"
                            color="primary"
                            onClick={onCancel}
                        >
                            {cancelLabel}
                        </Button>
                    )
                }
            </DialogActions>
        </Dialog>
    );
};

GenericDialog.propTypes = {
    open: PropTypes.bool.isRequired,
    title: PropTypes.string.isRequired,
    body: PropTypes.string.isRequired,
    okLabel: PropTypes.string.isRequired,
    cancelLabel: PropTypes.string,
    onOk: PropTypes.func.isRequired,
    onCancel: PropTypes.func
};

export default GenericDialog;
