import React, { useContext } from 'react';
import { initializeRoutes } from 'routes';
import strings from 'localization/strings';

// using React's context feature
// app context: { appContext, branches, markets, stripePromise }
const appContextObject = React.createContext();
export default appContextObject;

// hook for accessing the app context
export const useAppContext = () => useContext(appContextObject);

strings.addLoadCallback(appContext => {
    initializeRoutes(appContext);
});
