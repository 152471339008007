import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from 'styles/util';

const IconEdit = ({ fill }) => {
    const useStyles = makeStyles(() => ({
        svg: {
            fill: fill
        }
    }));

    const classes = useStyles();
    return (
        <svg className={classes.svg} width="32" height="22" viewBox="0 0 32 22" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M2 21.5H30C30.9625 21.5 31.75 20.7125 31.75 19.75C31.75 18.7875 30.9625 18 30 18H2C1.0375 18 0.25 18.7875 0.25 19.75C0.25 20.7125 1.0375 21.5 2 21.5ZM2 12.75H30C30.9625 12.75 31.75 11.9625 31.75 11C31.75 10.0375 30.9625 9.25 30 9.25H2C1.0375 9.25 0.25 10.0375 0.25 11C0.25 11.9625 1.0375 12.75 2 12.75ZM0.25 2.25C0.25 3.2125 1.0375 4 2 4H30C30.9625 4 31.75 3.2125 31.75 2.25C31.75 1.2875 30.9625 0.5 30 0.5H2C1.0375 0.5 0.25 1.2875 0.25 2.25Z" fill="#00B7AF"/>
        </svg>
    );
};

IconEdit.propTypes = {
    fill: PropTypes.string.isRequired
};

export default IconEdit;
