import markets from 'enums/markets';

const defaultMarketKey = markets.sweden.key;

export const getCurrentMarketKey = () => window.configuration.appContext?.marketKey ?? getMarketKeyFromHostName() ?? defaultMarketKey;

const getMarketKeyFromHostName = () => {
    if(window.location.hostname.indexOf('.fi') > -1) {
        return markets.finland.key;
    }
    return markets.sweden.key;
};
