import React from 'react';
import PropTypes from 'prop-types';
import strings from 'localization/strings';
import { makeStyles } from 'styles/util';

import Box from '@material-ui/core/Box';
import InformationItem from './InformationItem';

const useStyles = makeStyles(({ theme }) => ({
    caption: {
        fontSize: '120%',
        fontWeight: 'bold',
        marginBottom: theme.spacing(2)
    },
    informationContainer: {
        display: 'flex',
        flexDirection: 'column',
        gap: theme.spacing(1)
    }
}));

const Actor = ({ caption, value }) => {
    const classes = useStyles();

    return (
        <Box>
            <Box className={classes.caption}>
                {caption}
            </Box>
            <Box className={classes.body}>
                <Box className={classes.informationContainer}>
                    <InformationItem label={strings.name} value={value.name} />
                    {
                        value.organizationNumber && <InformationItem label={strings.organizationNumber} value={value.organizationNumber} />
                    }
                </Box>
            </Box>
        </Box>
    );
};

Actor.propTypes = {
    caption: PropTypes.string.isRequired,
    value: PropTypes.object.isRequired
};

export default Actor;
