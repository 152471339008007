import React from 'react';
import PropTypes from 'prop-types';
import strings from 'localization/strings';

import Alert from '@material-ui/lab/Alert';

const LoadingError = ({ message = strings.loadingErrorMessage }) => {
    return (
        <Alert severity="error">{message}</Alert>
    );
};

LoadingError.propTypes = {
    message: PropTypes.string
};

export default LoadingError;
