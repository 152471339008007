import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from 'styles/util';
import clsx from 'clsx';
import Box from '@material-ui/core/Box';

const useStyles = makeStyles(({ theme }) => ({
    container: {
        display: 'flex',
        gap: theme.spacing(0.625),
        alignItems: 'center'
    },
    icon: {
        display: 'flex',
        flex: '0 0 auto',
        paddingRight: theme.spacing(0.5),
        marginBottom: '1px'
    },
    children: {
        flex: '1 1 auto'
    }
}));

const IconPrefix = ({ icon, className, children }) => {
    const classes = useStyles();

    return (
        <Box className={clsx(classes.container, className)}>
            <Box className={classes.icon}>
                {
                    typeof icon === 'string' ?
                        <img src={icon} height="24" width="24"/>
                        :
                        icon
                }
            </Box>
            <Box className={classes.children} component="span">
                {children}
            </Box>
        </Box>
    );
};

IconPrefix.propTypes = {
    icon: PropTypes.oneOfType([PropTypes.node, PropTypes.string]).isRequired,
    className: PropTypes.string,
    children: PropTypes.node.isRequired
};

export default IconPrefix;
