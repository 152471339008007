import organizationTypes from 'enums/organizationTypes';

export const googleTagManagerConstants = {
    events: {
        userAction: 'GOOGLE_TAG_MANAGER_USER_ACTION'
    },
    hitTypes: {
        userAction: 'user-action'
    },
    userActions: {
        search: 'search',
        showBookingRequestDialog: 'show-booking-request-dialog',
        sendBookingRequest: 'send-booking-request',
        approveBooking: 'approve-booking',
        rejectBooking: 'reject-booking',
        bookingPaymentInitialized: 'booking-payment-initialized',
        bookingPaymentSucceeded: 'booking-payment-succeeded',
        userApplicationSent: 'user-application-sent',
        newUserCreated: 'new-user-created',
        userLoggedIn: 'user-logged-in',
        storageSiteCreated: 'storage-site-created',
        storageSiteUpdated: 'storage-site-updated'
    }
};

export const createGoogleTagManagerUserAction = (userAction, userActionMetadata, userActionValue, userActionCurrency) => (
    {
        type: googleTagManagerConstants.events.userAction,
        userAction,
        userActionMetadata,
        userActionValue,
        userActionCurrency
    }
);

export const setDataLayerVariable = (variableName, value) => {
    window.dataLayer.push({
        [variableName]: value
    });
};

export const setDataLayerVariables = variablesObject => {
    window.dataLayer.push(variablesObject);
};

export const getGoogleAnalyticsUserType = actor => {
    // we're using legacy values to keep consistency in Google Analytics
    const legacyUserType = actor.organizationType === organizationTypes.business.key
        ? 'organization'
        : 'individual';
    return legacyUserType;
};
