import React, { useState, useEffect } from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import { useAppContext } from 'context/AppContext';
import { Switch, Route } from 'react-router-dom';
import { setRedirectUrl } from 'actions/authentication';
import { Helmet } from 'react-helmet-async';
import { handleResponse } from 'actions/actionHelpers';
import { getAuthenticationContext, logIn } from 'actions/authentication';
import { useDispatch, useSelector } from 'react-redux';
import CssBaseline from '@material-ui/core/CssBaseline';
import Box from '@material-ui/core/Box';
import { ThemeProvider } from '@material-ui/core/styles';
import { makeStyles } from 'styles/util';
import { SnackbarProvider } from 'notistack';
import Loader from 'common/Loader';
import createAdminTheme from 'admin/createAdminTheme';
import routes from 'routes';
import queryString from 'query-string';

import SnackbarWrapper from './SnackbarWrapper';
import User from '../user/User';
import Users from '../users/Users';
import Actor from '../actor/Actor';
import Actors from '../actors/Actors';
import Booking from '../booking/Booking';
import Bookings from '../bookings/Bookings';
import StorageSites from '../storageSites/StorageSites';
import Purchase from '../purchase/Purchase';
import Purchases from 'admin/containers/purchases/Purchases';
import Payout from '../payout/Payout';
import Payouts from '../payouts/Payouts';
import Conversation from '../conversation/Conversation';
import Conversations from '../conversations/Conversations';
import EditTakeover from '../takeover/EditTakeover';
import ImportTenants from '../takeover/ImportTenants';
import CreateBookingsForTenants from '../takeover/CreateBookingsForTenants';
import SendEmailToTenants from '../takeover/SendEmailToTenants';
import Tenants from '../takeover/Tenants';
import Takeover from '../takeover/Takeover';
import Takeovers from '../takeovers/Takeovers';
import TakeoverMessages from '../takeover/TakeoverMessages';
import CapacityUtilizationContainer from '../capacityUtilization/CapacityUtilizationContainer';
import Reports from '../reports/Reports';
import VersionHistory from '../versionHistory/VersionHistory';
import Dashboard from '../dashboard/Dashboard';
import SuccessDialog from 'common/SuccessDialog';
import ErrorDialog from 'common/ErrorDialog';
import MessageTemplate from '../messageTemplate/MessageTemplate';
import MessageTemplates from '../messageTemplates/MessageTemplates';
import AdminAppBar from './AdminAppBar';
import Menu from './Menu';
import SearchStatistics from 'admin/containers/searchStatistics/SearchStatistics';
import StorageSite from 'admin/containers/storageSite/StorageSite';

export const drawerWidth = 240;

const useStyles = makeStyles(({ theme }) => ({
    root: {
        display: 'flex',
    },
    content: {
        flexGrow: 1,
        padding: theme.spacing(3),
        marginTop: theme.spacing(1)
    },
    toolbar: theme.mixins.toolbar
}));

const AdminApp = () => {
    const classes = useStyles();
    const { appContext } = useAppContext();
    const dispatch = useDispatch();
    const location = useLocation();
    const history = useHistory();
    const search = queryString.parse(location.search);

    const [mobileOpen, setMobileOpen] = useState(false);

    const isAuthenticated = useSelector(state => !!state.authentication.token);

    const authenticationContext = search.loginToken
        ? undefined
        : useSelector(state => state.authentication.context);
    const user = authenticationContext?.user;

    useEffect(() => {
        if(search.loginToken) {
            // we have a login token from a backoffice domain-to-domain redirection
            dispatch(logIn({ loginToken: search.loginToken }))
                .then(handleResponse(
                    () => {
                        window.location.href = location.pathname + getSearchString();
                    },
                    () => redirectToLoginPage()
                ));
        } else if (!isAuthenticated) {
            redirectToLoginPage();
        } else {
            dispatch(getAuthenticationContext());
        }
    }, []);

    const handleDrawerToggle = () => {
        setMobileOpen(!mobileOpen);
    };

    const getSearchString = () => {
        const adjustedSearch = { ...search, loginToken: undefined };
        let searchString = queryString.stringify(adjustedSearch);
        if(searchString) {
            searchString = '?' + searchString;
        }
        return searchString;
    };

    const redirectToLoginPage = () => {
        dispatch(setRedirectUrl(location.pathname + getSearchString()));
        history.push(routes.logIn);
    };

    return (
        <ThemeProvider theme={createAdminTheme(appContext)}>
            <Helmet defer={false}>
                <title>Storage365 Backoffice</title>
            </Helmet>
            {
                authenticationContext?.isSuperAdmin === false &&
                (
                    <Box>Access denied.</Box>
                )
            }
            {
                authenticationContext?.isSuperAdmin === true &&
                (
                    <Box className={classes.root}>
                        <CssBaseline />
                        <AdminAppBar
                            user={user}
                            onDrawerToggle={handleDrawerToggle}
                        />
                        <Menu
                            mobileOpen={mobileOpen}
                            onDrawerToggle={handleDrawerToggle}
                        />

                        <Box className={classes.content}>
                            <Box className={classes.toolbar} />
                            <SnackbarProvider maxSnack={3}>
                                <SnackbarWrapper>
                                    <Switch>
                                        <Route path={routes.admin.user} component={User}/>
                                        <Route path={routes.admin.users} component={Users}/>
                                        <Route path={routes.admin.actor} component={Actor}/>
                                        <Route path={routes.admin.actors} component={Actors}/>
                                        <Route path={routes.admin.booking} component={Booking}/>
                                        <Route path={routes.admin.bookings} component={Bookings}/>
                                        <Route path={routes.admin.storageSite} component={StorageSite}/>
                                        <Route path={routes.admin.storageSites} component={StorageSites}/>
                                        <Route path={routes.admin.purchase} component={Purchase}/>
                                        <Route path={routes.admin.purchases} component={Purchases}/>
                                        <Route path={routes.admin.payout} component={Payout}/>
                                        <Route path={routes.admin.payouts} component={Payouts}/>
                                        <Route path={routes.admin.conversation} component={Conversation}/>
                                        <Route path={routes.admin.conversations} component={Conversations}/>
                                        <Route path={routes.admin.importTakeoverTenants} component={ImportTenants}/>
                                        <Route path={routes.admin.createBookingsForTakeoverTenants} component={CreateBookingsForTenants}/>
                                        <Route path={routes.admin.sendEmailToTakeoverTenants} component={SendEmailToTenants}/>
                                        <Route path={routes.admin.takeoverMessages} component={TakeoverMessages}/>
                                        <Route path={routes.admin.takeoverTenants} component={Tenants}/>
                                        <Route path={routes.admin.createTakeover} component={EditTakeover}/>
                                        <Route path={routes.admin.editTakeover} component={EditTakeover}/>
                                        <Route path={routes.admin.takeover} component={Takeover}/>
                                        <Route path={routes.admin.takeovers} component={Takeovers}/>
                                        <Route path={routes.admin.capacityUtilization} component={CapacityUtilizationContainer}/>
                                        <Route path={routes.admin.reports} component={Reports}/>
                                        <Route path={routes.admin.searchStatistics} component={SearchStatistics}/>
                                        <Route path={routes.admin.versionHistory} component={VersionHistory}/>
                                        <Route path={routes.admin.messageTemplate} component={MessageTemplate}/>
                                        <Route path={routes.admin.messageTemplates} component={MessageTemplates}/>
                                        <Route path={routes.admin.index} component={Dashboard}/>
                                    </Switch>
                                </SnackbarWrapper>
                            </SnackbarProvider>
                        </Box>

                        <SuccessDialog />
                        <ErrorDialog />
                    </Box>
                )
            }
            {
                authenticationContext?.isSuperAdmin === undefined && <Loader />
            }
        </ThemeProvider>
    );
};

export default AdminApp;
