import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from 'styles/util';
import pdfIcon from 'assets/icons/pdf.svg';
import strings from 'localization/strings';

import Box from '@material-ui/core/Box';
import MediaList from './MediaList';
import { TextField } from 'mui-rff';

const useStyles = makeStyles(({ theme, colors }) => ({
    media: {
        border: `1.5px solid ${colors.mediumGrey}`,
        userSelect: 'none'
    },
    loading: {
        display: 'none'
    },
    documentContainer: {
        display: 'flex',
        alignItems: 'center',
        paddingBottom: theme.spacing(2)
    },
    pdfIcon: {
        flex: '0 0 auto',
        width: '5em',
        marginRight: theme.spacing(2),
        [theme.breakpoints.down('sm')]: {
            width: '3em'
        }
    },
    description: {
        flex: '1 0 auto'
    },
    listContainer: {
        marginBottom: theme.spacing(2)
    }
}));

const DocumentList = ({ fields }) => {
    const classes = useStyles();
    return (
        <MediaList fields={fields} direction="vertical" className={classes.listContainer}>
            {
                (document, fieldName) => (
                    <Box className={classes.documentContainer}>
                        <img src={pdfIcon} className={classes.pdfIcon} />
                        <TextField
                            name={`${fieldName}.description`}
                            label={strings.documentDescription}
                            variant="outlined"
                        />
                    </Box>
                )
            }
        </MediaList>
    );
};

DocumentList.propTypes = {
    fields: PropTypes.object.isRequired
};

export default DocumentList;
