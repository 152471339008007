import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from 'styles/util';
import { useForm } from 'react-final-form';
import strings from 'localization/strings';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { getLongDateFormat, formatIsoDate } from 'helpers/DateHelper';
import { required, mustBeGreaterThan } from 'form/validation';
import { createEndAdornment } from 'helpers/FormHelper';

import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import { TextField, showErrorOnBlur } from 'mui-rff';
import DatePicker from 'form/DatePicker';
import IconButton from '@material-ui/core/IconButton';
import DeleteOutlineOutlinedIcon from '@material-ui/icons/DeleteOutlineOutlined';
import ButtonContainer from 'common/ButtonContainer';
import { FieldArray } from 'react-final-form-arrays';
import MarginBox from 'common/MarginBox';

const useStyles = makeStyles(({ theme }) => ({
    grid: {
        marginBottom: theme.spacing(4)
    },
    discountRow: {
        display: 'flex',
        alignItems: 'center'
    },
    date: {
        flex: '1 1 33.3%',
        marginRight: theme.spacing(1)
    },
    percentage: {
        flex: '1 1 33.3%',
        marginRight: theme.spacing(1)
    },
    delete: {
        flex: '0 0 auto'
    }
}));

const EditStep4b = ({ showStepNumber = true }) => {
    const classes = useStyles();

    const fieldNamePrefix = 'steps[4]';

    const getName = suffix => suffix ? `${fieldNamePrefix}.${suffix}` : fieldNamePrefix;
    const formValues = useForm().getState().values;
    const value = formValues.steps[4];

    const handleAddDiscount = fields => {
        const newDiscount = {
            percentage: undefined,
            startDate: undefined,
            endDate: undefined
        };
        fields.push(newDiscount);
    };

    const handleRemoveDiscount = (fields, index) => {
        fields.remove(index);
    };

    const buttonFullWidth = !useMediaQuery(theme => theme.breakpoints.up('md'));

    return (
        <Box>
            <MarginBox bottom={2}>
                <Typography variant="h5">
                    { showStepNumber && '4b. ' }
                    {strings.discounts}
                </Typography>
            </MarginBox>

            <MarginBox bottom={2}>
                <Typography variant="body1">
                    {strings.discountInformation}
                </Typography>
            </MarginBox>

            <Grid container spacing={2} className={classes.grid}>
                <Grid item xs={12}>
                    <FieldArray name={getName('discounts')}>
                        {({ fields }) => (
                            <>
                                {
                                    fields.map((name, index) => (
                                        <Box className={classes.discountRow} key={index}>
                                            <Box className={classes.percentage}>
                                                <TextField
                                                    name={`${name}.percentage`}
                                                    label={strings.percentage}
                                                    variant="outlined"
                                                    required
                                                    fieldProps={{ validate: mustBeGreaterThan(0) }}
                                                    InputProps={createEndAdornment('%')}
                                                    showError={showErrorOnBlur}
                                                />
                                            </Box>
                                            <Box className={classes.date}>
                                                <DatePicker
                                                    name={`${name}.startDate`}
                                                    label={strings.fromUppercase}
                                                    formatFunc={getLongDateFormat}
                                                    inputVariant="outlined"
                                                    showError={showErrorOnBlur}
                                                    required
                                                    fieldProps={{ maxDate: formatIsoDate(value.discounts[index].endDate), validate: required }}
                                                />
                                            </Box>
                                            <Box className={classes.date}>
                                                <DatePicker
                                                    name={`${name}.endDate`}
                                                    label={strings.toUppercase}
                                                    formatFunc={getLongDateFormat}
                                                    inputVariant="outlined"
                                                    showError={showErrorOnBlur}
                                                    required
                                                    fieldProps={{ minDate: formatIsoDate(value.discounts[index].startDate), validate: required }}
                                                />
                                            </Box>
                                            <IconButton
                                                className={classes.delete}
                                                onClick={() => handleRemoveDiscount(fields, index)}
                                            >
                                                <DeleteOutlineOutlinedIcon/>
                                            </IconButton>
                                        </Box>
                                    ))
                                }
                                <ButtonContainer>
                                    <Button
                                        variant="contained"
                                        color="secondary"
                                        onClick={() => handleAddDiscount(fields)}
                                        fullWidth={buttonFullWidth}
                                    >
                                        {strings.addDiscount}
                                    </Button>
                                </ButtonContainer>
                            </>
                        )}
                    </FieldArray>
                </Grid>
            </Grid>

        </Box>
    );
};

EditStep4b.propTypes = {
    showStepNumber: PropTypes.bool
};

export default EditStep4b;
