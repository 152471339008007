import React, { useState } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { makeStyles } from 'styles/util';
import { useAppContext } from 'context/AppContext';
import strings from 'localization/strings';
import { parseIsoDate, formatLongDate, formatShortDate } from 'helpers/DateHelper';
import { differenceInDays } from 'date-fns';
import { roundAmount } from 'helpers/MonetaryHelper';
import { formatDateAndTimeSpan } from 'helpers/OpeningTimeSpanHelper';
import { createPaymentFormInitialValues, sanitizePaymentFormValues, stripeFormIsIncomplete, getInvoiceFee, getAvailableAndCurrentPaymentProviders, getSelectedPaymentProvider } from 'logic/paymentLogic';
import { getContact, getAddress } from 'helpers/ActorHelper';
import purchaseTypes from 'enums/purchaseTypes';
import contactTypes from 'enums/contactTypes';
import paymentProviders from 'enums/paymentProviders';
import addressTypes from 'enums/addressTypes';

import StripeElementsWrapper from 'components/stripe/StripeElementsWrapper';
import Form from 'form/Form';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import TwoColumnTable from 'common/TwoColumnTable';
import PaymentElement from 'components/payment/PaymentElement';
import FullScreenLoader from 'common/FullScreenLoader';
import Amount from 'common/Amount';
import ShortDate from 'common/ShortDate';
import DateDisplay from 'common/DateDisplay';
import MarginBox from 'common/MarginBox';

const useStyles = makeStyles(({theme}) => ({
    actions: {
        flexWrap: 'wrap',
        gap: theme.spacing(2)
    },
    btnSmall: {
        height: '40px',
    },
    halfWidth: {
        flex: `0 0 calc(50% - ${theme.spacing(1)}px)`
    }
}));

const EndDialog = ({ booking, open, onOk, onCancel, isOwner = false }) => {
    const { appContext } = useAppContext();
    const classes = useStyles();
    const [isSaving, setIsSaving] = useState(false);
    const [paymentElementProvider, setPaymentElementProvider] = useState(undefined);
    const [paymentSetupComplete, setPaymentSetupComplete] = useState(false);

    const availablePaymentProviders = getAvailableAndCurrentPaymentProviders(booking);
    const selectedPaymentProvider = getSelectedPaymentProvider(booking, availablePaymentProviders);

    const createStripePaymentIntentRequest = !isOwner && availablePaymentProviders.includes(paymentProviders.stripe.key)
        ? {
            bookingId: booking.id,
            type: purchaseTypes.endSubscriptionBookingPurchase.key
        }
        : undefined;

    const canEndForFree = booking.subscriptionInfo && booking.subscriptionInfo.canEndForFree;
    const paymentRequired = !isOwner && !canEndForFree;

    const initialValues = {
        paymentRequired,
        payment: paymentRequired
            ? createPaymentFormInitialValues({
                paymentProvider: selectedPaymentProvider,
                billecta: {
                    collectAddress: true,
                    name: booking.tenantActor.name,
                    email: getContact(booking.tenantActor, [contactTypes.invoiceEmail, contactTypes.mainEmail]),
                    address: booking.invoiceAddress ?? getAddress(booking.tenantActor, addressTypes.invoice)
                }}, appContext)
            : undefined
    };

    const handleFormSubmit = values => {
        setIsSaving(true);
        onOk({
            values,
            onCompleted: () => setIsSaving(false)
        });
    };

    const handleSelectedPaymentProvider = (provider) => {
        setPaymentElementProvider(provider);
    };

    const handlePaymentSetupComplete = (isComplete) => {
        setPaymentSetupComplete(isComplete);
    };

    let amountToPay;

    if(!booking.subscriptionInfo) {
        return null;
    }

    if (!canEndForFree && !isOwner) {
        // must pay extra invoice
        const daysLeftToPay = differenceInDays(parseIsoDate(booking.subscriptionInfo.earliestPossibleEndDate), parseIsoDate(booking.subscriptionInfo.invoicedUntilDate));
        amountToPay = roundAmount(booking.priceInfo.perMonth.amounts.totalAmount * (daysLeftToPay / 30), appContext);
    }

    const checkOutDate = parseIsoDate(booking.subscriptionInfo.earliestPossibleCheckOutOpeningTimeSpan?.date ?? booking.subscriptionInfo.earliestPossibleEndDate);
    const checkOutDayString = formatDateAndTimeSpan(
        checkOutDate,
        booking.subscriptionInfo.earliestPossibleCheckOutOpeningTimeSpan,
        appContext,
        formatShortDate
    );

    const getAmountToPayIncludingInvoiceFee = paymentProvider => amountToPay + getInvoiceFee(paymentProvider, appContext).amountIncludingVat;

    const getItems = paymentProvider => [
            { cells: [strings.rentStarted, <ShortDate value={booking.startDate}/>] },
            { cells: [isOwner ? strings.guestPaidUntil : strings.paidUntil, <ShortDate value={booking.subscriptionInfo.paidUntilDate} />] },
            { cells: [strings.minBookingLength, <>{booking.minBookingLength} {strings.daysLower}</>] },
            { cells: [strings.lastRentingDay, checkOutDayString] },
            amountToPay ?
                { cells: [strings.leftToPay, <Amount value={getAmountToPayIncludingInvoiceFee(paymentProvider)} currency={booking.currency} displayPriceRounding />], bold: true }
                : undefined
        ]
        .filter(item => item);

    if(!open) {
        return null;
    }

    return (
        <StripeElementsWrapper createStripePaymentIntentRequest={createStripePaymentIntentRequest}>
            {
                ({ stripePaymentContext }) => (
                    <Dialog open onClose={onCancel} disableEnforceFocus /* to be able to set focus to elements outside the dialog, e.g. when authorizing credit card in an iframe provided by Stripe */>
                        <FullScreenLoader open={isSaving} />
                        <Form
                            initialValues={initialValues}
                            initialValuesEqual={() => true}
                            onSubmit={values => handleFormSubmit(sanitizePaymentFormValues(values, stripePaymentContext))}
                        >
                            {({ handleSubmit, values }) => (
                                <form onSubmit={handleSubmit}>
                                    <DialogTitle disableTypography>
                                        <Typography variant="h5">
                                            {strings.endSubscriptionBookingTitle}
                                        </Typography>
                                    </DialogTitle>
                                    <DialogContent>
                                        <Typography variant="body1" gutterBottom>
                                            {strings.formatString(isOwner ? strings.endSubscriptionBookingDescriptionOwner : strings.endSubscriptionBookingDescriptionTenant, <DateDisplay formatter={formatLongDate} value={checkOutDate}/>)}
                                        </Typography>
                                        <TwoColumnTable items={getItems(values.payment?.paymentProvider)} nowrap />
                                        {
                                            paymentRequired &&
                                            (
                                                <MarginBox top={4}>
                                                    <PaymentElement
                                                        name="payment"
                                                        paymentProviders={availablePaymentProviders}
                                                        stripePaymentContext={stripePaymentContext}
                                                        onSelectedPaymentProvider={handleSelectedPaymentProvider}
                                                        paymentSetupComplete={paymentSetupComplete}
                                                    />
                                                </MarginBox>
                                            )
                                        }
                                    </DialogContent>
                                    {/* <DialogActions disableSpacing className={classes.actions}>
                                        <Button
                                            type="submit"
                                            variant="contained"
                                            color="primary"
                                            fullWidth
                                            disabled={(paymentRequired && stripeFormIsIncomplete(values.payment, stripePaymentContext))}
                                        >
                                            {paymentRequired ? strings.endAndPaySubscriptionBooking : strings.endSubscriptionBooking}
                                        </Button>
                                        <Button
                                            className={classes.btnSmall}
                                            variant="contained"
                                            color="secondary"
                                            fullWidth
                                            onClick={onCancel}
                                        >
                                            {strings.cancel}
                                        </Button>
                                    </DialogActions> */}
                                    <DialogActions disableSpacing className={classes.actions}>
                                        {!paymentRequired && (
                                            <Button
                                                type="submit"
                                                variant="contained"
                                                color="primary"
                                                fullWidth
                                            >
                                                {strings.endSubscriptionBooking}
                                            </Button>
                                        )}
                                        {paymentRequired && (
                                            <>
                                                {paymentSetupComplete ? (
                                                    <>
                                                        <Button
                                                            type="submit"
                                                            variant="contained"
                                                            color="primary"
                                                            fullWidth
                                                            disabled={stripeFormIsIncomplete(values.payment, stripePaymentContext)}
                                                        >
                                                            {strings.endAndPaySubscriptionBooking}
                                                        </Button>
                                                        <Button
                                                            className={`${classes.btnSmall} ${classes.halfWidth}`}
                                                            type="button"
                                                            variant="contained"
                                                            color="secondary"
                                                            onClick={() => handlePaymentSetupComplete(false)}
                                                        >
                                                            <ArrowBackIcon />{strings.goBack}
                                                        </Button>
                                                    </>
                                                ) : (
                                                    <Button
                                                        type="button"
                                                        variant="contained"
                                                        color="primary"
                                                        fullWidth
                                                        onClick={() => handlePaymentSetupComplete(true)}
                                                        disabled={!paymentElementProvider}
                                                    >
                                                        {strings.continue}
                                                    </Button>
                                                )}
                                            </>
                                        )}
                                        <Button
                                            className={clsx(classes.btnSmall, {[classes.halfWidth]: paymentSetupComplete})}
                                            variant="contained"
                                            color="secondary"
                                            fullWidth={!paymentSetupComplete}
                                            onClick={onCancel}
                                        >
                                            {strings.cancel}
                                        </Button>
                                    </DialogActions>
                                </form>
                            )}
                        </Form>
                    </Dialog>
                )
            }
        </StripeElementsWrapper>
    );
};

EndDialog.propTypes = {
    booking: PropTypes.object.isRequired,
    open: PropTypes.bool.isRequired,
    onOk: PropTypes.func.isRequired,
    onCancel: PropTypes.func.isRequired,
    isOwner: PropTypes.bool
};

export default EndDialog;
