import React, { useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useHistory } from 'react-router-dom';
import { searchUsers, setSearchParameters } from 'actions/admin/users';
import { performSearch, mergeSearchParameters } from 'components/adminTable/AdminTableHelper';
import routes from 'routes';

import { Link } from 'react-router-dom';
import AdminPage from '../../presentational/AdminPage';
import AdminContentWrapper from '../../presentational/AdminContentWrapper';
import SearchUsersForm from './SearchUsersForm';
import AdminTable from 'components/adminTable/AdminTable';
import Loader from 'common/Loader';

const columns = [
    {
        title: 'ID',
        id: 'id',
        align: 'right'
    },
    {
        title: 'Name',
        id: 'name',
        render: (user) => <Link to={routes.admin.user.replace(':userId', user.id)}>{user.name}</Link>
    },
    {
        title: 'E-mail',
        id: 'email',
    },
    {
        title: 'Authentication type',
        id: 'authenticationType',
    },
];

const Users = () => {
    const dispatch = useDispatch();
    const location = useLocation();
    const history = useHistory();

    const searchResult = useSelector(state => state.admin.users.searchResult);
    const searchParametersFromState = useSelector(state => state.admin.users.searchParameters);

    const defaultParameters = {
        query: undefined,
        page: 0,
        pageSize: 20,
        sortByColumn: 'name',
        sortDirection: 'asc'
    };

    const search = (parameters) => {
        const searchParameters = mergeSearchParameters(parameters, location, searchParametersFromState, defaultParameters);
        performSearch(performSearchObject, searchParameters);
    };

    const performSearchObject = {
        searchParametersFromState,
        setSearchParametersToState: parameters => dispatch(setSearchParameters(parameters)),
        searchResult,
        search,
        apiCall: parameters => {
            if(parameters.query) {
                dispatch(searchUsers(parameters));
            }
        },
        location,
        history,
        isInitializedRef: useRef()
    };

    useEffect(() => {
        const searchParameters = mergeSearchParameters({}, location, searchParametersFromState, defaultParameters);
        performSearch(performSearchObject, searchParameters);
    }, []);

    const handleSearchFormSubmit = (formValues) => {
        search({ ...defaultParameters, ...formValues, page: 0 });
    };

    const handleSort = (sortingParameters) => {
        search(sortingParameters);
    };

    const handlePageChange = (newPage) => {
        search({ page: newPage });
    };

    const handlePageChangeSize = (newPageSize) => {
        search({ page: 0, pageSize: newPageSize });
    };

    return (
        <AdminPage title="Users">
            <AdminContentWrapper type="listing">

                <SearchUsersForm
                    searchParameters={searchParametersFromState}
                    onSubmit={handleSearchFormSubmit}
                />

                <div>
                    {
                        searchResult.isLoading && <Loader />
                    }
                    {
                        searchResult.items && (
                            <AdminTable
                                isPaged
                                items={searchResult.items}
                                count={searchResult.count}
                                page={searchResult.page}
                                pageSize={searchResult.pageSize}
                                columns={columns}
                                sortByColumn={columns.find(c => c.id === searchParametersFromState.sortByColumn)}
                                sortDirection={searchParametersFromState.sortDirection}
                                onSort={handleSort}
                                onPageChange={handlePageChange}
                                onPageChangeSize={handlePageChangeSize}
                            />
                        )
                    }
                </div>

            </AdminContentWrapper>
        </AdminPage>
    );
};

export default Users;
