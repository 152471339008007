import strings from 'localization/strings';
import {formFieldKeys} from 'helpers/StorageSearchFormHelper';
import storageSiteFacilities from 'enums/storageSiteFacilities';
import organizationTypes from 'enums/organizationTypes';

const additionalFilterTypes = (landingPage, appContext) => ({
    goodsTypes: {
        title: strings.vehicleTypes,
        show: !!landingPage.goodsTypes,
        values: [landingPage.goodsTypes]
    },
    storageTypes: {
        title: strings.storageType,
        show: !!landingPage.storageTypes,
        values: [landingPage.storageTypes]
    },
    environments: {
        title: strings.storage,
        show: landingPage.useMainFilter && appContext.facilityCategories.environment.length > 0,
        values: [
            { title: strings.indoor, key: formFieldKeys.indoor},
            { title: strings.outdoor, key: formFieldKeys.outdoor},
        ]
    },
    facilities: {
        title: strings.storageSearchForm.facilityLabel,
        show: appContext.facilityCategories.general.length > 0,
        values: [
            storageSiteFacilities.electricity,
            storageSiteFacilities.heating,
            storageSiteFacilities.water,
            storageSiteFacilities.groundLevel,
            storageSiteFacilities.elevator
        ]
    },
    organizationTypes: {
        title: strings.storageSearchForm.ownerLabel,
        show: true,
        values: [
            organizationTypes.private,
            organizationTypes.business,
        ]
    },
    securities: {
        title: strings.storageSearchForm.securityLabel,
        show: appContext.facilityCategories.security.length > 0,
        values: [
            storageSiteFacilities.fenced,
            storageSiteFacilities.alarm,
            storageSiteFacilities.coverage,
            storageSiteFacilities.video
        ]
    },
});

export default additionalFilterTypes;
