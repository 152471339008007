import { RSAA } from 'redux-api-middleware';
import { createRsaaTypes, createHeaders } from './actionHelpers';
import queryString from 'query-string';

export const STORAGES_FETCH_BOOKINGS = 'STORAGES_FETCH_BOOKINGS';

export const fetchStorageBookings = (storageId, from, to) => {
    const qs = queryString.stringify({
        from,
        to,
        includeTakeoverBasedBookings: true
    });
    return {
        [RSAA]: {
            types: createRsaaTypes(STORAGES_FETCH_BOOKINGS),
            endpoint: `/api/storages/${storageId}/bookings?${qs}`,
            headers: createHeaders(false),
            method: 'GET'
        }
    };
};
