import React, { useState } from 'react';
import { useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { handleResponse } from 'actions/actionHelpers';
import { updateCombinedUserActor } from 'actions/users';
import { refreshAuthenticationContext } from 'actions/authentication';
import queryString from 'query-string';
import strings from 'localization/strings';
import { getContact } from 'helpers/ActorHelper';
import contactTypes from 'enums/contactTypes';

import LoadingWrapper from 'form/LoadingWrapper';
import UserEditor from './UserEditor';
import MarginBox from 'common/MarginBox';
import Alert from '@material-ui/lab/Alert';

const CombinedUserActorSettings = () => {
    const dispatch = useDispatch();
    const location = useLocation();

    const authenticationContext = useSelector(state => state.authentication.context);
    const { user, selectedActor } = authenticationContext;
    const { privateActorCreated } = queryString.parse(location.search);

    const initialValues = {
        firstName: user.firstName,
        lastName: user.lastName,
        email: user.email,
        password: undefined,
        confirmPassword: undefined,
        mobileTelephone: getContact(selectedActor, contactTypes.mainMobileTelephone),
        sendSms: selectedActor.sendSms
    };

    const [isSaving, setIsSaving] = useState(false);

    const handleSubmit = formValues => {
        setIsSaving(true);
        const updateCombinedUserActorRequest = { ...formValues };
        dispatch(updateCombinedUserActor(user.id, updateCombinedUserActorRequest))
            .then(handleResponse(
                () => {
                    dispatch(refreshAuthenticationContext())
                        .then(handleResponse(
                            () => {
                                // context in Redux state is updated by reducer
                                setIsSaving(false);
                                return {
                                    snackbar: {
                                        message: strings.userSettingsUpdated
                                    }
                                };
                            },
                            () => setIsSaving(false)
                        ));
                },
                () => setIsSaving(false)
            ));
    };

    return (
        <LoadingWrapper
            isSaving={isSaving}
        >
            {
                privateActorCreated &&
                (
                    <MarginBox bottom={4}>
                        <Alert severity="success">
                            {strings.privateActorCreatedMessage}
                        </Alert>
                    </MarginBox>
                )
            }
            <UserEditor
                isCombinedUserActorEditor
                initialValues={initialValues}
                authenticationType={user.authenticationType}
                isSaving={isSaving}
                onSubmit={handleSubmit}
            />
        </LoadingWrapper>
    );
};

export default CombinedUserActorSettings;
