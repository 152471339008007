import { RSAA } from 'redux-api-middleware';
import { createRsaaTypes, createHeaders } from '../actionHelpers';

export const ACCOUNT_CONVERSATIONS_SECTION_LIST_CONVERSATIONS = 'ACCOUNT_CONVERSATIONS_SECTION_LIST_CONVERSATIONS';
export const ACCOUNT_CONVERSATIONS_SECTION_LIST_CONVERSATIONS_SUCCESS = 'ACCOUNT_CONVERSATIONS_SECTION_LIST_CONVERSATIONS_SUCCESS';
export const ACCOUNT_CONVERSATIONS_SECTION_LIST_CONVERSATIONS_FAILURE = 'ACCOUNT_CONVERSATIONS_SECTION_LIST_CONVERSATIONS_FAILURE';

export const ACCOUNT_CONVERSATIONS_SECTION_FETCH_CONVERSATION = 'ACCOUNT_CONVERSATIONS_SECTION_FETCH_CONVERSATION';

export const listConversations = actorId => ({
    [RSAA]: {
        types: createRsaaTypes(ACCOUNT_CONVERSATIONS_SECTION_LIST_CONVERSATIONS),
        endpoint: `/api/actors/${actorId}/conversations`,
        headers: createHeaders(false),
        method: 'GET'
    }
});

export const fetchConversation = (conversationId) => ({
    [RSAA]: {
        types: createRsaaTypes(ACCOUNT_CONVERSATIONS_SECTION_FETCH_CONVERSATION),
        endpoint: `/api/conversations/${conversationId}`,
        headers: createHeaders(false),
        method: 'GET'
    }
});
