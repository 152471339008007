import React from 'react';
import PropTypes from 'prop-types';
import { useForm } from 'react-final-form';
import multiplicities from 'enums/multiplicities';

import EditStep1Single from './EditStep1Single';
import EditStep1Multiple from './EditStep1Multiple';

const EditStep1 = ({ showStepNumber = true, storageSiteExists = false, onSaveStorageGroup }) => {
    const formValues = useForm().getState().values;

    return (
        <>
            {
                formValues.steps[0].multiplicity === multiplicities.single &&
                (
                    <EditStep1Single showStepNumber={showStepNumber} />
                )
            }
            {
                formValues.steps[0].multiplicity === multiplicities.multiple &&
                (
                    <EditStep1Multiple showStepNumber={showStepNumber} storageSiteExists={storageSiteExists} onSaveStorageGroup={onSaveStorageGroup} />
                )
            }
        </>
    );
};

EditStep1.propTypes = {
    showStepNumber: PropTypes.bool,
    storageSiteExists: PropTypes.bool,
    onSaveStorageGroup: PropTypes.func
};

export default EditStep1;
