import React from 'react';
import PropTypes from 'prop-types';
import { useAppContext } from 'context/AppContext';
import { Helmet } from 'react-helmet-async';
import { getPriceInfo } from 'helpers/PriceCalculator';
import { defaultCompare } from 'helpers/SortHelper';

const StorageSiteFacebookMetaTags = ({ storageSite }) => {
    const { appContext } = useAppContext();
    const items = storageSite.storageGroups.map(storageGroup => ({
        storageGroup,
        price: storageGroup.pricePerStorage || storageGroup.pricePerAreaUnit // TODO: add VAT, kind of cheating
    }));
    items.sort((a, b) => defaultCompare(a.price, b.price));
    const priceInfo = getPriceInfo(items[0].price, appContext, items[0].storageGroup);
    return (
        <Helmet defer={false}>
            <meta property="og:id" content={storageSite.id}/>
            <meta property="og:value" content={priceInfo.displayPrice}/>
        </Helmet>
    );
};

StorageSiteFacebookMetaTags.propTypes = {
    storageSite: PropTypes.object.isRequired
};

export default StorageSiteFacebookMetaTags;
