import strings from 'localization/strings';
import { formatPercentage, formatAmount, formatNumber } from 'helpers/StringHelper';
import { getValueForCurrency, getCurrency } from 'logic/capacityUtilizationLogic';

const formatPercentageCreator = (appContext, { round } = {}) => value => formatPercentage(value ?? 0, appContext, { maxNumberOfDecimals: round ? 1 : undefined });
const formatCurrencyCreator = (appContext, { currency } = {}) => value => formatAmount(value, currency, appContext);
const formatValueFromObjectCreator = selector => (o, appContext) => {
    const currency = getCurrency(selector(o));
    return formatAmount(getValueForCurrency(selector(o), currency), currency, appContext);
};
// formatValueCreator: (appContext, { round: bool, currency: string }) => value: number => string
// formatValueFromObject: (propertyValue: object, appContext, { round: bool }) => string
// getValue: propertyObject: object => number
const capacityUtilizationProperties = {
    bookedStorageCount: {},
    bookedArea: {},
    totalStorageCount: {},
    totalArea: {},
    storageCountCapacityUtilization: {
        getNumericValue: o => safeDiv(o.bookedStorageCount, o.totalStorageCount),
        formatValueCreator: formatPercentageCreator,
        additionalColumns: ['bookedStorageCount', 'totalStorageCount']
    },
    areaCapacityUtilization: {
        getNumericValue: o => safeDiv(o.bookedArea, o.totalArea),
        formatValueCreator: formatPercentageCreator,
        additionalColumns: ['bookedArea', 'totalArea']
    },
    amount: {
        getNumericValue: o => getValueForCurrency(o.amount, o.currency),
        formatValueCreator: formatCurrencyCreator,
        formatValueFromObject: formatValueFromObjectCreator(o => o.amount)
    },
    totalValue: {
        getNumericValue: o => getValueForCurrency(o.totalValue, o.currency),
        formatValueCreator: formatCurrencyCreator,
        formatValueFromObject: formatValueFromObjectCreator(o => o.totalValue)
    }
};

Object.keys(capacityUtilizationProperties).forEach(key => {
    capacityUtilizationProperties[key].key = key;
    capacityUtilizationProperties[key].getNumericValue = capacityUtilizationProperties[key].getNumericValue ?? (o => o[key]);
    capacityUtilizationProperties[key].yAxis = capacityUtilizationProperties[key].yAxis ?? {};
    capacityUtilizationProperties[key].formatValueCreator = capacityUtilizationProperties[key].formatValueCreator ?? (appContext => value => formatNumber(value ?? 0, appContext));
    capacityUtilizationProperties[key].formatValueFromObject = capacityUtilizationProperties[key].formatValueFromObject
      ?? ((o, appContext, settings) => capacityUtilizationProperties[key].formatValueCreator(appContext, settings)(capacityUtilizationProperties[key].getNumericValue(o)));
});

strings.addLoadCallback(() => {
    Object.values(capacityUtilizationProperties).forEach(o => {
        o.title = strings.capacityUtilizationProperties[o.key];
    });
});

const safeDiv = (n, d) => d ? n / d : 0;

export default capacityUtilizationProperties;
