import strings from 'localization/strings';
import goodsTypes from 'enums/goodsTypes';

const storageTypes = {
    generalGarage: {
        icon: 'icon-garage',
        goodsTypes: [
            goodsTypes.car,
            goodsTypes.boat,
            goodsTypes.caravan,
            goodsTypes.motorcycle,
            goodsTypes.snowmobile
        ],
        recommendedPrice: 140
    },
    groundSurface: {
        icon: 'icon-area',
        goodsTypes: [],
        recommendedPrice: 20
    },
    industryStorage: {
        icon: 'icon-factory',
        goodsTypes: [
            goodsTypes.car,
            goodsTypes.boat,
            goodsTypes.caravan,
            goodsTypes.motorcycle,
            goodsTypes.snowmobile
        ],
        recommendedPrice: 140
    },
    office: {
        icon: 'icon-office',
        goodsTypes: [],
        recommendedPrice: 140
    },
    storeHouse: {
        icon: 'icon-general_storage',
        goodsTypes: [],
        recommendedPrice: 140
    },
    container: {
        icon: 'icon-container',
        goodsTypes: [],
        recommendedPrice: 140
    },
    berth: {
        icon: 'icon-harbor',
        goodsTypes: [
            goodsTypes.boat,
        ],
        recommendedPrice: 70
    },
    barn: {
        icon: 'icon-barn',
        goodsTypes: [
            goodsTypes.car,
            goodsTypes.boat,
            goodsTypes.caravan,
            goodsTypes.motorcycle,
            goodsTypes.snowmobile
        ],
        recommendedPrice: 70
    },
    commercialStorage: {
        icon: 'icon-local',
        goodsTypes: [],
        recommendedPrice: 140
    },
    pallet: {
        icon: 'icon-box',
        goodsTypes: [],
        recommendedPrice: 140
    },
    parking: {
        icon: 'icon-parking',
        goodsTypes: [
            goodsTypes.car,
            goodsTypes.boat,
            goodsTypes.caravan,
            goodsTypes.motorcycle,
            goodsTypes.snowmobile
        ],
        recommendedPrice: 70
    },
};
Object.keys(storageTypes).forEach(key => {
    storageTypes[key].key = key;
});

strings.addLoadCallback(() => {
    Object.values(storageTypes).forEach(o => {
        o.title = strings.storageTypes[o.key];
    });
});

export default storageTypes;
