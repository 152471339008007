import React, { useState } from 'react';
import PropTypes from 'prop-types';
import strings from 'localization/strings';
import { parseIsoDate } from 'helpers/DateHelper';
import { makeStyles } from 'styles/util';

import Hidden from '@material-ui/core/Hidden';
import Box from '@material-ui/core/Box';
import MarginBox from 'common/MarginBox';
import TableContainer from '@material-ui/core/TableContainer';
import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import TableBody from '@material-ui/core/TableBody';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import List from '@material-ui/core/List';
import Purchase from './Purchase';
import BookingDetailsSectionCaption from './BookingDetailsSectionCaption';
import GenericDialog from 'common/GenericDialog';

const useStyles = makeStyles(({ theme }) => ({
    table: {
        '& .MuiTableCell-sizeSmall': {
            padding: theme.spacing(1, 2)
        }
    }
}));

const Purchases = ({ booking, isOwner = false, onPayPurchase, onDeletePurchase }) => {
    const classes = useStyles();
    const [ paymentDocumentNotYetGeneratedDialogOpen, setPaymentDocumentNotYetGeneratedDialogOpen ] = useState(false);

    const sortedPurchases = [...booking.purchases];
    sortedPurchases.sort((a, b) => -(parseIsoDate(a.createdTime).getTime() - parseIsoDate(b.createdTime).getTime()));

    const includeDueDate = !!sortedPurchases.find(o => o.dueDate);

    const handlePaymentDocumentNotYetGenerated = () => {
        setPaymentDocumentNotYetGeneratedDialogOpen(true);
    };

    const handlePaymentDocumentNotYetGeneratedDialogClose = () => {
        setPaymentDocumentNotYetGeneratedDialogOpen(false);
    };

    return (
        <MarginBox bottom={2}>
            <BookingDetailsSectionCaption>
                {strings.payments}
            </BookingDetailsSectionCaption>

            { /* desktop */ }
            <Hidden smDown>
                <TableContainer component={Box}>
                    <Table size="small" className={classes.table}>
                        <TableHead>
                            <TableRow>
                                <TableCell>{strings.invoiceNumber}</TableCell>
                                <TableCell>{strings.type}</TableCell>
                                <TableCell>{strings.invoiceDate}</TableCell>
                                { includeDueDate && <TableCell>{strings.dueDate}</TableCell> }
                                <TableCell>{strings.amount}</TableCell>
                                <TableCell>{strings.status}</TableCell>
                                <TableCell/>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {
                                sortedPurchases.map(purchase => (
                                    <Purchase
                                        key={purchase.id}
                                        type="table"
                                        booking={booking}
                                        purchase={purchase}
                                        isOwner={isOwner}
                                        includeDueDate={includeDueDate}
                                        onPay={onPayPurchase}
                                        onDelete={onDeletePurchase}
                                        onPaymentDocumentNotYetGenerated={handlePaymentDocumentNotYetGenerated}
                                    />
                                ))
                            }
                        </TableBody>
                    </Table>
                </TableContainer>
            </Hidden>

            { /* mobile */ }
            <Hidden mdUp>
                <List>
                    {
                        sortedPurchases.map(purchase => (
                            <Purchase
                                key={purchase.id}
                                type="list"
                                booking={booking}
                                purchase={purchase}
                                isOwner={isOwner}
                                includeDueDate={includeDueDate}
                                onPay={onPayPurchase}
                                onDelete={onDeletePurchase}
                                onPaymentDocumentNotYetGenerated={handlePaymentDocumentNotYetGenerated}
                            />
                        ))
                    }
                </List>
            </Hidden>

            <GenericDialog
                open={paymentDocumentNotYetGeneratedDialogOpen}
                title={strings.paymentDocumentNotYetGeneratedTitle}
                body={strings.paymentDocumentNotYetGeneratedBody}
                okLabel={strings.close}
                onOk={handlePaymentDocumentNotYetGeneratedDialogClose}
            />
        </MarginBox>
    );
};

Purchases.propTypes = {
    booking: PropTypes.object.isRequired,
    isOwner: PropTypes.bool,
    onPayPurchase: PropTypes.func,
    onDeletePurchase: PropTypes.func
};

export default Purchases;
