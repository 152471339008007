import React from 'react';
import PropTypes from 'prop-types';
import { useAppContext } from 'context/AppContext';
import strings from 'localization/strings';

import BookingDetailsSectionCaption from './BookingDetailsSectionCaption';
import IconPrefix from 'common/IconPrefix';
import VpnKeyIcon from '@material-ui/icons/VpnKey';

const BookingLockCode = ({ booking }) => {
    const { appContext } = useAppContext();
    return (
        <>
            <BookingDetailsSectionCaption>
                {strings.bookingLockCode}
            </BookingDetailsSectionCaption>
            <IconPrefix icon={<VpnKeyIcon htmlColor={appContext.colors.icon}/>}>
                <a href={booking.lockCodeUrl} target="_blank" color="primary" variant="contained">
                    {strings.bookingLockCodeLink}
                </a>
            </IconPrefix>
        </>
    );
};

BookingLockCode.propTypes = {
    booking: PropTypes.object.isRequired
};

export default BookingLockCode;
