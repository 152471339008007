import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from 'styles/util';

import Box from '@material-ui/core/Box';

const Separator = ({ className, spacing = 1 }) => {
    const useStyles = makeStyles(({ theme, colors }) => ({
        separator: {
            borderBottom: `1.5px solid ${colors.mediumGrey}`,
            margin: theme.spacing(spacing, 0),
            paddingTop: '0 !important',
            paddingBottom: '0 !important',
        }
    }));
    const classes = useStyles();

    return <Box className={classes.separator + (className ? ' ' + className : '')}/>;
};

Separator.propTypes = {
    className: PropTypes.string,
    spacing: PropTypes.number
};

export default Separator;
