import strings from 'localization/strings';

const storageSiteStatuses = {
    undefined: {
        chipType: 'danger'
    },
    unsubmittedDraft: {
        chipType: 'hidden'
    },
    submittedDraft: {
        chipType: 'warning'
    },
    approved: {
        chipType: 'success'
    },
    hidden: {
        chipType: 'hidden'
    },
    deleted: {
        chipType: 'hidden'
    }
};

Object.keys(storageSiteStatuses).forEach(key => {
    storageSiteStatuses[key].key = key;
});

strings.addLoadCallback(() => {
    Object.values(storageSiteStatuses).forEach(o => {
        o.title = strings.storageSiteStatuses[o.key];
    });
});

export default storageSiteStatuses;
