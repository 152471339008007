import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import { makeStyles } from 'styles/util';
import { handleResponse } from 'actions/actionHelpers';
import strings from 'localization/strings';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import arrayMutators from 'final-form-arrays';
import { previewPostInvoice } from 'actions/ownerBookings';
import { convertToNumber } from 'helpers/StringHelper';
import organizationTypes from 'enums/organizationTypes';

import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Form from 'form/Form';
import { FieldArray } from 'react-final-form-arrays';
import Button from '@material-ui/core/Button';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import PostInvoiceItemEditor from './PostInvoiceItemEditor';
import CircularProgress from '@material-ui/core/CircularProgress';

const useStyles = makeStyles(({ theme }) => ({
    postInvoiceItem: {
        margin: theme.spacing(1, -1)
    },
    loader: {
        marginTop: theme.spacing(2)
    }
}));

const createNewItem = actor => {
    return {
        description: '',
        amount: undefined,
        eligibleForVat: actor.organizationType === organizationTypes.business.key,
        commissionRate: 5
    };
};

const CreatePostInvoiceDialog = ({ booking, open, onOk, onCancel }) => {
    const classes = useStyles();
    const isMobile = !useMediaQuery(theme => theme.breakpoints.up('md'));
    const dispatch = useDispatch();

    const [creatingPreview, setCreatingPreview] = useState(false);
    const [submittedPostInvoice, setSubmittedPostInvoice] = useState(undefined);
    const [confirmDialogOpen, setConfirmDialogOpen] = useState(false);
    const [initialValues, setInitialValues] = useState({ items: [] });

    const authenticationContext = useSelector(state => state.authentication.context);
    const { selectedActor } = authenticationContext;
    const useVat = selectedActor.organizationType === organizationTypes.business.key;
    const useCustomCommissionRate = authenticationContext.isSuperAdminOrImpersonated;

    useEffect(() => {
        if(open) {
            setInitialValues({ items: [createNewItem(selectedActor)] });
        }
    }, [open]);

    const handleFormSubmit = values => {
        const postInvoice = {
            items: values.items.map(createItemForSave)
        };
        setSubmittedPostInvoice(postInvoice);
        setConfirmDialogOpen(true);
    };

    const handleConfirmDialogOk = () => {
        setConfirmDialogOpen(false);
        onOk(submittedPostInvoice);
    };

    const handleConfirmDialogCancel = () => {
        setConfirmDialogOpen(false);
    };

    const handlePreview = values => {
        setCreatingPreview(true);
        const postInvoice = {
            items: values.items.map(createItemForSave)
        };
        dispatch(previewPostInvoice(booking.id, postInvoice))
            .then(handleResponse(
                () => {
                    setCreatingPreview(false);
                }
            ));
    };

    const handleAddItem = fields => {
        fields.push(createNewItem(selectedActor));
    };

    const handleRemoveItem = (fields, index) => {
        fields.remove(index);
    };

    const createItemForSave = item => ({
        ...item,
        amount: convertToNumber(item.amount),
        currency: booking.currency,
        commissionRate: item.commissionRate
            ? convertToNumber(item.commissionRate) / 100
            : undefined
    });

    return (
        <>
            <Dialog fullWidth maxWidth="md" open={open && !confirmDialogOpen} onClose={onCancel}>
                <Form
                    initialValues={initialValues}
                    mutators={arrayMutators}
                    onSubmit={handleFormSubmit}
                >
                    {({ handleSubmit, submitting, invalid, values }) => (
                        <form onSubmit={handleSubmit}>
                            <FieldArray name="items">
                                {({ fields }) => (
                                    <>
                                        <DialogTitle disableTypography>
                                            <Typography variant="h5">
                                                {strings.createPostInvoice}
                                            </Typography>
                                        </DialogTitle>
                                        <DialogContent>
                                            <Typography variant="body1" gutterBottom>
                                                {strings.createPostInvoiceInformation}
                                            </Typography>
                                            {
                                                fields.length > 0 &&
                                                (
                                                    <Box className={classes.postInvoiceItem}>
                                                        {
                                                            fields.map((name, index) => (
                                                                <PostInvoiceItemEditor
                                                                    key={name}
                                                                    fieldNamePrefix={name}
                                                                    onRemove={() => handleRemoveItem(fields, index)}
                                                                    submitting={submitting}
                                                                    useVat={useVat}
                                                                    useCustomCommissionRate={useCustomCommissionRate}
                                                                />
                                                            ))
                                                        }
                                                    </Box>
                                                )
                                            }
                                            <Button
                                                className={classes.addButton}
                                                variant="outlined"
                                                color="primary"
                                                onClick={() => handleAddItem(fields)}
                                                fullWidth={isMobile}
                                            >
                                                {strings.addPostInvoiceItem}
                                            </Button>
                                            { creatingPreview && <Box className={classes.loader}><CircularProgress color="primary"/></Box> }
                                        </DialogContent>
                                        <DialogActions>
                                            <Button
                                                type="submit"
                                                variant="contained"
                                                color="primary"
                                                disabled={invalid || fields.length === 0}
                                            >
                                                {strings.ok}
                                            </Button>
                                            <Button
                                                variant="outlined"
                                                color="primary"
                                                disabled={invalid || fields.length === 0}
                                                onClick={() => handlePreview(values)}
                                            >
                                                {strings.preview}
                                            </Button>
                                            <Button
                                                variant="outlined"
                                                color="primary"
                                                onClick={onCancel}
                                            >
                                                {strings.cancel}
                                            </Button>
                                        </DialogActions>
                                    </>
                                )}
                            </FieldArray>
                        </form>
                    )}
                </Form>
            </Dialog>

            <Dialog open={confirmDialogOpen} onClose={handleConfirmDialogCancel}>
                <DialogTitle disableTypography>
                    <Typography variant="h5">
                        {strings.postInvoiceConfirmCreateTitle}
                    </Typography>
                </DialogTitle>
                <DialogContent>
                    <Typography variant="body1" gutterBottom>
                        {strings.postInvoiceConfirmCreateBody}
                    </Typography>
                </DialogContent>
                <DialogActions>
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={handleConfirmDialogOk}
                    >
                        {strings.postInvoiceCreate}
                    </Button>
                    <Button
                        variant="outlined"
                        color="primary"
                        onClick={handleConfirmDialogCancel}
                    >
                        {strings.cancel}
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
};

CreatePostInvoiceDialog.propTypes = {
    booking: PropTypes.object.isRequired,
    open: PropTypes.bool.isRequired,
    onOk: PropTypes.func.isRequired,
    onCancel: PropTypes.func.isRequired
};

export default CreatePostInvoiceDialog;
