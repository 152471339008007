import { RSAA } from 'redux-api-middleware';
import { createRsaaTypes, createHeaders } from '../actionHelpers';

export const ADMIN_PAYMENTS_REFUND = 'ADMIN_PAYMENTS_REFUND';
export const ADMIN_PAYMENTS_CONNECT_TO_PURCHASE = 'ADMIN_PAYMENTS_CONNECT_TO_PURCHASE';

export const refundPayment = (paymentId, request) => ({
    [RSAA]: {
        types: createRsaaTypes(ADMIN_PAYMENTS_REFUND),
        endpoint: `/api/admin/payments/${paymentId}/refunds`,
        headers: createHeaders(true),
        method: 'POST',
        body: JSON.stringify(request)
    }
});

export const connectUnmatchedPaymentToPurchase = (paymentId, purchaseId) => ({
    [RSAA]: {
        types: createRsaaTypes(ADMIN_PAYMENTS_CONNECT_TO_PURCHASE),
        endpoint: `/api/admin/payments/${paymentId}/purchases/${purchaseId}/connect`,
        headers: createHeaders(false),
        method: 'PUT'
    }
});
