import React from 'react';
import PropTypes from 'prop-types';
import { useAppContext } from 'context/AppContext';
import { makeStyles } from 'styles/util';
import { formatClassNames } from 'helpers/ComponentHelper';

import Box from '@material-ui/core/Box';
import IconPrefix from 'common/IconPrefix';

const useStyles = makeStyles(({ theme, colors, fonts, styles }) => ({
    contactBox: {
        display: 'flex',
        flexDirection: 'column',
        gap: theme.spacing(2.5)
    },
    supportCta: {
        display: 'flex',
        alignItems: 'center',
        gap: theme.spacing(2.5),
        '& > div:first-child': {
            paddingRight: 0,
            width: '2rem',
            height: '2rem'
        },
        '& a': {
            color: colors.darkBlue,
            fontFamily: fonts.bold,
            textDecoration: 'none',
            lineHeight: 1,
            [theme.breakpoints.down('sm')]: {
                fontSize: '1rem'
            },
            position: 'relative',
            '&::after': {
                content: '""',
                position: 'absolute',
                bottom: '-3px',
                left: 0,
                width: 0,
                height: '2px',
                backgroundColor: colors.primaryColor,
                transition: `width ${styles.transitionEffect}`
            },
            '&:hover::after': {
                width: '100%'
            }
        },
        '& img': {
            width: '2rem',
            height: '2rem',
        }
    },
    large: {
        '& a': {
            fontSize: '1.375rem'
        }
    }
}));

const ContactBox = ({ className, size, ...rest }) => {
    const classes = useStyles();
    const { appContext } = useAppContext();
    const sizeClass = size === 'large' ? classes.large : undefined;

    return (
        <Box
            {...rest}
            className={formatClassNames([classes.contactBox, sizeClass, className])}
        >
            <IconPrefix icon={appContext.images.iconTelephone} className={classes.supportCta}><a href={`tel:${appContext.company.telephone}`}>{appContext.company.telephone}</a></IconPrefix>
            <IconPrefix icon={appContext.images.iconEmail} className={classes.supportCta}><a href={`mailto:${appContext.company.email}`}>{appContext.company.email}</a></IconPrefix>
        </Box>
    );
};

ContactBox.propTypes = {
    className: PropTypes.string,
    size: PropTypes.string
};

export default ContactBox;
