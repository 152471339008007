import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from 'styles/util';

import { Divider } from '@material-ui/core';

const WithDividerWrapper = (ComponentToDivide) => {
    const wrapped = ({show = true, showDivider = true, bottom = false, orientation, flexItem, ...restProps}) => {
        const useStyles = makeStyles(({ theme, colors }) => ({
            divider: {
                margin: orientation === 'vertical' ? theme.spacing(0, 3) : theme.spacing(3, 0),
                backgroundColor: colors.mediumLightGrey
            },
        }));
        const classes = useStyles();

        if(!show) {
            return null;
        }

        return (
            <>
                {!bottom && showDivider && <Divider flexItem={flexItem} orientation={orientation} classes={{root: classes.divider}} /> }
                <ComponentToDivide {...restProps}/>
                {bottom && showDivider && <Divider orientation={orientation} classes={{root: classes.divider}} /> }
            </>

        );
    };

    return wrapped;
};

WithDividerWrapper.propTypes = {
    children: PropTypes.node.isRequired
};

export default WithDividerWrapper;
