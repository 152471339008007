import React from 'react';
import { hot } from 'react-hot-loader/root';
import configureStore from 'store/configureStore';

import { HelmetProvider } from 'react-helmet-async';
import { Provider } from 'react-redux';
import AppContextProvider from 'context/AppContextProvider';
import AppContextRoot from './AppContextRoot';

const Root = () => {
    const store = configureStore({});
    return (
        <Provider store={store}>
            <HelmetProvider>
                <AppContextProvider>
                    <AppContextRoot/>
                </AppContextProvider>
            </HelmetProvider>
        </Provider>
    );
};

export default hot(Root);
