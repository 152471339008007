import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router';
import { useDispatch } from 'react-redux';
import { handleResponse } from 'actions/actionHelpers';
import { fetchPurchase } from 'actions/purchases';
import strings from 'localization/strings';
import { makeStyles } from 'styles/util';
import purchaseStatuses from 'enums/purchaseStatuses';
import { getBusinessOrPrivateName } from 'helpers/ActorHelper';
import purchaseTypes from 'enums/purchaseTypes';

import PageTitle from 'common/PageTitle';
import Container from 'common/Container';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import Loader from 'common/Loader';
import Header from 'components/paymentDocument/Header';
import SubscriptionReceiptBody from 'components/paymentDocument/SubscriptionReceiptBody';
import ReceiptBody from 'components/paymentDocument/ReceiptBody';
import TenantFooter from 'components/paymentDocument/TenantFooter';
import TenantBookingSpecificInformation from 'components/paymentDocument/TenantBookingSpecificInformation';

const useStyles = makeStyles(({ colors }) => ({
    container: {
        fontSize: '12px',
        '& *': {
            color: colors.black
        },
        '& hr': {
            color: colors.primaryColor,
            backgroundColor: colors.primaryColor,
            border: `1px solid ${colors.primaryColor}`,
            margin: '0.75em 0'
        }
    },
    title: {
        fontSize: '16px'
    },
    innerContent: {
        margin: '40px 0'
    },
    description: {
        padding: '0.5em 0'
    },
    pageBreakAfter: {
        pageBreakAfter: 'always',
    }
}));

const TenantReceiptPage = () => {
    const classes = useStyles();
    const params = useParams();
    const dispatch = useDispatch();

    const [purchase, setPurchase] = useState(undefined);
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        dispatch(fetchPurchase(params.purchaseId, params.token))
            .then(handleResponse(
                response => {
                    setPurchase(response.payload);
                    setIsLoading(false);
                },
                () => {
                    setIsLoading(false);
                }
            ));
    }, []);

    if(isLoading) {
        return <Loader />;
    }

    const isRefund = purchase?.status === purchaseStatuses.refunded.key;
    const isInitialPurchase = purchase.type === purchaseTypes.periodBookingPurchase.key || purchaseTypes.subscriptionBookingInitialPurchase.key;

    return (
        <Container className={classes.container}>
            <PageTitle>{strings.receipt}</PageTitle>

            <Header text={(isRefund ? strings.paymentDocuments.refund : strings.receipt) + ' | ' + strings.tenant} />

            <Box className={classes.innerContent + ' ' + (purchase.booking.subscriptionBooking ? '' : classes.pageBreakAfter)}>
                <Typography variant="h2" className={classes.title}>{strings.formatString(strings.paymentDocuments.helloX, getBusinessOrPrivateName(purchase.booking.tenantActor))}</Typography>
                <hr />

                {
                    isRefund && <Typography variant="body1" className={classes.description}>{strings.paymentDocuments.refundTenant}</Typography>
                }

                {
                    isInitialPurchase &&
                    (
                        <>
                            {
                                !isRefund && <Typography variant="body1" className={classes.description}>{strings.paymentDocuments.congratulationsTenant}</Typography>
                            }
                            <ReceiptBody purchase={purchase} />
                        </>
                    )
                }
                { !isInitialPurchase && <SubscriptionReceiptBody purchase={purchase} /> }

                <hr />

                <TenantFooter purchase={purchase} />

                <TenantBookingSpecificInformation purchase={purchase} />
            </Box>
        </Container>
    );
};

export default TenantReceiptPage;
