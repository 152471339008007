import { parseIsoDate, formatDateTime, getDayOfWeekFormat, getShortDayOfWeekFormat } from 'helpers/DateHelper';
import { add } from 'date-fns';
import strings from 'localization/strings';

const zeroDate = parseIsoDate('2021-09-06');

const daysOfWeek = {
    monday: {},
    tuesday: {},
    wednesday: {},
    thursday: {},
    friday: {},
    saturday: {},
    sunday: {}
};

Object.keys(daysOfWeek).forEach(key => {
    daysOfWeek[key].key = key;
});

strings.addLoadCallback(appContext => {
    Object.values(daysOfWeek).forEach((value, i) => {
        const date = add(zeroDate, { days: i });
        value.index = i;
        value.title = formatDateTime(date, getDayOfWeekFormat, appContext);
        value.shortTitle = formatDateTime(date, getShortDayOfWeekFormat, appContext);
        value.pluralTitle = strings.formatString(strings.dayOfWeekPluralTemplate, formatDateTime(date, getDayOfWeekFormat, appContext));
    });
});


export default daysOfWeek;
