import React from 'react';
import PropTypes from 'prop-types';
import routes from 'routes';

import AdminFormWrapper from 'admin/presentational/AdminFormWrapper';
import AdminFormFields from 'admin/presentational/AdminFormFields';
import ReadOnlyField from 'admin/presentational/ReadOnlyField';
import AdminFormButtons from 'admin/presentational/AdminFormButtons';
import Form from 'form/Form';
import Button from '@material-ui/core/Button';
import { Checkboxes } from 'mui-rff';
import { Link } from 'react-router-dom';

const EditConversationForm = ({ conversation, isSubmitting = false, onSubmit, onCancel }) => {
    const initialValues = {
        completed: conversation.completed
    };

    return (
        <AdminFormWrapper isSubmitting={isSubmitting}>
            <Form
                initialValues={initialValues}
                onSubmit={onSubmit}
            >
                {({ handleSubmit }) => (
                    <form onSubmit={handleSubmit}>
                        <AdminFormFields>

                            <ReadOnlyField label="Storage site">
                                <Link to={routes.storageSiteDetails.replace(':storageSiteId', conversation.storageSiteId)} target="_blank">{conversation.storageSiteTitle}</Link>
                            </ReadOnlyField>

                            <Checkboxes
                                name="completed"
                                data={{
                                    label: 'Conversation has been completed',
                                    value: true
                                }}
                            />

                        </AdminFormFields>

                        <AdminFormButtons>
                            <Button
                                type="submit"
                                variant="contained"
                                color="primary"
                            >
                                Save
                            </Button>
                            <Button
                                type="button"
                                variant="outlined"
                                color="primary"
                                onClick={onCancel}
                            >
                                Cancel
                            </Button>
                        </AdminFormButtons>
                    </form>
                )}
            </Form>

        </AdminFormWrapper>
    );
};

EditConversationForm.propTypes = {
    conversation: PropTypes.object.isRequired,
    isSubmitting: PropTypes.bool,
    onSubmit: PropTypes.func.isRequired,
    onCancel: PropTypes.func.isRequired
};

export default EditConversationForm;
