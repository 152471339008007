import React from 'react';
import PropTypes from 'prop-types';
import { useField } from 'react-final-form';

import PayWithSavedCardCheckbox from './PayWithSavedCardCheckbox';
import StripePaymentField from '../stripe/StripePaymentField';
import MarginBox from 'common/MarginBox';

const StripePaymentElement = ({ name, paymentContext }) => {
    const getName = suffix => suffix ? `${name}.${suffix}` : name;
    const getValue = suffix => useField(getName(suffix)).input.value;
    const payWithSavedCard = getValue('payWithSavedCard');

    return (
        <>
            {
                paymentContext?.savedCard &&
                (
                    <MarginBox bottom={4}>
                        <PayWithSavedCardCheckbox
                            name={getName('payWithSavedCard')}
                            savedCard={paymentContext?.savedCard}
                        />
                    </MarginBox>
                )
            }

            <StripePaymentField
                visible={!(payWithSavedCard && paymentContext?.savedCard)}
            />
        </>
    );
};

StripePaymentElement.propTypes = {
    name: PropTypes.string.isRequired,
    paymentContext: PropTypes.object
};

export default StripePaymentElement;
