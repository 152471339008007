import { RSAA } from 'redux-api-middleware';
import { createRsaaTypes, createHeaders } from './actionHelpers';
import queryString from 'query-string';

export const USERS_INVITATIONS_CREATE = 'USERS_INVITATIONS_CREATE';

export const USERS_INVITATIONS_FETCH = 'USERS_INVITATIONS_FETCH';

export const USERS_INVITATIONS_ACCEPT = 'USERS_INVITATIONS_ACCEPT';

export const createUserInvitation = createUserInvitationRequest => ({
    [RSAA]: {
        types: createRsaaTypes(USERS_INVITATIONS_CREATE),
        endpoint: '/api/userInvitations',
        headers: createHeaders(true),
        body: JSON.stringify(createUserInvitationRequest),
        method: 'POST'
    }
});

export const fetchUserInvitation = (userInvitationId, token) => ({
    [RSAA]: {
        types: createRsaaTypes(USERS_INVITATIONS_FETCH),
        endpoint: `/api/userInvitations/${userInvitationId}?${queryString.stringify({ token })}`,
        headers: createHeaders(false),
        method: 'GET'
    }
});

export const acceptUserInvitation = (userInvitationId, acceptUserInvitationRequest) => ({
    [RSAA]: {
        types: createRsaaTypes(USERS_INVITATIONS_ACCEPT),
        endpoint: `/api/userInvitations/${userInvitationId}/accept`,
        headers: createHeaders(true),
        body: JSON.stringify(acceptUserInvitationRequest),
        method: 'PUT'
    }
});
