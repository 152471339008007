import * as actionTypes from 'actions/admin/searchStatistics';

const initialState = {
    searchResult: {
        isLoading: false
    },
    searchParameters: {
        sortByColumn: 'count',
        sortDirection: 'desc'
    }
};

const searchStatistics = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.ADMIN_SEARCH_STATISTICS_GET:
            return { ...state, searchResult: { isLoading: true } };
        case actionTypes.ADMIN_SEARCH_STATISTICS_GET_SUCCESS:
            return { ...state, searchResult: { items: action.payload } };
        case actionTypes.ADMIN_SEARCH_STATISTICS_GET_FAILURE:
            return { ...state, searchResult: { isLoading: false } };
        case actionTypes.ADMIN_SEARCH_STATISTICS_SET_SEARCH_PARAMETERS:
            return { ...state, searchParameters: action.searchParameters };
        default:
            return state;
    }
};

export default searchStatistics;
