import { RSAA } from 'redux-api-middleware';
import { createRsaaTypes, createHeaders } from './actionHelpers';

export const BILLECTA_CREATE_INVOICE = 'BILLECTA_CREATE_INVOICE';

export const createBillectaInvoice = createBillectaInvoiceRequest => ({
    [RSAA]: {
        types: createRsaaTypes(BILLECTA_CREATE_INVOICE),
        endpoint: '/api/billecta/invoices',
        method: 'POST',
        headers: createHeaders(true),
        body: JSON.stringify(createBillectaInvoiceRequest)
    }
});
