import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { useAppContext } from 'context/AppContext';
import { handleResponse } from 'actions/actionHelpers';
import { createExtendBookingPreview } from 'actions/tenantBookings';
import { parseOpeningTimeSpan, stripDateCandidates } from 'helpers/OpeningTimeSpanHelper';
import { parseDate, convertDateStringToIsoDateString } from 'helpers/DateHelper';

import Dialog from '@material-ui/core/Dialog';
import FullScreenLoader from 'common/FullScreenLoader';
import LoadingError from 'common/LoadingError';
import ExtendBookingStep1Form from './ExtendBookingStep1Form';
import ExtendBookingStep2Form from './ExtendBookingStep2Form';

const ExtendBookingDialog = ({ booking, open, onOk, onCancel, onError }) => {
    const dispatch = useDispatch();
    const { appContext } = useAppContext();

    const [endDate, setEndDate] = useState(undefined);
    const [step, setStep] = useState(1);
    const [isSaving, setIsSaving] = useState(false);
    const [extendBookingPreview, setExtendBookingPreview] = useState(undefined);
    const [isLoadingExtendBookingPreview, setIsLoadingExtendBookingPreview] = useState(false);
    const [loadingError, setLoadingError] = useState(undefined);

    useEffect(() => {
        if(open) {
            setStep(1);
        }
    }, [open]);

    const loadExtendBookingPreview = values => {
        setIsLoadingExtendBookingPreview(true);
        setExtendBookingPreview(undefined);
        dispatch(createExtendBookingPreview(booking.id, { endDate: convertDateStringToIsoDateString(values.requestedEndDate, appContext) }))
            .then(handleResponse(
                response => {
                    setExtendBookingPreview({
                        ...response.payload,
                        availableEndDateCandidates: stripDateCandidates(response.payload.availableEndDateCandidates.map(parseOpeningTimeSpan), parseDate(values.requestedEndDate, appContext))
                    });
                    setIsLoadingExtendBookingPreview(false);
                },
                () => {
                    setLoadingError(true);
                    setIsLoadingExtendBookingPreview(false);
                }
            ));
    };


    const handleStep1Ok = values => {
        setEndDate(values.endDate);
        setStep(2);
    };

    const handleStep2Ok = values => {
        setIsSaving(true);
        onOk({
            values: {
                ...values,
                endDate
            },
            onCompleted: () => setIsSaving(false)
        });
    };

    if(!open) {
        return null;
    }

    return (
        <Dialog open onClose={onCancel} disableEnforceFocus /* to be able to set focus to elements outside the dialog, e.g. when authorizing credit card in an iframe provided by Stripe */>
            <FullScreenLoader open={isSaving} />
            {
                loadingError && <LoadingError />
            }
            {
                !loadingError && step === 1 &&
                (
                    <ExtendBookingStep1Form
                        booking={booking}
                        extendBookingPreview={extendBookingPreview}
                        loadExtendBookingPreview={loadExtendBookingPreview}
                        isLoadingExtendBookingPreview={isLoadingExtendBookingPreview}
                        onOk={handleStep1Ok}
                        onCancel={onCancel}
                        onError={onError}
                    />
                )
            }
            {
                !loadingError && step === 2 && endDate &&
                (
                    <ExtendBookingStep2Form
                        existingBooking={booking}
                        newBookingForExtendedPeriod={extendBookingPreview.booking}
                        endDate={endDate}
                        isSaving={isSaving}
                        onOk={handleStep2Ok}
                        onCancel={onCancel}
                    />
                )
            }
        </Dialog>
    );
};

ExtendBookingDialog.propTypes = {
    booking: PropTypes.object.isRequired,
    open: PropTypes.bool.isRequired,
    onOk: PropTypes.func.isRequired,
    onCancel: PropTypes.func.isRequired,
    onError: PropTypes.func.isRequired
};

export default ExtendBookingDialog;
