import React, { useState } from 'react';
import { makeStyles } from 'styles/util';
import { useAppContext } from 'context/AppContext';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { logIn } from 'actions/authentication';
import { handleResponse } from 'actions/actionHelpers';
import { required } from 'form/validation';
import { createObjectFromAuthenticationQuerystringParameters, handleSuccessfulLogIn } from 'logic/authenticationLogic';

import PageTitle from 'common/PageTitle';
import Form from 'form/Form';
import MuiContainer from '@material-ui/core/Container';
import ContentBox from 'common/ContentBox';
import Box from '@material-ui/core/Box';
import { TextField, showErrorOnBlur } from 'mui-rff';
import Typography from '@material-ui/core/Typography';
import MenuItem from '@material-ui/core/MenuItem';
import CircularProgressButton from 'common/CircularProgressButton';

const useStyles = makeStyles(({ theme }) => ({
    pageWrapper: {
        display: 'flex',
        flexDirection: 'column',
        gap: theme.spacing(3.75),
        marginTop: theme.spacing(10),
        marginBottom: theme.spacing(3.75),
        [theme.breakpoints.down('sm')]: {
            marginTop: theme.spacing(3.75)
        }
    },
    form: {
        display: 'flex',
        flexDirection: 'column',
        gap: theme.spacing(3.75),
    }
}));

const MockLoginPage = () => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const history = useHistory();
    const redirectUrl = useSelector(state => state.authentication.redirectUrl);
    const [isLoading, setIsLoading] = useState(false);
    const { appContext } = useAppContext();
    const { takeoverTenant, userInvitation } = createObjectFromAuthenticationQuerystringParameters(location);

    const handleFormSubmit = values => {
        setIsLoading(true);
        dispatch(logIn({ mockUserId: values.userId }))
            .then(handleResponse(
                () => handleSuccessfulLogIn({
                    takeoverTenant,
                    userInvitation,
                    redirectUrl,
                    setIsLoading,
                    dispatch,
                    history
                }),
                () => {
                    setIsLoading(false);
                }
            ));
    };

    const users = appContext.mockUsers
        ? JSON.parse(appContext.mockUsers)
        : [];

    const initialValues = {
        userId: null
    };

    const title = 'Inloggning för testmiljö';

    return (
        <>
            <PageTitle>{title}</PageTitle>
            <MuiContainer maxWidth={false}>
                <ContentBox className={classes.pageWrapper} themeMaxWidth="xs">
                    <Typography variant="h2">
                        {title}
                    </Typography>

                    <Form
                        initialValues={initialValues}
                        onSubmit={handleFormSubmit}
                    >
                        {({ handleSubmit, invalid }) => (
                            <form onSubmit={handleSubmit}>
                                <Box>
                                    <TextField
                                        select
                                        name="userId"
                                        label="Användare att logga in som"
                                        variant="outlined"
                                        showError={showErrorOnBlur}
                                        disabled={isLoading}
                                        fieldProps={{ validate: required }}
                                    >
                                        {
                                            users.map(user => (
                                                <MenuItem key={user.id} value={user.id}>
                                                    {user.name}
                                                </MenuItem>
                                            ))
                                        }
                                    </TextField>
                                </Box>

                                <CircularProgressButton
                                    type="submit"
                                    className={classes.submitButton}
                                    disabled={invalid}
                                    isLoading={isLoading}
                                >
                                    Logga in
                                </CircularProgressButton>
                            </form>
                        )}
                    </Form>
                </ContentBox>
            </MuiContainer>
        </>
    );
};

export default MockLoginPage;
