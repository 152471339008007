import React from 'react';
import strings from 'localization/strings';
import { makeStyles } from 'styles/util';
import routes from 'routes';

import PageTitle from 'common/PageTitle';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import Container from 'common/Container';
import topLogo from 'assets/images/find_storage_top_image.jpg';
import Text from 'common/Text';

const useStyles = makeStyles(({ theme }) => ({
    findStorageTopLogo: {
        display: 'block',
        width: '354px',
        height: '236px',
        margin: theme.spacing(4, 0)
    }
}));

const FindStoragePage = () => {
    const classes = useStyles();

    return (
        <Container withPageMargins>
            <PageTitle>{strings.headerTitleFindStorage}</PageTitle>
            <Typography variant="h1">{strings.pages.findStorage.title}</Typography>
            <img className={classes.findStorageTopLogo} src={topLogo}/>
            <Button
                variant="contained"
                color="primary"
                href={routes.index}
            >
                {strings.pages.findStorage.searchStorage}
            </Button>
            <Text component="div" html={strings.pages.findStorage.body} />
        </Container>
    );
};

export default FindStoragePage;
