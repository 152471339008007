const takeoverTenantStatuses = {
    unsaved: {},
    draft: {},
    bookingCreated: {},
    userConnectionLinkClicked: {},
    userConnectionCompleted: {},
    canceled: {}
};

Object.keys(takeoverTenantStatuses).forEach(key => {
    takeoverTenantStatuses[key].key = key;
});

export default takeoverTenantStatuses;
