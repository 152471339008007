import strings from 'localization/strings';

const openingModes = {
    open: {},
    closed: {}
};

Object.keys(openingModes).forEach(key => {
    openingModes[key].key = key;
});

strings.addLoadCallback(() => {
    Object.values(openingModes).forEach(o => {
        o.title = strings.openingModes[o.key];
    });
});

export default openingModes;
