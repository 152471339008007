import React from 'react';
import PropTypes from 'prop-types';
import { useAppContext } from 'context/AppContext';
import strings from 'localization/strings';
import { formatDateTime } from 'helpers/DateHelper';
import { makeStyles } from 'styles/util';

import { DatePicker } from 'mui-rff';

const useStyles = makeStyles(({ theme, colors }) => ({
    nullableDatePicker: {
        // zero padding to enable header background
        '& .MuiPickersModal-dialogRoot': {
            padding: 0
        },
        // margin for button container at the bottom of the picker dialog
        '& .MuiDialogActions-root': {
            margin: theme.spacing(0, 1, 1, 1)
        },
        // hack to make the clear button stand out
        '& .MuiPickersModal-withAdditionalAction button:first-child': {
            color: colors.white,
            backgroundColor: colors.primaryColor,
            '&:hover': {
                backgroundColor: colors.primaryHoverColor
            }
        }
    }
}));

const NullableDatePicker = ({ name, label, formatFunc, size, inputVariant, helperText, nullString, className, showError, fieldProps = {}, nullButtonLabel = undefined, disabled, ...rest }) => {
    const classes = useStyles();
    const { appContext } = useAppContext();

    const additionalFieldProps = {
        clearable: true,
        okLabel: strings.ok,
        cancelLabel: strings.cancel,
        clearLabel: nullButtonLabel || nullString,
        labelFunc: date => date
            ? formatDateTime(date, formatFunc, appContext)
            : nullString
    };
    return (
        <DatePicker
            name={name}
            label={label}
            format={formatFunc(appContext)}
            size={size}
            inputVariant={inputVariant}
            helperText={helperText}
            className={className}
            showError={showError}
            fieldProps={{ ...fieldProps, ...additionalFieldProps }}
            DialogProps={{ className: classes.nullableDatePicker}}
            disabled={disabled}
            {...rest}
        />
    );
};

NullableDatePicker.propTypes = {
    name: PropTypes.string.isRequired,
    label: PropTypes.string,
    formatFunc: PropTypes.func.isRequired,
    size: PropTypes.string,
    inputVariant: PropTypes.string,
    helperText: PropTypes.string,
    nullString: PropTypes.string.isRequired,
    className: PropTypes.string,
    showError: PropTypes.func,
    fieldProps: PropTypes.object,
    nullButtonLabel: PropTypes.string,
    disabled: PropTypes.bool
};

export default NullableDatePicker;
