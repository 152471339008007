import * as actionTypes from 'actions/admin/purchases';

const initialState = {
    searchResult: {
        isLoading: false
    },
    searchParameters: {}
};

const purchases = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.ADMIN_PURCHASES_SEARCH:
            return { ...state, searchResult: { isLoading: true } };
        case actionTypes.ADMIN_PURCHASES_SEARCH_SUCCESS:
            return { ...state, searchResult: action.payload };
        case actionTypes.ADMIN_PURCHASES_SEARCH_FAILURE:
            return { ...state, searchResult: { isLoading: false } };
        case actionTypes.ADMIN_PURCHASES_SET_SEARCH_PARAMETERS:
            return { ...state, searchParameters: action.searchParameters };
        default:
            return state;
    }
};

export default purchases;
