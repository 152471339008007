import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useAppContext } from 'context/AppContext';
import { makeStyles } from 'styles/util';
import strings from 'localization/strings';
import { required } from 'form/validation';
import useMediaQuery from '@material-ui/core/useMediaQuery';

import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import Form from 'form/Form';
import { TextField, Checkboxes, showErrorOnBlur } from 'mui-rff';
import ButtonContainer from 'common/ButtonContainer';
import Text from 'common/Text';

const useStyles = makeStyles(({ theme }) => ({
    paragraph: {
        marginBottom: theme.spacing(2)
    }
}));

const BankAccountEditor = ({ stripeAccountInfo, onSubmit }) => {
    const isMobile = !useMediaQuery(theme => theme.breakpoints.up('md'));
    const classes = useStyles();
    const { appContext } = useAppContext();
    const [isEditing, setIsEditing] = useState(false);

    const initialValues = {
        iban: '',
        termsOfServiceAcceptance: false
    };

    const handleFormSubmit = values => {
        setIsEditing(false);
        onSubmit(values);
    };

    const showEditor = isEditing;

    return (
        <>
            <Typography variant="h5" className={classes.paragraph}>
                {strings.bankAccountDetails}
            </Typography>
            {
                !showEditor &&
                (
                    <>
                        {
                            stripeAccountInfo &&
                            (
                                <Typography variant="body1" gutterBottom>
                                    {strings.bank}: {stripeAccountInfo.externalAccount.bankName}
                                    <br/>
                                    {strings.iban}: **** **** **** **** **** {stripeAccountInfo.externalAccount.ibanLast4}
                                </Typography>
                            )
                        }
                        {
                            !stripeAccountInfo &&
                            (
                                <Typography variant="body1" gutterBottom>
                                    {strings.wantToBeOwnerDescription}
                                </Typography>
                            )
                        }

                        <ButtonContainer>
                            <Button
                                type="button"
                                color="primary"
                                variant="contained"
                                fullWidth={isMobile}
                                onClick={() => setIsEditing(true)}
                            >
                                {stripeAccountInfo ? strings.updateDetails : strings.addBankAccountNumber}
                            </Button>
                        </ButtonContainer>
                    </>
                )
            }
            {
                showEditor &&
                (
                    <Form
                        initialValues={initialValues}
                        onSubmit={handleFormSubmit}
                    >
                        {({ handleSubmit, submitting, invalid, values }) => (
                            <form onSubmit={handleSubmit}>
                                <TextField
                                    name="iban"
                                    label={strings.iban}
                                    helperText={appContext.payment.stripe.testIban ? `Use ${appContext.payment.stripe.testIban} for testing.` : strings.ibanHelperText}
                                    variant="outlined"
                                    showError={showErrorOnBlur}
                                    required={!stripeAccountInfo}
                                    fieldProps={{ validate: stripeAccountInfo ? undefined : required }}
                                />
                                {
                                    !stripeAccountInfo &&
                                    (
                                        <Checkboxes
                                            name="termsOfServiceAcceptance"
                                            data={{
                                                label: (
                                                    <span>
                                                        {strings.acceptStripePolicies} (<Text inline className={classes.longText} html={strings.stripeServicesAgreementLink} />)
                                                    </span>
                                                ),
                                                value: true
                                            }}
                                        />
                                    )
                                }

                                <ButtonContainer>
                                    <Button
                                        type="submit"
                                        color="primary"
                                        variant="contained"
                                        disabled={submitting || invalid || (!stripeAccountInfo && !values.termsOfServiceAcceptance)}
                                        fullWidth={isMobile}
                                    >
                                        {strings.save}
                                    </Button>
                                    <Button
                                        type="button"
                                        color="secondary"
                                        variant="contained"
                                        fullWidth={isMobile}
                                        onClick={() => setIsEditing(false)}
                                    >
                                        {strings.cancel}
                                    </Button>
                                </ButtonContainer>
                            </form>
                        )}
                    </Form>
                )
            }
        </>
    );
};

BankAccountEditor.propTypes = {
    stripeAccountInfo: PropTypes.object,
    onSubmit: PropTypes.func.isRequired
};

export default BankAccountEditor;
