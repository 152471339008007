import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router';
import { makeStyles } from 'styles/util';
import { fetchStorageSiteLockCodeRequests } from 'actions/storageSites';
import { useDispatch } from 'react-redux';
import strings from 'localization/strings';
import { formatUtcIsoDateTime } from 'helpers/DateHelper';
import { handleResponse } from 'actions/actionHelpers';
import { subDays, endOfToday, startOfToday } from 'date-fns';
import routes from 'routes';
import { getShortDateFormat } from 'helpers/DateHelper';
import { required } from 'form/validation';

import Button from '@material-ui/core/Button';
import ButtonContainer from 'common/ButtonContainer';
import PageTitle from 'common/PageTitle';
import Typography from '@material-ui/core/Typography';
import ShadowBox from 'common/ShadowBox';
import ShortDateTime from 'common/ShortDateTime';
import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import TableBody from '@material-ui/core/TableBody';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import { Link } from 'react-router-dom';
import Form from 'form/Form';
import LoadingWrapper from 'form/LoadingWrapper';
import DatePicker from 'form/DatePicker';
import { showErrorOnBlur } from 'mui-rff';

const useStyles = makeStyles(() => ({
    nowrap: {
        whiteSpace: 'nowrap'
    }
}));

const StorageSiteLockCodeRequests = () => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const params = useParams();

    const [isLoading, setIsLoading] = useState(true);
    const [lockCodeRequests, setLockCodeRequests] = useState(undefined);
    const storageSiteId = params.storageSiteId;

    const initialValues = {
        from: subDays(startOfToday(), 7),
        to: endOfToday()
    };

    useEffect(() => {
        loadData(initialValues.from, initialValues.to);
    }, [storageSiteId]);

    const handleFormSubmit = formValues => loadData(formValues.from, formValues.to);

    const loadData = (from, to) => {
        setLockCodeRequests(undefined);
        setIsLoading(true);
        dispatch(fetchStorageSiteLockCodeRequests(storageSiteId, formatUtcIsoDateTime(from), formatUtcIsoDateTime(to)))
            .then(handleResponse(
                response => {
                    setLockCodeRequests(response.payload);
                    setIsLoading(false);
                },
                () => setIsLoading(false)
            ));
    };

    const title = lockCodeRequests
        ? `${strings.lockCodeRequests}: ${lockCodeRequests.storageSiteTitle}`
        : strings.lockCodeRequests;

    const createShowOwnerBookingDetailsUrl = (bookingId) => routes.account.ownerBookingDetails.replace(':bookingId', bookingId);

    return(
        <>
            <PageTitle>{title}</PageTitle>
            <ShadowBox title={title}>
                <Form
                    initialValues={initialValues}
                    onSubmit={handleFormSubmit}
                >
                    {({ handleSubmit, submitting, invalid }) => (
                        <LoadingWrapper isLoading={isLoading}>
                            <form onSubmit={handleSubmit}>
                                <DatePicker
                                    name="from"
                                    label={strings.startDate}
                                    formatFunc={getShortDateFormat}
                                    inputVariant="outlined"
                                    showError={showErrorOnBlur}
                                    fieldProps={{ validate: required }}
                                />

                                <DatePicker
                                    name="to"
                                    label={strings.endDate}
                                    formatFunc={getShortDateFormat}
                                    inputVariant="outlined"
                                    showError={showErrorOnBlur}
                                    fieldProps={{ validate: required }}
                                />

                                <ButtonContainer>
                                    <Button
                                        type="submit"
                                        color="primary"
                                        variant="contained"
                                        disabled={submitting || invalid}
                                    >
                                        {strings.ok}
                                    </Button>
                                </ButtonContainer>
                            </form>

                            {
                                lockCodeRequests && lockCodeRequests.items.length === 0 &&
                                (
                                    <Typography variant="body1">
                                        {strings.noLockCodesRequestedInTimePeriod}
                                    </Typography>
                                )
                            }
                            {
                                lockCodeRequests && lockCodeRequests.items.length > 0 &&
                                (
                                    <Table size="small">
                                        <TableHead>
                                            <TableRow>
                                                <TableCell>{strings.tenant}</TableCell>
                                                <TableCell>{strings.showTime}</TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {
                                                lockCodeRequests.items.map((lockCode, i) => (
                                                    <TableRow key={i}>
                                                        <TableCell className={classes.nowrap}>
                                                            <Link to={createShowOwnerBookingDetailsUrl(lockCode.bookingId)}>{lockCode.tenantName}</Link>
                                                        </TableCell>
                                                        <TableCell className={classes.nowrap}>
                                                            <ShortDateTime value={lockCode.time} />
                                                        </TableCell>
                                                    </TableRow>
                                                ))
                                            }
                                        </TableBody>
                                    </Table>
                                )
                            }
                            {
                                lockCodeRequests &&
                                (
                                    <ButtonContainer>
                                        <Button
                                            variant="contained"
                                            color="primary"
                                            component={Link}
                                            to={routes.account.editStorageSite.replace(':storageSiteId', storageSiteId)}
                                        >
                                            {strings.showStorageSite}
                                        </Button>
                                    </ButtonContainer>
                                )
                            }
                        </LoadingWrapper>
                    )}
                </Form>
            </ShadowBox>
        </>
    );
};

export default StorageSiteLockCodeRequests;
