import React from 'react';
import PropTypes from 'prop-types';
import { useAppContext } from 'context/AppContext';
import storageGroupCategories from 'enums/storageGroupCategories';
import { makeStyles } from 'styles/util';
import { useSelector } from 'react-redux';
import { useField } from 'react-final-form';
import { required, mustBeGreaterThan, mustBeNullOrGreaterThan, mustBeGreaterThanIf } from 'form/validation';
import { getLongDateFormat, formatIsoDate } from 'helpers/DateHelper';
import { calculateArea } from 'helpers/StorageAreaCalculator';
import { formatArea } from 'helpers/StringHelper';
import { createEndAdornment } from 'helpers/FormHelper';
import strings from 'localization/strings';
import { showOptionToTax } from 'logic/editStorageSiteLogic';

import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import { TextField, Checkboxes, showErrorOnBlur } from 'mui-rff';
import DatePicker from 'form/DatePicker';
import NullableDatePicker from 'form/NullableDatePicker';
import LockConfigurationEditor from './LockConfigurationEditor';
import Text from 'common/Text';

const useStyles = makeStyles(() => ({
    hidden: {
        display: 'none'
    },
    heading: {
        paddingBottom: '0 !important'
    }
}));

const EditStep1Single = ({ showStepNumber = true }) => {
    const classes = useStyles();

    const fieldNamePrefix = 'steps[1].single';

    const authenticationContext = useSelector(state => state.authentication.context);
    const { selectedActor } = authenticationContext;
    const { appContext } = useAppContext();

    const getName = suffix => suffix ? `${fieldNamePrefix}.${suffix}` : fieldNamePrefix;
    const getValue = suffix => useField(getName(suffix)).input.value;

    const startDate = getValue('startDate');
    const endDate = getValue('endDate');
    const storageLength = getValue('storageLength');
    const storageWidth = getValue('storageWidth');
    const parakeyDomainId = useField('steps[6].parakeyDomainId').input.value;

    const areaCalculation = calculateArea(storageLength, storageWidth);
    const lockConfigurationVisible = authenticationContext.isSuperAdminOrImpersonated;
    const categories = getValue('categories');
    const optionToTaxVisible = showOptionToTax(selectedActor, categories);

    const atLeastOneCategoryValidator = value => {
        if(!value || value.length > 0) {
            return undefined;
        }
        return strings.atLeastOneCategoryMustBeGiven;
    };

    const storageIsRectangular = getValue('storageIsRectangular');

    const visibleForOnlyAreaMode = !storageIsRectangular
        ? undefined
        : classes.hidden;

    const visibleForLengthAndWidthMode = storageIsRectangular
        ? undefined
        : classes.hidden;

    return (
        <Box>
            <Typography variant="h5" display="block" gutterBottom>
                { showStepNumber && '2. ' }
                {strings.categoryAndSizeTitle}
            </Typography>

            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <Checkboxes
                        name={getName('storageIsRectangular')}
                        data={
                            { label: strings.storageIsRectangular, value: true }
                        }
                    />
                </Grid>

                <Grid item xs={6} md={3} className={visibleForLengthAndWidthMode}>
                    <TextField
                        name={getName('storageLength')}
                        label={strings.length}
                        variant="outlined"
                        helperText={strings.lengthHelperText}
                        required={storageIsRectangular}
                        fieldProps={{ validate: mustBeGreaterThanIf(storageIsRectangular, 0) }}
                        InputProps={createEndAdornment(strings.meterAbbreviated)}
                        showError={showErrorOnBlur}
                    />
                </Grid>
                <Grid item xs={6} md={3} className={visibleForLengthAndWidthMode}>
                    <TextField
                        name={getName('storageWidth')}
                        label={strings.width}
                        variant="outlined"
                        helperText={strings.widthHelperText}
                        required={storageIsRectangular}
                        fieldProps={{ validate: mustBeGreaterThanIf(storageIsRectangular, 0) }}
                        InputProps={createEndAdornment(strings.meterAbbreviated)}
                        showError={showErrorOnBlur}
                    />
                </Grid>
                <Grid item xs={6} md={3} className={visibleForLengthAndWidthMode}>
                    <TextField
                        name={getName('calculatedStorageArea')}
                        value={areaCalculation.adjustedArea ? formatArea(areaCalculation.adjustedArea, appContext, { maxNumberOfDecimals: 1 }) : ''}
                        label={strings.area}
                        variant="outlined"
                        helperText={strings.areaHelperText}
                        disabled
                    />
                </Grid>

                <Grid item xs={6} md={3} className={visibleForOnlyAreaMode}>
                    <TextField
                        name={getName('storageArea')}
                        label={strings.area}
                        variant="outlined"
                        showError={showErrorOnBlur}
                        helperText={strings.areaHelperText}
                        required={!storageIsRectangular}
                        fieldProps={{ validate: mustBeGreaterThanIf(!storageIsRectangular, 0) }}
                    />
                </Grid>

                <Grid item xs={6} md={3}>
                    <TextField
                        name={getName('ceilingHeight')}
                        label={strings.ceilingHeight}
                        variant="outlined"
                        showError={showErrorOnBlur}
                        helperText={strings.ceilingHeightHelperText}
                        fieldProps={{ validate: mustBeNullOrGreaterThan(0) }}
                        InputProps={createEndAdornment(strings.meterAbbreviated)}
                    />
                </Grid>
            </Grid>

            <Grid container spacing={2}>
                <Grid item xs={6} md={3}>
                    <TextField
                        name={getName('price')}
                        label={strings.pricePerMonth}
                        variant="outlined"
                        helperText={strings.enterAmountExcludingVat}
                        required
                        fieldProps={{ validate: mustBeGreaterThan(0) }}
                        InputProps={createEndAdornment(appContext.currency.label)}
                        showError={showErrorOnBlur}
                    />
                </Grid>
            </Grid>

            <Grid container spacing={2}>
                <Grid item xs={12} className={classes.heading}>
                    <Typography variant="h6" display="block" gutterBottom>
                        {strings.availibility}
                    </Typography>
                </Grid>

                <Grid item xs={6} md={3}>
                    <DatePicker
                        name={getName('startDate')}
                        label={strings.availableFrom}
                        formatFunc={getLongDateFormat}
                        inputVariant="outlined"
                        required
                        fieldProps={{ maxDate: formatIsoDate(endDate), validate: required}}
                        showError={showErrorOnBlur}
                    />
                </Grid>

                <Grid item xs={6} md={3}>
                    <NullableDatePicker
                        name={getName('endDate')}
                        label={strings.availableTo}
                        formatFunc={getLongDateFormat}
                        inputVariant="outlined"
                        nullString={strings.untilFurther}
                        fieldProps={{ minDate: formatIsoDate(startDate) }}
                        showError={showErrorOnBlur}
                    />
                </Grid>

                {
                    appContext.storageGroupCategories.length > 1 &&
                    (
                        <Grid item xs={12}>
                            <Checkboxes
                                name={getName('categories')}
                                label={strings.areaOfUsage}
                                data={[
                                    { label: storageGroupCategories.storeHouse.singleTitle, value: storageGroupCategories.storeHouse.key },
                                    { label: storageGroupCategories.vehicle.singleTitle, value: storageGroupCategories.vehicle.key }
                                ]}
                                fieldProps={{ validate: atLeastOneCategoryValidator }}
                                showError={showErrorOnBlur}
                            />
                        </Grid>
                    )
                }
                {
                    optionToTaxVisible &&
                    (
                        <Grid item xs={12}>
                            <Checkboxes
                                name={getName('optionToTax')}
                                label={strings.optionToTax}
                                data={
                                    { label: strings.optionToTaxDescription, value: true }
                                }
                                helperText={<Text html={strings.optionToTaxHelperText}/>}
                            />
                        </Grid>
                    )
                }

                {
                    lockConfigurationVisible &&
                    (
                        <Grid item xs={12}>
                            <Typography variant="h6" display="block" gutterBottom>
                                {strings.lockConfigurations}
                            </Typography>
                            <LockConfigurationEditor
                                name={getName('lockConfigurations')}
                                parakeyDomainId={parakeyDomainId}
                            />
                        </Grid>
                    )
                }
            </Grid>
        </Box>
    );
};

EditStep1Single.propTypes = {
    showStepNumber: PropTypes.bool
};

export default EditStep1Single;
