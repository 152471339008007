import * as actionTypes from 'actions/admin/takeovers';
import takeoverStatuses from 'enums/takeoverStatuses';

const initialState = {
    searchResult: {
        isLoading: false
    },
    searchParameters: {
        statuses: [takeoverStatuses.draft.key, takeoverStatuses.active.key]
    }
};

const takeovers = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.ADMIN_TAKEOVERS_SEARCH_SUCCESS:
            return { ...state, searchResult: action.payload };
        case actionTypes.ADMIN_TAKEOVERS_SET_SEARCH_PARAMETERS:
            return { ...state, searchParameters: action.searchParameters };
        default:
            return state;
    }
};

export default takeovers;
