import { RSAA } from 'redux-api-middleware';
import { createRsaaTypes, createHeaders } from './actionHelpers';
import queryString from 'query-string';

export const NOTIFICATIONS_FETCH = 'NOTIFICATIONS_FETCH';
export const NOTIFICATIONS_FETCH_SUCCESS = 'NOTIFICATIONS_FETCH_SUCCESS';
export const NOTIFICATIONS_FETCH_FAILURE = 'NOTIFICATIONS_FETCH_FAILURE';

export const NOTIFICATIONS_SET_READ = 'NOTIFICATIONS_SET_READ';
export const NOTIFICATIONS_SET_READ_SUCCESS = 'NOTIFICATIONS_SET_READ_SUCCESS';
export const NOTIFICATIONS_SET_READ_FAILURE = 'NOTIFICATIONS_SET_READ_FAILURE';

export const fetchNotifications = userId => ({
    [RSAA]: {
        types: createRsaaTypes(NOTIFICATIONS_FETCH),
        endpoint: `/api/actors/${userId}/notifications`,
        method: 'GET',
        headers: createHeaders(false)
    }
});

export const setNotificationsRead = (actorId, params) => ({
    [RSAA]: {
        types: createRsaaTypes(NOTIFICATIONS_SET_READ),
        endpoint: `/api/actors/${actorId}/notifications?${queryString.stringify(params)}`,
        method: 'PUT',
        headers: createHeaders(false)
    }
});
