import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { withAITracking } from '@microsoft/applicationinsights-react-js';
import { ai } from 'integration/application-insights/applicationInsightsHelper';
import PropTypes from 'prop-types';

/**
 * This component provides telemetry with Azure App Insights.
 *
 * NOTE: the package '@microsoft/applicationinsights-react-js' has a HOC withAITracking that requires this to be a class component rather than a functional component.
 * @microsoft/applicationinsights-web now has support for hooks, see https://github.com/microsoft/ApplicationInsights-JS/pull/1120, but too lazy to implement.
 */
class TelemetryProviderInner extends Component {
    state = {
        initialized: false
    };

    componentDidMount() {
        const { history, connectionString, after } = this.props;
        const { initialized } = this.state;
        if (!initialized) {
            ai.initialize(connectionString, history);
        }

        if(after) {
            after();
        }
    }

    render() {
        return (
            <>
                {this.props.children}
            </>
        );
    }
}

TelemetryProviderInner.propTypes = {
    history: PropTypes.object.isRequired,
    connectionString: PropTypes.string.isRequired,
    after: PropTypes.func,
    children: PropTypes.oneOfType([PropTypes.object, PropTypes.array])
};

export default withRouter(withAITracking(ai.getReactPlugin(), TelemetryProviderInner));
