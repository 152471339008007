import React from 'react';
import PropTypes from 'prop-types';
import strings from 'localization/strings';

import LinkButton from 'common/LinkButton';

const VehicleInfoLink = ({ onClick }) => {
    return (
        <LinkButton onClick={onClick}>
            {strings.getVehicleMeasurements}
        </LinkButton>
    );
};

VehicleInfoLink.propTypes = {
    onClick: PropTypes.func.isRequired
};

export default VehicleInfoLink;
