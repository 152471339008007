import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { makeStyles } from 'styles/util';
import { fetchStorageSites } from 'actions/account/storageSitesSection';
import strings from 'localization/strings';
import routes from 'routes';

import PageTitle from 'common/PageTitle';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import Loader from 'common/Loader';
import OwnerStorageSite from './OwnerStorageSite';
import LoadingError from 'common/LoadingError';

const StorageSitesSection = () => {
    const useStyles = makeStyles(({ theme }) => ({
        overrideSpacing: {
            margin: theme.spacing(-1.875),
            [theme.breakpoints.down('sm')]: {
                margin: theme.spacing(-1.25)
            },
            '& > .MuiGrid-item': {
                padding: theme.spacing(1.875),
                [theme.breakpoints.down('sm')]: {
                    padding: theme.spacing(1.25)
                }
            }
        }
    }));

    const classes = useStyles();
    const dispatch = useDispatch();

    const storageSites = useSelector(state => state.account.storageSitesSection.storageSites);
    const isLoading = useSelector(state => state.account.storageSitesSection.isLoading);
    const loadingError = useSelector(state => state.account.storageSitesSection.loadingError);
    const authenticationContext = useSelector(state => state.authentication.context);
    const { selectedActor } = authenticationContext;

    useEffect(() => {
        if(!storageSites) {
            dispatch(fetchStorageSites(selectedActor.id));
        }
    }, [storageSites]);

    const title = strings.accountTabs.storageSites;

    return (
        <>
            <PageTitle>{title}</PageTitle>
            <Typography variant="h2">{title}</Typography>
            {
                loadingError && <LoadingError />
            }
            {
                isLoading && <Loader />
            }
            {
                !isLoading &&
                <Box>
                    <Button component={Link} to={routes.account.createStorageSiteIndex} variant="contained" color="primary">{strings.createStorageSite}</Button>
                </Box>
            }
            {
                storageSites && (
                    storageSites.length === 0 ? (
                        <Typography variant="body1">{strings.noStorageSitesCreatedYet}</Typography>
                    ) : (
                    <Grid container spacing={4} justifyContent="center" className={classes.overrideSpacing}>
                        {storageSites.map(storageSite =>
                            (
                                <Grid key={storageSite.id} item xs={12} sm={6}>
                                    <OwnerStorageSite storageSite={storageSite} />
                                </Grid>
                            )
                        )}
                    </Grid>
                    )
                )
            }
        </>
    );
};

export default StorageSitesSection;
