import React from 'react';
import PropTypes from 'prop-types';
import { useAppContext } from 'context/AppContext';
import strings from 'localization/strings';

import MarginBox from 'common/MarginBox';
import Caption from './Caption';
import InformationItems from './InformationItems';
import QrCodeContainer from './QrCodeContainer';

const InvoiceInformation = ({ purchase }) => {
    const { appContext } = useAppContext();

    const booking = purchase.booking;

    const contactUs = strings.formatString(strings.invoiceInformation.contactUs, <a href={`mailto:${appContext.company.email}`}>{appContext.company.email}</a>, appContext.company.telephone);

    return (
        <>
            <MarginBox top={3} bottom={3}>
                <Caption text={strings.invoiceInformation.manageBookingCaption}/>
                <MarginBox bottom={2}>
                    {strings.invoiceInformation.manageBooking}
                </MarginBox>
                <MarginBox bottom={2}>
                    {strings.invoiceInformation.takeoverFirstInvoiceFeeInformation}
                </MarginBox>

                <MarginBox>
                    <QrCodeContainer
                        qrCode={booking.tenantQrCode}
                        text={strings.invoiceInformation.manageBookingCaption}
                        url={booking.tenantUrl}
                    />
                </MarginBox>
            </MarginBox>

            <MarginBox bottom={3}>
                <Caption text={strings.contactUsCaption}/>
                <MarginBox>
                    {contactUs}
                </MarginBox>
            </MarginBox>

            <InformationItems caption={strings.yourBooking} purchase={purchase} />
        </>
    );
};

InvoiceInformation.propTypes = {
    purchase: PropTypes.object.isRequired
};

export default InvoiceInformation;
