import strings from 'localization/strings';

const tenantAvailabilities = {
    always: {},
    timeAppointed: {},
    customDescription: {}
};

Object.keys(tenantAvailabilities).forEach(key => {
    tenantAvailabilities[key].key = key;
});

strings.addLoadCallback(() => {
    Object.values(tenantAvailabilities).forEach(o => {
        o.title = strings.tenantAvailabilities[o.key];
    });
});

export default tenantAvailabilities;
