import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { makeStyles } from 'styles/util';

import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Box from '@material-ui/core/Box';

const AccountNavigationLinks = ({ links, handleLinkClick }) => {
    const useStyles = makeStyles(({ theme, colors, styles }) => ({
        accountMenuItem: {
            cursor: 'pointer',
            paddingTop: 0,
            paddingBottom: 0,
            position: 'relative',
            '&:not(:last-child)': {
                marginBottom: theme.spacing(0.875)
            },
            '& a, a:visited': {
                textDecoration: 'none',
                color: colors.textColor
            },
            '& span': {
                display: 'inline-block',
                position: 'relative'
            },
            '& span::after': {
                content: '""',
                position: 'absolute',
                bottom: '-3px',
                left: 0,
                width: 0,
                height: '2px',
                backgroundColor: colors.primaryColor,
                transition: `width ${styles.transitionEffect}`
            },
            '&:hover span::after, &.active span::after': {
                width: '100%'
            },
            '&:hover span, &.active span': {
                fontFamily: theme.overrides.MuiTypography.h3.fontFamily
            }
        },
        badge: {
            backgroundColor: colors.primaryColor,
            color: colors.white,
            borderRadius: '50%',
            fontSize: '0.75rem',
            left: theme.spacing(-3.75),
            top: '50%',
            height: '1.25rem',
            width: '1.25rem',
            lineHeight: '1.25rem',
            textAlign: 'center',
            position: 'absolute',
            transform: 'translateY(-50%)'
        }
    }));
    const classes = useStyles();

    if(links.length === 0) {
        return null;
    }

    return (
        <List disablePadding>
            {
                links.map(link =>
                    <ListItem
                        key={link.url ?? link.text}
                        className={`${classes.accountMenuItem} ${link.selected ? 'active' : ''}`}
                        onClick={() => handleLinkClick(link)}
                        disableGutters
                    >
                        {
                            link.badge && <Box className={classes.badge}>{link.badge}</Box>
                        }
                        {
                            link.url ?
                                <Link to={link.url}>
                                    <ListItemText>{link.text}</ListItemText>
                                </Link>
                                :
                                <ListItemText>{link.text}</ListItemText>
                        }
                    </ListItem>
                )
            }
        </List>
    );
};

AccountNavigationLinks.propTypes = {
    links: PropTypes.array,
    handleLinkClick: PropTypes.func
};

export default AccountNavigationLinks;
