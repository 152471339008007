import React from 'react';
import PropTypes from 'prop-types';
import strings from 'localization/strings';
import { makeStyles } from 'styles/util';
import { calculateSum } from 'helpers/ArrayHelper';
import useMediaQuery from '@material-ui/core/useMediaQuery';

import Typography from '@material-ui/core/Typography';
import TableContainer from '@material-ui/core/TableContainer';
import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import TableBody from '@material-ui/core/TableBody';
import TableFooter from '@material-ui/core/TableFooter';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import Paper from '@material-ui/core/Paper';
import Amount from 'common/Amount';

const PriceSpecificationTable = ({ booking, purchaseItems, showVat, caption }) => {
    const isMobile = !useMediaQuery(theme => theme.breakpoints.up('md'));

    const useStyles = makeStyles(({ theme }) => ({
        specificationTable: {
            marginBottom: theme.spacing(2),
            '& td, & th': {
                fontSize: isMobile ? '0.75rem' : undefined
            }
        },
        footer: {
            '& tr td': {
                fontSize: isMobile ? '0.75rem' : '0.875rem',
                opacity: '1 !important'
            }
        },
        desktopRow: {
            '& > *': {
                width: '15%'
            },
            '& > :first-child': {
                width: '55%'
            }
        },
        mobileRow1: {
            '& td, & th': {
                borderBottom: 'none'
            }
        },
        mobileRow2: {
            '& td, & th': {
                opacity: 0.75,
                width: '33.33%'
            }
        },
        sumIncludingVat: {
            fontWeight: 'bold'
        },
        noWrap: {
            whiteSpace: 'nowrap'
        }
    }));
    const classes = useStyles();

    const currency = booking.currency;

    const firstCellColSpan = isMobile && showVat ? 3 : 1;

    const formatAmountOptions = { displayPriceRounding: false };

    const renderHeader = () => renderRowHelper([
        <TableCell colSpan={firstCellColSpan}>{strings.description}</TableCell>,
        <TableCell align="right">{strings.excludingVat}</TableCell>,
        <TableCell align="right">{strings.vat}</TableCell>,
        <TableCell align="right">{showVat ? strings.includingVat : strings.amount}</TableCell>
    ]);

    const renderRow = (purchaseItem, i) => renderRowHelper([
        <TableCell colSpan={firstCellColSpan}>{purchaseItem.description}</TableCell>,
        <TableCell className={classes.noWrap} align="right"><Amount value={purchaseItem.amount} currency={currency} {...formatAmountOptions}/></TableCell>,
        <TableCell className={classes.noWrap} align="right">{(purchaseItem.vat ?? 0) !== 0 && <Amount value={purchaseItem.vat} currency={currency} {...formatAmountOptions}/>}</TableCell>,
        <TableCell className={classes.noWrap} align="right"><Amount value={purchaseItem.amount + (purchaseItem.vat ?? 0)} currency={currency} {...formatAmountOptions}/></TableCell>
    ], i);

    const renderFooter = () => renderRowHelper([
        <TableCell colSpan={firstCellColSpan}>{strings.sum}</TableCell>,
        <TableCell className={classes.noWrap} align="right"><Amount value={calculateSum(purchaseItems, item => item.amount)} currency={currency} {...formatAmountOptions}/></TableCell>,
        <TableCell className={classes.noWrap} align="right"><Amount value={calculateSum(purchaseItems, item => item.vat ?? 0)} currency={currency} {...formatAmountOptions}/></TableCell>,
        <TableCell className={classes.noWrap + ' ' + classes.sumIncludingVat} align="right"><Amount value={calculateSum(purchaseItems, item => item.amount + (item.vat ?? 0))} currency={currency} {...formatAmountOptions}/></TableCell>
    ]);

    const renderRowHelper = (cells, key) => {
        if(isMobile && showVat) {
            return (
                <React.Fragment key={key}>
                    <TableRow className={classes.mobileRow1}>
                        {cells[0]}
                    </TableRow>
                    <TableRow className={classes.mobileRow2}>
                        {cells[1]}
                        {cells[2]}
                        {cells[3]}
                    </TableRow>
                </React.Fragment>
            );
        }
        return (
            <TableRow key={key} className={classes.desktopRow}>
                {cells[0]}
                {
                    showVat &&
                    (
                        <>
                            {cells[1]}
                            {cells[2]}
                        </>
                    )
                }
                {cells[3]}
            </TableRow>
        );
    };

    return (
        <>
            {caption && <Typography variant="h6" gutterBottom>{caption}</Typography>}
            <TableContainer className={classes.specificationTable} component={Paper}>
                <Table size="small">
                    <TableHead>
                        {renderHeader()}
                    </TableHead>
                    <TableBody>
                        {purchaseItems.map(renderRow)}
                    </TableBody>
                    <TableFooter className={classes.footer}>
                        {renderFooter()}
                    </TableFooter>
                </Table>
            </TableContainer>
        </>
    );
};

PriceSpecificationTable.propTypes = {
    booking: PropTypes.object.isRequired,
    purchaseItems: PropTypes.array.isRequired,
    showVat: PropTypes.bool,
    caption: PropTypes.string
};

export default PriceSpecificationTable;
