import Geocode from 'react-geocode';
import { defaultCompare } from 'helpers/SortHelper';
import store from 'store2';

const _addressToLocationCacheKeyGetter = appContext => `addressToLocation_${appContext.marketId}`;
const _locationToAddressCacheKeyGetter = appContext => `locationToAddress_${appContext.marketId}`;
const _maxCacheSize = 10;

// using react-geocode to translate a textual address to a (lat, lng) location
export const addressToLocation = (address, appContext, callback, useCache = false) => {
    const cachedResult =
        // Google is wrong for a few places, correct it through a lookup table
        addressToLocationLookupTable[(address || '').toLowerCase()]
        ??
        // use cache in local storage
        (useCache ? getFromCache(_addressToLocationCacheKeyGetter(appContext), address) : undefined);

    if(cachedResult) {
        callback({
            requestedAddress: address,
            ...cachedResult
        });
        return;
    }

    setContext(appContext);
    Geocode.fromAddress(address)
        .then(
            response => {
                const geocodeResult = getGeocodeResult(response, appContext);
                const callbackArgs = {
                    requestedAddress: address,
                    ...geocodeResult
                };
                addToCache(_addressToLocationCacheKeyGetter(appContext), address, geocodeResult);
                callback(callbackArgs);
            },
            error => callback({ requestedAddress: address, error })
        );
};

export const locationToAddress = (location /* { latitude, longitude} */, appContext, callback) => {
    setContext(appContext);
    const cacheKey = `${location.latitude}:${location.longitude}`;
    const cachedResult =
        // use cache in local storage
        getFromCache(_locationToAddressCacheKeyGetter(appContext), cacheKey);

    if(cachedResult) {
        callback({
            requestedLocation: location,
            ...cachedResult
        });
        return;
    }

    Geocode.fromLatLng(location.latitude, location.longitude)
        .then(
            response => {
                const geocodeResult = getGeocodeResult(response, appContext);
                const callbackArgs = {
                    requestedLocation: location,
                    ...geocodeResult
                };
                addToCache(_locationToAddressCacheKeyGetter(appContext), cacheKey, geocodeResult);
                callback(callbackArgs);
            },
            error => callback({ requestedLocation: location, error })
        );
};

export const getGeocodeResult = (response, appContext) => {
    if (response.status === 'OK') {
        const resultsWithScore = response.results.map(result => ({
            score: getAddressScore(result, appContext),
            result
        }));
        resultsWithScore.sort((a, b) => -defaultCompare(a.score, b.score));

        const bestResult = resultsWithScore[0];

        if (bestResult) {
            return createGeocodeResult(bestResult.result, appContext);
        }
    }
    return {
        address: undefined,
        location: undefined
    };
};

export const getGeocodeResultForSinglePlace = (result, appContext, callback, useCache = false) => {
    if(result.address_components) {
        // returns { locationText, address: { street, sublocality, postalCode, city }, location: { latitude, longitude } } }
        callback(createGeocodeResult(result, appContext));
    } else {
        // we only have a name, { name: 'whatever' }
        // reverse geocode
        // returns { requestedAddress, address: { street, sublocality, postalCode, city }, location: { latitude, longitude } } }
        const requestedAddress = result.name;
        addressToLocation(requestedAddress, appContext, args => callback({ ...args, locationText: args.address?.city }), useCache);
    }
};

const createGeocodeResult = (result, appContext) => {
    return {
        locationText: result.name,
        address: createAddressObject(result, appContext),
        location: createLocationObject(result)
    };
};

const getAddressScore = (geocodeResult, appContext) => {
    let score = 0;
    getValidAddressComponentTypes(appContext).forEach(validTypes => {
        geocodeResult.address_components.forEach(addressComponent => {
            const isMatch = atLeastOneElementInBothArrays(addressComponent.types, validTypes);
            if (isMatch) {
                score++;
            }
        });
    });
    return score;
};

const createAddressObject = (geocodeResult, appContext) => {
    let street = undefined;
    let streetNumber = undefined;
    let sublocality = undefined;
    let postalCode = undefined;
    let city = undefined;

    if(geocodeResult.address_components) {
        const array = [...getValidAddressComponentTypes(appContext)];
        geocodeResult.address_components.forEach(addressComponent => {
            if(atLeastOneElementInBothArrays(addressComponent.types, array[0])) {
                city = addressComponent.long_name;
                removeFromArray(addressComponent.types, array);
            }
            if(atLeastOneElementInBothArrays(addressComponent.types, array[1])) {
                postalCode = addressComponent.long_name;
                removeFromArray(addressComponent.types, array);
            }
            if(atLeastOneElementInBothArrays(addressComponent.types, array[2])) {
                sublocality = addressComponent.long_name;
                removeFromArray(addressComponent.types, array);
            }
            if(atLeastOneElementInBothArrays(addressComponent.types, array[3])) {
                street = addressComponent.long_name;
                removeFromArray(addressComponent.types, array);
            }
            if(atLeastOneElementInBothArrays(addressComponent.types, array[4])) {
                streetNumber = addressComponent.long_name;
                removeFromArray(addressComponent.types, array);
            }
        });
    }

    if(!city && street) {
        city = street;
        street = undefined;
        streetNumber = undefined;
    }

    return {
        street: street || streetNumber ? ((street || '') + ' ' + (streetNumber || '')).trim() : undefined,
        sublocality,
        postalCode,
        city
    };
};

const removeFromArray = (itemsToRemove, array) => {
    for(let i = 0; i < array.length; i++) {
        itemsToRemove.forEach(item => {
            array[i] = array[i].filter(o => o !== item);
        });
    }
};

const createLocationObject = geocodeResult => geocodeResult.geometry
    ? {
        latitude: typeof geocodeResult.geometry.location.lat === 'function' ? geocodeResult.geometry.location.lat() : geocodeResult.geometry.location.lat,
        longitude: typeof geocodeResult.geometry.location.lng === 'function' ? geocodeResult.geometry.location.lng() : geocodeResult.geometry.location.lng,
    }
    : undefined;

const atLeastOneElementInBothArrays = (array1, array2) => {
    return array1.filter(i1 => array2.filter(i2 => i1 === i2).length > 0).length > 0;
};

const getValidAddressComponentTypes = appContext => {
    // TODO: make nicer
    if(appContext.countryCode === 'fi') {
        return [['postal_town', 'locality', 'administrative_area_level_3'], ['postal_code'], ['sublocality'], ['locality', 'route', 'town_square'], ['premise', 'street_number']];
    }
    return [['postal_town'], ['postal_code'], ['sublocality'], ['locality', 'route', 'town_square'], ['premise', 'street_number']];
};

// lookup table for locations that Google has messed up
const addressToLocationLookupTable = {
    'kungsängen': {
        address: { city: 'Kungsängen' },
        location: {
            latitude: 59.47772,
            longitude: 17.750921
        }
    },
    'benestad': {
        address: { city: 'Benestad' },
        location: {
            latitude: 55.524272,
            longitude: 13.905974
        }
    },
    'öland': {
        address: { city: 'Öland' },
        location: {
            latitude: 56.674295,
            longitude: 16.518367
        }
    }
};

const setContext = appContext => {
    Geocode.setLanguage(appContext.language);
    Geocode.setRegion(appContext.countryCode);
};

const addToCache = (cacheKey, propertyName, propertyValue) => {
    const cachedObject = getCachedObject(cacheKey);
    const updatedCachedObject = {};
    cachedObject[propertyName] = undefined;
    let count = Object.keys(cachedObject).length;
    Object.keys(cachedObject).forEach(pk => {
        if(count < _maxCacheSize) {
            updatedCachedObject[pk] = cachedObject[pk];
        }
        count--;
    });
    updatedCachedObject[propertyName] = propertyValue;
    store.set(cacheKey, JSON.stringify(updatedCachedObject));
};

const getFromCache = (cacheKey, propertyName) => {
    const cachedObject = getCachedObject(cacheKey);
    return cachedObject[propertyName];
};

const getCachedObject = cacheKey => {
    const cachedObjectString = store.get(cacheKey);
    const cachedObject = cachedObjectString
        ? JSON.parse(cachedObjectString)
        : {};
    return cachedObject;
};
