import React from 'react';
import PropTypes from 'prop-types';
import { useAppContext } from 'context/AppContext';
import { useSelector } from 'react-redux';
import strings from 'localization/strings';
import { makeStyles } from 'styles/util';
import { formatLengthAndWidth } from 'helpers/StringHelper';
import bookingStatuses from 'enums/bookingStatuses';
import adminBookingStatuses from 'enums/adminBookingStatuses';
import { formatDateAndTimeSpan } from 'helpers/OpeningTimeSpanHelper';
import { getContact, getBusinessOrPrivateName } from 'helpers/ActorHelper';
import contactTypes from 'enums/contactTypes';
import routes from 'routes';

import Box from '@material-ui/core/Box';
import LinkButton from 'common/LinkButton';
import Area from 'common/Area';
import InformationItem from './InformationItem';
import { Link } from 'react-router-dom';

const useStyles = makeStyles(({ theme }) => ({
    changeStorage: {
        paddingLeft: theme.spacing(2),
        fontSize: 'inherit'
    }
}));

const InformationItems = ({ booking, isOwner = false, onChangeStorage }) => {
    const classes = useStyles();
    const { appContext } = useAppContext();
    const authenticationContext = useSelector(state => state.authentication.context);

    const status = booking.adminBookingStatus;

    const canChangeStorage = isOwner &&
        (status === adminBookingStatuses.confirmed.key || status === adminBookingStatuses.notStarted.key || status === adminBookingStatuses.active.key || status === adminBookingStatuses.subscriptionEnding.key) &&
        booking.storageGroup.enumerateStorages &&
        !!onChangeStorage;

    const getStorageTitleText = () => {
        if(canChangeStorage) {
            return (
                <>
                    {booking.storage.title}
                    <LinkButton className={classes.changeStorage} onClick={onChangeStorage}>
                        {strings.change}
                    </LinkButton>
                </>
            );
        }
        return booking.storage.title;
    };

    const getBookingIdWithLink = () => {
        if(authenticationContext.isSuperAdminOrImpersonated) {
            return (
                <Link to={routes.admin.booking.replace(':bookingId', booking.id)}>
                    {booking.id}
                </Link>
            );
        }
        return booking.id;
    };

    const formatCreditCheckValue = b => `${(b.creditCheckPassed ? strings.approved : strings.disapproved)} (${b.creditReportData})`;

    let bookingEndDate = booking.actualEndDate ?? booking.endDate ?? booking.subscriptionEndDate;
    let checkOutOpeningTimeSpan = booking.checkOutOpeningTimeSpan;

    if(booking.subscriptionBooking && bookingEndDate) {
        bookingEndDate = booking.subscriptionInfo.earliestPossibleCheckOutOpeningTimeSpan?.date ?? booking.subscriptionInfo.earliestPossibleEndDate;
        checkOutOpeningTimeSpan = booking.subscriptionInfo.earliestPossibleCheckOutOpeningTimeSpan;
    }

    return (
        <>
            <Box>
                <InformationItem title={strings.bookingNumber} value={getBookingIdWithLink()}/>
                {
                    !booking.isTakeoverBooking &&
                    (
                        <InformationItem title={strings.checkIn} value={formatDateAndTimeSpan(booking.startDate, booking.checkInOpeningTimeSpan, appContext)}/>
                    )
                }
                <InformationItem title={strings.checkOut} value={bookingEndDate ? formatDateAndTimeSpan(bookingEndDate, checkOutOpeningTimeSpan, appContext) : strings.bookingIsUntilFurther}/>
            </Box>
            <Box>
                {
                    isOwner && booking.storageGroup.enumerateStorages && booking.storageId &&
                    (
                        <InformationItem title={strings.bookedStorageTitle} value={getStorageTitleText()}/>
                    )
                }
                {
                    booking.area > 0 &&
                    (
                        <InformationItem title={strings.area} value={<Area value={booking.area} maxNumberOfDecimals={1}/>}/>
                    )
                }
                {
                    booking.length > 0 && booking.width > 0 &&
                    (
                        <InformationItem title={strings.dimensions} value={`${formatLengthAndWidth(booking.length, booking.width, appContext, { maxNumberOfDecimals: 1 })}`}/>
                    )
                }
                {
                    isOwner && getBusinessOrPrivateName(booking.tenantActor) &&
                    (
                        <>
                            <InformationItem title={strings.tenant} value={getBusinessOrPrivateName(booking.tenantActor)}/>
                            {
                                booking.bookingStatus === bookingStatuses.purchased.key &&
                                (
                                    <>
                                        <InformationItem title={strings.email} value={<a href={`mailto:${getContact(booking.tenantActor, contactTypes.mainEmail)}`}>{getContact(booking.tenantActor, contactTypes.mainEmail)}</a>}/>
                                        <InformationItem title={strings.phoneNumber} value={getContact(booking.tenantActor, contactTypes.mainMobileTelephone)}/>
                                    </>
                                )
                            }
                        </>
                    )
                }
                {
                    isOwner && appContext.creditReport.enabled && booking.creditCheckPassed !== undefined && <InformationItem title={strings.creditCheck} value={formatCreditCheckValue(booking)}/>
                }
                {
                    !isOwner && getBusinessOrPrivateName(booking.ownerActor) && <InformationItem title={strings.owner} value={getBusinessOrPrivateName(booking.ownerActor)}/>
                }
            </Box>
        </>
    );
};

InformationItems.propTypes = {
    booking: PropTypes.object.isRequired,
    isOwner: PropTypes.bool,
    onChangeStorage: PropTypes.func
};

export default InformationItems;
