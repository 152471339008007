import React  from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from 'styles/util';

import ButtonLink from 'common/ButtonLink';
import Box from '@material-ui/core/Box';

const useStyles = makeStyles(({ theme }) => ({
    container: {
        display: 'flex',
        gap: theme.spacing(2)
    }
}));

const DesktopNavigation = ({ menuItems }) => {
    const classes = useStyles();

    return (
        <Box className={classes.container}>
            {
                menuItems.map(menuItem => (
                    <ButtonLink key={menuItem.route} to={menuItem.route} variant={menuItem.variant}>
                        {menuItem.text}
                    </ButtonLink>
                ))
            }
        </Box>
    );
};

DesktopNavigation.propTypes = {
    menuItems: PropTypes.array.isRequired
};

export default DesktopNavigation;
