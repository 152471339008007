import strings from 'localization/strings';

const paymentProviders = {
    stripe: {},
    billecta: {}
};

Object.keys(paymentProviders).forEach(key => {
    paymentProviders[key].key = key;
});

strings.addLoadCallback(() => {
    Object.keys(paymentProviders).forEach(key => {
        Object.keys(strings.paymentProviders[key]).forEach(o => {
            paymentProviders[key][o] = strings.paymentProviders[key][o];
        });
    });
});

export default paymentProviders;
