import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { handleResponse } from 'actions/actionHelpers';
import { fetchAppContextInformation } from 'actions/appContext';
import { makeQueryablePromise } from 'helpers/PromiseHelper';
import { loadStripe } from '@stripe/stripe-js';
import { arrayDictionarify } from 'helpers/ArrayHelper';
import { querystringParameterSet } from 'helpers/BrowserHelper';
import storageGroupCategories from 'enums/storageGroupCategories';
import { nestedMerge } from 'helpers/ObjectHelper';
import branches from 'enums/branches';
import markets from 'enums/markets';

import AppContext from 'context/AppContext';
import Loader from 'common/Loader';

const packageJson = require('./../../package.json');

const extractIdAndName = (appContext, prefix) => ({
    id: appContext[`${prefix}Id`],
    name: appContext[`${prefix}Name`]
});

const enrichBranches = appContextInformation => {
    appContextInformation.all.forEach(item => {
        branches[item.branchKey].id = item.branchId;
        branches[item.branchKey].name = item.branchName;
    });
};

const enrichMarkets = appContextInformation => {
    appContextInformation.all.forEach(item => {
        markets[item.marketKey].id = item.marketId;
        markets[item.marketKey].name = item.marketName;
    });
};

const enrichAppContextInformation = appContextInformation => {
    // add frontend-specific info to configuration received from backend
    const branch = {
        ...Object.values(branches).find(b => b.key === appContextInformation.current.branchKey),
        ...extractIdAndName(appContextInformation.current, 'branch')
    };
    const market = {
        ...Object.values(markets).find(m => m.key === appContextInformation.current.marketKey),
        ...extractIdAndName(appContextInformation.current, 'market')
    };

    const appContext = nestedMerge(
        // copy backend configuration
        appContextInformation.current,
        // branch-specific info
        branch,
        // market-specific info
        market
    );

    // enrich appContext.storageGroupCategories
    appContext.storageGroupCategories = appContext.storageGroupCategories.map(key => storageGroupCategories[key]);

    // add appContext.appVersion
    appContext.appVersion = packageJson.version;

    // add appContext.theme
    appContext.theme = appContext.createTheme(appContext);

    return {
        allAppContexts: appContextInformation.all,
        appContext,
        branches: Object.values(
            arrayDictionarify(
                appContextInformation.all,
                o => o.branchKey,
                o => ({ key: o.branchKey, name: o.branchName, id: o.branchId })
            )
        ).map(o => o[0]),
        markets: Object.values(
            arrayDictionarify(
                appContextInformation.all,
                o => o.marketKey,
                o => ({ key: o.marketKey, name: o.marketName, id: o.marketId })
            )
        ).map(o => o[0])
    };
};

// component that loads the app context information and provides it to the application using the useAppContext hook
const AppContextProvider = ({ children }) => {
    const dispatch = useDispatch();

    const minimal = querystringParameterSet('minimal');

    const [appContextInformation, setAppContextInformation] = useState(undefined);
    const [stripePromise, setStripeObject] = useState(undefined);

    // fetch appContext configuration based on localStorage settings or url
    useEffect(() => {
        dispatch(fetchAppContextInformation())
            .then(handleResponse(
                response => {
                    enrichBranches(response.payload);
                    enrichMarkets(response.payload);
                    setAppContextInformation(enrichAppContextInformation(response.payload));
                }
            ));
    }, []);

    useEffect(() => {
        if(appContextInformation) {
            initializeStripe();
        }
    }, [appContextInformation]);

    const initializeStripe = () => {
        // fetch a StripeElements element, which needs a Stripe promise based on the Stripe API key that is part of the app context configuration
        if(appContextInformation.appContext.payment.stripe.publishableKey && !minimal) {
            setStripeObject(() => makeQueryablePromise(loadStripe(appContextInformation.appContext.payment.stripe.publishableKey)));
        }
    };

    const isLoading = !((stripePromise || minimal) && appContextInformation);

    // a loaded app context is required in order to show the application
    // show a spinner in neutral color until it is loaded
    if(isLoading) {
        return <Loader color="#7f7f7f" />;
    }

    const appContextObject = {
        ...appContextInformation,
        stripePromise
    };

    // using React's context feature
    // in React components, the app context is accessed by const { appContext } = useAppContext();
    return (
        <AppContext.Provider value={appContextObject}>
            {children}
        </AppContext.Provider>
    );
};

AppContextProvider.propTypes = {
    children: PropTypes.node.isRequired
};

export default AppContextProvider;
