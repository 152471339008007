import { RSAA } from 'redux-api-middleware';
import { createRsaaTypes, createHeaders } from '../actionHelpers';
import queryString from 'query-string';

export const ADMIN_ACTORS_SEARCH = 'ADMIN_ACTORS_SEARCH';
export const ADMIN_ACTORS_SEARCH_SUCCESS = 'ADMIN_ACTORS_SEARCH_SUCCESS';
export const ADMIN_ACTORS_SEARCH_FAILURE = 'ADMIN_ACTORS_SEARCH_FAILURE';
export const ADMIN_ACTORS_GET = 'ADMIN_ACTORS_GET';
export const ADMIN_ACTORS_UPDATE = 'ADMIN_ACTORS_UPDATE';
export const ADMIN_ACTORS_SET_SEARCH_PARAMETERS = 'ADMIN_ACTORS_SET_SEARCH_PARAMETERS';

export const searchActors = (searchParameters, useRedux = true) => ({
    [RSAA]: {
        types: createRsaaTypes(ADMIN_ACTORS_SEARCH + (useRedux ? '' : '_NO_REDUX')),
        endpoint: `/api/admin/actors?${queryString.stringify(searchParameters)}`,
        headers: createHeaders(false),
        method: 'GET'
    }
});

export const getActor = (id) => ({
    [RSAA]: {
        types: createRsaaTypes(ADMIN_ACTORS_GET),
        endpoint: `/api/admin/actors/${id}`,
        headers: createHeaders(false),
        method: 'GET'
    }
});

export const updateActor = (id, updateActorData) => ({
    [RSAA]: {
        types: createRsaaTypes(ADMIN_ACTORS_UPDATE),
        endpoint: `/api/admin/actors/${id}`,
        headers: createHeaders(true),
        method: 'PUT',
        body: JSON.stringify(updateActorData)
    }
});

export const setSearchParameters = (searchParameters) => ({
    type: ADMIN_ACTORS_SET_SEARCH_PARAMETERS,
    searchParameters
});
