import React from 'react';
import PropTypes from 'prop-types';
import { useAppContext } from 'context/AppContext';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { makeStyles } from 'styles/util';
import { getFilteredLandingPages } from 'helpers/LandingPageHelper';
import { roundToDecimals, saveSearchParametersToLocalStorage, getBrowserSearchUrl } from 'helpers/SearchHelper';
import { setLocalStorageValue } from 'actions/localStorage';
import { getStorageGroupCategoryOrDefault } from 'enums/storageGroupCategories';
import useMediaQuery from '@material-ui/core/useMediaQuery';

import Tabs from '@material-ui/core/Tabs';

import StyledTab from 'components/muiModifications/StyledTab';

import Box from '@material-ui/core/Box';
import LocationSelector from 'form/LocationSelector';

const useStyles = makeStyles(({ theme, colors, styles }) => ({
    hideIndicator: {
        height: 0
    },
    mainContainer: {
        flex: '0 0 auto',
        width: '100%',
        margin: 'auto 0 0',
        borderTopLeftRadius: `${styles.borderRadius}px`,
        borderTopRightRadius: `${styles.borderRadius}px`,
        [theme.breakpoints.down('sm')]: {
            width: '100%'
        }
    },
    innerContainer: {
        padding: theme.spacing(1.875, 0, 0),
    },
    typeContainer: {
        display: 'flex',
        flex: `1 0 ${theme.spacing(11.125)}px`,
        width: '100%',
        '& [role="tablist"]': {
            gap: `${theme.spacing(1.25)}px`,
        }
    },
    storageType: {
        padding: theme.spacing(0),
        [theme.breakpoints.down('sm')]: {
            flex: '1 1 0',
        },
        '& span': {
            lineHeight: `${theme.spacing(3.125)}px`,
            letterSpacing: '-0.01em',
        }
    },
    selected: {
        background: colors.primaryColor,
        '& span': {
            color: colors.white
        },
        '& img': {
            filter: 'invert(1)'
        }
    }
}),
({ theme }) => ({
    storage: {
        [theme.breakpoints.down('sm')]: {
            innerContainer: {
                paddingTop: '20px'
            }
        }
    }
}));

const StartPageSearchForm = ({ searchParameters }) => {
    const classes = useStyles();
    const { appContext } = useAppContext();
    const dispatch = useDispatch();
    const history = useHistory();
    const startPageCategory = getStorageGroupCategoryOrDefault(useSelector(state => state.localStorage.startPageCategory), appContext);
    const isSmallMobile = useMediaQuery(theme => theme.breakpoints.down('xs'));
    const filteredLandingPages = getFilteredLandingPages(appContext);

    const handleChange = (event, newValue) => {
        dispatch(setLocalStorageValue('startPageCategory', newValue));
    };

    const handleSearch = searchResult => {
        history.push(getLocationUrl(searchResult));
        saveSearchParametersToLocalStorage({ locationText: searchResult.locationText, ...getSearchParameters(searchResult) });
    };

    const getLocationUrl = searchResult => {
        const parameters = {
            locationText: searchResult.locationText
        };
        const landingPage = filteredLandingPages[startPageCategory.key];
        if (searchResult.location) {
            parameters.lat = roundToDecimals(searchResult.location.lat, 5);
            parameters.lng = roundToDecimals(searchResult.location.lng, 5);
        }

        const { path, query } = getBrowserSearchUrl(landingPage, parameters);
        return `${path}?${query}`;
    };

    const getSearchParameters = searchResult => {
        const parameters = {};
        if(searchResult.location && searchResult.locationText) {
            parameters.lat = roundToDecimals(searchResult.location.lat, 5);
            parameters.lng = roundToDecimals(searchResult.location.lng, 5);
        }
        return parameters;
    };

    return (
        <Box className={classes.mainContainer}>
            {
                Object.values(filteredLandingPages).length > 1 &&
                (
                    <Tabs value={startPageCategory.key} onChange={handleChange} className={classes.typeContainer} classes={{ indicator: classes.hideIndicator }}>
                        {
                            Object.values(filteredLandingPages).map(landingPage => (
                                <StyledTab
                                    icon={<img src={landingPage.icon} />}
                                    key={landingPage.key}
                                    value={landingPage.key}
                                    label={isSmallMobile ? landingPage.shortestLabel : landingPage.shortLabel}
                                    classes={{ root: classes.storageType, selected: classes.selected }}
                                />
                            ))
                        }
                    </Tabs>
                )
            }
            <Box className={classes.innerContainer}>
                <LocationSelector
                    address={searchParameters.locationText}
                    location={searchParameters.location}
                    onSearch={handleSearch}
                />
            </Box>
        </Box>
    );
};

StartPageSearchForm.propTypes = {
    searchParameters: PropTypes.object
};

export default StartPageSearchForm;
