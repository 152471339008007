import React from 'react';
import PropTypes from 'prop-types';
import storageTypes from 'enums/storageTypes';
import { makeStyles } from 'styles/util';

import Box from '@material-ui/core/Box';

const StorageTypeIconAndText = ({ storageType, iconColor }) => {
    const useStyles = makeStyles(({ theme }) => ({
        container: {
            display: 'flex'
        },
        icon: {
            display: 'inline',
            paddingRight: theme.spacing(0.75),
            zoom: 1.25,
            color: iconColor
        }
    }));
    const classes = useStyles();

    const obj = typeof storageType === 'string'
        ? Object.values(storageTypes).find(o => o.key === storageType) || {}
        : storageType || {};
    return (
        <Box component="span" className={classes.container}>
            <Box component="span" className={classes.icon + ' ' + obj.icon}/>
            {obj.title}
        </Box>
    );
};

StorageTypeIconAndText.propTypes = {
    storageType: PropTypes.oneOfType([
        PropTypes.object,
        PropTypes.string
    ]).isRequired,
    iconColor: PropTypes.string
};

export default StorageTypeIconAndText;
