import React from 'react';
import { useAppContext } from 'context/AppContext';
import PropTypes from 'prop-types';
import { formatArea } from 'helpers/StringHelper';

const Area = ({ value, numberOfDecimals, maxNumberOfDecimals, toLocale, sanitizeMinusSign, skipThousandSeparators, defaultValue }) => {
    const { appContext } = useAppContext();
    return <>{formatArea(value, appContext, { numberOfDecimals, maxNumberOfDecimals, toLocale, sanitizeMinusSign, skipThousandSeparators, defaultValue })}</>;
};

Area.propTypes = {
    value: PropTypes.number,
    numberOfDecimals: PropTypes.number,
    maxNumberOfDecimals: PropTypes.number,
    toLocale: PropTypes.bool,
    sanitizeMinusSign: PropTypes.bool,
    skipThousandSeparators: PropTypes.bool,
    defaultValue: PropTypes.string
};

export default Area;
