import React from 'react';
import PropTypes from 'prop-types';
import strings from 'localization/strings';
import { required } from 'form/validation';

import Form from 'form/Form';
import Button from '@material-ui/core/Button';
import { TextField, showErrorOnBlur } from 'mui-rff';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Typography from '@material-ui/core/Typography';

const CreateBookingNoteDialog = ({ open, onOk, onCancel }) => {
    const initialValues = {
        text: ''
    };

    return (
        <Dialog fullWidth maxWidth="md" open={open} onClose={onCancel}>
            <Form
                initialValues={initialValues}
                onSubmit={onOk}
            >
                {({ handleSubmit, submitting, pristine }) => (
                    <form onSubmit={handleSubmit}>
                        <DialogTitle disableTypography>
                            <Typography variant="h5">
                                {strings.createBookingNoteDialogTitle}
                            </Typography>
                        </DialogTitle>
                        <DialogContent>
                            <Typography variant="body1" gutterBottom>
                                {strings.createBookingNoteDialogBody}
                            </Typography>
                            <TextField
                                name="text"
                                label={strings.bookingNote}
                                type="text"
                                variant="outlined"
                                showError={showErrorOnBlur}
                                required
                                fieldProps={{ validate: required }}
                                multiline
                                rows={3}
                                maxRows={3}
                                maxLength={1024}
                                autoFocus
                            />
                        </DialogContent>
                        <DialogActions>
                            <Button
                                type="submit"
                                variant="contained"
                                color="primary"
                                disabled={submitting || pristine}
                            >
                                {strings.save}
                            </Button>
                            <Button
                                type="button"
                                variant="outlined"
                                color="primary"
                                onClick={onCancel}
                            >
                                {strings.cancel}
                            </Button>
                        </DialogActions>
                    </form>
                )}
            </Form>
        </Dialog>
    );
};

CreateBookingNoteDialog.propTypes = {
    open: PropTypes.bool.isRequired,
    onOk: PropTypes.func.isRequired,
    onCancel: PropTypes.func.isRequired
};

export default CreateBookingNoteDialog;
