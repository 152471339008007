import React from 'react';
import PropTypes from 'prop-types';

import Paper from '@material-ui/core/Paper';
import LoadingWrapper from 'form/LoadingWrapper';

const AdminFormWrapper = ({ children, isLoading = false, isSubmitting = false }) => {
    return (
        <Paper variant="outlined">
            <LoadingWrapper isLoading={isLoading} isSaving={isSubmitting}>
                {children}
            </LoadingWrapper>
        </Paper>
    );
};

AdminFormWrapper.propTypes = {
    children: PropTypes.oneOfType([
        PropTypes.arrayOf(PropTypes.node),
        PropTypes.node
    ]),
    isLoading: PropTypes.bool,
    isSubmitting: PropTypes.bool
};

export default AdminFormWrapper;
