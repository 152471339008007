import React from 'react';
import strings from 'localization/strings';
import { formatTimeSpan, formatDateInterval, getWeekIntervalTitle, getComingOpeningTimeSpanDates } from 'helpers/OpeningTimeSpanHelper';
import { parseIsoDate, formatShortDate } from 'helpers/DateHelper';
import { capitalizeFirstLetter } from 'helpers/StringHelper';
import daysOfWeek from 'enums/daysOfWeek';
import { isBefore, startOfToday } from 'date-fns';
import openingTimeSpanActions from 'enums/openingTimeSpanActions';
import openingModes from 'enums/openingModes';

import TimeSpan from 'common/TimeSpan';

const dateRuleTypes = {
    all: {
        getDescription: (openingTimeSpanRule, { asValueObject = false, appContext }) =>
            getReturnValue(strings.dateRuleTypes.all, openingTimeSpanRule, appContext, asValueObject),
        isActive: () => true
    },
    dayOfWeekPattern: {
        getDescription: (openingTimeSpanRule, { asValueObject = false, appContext }) => {
            const data = getDataForRule(openingTimeSpanRule);
            let daysOfWeekString = <>{capitalizeFirstLetter(data.daysOfWeek.map(o => daysOfWeek[o].pluralTitle).join(', '))}</>;

            const weekIntervalTitle = data.weekInterval > 1
                ? getWeekIntervalTitle(data.weekInterval)
                : undefined;

            const comingOpeningTimeSpanDatesString = getComingOpeningTimeSpanDates(openingTimeSpanRule, data, appContext);
            daysOfWeekString = <>{daysOfWeekString}{weekIntervalTitle ? <>, {weekIntervalTitle}</> : undefined}{comingOpeningTimeSpanDatesString ? <> ({comingOpeningTimeSpanDatesString})</> : undefined}</>;

            return getReturnValue(daysOfWeekString, openingTimeSpanRule, appContext, asValueObject);
        },
        isActive: openingTimeSpanRule => {
            const data = getDataForRule(openingTimeSpanRule);
            const to = parseIsoDate(data.to);
            return !(to && isBefore(to, startOfToday()));
        }
    },
    swedishHolidays: {
        getDescription: (openingTimeSpanRule, { asValueObject = false, appContext }) =>
            getReturnValue(strings.dateRuleTypes.swedishHolidays, openingTimeSpanRule, appContext, asValueObject),
        isActive: () => true
    },
    weekendsAndSwedishHolidays: {
        getDescription: (openingTimeSpanRule, { asValueObject = false, appContext }) =>
            getReturnValue(strings.dateRuleTypes.weekendsAndSwedishHolidays, openingTimeSpanRule, appContext, asValueObject),
        isActive: () => true
    },
    swedishWorkdays: {
        getDescription: (openingTimeSpanRule, { asValueObject = false, appContext }) =>
            getReturnValue(strings.dateRuleTypes.swedishWorkdays, openingTimeSpanRule, appContext, asValueObject),
        isActive: () => true
    },
    dateInterval: {
        getDescription: (openingTimeSpanRule, { asValueObject = false, appContext }) => {
            const data = getDataForRule(openingTimeSpanRule);
            return getReturnValue(formatDateInterval(data), openingTimeSpanRule, appContext, asValueObject);
        },
        isActive: openingTimeSpanRule => {
            const data = getDataForRule(openingTimeSpanRule);
            const to = parseIsoDate(data.to);
            return !isBefore(to, startOfToday());
        }
    },
    dates: {
        getDescription: (openingTimeSpanRule, { asValueObject = false, ignorePastDates = false, appContext }) => {
            const data = getDataForRule(openingTimeSpanRule);
            const dates = data.dates.filter(date => !ignorePastDates || !isBefore(parseIsoDate(date), startOfToday()));
            return getReturnValue(dates.map(o => formatShortDate(o, appContext)).join(', '), openingTimeSpanRule, appContext, asValueObject);
        },
        isActive: openingTimeSpanRule => {
            const data = getDataForRule(openingTimeSpanRule);
            const dates = data.dates.map(parseIsoDate);
            return dates.find(date => !isBefore(date, startOfToday())) !== undefined;
        }
    }
};

// data is stored defferently in edit mode compared to display mode
const getDataForRule = openingTimeSpanRule => openingTimeSpanRule[openingTimeSpanRule.type] ?? openingTimeSpanRule.data;

Object.keys(dateRuleTypes).forEach(key => {
    dateRuleTypes[key].key = key;
});

strings.addLoadCallback(() => {
    Object.values(dateRuleTypes).forEach(o => {
        o.title = strings.dateRuleTypes[o.key];
    });
});

const getReturnValue = (str, openingTimeSpanRule, appContext, asValueObject = false) =>
    asValueObject
        ? getValueObject(str, openingTimeSpanRule, appContext)
        : appendTimeSpan(str, openingTimeSpanRule);

const appendTimeSpan = (str, openingTimeSpanRule) => <>{str}: <TimeSpan value={openingTimeSpanRule} includeMode/>{getRestrictionString(openingTimeSpanRule)}</>;

const getValueObject = (str, openingTimeSpanRule, appContext) => ({
    title: str,
    value: <>{formatTimeSpan(openingTimeSpanRule, { includeMode: true, appContext })}{getRestrictionString(openingTimeSpanRule)}</>
});

const getRestrictionString = openingTimeSpanRule => {
    if(!openingTimeSpanRule.restrictedToAction ||
       openingTimeSpanRule.restrictedToAction === 'all' ||
       openingTimeSpanRule.mode === openingModes.closed.key) {
        return '';
    }
    const str = openingTimeSpanActions[openingTimeSpanRule.restrictedToAction].lowercaseTitle;
    return ` (${str})`;
};

export default dateRuleTypes;
