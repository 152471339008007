import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router';
import { useHistory } from 'react-router-dom';
import { makeStyles } from 'styles/util';
import { getPayout, retryPayout } from 'actions/admin/payouts';
import { handleResponse } from 'actions/actionHelpers';
import payoutStatuses from 'enums/payoutStatuses';
import payoutReceiverTypes from 'enums/payoutReceiverTypes';
import routes from 'routes';

import Loader from 'common/Loader';
import AdminPage from '../../presentational/AdminPage';
import AdminContentWrapper from '../../presentational/AdminContentWrapper';
import AdminActionWrapper from '../../presentational/AdminActionWrapper';
import AdminFormWrapper from '../../presentational/AdminFormWrapper';
import AdminFormFields from '../../presentational/AdminFormFields';
import Button from '@material-ui/core/Button';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import ReadOnlyField from '../../presentational/ReadOnlyField';
import { Link } from 'react-router-dom';
import TimeAgo from 'common/TimeAgo';
import Amount from 'common/Amount';
import ShortDate from 'common/ShortDate';
import ShortDateTime from 'common/ShortDateTime';

const useStyles = makeStyles(() => ({
    nowrap: {
        whiteSpace: 'nowrap'
    }
}));

const formatAmountOptions = { displayPriceRounding: false };

const Payout = () => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const params = useParams();
    const history = useHistory();

    const [payout, setPayout] = useState(undefined);
    const [isLoading, setIsLoading] = useState(true);
    const { payoutId } = params;

    useEffect(() => {
        loadPayout();
    }, [payoutId]);

    const loadPayout = () => {
        setIsLoading(true);
        dispatch(getPayout(payoutId))
            .then(handleResponse(
                response => {
                    setPayout(response.payload);
                    setIsLoading(false);
                },
                () => setIsLoading(false)
            ));
    };

    const title = 'Payout: ' + (payout ? payout.id : 'Loading...');

    const getOwnerName = () => payout.ownerActorId
        ? <Link to={routes.admin.actor.replace(':actorId', payout.ownerActorId)}>{payout.ownerName}</Link>
        : 'Storage365';

    const formatPurchasePeriod = purchase => purchase.periodStartDate && purchase.periodEndDate
        ? <><ShortDate value={purchase.periodStartDate}/> &ndash; <ShortDate value={purchase.periodEndDate}/></>
        : undefined;

    const getPurchaseAmountForRecipient = purchase => payout.receiverType === payoutReceiverTypes.owner.key
        ? purchase.ownerAmount
        : purchase.commissionFee - purchase.paymentProviderFee;

    const handleRetryPayoutClick = () => {
        setIsLoading(true);
        dispatch(retryPayout(payout.id))
            .then(handleResponse(
                response => {
                    setIsLoading(false);
                    history.push(routes.admin.payout.replace(':payoutId', response.payload.id));
                    return response.payload.status === payoutStatuses.failed.key
                        ? { snackbar: { message: 'The payout retry failed.', variant: 'error' } }
                        : { snackbar: { message: 'The payout was successfully retried.' } };
                },
                () => setIsLoading(false)
            ));
    };

    return (
        <AdminPage title={title}>
            {
                isLoading && <Loader />
            }
            {
                !isLoading && payout && (
                    <AdminContentWrapper type="detail">

                        <AdminFormWrapper>

                            <AdminFormFields>

                                <ReadOnlyField label="Payout ID">
                                    {payout.id}
                                </ReadOnlyField>

                                <ReadOnlyField label="Stripe payout ID">
                                    <a href={payout.stripeDashboardPayoutUrl} target="_blank">{payout.stripePayoutId}</a>
                                </ReadOnlyField>

                                <ReadOnlyField label="Recipient">
                                    {getOwnerName()}
                                </ReadOnlyField>

                                <ReadOnlyField label="Amount">
                                    <Amount value={payout.amount} currency={payout.currency} {...formatAmountOptions}/>
                                </ReadOnlyField>

                                {
                                    payout.amountAdjustment !== 0 &&
                                    (
                                        <ReadOnlyField label="Amount adjustment">
                                            <Amount value={payout.amountAdjustment} currency={payout.currency} {...formatAmountOptions}/>
                                        </ReadOnlyField>
                                    )
                                }

                                <ReadOnlyField label="Status">
                                    {payout.status}
                                </ReadOnlyField>

                                {
                                    payout.stripeFailureMessage &&
                                    (
                                        <ReadOnlyField label="Stripe failure message">
                                            {payout.stripeFailureMessage}
                                        </ReadOnlyField>
                                    )
                                }

                                <ReadOnlyField label="Created time">
                                    <TimeAgo date={payout.createdTime}/>
                                </ReadOnlyField>

                                <ReadOnlyField label="Modified time">
                                    <TimeAgo date={payout.modifiedTime}/>
                                </ReadOnlyField>

                                <ReadOnlyField label="Arrival date">
                                    <ShortDate value={payout.arrivalDate} defaultString="Not yet arrived"/>
                                </ReadOnlyField>

                                <ReadOnlyField label="Number of purchases">
                                    {payout.numberOfPurchases}
                                </ReadOnlyField>

                                <ReadOnlyField label="Purchases">
                                    <Table size="small">
                                        <TableHead>
                                            <TableRow>
                                                <TableCell align="right">Purchase ID</TableCell>
                                                <TableCell>Created date</TableCell>
                                                <TableCell align="right">Amount</TableCell>
                                                <TableCell>Storage site</TableCell>
                                                <TableCell>Tenant</TableCell>
                                                <TableCell>Period</TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {
                                                payout.purchases.map(purchase => (
                                                    <TableRow key={purchase.id}>
                                                        <TableCell align="right"><Link to={routes.admin.purchase.replace(':purchaseId', purchase.id)}>{purchase.id}</Link></TableCell>
                                                        <TableCell className={classes.nowrap}><ShortDateTime value={purchase.createdTime}/></TableCell>
                                                        <TableCell align="right" className={classes.nowrap}><Amount value={getPurchaseAmountForRecipient(purchase)} currency={purchase.currency} {...formatAmountOptions}/></TableCell>
                                                        <TableCell><Link to={routes.storageSiteDetails.replace(':storageSiteId', purchase.booking.storageSiteId)} target="_blank">{purchase.booking.storageSiteTitle}</Link></TableCell>
                                                        <TableCell><Link to={routes.admin.actor.replace(':actorId', purchase.booking.tenantActorId)}>{purchase.booking.tenantName}</Link></TableCell>
                                                        <TableCell className={classes.nowrap}>{formatPurchasePeriod(purchase)}</TableCell>
                                                    </TableRow>
                                                ))
                                            }
                                        </TableBody>
                                    </Table>
                                </ReadOnlyField>

                            </AdminFormFields>

                        </AdminFormWrapper>

                        <AdminActionWrapper>
                            {
                                payout && payout.status === payoutStatuses.failed.key &&
                                (
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        onClick={handleRetryPayoutClick}
                                    >
                                        Retry payout
                                    </Button>
                                )
                            }
                        </AdminActionWrapper>

                    </AdminContentWrapper>
                )
            }
        </AdminPage>
    );
};

export default Payout;
