import React from 'react';
import strings from 'localization/strings';
import { useScrollToTop } from 'helpers/BrowserHelper';
import { replaceAll } from 'helpers/StringHelper';

import PageTitle from 'common/PageTitle';
import Container from 'common/Container';
import Text from 'common/Text';

const GeneralPolicyPage = () => {
    useScrollToTop();

    const html = replaceAll(strings.pages.generalPolicy, '<!-- policy-links -->', strings.pages.policyLinks);

    return (
        <Container withPageMargins>
            <PageTitle>{strings.generalPolicies}</PageTitle>
            <Text component="div" html={html} ignoreNewlines/>
        </Container>
    );
};

export default GeneralPolicyPage;
