import { RSAA } from 'redux-api-middleware';
import { createRsaaTypes, createHeaders } from '../actionHelpers';

export const ADMIN_STORAGE_GROUPS_FETCH_LOCK_CODES = 'ADMIN_STORAGE_GROUPS_FETCH_LOCK_CODES';

export const fetchStorageGroupLockCodes = storageGroupId => ({
    [RSAA]: {
        types: createRsaaTypes(ADMIN_STORAGE_GROUPS_FETCH_LOCK_CODES),
        endpoint: `/api/admin/storageGroups/${storageGroupId}/lockCodes`,
        headers: createHeaders(false),
        method: 'GET'
    }
});
