import { formatInTimeZone, zonedTimeToUtc } from 'date-fns-tz';
import { sv, fi } from 'date-fns/locale';
import { format, parse, parseISO } from 'date-fns';
import { replaceAll } from 'helpers/StringHelper';
import strings from 'localization/strings';

export const getLongDateFormat = appContext => appContext.dateTime.dateFns.longDateFormat;
export const getShortDateFormat = appContext => appContext.dateTime.dateFns.shortDateFormat;
export const getShortDateTimeFormat = appContext => appContext.dateTime.dateFns.shortDateTimeFormat;
export const getShortDateTimeSecondsFormat = appContext => appContext.dateTime.dateFns.shortDateTimeSecondsFormat;
export const getMonthAndYearDateFormat = appContext => appContext.dateTime.dateFns.monthAndYearDateFormat;
export const getShortMonthAndYearDateFormat = appContext => appContext.dateTime.dateFns.shortMonthAndYearDateFormat;
export const getDayAndMonthFormat = appContext => appContext.dateTime.dateFns.dayAndMonthFormat;
export const getYearDateFormat = (/* appContext */) => 'yyyy';
export const getDayOfWeekFormat = (/* appContext */) => 'EEEE';
export const getShortDayOfWeekFormat = (/* appContext */) => 'EEEEEE';

export const formatDateTime = (dateTime, formatStringOrFunc, appContext, useMarketTimeZone) => {
    if(!dateTime || !formatStringOrFunc) {
        return undefined;
    }
    const sanitizedDateTime = typeof dateTime === 'string'
        ? parseIsoWrapper(dateTime)
        : dateTime;

    if(!sanitizedDateTime) {
        return undefined;
    }

    const formatString = typeof formatStringOrFunc === 'string'
        ? formatStringOrFunc
        : formatStringOrFunc(appContext);

    if(useMarketTimeZone) {
        return formatInTimeZone(sanitizedDateTime, appContext.ianaTimeZone, formatString, { locale: getLocaleForAppContext(appContext) });
    }

    return format(sanitizedDateTime, formatString, { locale: getLocaleForAppContext(appContext) });
};

export const formatLongDate = (date, appContext) => formatDateTime(date, getLongDateFormat, appContext);

export const formatDayOfWeekLongDate = (date, appContext) => formatDateTime(date, `${getDayOfWeekFormat(appContext)} ${getLongDateFormat(appContext)}`, appContext);

export const formatShortDate = (date, appContext) => formatDateTime(date, getShortDateFormat, appContext);

export const formatShortDateTime = (dateTime, appContext) => formatDateTime(dateTime, getShortDateTimeFormat, appContext, true);

export const formatShortDateTimeSeconds = (dateTime, appContext) => formatDateTime(dateTime, getShortDateTimeSecondsFormat, appContext, true);

export const formatDateNumber = (date, appContext) => formatDateTime(date, 'd', appContext);

export const formatMonth = (date, appContext) => formatDateTime(date, getMonthAndYearDateFormat, appContext);

export const formatShortMonth = (date, appContext) => formatDateTime(date, getShortMonthAndYearDateFormat, appContext);

export const formatYear = (date, appContext) => formatDateTime(date, getYearDateFormat, appContext);

export const formatIsoDate = date => formatDateTime(date, 'yyyy-MM-dd');

export const formatUtcIsoDateTime = dateTime => {
    if (dateTime instanceof Date) {
        return dateTime.toISOString();
    }

    const parsedDateTime = parseIsoDate(dateTime);
    if (parsedDateTime instanceof Date) {
        return parsedDateTime.toISOString();
    }
    return undefined;
};

export const parseIsoDate = dateString => {
    if(dateString instanceof Date) {
        return dateString;
    }
    return dateString
        ? parseIsoWrapper(dateString)
        : undefined;
};

export const parseDate = (dateString, appContext) => {
    if(dateString instanceof Date) {
        return dateString;
    }
    return dateString
        ? parseDateWrapper(dateString, appContext)
        : undefined;
};

export const parseDateTime = (dateTimeString, appContext) => {
    if(dateTimeString instanceof Date) {
        return dateTimeString;
    }
    return dateTimeString
        ? parseDateTimeWrapper(dateTimeString, appContext)
        : undefined;
};

export const convertDateStringToIsoDateString = (dateString, appContext) => formatIsoDate(parseDate(dateString, appContext));

export const convertDateTimeStringToIsoDateTimeString = (dateString, appContext) => formatUtcIsoDateTime(parseDate(dateString, appContext));

const parseIsoWrapper = dateTime => {
    const parsed = parseISO(dateTime);
    return !parsed || isNaN(parsed)
        ? undefined
        : parsed;
};

const parseDateWrapper = (dateString, appContext) => parseWrapper(dateString, getShortDateFormat, appContext);

const parseDateTimeWrapper = (dateTimeString, appContext) => {
    const parsed =
        parseWrapper(dateTimeString, getShortDateFormat, appContext) ??
        parseWrapper(dateTimeString, getShortDateTimeFormat, appContext) ??
        parseWrapper(dateTimeString, getShortDateTimeSecondsFormat, appContext);
    return parsed
        ? zonedTimeToUtc(parsed, appContext.ianaTimeZone)
        : undefined;
};

const parseWrapper = (dateTime, formatter, appContext) => {
    // convert periods to commas for times
    const atoms = dateTime.split(' ');
    if(atoms[1]) {
        atoms[1] = formatTime(atoms[1]);
    }
    const sanitizedDateTimeString = atoms.join(' ');
    const parsed = parse(sanitizedDateTimeString, formatter(appContext), new Date(), { locale: getLocaleForAppContext(appContext) });
    return !parsed || isNaN(parsed)
        ? undefined
        : parsed;
};

export const formatTime = (timeString /* , appContext */) => {
    if(!timeString) {
        return '';
    }
    const sanitizedTimeString = replaceAll(timeString, '.', ':');
    const atoms = sanitizedTimeString.split(':');
    if(atoms.length === 1 && atoms[0].length <= 2) {
        atoms.push('00');
    }
    return atoms.join(':');
};

const getLocaleForAppContext = appContext => {
    switch(appContext?.language) {
        case 'fi': return fi;
        default: return sv;
    }
};

export const relativeDateFormat = (dateTime, appContext) => {
    const date = new Date(dateTime);
    const day = date.getDate();
    const month = date.toLocaleString(getLocaleForAppContext(appContext), { month: 'short' });
    const year = date.getFullYear();
    const hours = date.getHours();
    const minutes = date.getMinutes();
    const today = new Date();
    if (day === today.getDate()) {
        return `${strings.relativeDateFormat.today} ${hours}:${minutes}`;
    }
    if (day === today.getDate() - 1) {
        return `${strings.relativeDateFormat.yesterday} ${hours}:${minutes}`;
    }
    if (year !== today.getFullYear()) {
        return `${day} ${month} ${year} ${hours}:${minutes}`;
    }
    return `${day} ${month} ${hours}:${minutes}`;
};
