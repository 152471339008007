import React from 'react';
import PropTypes from 'prop-types';
import strings from 'localization/strings';
import { makeStyles } from 'styles/util';
import storageGroupCategories from 'enums/storageGroupCategories';

import Box from '@material-ui/core/Box';
import Text from 'common/Text';

const useStyles = makeStyles(({ colors }) => ({
    tenantBookingSpecificInformation: {
        margin: '2em 0',
        borderColor: colors.primaryColor,
        borderWidth: '2px'
    },
    title: {
        fontSize: '16px',
        fontWeight: 'bold',
        margin: '1em 0 0.5em 0'
    },
    body: {
        marginBottom: '1em'
    }
}));

const TenantBookingSpecificInformation = ({ purchase }) => {
    const classes = useStyles();

    return (
        <Box className={classes.tenantBookingSpecificInformation}>
            {
                purchase.booking.storageGroup.storageSite.descriptionGivenAfterPurchase &&
                (
                    <>
                        <hr />
                        <Box className={classes.title}>
                            {strings.paymentDocuments.descriptionGivenAfterPurchasePaymentDocumentTitle}
                        </Box>
                        <Box className={classes.body}>
                            <Text text={purchase.booking.storageGroup.storageSite.descriptionGivenAfterPurchase} />
                        </Box>
                    </>
                )
            }
            {
                purchase.booking.storageGroup.category === storageGroupCategories.vehicle.key &&
                (
                    <>
                        <hr />
                        <Box className={classes.title}>
                            {strings.paymentDocuments.vehicleInformationTitle}
                        </Box>
                        <Box className={classes.body}>
                            {strings.paymentDocuments.vehicleInformationBody}
                        </Box>
                    </>
                )
            }
        </Box>
    );
};

TenantBookingSpecificInformation.propTypes = {
    purchase: PropTypes.object.isRequired
};

export default TenantBookingSpecificInformation;
