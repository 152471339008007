import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from 'styles/util';

import Box from '@material-ui/core/Box';

const useStyles = makeStyles(() => ({
    label: {
        fontWeight: 'bold'
    },
    value: {

    }
}));

const InformationItem = ({ label, value }) => {
    const classes = useStyles();

    return (
        <Box>
            <Box className={classes.label}>{label}</Box>
            <Box className={classes.value}>{value}</Box>
        </Box>
    );
};

InformationItem.propTypes = {
    label: PropTypes.any,
    value: PropTypes.any
};

export default InformationItem;
