import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { getStorageSite } from 'actions/admin/storageSites';
import { handleResponse } from 'actions/actionHelpers';
import Button from '@material-ui/core/Button';
import Loader from 'common/Loader';
import PropTypes from 'prop-types';

import AdminTable from 'components/adminTable/AdminTable';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Typography from '@material-ui/core/Typography';

const ViewCountDialog = ({ storageSite, open, onCancel }) => {
    const dispatch = useDispatch();
    const [storageSiteWithViewCounts, setStorageSiteWithViewCounts] = useState(undefined);
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
       loadStorageSiteWithViewCounts();
    }, []);

    const loadStorageSiteWithViewCounts = () => {
        setIsLoading(true);
        dispatch(getStorageSite(storageSite.id))
            .then(handleResponse(
               response => {
                   setStorageSiteWithViewCounts(response.payload);
                   setIsLoading(false);
               },
                () => setIsLoading(false)
            ));
    };

    const columns = [
        {
            title: 'Month',
            id: 'month',
            align: 'left',
            render: numberOfViews => numberOfViews.timePeriod
        },
        {
            title: 'Views',
            id: 'views',
            align: 'right',
            render: numberOfViews => numberOfViews.count
        }
    ];

    return (
        <Dialog fullWidth maxWidth="xs" open={open} onClose={onCancel}>
            <DialogTitle disableTypography>
                <Typography variant="h5">
                    {storageSite.title}: views per month
                </Typography>
            </DialogTitle>
            <DialogContent>
                {
                    isLoading && <Loader />
                }
                {
                    !isLoading &&
                    (
                        <AdminTable
                            items={storageSiteWithViewCounts.viewCounts}
                            columns={columns}
                        />
                    )
                }
            </DialogContent>
            <DialogActions>
                <Button
                    type="button"
                    variant="outlined"
                    color="primary"
                    onClick={onCancel}
                >
                    Close
                </Button>
            </DialogActions>
        </Dialog>
    );
};

ViewCountDialog.propTypes = {
    storageSite: PropTypes.object.isRequired,
    open: PropTypes.bool.isRequired,
    onCancel: PropTypes.func.isRequired
};

export default ViewCountDialog;
