import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from 'styles/util';
import { replaceAll } from 'helpers/StringHelper';
import Autolinker from 'autolinker';

import Box from '@material-ui/core/Box';

const useStyles = makeStyles(() => ({
    line: {
        display: 'block'
    },
    emptyLine: {
        height: '0.75em'
    }
}));

const Text = ({ text, html, className, linkify = true, component = 'span', inline = false, ignoreNewlines = false }) => {
    const classes = useStyles();

    const isHtml = !!html;
    let data = isHtml ? html : text;

    if(!data) {
        return null;
    }

    const addLinks = item => linkify
        ? Autolinker.link(item)
        : item;

    const classNames = [];
    if(!inline) {
        classNames.push(classes.line);
    }
    if(className) {
        classNames.push(className);
    }

    const enhancedClassName = classNames.join(' ');

    if(ignoreNewlines) {
        data = replaceAll(data, '\n', '');
    }

    return data.split('\n').map((item, i) =>
        isHtml
            ? <Box key={i} className={enhancedClassName + (item ? '' : ' ' + classes.emptyLine)} component={component} dangerouslySetInnerHTML={{ __html: addLinks(item) }}/>
            : <Box key={i} className={enhancedClassName + (item ? '' : ' ' + classes.emptyLine)} component={component}>{item}</Box>
    );
};

Text.propTypes = {
    text: PropTypes.string,
    html: PropTypes.string,
    className: PropTypes.string,
    linkify: PropTypes.bool,
    component: PropTypes.string,
    inline: PropTypes.bool,
    ignoreNewlines: PropTypes.bool
};

export default Text;
