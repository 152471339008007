import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router';
import { useLocation, useHistory } from 'react-router-dom';
import { handleResponse } from 'actions/actionHelpers';
import { getShortDateFormat, formatIsoDate } from 'helpers/DateHelper';
import { required, mustBeNumber } from 'form/validation';
import { getTakeover, createTakeover, updateTakeover } from 'actions/admin/takeovers';
import takeoverStatuses from 'enums/takeoverStatuses';
import routes from 'routes';
import queryString from 'query-string';

import Loader from 'common/Loader';
import AdminPage from '../../presentational/AdminPage';
import AdminFormWrapper from '../../presentational/AdminFormWrapper';
import AdminFormFields from '../../presentational/AdminFormFields';
import ReadOnlyField from '../../presentational/ReadOnlyField';
import AdminFormButtons from '../../presentational/AdminFormButtons';
import Form from 'form/Form';
import Button from '@material-ui/core/Button';
import { TextField, showErrorOnBlur } from 'mui-rff';
import MenuItem from '@material-ui/core/MenuItem';
import DatePicker from 'form/DatePicker';
import { Link } from 'react-router-dom';
import { startOfDay } from 'date-fns';

const EditTakeover = () => {
    const dispatch = useDispatch();
    const params = useParams();
    const history = useHistory();
    const location = useLocation();
    const { storageSiteId } = queryString.parse(location.search);

    const [isSubmitting, setIsSubmitting] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
    const [takeover, setTakeover] = useState(undefined);
    const [initialValues, setInitialValues] = useState(undefined);

    useEffect(() => {
        if(params.takeoverId) {
            dispatch(getTakeover(params.takeoverId))
                .then(handleResponse(
                    response => {
                        setTakeover(response.payload);
                        setInitialValues({
                            storageSiteId: response.payload.storageSiteId,
                            startDate: response.payload.startDate,
                            status: response.payload.status
                        });
                        setIsLoading(false);
                    },
                    () => setIsLoading(false)
                ));
        } else {
            setTakeover({});
            setInitialValues({
                storageSiteId,
                startDate: startOfDay(new Date())
            });
            setIsLoading(false);
        }
    }, []);

    const handleFormSubmit = formValues => {
        if(params.takeoverId) {
            handleUpdateTakeover(formValues);
        } else {
            handleCreateTakeover(formValues);
        }
    };

    const handleCreateTakeover = formValues => {
        setIsSubmitting(true);
        const request = {
            storageSiteId: formValues.storageSiteId,
            startDate: formatIsoDate(formValues.startDate)
        };
        dispatch(createTakeover(request))
            .then(handleResponse(
                response => {
                    history.push(routes.admin.takeover.replace(':takeoverId', response.payload.id));
                    return { snackbar: { message: 'Takeover was successfully created.' } };
                },
                () => setIsSubmitting(false)
            ));
    };

    const handleUpdateTakeover = formValues => {
        setIsSubmitting(true);
        const request = {
            startDate: formValues.startDate,
            status: formValues.status,
            tenants: formValues.tenants
        };
        dispatch(updateTakeover(params.takeoverId, request))
            .then(handleResponse(
                () => {
                    history.push(routes.admin.takeover.replace(':takeoverId', params.takeoverId));
                    return { snackbar: { message: 'Takeover was successfully updated.' } };
                },
                () => setIsSubmitting(false)
            ));
    };

    const cancelUrl = params.takeoverId
        ? routes.admin.takeover.replace(':takeoverId', params.takeoverId)
        : routes.admin.takeovers;

    const title = params.takeoverId
        ? 'Edit takeover: ' + (takeover ? takeover.storageSite.title : 'Loading...')
        : 'Create new takeover';

    return (
        <AdminPage title={title}>
            {
                isLoading && <Loader />
            }
            {
                initialValues &&
                (
                    <AdminFormWrapper isSubmitting={isSubmitting}>
                        <Form
                            initialValues={initialValues}
                            onSubmit={handleFormSubmit}
                        >
                            {({ handleSubmit }) => (
                                <form onSubmit={handleSubmit}>
                                    <AdminFormFields>
                                        {
                                            params.takeoverId &&
                                            (
                                                <>
                                                    <ReadOnlyField label="Takeover ID">
                                                        {takeover.id}
                                                    </ReadOnlyField>

                                                    <ReadOnlyField label="Storage site">
                                                        {takeover.storageSite.title}
                                                    </ReadOnlyField>

                                                    <ReadOnlyField label="Owner actor">
                                                        <Link to={routes.admin.actor.replace(':actorId', takeover.ownerActorId)}>{takeover.ownerActorName}</Link>
                                                    </ReadOnlyField>
                                                </>
                                            )
                                        }

                                        {
                                            !params.takeoverId &&
                                            (
                                                <TextField
                                                    name="storageSiteId"
                                                    label="Storage site ID"
                                                    type="text"
                                                    variant="outlined"
                                                    disabled={!!storageSiteId}
                                                    fieldProps={{ validate: mustBeNumber }}
                                                />
                                            )
                                        }

                                        <DatePicker
                                            name="startDate"
                                            label="Start date"
                                            formatFunc={getShortDateFormat}
                                            inputVariant="outlined"
                                            showError={showErrorOnBlur}
                                            fieldProps={{ validate: required }}
                                        />

                                        {
                                            params.takeoverId &&
                                            (
                                                <TextField
                                                    select
                                                    name="status"
                                                    variant="outlined"
                                                >
                                                    {
                                                        Object.values(takeoverStatuses).map(o => (
                                                            <MenuItem key={o.key} value={o.key}>
                                                                {o.key}
                                                            </MenuItem>
                                                        ))
                                                    }
                                                </TextField>
                                            )
                                        }
                                    </AdminFormFields>

                                    <AdminFormButtons>
                                        <Button
                                            type="submit"
                                            variant="contained"
                                            color="primary"
                                        >
                                            Save
                                        </Button>
                                        <Button
                                            type="button"
                                            variant="outlined"
                                            color="primary"
                                            component={Link}
                                            to={cancelUrl}
                                        >
                                            Cancel
                                        </Button>
                                    </AdminFormButtons>
                                </form>
                            )}
                        </Form>
                    </AdminFormWrapper>
                )
            }
        </AdminPage>
    );
};

export default EditTakeover;
