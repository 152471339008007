import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useAppContext } from 'context/AppContext';
import { useForm, useField } from 'react-final-form';
import strings from 'localization/strings';
import { formatStorageGroupArea } from './FormatHelper';
import { getStorageGroupTitle, formatLockConfigurations } from 'helpers/StorageSiteHelper';
import rentalTypes from 'enums/rentalTypes';

import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import LockConfigurationEditor from './LockConfigurationEditor';
import { Checkboxes } from 'mui-rff';
import Alert from '@material-ui/lab/Alert';
import MarginBox from 'common/MarginBox';
import LoadingWrapper from 'form/LoadingWrapper';
import LinkButton from 'common/LinkButton';

const LockConfigurationDialog = ({ name: parentName, storageGroups, parakeyDomainId, open, isSaving, onOk, onCancel }) => {
    const { appContext } = useAppContext();
    const form = useForm();
    const getName = suffix => suffix ? `${parentName}.${suffix}` : parentName;
    const getValue = suffix => useField(getName(suffix)).input.value;
    const invalid = useField(getName()).meta.invalid;

    const getTitle = storageGroup => {
        const storageTitle = storageGroup.rentalType === rentalTypes.customArea.key
            ? formatStorageGroupArea(storageGroup, appContext)
            : getStorageGroupTitle(storageGroup, appContext, { forceShowSize: true });

        const lockConfigurationString = formatLockConfigurations(storageGroup);
        return `${storageTitle}: ${lockConfigurationString ?? '-'}`;
    };

    const value = getValue();

    const handleOk = () => {
        onOk(value);
    };

    const handleSelectAllStorageGroups = () => {
        form.change(getName('storageGroupIds'), storageGroups.map(o => o.id));
    };

    const getLockConfigurationArrays = () => {
        const lockConfigurationsDictionary = {};
        storageGroups.forEach(sg => {
            if(value.storageGroupIds.indexOf(sg.id) !== -1) {
                const key = JSON.stringify(sg.lockConfigurations);
                lockConfigurationsDictionary[key] = JSON.parse(key);
            }
        });
        return Object.values(lockConfigurationsDictionary);
    };

    useEffect(() => {
        form.change(getName('lockConfigurations'), getLockConfigurationArrays()[0]);
    }, [value.storageGroupIds]);

    useEffect(() => {
        form.change(getName('storageGroupIds'), []);
    }, [open]);

    return (
        <Dialog fullWidth maxWidth="md" open={open} onClose={onCancel}>
            <DialogTitle disableTypography>
                <Typography variant="h5">
                    {strings.editLockConfigurations}
                </Typography>
            </DialogTitle>
            <DialogContent>
                <LoadingWrapper
                    isSaving={isSaving}
                >
                    <Typography variant="body1" gutterBottom>
                        {strings.lockConfigurationEditorInformation}
                    </Typography>
                    <MarginBox>
                        <LinkButton onClick={handleSelectAllStorageGroups}>
                            {strings.selectAll}
                        </LinkButton>
                    </MarginBox>
                    <Checkboxes
                        name={getName('storageGroupIds')}
                        data={storageGroups.map(o => ({
                            label: getTitle(o),
                            value: o.id
                        }))}
                    />
                    {
                        getLockConfigurationArrays().length > 1 &&
                        (
                            <MarginBox top={2} bottom={2}>
                                <Alert severity="warning">
                                    {strings.multipleLockConfigurationsSelected}
                                </Alert>
                            </MarginBox>
                        )
                    }
                    {
                        value.storageGroupIds.length > 0 &&
                        (
                            <LockConfigurationEditor
                                name={getName('lockConfigurations')}
                                parakeyDomainId={parakeyDomainId}
                            />
                        )
                    }
                </LoadingWrapper>
            </DialogContent>
            <DialogActions>
                <Button
                    variant="contained"
                    color="primary"
                    disabled={value.storageGroupIds.length === 0 || isSaving || invalid}
                    onClick={handleOk}
                >
                    {strings.ok}
                </Button>
                <Button
                    variant="outlined"
                    color="primary"
                    disabled={isSaving}
                    onClick={onCancel}
                >
                    {strings.cancel}
                </Button>
            </DialogActions>
        </Dialog>
    );
};

LockConfigurationDialog.propTypes = {
    name: PropTypes.string.isRequired,
    storageGroups: PropTypes.array.isRequired,
    parakeyDomainId: PropTypes.string,
    open: PropTypes.bool.isRequired,
    isSaving: PropTypes.bool.isRequired,
    onOk: PropTypes.func.isRequired,
    onCancel: PropTypes.func.isRequired
};

export default LockConfigurationDialog;
