import React from 'react';
import { useAppContext } from 'context/AppContext';
import { useLocation } from 'react-router-dom';
import { makeStyles } from 'styles/util';
import { getPrintDisplay } from 'helpers/PrintHelper';
import strings from 'localization/strings';

import { Link } from 'react-router-dom';
import AppBar from '@material-ui/core/AppBar';
import Box from '@material-ui/core/Box';
import MuiContainer from '@material-ui/core/Container';
import Toolbar from '@material-ui/core/Toolbar';
import StorageNavigation from 'components/header/StorageNavigation';
import OfficeNavigation from 'components/header/OfficeNavigation';
import VisibleFor from 'common/VisibleFor';

const Header = () => {
    const useStyles = makeStyles(({ theme }) => ({
        header: {
            lineHeight: 0,
            '@media print': {
                display: getPrintDisplay(location)
            },
            [theme.breakpoints.down('xs')]: {
                height: '60px'
            }
        },
        toolbar: {
            padding: 0,
                height: '100%'
        },
        container: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            height: '100%',
            [theme.breakpoints.down('sm')]: {
                paddingLeft: '30px',
                paddingRight: '30px'
            }
        }
    }),
    ({ theme }) => ({
        storage: {
            headerLogo: {
                height: '40px'
            },
            header: {
                height: '80px',
                paddingRight: '0px !important'
            },
            container: {
                paddingRight: 0
            }
        },
        office: {
            container: {
                height: '80px',
                [theme.breakpoints.down('xs')]: {
                    height: '60px'
                }
            },
            headerLogo: {
                height: '40px',
                [theme.breakpoints.down('xs')]: {
                    height: '30px'
                }
            }
        }
    }));
    const { appContext } = useAppContext();
    const location = useLocation();
    const classes = useStyles();

    return (
        <AppBar
            position="fixed"
            className={classes.header}
            elevation={0}
        >
            <Toolbar className={classes.toolbar}>
                <MuiContainer className={classes.container} maxWidth={false}>
                    <Box>
                        <Link to="/">
                            <img className={classes.headerLogo} src={appContext.images.headerLogo} alt={strings.companyName}/>
                        </Link>
                    </Box>
                    <VisibleFor branch="storage">
                        <StorageNavigation/>
                    </VisibleFor>
                    <VisibleFor branch="office">
                        <OfficeNavigation/>
                    </VisibleFor>
                </MuiContainer>
            </Toolbar>
        </AppBar>
    );
};

export default Header;
