export const defaultCompare = (a, b) => chainableDefaultCompare(a, b) ?? 0;

export const chainableDefaultCompare = (a, b) => {
    if(a < b) {
        return -1;
    }
    if(a > b) {
        return 1;
    }
    return undefined;
};
