import React from 'react';
import PropTypes from 'prop-types';
import { useAppContext } from 'context/AppContext';
import { formatShortDateTime } from 'helpers/DateHelper';
import ReactTimeAgo from 'react-timeago';

const TimeAgo = ({ date, ...rest }) => {
    const { appContext } = useAppContext();
    return <ReactTimeAgo date={date} title={formatShortDateTime(date, appContext)} {...rest} />;
};

TimeAgo.propTypes = {
    date: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.instanceOf(Date)
    ]).isRequired
};

export default TimeAgo;
