import React from 'react';
import PropTypes from 'prop-types';
import { required } from 'form/validation';
import strings from 'localization/strings';

import { TextField, showErrorOnBlur } from 'mui-rff';

const BusinessUserPasswordForm = ({ fieldNamePrefix }) => {
    const getName = suffix => suffix
        ? `${fieldNamePrefix}.${suffix}`
        : fieldNamePrefix;

    return (
        <>
            <TextField
                name={getName('userEmail')}
                label={strings.email}
                variant="outlined"
                disabled
            />
            <TextField
                name={getName('userPassword')}
                type="password"
                label={strings.password}
                variant="outlined"
                showError={showErrorOnBlur}
                required
                fieldProps={{ validate: required }}
            />
        </>
    );
};

BusinessUserPasswordForm.propTypes = {
    fieldNamePrefix: PropTypes.string
};

export default BusinessUserPasswordForm;
