import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from 'styles/util';
import { useDispatch } from 'react-redux';
import { useField } from 'react-final-form';
import { handleResponse } from 'actions/actionHelpers';
import strings from 'localization/strings';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { getOpeningTimeSpanRules, createDefaultOpeningTimeSpanRule } from 'helpers/OpeningTimeSpanHelper';
import { calculateOpeningTimeSpansForOpeningTimeSpanRules } from 'actions/openingTimeSpans';
import { formatIsoDate, formatDayOfWeekLongDate } from 'helpers/DateHelper';
import { add } from 'date-fns';
import openingTimeSpanActions from 'enums/openingTimeSpanActions';

import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import { FieldArray } from 'react-final-form-arrays';
import OpeningTimeSpanRuleEditor from './OpeningTimeSpanRuleEditor';
import ButtonContainer from 'common/ButtonContainer';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import CircularProgress from '@material-ui/core/CircularProgress';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import DateDisplay from 'common/DateDisplay';
import TimeSpan from 'common/TimeSpan';

const useStyles = makeStyles(({ theme }) => ({
    date: {
        display: 'inline-block',
        width: '18em',
        paddingRight: theme.spacing(2)
    },
    timeSpan: {
        display: 'inline-block',
        width: '8em',
        paddingRight: theme.spacing(2)
    },
    restrictedToAction: {
        display: 'inline-block'
    }
}));

const OpeningTimeSpanRuleList = ({ fieldNamePrefix }) => {
    const classes = useStyles();
    const dispatch = useDispatch();

    const [ isCalculatingPreview, setIsCalculatingPreview ] = useState(false);
    const [ openingTimeSpansForPreview, setOpeningTimeSpansForPreview ] = useState(undefined);
    const [ openingTimeSpanPreviewDialogOpen, setOpeningTimeSpanPreviewDialogOpen ] = useState(false);

    const getName = suffix => suffix ? `${fieldNamePrefix}.${suffix}` : fieldNamePrefix;

    const error = useField(getName()).meta.error;

    const buttonFullWidth = !useMediaQuery(theme => theme.breakpoints.up('md'));

    const handleAddRule = fields => {
        const newRule = createDefaultOpeningTimeSpanRule();
        fields.push(newRule);
    };

    const handlePreviewRules = rules => {
        const now = new Date();
        const request = {
            rules: getOpeningTimeSpanRules(rules),
            from: formatIsoDate(now),
            to: formatIsoDate(add(now, { years: 1 }))
        };
        setIsCalculatingPreview(true);

        dispatch(calculateOpeningTimeSpansForOpeningTimeSpanRules(request))
            .then(handleResponse(
                response => {
                    setOpeningTimeSpansForPreview(response.payload);
                    setIsCalculatingPreview(false);
                    setOpeningTimeSpanPreviewDialogOpen(true);
                },
                () => {
                    setIsCalculatingPreview(false);
                }
            ));
    };

    const handleRemoveRule = (fields, index) => {
        fields.remove(index);
    };

    const handleOpeningTimeSpanPreviewDialogClose = () => {
        setOpeningTimeSpanPreviewDialogOpen(false);
        setOpeningTimeSpansForPreview(undefined);
    };

    return (
        <>
            <FieldArray name={getName()}>
                {({ fields }) => (
                    <>
                        {
                            fields.map((name, index) => (
                                <OpeningTimeSpanRuleEditor
                                    key={name}
                                    label={index + 1}
                                    fieldNamePrefix={name}
                                    onRemove={() => handleRemoveRule(fields, index)}
                                />
                            ))
                        }
                        <ButtonContainer>
                            <Button
                                variant="contained"
                                color="secondary"
                                onClick={() => handleAddRule(fields)}
                                fullWidth={buttonFullWidth}
                            >
                                {strings.addOpeningTimeSpanRule}
                            </Button>
                            <Button
                                variant="contained"
                                color="secondary"
                                onClick={() => handlePreviewRules(fields.value)}
                                disabled={!!error || isCalculatingPreview}
                                fullWidth={buttonFullWidth}
                            >
                                {strings.previewOpeningTimeSpanRules}
                            </Button>
                            {
                                isCalculatingPreview &&
                                (
                                    <CircularProgress
                                        size={16}
                                        color="primary"
                                    />
                                )
                            }
                        </ButtonContainer>
                    </>
                )}
            </FieldArray>

            <Dialog maxWidth="md" open={openingTimeSpanPreviewDialogOpen} onClose={handleOpeningTimeSpanPreviewDialogClose}>
                <DialogTitle disableTypography>
                <Typography variant="h5">
                        {strings.openingTimeSpanPreviewDialogTitle}
                    </Typography>
                </DialogTitle>
                <DialogContent>
                    <Typography variant="body1" gutterBottom>
                        {strings.openingTimeSpanPreviewDialogDescription}
                    </Typography>
                    <List disablePadding dense>
                        {
                            (openingTimeSpansForPreview || []).map(openingTimeSpan => (
                                <ListItem key={openingTimeSpan.date}>
                                    <ListItemText>
                                        <Box className={classes.date}>
                                            <DateDisplay value={openingTimeSpan.date} formatter={formatDayOfWeekLongDate}/>
                                        </Box>
                                        <Box className={classes.timeSpan}>
                                            <TimeSpan value={openingTimeSpan} />
                                        </Box>
                                        <Box className={classes.restrictedToAction}>
                                            {
                                                openingTimeSpan.restrictedToAction && openingTimeSpanActions[openingTimeSpan.restrictedToAction].lowercaseTitle
                                            }
                                        </Box>
                                    </ListItemText>
                                </ListItem>
                            ))
                        }
                    </List>
                </DialogContent>
                <DialogActions>
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={handleOpeningTimeSpanPreviewDialogClose}
                    >
                        {strings.ok}
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
};

OpeningTimeSpanRuleList.propTypes = {
    fieldNamePrefix: PropTypes.string.isRequired
};

export default OpeningTimeSpanRuleList;
