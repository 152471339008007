import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from 'styles/util';

const useStyles = makeStyles(({ theme }) => ({
    detail: {
        display: 'flex',
        flexDirection: 'column',
        [theme.breakpoints.up('md')]: {
            flexDirection: 'row'
        },
        '& > :first-child': {
            marginBottom: theme.spacing(3),
            [theme.breakpoints.up('md')]: {
                marginBottom: 0,
                marginRight: theme.spacing(3)
            },
            flexGrow: 1
        },
        '& > :last-child': {
            flexGrow: 1,
            [theme.breakpoints.up('md')]: {
                minWidth: '240px'
            },
        }
    },
    listing: {
        display: 'flex',
        flexDirection: 'column',
        '& > :first-child': {
            marginBottom: theme.spacing(3),
        }
    }
}));

const AdminContentWrapper = ({ children, type }) => {
    const classes = useStyles();
    const className = type === 'detail' ? classes.detail : classes.listing;

    return (
        <div className={className}>
            {children}
        </div>
    );
};

AdminContentWrapper.propTypes = {
    children: PropTypes.oneOfType([
        PropTypes.arrayOf(PropTypes.node),
        PropTypes.node
    ]),
    type: PropTypes.string
};

export default AdminContentWrapper;
