const geographicalLocationTypes = {
    city: {},
    region: {},
    municipality: {}
};

Object.keys(geographicalLocationTypes).forEach(key => {
    geographicalLocationTypes[key].key = key;
});

export default geographicalLocationTypes;
