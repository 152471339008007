import { RSAA } from 'redux-api-middleware';
import { createRsaaTypes, createHeaders } from '../actionHelpers';
import queryString from 'query-string';

export const ADMIN_BOOKINGS_SEARCH = 'ADMIN_BOOKINGS_SEARCH';
export const ADMIN_BOOKINGS_SEARCH_SUCCESS = 'ADMIN_BOOKINGS_SEARCH_SUCCESS';
export const ADMIN_BOOKINGS_SEARCH_FAILURE = 'ADMIN_BOOKINGS_SEARCH_FAILURE';

export const ADMIN_BOOKINGS_SET_SEARCH_PARAMETERS = 'ADMIN_BOOKINGS_SET_SEARCH_PARAMETERS';

export const ADMIN_BOOKINGS_GET = 'ADMIN_BOOKINGS_GET';

export const ADMIN_BOOKINGS_CREATE_NOTE = 'ADMIN_BOOKINGS_CREATE_NOTE';

export const ADMIN_BOOKINGS_UPDATE = 'ADMIN_BOOKINGS_UPDATE';

export const ADMIN_BOOKINGS_CREATE_UPCOMING_PURCHASE_PREVIEW = 'ADMIN_BOOKINGS_CREATE_UPCOMING_PURCHASE_PREVIEW';

export const ADMIN_BOOKINGS_SYNCHRONIZE_PARAKEY_ACCESS = 'ADMIN_BOOKINGS_SYNCHRONIZE_PARAKEY_ACCESS';

export const searchBookings = searchParameters => ({
    [RSAA]: {
        types: createRsaaTypes(ADMIN_BOOKINGS_SEARCH),
        endpoint: `/api/admin/bookings?${queryString.stringify(searchParameters)}`,
        headers: createHeaders(false),
        method: 'GET'
    }
});

export const getBooking = (bookingId) => ({
    [RSAA]: {
        types: createRsaaTypes(ADMIN_BOOKINGS_GET),
        endpoint: `/api/admin/bookings/${bookingId}`,
        headers: createHeaders(false),
        method: 'GET'
    }
});

export const createBookingNote = (bookingId, request) => ({
    [RSAA]: {
        types: createRsaaTypes(ADMIN_BOOKINGS_CREATE_NOTE),
        endpoint: `/api/admin/bookings/${bookingId}/notes`,
        headers: createHeaders(true),
        method: 'POST',
        body: JSON.stringify(request)
    }
});

export const updateBooking = (bookingId, request) => ({
    [RSAA]: {
        types: createRsaaTypes(ADMIN_BOOKINGS_UPDATE),
        endpoint: `/api/admin/bookings/${bookingId}`,
        headers: createHeaders(true),
        method: 'PUT',
        body: JSON.stringify(request)
    }
});

export const createUpcomingPurchasePreview = (bookingId, request) => ({
    [RSAA]: {
        types: createRsaaTypes(ADMIN_BOOKINGS_CREATE_UPCOMING_PURCHASE_PREVIEW),
        endpoint: `/api/admin/bookings/${bookingId}/upcomingPurchasePreview`,
        headers: createHeaders(true),
        method: 'POST',
        body: JSON.stringify(request)
    }
});

export const synchronizeParakeyAccess = bookingId => ({
    [RSAA]: {
        types: createRsaaTypes(ADMIN_BOOKINGS_SYNCHRONIZE_PARAKEY_ACCESS),
        endpoint: `/api/admin/bookings/${bookingId}/synchronizeParakeyAccess`,
        headers: createHeaders(false),
        method: 'PUT'
    }
});

export const setSearchParameters = searchParameters => ({
    type: ADMIN_BOOKINGS_SET_SEARCH_PARAMETERS,
    searchParameters
});
