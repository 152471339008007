import React, { useState } from 'react';
import PropTypes from 'prop-types';
import strings from 'localization/strings';
import purchaseTypes from 'enums/purchaseTypes';
import purchaseStatuses from 'enums/purchaseStatuses';
import paymentProviders from 'enums/paymentProviders';
import subscriptionStatuses from 'enums/subscriptionStatuses';
import { formatTimePeriod } from 'helpers/StringHelper';
import { makeStyles } from 'styles/util';
import { useDispatch } from 'react-redux';
import { handleResponse } from 'actions/actionHelpers';
import { fetchPurchase, downloadPurchaseInvoice } from 'actions/purchases';
import { downloadUrl } from 'helpers/BrowserHelper';
import { getAvailableAndCurrentPaymentProviders } from 'logic/paymentLogic';

import Box from '@material-ui/core/Box';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Amount from 'common/Amount';
import CircularProgress from '@material-ui/core/CircularProgress';
import ShortDate from 'common/ShortDate';
import LinkButton from 'common/LinkButton';

const useStyles = makeStyles(({ theme }) => ({
    actions: {
        display: 'flex',
        gap: theme.spacing(2)
    },
    loadingVisible: {
        visibility: 'visible'
    },
    loadingHidden: {
        visibility: 'hidden'
    },
    listItem: {
        padding: 0
    },
    secondaryList: {
        margin: theme.spacing(1, 0, 0, 0),
        padding: 0,
        '& li': {
            listStyleType: 'none',
        }
    }
}));

const Purchase = ({ type, booking, purchase, isOwner = false, includeDueDate = false, onPay, onDelete, onPaymentDocumentNotYetGenerated }) => {
    const classes = useStyles();
    const dispatch = useDispatch();

    const [isLoading, setIsLoading] = useState(false);

    const handleDownloadPaymentDocument = () => {
        const url = getPaymentDocumentUrl(purchase);
        if(!url) {
            setIsLoading(true);
            dispatch(fetchPurchase(purchase.id, booking.token))
                .then(handleResponse(
                    response => {
                        setIsLoading(false);
                        const fetchedUrl = getPaymentDocumentUrl(response.payload);
                        if(fetchedUrl) {
                            downloadUrl(fetchedUrl);
                        } else {
                            onPaymentDocumentNotYetGenerated();
                        }
                    },
                    () => {
                        setIsLoading(false);
                    }
                ));
        }
    };

    const getPaymentDocumentUrl = p => isOwner ? p.ownerPaymentDocumentUrl : p.tenantPaymentDocumentUrl;

    const handleDownloadInvoice = () => {
        setIsLoading(true);
        dispatch(downloadPurchaseInvoice(purchase.id, booking.token))
            .then(handleResponse(
                () => {
                    setIsLoading(false);
                },
                () => {
                    setIsLoading(false);
                }
            ));
    };

    const isInitialPurchase = purchase.type === purchaseTypes.periodBookingPurchase.key || purchase.type === purchaseTypes.subscriptionBookingInitialPurchase.key;
    const isSubscriptionPurchase = purchase.type === purchaseTypes.subscriptionBookingRecurringPurchase.key;
    const isEndSubscriptionPurchase = purchase.type === purchaseTypes.endSubscriptionBookingPurchase.key;
    const isPostInvoicePurchase = purchase.type === purchaseTypes.postInvoice.key;
    let title;
    const subscriptionActive = booking.subscriptionBooking && booking.subscriptionStatus === subscriptionStatuses.active.key;
    const subscriptionEnded = booking.subscriptionBooking && booking.subscriptionStatus === subscriptionStatuses.ended.key;
    const isPaid = purchase.status === purchaseStatuses.paid.key;
    const isUnpaid = purchase.status === purchaseStatuses.unpaid.key && purchase.amount > 0;
    const isRefunded = purchase.status === purchaseStatuses.refunded.key;
    const isBillecta = purchase.paymentProvider === paymentProviders.billecta.key;
    const availablePaymentProviders = getAvailableAndCurrentPaymentProviders(booking);
    const stripeAvailable = availablePaymentProviders.includes(paymentProviders.stripe.key);
    const renderDownloadInvoiceButton = isUnpaid && isBillecta && !subscriptionEnded;
    const renderPayWithCardButton = !isOwner && isUnpaid && isBillecta && stripeAvailable && !subscriptionEnded;
    const renderPayButton = !isOwner && isUnpaid && !isBillecta && (!subscriptionEnded || isPostInvoicePurchase);
    const canRetrySubscriptionPayment = booking.subscriptionBooking && purchase.status === purchaseStatuses.failed.key && subscriptionActive;
    let renderDeleteButton = false;
    let renderReceiptButton = isPaid || isRefunded;
    let receiptButtonText = strings.receipt;

    if (isRefunded) {
        title = strings.refundPayment;
    } else if (isInitialPurchase) {
        title = booking.subscriptionBooking
            ? strings.subscriptionInitialPayment
            : strings.periodPayment;
    } else if (isSubscriptionPurchase) {
        title = <>{strings.subscriptionBookingPayment}, {formatTimePeriod(purchase.periodStartDate, purchase.periodEndDate)}</>;
    } else if (isEndSubscriptionPurchase) {
        title = strings.endSubscriptionPayment;
    } else if (isPostInvoicePurchase) {
        title = strings.postInvoice;
    }

    if (isPostInvoicePurchase) {
        renderReceiptButton = true;
        renderDeleteButton = isOwner && !isPaid && !isRefunded;
        receiptButtonText = strings.paymentDocuments.basis;
    }

    const renderActions = renderReceiptButton || renderDeleteButton || renderPayButton || renderDownloadInvoiceButton || renderPayWithCardButton || canRetrySubscriptionPayment;

    const actions = renderActions
        ? (
            <Box className={classes.actions}>
                {
                    renderReceiptButton &&
                    (
                        <LinkButton
                            size="small"
                            target="_blank"
                            component="a"
                            href={getPaymentDocumentUrl(purchase)}
                            onClick={handleDownloadPaymentDocument}
                        >
                            {receiptButtonText}
                        </LinkButton>
                    )
                }

                {
                    renderDeleteButton &&
                    (
                        <LinkButton
                            size="small"
                            onClick={() => onDelete(purchase)}
                        >
                            {strings.postInvoiceDelete}
                        </LinkButton>
                    )
                }
                {
                    renderPayButton &&
                    (
                        <LinkButton
                            size="small"
                            onClick={() => onPay(purchase)}
                        >
                            {strings.pay}
                        </LinkButton>
                    )
                }
                {
                    renderDownloadInvoiceButton &&
                    (
                        <LinkButton
                            size="small"
                            onClick={handleDownloadInvoice}
                        >
                            {strings.invoice}
                        </LinkButton>
                    )
                }
                {
                    renderPayWithCardButton &&
                    (
                        <LinkButton
                            size="small"
                            onClick={() => onPay(purchase, paymentProviders.stripe.key)}
                        >
                            {strings.payWithCard}
                        </LinkButton>
                    )
                }
                {
                    canRetrySubscriptionPayment &&
                    (
                        <LinkButton
                            size="small"
                            onClick={() => onPay(purchase)}
                        >
                            {strings.retrySubscriptionPayment}
                        </LinkButton>
                    )
                }
                <CircularProgress
                    className={isLoading ? classes.loadingVisible : classes.loadingHidden}
                    size={16}
                    color="primary"
                />
            </Box>
        )
        : undefined;

    return (
        <>
            {
                type === 'table' &&
                (
                    <TableRow>
                        <TableCell>
                            {purchase.id}
                        </TableCell>
                        <TableCell>
                            {title}
                        </TableCell>
                        <TableCell>
                            <ShortDate value={purchase.createdTime}/>
                        </TableCell>
                        {
                            includeDueDate &&
                            (
                                <TableCell>
                                    <ShortDate value={purchase.dueDate}/>
                                </TableCell>
                            )
                        }
                        <TableCell>
                            <Amount value={purchase.amount} currency={purchase.currency}/>
                        </TableCell>
                        <TableCell>
                            {purchase.amount > 0 && purchaseStatuses[purchase.status].title}
                        </TableCell>
                        <TableCell>
                            {actions}
                        </TableCell>
                    </TableRow>
                )
            }

            {
                type === 'list' &&
                (
                    <ListItem alignItems="flex-start" divider className={classes.listItem}>
                        <ListItemText
                            primary={
                                <>
                                    {title}
                                    {' '}&ndash;{' '}
                                    <Amount value={purchase.amount} currency={purchase.currency}/>
                                    {' '}&ndash;{' '}
                                    {purchaseStatuses[purchase.status].title}
                                </>
                            }
                            secondary={
                                <ul className={classes.secondaryList}>
                                    <li>
                                        {strings.invoiceDate}: <ShortDate value={purchase.createdTime}/>
                                    </li>
                                    {
                                        includeDueDate &&
                                        (
                                            <li>
                                                {strings.dueDate}: <ShortDate value={purchase.dueDate}/>
                                            </li>
                                        )
                                    }
                                    {
                                        actions &&
                                        (
                                            <li>
                                                {actions}
                                            </li>
                                        )
                                    }
                                </ul>
                            }
                        />
                    </ListItem>
                )
            }
        </>
    );
};

Purchase.propTypes = {
    type: PropTypes.string.isRequired,
    booking: PropTypes.object.isRequired,
    purchase: PropTypes.object.isRequired,
    isOwner: PropTypes.bool,
    includeDueDate: PropTypes.bool,
    onPay: PropTypes.func,
    onDelete: PropTypes.func,
    onPaymentDocumentNotYetGenerated: PropTypes.func
};

export default Purchase;
