import { createTheme } from '@material-ui/core/styles';
import { breakpoints } from './breakpoints';

export const createBaseMuiTheme = ({ colors, fonts, styles, materialUiLocale }) => createTheme({
    breakpoints: {
        values: breakpoints
    },
    palette: {
        primary: {
            main: colors.primaryColor
        },
        secondary: {
            main: colors.secondaryColor
        },
        text: {
            primary: colors.textColor,
            secondary: colors.textColor,
            dark: colors.textColorDark
        }
    },
    typography: {
        fontFamily: fonts.regular,
        bodyMedium: {
            fontSize: '1.25rem'
        }
    },
    themeContentWidth: {
        xxxs: '450px',
        xxs: '550px',
        xs: '650px',
        sm: '768px',
        md: '980px',
        lg: '1070px',
        xl: '1440px',
        none: 'none'
    },
    overrides: {
        MuiBadge: {
            colorPrimary: {
                color: colors.white
            }
        },
        MuiFormLabel: {
            asterisk: {
                color: colors.asterisk,
                opacity: 0.67
            }
        },
        MuiTextField: {
            root: {
                margin: '12px 0'
            },
        },
        MuiTypography: {
            root: {
                fontWeight: 'normal',
                fontStyle: 'normal',
                fontStretch: 'normal',
                letterSpacing: 'normal'
            }
        },
        MuiInput: {
            underline: {
                '&:before': {
                    borderBottomColor: colors.primaryColor
                },
                '&:hover:not(.Mui-disabled):before': {
                    borderBottomColor: colors.primaryColor
                }
            }
        },
        MuiCheckbox: {
            colorSecondary: {
                '&.Mui-checked': {
                    color: colors.primaryColor
                }
            }
        },
        MuiRadio: {
            colorSecondary: {
                '&.Mui-checked': {
                    color: colors.primaryColor
                }
            }
        },
        MuiButton: {
            root: {
                textTransform: 'none',
                borderRadius: '8px',
                textAlign: 'center'
            },
            contained: {
                color: `${colors.white} !important`,
                '& .MuiCircularProgress-root': {
                    color: colors.white
                }
            }
        },
        MuiTab: {
            root: {
                textTransform: 'none'
            }
        },
        MuiSnackbarContent: {
            root: {
                backgroundColor: colors.primaryColor,
                boxShadow: '0px -1px 5px 1px rgba(0,0,0,0.35)'
            }
        },
        MuiOutlinedInput: {
            root: {
                background: colors.white,
                borderRadius: `${styles.inputBorderRadius}px`
            }
        },
        MuiDialog: {
            paper: {
                padding: '30px',
                borderRadius: '8px'
            },
            paperFullWidth: {
                width: 'calc(100% - 32px)'
            },
            paperScrollPaper: {
                maxHeight: 'calc(100% - 32px)'
            }
        },
        MuiPickersModal: {
            dialogRoot: {
                padding: '0 !important'
            }
        },
        MuiDialogTitle: {
            root: {
                '& h5': {
                    fontSize: '22px',
                    fontFamily: fonts.black,
                    lineHeight: '28.6px',
                    letterSpacing: '-0.44px'
                },
                padding: 0
            }
        },
        MuiDialogContent: {
            root: {
                margin: 0,
                padding: '25px 0'
            }
        },
        MuiDialogActions: {
            root: {
                padding: 0,
                justifyContent: 'flex-end',
                [`@media only screen and (max-width: ${breakpoints.sm}px)`]: {
                    flexDirection: 'column',
                    alignItems: 'stretch',
                    gap: '16px'
                }
            },
            spacing: {
                [`@media only screen and (max-width: ${breakpoints.sm}px)`]: {
                    '& > :not(:first-child)': {
                        margin: 0
                    }
                }
            }
        },
        MuiTooltip: {
            tooltip: {
                fontSize: '0.8rem'
            }
        },
        MuiLink: {
            button: {
                fontSize: '1rem'
            }
        },
        MuiAlert: {
            root: {
                borderRadius: '8px',
                padding: '20px 20px 40px 20px'
            },
            message: {
                flexGrow: 1
            },
            standardSuccess: {
                backgroundColor: colors.success
            },
            standardInfo: {
                backgroundColor: colors.infoBackground,
                '& .MuiAlert-icon svg': {
                    color: colors.info
                }
            },
            standardError: {
                backgroundColor: colors.errorBackground,
                '& .MuiAlert-icon svg': {
                    color: colors.error
                }
            }
        },

        MuiCssBaseline: {
            '@global': {
                body: {
                    color: colors.textColor,
                    backgroundColor: colors.white,
                    height: '100%',
                    position: 'relative',
                    fontSize: '16px',
                    fontFamily: fonts.regular,
                    fontWeight: 'normal',
                    fontStyle: 'normal',
                    fontStretch: 'normal',
                    letterSpacing: 'normal'
                },

                /* Marked text background color */
                '::selection': {
                    background: colors.primaryColor,
                    color: colors.white
                },
                '::-moz-selection': {
                    background: colors.primaryColor,
                    color: colors.white
                },

                /* Placeholder text color */
                '::placeholder': {
                    color: colors.textColorDisabled,
                    opacity: 1 /* Firefox */
                },

                ':-ms-input-placeholder': {
                    color: colors.textColorDisabled
                },

                '::-ms-input-placeholder': {
                    color: colors.textColorDisabled
                },

                'textarea, button, select': {
                    '-webkit-tap-highlight-color': 'transparent',
                    color: colors.textColor
                },

                'html a': {
                    color: colors.primaryColor,
                },

                /* Fonts */
                'html *': {
                    fontFamily: fonts.regular
                },

                input: {
                    fontFamily: `${fonts.regular} !important`
                },

                'ul li': {
                    lineHeight: '1.8rem'
                },

                p: {
                    fontWeight: 'normal',
                    fontStyle: 'normal',
                    fontStretch: 'normal',
                    letterSpacing: 'normal',
                    fontSize: '16px',
                    lineHeight: '20px'
                }
            }
        }
    }
}, materialUiLocale);
