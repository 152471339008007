import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from 'styles/util';

import Box from '@material-ui/core/Box';

const ButtonContainer = ({ children, align = 'left' }) => {
    const flexAlign = align === 'right' ? 'flex-end' : 'flex-start';
    const useStyles = makeStyles(({ theme }) => ({
        buttonContainer: {
            marginTop: theme.spacing(1),
            display: 'flex',
            justifyContent: flexAlign,
            flexWrap: 'wrap',
            gap: theme.spacing(1)
        }
    }));
    const classes = useStyles();

    return (
        <Box className={classes.buttonContainer}>
            {children}
        </Box>
    );
};

ButtonContainer.propTypes = {
    children: PropTypes.node.isRequired,
    align: PropTypes.string
};

export default ButtonContainer;
