import React from 'react';
import PropTypes from 'prop-types';
import { useAppContext } from 'context/AppContext';
import { useSelector } from 'react-redux';
import { useField } from 'react-final-form';
import { makeStyles } from 'styles/util';
import storageGroupCategories from 'enums/storageGroupCategories';
import storageGroupStatuses from 'enums/storageGroupStatuses';
import storageTypes from 'enums/storageTypes';
import { requiredIf, mustBeGreaterThanOrEqualToIf } from 'form/validation';
import strings from 'localization/strings';
import { getCustomAreaExists, calculateTotalArea, showOptionToTax, showExclusiveVatSuffix } from 'logic/editStorageSiteLogic';

import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import StorageGroupList from './StorageGroupList';
import Separator from 'common/Separator';
import StorageTypeIconAndText from 'common/StorageTypeIconAndText';
import MenuItem from '@material-ui/core/MenuItem';
import { TextField, Checkboxes, showErrorOnBlur } from 'mui-rff';
import Text from 'common/Text';

const useStyles = makeStyles(({ theme }) => ({
    container: {
        marginBottom: theme.spacing(4)
    },
    categoryContainer: {
        margin: theme.spacing(4, 0)
    },
    firstSeparator: {
        marginTop: `${theme.spacing(4)}px !important`
    },
    hidden: {
        display: 'none'
    }
}));

const EditStep1Multiple = ({ showStepNumber = true, storageSiteExists = false, onSaveStorageGroup }) => {
    const classes = useStyles();
    const { appContext } = useAppContext();
    const authenticationContext = useSelector(state => state.authentication.context);
    const { selectedActor } = authenticationContext;

    const fieldNamePrefix = 'steps[1].multiple';

    const getName = suffix => suffix ? `${fieldNamePrefix}.${suffix}` : fieldNamePrefix;
    const getValue = suffix => useField(getName(suffix)).input.value;

    const value = getValue();
    const storageSiteFormData = useField('').input.value;

    const showStorageGroup = sg => sg.status !== storageGroupStatuses.deleted.key || value.showDeletedStorageGroups;
    const storageTypeSelectorVisible = value.buildingsAndLand?.storageGroups.filter(showStorageGroup).length > 0;
    const categoryKeys = Object.keys(storageGroupCategories).filter(key => value[key] && value[key].storageGroups.filter(showStorageGroup).length > 0);
    const optionToTaxVisible = showOptionToTax(selectedActor, categoryKeys);
    const exclusiveVatSuffixVisible = showExclusiveVatSuffix(selectedActor, categoryKeys, value.optionToTax);

    const categories = appContext.storageGroupCategories.map(category => ({
        storageGroups: value[category.key].storageGroups,
        ...category
    }));

    const areaTextboxVisible = categories.filter(category => getCustomAreaExists(value[category.key].storageGroups.filter(showStorageGroup))).length > 0;

    let totalArea = 0;
    categories.forEach(category => {
        totalArea += calculateTotalArea(value[category.key].storageGroups.filter(showStorageGroup));
    });

    let validationKeyCount = 0;

    return (
        <Box className={classes.container}>
            <Typography variant="h5" display="block" gutterBottom>
                { showStepNumber && '2. ' }
                {strings.storageTypeTitle}
            </Typography>

            <Typography variant="body1" display="block" gutterBottom>
                {strings.storageTypeDescription}
            </Typography>

            <Separator className={classes.firstSeparator} />

            {
                categories.map(category => (
                    <React.Fragment key={category.key}>
                        <Box key={category.key} className={classes.categoryContainer}>
                            <Typography variant="h6" display="block">
                                {category.title}
                            </Typography>

                            <Typography variant="body1" display="block" gutterBottom>
                                {category.editSubtitle}
                            </Typography>

                            <StorageGroupList
                                storageSiteExists={storageSiteExists}
                                storageSiteFormData={storageSiteFormData}
                                category={category}
                                fieldNamePrefix={`steps[1].multiple.${category.key}`}
                                showExclusiveVatSuffix={exclusiveVatSuffixVisible}
                                showDeleted={value.showDeletedStorageGroups}
                                editable
                                onSaveStorageGroup={onSaveStorageGroup}
                            />
                        </Box>
                    </React.Fragment>
                ))
            }

            <TextField
                select
                name={getName('storageType')}
                label={strings.storageType}
                variant="outlined"
                showError={showErrorOnBlur}
                className={storageTypeSelectorVisible ? undefined : classes.hidden}
                required={storageTypeSelectorVisible}
                fieldProps={{ validate: requiredIf(storageTypeSelectorVisible)}}
                // we need a key prop, see https://codesandbox.io/s/changing-field-level-validators-zc8ei?fontsize=14&file=/src/index.js:1722-1771
                key={storageTypeSelectorVisible ? validationKeyCount++ : -(validationKeyCount++)}
            >
                {
                    Object.values(storageTypes).map(storageType => (
                        <MenuItem key={storageType.key} value={storageType.key}>
                            <StorageTypeIconAndText storageType={storageType} />
                        </MenuItem>
                    ))
                }
            </TextField>

            <TextField
                name={getName('area')}
                label={strings.storageSiteArea}
                variant="outlined"
                showError={showErrorOnBlur}
                helperText={strings.storageSiteAreaHelperText}
                className={areaTextboxVisible ? undefined : classes.hidden}
                required={areaTextboxVisible}
                fieldProps={{ validate: mustBeGreaterThanOrEqualToIf(areaTextboxVisible, totalArea)}}
                // we need a key prop, see https://codesandbox.io/s/changing-field-level-validators-zc8ei?fontsize=14&file=/src/index.js:1722-1771
                key={areaTextboxVisible ? validationKeyCount++ : -(validationKeyCount++)}
            />

            {
                optionToTaxVisible && <Separator spacing={2} />
            }

            <Box className={optionToTaxVisible ? undefined : classes.hidden}>
                <Checkboxes
                    name={getName('optionToTax')}
                    label={strings.optionToTax}
                    data={
                        { label: strings.optionToTaxDescription, value: true }
                    }
                    helperText={<Text html={strings.optionToTaxHelperText}/>}
                    key={optionToTaxVisible ? validationKeyCount++ : -(validationKeyCount++)}
                />
            </Box>
        </Box>
    );
};

EditStep1Multiple.propTypes = {
    showStepNumber: PropTypes.bool,
    storageSiteExists: PropTypes.bool,
    onSaveStorageGroup: PropTypes.func
};

export default EditStep1Multiple;
