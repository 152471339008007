const bookingItemTypes = {
    storage: {},
    service: {},
    requestedService: {},
    discount: {}
};

Object.keys(bookingItemTypes).forEach(key => {
    bookingItemTypes[key].key = key;
});

export default bookingItemTypes;
