import React from 'react';
import PropTypes from 'prop-types';
import { useAppContext } from 'context/AppContext';
import sv from 'react-timeago/lib/language-strings/sv';
import fi from 'react-timeago/lib/language-strings/fi';
import buildFormatter from 'react-timeago/lib/formatters/buildFormatter';

import TimeAgo from 'common/TimeAgo';

const getLocalizedStrings = appContext => {
    switch(appContext.language) {
        case 'fi': return fi;
        default: return sv;
    }
};

const LocalizedTimeAgo = ({ date, ...otherProps }) => {
    const { appContext } = useAppContext();

    const formatter = buildFormatter(getLocalizedStrings(appContext));

    return (
        <TimeAgo
            date={date}
            formatter={formatter}
            {...otherProps}
        />
    );
};

LocalizedTimeAgo.propTypes = {
    date: PropTypes.string.isRequired
};

export default LocalizedTimeAgo;
