import React from 'react';
import PropTypes from 'prop-types';
import strings from 'localization/strings';
import { sanitizeRegistrationNumber } from 'helpers/StringHelper';
import goodsTypes from 'enums/goodsTypes';

import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Form from 'form/Form';
import { TextField } from 'mui-rff';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import MenuItem from '@material-ui/core/MenuItem';

const ChangeBookingDialog = ({ booking, open, onOk, onCancel }) => {
    const initialValues = {
        goodsType: booking.goodsType,
        registrationNumber: booking.registrationNumber
    };

    const goodsTypeSelectListItems = (booking.goodsType ? [] : [{ title: '' }])
        .concat(Object.values(goodsTypes));

    const handleFormSubmit = values => {
        const sanitizedValues = { ...values };
        sanitizedValues.registrationNumber = sanitizeRegistrationNumber(sanitizedValues.registrationNumber);
        onOk(sanitizedValues);
    };

    return (
        <Dialog fullWidth maxWidth="md" open={open} onClose={onCancel}>
            <Form
                initialValues={initialValues}
                onSubmit={handleFormSubmit}
            >
                {({ handleSubmit }) => (
                    <form onSubmit={handleSubmit}>
                        <DialogTitle disableTypography>
                            <Typography variant="h5">
                                {strings.changeBookingDialogTitle}
                            </Typography>
                        </DialogTitle>
                        <DialogContent>
                            <TextField
                                select
                                name="goodsType"
                                label={strings.vehicleType}
                                variant="outlined"
                            >
                                {
                                    goodsTypeSelectListItems.map((goodsType, i) => (
                                        <MenuItem key={(goodsType.key ?? i)} value={goodsType.key}>
                                            {goodsType.title}
                                        </MenuItem>
                                    ))
                                }
                            </TextField>
                            <TextField
                                name="registrationNumber"
                                label={strings.registrationNumber}
                                placeholder={strings.registrationNumberPlaceholder}
                                maxLength={16}
                                variant="outlined"
                                autoFocus
                            />
                        </DialogContent>
                        <DialogActions>
                            <Button
                                type="submit"
                                variant="contained"
                                color="primary"
                            >
                                {strings.save}
                            </Button>
                            <Button
                                variant="outlined"
                                color="primary"
                                onClick={onCancel}
                            >
                                {strings.cancel}
                            </Button>
                        </DialogActions>
                    </form>
                )}
            </Form>
        </Dialog>
    );
};

ChangeBookingDialog.propTypes = {
    booking: PropTypes.object.isRequired,
    open: PropTypes.bool.isRequired,
    onOk: PropTypes.func.isRequired,
    onCancel: PropTypes.func.isRequired
};

export default ChangeBookingDialog;
