import allGoodsTypes from 'enums/goodsTypes';
import allStorageTypes from 'enums/storageTypes';
import allStorageOrderBys from 'enums/storageOrderBys';
import allStorageSiteFacilities from 'enums/storageSiteFacilities';
import allOrganizationTypes from 'enums/organizationTypes';
import allPageSizes from 'enums/pageSizes';
import { convertToNumber } from 'helpers/StringHelper';
import additionalFilterTypes from 'enums/additionalFilterTypes';

export const formFieldKeys = {
    categories: 'categories',
    storageTypes: 'storageTypes',
    maxPricePerStorage: 'maxPricePerStorage',
    maxPricePerAreaUnit: 'maxPricePerAreaUnit',
    private: 'private',
    business: 'business',
    goodsTypes: 'goodsTypes',
    orderBy: 'orderBy',
    minArea: 'minArea',
    maxArea: 'maxArea',
    page: 'page',
    pageSize: 'pageSize'
};
Object.keys(allStorageSiteFacilities).forEach(key => {
    formFieldKeys[key] = key;
});

export const getOrderByItems = () => {
    return Object.values(allStorageOrderBys)
        .map(orderBy => ({
            value: orderBy.key,
            title: orderBy.title
        }));
};

export const getPageSizes = () => {
    return Object.values(allPageSizes)
        .map(size => ({
            value: size.value,
            title: size.title
        }));
};

export const getParams = (values, location) => {
    const toNumber = (s, numberOfDecimals = 0) => {
        const parsed = convertToNumber(s);
        const factor = Math.pow(10, numberOfDecimals);
        return isNaN(parsed)
            ? undefined
            : Math.round(factor * parsed) / factor;
    };

    const lat = values.lat || toNumber((location ?? {}).lat, 5);
    const lng = values.lng || toNumber((location ?? {}).lng, 5);

    const params = {
        goodsTypes: values.goodsTypes.map(key => allGoodsTypes[key] ? key : undefined),
        storageTypes: values.storageTypes.map(key => allStorageTypes[key] ? key : undefined),
        maxPricePerStorage: toNumber(values.maxPricePerStorage),
        maxPricePerAreaUnit: toNumber(values.maxPricePerAreaUnit),
        locationText: values.locationText,
        lat,
        lng,
        radius: lat && lng
            ? toNumber(values.radius, 0)
            : undefined,
        facilities: [],
        ownerTypes: [
            values.private ? allOrganizationTypes.private.key : undefined,
            values.business ? allOrganizationTypes.business.key : undefined,
        ],
        orderBy: values.orderBy ?? allStorageOrderBys.weightedDistance.key,
        minArea: toNumber(values.minArea),
        maxArea: toNumber(values.maxArea),
        page: toNumber(values.page),
        pageSize: values.pageSize ?? allPageSizes.twenty.value
    };

    Object.values(allStorageSiteFacilities)
        .filter(facility => values[facility.key])
        .forEach(facility => {
            params.facilities.push(facility.key);
        });

    return params;
};

export const getActiveFilters = (landingPage, appContext, values) => {
    let sizeFiltersCount = 0;
    let priceFiltersCount = 0;
    let additionalFiltersCount = 0;
    const sizeFilters = {
        minArea: true,
        maxArea: true,
    };
    const priceFilters = {
        maxPricePerAreaUnit: true,
        maxPricePerStorage: true,
    };
    const additionalFilters = {};

    // Map additionalFilterTypes to additionalFilters, so they correspond to formFieldKeys
    Object.entries(additionalFilterTypes(landingPage, appContext)).map(([key, filter]) => {
        if (filter.show) {
            if (Array.isArray(filter.values[0])) {
                additionalFilters[key] = true;
            } else {
                filter.values.map((value) => {
                    additionalFilters[value.key] = true;
                });
            }
        }
    });

    // Check if current form values has any values, if so filter is 'active'
    Object.entries(values).map(([key, value]) => {
        if (sizeFilters[key]) {
            sizeFiltersCount = value ? sizeFiltersCount + 1 : sizeFiltersCount;
        } else if (priceFilters[key]) {
            priceFiltersCount = value ? priceFiltersCount + 1 : sizeFiltersCount;
        } else if (additionalFilters[key]) {
            if (Array.isArray(value)) {
                additionalFiltersCount = additionalFiltersCount + value.length;
            } else {
                additionalFiltersCount = value ? additionalFiltersCount + 1 : additionalFiltersCount;
            }
        }
    });

    return {
        sizeFiltersCount,
        priceFiltersCount,
        additionalFiltersCount,
        totalFiltersCount: sizeFiltersCount + priceFiltersCount + additionalFiltersCount
    };
};

export const createInitialValues = searchParams => {
    const p = searchParams || {};

    const initialValues = {
        locationText: searchParams.locationText,
        lat: searchParams.lat,
        lng: searchParams.lng,
        radius: searchParams.lat && searchParams.lng
            ? 50
            : undefined,
        goodsTypes: (p.goodsTypes ?? [])
            .map(goodsTypeKey => Object.values(allGoodsTypes).find(o => o.key === goodsTypeKey))
            .filter(goodsType => goodsType)
            .map(goodsType => goodsType.key),
        storageTypes: (p.storageTypes ?? [])
            .map(storageTypeKey => Object.values(allStorageTypes).find(o => o.key === storageTypeKey))
            .filter(storageType => storageType)
            .map(storageType => storageType.key),
        maxPricePerStorage: p.maxPricePerStorage ?? '',
        maxPricePerAreaUnit: p.maxPricePerAreaUnit ?? '',
        private: (p.ownerTypes ?? []).filter(e => e === allOrganizationTypes.private.key).length > 0,
        business: (p.ownerTypes ?? []).filter(e => e === allOrganizationTypes.business.key).length > 0,
        orderBy: p.orderBy ?? allStorageOrderBys.weightedDistance.key,
        minArea: p.minArea ?? '',
        maxArea: p.maxArea ?? '',
        page: p.page ?? 1,
        pageSize: p.pageSize ?? allPageSizes.twenty.value,
    };

    Object.values(allStorageSiteFacilities)
        .forEach(facility => {
            initialValues[facility.key] = (p.facilities ?? []).filter(e => e === facility.key).length > 0;
        });

    return initialValues;
};

