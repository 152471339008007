import { RSAA } from 'redux-api-middleware';
import { createRsaaTypes, createHeaders } from '../actionHelpers';

export const ACCOUNT_STORAGE_SITES_SECTION_FETCH_STORAGE_SITES = 'ACCOUNT_STORAGE_SITES_SECTION_FETCH_STORAGE_SITES';
export const ACCOUNT_STORAGE_SITES_SECTION_FETCH_STORAGE_SITES_SUCCESS = 'ACCOUNT_STORAGE_SITES_SECTION_FETCH_STORAGE_SITES_SUCCESS';
export const ACCOUNT_STORAGE_SITES_SECTION_FETCH_STORAGE_SITES_FAILURE = 'ACCOUNT_STORAGE_SITES_SECTION_FETCH_STORAGE_SITES_FAILURE';

export const ACCOUNT_STORAGE_SITES_SECTION_CLEAR_STORAGE_SITES = 'ACCOUNT_STORAGE_SITES_SECTION_CLEAR_STORAGE_SITES';

export const fetchStorageSites = ownerActorId => ({
    [RSAA]: {
        types: createRsaaTypes(ACCOUNT_STORAGE_SITES_SECTION_FETCH_STORAGE_SITES),
        endpoint: `/api/actors/${ownerActorId}/storageSites`,
        headers: createHeaders(false),
        method: 'GET'
    }
});

export const clearStorageSites = () => {
    return {
        type: ACCOUNT_STORAGE_SITES_SECTION_CLEAR_STORAGE_SITES
    };
};
