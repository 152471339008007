// TODO: make all SVGs styleable with CSS; we need Webpack 5 for that

import storageLogo from 'assets/images/storage/top-logo.svg';
import storageNegativeLogo from 'assets/images/storage/white-logo.svg';
import storageShortLogo from 'assets/images/storage/short-logo.svg';
import storageAppleTouchIcon from 'assets/images/storage/apple-touch-icon.png';
import storageFavicon32x32 from 'assets/images/storage/favicon-32x32.png';
import storageMaskIcon from 'assets/images/storage/safari-pinned-tab.svg';
import storageMailIcon from 'assets/images/storage/mail.svg';
import storageForOwners from 'assets/images/storage/for_owners.jpg';
import storageOwnerLogos from 'assets/images/storage/owner-logos.png';
import storagePartnerLogos from 'assets/images/storage/partner-logos.png';
import storagePlaceIcon from 'assets/icons/storage/icon-location-filled.svg';
import storageIrregularHexagon from 'assets/icons/storage/irregular-hexagon.svg';
import storageSecureIcon from 'assets/icons/storage/icon-usp-secure.svg';
import storageSustainableIcon from 'assets/icons/storage/icon-usp-sustainable.svg';
import storageFlexibleStorageIcon from 'assets/icons/storage/icon-usp-flexible-storage.svg';
import storageClippedBgLight from 'assets/icons/storage/clipped-bg-light.svg';
import storageClippedBgLightSm from 'assets/icons/storage/clipped-bg-light-sm.svg';
import storageClippedBgLightXs from 'assets/icons/storage/clipped-bg-light-xs.svg';
import storageIconTelephone from 'assets/icons/storage/icon-telephone.svg';
import storageIconEmail from 'assets/icons/storage/icon-email.svg';
import storageNowRentingSign from 'assets/images/storage/now-renting-sign.svg';
import storageSupportPerson from 'assets/images/storage/support-person.svg';
import storageCurvedArrow from 'assets/icons/storage/curved-arrow.svg';
import storageLogin from 'assets/images/storage/login.svg';

import officeTopLogo from 'assets/images/office/top-logo.svg';
import officeFooterLogo from 'assets/images/office/footer-logo.svg';
import officeShortLogo from 'assets/images/office/short-logo.svg';
import officeAppleTouchIcon from 'assets/images/office/apple-touch-icon.png';
import officeFavicon32x32 from 'assets/images/office/favicon-32x32.png';
import officeMaskIcon from 'assets/images/office/safari-pinned-tab.svg';
import officeMailIcon from 'assets/images/office/mail.svg';
import officeForOwners from 'assets/images/office/for_owners.jpg';
import officePlaceIcon from 'assets/icons/office/icon-location-filled.svg';
import officeIrregularHexagon from 'assets/icons/office/irregular-hexagon.svg';
import officeSecureIcon from 'assets/icons/office/icon-usp-secure.svg';
import officeSustainableIcon from 'assets/icons/office/icon-usp-sustainable.svg';
import officeFlexibleStorageIcon from 'assets/icons/office/icon-usp-flexible-storage.svg';
import officeClippedBgLight from 'assets/icons/office/clipped-bg-light.svg';
import officeClippedBgLightSm from 'assets/icons/office/clipped-bg-light-sm.svg';
import officeClippedBgLightXs from 'assets/icons/office/clipped-bg-light-xs.svg';
import officeIconTelephone from 'assets/icons/office/icon-telephone.svg';
import officeIconEmail from 'assets/icons/office/icon-email.svg';
import officeNowRentingSign from 'assets/images/office/now-renting-sign.svg';
import officeSupportPerson from 'assets/images/office/support-person.svg';
import officeCurvedArrow from 'assets/icons/office/curved-arrow.svg';
import officeLogin from 'assets/images/office/login.svg';

import bankid from 'assets/images/sweden/bankid.svg';

export const storageImages = {
    topLogo: storageLogo,
    headerLogo: storageLogo,
    footerLogo: storageNegativeLogo,
    shortLogo: storageShortLogo,
    appleTouchIcon: storageAppleTouchIcon,
    favicon32x32: storageFavicon32x32,
    maskIcon: storageMaskIcon,
    mailIcon: storageMailIcon,
    forOwners: storageForOwners,
    ownerLogos: storageOwnerLogos,
    partnerLogos: storagePartnerLogos,
    placeIcon: storagePlaceIcon,
    irregularHexagon: storageIrregularHexagon,
    secureIcon: storageSecureIcon,
    sustainableIcon: storageSustainableIcon,
    flexibleStorageIcon: storageFlexibleStorageIcon,
    clippedBgLight: storageClippedBgLight,
    clippedBgLightSm: storageClippedBgLightSm,
    clippedBgLightXs: storageClippedBgLightXs,
    iconTelephone: storageIconTelephone,
    iconEmail: storageIconEmail,
    nowRentingSign: storageNowRentingSign,
    supportPerson: storageSupportPerson,
    curvedArrow: storageCurvedArrow,
    login: storageLogin
};

export const officeImages = {
    topLogo: officeTopLogo,
    headerLogo: officeTopLogo,
    footerLogo: officeFooterLogo,
    shortLogo: officeShortLogo,
    appleTouchIcon: officeAppleTouchIcon,
    favicon32x32: officeFavicon32x32,
    maskIcon: officeMaskIcon,
    mailIcon: officeMailIcon,
    forOwners: officeForOwners,
    placeIcon: officePlaceIcon,
    irregularHexagon: officeIrregularHexagon,
    secureIcon: officeSecureIcon,
    sustainableIcon: officeSustainableIcon,
    flexibleStorageIcon: officeFlexibleStorageIcon,
    clippedBgLight: officeClippedBgLight,
    clippedBgLightSm: officeClippedBgLightSm,
    clippedBgLightXs: officeClippedBgLightXs,
    iconTelephone: officeIconTelephone,
    iconEmail: officeIconEmail,
    nowRentingSign: officeNowRentingSign,
    supportPerson: officeSupportPerson,
    curvedArrow: officeCurvedArrow,
    login: officeLogin
};

export const swedenImages = {
    eid: bankid
};

export const finlandImages = {
};
