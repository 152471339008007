import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useAppContext } from 'context/AppContext';
import { makeStyles } from 'styles/util';
import { handleResponse } from 'actions/actionHelpers';
import strings from 'localization/strings';
import originalReportDefinitions from './reportDefinitions';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { createReportDefinitions, createTableItems, createTableColumns, createTableFooterCells } from 'helpers/ReportHelper';
import { fetchStorageSites } from 'actions/account/storageSitesSection';

import Container from 'common/Container';
import Button from '@material-ui/core/Button';
import ButtonContainer from 'common/ButtonContainer';
import PageTitle from 'common/PageTitle';
import Form from 'form/Form';
import ShadowBox from 'common/ShadowBox';
import LoadingWrapper from 'form/LoadingWrapper';
import ReportInputFields from 'common/ReportInputFields';
import AdminTable from 'components/adminTable/AdminTable';
import Loader from 'common/Loader';

const useStyles = makeStyles(() => ({
    inputFields: {
        '@media print': {
            display: 'none'
        }
    },
    reportTable: {
        '@media print': {
            zoom: 0.67,
            '-webkit-print-color-adjust': 'exact !important',
            printColorAdjust: 'exact !important'
        }
    }
}));

const ReportsSection = () => {
    const classes = useStyles();
    const isMobile = !useMediaQuery(theme => theme.breakpoints.up('md'));

    const dispatch = useDispatch();
    const authenticationContext = useSelector(state => state.authentication.context);
    const selectedActor = authenticationContext.selectedActor;
    const { appContext } = useAppContext();

    const [isLoading, setIsLoading] = useState(false);
    const [reportDefinitions, setReportDefinitions] = useState(undefined);
    const [initialValues, setInitialValues] = useState(undefined);
    const [reportDataCollection, setReportDataCollection] = useState({});
    const [dataForReportDefinitionCreation, setDataForReportDefinitionCreation] = useState({});
    const [sortingParameters, setSortingParameters] = useState();

    useEffect(() => {
        // populate data
        dispatch(fetchStorageSites(selectedActor.id))
            .then(handleResponse(
                response => setDataForReportDefinitionCreation({ ...dataForReportDefinitionCreation, storageSites: response.payload })
            ));
    }, []);

    useEffect(() => {
        if(dataForReportDefinitionCreation.storageSites) {
            // now we have all data populated
            const iv = {
                inputFields: {}
            };
            setReportDefinitions(createReportDefinitions(originalReportDefinitions, iv, authenticationContext, dataForReportDefinitionCreation));
            setInitialValues(iv);
        }
    }, [dataForReportDefinitionCreation]);

    const handleFormSubmit = formValues => {
        const reportKey = formValues.report;
        const reportDefinition = reportDefinitions[reportKey];
        setIsLoading(true);
        setReportDataCollection({
            ...reportDataCollection,
            [reportKey]: undefined
        });

        const enrichedFormValues = {
            ...formValues
        };
        // populate ownerActorId for the selected report
        enrichedFormValues.inputFields[reportKey].ownerActorId = selectedActor.id;

        const fetchData = fv => dispatch(reportDefinition.fetchData(fv))
            .then(handleResponse(
                response => {
                    if(enrichedFormValues.format === 'json') {
                        setReportDataCollection({
                            ...reportDataCollection,
                            [reportKey]: response.payload
                        });
                    }
                    setIsLoading(false);
                },
                () => {
                    setIsLoading(false);
                })
            );

        if(reportDefinition.beforeFetchData) {
            reportDefinition.beforeFetchData(enrichedFormValues, appContext, fetchData);
        } else {
            fetchData(enrichedFormValues);
        }
    };

    const handleSort = sp => {
        setSortingParameters(sp);
    };

    const title = strings.accountTabs.reports;

    return (
        <>
            <PageTitle>{title}</PageTitle>
            <Form
                initialValues={initialValues}
                onSubmit={handleFormSubmit}
            >
                {({ form, handleSubmit, submitting, invalid, values }) => {
                    const reportDefinition = reportDefinitions
                        ? reportDefinitions[values.report]
                        : undefined;
                    const reportData = reportDataCollection[values.report];
                    return (
                        <>
                            <Container className={classes.inputFields}>
                                <ShadowBox
                                    title={title}
                                >
                                    {
                                        !reportDefinitions && <Loader />
                                    }
                                    {
                                        reportDefinitions &&
                                        (
                                            <LoadingWrapper isLoading={isLoading}>
                                                <form onSubmit={handleSubmit}>
                                                    <ReportInputFields
                                                        reportDefinitions={reportDefinitions}
                                                        selectedReportDefinition={reportDefinition}
                                                        selectReportLabel={strings.selectReport}
                                                    />

                                                    <ButtonContainer>
                                                        <Button
                                                            type="submit"
                                                            color="primary"
                                                            variant="contained"
                                                            disabled={submitting || invalid}
                                                            fullWidth={isMobile}
                                                            onClick={() => form.change('format', 'json')}
                                                        >
                                                            {strings.createReport}
                                                        </Button>
                                                        <Button
                                                            type="submit"
                                                            color="primary"
                                                            variant="contained"
                                                            disabled={submitting || invalid}
                                                            fullWidth={isMobile}
                                                            onClick={() => form.change('format', 'excel')}
                                                        >
                                                            {strings.exportToExcel}
                                                        </Button>
                                                    </ButtonContainer>
                                                </form>
                                            </LoadingWrapper>
                                        )
                                    }
                                </ShadowBox>
                            </Container>

                            {
                                reportData && reportDefinition &&
                                (
                                    <AdminTable
                                        className={classes.reportTable}
                                        items={createTableItems(reportData, sortingParameters)}
                                        columns={createTableColumns(reportData, reportDefinition, appContext)}
                                        footerCells={createTableFooterCells(reportData, reportDefinition, appContext)}
                                        noItemsMessage={strings.noItemsMessage}
                                        onSort={handleSort}
                                    />
                                )
                            }
                        </>
                    );
                }}
            </Form>
        </>
    );
};

export default ReportsSection;
