import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useAppContext } from 'context/AppContext';
import { useDispatch, useSelector } from 'react-redux';
import { handleResponse } from 'actions/actionHelpers';
import { useForm, useField } from 'react-final-form';
import { makeStyles } from 'styles/util';
import { updateStorageSiteLockConfigurations } from 'actions/storageSites';
import storageGroupCategories from 'enums/storageGroupCategories';
import storageGroupStatuses from 'enums/storageGroupStatuses';
import strings from 'localization/strings';
import { getCustomAreaExists, showOptionToTax, showExclusiveVatSuffix } from 'logic/editStorageSiteLogic';
import { formatYesNo } from 'helpers/StringHelper';
import { convertToNumber } from 'helpers/StringHelper';

import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import StorageGroupList from './StorageGroupList';
import StorageTypeIconAndText from 'common/StorageTypeIconAndText';
import { Checkboxes } from 'mui-rff';
import Area from 'common/Area';
import Button from '@material-ui/core/Button';
import LockConfigurationDialog from './LockConfigurationDialog';

const useStyles = makeStyles(({ theme }) => ({
    categoryContainer: {
        margin: theme.spacing(4, 0)
    },
    section: {
        marginBottom: theme.spacing(2)
    }
}));

const DisplayStep1Multiple = ({ storageSiteId }) => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const { appContext } = useAppContext();
    const [ lockConfigurationDialogOpen, setLockConfigurationDialogOpen ] = useState(false);
    const [ lockConfigurationDialogSaving, setLockConfigurationDialogSaving ] = useState(false);
    const fieldNamePrefix = 'steps[1].multiple';
    const form = useForm();
    const formValues = form.getState().values;
    const value = formValues.steps[1].multiple;
    const getName = suffix => suffix ? `${fieldNamePrefix}.${suffix}` : fieldNamePrefix;

    const authenticationContext = useSelector(state => state.authentication.context);
    const { selectedActor } = authenticationContext;

    const categories = appContext.storageGroupCategories.map(o => (
        {
            key: o.key,
            title: o.title,
            storageGroups: value[o.key].storageGroups
        })
    );

    const getAllStorageGroups = () => {
        const storageGroups = [];
        categories.forEach(category => {
            storageGroups.push(...category.storageGroups);
        });
        return storageGroups;
    };

    const getFormNameForStorageGroup = (storageGroupId) => {
        let formName;
        categories.forEach(category => {
            for(let i = 0; i < category.storageGroups.length; i++) {
                if(category.storageGroups[i].id === storageGroupId) {
                    formName = getName(`${category.key}.storageGroups[${i}]`);
                }
            }
        });
        return formName;
    };

    const showStorageGroup = sg => sg.status !== storageGroupStatuses.deleted.key || value.showDeletedStorageGroups;
    const hasDeletedStorageGroups = Object.keys(storageGroupCategories).filter(key => value[key] && value[key].storageGroups.filter(sg => sg.status === storageGroupStatuses.deleted.key).length > 0).length > 0;

    const visibleCategories = categories.filter(category => category.storageGroups.filter(showStorageGroup).length > 0);
    const storageTypeSelectorVisible = value.buildingsAndLand?.storageGroups.filter(showStorageGroup).length > 0;
    const totalAreaVisible = categories.filter(category => getCustomAreaExists(category.storageGroups.filter(showStorageGroup))).length > 0;
    const optionToTaxVisible = showOptionToTax(selectedActor, visibleCategories.map(category => category.key));
    const exclusiveVatSuffixVisible = showExclusiveVatSuffix(selectedActor, visibleCategories.map(o => o.key), value.optionToTax);
    const lockConfigurationVisible = authenticationContext.isSuperAdminOrImpersonated;
    const storageSiteFormData = useField('').input.value;
    const parakeyDomainId = storageSiteFormData.steps[6].parakeyDomainId;

    const handleOpenLockConfigurationDialog = () => {
        setLockConfigurationDialogOpen(true);
    };

    const handleLockConfigurationDialogOk = values => {
        setLockConfigurationDialogSaving(true);
        dispatch(updateStorageSiteLockConfigurations(storageSiteId, values))
            .then(handleResponse(
                () => {
                    // update lock configurations in the form
                    values.storageGroupIds.forEach(id => {
                        const formName = getFormNameForStorageGroup(id);
                        form.change(`${formName}.lockConfigurations`, values.lockConfigurations);
                    });

                    setLockConfigurationDialogSaving(false);
                    setLockConfigurationDialogOpen(false);
                },
                () => {
                    setLockConfigurationDialogSaving(false);
                    setLockConfigurationDialogOpen(false);
                }
            ));
    };

    const handleLockConfigurationDialogCancel = () => {
        setLockConfigurationDialogOpen(false);
    };

    return (
        <>
            {
                visibleCategories.map(category => (
                    <React.Fragment key={category.key}>
                        <Box key={category.key} className={classes.categoryContainer}>
                            <Typography variant="h6" display="block">
                                {category.title}
                            </Typography>

                            <StorageGroupList
                                storageSiteFormData={storageSiteFormData}
                                category={category}
                                fieldNamePrefix={`${fieldNamePrefix}.${category.key}`}
                                showExclusiveVatSuffix={exclusiveVatSuffixVisible}
                                showDeleted={value.showDeletedStorageGroups}
                            />
                        </Box>
                    </React.Fragment>
                ))
            }
            {
                storageTypeSelectorVisible && value.storageType &&
                (
                    <Box className={classes.section}>
                        <Typography variant="h6" gutterBottom>{strings.storageType}</Typography>
                        <Typography variant="body1">
                            <StorageTypeIconAndText storageType={value.storageType} />
                        </Typography>
                    </Box>
                )
            }
            {
                totalAreaVisible && value.area &&
                (
                    <Box className={classes.section}>
                        <Typography variant="h6" gutterBottom>{strings.storageSiteArea}</Typography>
                        <Typography variant="body1">
                            <Area value={convertToNumber(value.area)} maxNumberOfDecimals={1}/>
                        </Typography>
                    </Box>
                )
            }
            {
                optionToTaxVisible &&
                (
                    <Box className={classes.section}>
                        <Typography variant="h6" gutterBottom>{strings.optionToTax}</Typography>
                        <Typography variant="body1">
                            {formatYesNo(value.optionToTax)}
                        </Typography>
                    </Box>
                )
            }
            {
                hasDeletedStorageGroups &&
                (
                    <Box className={classes.section}>
                        <Checkboxes
                            name={getName('showDeletedStorageGroups')}
                            data={
                                { label: strings.showDeletedObjects, value: true }
                            }
                        />
                    </Box>
                )
            }
            {
                lockConfigurationVisible &&
                (
                    <Box className={classes.section}>
                        <Button
                            variant="contained"
                            color="secondary"
                            onClick={handleOpenLockConfigurationDialog}
                        >
                            {strings.editLockConfigurations}
                        </Button>
                    </Box>
                )
            }
            <LockConfigurationDialog
                name={getName('lockConfigurationDialog')}
                parakeyDomainId={parakeyDomainId}
                storageGroups={getAllStorageGroups()}
                open={lockConfigurationDialogOpen}
                isSaving={lockConfigurationDialogSaving}
                onOk={handleLockConfigurationDialogOk}
                onCancel={handleLockConfigurationDialogCancel}
            />
        </>
    );
};

DisplayStep1Multiple.propTypes = {
    storageSiteId: PropTypes.number.isRequired
};

export default DisplayStep1Multiple;
