import React, { useEffect, useState } from 'react';
import { useAppContext } from 'context/AppContext';
import { useHistory, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import strings from 'localization/strings';
import { makeStyles } from 'styles/util';
import { useScrollToTop } from 'helpers/BrowserHelper';
import { handleResponse } from 'actions/actionHelpers';
import { fetchEidTemporaryData, logIn } from 'actions/authentication';
import { fetchTakeoverTenant } from 'actions/takeoverTenants';
import organizationTypes from 'enums/organizationTypes';
import userAuthenticationTypes from 'enums/userAuthenticationTypes';
import routes from 'routes';
import { createInitialClientState, enhanceRedirectUrl, createBusinessActor, createObjectFromAuthenticationQuerystringParameters, getConnectTakeoverTenantRoute } from 'logic/authenticationLogic';

import PageTitle from 'common/PageTitle';
import Form from 'form/Form';
import OrganizationTypeSelector from './OrganizationTypeSelector';
import CreatePrivateActor from './CreatePrivateActor';
import CreateBusinessActor from './CreateBusinessActor';
import MuiContainer from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';
import ContentBox from 'common/ContentBox';
import Loader from 'common/Loader';
import Alert from '@material-ui/lab/Alert';

const useStyles = makeStyles(({ theme }) => ({
    pageWrapper: {
        display: 'flex',
        flexDirection: 'column',
        gap: theme.spacing(3.75),
        marginTop: theme.spacing(10),
        marginBottom: theme.spacing(3.75),
        [theme.breakpoints.down('sm')]: {
            marginTop: theme.spacing(2.5)
        }
    }
}));

const CreateAccountPage = () => {
    const classes = useStyles();
    const { appContext } = useAppContext();
    const location = useLocation();
    const history = useHistory();
    const dispatch = useDispatch();
    const { eidLoginResult, eidTemporaryDataToken, takeoverTenant, createAccountFromEidUserInfo } = createObjectFromAuthenticationQuerystringParameters(location);

    const [ initialValues, setInitialValues ] = useState(createInitialClientState(location));

    const isAuthenticated = useSelector(state => !!state.authentication.token);
    const redirectUrl = useSelector(state => state.authentication.redirectUrl);

    const title = strings.login;

    useScrollToTop();

    const onFetchEidTemporaryDataFailure = response => {
        if(response.meta.response.status === 404 && isAuthenticated) {
            // this is probably due to a token being used twice, caused by back button navigation
            history.push(routes.account.overview);
            return false;
        }
        return undefined;
    };

    // eID login callback
    useEffect(() => {
        if(eidLoginResult === 'success') {
            performFetchEidTemporaryData({
                onSuccess: response => {
                    const { userInfo, clientState } = response.payload;
                    if(clientState.organizationType === organizationTypes.business.key) {
                        // business actor
                        if(clientState.business.userAuthenticationTypeAssociatedWithEmail === userAuthenticationTypes.eid.key) {
                            // the email is already in use - make sure that the eid login belongs to the user with this email
                            if(clientState.business.userIdAssociatedWithEmail !== userInfo.storage365User?.id) {
                                // another user - not allowed
                                setInitialValues(clientState);
                                return {
                                    dialog: {
                                        title: strings.loginFailed,
                                        body: strings.formatString(strings.authentication.differentEidUserErrorMessage, clientState.business.userEmail),
                                        severity: 'error',
                                        onClose: () => history.push(routes.createAccountBusinessDetails)
                                    }
                                };
                            }
                        }
                        // create business actor
                        createBusinessActor({
                            clientState: { ...clientState, eidTemporaryDataToken },
                            dispatch,
                            onSuccess: () => {
                                if(clientState.takeoverTenant) {
                                    history.push(getConnectTakeoverTenantRoute(clientState.takeoverTenant));
                                } else {
                                    history.push(enhanceRedirectUrl(redirectUrl, true));
                                }
                            },
                            onFailure: () => {
                                // something failed
                                setInitialValues({ ...clientState, eidTemporaryDataToken });
                                return {
                                    dialog: {
                                        onClose: () => history.push(routes.createAccountBusinessDetails)
                                    }
                                };
                            }
                        });
                    } else {
                        // private actor
                        if(userInfo.storage365User) {
                            // the user already exists
                            const logInRequest = {
                                eidLogInRequest: {
                                    eidTemporaryDataToken
                                }
                            };
                            dispatch(logIn(logInRequest))
                                .then(handleResponse(
                                    () => {
                                        if(clientState.takeoverTenant) {
                                            history.push(getConnectTakeoverTenantRoute(clientState.takeoverTenant));
                                        } else if (clientState.userInvitation) {
                                            // TODO: handle user invitation
                                        } else {
                                            history.push(enhanceRedirectUrl(redirectUrl, true));
                                        }
                                    }
                                ));
                        } else {
                            // reload state and redirect to user details form
                            setInitialValues({
                                ...clientState,
                                private: {
                                    ...clientState.private,
                                    firstName: userInfo.firstName,
                                    lastName: userInfo.lastName
                                },
                                eidTemporaryDataToken
                            });
                            history.push(routes.createAccountPrivateEidDetails);
                        }
                    }
                    return undefined;
                },
                onFailure: onFetchEidTemporaryDataFailure
            });
        }
    }, [eidLoginResult, eidTemporaryDataToken]);

    // tried to log in using eid, but user didn't exist; create new account based on that data
    useEffect(() => {
        if(createAccountFromEidUserInfo) {
            performFetchEidTemporaryData({
                onSuccess: response => {
                    const { userInfo } = response.payload;
                    setInitialValues({
                        ...initialValues,
                        business: {
                            userFirstName: userInfo.firstName,
                            userLastName: userInfo.lastName
                        },
                        private: {
                            firstName: userInfo.firstName,
                            lastName: userInfo.lastName
                        },
                        eidTemporaryDataToken
                    });
                },
                onFailure: onFetchEidTemporaryDataFailure
            });
        }
    }, [createAccountFromEidUserInfo, eidTemporaryDataToken]);

    // fetch takeover tenant
    useEffect(() => {
        if(takeoverTenant) {
            dispatch(fetchTakeoverTenant(takeoverTenant.id, takeoverTenant.token, true /* setLinkClickedStatus */))
                .then(handleResponse(
                    response => {
                        const tt = response.payload;
                        if(!response.payload.isConnectedToUser) {
                            setInitialValues({
                                ...initialValues,
                                private: {
                                    ...initialValues.private,
                                    firstName: tt.firstName,
                                    lastName: tt.lastName,
                                    email: tt.email,
                                    mobileTelephone: tt.mobileTelephone
                                },
                                business: {
                                    ...initialValues.business,
                                    actorName: tt.name,
                                    actorEmail: tt.email,
                                    actorMobileTelephone: tt.mobileTelephone,
                                    userEmail: tt.email
                                }
                            });
                        }
                    }
                ));
        }
    }, [takeoverTenant]);

    const performFetchEidTemporaryData = ({ onSuccess, onFailure }) => {
        dispatch(fetchEidTemporaryData(eidTemporaryDataToken, true /* keepData */))
            .then(handleResponse(
                response => onSuccess(response),
                response => onFailure
                    ? onFailure(response)
                    : undefined
            ));
    };

    const handleOrganizationTypeSelected = value => {
        let route;
        if(value === organizationTypes.business.key) {
            route = routes.createAccountBusinessOrganizationNumber;
        } else {
            route = initialValues.eidTemporaryDataToken
                ? routes.createAccountPrivateEidDetails
                : routes.createAccountPrivate;
        }
        history.push(route);
    };

    const getOrganizationTypeFromLocation = () => {
        if(location.pathname.startsWith(routes.createAccountBusiness)) {
            return organizationTypes.business.key;
        }
        if(location.pathname.startsWith(routes.createAccountPrivate)) {
            return organizationTypes.private.key;
        }
        return undefined;
    };

    const handleFormSubmit = () => {};

    if(appContext.allowedOrganizationTypes.length === 1) {
        handleOrganizationTypeSelected(appContext.allowedOrganizationTypes[0]);
    }

    const organizationTypeLabel = getOrganizationTypeFromLocation()
        ? organizationTypes[getOrganizationTypeFromLocation()].title
        : undefined;

    return (
        <>
            <PageTitle>{title}</PageTitle>
            <MuiContainer maxWidth={false}>
                <ContentBox className={classes.pageWrapper} themeMaxWidth="xs">
                    <Typography variant="h2">
                        {strings.createAccount}{organizationTypeLabel ? `: ${organizationTypeLabel}` : ''}
                    </Typography>

                    {
                        eidLoginResult === 'success' &&
                        (
                            <Loader />
                        )
                    }

                    {
                        eidLoginResult === 'failure' &&
                        (
                            <Alert severity="error">
                                Authentication failed.
                            </Alert>
                        )
                    }

                    {
                        !eidLoginResult &&
                        (
                            <Form
                                initialValues={initialValues}
                                onSubmit={handleFormSubmit}
                            >
                                {({ handleSubmit }) => (
                                    <form onSubmit={handleSubmit}>
                                        {
                                            !getOrganizationTypeFromLocation() &&
                                            (
                                                <OrganizationTypeSelector
                                                    onClick={handleOrganizationTypeSelected}
                                                />
                                            )
                                        }

                                        {
                                            getOrganizationTypeFromLocation() === organizationTypes.private.key &&
                                            (
                                                <CreatePrivateActor
                                                    fieldNamePrefix="private"
                                                />
                                            )
                                        }

                                        {
                                            getOrganizationTypeFromLocation() === organizationTypes.business.key &&
                                            (
                                                <CreateBusinessActor
                                                    fieldNamePrefix="business"
                                                />
                                            )
                                        }
                                    </form>
                                )}
                            </Form>
                        )
                    }
                </ContentBox>
            </MuiContainer>
        </>
    );
};

export default CreateAccountPage;
