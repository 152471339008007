import React  from 'react';
import PropTypes from 'prop-types';
import { useAppContext } from 'context/AppContext';

const VisibleFor = ({ branch, market, children }) => {
    const { appContext } = useAppContext();
    const match =
        (branch === appContext.branchKey && market === appContext.marketKey) ||
        (branch === appContext.branchKey && market === undefined) ||
        (branch === undefined && market === appContext.marketKey) ||
        (branch === undefined && market === undefined);
    if(match) {
        return <>{children}</>;
    }
    return null;
};

VisibleFor.propTypes = {
    branch: PropTypes.string,
    market: PropTypes.string,
    children: PropTypes.node.isRequired
};

export default VisibleFor;
