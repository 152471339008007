import React from 'react';
import PropTypes from 'prop-types';
import { useAppContext } from 'context/AppContext';
import strings from 'localization/strings';
import { makeStyles } from 'styles/util';

import List from '@material-ui/core/List';
import ChatBubbleRoundedIcon from '@material-ui/icons/ChatBubbleRounded';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import WarningIcon from '@material-ui/icons/Warning';
import Typography from '@material-ui/core/Typography';
import TimeAgo from 'common/TimeAgo';

const useStyles = makeStyles(({ theme, colors }) => ({
    tenant: {
        color: `${colors.attentionBackground} !important`
    },
    owner: {
        color: `${colors.primaryColor} !important`
    },
    timeAgo: {
        opacity: 0.6
    },
    companyIcon: {
        height: '1em'
    },
    contactDetailsIncluded: {
        color: colors.attention,
        marginRight: theme.spacing(1)
    }
}));

const MessageList = ({ conversation }) => {
    const classes = useStyles();
    const { appContext } = useAppContext();

    return (
        <List>
            {
                conversation.messages.map(message => {
                    let icon;
                    let writerName;

                    if (message.adminName) {
                        writerName = `${message.adminName} (${strings.companyName})`;
                        icon = <img src={appContext.images.shortLogo} className={classes.companyIcon} />;
                    } else {
                        writerName = message.senderName;

                        const chatBubbleClassName = message.senderIsOwner
                            ? classes.owner
                            : classes.tenant;

                        icon = <ChatBubbleRoundedIcon className={chatBubbleClassName} />;
                    }

                    return (
                        <ListItem key={message.id} alignItems="flex-start">
                            <ListItemIcon>
                                {icon}
                            </ListItemIcon>
                            <ListItemText>
                                <Typography variant="caption" gutterBottom>
                                    {writerName} <TimeAgo className={classes.timeAgo} date={message.createdTime}/>
                                </Typography>
                                <Typography variant="body1">
                                    {
                                        message.contactDetailsIncluded && <WarningIcon className={classes.contactDetailsIncluded}/>
                                    }
                                    {message.text}
                                </Typography>
                            </ListItemText>
                        </ListItem>
                    );
                })
            }
        </List>
    );
};

MessageList.propTypes = {
    conversation: PropTypes.object.isRequired
};

export default MessageList;
