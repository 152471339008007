import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from 'styles/util';
import { defaultCompare } from 'helpers/SortHelper';

import Container from '@material-ui/core/Container';
import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableBody';
import TableBody from '@material-ui/core/TableBody';
import TableFooter from '@material-ui/core/TableFooter';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import Pagination from './Pagination';

const useStyles = makeStyles(({ colors }) => ({
    table: {
        backgroundColor: colors.white,
        border: 'thin solid rgba(0, 0, 0, 0.125)'
    }
}));

const AdminTable = ({ className, isPaged, items, footerCells, count, page, pageSize, columns, sortByColumn: initialSortByColumn, sortDirection: initialSortDirection, onSort, onPageChange, onPageChangeSize, noItemsMessage }) => {
    const classes = useStyles();
    const sortDirections = {
        asc: 'asc',
        desc: 'desc'
    };

    const [sortDirection, setSortDirection] = useState(initialSortDirection ?? sortDirections.asc);
    const [sortByColumn, setSortByColumn] = useState(initialSortByColumn ?? columns[0]);

    const createSortHandler = clickedColumn => () => {
        const newSortByColumn = columns.find(o => o.id === clickedColumn?.id);
        const previousSortByColumn = sortByColumn;
        setSortByColumn(newSortByColumn);
        let newSortDirection = sortDirections.desc;
        if (previousSortByColumn?.id === newSortByColumn?.id && sortDirection === sortDirections.desc) {
            newSortDirection = sortDirections.asc;
        }
        setSortDirection(newSortDirection);
        if(onSort) {
            onSort({
                sortByColumn: newSortByColumn.id,
                sortDirection: newSortDirection
            });
        }
    };

    const compareAscending = (a, b) => defaultCompare(a[sortByColumn.id], b[sortByColumn.id]);

    const getSortedItems = () => {
        if(isPaged) {
            // paged tables fetch sorted data from server
            // don't sort anything here
            return items;
        }
        const column = columns.find(c => c.id === sortByColumn?.id);
        const sortingFunction = (column || {}).customSort
            ??
            ((a, b) => compareAscending(a, b));
        const directionAwareSortingFunction = sortDirection === sortDirections.asc
            ? (a, b) => sortingFunction(a, b)
            : (a, b) => -sortingFunction(a, b);
        const sortedItems = [...items];
        sortedItems.sort(directionAwareSortingFunction);
        return sortedItems;
    };

    const renderColumn = (column, item) => {
        if(column.render) {
            return column.render(item);
        }
        return item[column.id];
    };

    const getFooterCells = () => {
        if(footerCells) {
            return footerCells;
        }
        if(hasRenderFooterFunctions()) {
            return columns.map(column => {
                if(column.renderFooter) {
                    return {
                        align: 'right',
                        value: column.renderFooter(items)
                    };
                }
                return {};
            });
        }
        return undefined;
    };

    const hasRenderFooterFunctions = () => {
        return columns.filter(o => o.renderFooter).length > 0;
    };

    const getStyle = column => {
        return {
            whiteSpace: column.whiteSpace || 'normal'
        };
    };

    if (!items) {
        return null;
    }

    if(!items.length && !count) {
        return (
            <Container>
                {noItemsMessage ?? 'No items were found.'}
            </Container>
        );
    }

    let rowCount = 0;

    const footerCellsToUse = getFooterCells();

    const renderColumnLabel = column => {
        if(onSort || !isPaged) {
            return (
                <TableSortLabel
                    active={sortByColumn?.id === column.id}
                    direction={sortDirection}
                    onClick={createSortHandler(column)}
                >
                    {column.title}
                </TableSortLabel>
            );
        }
        return column.title;
    };

    return (
         <Table className={classes.table + (className ? ' ' + className : '')}>
            <TableHead>
                <TableRow>
                    {
                        columns.map(column => (
                            <TableCell
                                key={column.id}
                                sortDirection={sortByColumn === column ? sortDirection : false}
                                align={column.align}
                            >
                                {renderColumnLabel(column)}
                            </TableCell>
                        ))
                    }
                </TableRow>
            </TableHead>
            <TableBody>
                {
                    getSortedItems(items).map((item, index) => (
                        <TableRow
                            key={item.id || rowCount++}
                            style={{ backgroundColor: (index % 2) === 0 ? '#ffffff' : '#f7f7f7'}}
                        >
                            {
                                columns.map(column => (
                                    <TableCell
                                        key={column.id}
                                        align={column.align}
                                        style={getStyle(column)}
                                    >
                                        {renderColumn(column, item)}
                                    </TableCell>
                                ))
                            }
                        </TableRow>
                    ))
                }
            </TableBody>
            {
                isPaged &&
                (
                    <Pagination
                        count={count}
                        page={page}
                        pageSize={pageSize}
                        colSpan={columns.length}
                        onPageChange={onPageChange}
                        onPageChangeSize={onPageChangeSize}
                    />
                )
            }
            {
                footerCellsToUse &&
                (
                    <TableFooter>
                        <TableRow>
                            {
                                footerCellsToUse.map((footerCell, i) => (
                                    <TableCell
                                        key={i}
                                        align={footerCell.align}
                                    >
                                        {footerCell.value}
                                    </TableCell>
                                ))
                            }
                        </TableRow>
                    </TableFooter>
                )
            }
        </Table>
    );
};

AdminTable.propTypes = {
    className: PropTypes.string,
    isPaged: PropTypes.bool,
    items: PropTypes.array,
    count: PropTypes.number,
    footerCells: PropTypes.array,
    page: PropTypes.number,
    pageSize: PropTypes.number,
    columns: PropTypes.array.isRequired,
    sortByColumn: PropTypes.object,
    sortDirection: PropTypes.string,
    onSort: PropTypes.func,
    onPageChange: PropTypes.func,
    onPageChangeSize: PropTypes.func,
    noItemsMessage: PropTypes.string
};

export default AdminTable;
