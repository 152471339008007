import React from 'react';
import PropTypes from 'prop-types';
import TelemetryProviderInner from './TelemetryProviderInner';
import { getAppInsights } from 'integration/application-insights/applicationInsightsHelper';

const TelemetryProvider = ({ applicationInsightsConnectionString, children }) => {
    if(applicationInsightsConnectionString) {
        return (
            <TelemetryProviderInner connectionString={applicationInsightsConnectionString} after={() => { window.applicationInsights = getAppInsights(); }}>
                {children}
            </TelemetryProviderInner>
        );
    }
    return children;
};

TelemetryProvider.propTypes = {
    applicationInsightsConnectionString: PropTypes.string,
    children: PropTypes.node.isRequired
};

export default TelemetryProvider;
