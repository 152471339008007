import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from 'styles/util';

import Box from '@material-ui/core/Box';
import Text from 'common/Text';
import EidLoginContainer from 'components/eid/EidLoginContainer';

const useStyles = makeStyles(({ theme }) => ({
    container: {
        display: 'flex',
        flexDirection: 'column',
        gap: theme.spacing(3.75)
    }
}));

const CreateEidUser = ({ description, redirectUrl, clientState, onStatusChange }) => {
    const classes = useStyles();
    return (
        <Box className={classes.container}>
            <Text html={description}/>
            <EidLoginContainer
                redirectUrl={redirectUrl}
                clientState={clientState}
                onStatusChange={onStatusChange}
            />
        </Box>
    );
};

CreateEidUser.propTypes = {
    description: PropTypes.string,
    redirectUrl: PropTypes.string.isRequired,
    clientState: PropTypes.object,
    onStatusChange: PropTypes.func
};

export default CreateEidUser;
