import * as actionTypes from 'actions/responses';
import { isSuccessfulResponse } from 'actions/actionHelpers';

const initialState = {
    successDialogOpen: false,
    successDialogData: undefined,
    errorDialogOpen: false,
    errorResponse: undefined,
    errorDialogData: undefined,
    successTimestamp: undefined,
    successResponse: undefined,
    switchActorDialogOpen: false,
    switchActorDialogData: undefined,
};

const responses = (state = initialState, action) => {
    const timestamp = new Date().getTime();
    switch (action.type) {
        case actionTypes.RESPONSES_SHOW_SUCCESS_DIALOG:
            return { ...state, successDialogOpen: true, successDialogData: action.dialogData };
        case actionTypes.RESPONSES_CLOSE_SUCCESS_DIALOG:
            return { ...state, successDialogOpen: false };
        case actionTypes.RESPONSES_SHOW_ERROR_DIALOG:
            return { ...state, errorDialogOpen: true, errorResponse: typeof action.response === 'string' ? { meta: { message: action.response } } : action.response, errorDialogData: action.dialogData };
        case actionTypes.RESPONSES_CLOSE_ERROR_DIALOG:
            return { ...state, errorDialogOpen: false, errorResponse: undefined, errorDialogData: undefined };
        case actionTypes.RESPONSES_SHOW_SWITCH_USER_DIALOG:
            return { ...state, switchActorDialogOpen: true, switchActorDialogData: action.dialogData };
        case actionTypes.RESPONSES_CLOSE_SWITCH_USER_DIALOG:
            return { ...state, switchActorDialogOpen: false, switchActorDialogData: undefined };
        case actionTypes.RESPONSES_SET_SUCCESS:
            return { ...state, successResponse: { meta: { message: action.message } }, successTimestamp: timestamp };
        case actionTypes.RESPONSES_SET_ERROR:
            return { ...state, errorDialogOpen: true, errorResponse: { meta: { message: action.message } } };
        default:
            if(isSuccessfulResponse(action)) {
                return { ...state, successResponse: action, successTimestamp: timestamp };
            }
            return state;
    }
};

export default responses;
