import storageGroupCategories from 'enums/storageGroupCategories';

import * as actionTypes from 'actions/viewStorageSites';

const initialState = {
    viewStorageSites: {
        items: undefined,
        totalCount: undefined,
        loading: false,
        seoGeoItem: undefined
    },
    relevantStorageSites: {}
};

Object.values(storageGroupCategories).forEach(category => {
    initialState.relevantStorageSites[category.key] = {
        items: undefined,
        loading: false
    };
});

const viewStorageSites = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.VIEWSTORAGESITES_LIST:
            return { ...state, viewStorageSites: { ...state.viewStorageSites, loading: true, items: undefined, totalCount: undefined } };
        case actionTypes.VIEWSTORAGESITES_LIST_SUCCESS:
            return { ...state, viewStorageSites: { ...state.viewStorageSites, loading: false, items: action.payload.storageSites, totalCount: action.payload.totalCount, seoGeoItem: action.payload.seoGeoItem } };
        case actionTypes.VIEWSTORAGESITES_LIST_FAILURE:
            return { ...state, viewStorageSites: { ...state.viewStorageSites, loading: false } };
        case actionTypes.VIEWSTORAGESITES_FETCH_RELEVANT:
            return { ...state, relevantStorageSites: { ...state.relevantStorageSites, [action.meta.storageGroupCategory]: { loading: true, items: undefined } } };
        case actionTypes.VIEWSTORAGESITES_FETCH_RELEVANT_SUCCESS:
            return { ...state, relevantStorageSites: { ...state.relevantStorageSites, [action.meta.storageGroupCategory]: { loading: false, items: action.payload.storageSites } } };
        case actionTypes.VIEWSTORAGESITES_FETCH_RELEVANT_FAILURE:
            return { ...state, relevantStorageSites: { ...state.relevantStorageSites, [action.meta.storageGroupCategory]: { loading: false } } };
        default:
            return state;
    }
};

export default viewStorageSites;
