import React from 'react';
import { withStyles } from 'styles/util';

import Tab from '@material-ui/core/Tab';

const StyledTab = withStyles(({ theme, colors }) => ({
    root: {
        borderRadius: theme.spacing(1),
        transition: 'background-color .1s ease-in-out',
        border: 0,
        opacity: 1,
        backgroundColor: 'transparent',
        flexGrow: 1,
        flexBasis: 0,
        minHeight: '70px',
        '&:hover': {
            backgroundColor: colors.darkBlueOpacity,
            opacity: 1
        },
        '&$selected': {
            backgroundColor: colors.primaryColor
        },
        '& > span': {
            color: colors.white,
            fontSize: theme.typography.body2.fontSize,
            '& > img': {
                filter: 'invert(1)',
                marginBottom: '2px !important'
            }
        },
        [theme.breakpoints.down('xs')]: {
            minHeight: '60px',
            '& > span': {
                fontSize: theme.typography.caption.fontSize
            }
        }
    },
    selected: {},
}))((props) => <Tab {...props} />);

export default StyledTab;
