import strings from 'localization/strings';

export const questionAndAnswerItemsTenant = [];
export const questionAndAnswerItemsTenantSmall = [];
export const questionAndAnswerItemsOwner = [];

const getQA = (obj, index) => {
    const stringIndex = index < 10
        ? `0${index}`
        : `${index}`;
    return obj[stringIndex] && obj[stringIndex].question
        ? obj[stringIndex]
        : undefined;
};

strings.addLoadCallback(() => {
    questionAndAnswerItemsTenant.splice(0, questionAndAnswerItemsTenant.length);
    let i;
    let qa;
    for (i = 1; i < 100; i++) {
        qa = getQA(strings.faq.tenant, i);
        if(qa) {
            questionAndAnswerItemsTenant.push({ title: qa.question, description: qa.answer });
            if (i < 7) {
                questionAndAnswerItemsTenantSmall.push({ title: qa.question, description: qa.answer });
            }
        }
    }

    for (i = 1; i < 100; i++) {
        qa = getQA(strings.faq.owner, i);
        if(qa) {
            questionAndAnswerItemsOwner.push({ title: qa.question, description: qa.answer });
        }
    }
});
