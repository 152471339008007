import React from 'react';
import PropTypes from 'prop-types';
import strings from 'localization/strings';

import { Checkboxes } from 'mui-rff';

const PayWithSavedCardCheckbox = ({ name, savedCard }) => {
    const payWithSavedCardLabel = (
        <>
            {strings.payWithSavedCard}
            {' '}
            {': ' + strings.formatString(strings.protectedCardDetails, savedCard.last4, savedCard.expirationMonth, savedCard.expirationYear)}
        </>
    );

    return (
        <Checkboxes
            name={name}
            data={{
                label: (
                    <span>
                        {payWithSavedCardLabel}
                    </span>
                ),
                value: true
            }}
        />
    );
};

PayWithSavedCardCheckbox.propTypes = {
    name: PropTypes.string.isRequired,
    savedCard: PropTypes.object.isRequired
};

export default PayWithSavedCardCheckbox;
