import React from 'react';
import PropTypes from 'prop-types';
import { useAppContext } from 'context/AppContext';
import { makeStyles } from 'styles/util';
import { getFlattenedStorageSiteFacilities } from 'helpers/StorageSiteHelper';

import Typography from '@material-ui/core/Typography';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import NotInterestedIcon from '@material-ui/icons/NotInterested';
import Box from '@material-ui/core/Box';

const useStyles = makeStyles(({ theme, colors }) => ({
    facilities: {
        display: 'flex',
        flexWrap: 'wrap',
        alignItems: 'flex-start',
    },
    facility: {
        display: 'flex',
        alignItems: 'center',
        fontSize: theme.spacing(2),
        fontStyle: 'normal',
        fontWeight: 'normal',
        lineHeight: '200%',
        marginRight: theme.spacing(1.5),
        '& svg': {
            marginRight: theme.spacing(1.25)
        },
        [theme.breakpoints.down('sm')]: {
            flex: '1',
            paddingRight: theme.spacing(3)
        }
    },
    availableFacility: {
        color: colors.black,
    },
    notAvailableFacility: {
        color: colors.textColorDisabled
    }
}));

const Facilities = ({ storageSite, ...rest }) => {
    const classes = useStyles();
    const { appContext } = useAppContext();

    const flattenedStorageSiteFacilities = getFlattenedStorageSiteFacilities(appContext);
    const availableStorageSiteFacilities = flattenedStorageSiteFacilities.filter(o => storageSite.facilities.find(o2 => o2 === o.key));
    const notAvailableStorageSiteFacilities = flattenedStorageSiteFacilities.filter(o => !storageSite.facilities.find(o2 => o2 === o.key));

    return (
        <Box className={classes.facilities} {...rest}>
            {
                availableStorageSiteFacilities.map(facility =>
                    (
                        <Typography className={classes.facility + ' ' + classes.availableFacility} variant="body1" key={facility.key}>
                            <CheckCircleOutlineIcon htmlColor={appContext.colors.icon}/>
                            {facility.title}
                        </Typography>
                    )
                )
            }
            {
                notAvailableStorageSiteFacilities.map(facility =>
                    (
                        <Typography className={classes.facility + ' ' + classes.notAvailableFacility} variant="body1" key={facility.key}>
                            <NotInterestedIcon htmlColor={appContext.colors.textColorDisabled}/>
                            {facility.title}
                        </Typography>
                    )
                )
            }
        </Box>
    );
};

Facilities.propTypes = {
    storageSite: PropTypes.object.isRequired
};

export default Facilities;
