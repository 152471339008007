import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from 'styles/util';
import strings from 'localization/strings';
import mediaTypes from 'enums/mediaTypes';

import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import Dropzone from 'react-dropzone';

const useStyles = makeStyles(({ theme, colors }) => ({
    dropzone: {
        border: `2px solid ${colors.primaryColor}`,
        borderRadius: '4px',
        padding: theme.spacing(4),
        cursor: 'pointer'
    },
    mediaHint: {
        fontSize: '80%'
    }
}));

const ImageDropzone = ({ fields, onDrop }) => {
    const classes = useStyles();
    const handleDrop = (acceptedFiles, fileRejections) => {
        onDrop(acceptedFiles, fileRejections, fields, mediaTypes.image.key);
    };

    return (
        <Dropzone
            accept={mediaTypes.image.accept.join(', ')}
            multiple
            maxSize={16000000}
            onDrop={handleDrop}
        >
            {({ getRootProps, getInputProps }) => (
                <Box {...getRootProps()} component="div" className={classes.dropzone}>
                    <input {...getInputProps()} />
                    <Typography variant="body1" gutterBottom>
                        {strings.uploadImagesTitle}
                    </Typography>
                    <Typography variant="body1" gutterBottom className={classes.mediaHint}>
                        {strings.uploadImagesDescription}
                    </Typography>
                </Box>
            )}
        </Dropzone>
    );
};

ImageDropzone.propTypes = {
    fields: PropTypes.object.isRequired,
    onDrop: PropTypes.func
};

export default ImageDropzone;
