
import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from 'styles/util';

const IconChevronRight = ({fill}) => {
    const useStyles = makeStyles(() => ({
        svg: {
            fill: fill
        }
    }));

    const classes = useStyles();
    return (
        <svg className={classes.svg} xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
            <path d="M9.70492 6L8.29492 7.41L12.8749 12L8.29492 16.59L9.70492 18L15.7049 12L9.70492 6Z"/>
        </svg>
    );
};

IconChevronRight.propTypes = {
    fill: PropTypes.string.isRequired
};

export default IconChevronRight;
