import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from 'styles/util';

import Box from '@material-ui/core/Box';
import RightIconButton from 'common/RightIconButton';

const useStyles = makeStyles(({ theme }) => ({
    buttonContainer: {
        display: 'flex',
        gap: theme.spacing(4),
        width: '100%',
        '& > *': {
            flex: '1 1 100%'
        },
        [theme.breakpoints.down('sm')]: {
            flexDirection: 'column',
            gap: theme.spacing(3)
        }
    }
}));

const TwoButtons = ({ first, second }) => {
    const classes = useStyles();

    const createIcon = icon => icon
        ? <img src={icon} />
        : undefined;

    return (
        <Box className={classes.buttonContainer}>
            {
                [first, second].map((item, i) => (
                    <RightIconButton
                        key={i}
                        variant="contained"
                        color="primary"
                        icon={createIcon(item.icon)}
                        onClick={item.onClick}
                    >
                        {item.text}
                    </RightIconButton>
                ))
            }
        </Box>
    );
};

TwoButtons.propTypes = {
    first: PropTypes.object.isRequired,
    second: PropTypes.object.isRequired
};

export default TwoButtons;
