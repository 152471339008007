import { RSAA } from 'redux-api-middleware';
import { createRsaaTypes, createHeaders } from './actionHelpers';

export const PINGS_GET_PING = 'PINGS_GET_PING';

export const ping = () => ({
    [RSAA]: {
        types: createRsaaTypes(PINGS_GET_PING),
        endpoint: '/api/ping',
        method: 'GET',
        headers: createHeaders(false)
    }
});
