import strings from 'localization/strings';
import { toCamelCase, toPascalCase } from 'helpers/StringHelper';

const genericAddressLines = ['line1', 'line2', 'postalCode', 'city', 'countryCode'];

export const getAddressLines = (address, appContext) =>
    appContext.addressLines
        .map(line => ({
            key: line.key,
            title: strings.addressLineTitles[line.key],
            isRequired: line.isRequired,
            value: address?.lines.find(o => o.key.toLowerCase() === line.key.toLowerCase())?.value
        }));

export const getGenericAddressLines = address =>
    genericAddressLines
        .map(line => ({
            key: line,
            title: toPascalCase(line),
            isRequired: false,
            value: address?.lines.find(o => o.key.toLowerCase() === line.toLowerCase())?.value
        }));

export const toAddressKeyValueObject = dataContractAddress => {
    const object = {};
    dataContractAddress?.lines.forEach(line => {
        object[toCamelCase(line.key)] = line.value;
    });
    return object;
};

export const toDataContractAddress = addressKeyValueObject => {
    return {
        lines: addressKeyValueObject
            ? Object.keys(addressKeyValueObject)
                .map(key => ({
                    key: toPascalCase(key),
                    value: addressKeyValueObject[key]
                }))
            : undefined
    };
};

export const getAddressOrDefault = (address, appContext) => {
    if(address) {
        return address;
    }
    return {
        lines: [
            { key: 'CountryCode', value: appContext.countryCode.toUpperCase() }
        ]
    };
};
