import React, { useState, useRef, useEffect } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import strings from 'localization/strings';
import Collapse from '@material-ui/core/Collapse';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';

import { makeStyles } from 'styles/util';

const ReadMoreText = ({ text, fadeBackgroundColor, maxHeight = '20px' }) => {
    const useStyles = makeStyles(({colors}) => ({
        collapse: {
            position: 'relative',
            '&.fade::after': {
                content: '""',
                position: 'absolute',
                bottom: 0,
                left: 0,
                right: 0,
                height: '20px',
                background: `linear-gradient(rgba(255, 255, 255, 0), ${fadeBackgroundColor || colors.white})`
            },
            '&.fade.MuiCollapse-entered::after': {
                display: 'none'
            }
        }
    }));
    const classes = useStyles();
    const [expanded, setExpanded] = useState(false);
    const [shouldShowButton, setShouldShowButton] = useState(false);
    const textRef = useRef();

    useEffect(() => {
        if (textRef.current && textRef.current.scrollHeight > parseInt(maxHeight, 10)) {
            setShouldShowButton(true);
        } else {
            setShouldShowButton(false);
        }
    }, [text]);

    return (
        <>
            <Collapse in={expanded} collapsedSize={maxHeight} className={clsx(classes.collapse, { fade: shouldShowButton})}>
                <Typography variant="body1" ref={textRef}>
                    {text}
                </Typography>
            </Collapse>
            {shouldShowButton && (
                <Button onClick={() => setExpanded(!expanded)}>
                    {!expanded ? strings.readMore : strings.readLess}
                </Button>
            )}
        </>
    );
};

ReadMoreText.propTypes = {
    text: PropTypes.string.isRequired,
    maxHeight: PropTypes.string,
    fadeBackgroundColor: PropTypes.string
};

export default ReadMoreText;
