import React from 'react';
import PropTypes from 'prop-types';
import { Form as FinalFormForm } from 'react-final-form';
import deepEqual from 'fast-deep-equal';

// applying initialValuesEqual={deepEqual} to each Form
const Form = ({ children, ...props }) => {
    return (
        <FinalFormForm initialValuesEqual={deepEqual} {...props}>
            {children}
        </FinalFormForm>
    );
};

Form.propTypes = {
    children: PropTypes.func.isRequired
};

export default Form;
