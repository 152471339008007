import React from 'react';
import strings from 'localization/strings';
import { useScrollToTop } from 'helpers/BrowserHelper';

import PageTitle from 'common/PageTitle';
import Container from 'common/Container';
import Text from 'common/Text';

const CookiePolicyPage = () => {
    useScrollToTop();

    return (
        <Container withPageMargins>
            <PageTitle>{strings.environmentPolicy}</PageTitle>
            <Text component="div" html={strings.pages.environmentPolicy} ignoreNewlines/>
        </Container>
    );
};

export default CookiePolicyPage;
