import React from 'react';
import PropTypes from 'prop-types';
import { useAppContext } from 'context/AppContext';
import { useDispatch } from 'react-redux';
import { makeStyles } from 'styles/util';
import { drawerWidth } from './AdminApp';
import queryString from 'query-string';
import { handleResponse } from 'actions/actionHelpers';
import { getCurrentUserLoginToken } from 'actions/admin/users';

import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import MarketSelector from './MarketSelector';

const useStyles = makeStyles(({ theme, colors }) => ({
    appBar: {
        [theme.breakpoints.up('md')]: {
            width: `calc(100% - ${drawerWidth}px)`,
            marginLeft: drawerWidth,
        },
        backgroundColor: colors.white,
        height: 70
    },
    menuButton: {
        marginRight: theme.spacing(2),
        [theme.breakpoints.up('md')]: {
            display: 'none',
        },
    },
    welcomeContainer: {
        display: 'flex',
        width: '100%',
        alignItems: 'center',
        '& > *:last-child': {
            textAlign: 'right',
            flex: '1 0 auto'
        }
    }
}),
// branch-specific styling
({ colors }) => ({
    office: {
        menuButton: {
            color: colors.primaryColor
        }
    }
}));

const AdminAppBar = ({ user, onDrawerToggle }) => {
    const classes = useStyles();
    const { appContext, allAppContexts } = useAppContext();
    const dispatch = useDispatch();

    const handleMarketChange = market => {
        // we need a login token to keep the logged-in user when switching domains
        dispatch(getCurrentUserLoginToken())
            .then(handleResponse(
                response => {
                    const newAppContext = allAppContexts.find(o => o.branchKey === appContext.branchKey && o.marketKey === market.key);
                    window.location.href = `${newAppContext.siteUrl}/admin?${queryString.stringify({ loginToken: response.payload.token })}`;
                }
            ));
    };

    return (
        <AppBar position="fixed" className={classes.appBar}>
            <Toolbar>
                <IconButton
                    color="inherit"
                    aria-label="open drawer"
                    edge="start"
                    onClick={onDrawerToggle}
                    className={classes.menuButton}
                >
                    <MenuIcon />
                </IconButton>
                <Box className={classes.welcomeContainer}>
                    <Typography variant="h6" noWrap>Welcome, {user.firstName}!</Typography>
                    {
                        // in local environment, window.configuration.appContext determines the app context which can't be changed dynamically
                        !window.configuration.appContext &&
                        (
                            <Box>
                                <MarketSelector onMarketChange={handleMarketChange}/>
                            </Box>
                        )
                    }
                </Box>
            </Toolbar>
        </AppBar>
    );
};

AdminAppBar.propTypes = {
    user: PropTypes.object,
    onDrawerToggle: PropTypes.func.isRequired
};

export default AdminAppBar;
