const purchaseTypes = {
    periodBookingPurchase: {},
    subscriptionBookingInitialPurchase: {},
    subscriptionBookingRecurringPurchase: {},
    endSubscriptionBookingPurchase: {},
    postInvoice: {}
};

Object.keys(purchaseTypes).forEach(key => {
    purchaseTypes[key].key = key;
});

export default purchaseTypes;
