const takeoverTenantSelections = {
    bookingNotYetCreated: {
        label: 'Tenants that bookings haven\'t been created for yet'
    },
    bookingCreatedButNotYetPaid: {
        label: 'Tenants that have had bookings created but not yet paid'
    },
    custom: {
        label: 'Custom selection'
    }
};

Object.keys(takeoverTenantSelections).forEach(key => {
    takeoverTenantSelections[key].key = key;
});

export default takeoverTenantSelections;
