import React from 'react';
import PropTypes from 'prop-types';
import strings from 'localization/strings';
import { makeStyles } from 'styles/util';

import Loader from 'common/Loader';
import StorageSiteListItem from './StorageSiteListItem';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';

const StorageSiteList = ({ storageSites, storageGroupCategory, loading, showDistances, storageSiteLinkCreator, landingPage, relevantStorageSites }) => {
    const useStyles = makeStyles(({ theme, colors }) => ({
        root: {
            display: 'flex',
            flexDirection: relevantStorageSites ? 'row' : 'column',
            flexWrap: relevantStorageSites ? 'nowrap' : 'wrap',
            gap: theme.spacing(4),
            justifyContent: 'center',
            alignItems: 'stretch',
            padding: relevantStorageSites ? theme.spacing(2, 0, 4, 0) : 0,
            margin: relevantStorageSites ? theme.spacing(0, -0.5) : 0,
            width: relevantStorageSites ? undefined : '100%',
            [theme.breakpoints.down('sm')]: {
                margin: 0,
                flexDirection: 'column',
                flexWrap: 'nowrap'
            }
        },
        noResult: {
            textAlign: 'center',
            padding: theme.spacing(5, 2.5)
        },
        allItemsCaption: {
            margin: theme.spacing(6, 0, 3),
            color: colors.textColor
        }
    }));
    const classes = useStyles();

    if (loading) {
        return <Loader/>;
    }

    const showAllItemsLinkAtIndex = index =>
        !relevantStorageSites &&
        (index === 2) &&
        landingPage !== undefined;

    if (storageSites && storageSites.length > 0) {
        return (
            <Box className={classes.root}>
                {
                    storageSites.map((storageSite, index) =>
                        (
                            <React.Fragment key={storageSite.id}>
                                <StorageSiteListItem
                                    storageSite={storageSite}
                                    storageGroupCategory={storageGroupCategory}
                                    showDistance={showDistances}
                                    storageSiteLinkCreator={storageSiteLinkCreator}
                                    forceSmall={relevantStorageSites}
                                    showStreetAddress
                                />
                                {
                                    showAllItemsLinkAtIndex(index) &&
                                    (
                                        <Typography variant="h4" className={classes.allItemsCaption}>
                                            {landingPage.allTitle}
                                        </Typography>
                                    )
                                }
                            </React.Fragment>
                        )
                    )
                }
            </Box>
        );
    }

    return (
        <Box className={classes.noResult}>
            <Typography variant="h4">{strings.emptySearchResultTitle}</Typography>
            <Typography variant="body1">
                {strings.formatString(strings.emptySearchResultDescription, <a href={`mailto:${strings.findStorageMail}`}>{strings.findStorageMail}</a>)}
            </Typography>
        </Box>
    );
};

StorageSiteList.propTypes = {
    storageSites: PropTypes.array,
    storageGroupCategory: PropTypes.object,
    loading: PropTypes.bool,
    showDistances: PropTypes.bool,
    storageSiteLinkCreator: PropTypes.func,
    landingPage: PropTypes.object,
    relevantStorageSites: PropTypes.bool
};

export default StorageSiteList;
