import React, { useEffect, useState } from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { fetchOwnerBookings } from 'actions/account/ownerBookingsSection';
import { fetchStorageSites } from 'actions/account/storageSitesSection';
import strings from 'localization/strings';
import queryString from 'query-string';
import { getTabs, getFilteredBookings, isStale } from './BookingsSectionHelper';
import bookingStatuses from 'enums/bookingStatuses';
import { convertToNumber } from 'helpers/StringHelper';
import routes from 'routes';

import BookingsSection from './BookingsSection';
import OwnerBookingListItem from './OwnerBookingListItem';
import BookingFilter from './BookingFilter';
import CopyBookingEmailsToClipboardDialog from './CopyBookingEmailsToClipboardDialog';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import EmailOutlinedIcon from '@material-ui/icons/EmailOutlined';

const OwnerBookingsSection = () => {
    const dispatch = useDispatch();
    const history = useHistory();
    const location = useLocation();

    const filterParams = queryString.parse(location.search);
    filterParams.page = convertToNumber(filterParams.page) || 1; // page numbers are one-based
    filterParams.pageSize = convertToNumber(filterParams.pageSize) || 20;
    const initialIsExpanded = !!(filterParams.status || filterParams.storageSiteId || filterParams.q);

    const [menuAnchorElement, setMenuAnchorElement] = useState(undefined);
    const [copyBookingEmailsToClipboardDialogOpen, setCopyBookingEmailsToClipboardDialogOpen] = useState(false);
    const [bookingFilterExpanded, setBookingFilterExpanded] = useState(initialIsExpanded);

    const authenticationContext = useSelector(state => state.authentication.context);
    const { selectedActor } = authenticationContext;
    const bookings = useSelector(state => state.account.ownerBookingsSection.bookings);
    const bookingsFetchedTime = useSelector(state => state.account.ownerBookingsSection.fetchedTime);
    const storageSites = useSelector(state => state.account.storageSitesSection.storageSites);
    const isLoadingBookings = useSelector(state => state.account.ownerBookingsSection.isLoading);
    const isLoadingStorageSites = useSelector(state => state.account.storageSitesSection.isLoading);
    const isLoading = isLoadingBookings || isLoadingStorageSites;
    const bookingsLoadingError = useSelector(state => state.account.ownerBookingsSection.loadingError);
    const storageSitesLoadingError = useSelector(state => state.account.storageSitesSection.loadingError);
    const loadingError = !!(bookingsLoadingError || storageSitesLoadingError);

    useEffect(() => {
        if(isStale(bookingsFetchedTime)) {
            // we're fetching all bookings for the owner, and put them in the Redux store
            dispatch(fetchOwnerBookings(selectedActor.id));
        }
    }, [isStale(bookingsFetchedTime)]);

    useEffect(() => {
        if(!storageSites) {
            dispatch(fetchStorageSites(selectedActor.id));
        }
    }, [storageSites]);

    const handleFilterChange = filter => {
        const q = queryString.stringify(filter);
        history.push(routes.account.ownerBookings + (q ? '?' + q : ''));
    };

    const handleOpenMenu = event => {
        setMenuAnchorElement(event.currentTarget);
    };

    const handleCloseMenu = () => {
        setMenuAnchorElement(undefined);
    };

    const handleOpenCopyBookingEmailsToClipboardDialog = () => {
        handleCloseMenu();
        setCopyBookingEmailsToClipboardDialogOpen(true);
    };

    const handleCloseCopyBookingEmailsToClipboardDialog = () => {
        setCopyBookingEmailsToClipboardDialogOpen(false);
    };

    const handleFilterExpandedChange = () => {
        setBookingFilterExpanded(!bookingFilterExpanded);
    };

    const title = selectedActor.isTenant && selectedActor.isOwner
        ? strings.accountTabs.ownerBookings
        : strings.accountTabs.bookings;

    const titleActionPanel = (
        <Box>
            <Button aria-controls="context-menu" aria-haspopup="true" onClick={handleOpenMenu}>
                <EmailOutlinedIcon/>
            </Button>
            <Menu
                id="context-menu"
                anchorEl={menuAnchorElement}
                anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
                transformOrigin={{ vertical: 'top', horizontal: 'right' }}
                keepMounted
                open={Boolean(menuAnchorElement)}
                onClose={handleCloseMenu}
                getContentAnchorEl={undefined}
            >
                <MenuItem onClick={handleOpenCopyBookingEmailsToClipboardDialog}>{strings.copyBookingEmailsToClipboard}</MenuItem>
            </Menu>
        </Box>
    );

    const bookingFilter = (
        <BookingFilter
            storageSites={storageSites}
            filterParams={filterParams}
            onFilterChange={handleFilterChange}
            onExpandedChange={handleFilterExpandedChange}
            expanded={bookingFilterExpanded}
        />
    );

    const tabs = getTabs(true /* excludeCanceledBookings */);

    const filteredBookings = getFilteredBookings(bookings, filterParams);

    const bookingsForCopyBookingEmailsToClipboardDialog = filteredBookings
        ? filteredBookings.filter(o => o.bookingStatus === bookingStatuses.purchased.key)
        : undefined;

    return (
        <>
            <BookingsSection
                storageSites={storageSites}
                bookings={bookings}
                filteredBookings={filteredBookings}
                isLoading={isLoading}
                loadingError={loadingError}
                tabs={tabs}
                filterParams={filterParams}
                onFilterChange={handleFilterChange}
                title={title}
                titleActionPanel={titleActionPanel}
                renderBooking={booking => <OwnerBookingListItem booking={booking}/>}
                bookingFilter={bookingFilter}
                paginated
            />
            <CopyBookingEmailsToClipboardDialog
                bookings={bookingsForCopyBookingEmailsToClipboardDialog}
                open={copyBookingEmailsToClipboardDialogOpen}
                onClose={handleCloseCopyBookingEmailsToClipboardDialog}
            />
        </>
    );
};

export default OwnerBookingsSection;
