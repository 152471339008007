import { makeStyles as materialUiMakeStyles, withStyles as materialUiWithStyles } from '@material-ui/core/styles';
import { styled as materialUiStyled } from '@material-ui/core';
import { getCurrentBranchKey } from 'helpers/BranchHelper';
import { getCurrentMarketKey } from 'helpers/MarketHelper';
import { nestedMerge } from 'helpers/ObjectHelper';
import branches from 'enums/branches';
import markets from 'enums/markets';

// the current branch and market are stored in the app context,
// but we can't use the app context outside of React components
// instead, use a shortcut to extract the current branch and market
const currentBranch = branches[getCurrentBranchKey()] ?? branches.storage;
const currentMarket = markets[getCurrentMarketKey()] ?? markets.sweden;

const images = { ...currentBranch.images, ...currentMarket.images };

export const makeStyles = (stylesFunc, branchStylesFunc) => {
    return materialUiMakeStyles(theme => {
        const styleFuncParameter = {
            theme,
            colors: currentBranch.colors,
            fonts: currentBranch.fonts,
            styles: currentBranch.styles,
            images
        };
        const response = stylesFunc(styleFuncParameter);
        if(currentBranch && branchStylesFunc) {
            const branchResponse = branchStylesFunc(styleFuncParameter);
            if(branchResponse[currentBranch.key]) {
                return nestedMerge(response, branchResponse[currentBranch.key]);
            }
        }
        return response;
    });
};

export const withStyles = (stylesFunc, branchStylesFunc) => {
    return materialUiWithStyles(theme => {
        const styleFuncParameter = {
            theme,
            colors: currentBranch.colors,
            fonts: currentBranch.fonts,
            styles: currentBranch.styles,
            images
        };
        const response = stylesFunc(styleFuncParameter);
        if(currentBranch && branchStylesFunc) {
            const branchResponse = branchStylesFunc(styleFuncParameter);
            if(branchResponse[currentBranch.key]) {
                return nestedMerge(response, branchResponse[currentBranch.key]);
            }
        }
        return response;
    });
    // Implement merge of branch styles if needed
};

export const styled = arg => {
    const func = materialUiStyled(arg);
    return ({ materialUiStyledArgs }) => func({
        ...materialUiStyledArgs,
        colors: currentBranch.colors,
        fonts: currentBranch.fonts,
        styles: currentBranch.styles,
        images
    });
};
