import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from 'styles/util';
import { getResizedImageUrl } from 'helpers/ImageHelper';

import DefaultImage from 'assets/images/storage365_object_defaultplacementimage.jpg';
import Box from '@material-ui/core/Box';

const useStyles = makeStyles(({ colors }) => ({
    storageSiteImage: {
        position: 'relative',
        width: '100%',
        paddingBottom: '56.2%',
        '& img': {
            position: 'absolute',
            objectFit: 'cover',
            width: '100%',
            height: '100%'
        }
    },
    overlayIcon: {
        position: 'absolute',
        bottom: '20%',
        left: 0,
        right: 0,
        '& > *': {
            display: 'inline-block',
            width: '100%',
            textAlign: 'center',
            color: colors.white
        }
    }
}));

const StorageSiteImage = ({ storageSite, storageTypeItem, imageOptions }) => {
    const classes = useStyles();
    if (storageSite.images.length >= 1) {
        return (
            <Box className={classes.storageSiteImage + ' ' + classes.className}>
                <img src={getResizedImageUrl(storageSite.images[0].mediaUrl, imageOptions)} alt={storageSite.title}/>
            </Box>
        );
    }

    return (
        <Box className={classes.storageSiteImage + ' ' + classes.className}>
            <img src={DefaultImage} />
            <Box className={classes.overlayIcon}>
                <Box className={storageTypeItem.icon}/>
            </Box>
        </Box>
    );
};

StorageSiteImage.propTypes = {
    storageSite: PropTypes.object.isRequired,
    storageTypeItem: PropTypes.object.isRequired,
    imageOptions: PropTypes.object.isRequired
};

export default StorageSiteImage;
