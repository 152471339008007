import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { makeStyles } from 'styles/util';
import { required } from 'form/validation';
import strings from 'localization/strings';
import { useAppContext } from 'context/AppContext';

import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import IconButton from '@material-ui/core/IconButton';
import Form from 'form/Form';
import { TextField, Checkboxes, Radios } from 'mui-rff';
import Text from 'common/Text';
import IconSendMessage from 'common/IconSendMessage';

const useStyles = makeStyles(({ theme, colors }) => ({
    shortText: {
        fontSize: '80%',
    },
    longText: {
        color: colors.darkGrey,
        fontSize: '80%',
        marginTop: theme.spacing(1)
    },
    messageField: {
        '& .MuiInputBase-root': {
            borderRadius: '30px'
        },
        '& .MuiOutlinedInput-notchedOutline': {
            borderColor: colors.lightGreyBorder ?? colors.mediumGrey
        },
        '& .MuiFormLabel-root': {
            color: colors.mediumDarkGrey
        },
        '& .MuiFormLabel-asterisk': {
            display: 'none'
        },
        '& textarea': {
            marginRight: theme.spacing(5)
        },
        '& ::-webkit-scrollbar': {
            width: '4px'
        },
        '& ::-webkit-scrollbar-track': {
            background: 'transparent'
        },
        '& ::-webkit-scrollbar-thumb': {
            background: colors.lightGreyBorder ?? colors.mediumGrey,
            borderRadius: '4px'
        }
    },
    sendMessage: {
        position: 'relative'
    },
    buttonContainer: {
        position: 'absolute',
        right: 0,
        top: '50%',
        transform: 'translateY(-50%)',
        zIndex: 1,
        '&:disabled svg': {
            fill: colors.mediumGrey
        }
    }
}));

const MessageEditor = ({ actor, /* conversation, */ onSubmit }) => {
    const classes = useStyles();
    const authenticationContext = useSelector(state => state.authentication.context);
    const [messageConsentLongTextVisible, setMessageConsentLongTextVisible] = useState(false);

    const { appContext } = useAppContext();

    const initialValues = {
        messageText: '',
        consentGiven: authenticationContext.isSuperAdminOrImpersonated,
        sendMessageAsAdmin: 'false'
    };

    // must use strings as values
    const sendMessageAsAdminItems = [
        { label: strings.formatString(strings.sendingMessageAsUser, actor.name), value: 'false' },
        { label: strings.sendingMessageAsAdmin, value: 'true' }
    ];

    const handleReadMoreClick = (e) => {
        e.preventDefault();
        setMessageConsentLongTextVisible(!messageConsentLongTextVisible);
    };

    const onFormSubmit = formValues => {
        onSubmit({
            ...formValues,
            sendMessageAsAdmin: formValues.sendMessageAsAdmin === 'true' // string -> bool
        });
    };

    return (
        <Form
            initialValues={initialValues}
            onSubmit={onFormSubmit}
        >
            {({ handleSubmit, submitting, invalid, values }) => (
                <form onSubmit={handleSubmit}>
                    <Grid container>
                        {
                            authenticationContext.isImpersonated &&
                            (
                                <Grid item xs={12}>
                                    <Radios
                                        name="sendMessageAsAdmin"
                                        data={sendMessageAsAdminItems}
                                    />
                                </Grid>
                            )
                        }
                        <Grid item xs={12} className={classes.sendMessage}>
                            <TextField
                                className={classes.messageField}
                                name="messageText"
                                label={strings.newMessagePlaceholder}
                                variant="outlined"
                                showError={() => { return false; }}
                                required
                                fieldProps={{ validate: required }}
                                multiline
                                maxRows={4}
                            />
                             <IconButton
                                className={classes.buttonContainer}
                                type="submit"
                                color="primary"
                                variant="contained"
                                disabled={submitting || invalid || !values.consentGiven}
                            >
                                <IconSendMessage fill={appContext.colors.primaryColor}/>
                            </IconButton>
                        </Grid>
                        <Grid item xs={12} md={9}>
                            {
                                values.messageText && !authenticationContext.isSuperAdminOrImpersonated &&
                                (
                                    <>
                                        <Box>
                                            <Checkboxes
                                                name="consentGiven"
                                                data={{
                                                    label: (
                                                        <span className={classes.shortText}>
                                                            {strings.messageConsent.shortText}
                                                            {' '}
                                                            <a href={void(0)} onClick={handleReadMoreClick}>{strings.messageConsent.readMore}</a>
                                                        </span>
                                                    ),
                                                    value: true
                                                }}
                                            />
                                        </Box>
                                        {
                                            messageConsentLongTextVisible && <Text className={classes.longText} html={strings.messageConsent.longText}/>
                                        }
                                    </>
                                )
                            }
                        </Grid>
                    </Grid>
                </form>
            )}
        </Form>
    );
};

MessageEditor.propTypes = {
    actor: PropTypes.object.isRequired,
    conversation: PropTypes.object.isRequired,
    onSubmit: PropTypes.func.isRequired
};

export default MessageEditor;
