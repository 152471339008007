import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router';
import { useDispatch } from 'react-redux';
import { fetchPurchase } from 'actions/purchases';
import { handleResponse } from 'actions/actionHelpers';
import { fetchTakeoverTenant } from 'actions/takeoverTenants';
import strings from 'localization/strings';
import { makeStyles } from 'styles/util';

import Container from 'common/Container';
import Header from 'components/paymentDocument/Header';
import Loader from 'common/Loader';
import InvoiceInformation from './InvoiceInformation';
import TakeoverInvoiceInformation from './TakeoverInvoiceInformation';

const useStyles = makeStyles(({ colors }) => ({
    container: {
        fontSize: '10px',
        '& *': {
            color: colors.black
        }
    }
}));

// url: /billecta-invoice-information-pages/:purchaseId/:bookingToken
const BillectaInvoiceInformationPage = () => {
    const classes = useStyles();
    const { purchaseId, token } = useParams();
    const dispatch = useDispatch();

    const [purchase, setPurchase] = useState(undefined);
    const [takeoverTenant, setTakeoverTenant] = useState(undefined);
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        dispatch(fetchPurchase(purchaseId, token, true /* includeBookingTenantQrCode */))
            .then(handleResponse(
                response => {
                    setPurchase(response.payload);
                    if(!response.payload.booking.isTakeoverBooking) {
                        setIsLoading(false);
                    }
                },
                () => {
                    setIsLoading(false);
                }
            ));
    }, []);

    useEffect(() => {
        if(purchase?.booking.isTakeoverBooking) {
            dispatch(fetchTakeoverTenant(purchase.booking.takeoverTenantId, token, false /* setLinkClickedStatus */, true /* includeQrCode */))
                .then(handleResponse(
                    response => {
                        setTakeoverTenant(response.payload);
                        setIsLoading(false);
                    },
                    () => {
                        setIsLoading(false);
                    }
                ));
        }
    }, [purchase]);

    if(isLoading) {
        return <Loader />;
    }

    return (
        <Container className={classes.container}>
            <Header text={strings.invoiceInformationTitle} />
            {
                purchase.booking.isTakeoverBooking && <TakeoverInvoiceInformation purchase={purchase} takeoverTenant={takeoverTenant} />
            }
            {
                !purchase.booking.isTakeoverBooking && <InvoiceInformation purchase={purchase} />
            }
        </Container>
    );
};

export default BillectaInvoiceInformationPage;
