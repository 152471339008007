import landingPages from 'enums/landingPages';

export const getFilteredLandingPages = appContext => {
    const filteredLandingPages = {};
    appContext.storageGroupCategories.forEach(category => {
        const landingPage = landingPages[category.key];
        filteredLandingPages[landingPage.key] = landingPage;
    });
    return filteredLandingPages;
};

export const getLandingPageFromUrlParam = (urlParam, filteredLandingPages) => {
    let matchingLandingPage;
    Object.values(filteredLandingPages).forEach(landingPage => {
        if(matchesUrl(landingPage, urlParam)) {
            matchingLandingPage = landingPage;
        }
        landingPage.aliases.forEach(aliasLandingPage => {
            if(matchesUrl(aliasLandingPage, urlParam)) {
                matchingLandingPage = aliasLandingPage;
            }
        });
    });

    return matchingLandingPage;
};

const matchesUrl = (landingPage, urlParam) => landingPage.url === `/${urlParam}`;
