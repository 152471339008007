import React from 'react';
import strings from 'localization/strings';
import { makeStyles } from 'styles/util';

import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Text from 'common/Text';

const useStyles = makeStyles(() => ({
    accordions: {
        marginTop: '2em',
        '& li': {
            marginBottom: '0.5em',
            '&:last-child': {
                marginBottom: '0'
            }
        }
    }
}));

const AddToHomeScreenInstructions = () => {
    const classes = useStyles();
    const instructions = [ 'androidChrome', 'androidSamsungInternet', 'iOS' ]
        .map(key => ({ title: strings.browserNames[key], content: strings.addToHomeScreenInstructions[key] }));

    return (
        <>
            <Typography variant="h6" gutterBottom>
                {strings.addToHomeScreenTitle}
            </Typography>

            <Typography>
                {strings.addToHomeScreenMessage}
            </Typography>

            <Box className={classes.accordions}>
                {
                    instructions.map(instruction => (
                        <Accordion key={instruction.title}>
                            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                                <Typography>{instruction.title}</Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                                <Text component="div" html={instruction.content} />
                            </AccordionDetails>
                        </Accordion>
                    ))
                }
            </Box>
        </>
    );
};

export default AddToHomeScreenInstructions;
