import React from 'react';
import PropTypes from 'prop-types';
import strings from 'localization/strings';
import { makeStyles } from 'styles/util';

import Amount from 'common/Amount';
import RoundingCorrection from './RoundingCorrection';

const useStyles = makeStyles(({ colors }) => ({
    specificationList: {
        width: '100%',
        borderCollapse: 'collapse',
        '& th:first-child': {
            borderRadius: '0.25em 0 0 0.25em',
        },
        '& th:last-child': {
            borderRadius: '0 0.25em 0.25em 0',
        },
        '& thead th': {
            padding: '0.5em'
        },
        '& thead': {
            color: colors.white,
            fontWeight: 'bold',
            backgroundColor: colors.primaryColor
        },
        '& td': {
            padding: '0.15em'
        },
        '& th': {
            padding: '0.15em'
        },
        '& tbody tr:first-child td': {
            paddingTop: '0.5em'
        }
    },
    description: {
        textAlign: 'left',
        paddingRight: '1em'

    },
    price: {
        textAlign: 'right',
        width: '7em'
    }
}));

const PostInvoiceSpecificationList = ({ purchase }) => {
    const classes = useStyles();

    return (
        <table className={classes.specificationList}>
            <thead>
                <tr>
                    <th colSpan={2} className={classes.description}>
                        {strings.paymentDocuments.productOrService}
                    </th>
                    <th className={classes.price}>
                        {strings.price}
                    </th>
                </tr>
            </thead>
            <tbody>
                {
                    purchase.purchaseItems.map((item, i) => (
                        <tr key={i}>
                            <td colSpan={2} className={classes.description}>
                                {item.description}
                            </td>
                            <td className={classes.price}>
                                <Amount value={item.amount + item.vat} currency={item.currency} displayPriceRounding={false} />
                            </td>
                        </tr>
                    ))
                }
                <RoundingCorrection purchase={purchase} />
            </tbody>
        </table>
    );
};

PostInvoiceSpecificationList.propTypes = {
    purchase: PropTypes.object.isRequired
};

export default PostInvoiceSpecificationList;
