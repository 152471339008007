import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { makeStyles } from 'styles/util';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { fetchOwnerBooking, updateOwnerBooking, endOwnerSubscriptionBooking, createPostInvoice, deletePurchase, createBookingNote } from 'actions/ownerBookings';
import { fetchOwnerBookings } from 'actions/account/ownerBookingsSection';
import { fetchTenantBookings } from 'actions/account/tenantBookingsSection';
import { listConversations } from 'actions/account/conversationsSection';
import { createMessage } from 'actions/messages';
import { handleResponse } from 'actions/actionHelpers';
import strings from 'localization/strings';
import bookingStatuses from 'enums/bookingStatuses';
import adminBookingStatuses from 'enums/adminBookingStatuses';
import { googleTagManagerConstants, createGoogleTagManagerUserAction } from 'integration/google-tag-manager/googleTagManagerHelper';
import { getStorageSiteAndStorageTitle } from 'helpers/StorageSiteHelper';
import routes from 'routes';
import { getBookingDescription } from 'logic/bookingLogic';
import { getBookingChipText, getBookingPeriod } from './BookingsSectionHelper';

import PageTitle from 'common/PageTitle';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import StorageSiteImage from '../StorageSiteImage';
import { Link } from 'react-router-dom';
import BookingNextAction from './BookingNextAction';
import PriceSpecificationAccordion from './PriceSpecificationAccordion';
import Loader from 'common/Loader';
import StatusChip from 'common/StatusChip';
import Agreement from './Agreement';
import Purchases from './Purchases';
import BookingNotes from './BookingNotes';
import Separator from 'common/Separator';
import ButtonContainer from 'common/ButtonContainer';
import AcceptDialog from './AcceptDialog';
import RejectDialog from './RejectDialog';
import EndDialog from './EndDialog';
import CreatePostInvoiceDialog from './CreatePostInvoiceDialog';
import DeletePostInvoiceDialog from './DeletePostInvoiceDialog';
import CreateBookingNoteDialog from './CreateBookingNoteDialog';
import MessageDialog from 'components/MessageDialog';
import ChangeStorageDialog from './ChangeStorageDialog';
import ChangeBookingDialog from './ChangeBookingDialog';
import FullScreenLoader from 'common/FullScreenLoader';
import LoadingError from 'common/LoadingError';
import MarginBox from 'common/MarginBox';
import InformationItems from './InformationItems';
import BookingDescription from './BookingDescription';
import Alert from '@material-ui/lab/Alert';
import ChatBubbleRoundedIcon from '@material-ui/icons/ChatBubbleRounded';

const useStyles = makeStyles(({ theme, colors }) => ({
    container: {
        display: 'flex',
    },
    image: {
        flex: '0 0 auto',
        padding: theme.spacing(0, 1, 0, 0),
        lineHeight: 0
    },
    information: {
        flex: '1 1 50%',
        flexDirection: 'column',
        display: 'flex',
        justifyContent: 'space-between',
        padding: theme.spacing(0, 1, 0, 1),
        '& > *': {
            flex: '0 0 auto'
        }
    },
    detailsButton: {
        flex: '0 0 auto',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-around'
    },
    alert: {
        marginTop: theme.spacing(2)
    },

    mobileFirstLine: {
        display: 'flex',
        marginBottom: theme.spacing(1)
    },
    mobileImage: {
        flex: '1 0 auto',
        padding: theme.spacing(0, 1, 0, 0),
        lineHeight: 0
    },
    mobileDetailsButton: {
        flex: '0 0 auto',
        color: `${colors.icon} !important`,
        transform: 'scale(2)'
    },
    mobileBookingChip: {
        marginBottom: theme.spacing(1)
    },
    mobileNextAction: {
        marginTop: theme.spacing(2)
    },
    conversationContainer: {
        display: 'flex',
        flexWrap: 'wrap',
        alignItems: 'center',
        '& > *': {
            flex: '0 0 auto'
        },
        '& > :first-child': {
            paddingRight: theme.spacing(1)
        }
    },
    imageContainer: {
        '& img': {
            width: '100%',
            height: 'auto'
        }
    }
}));

const OwnerBookingDetails = () => {
    const classes = useStyles();
    const isMobile = !useMediaQuery(theme => theme.breakpoints.up('md'));
    const params = useParams();
    const dispatch = useDispatch();
    const history = useHistory();
    const bookingId = parseInt(params.bookingId, 10);

    const authenticationContext = useSelector(state => state.authentication.context);
    const { user, selectedActor } = authenticationContext;

    const [booking, setBooking] = useState(undefined);
    const [isLoading, setIsLoading] = useState(true);
    const [isSaving, setIsSaving] = useState(false);
    const [loadingError, setLoadingError] = useState(false);
    const [postInvoicePurchaseToDelete, setPostInvoicePurchaseToDelete] = useState(undefined);
    const [acceptDialogOpen, setAcceptDialogOpen] = useState(false);
    const [rejectDialogOpen, setRejectDialogOpen] = useState(false);
    const [endDialogOpen, setEndDialogOpen] = useState(false);
    const [createPostInvoiceDialogOpen, setCreatePostInvoiceDialogOpen] = useState(false);
    const [deletePostInvoiceDialogOpen, setDeletePostInvoiceDialogOpen] = useState(false);
    const [messageDialogOpen, setMessageDialogOpen] = useState(false);
    const [changeStorageDialogOpen, setChangeStorageDialogOpen] = useState(false);
    const [createBookingNoteDialogOpen, setCreateBookingNoteDialogOpen] = useState(false);
    const [changeBookingDialogOpen, setChangeBookingDialogOpen] = useState(false);

    const fetchBooking = () => {
        dispatch(fetchOwnerBooking(bookingId))
            .then(handleResponse(
                response => {
                    setBooking(response.payload);
                    setIsLoading(false);
                },
                () => {
                    setLoadingError(true);
                    setIsLoading(false);
                }
            ));
    };

    const refetchDataFromApi = () => {
        fetchBooking();
        dispatch(fetchOwnerBookings(selectedActor.id));
        dispatch(fetchTenantBookings(selectedActor.id));
    };

    useEffect(fetchBooking, [bookingId]);

    const storageGroup = booking?.storageGroup;

    if (isLoading) {
        return <Loader />;
    }

    if(loadingError || !storageGroup) {
        return <MarginBox top={2}><LoadingError /></MarginBox>;
    }

    const storageSite = storageGroup.storageSite;
    const title = getStorageSiteAndStorageTitle(booking.storage, storageGroup);

    const imageUrl = storageSite.images[0]
        ? storageSite.images[0].mediaUrl
        : undefined;

    const status = booking.adminBookingStatus;
    const canAccept = status === adminBookingStatuses.requested.key;
    const canReject = status === adminBookingStatuses.requested.key || status === adminBookingStatuses.confirmed.key;
    const canEnd = booking.subscriptionBooking && (status === adminBookingStatuses.notStarted.key || status === adminBookingStatuses.active.key);
    const canCreatePostInvoice = status === adminBookingStatuses.notStarted.key || status === adminBookingStatuses.active.key || status === adminBookingStatuses.subscriptionEnding.key || status === adminBookingStatuses.ended.key;

    const googleTagManagerMetadata = `${storageGroup.category}-${(booking.subscriptionBooking ? 'subscription' : 'period')}`;

    let alert;
    if(booking.subscriptionBooking && booking.subscriptionInfo?.hasUnpaidInvoiceWithFailedPayments) {
        alert = <Alert className={classes.alert} severity="error">{strings.subscriptionBookingPurchasePaymentFailedOwner}</Alert>;
    }


    // accept dialog

    const handleAccept = () => {
        setAcceptDialogOpen(true);
    };

    const handleAcceptDialogOk = values => {
        const successContent = { dialog: { title: strings.acceptSucceededTitle, body: strings.acceptSucceededBody, useAnimation: true } };
        const failureContent = { dialog: { title: strings.acceptFailedTitle, body: strings.acceptFailedBody } };

        const updateBookingRequest = {
            storageId: values.storageId,
            bookingStatus: bookingStatuses.confirmed.key,
            goodsType: booking.goodsType,
            registrationNumber: booking.registrationNumber,
            bookingItems: values.bookingItems
        };

        setAcceptDialogOpen(false);
        setIsSaving(true);
        dispatch(createGoogleTagManagerUserAction(googleTagManagerConstants.userActions.approveBooking, googleTagManagerMetadata));
        dispatch(updateOwnerBooking(booking.id, updateBookingRequest))
            .then(handleResponse(
                () => {
                    setIsSaving(false);
                    refetchDataFromApi();
                    return successContent;
                },
                () => {
                    setIsSaving(false);
                    return failureContent;
                }
            ));
    };

    const handleAcceptDialogCancel = () => {
        setAcceptDialogOpen(false);
    };


    // reject dialog

    const handleReject = () => {
        setRejectDialogOpen(true);
    };

    const handleRejectDialogOk = values => {
        const successContent = { dialog: { title: strings.rejectSucceededTitle, body: strings.ownerRejectSucceededBody, onClose: () => history.push(routes.account.ownerBookings) } };
        const failureContent = { dialog: { title: strings.rejectFailedTitle, body: strings.rejectFailedBody } };

        const updateBookingRequest = {
            storageId: booking.storage ? booking.storage.id : undefined,
            goodsType: booking.goodsType,
            registrationNumber: booking.registrationNumber,
            bookingStatus: bookingStatuses.canceled.key,
            cancellationReason: values.cancellationReason,
            cancellationReasonText: values.cancellationReasonText
        };

        setRejectDialogOpen(false);
        setIsSaving(true);
        dispatch(createGoogleTagManagerUserAction(googleTagManagerConstants.userActions.rejectBooking, googleTagManagerMetadata));
        dispatch(updateOwnerBooking(booking.id, updateBookingRequest))
            .then(handleResponse(
                () => {
                    setIsSaving(false);
                    refetchDataFromApi();
                    return successContent;
                },
                () => {
                    setIsSaving(false);
                    return failureContent;
                }
            ));
    };

    const handleRejectDialogCancel = () => {
        setRejectDialogOpen(false);
    };

    // end dialog

    const handleEnd = () => {
        setEndDialogOpen(true);
    };

    const handleEndDialogOk = () => {
        const successContent = { dialog: { title: strings.endSubscriptionSucceededTitle, body: strings.endSubscriptionSucceededBody } };
        const failureContent = { dialog: { title: strings.endSubscriptionFailedTitle, body: strings.endSubscriptionFailedBody } };

        setEndDialogOpen(false);
        setIsSaving(true);
        dispatch(endOwnerSubscriptionBooking(booking.id))
            .then(handleResponse(
                () => {
                    setIsSaving(false);
                    refetchDataFromApi();
                    return successContent;
                },
                () => {
                    setIsSaving(false);
                    return failureContent;
                }
            ));
    };

    const handleEndDialogCancel = () => {
        setEndDialogOpen(false);
    };


    // create post invoice dialog

    const handleCreatePostInvoice = () => {
        setCreatePostInvoiceDialogOpen(true);
    };

    const handleCreatePostInvoiceDialogOk = postInvoice => {
        const successContent = { dialog: { title: strings.postInvoiceCreationSucceededTitle, body: strings.postInvoiceCreationSucceededBody } };
        const failureContent = { dialog: { title: strings.postInvoiceCreationFailedTitle, body: strings.postInvoiceCreationFailedBody } };

        setCreatePostInvoiceDialogOpen(false);
        setIsSaving(true);
        dispatch(createPostInvoice(booking.id, postInvoice))
            .then(handleResponse(
                () => {
                    setIsSaving(false);
                    refetchDataFromApi();
                    return successContent;
                },
                () => {
                    setIsSaving(false);
                    return failureContent;
                }
            ));
    };

    const handleCreatePostInvoiceDialogCancel = () => {
        setCreatePostInvoiceDialogOpen(false);
    };


    // delete post invoice dialog

    const handleDeletePostInvoice = purchase => {
        setDeletePostInvoiceDialogOpen(true);
        setPostInvoicePurchaseToDelete(purchase);
    };

    const handleDeletePostInvoiceDialogOk = () => {
        const successContent = { dialog: { title: strings.postInvoiceDeletionSucceededTitle, body: strings.postInvoiceDeletionSucceededBody } };
        const failureContent = { dialog: { title: strings.postInvoiceDeletionFailedTitle, body: strings.postInvoiceDeletionFailedBody } };

        setDeletePostInvoiceDialogOpen(false);
        setIsSaving(true);
        dispatch(deletePurchase(booking.id, postInvoicePurchaseToDelete.id))
            .then(handleResponse(
                () => {
                    setIsSaving(false);
                    refetchDataFromApi();
                    return successContent;
                },
                () => {
                    setIsSaving(false);
                    return failureContent;
                }
            ));
    };

    const handleDeletePostInvoiceDialogCancel = () => {
        setDeletePostInvoiceDialogOpen(false);
    };


    // message dialog

    const handleMessage = () => {
        setMessageDialogOpen(true);
    };

    const handleMessageDialogOk = messageText => {
        const successContent = { dialog: { title: strings.postMessageSucceededTitle, body: strings.postMessageSucceededOwnerBody, useAnimation: true } };
        const failureContent = { dialog: { title: strings.postMessageFailedTitle, body: strings.postMessageFailedBody } };

        const createMessageRequest = {
            writerActorId: selectedActor.id,
            writerUserId: user.id,
            text: messageText,
            storageSiteId: storageSite.id,
            recipientActorId: booking.tenantActor.id
        };

        setMessageDialogOpen(false);
        setIsSaving(true);
        dispatch(createMessage(createMessageRequest))
            .then(handleResponse(
                () => {
                    setIsSaving(false);
                    dispatch(listConversations(selectedActor.id));
                    return successContent;
                },
                () => {
                    setIsSaving(false);
                    return failureContent;
                }
            ));
    };

    const handleMessageDialogClose = () => {
        setMessageDialogOpen(false);
    };


    // change storage

    const handleChangeStorage = () => {
        setChangeStorageDialogOpen(true);
    };

    const handleChangeStorageDialogOk = formValues => {
        const successContent = { dialog: { title: strings.changeBookingSucceededTitle, body: strings.changeBookingSucceededBody } };
        const failureContent = { dialog: { title: strings.changeBookingFailedTitle, body: strings.changeBookingFailedBody } };

        const updateBookingRequest = {
            storageId: formValues.storageId,
            bookingStatus: booking.bookingStatus,
            goodsType: booking.goodsType,
            registrationNumber: booking.registrationNumber
        };

        setChangeStorageDialogOpen(false);
        setIsSaving(true);
        dispatch(updateOwnerBooking(booking.id, updateBookingRequest))
            .then(handleResponse(
                response => {
                    setIsSaving(false);
                    setBooking(response.payload);
                    return successContent;
                },
                () => {
                    setIsSaving(false);
                    return failureContent;
                }
            ));
    };

    const handleChangeStorageDialogCancel = () => {
        setChangeStorageDialogOpen(false);
    };

    // create booking note
    const handleCreateBookingNote = () => {
        setCreateBookingNoteDialogOpen(true);
    };

    const handleCreateBookingNoteDialogOk = (formValues, form) => {
        const request = {
            text: formValues.text
        };
        setCreateBookingNoteDialogOpen(false);
        setIsSaving(true);
        dispatch(createBookingNote(params.bookingId, request))
            .then(handleResponse(
                response => {
                    const newBooking = { ...booking };
                    newBooking.notes.push(response.payload);
                    setBooking(newBooking);
                    setIsSaving(false);
                    form.reset();
                },
                () => {
                    setIsSaving(false);
                }
            ));
    };

    const handleCreateBookingNoteDialogCancel = () => {
        setCreateBookingNoteDialogOpen(false);
    };

    // change booking

    const handleChangeBooking = () => {
        setChangeBookingDialogOpen(true);
    };

    const handleChangeBookingDialogOk = formValues => {
        const successContent = { dialog: { title: strings.changeBookingSucceededTitle, body: strings.changeBookingSucceededBody } };
        const failureContent = { dialog: { title: strings.changeBookingFailedTitle, body: strings.changeBookingFailedBody } };

        const updateBookingRequest = {
            storageId: booking.storageId,
            bookingStatus: booking.bookingStatus,
            goodsType: formValues.goodsType,
            registrationNumber: formValues.registrationNumber
        };

        setChangeBookingDialogOpen(false);
        setIsSaving(true);
        dispatch(updateOwnerBooking(booking.id, updateBookingRequest))
            .then(handleResponse(
                response => {
                    setIsSaving(false);
                    setBooking(response.payload);
                    return successContent;
                },
                () => {
                    setIsSaving(false);
                    return failureContent;
                }
            ));
    };

    const handleChangeBookingDialogCancel = () => {
        setChangeBookingDialogOpen(false);
    };

    return (
        <>
            <PageTitle>{title}</PageTitle>
            <Box className={classes.imageContainer}>
                <StorageSiteImage storageSite={storageSite} imageUrl={imageUrl} width={980} height={613} />
            </Box>
            <Box>
                <Typography variant="h2">{title}</Typography>
                { storageSite && <Typography>{storageSite.title}</Typography> }
            </Box>
            <BookingNextAction booking={booking} fullWidth isOwner />

            <StatusChip text={getBookingPeriod(booking)} heading={getBookingChipText(booking)} status={bookingStatuses[booking.bookingStatus].chipType} />

            {alert}

            <Separator spacing={0}/>

            <InformationItems booking={booking} isOwner onChangeStorage={handleChangeStorage} />

            <Separator spacing={0}/>

            {
                getBookingDescription(booking) &&
                (
                    <>
                        <BookingDescription booking={booking} onChangeBooking={handleChangeBooking} />
                        <Separator spacing={0}/>
                    </>
                )
            }

            <PriceSpecificationAccordion booking={booking} />
            <Separator spacing={0}/>

            {
                booking.agreementUrl &&
                (
                    <>
                        <Agreement booking={booking} />
                        <Separator spacing={0}/>
                    </>
                )
            }

            {
                booking.purchases.length > 0 &&
                (
                    <Purchases booking={booking} isOwner onDeletePurchase={handleDeletePostInvoice} />
                )
            }

            {
                booking.conversationId &&
                (
                    <>
                        <Box className={classes.conversationContainer}>
                            <ChatBubbleRoundedIcon />
                            <Link to={routes.account.conversationDetails.replace(':conversationId', booking.conversationId)}>{strings.showConversationForBooking}</Link>
                        </Box>
                        <Separator spacing={0}/>
                    </>
                )
            }

            {
                booking.notes.length > 0 &&
                (
                    <>
                        <BookingNotes booking={booking}/>
                        <Separator spacing={0}/>
                    </>
                )
            }

            <ButtonContainer>
                {
                    canAccept &&
                    (
                        <Button
                            type="button"
                            color="primary"
                            variant="contained"
                            fullWidth={isMobile}
                            onClick={handleAccept}
                        >
                            {strings.accept}
                        </Button>
                    )
                }
                {
                    canReject &&
                    (
                        <Button
                            type="button"
                            color="primary"
                            variant="contained"
                            fullWidth={isMobile}
                            onClick={handleReject}
                        >
                            {strings.reject}
                        </Button>
                    )
                }
                {
                    canEnd &&
                    (
                        <Button
                            type="button"
                            color="primary"
                            variant="contained"
                            fullWidth={isMobile}
                            onClick={handleEnd}
                        >
                            {strings.endSubscriptionBooking}
                        </Button>
                    )
                }
                {
                    canCreatePostInvoice &&
                    (
                        <Button
                            type="button"
                            color="primary"
                            variant="contained"
                            fullWidth={isMobile}
                            onClick={handleCreatePostInvoice}
                        >
                            {strings.createPostInvoice}
                        </Button>
                    )
                }
                <Button
                    type="button"
                    color="primary"
                    variant="contained"
                    fullWidth={isMobile}
                    onClick={handleCreateBookingNote}
                >
                    {strings.createBookingNote}
                </Button>
                <Button
                    type="button"
                    color="primary"
                    variant="contained"
                    fullWidth={isMobile}
                    onClick={handleMessage}
                >
                    {strings.contactTheTenant}
                </Button>
                <Button
                    type="button"
                    color="secondary"
                    variant="contained"
                    fullWidth={isMobile}
                    component={Link}
                    to={routes.account.ownerBookings}
                >
                    {strings.goBack}
                </Button>
            </ButtonContainer>

            <AcceptDialog booking={booking} open={acceptDialogOpen} onOk={handleAcceptDialogOk} onCancel={handleAcceptDialogCancel} />
            <RejectDialog isOwner open={rejectDialogOpen} onOk={handleRejectDialogOk} onCancel={handleRejectDialogCancel} />
            <EndDialog isOwner booking={booking} open={endDialogOpen} onOk={handleEndDialogOk} onCancel={handleEndDialogCancel} />
            <CreatePostInvoiceDialog booking={booking} open={createPostInvoiceDialogOpen} onOk={handleCreatePostInvoiceDialogOk} onCancel={handleCreatePostInvoiceDialogCancel} />
            <CreateBookingNoteDialog open={createBookingNoteDialogOpen} onOk={handleCreateBookingNoteDialogOk} onCancel={handleCreateBookingNoteDialogCancel} />
            <DeletePostInvoiceDialog open={deletePostInvoiceDialogOpen} onOk={handleDeletePostInvoiceDialogOk} onCancel={handleDeletePostInvoiceDialogCancel} />
            <MessageDialog title={strings.contactTheTenant} content={strings.sendAMessageToTenant} open={messageDialogOpen} onOk={handleMessageDialogOk} onClose={handleMessageDialogClose} />
            <ChangeStorageDialog booking={booking} open={changeStorageDialogOpen} onOk={handleChangeStorageDialogOk} onCancel={handleChangeStorageDialogCancel} />
            <ChangeBookingDialog booking={booking} open={changeBookingDialogOpen} onOk={handleChangeBookingDialogOk} onCancel={handleChangeBookingDialogCancel} />
            <FullScreenLoader open={isSaving} />
        </>
    );
};

export default OwnerBookingDetails;
