import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from 'styles/util';

import Link from '@material-ui/core/Link';

const useStyles = makeStyles(() => ({
    button: {
        textAlign: 'left'
    },
    small: {
        fontSize: '0.875rem'
    }
}));

// a link (underlined text) that isn't required to have an href to work
// renders a <button> element
const LinkButton = props => {
    const classes = useStyles();
    const modifiedProps = {
        ...props,
        size: undefined
    };
    let className = props.className ?? '';
    if(props.size === 'small') {
        className += ' ' + classes.small;
    }
    return (
        <Link
            component="button"
            type="button"
            {...modifiedProps}
            className={classes.button + ' ' + className}
        />
    );
};

LinkButton.propTypes = {
    size: PropTypes.string,
    className: PropTypes.string,
    children: PropTypes.node.isRequired
};

export default LinkButton;
