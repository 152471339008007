import strings from 'localization/strings';

const rentalTypes = {
    wholeSite: {},
    customArea: {},
    fixedArea: {}
};

Object.keys(rentalTypes).forEach(key => {
    rentalTypes[key].key = key;
});

strings.addLoadCallback(() => {
    Object.values(rentalTypes).forEach(o => {
        o.title = strings.rentalTypes[o.key];
        o.description = strings.rentalTypeDescriptions[o.key];
    });
});

export default rentalTypes;
