import * as actionTypes from 'actions/account/conversationsSection';
import * as authenticationActionTypes from 'actions/authentication';

const initialState =  {
    conversations: undefined,
    isLoading: false,
    loadingError: undefined
};

const conversationsSection = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.ACCOUNT_CONVERSATIONS_SECTION_LIST_CONVERSATIONS:
            return { ...state, isLoading: true, conversations: undefined, loadingError: undefined };
        case actionTypes.ACCOUNT_CONVERSATIONS_SECTION_LIST_CONVERSATIONS_SUCCESS:
            return { ...state, isLoading: false, conversations: action.payload };
        case actionTypes.ACCOUNT_CONVERSATIONS_SECTION_LIST_CONVERSATIONS_FAILURE:
            return { ...state, isLoading: false, conversations: undefined, loadingError: action.payload };

        case authenticationActionTypes.AUTHENTICATION_LOGIN:
        case authenticationActionTypes.AUTHENTICATION_LOGOUT:
        case authenticationActionTypes.AUTHENTICATION_IMPERSONATIONLOGOUT:
        case authenticationActionTypes.AUTHENTICATION_GET_CONTEXT:
        case authenticationActionTypes.AUTHENTICATION_REFRESH_CONTEXT_SUCCESS:
            return { ...initialState };

        default:
            return state;
    }
};

export default conversationsSection;
