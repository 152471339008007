import React from 'react';
import { Switch, Route, Redirect, useLocation } from 'react-router-dom';
import { match as pathToRegexpMatch } from 'path-to-regexp';
import strings from 'localization/strings';
import routes from 'routes';

let redirects = [];
strings.addLoadCallback(() => {
    redirects = [
        ['/account/tenantBookings/:bookingId/:token/lockCode', routes.bookingLockInformation],
        ['/account/ownerBookings/:bookingId', routes.account.ownerBookingDetails],
        ['/account/tenantBookings/:bookingId', routes.account.tenantBookingDetails],
        ['/storageSites/:storageGroupCategory/:location/:title/:storageSiteId', routes.storageSiteDetailsExtended],
        ['/storageSites/:storageSiteId', routes.storageSiteDetails],
        ['/storage/:storageSiteId', routes.storageSiteDetails],
        ['/storage', routes.index],
        [routes.bookingLockCode, routes.bookingLockInformation]
    ];
});

const LegacyRouteRedirects = () => {
    const location = useLocation();

    const createRedirectUrl = (legacyUrl, newUrl) => {
        const matcher = pathToRegexpMatch(legacyUrl, { decode: decodeURIComponent });
        const params = matcher(location.pathname).params;

        let url = newUrl;
        if(params) {
            Object.entries(params).forEach(([key, value]) => {
                url = url.replace(`:${key}`, value);
            });
        }
        url += location.search;
        return url;
    };

    return (
        <Switch>
            {
                redirects.map(([legacyUrl, newUrl]) => (
                    <Route key={legacyUrl} path={legacyUrl} render={() => <Redirect to={createRedirectUrl(legacyUrl, newUrl)} />} />
                ))
            }
        </Switch>
    );
};

export default LegacyRouteRedirects;
