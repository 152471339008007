import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from 'styles/util';

const IconEdit = ({fill}) => {
    const useStyles = makeStyles(() => ({
        svg: {
            fill: fill
        }
    }));

    const classes = useStyles();
    return (
        <svg className={classes.svg} width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
            <path d="M14.0588 9.02L14.9788 9.94L5.91878 19H4.99878V18.08L14.0588 9.02ZM17.6588 3C17.4088 3 17.1488 3.1 16.9588 3.29L15.1288 5.12L18.8788 8.87L20.7088 7.04C21.0988 6.65 21.0988 6.02 20.7088 5.63L18.3688 3.29C18.1688 3.09 17.9188 3 17.6588 3ZM14.0588 6.19L2.99878 17.25V21H6.74878L17.8088 9.94L14.0588 6.19Z"/>
        </svg>
    );
};

IconEdit.propTypes = {
    fill: PropTypes.string.isRequired
};

export default IconEdit;
