import LocalizedStrings from 'react-localization';

const loadCallbacks = [];

// resources are fetched from the API, see ResouceProvider
const strings = new LocalizedStrings({
    lang: {} // using a neutral 'lang' key for the language, since we are only loading one language
});
strings.setLanguage('lang');

// enhancing strings.formatString to handle null/undefined arguments
strings.formatStringOriginal = strings.formatString;
strings.formatString = (...args) => {
    const adjustedArguments = [...args];
    for (let i = 1; i < adjustedArguments.length; i++) {
        adjustedArguments[i] = adjustedArguments[i] ?? '';
    }
    return strings.formatStringOriginal(...adjustedArguments);
};

strings.addLoadCallback = callback => loadCallbacks.push(callback);

strings.setStrings = (data, appContext) => {
    strings.setContent(data);
    loadCallbacks.forEach(callback => callback(appContext));
};

strings.pluralize = (template, count) => strings.formatString(template[count === 1 ? 'singular' : 'plural'], count);

export default strings;
