import React from 'react';
import { Helmet } from 'react-helmet-async';
import strings from 'localization/strings';

import Container from 'common/Container';
import Typography from '@material-ui/core/Typography';

const StorageSiteNotFound = () => {
    return (
        <Container smMaxWidth="585px" withPageMargins>
            <Helmet defer={false}>
                <meta name="robots" content="noindex" />
            </Helmet>

            <Typography variant="h1" gutterBottom>{strings.StorageSiteNotFoundTitle}</Typography>
            <Typography variant="body1">{strings.StorageSiteNotFoundMessage}</Typography>
        </Container>
    );
};

export default StorageSiteNotFound;
