import strings from 'localization/strings';

const paymentRecurrences = {
    perMonth: {},
    oneTime: {}
};

Object.keys(paymentRecurrences).forEach(key => {
    paymentRecurrences[key].key = key;
});

strings.addLoadCallback(() => {
    Object.values(paymentRecurrences).forEach(o => {
        o.title = strings.paymentRecurrences[o.key];
    });
    paymentRecurrences.perMonth.priceSuffixTemplate = strings.currencyUnitPerMonth;
    paymentRecurrences.oneTime.priceSuffixTemplate = '{0}';
});

export default paymentRecurrences;
