import React, {useEffect, useRef, useState} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useHistory } from 'react-router-dom';
import { searchStorageSites, setSearchParameters } from 'actions/admin/storageSites';
import { performSearch, mergeSearchParameters } from 'components/adminTable/AdminTableHelper';
import { logIn } from 'actions/authentication';
import { handleResponse } from 'actions/actionHelpers';
import routes from 'routes';

import Loader from 'common/Loader';
import { Link } from 'react-router-dom';
import MaterialUiLink from '@material-ui/core/Link';
import AdminPage from '../../presentational/AdminPage';
import AdminContentWrapper from '../../presentational/AdminContentWrapper';
import SearchStorageSitesForm from './SearchStorageSitesForm';
import AdminTable from 'components/adminTable/AdminTable';
import ViewCountDialog from './ViewCountDialog';

const StorageSites = () => {
    const dispatch = useDispatch();
    const location = useLocation();
    const history = useHistory();

    const searchResult = useSelector(state => state.admin.storageSites.searchResult);
    const searchParametersFromState = useSelector(state => state.admin.storageSites.searchParameters);

    const [viewCountDialogOpen, setViewCountDialogOpen] = useState(false);
    const [viewCountDialogStorageSite, setViewCountDialogStorageSite] = useState(undefined);

    const defaultParameters = {
        title: undefined,
        ownerName: undefined,
        page: 0,
        pageSize: 20,
        sortByColumn: 'title',
        sortDirection: 'asc'
    };

    const handleViewCountDialogCancel = () => {
        setViewCountDialogOpen(false);
        setViewCountDialogStorageSite(undefined);
    };

    const handleViewCountButtonClick = storageSite => {
        setViewCountDialogStorageSite(storageSite);
        setViewCountDialogOpen(true);
    };

    const columns = [
        {
            title: 'ID',
            id: 'id',
            align: 'right',
            render: storageSite => <Link to={routes.admin.storageSite.replace(':storageSiteId', storageSite.id)}>{storageSite.id}</Link>
        },
        {
            title: 'Title',
            id: 'title',
            render: storageSite => (
                <MaterialUiLink
                    component="button"
                    onClick={() => handleEditClick(storageSite)}
                >
                    {storageSite.title}
                </MaterialUiLink>
            )
        },
        {
            title: 'Owner',
            id: 'ownerName',
            render: storageSite => <Link to={routes.admin.actor.replace(':actorId', storageSite.ownerActorId)}>{storageSite.ownerName}</Link>
        },
        {
            title: 'Number of views',
            id: 'numberOfViews',
            align: 'right',
            render: storageSite => (
                <MaterialUiLink
                    component="button"
                    onClick={() => handleViewCountButtonClick(storageSite)}
                >
                    {storageSite.numberOfViews}
                </MaterialUiLink>
            )
        }
    ];

    const search = parameters => {
        const searchParameters = mergeSearchParameters(parameters, location, searchParametersFromState, defaultParameters);
        performSearch(performSearchObject, searchParameters);
    };

    const performSearchObject = {
        searchParametersFromState,
        setSearchParametersToState: parameters => dispatch(setSearchParameters(parameters)),
        searchResult,
        search,
        apiCall: parameters => {
            if(!isEmpty(parameters)) {
                dispatch(searchStorageSites(parameters));
            }
        },
        location,
        history,
        isInitializedRef: useRef()
    };

    const isEmpty = parameters => !parameters.title && !parameters.ownerName;

    useEffect(() => {
        const searchParameters = mergeSearchParameters({}, location, searchParametersFromState, defaultParameters);
        performSearch(performSearchObject, searchParameters);
    }, []);

    const handleSearchFormSubmit = (formValues) => {
        search({ ...defaultParameters, ...formValues, page: 0 });
    };

    const handleSort = (sortingParameters) => {
        search(sortingParameters);
    };

    const handlePageChange = (newPage) => {
        search({ page: newPage });
    };

    const handlePageChangeSize = (newPageSize) => {
        search({ page: 0, pageSize: newPageSize });
    };

    const handleEditClick = storageSite => {
        dispatch(logIn({
            impersonationActorId: storageSite.ownerActorId
        }))
            .then(handleResponse(
                () => history.push(routes.account.storageSiteCapacityUtilizationChart.replace(':storageSiteId', storageSite.id))
            ));
    };

    return (
        <AdminPage title="Storage sites">
            <AdminContentWrapper type="listing">

                <SearchStorageSitesForm
                    searchParameters={searchParametersFromState}
                    onSubmit={handleSearchFormSubmit}
                />

                <div>
                    {
                        searchResult.isLoading && <Loader />
                    }
                    {
                        searchResult.items && (
                            <AdminTable
                                isPaged
                                items={searchResult.items}
                                count={searchResult.count}
                                page={searchResult.page}
                                pageSize={searchResult.pageSize}
                                columns={columns}
                                sortByColumn={columns.find(c => c.id === searchParametersFromState.sortByColumn)}
                                sortDirection={searchParametersFromState.sortDirection}
                                onSort={handleSort}
                                onPageChange={handlePageChange}
                                onPageChangeSize={handlePageChangeSize}
                            />
                        )
                    }
                    {
                        viewCountDialogStorageSite && (
                            <ViewCountDialog
                                open={viewCountDialogOpen}
                                storageSite={viewCountDialogStorageSite}
                                onCancel={handleViewCountDialogCancel}
                            />
                        )
                    }
                </div>

            </AdminContentWrapper>
        </AdminPage>
    );
};

export default StorageSites;
