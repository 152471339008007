import React from 'react';
import * as reports from 'actions/reports';
import { add, sub, startOfDay, startOfMonth, endOfMonth, endOfDay } from 'date-fns';
import { parseIsoDate, formatShortDate, formatIsoDate, formatUtcIsoDateTime } from 'helpers/DateHelper';

import { shorten } from 'helpers/StringHelper';
import strings from 'localization/strings';
import bookingBoundaryTypes from 'enums/bookingBoundaryTypes';
import transactionReportDateTypes from 'enums/transactionReportDateTypes';
import { defaultCompare } from 'helpers/SortHelper';
import routes from 'routes';

import { Link } from 'react-router-dom';
import Tooltip from '@material-ui/core/Tooltip';
import Box from '@material-ui/core/Box';

const reportDefinitions = {};

strings.addLoadCallback(appContext => {
    reportDefinitions.bookingBoundaries = {
        inputFields: [
            { name: 'from', type: 'date', defaultValueGetter: () => startOfDay(new Date()) },
            { name: 'to', type: 'date', defaultValueGetter: () => startOfDay(add(new Date(), { days: 6 })) },
            getStorageSiteInputField(false)
        ],
        fetchData: values => {
            const inputFields = values.inputFields[reportDefinitions.bookingBoundaries.key];
            const parameters = {
                ...inputFields,
                from: formatIsoDate(inputFields.from),
                to: formatIsoDate(inputFields.to),
                storageSiteId: getStorageSiteIdParameter(inputFields)
            };

            return values.format === 'excel'
                ? reports.getBookingBoundariesReportExcelFile(parameters)
                : reports.getBookingBoundariesReport(parameters);
        },
        renderers: {
            bookingId: value => <Link to={routes.account.ownerBookingDetails.replace(':bookingId', value)} target="_blank">{value}</Link>,
            boundaryType: value => bookingBoundaryTypes[value].title,
            description: value => value?.length > 40
                ? <Tooltip disableFocusListener title={value} enterTouchDelay={100}><Box>{shorten(value, 40, true)}</Box></Tooltip>
                : value,
            endDate: value => value
                ? formatShortDate(value, appContext)
                : strings.untilFurther
        }
    };

    reportDefinitions.transactions = {
        inputFields: [
            { name: 'from', type: 'month', defaultValueGetter: () => startOfMonth(sub(new Date(), { months: 1 })) },
            { name: 'to', type: 'month', defaultValueGetter: () => startOfMonth(sub(new Date(), { months: 1 })) },
            getTransactionReportDateTypeInputField(),
            getStorageSiteInputField(true),
            {
                name: 'actorIds',
                type: 'checkboxes',
                dataGetter: ({ authenticationContext }) => authenticationContext.actors.map(actor => ({ label: actor.name, value: actor.id })),
                defaultValueGetter: ({ authenticationContext }) => authenticationContext.actors.map(actor => actor.id ),
                visibleGetter: ({ authenticationContext }) => authenticationContext.actors.length > 1
            }
        ],
        fetchData: values => {
            const inputFields = values.inputFields[reportDefinitions.transactions.key];
            const parameters = {
                ...inputFields,
                from: formatUtcIsoDateTime(startOfMonth(inputFields.from)),
                to: formatUtcIsoDateTime(endOfMonth(inputFields.to)),
                storageSiteId: getStorageSiteIdParameter(inputFields),
                actorIds: inputFields.actorIds.join(',')
            };

            return values.format === 'excel'
                ? reports.getTransactionsReportExcelFile(parameters)
                : reports.getTransactionsReport(parameters);
        },
        renderers: {
            bookingId: value => <Link to={routes.account.ownerBookingDetails.replace(':bookingId', value)} target="_blank">{value}</Link>,
            ownerPaymentDocumentUrl: value => <Link to={value} target="_blank">{strings.receipt}</Link>
        }
    };

    reportDefinitions.bookingOverview = {
        inputFields: [
            { name: 'date', type: 'date', defaultValueGetter: () => startOfDay(new Date()) },
            getStorageSiteInputField(true)
        ],
        fetchData: values => {
            const inputFields = values.inputFields[reportDefinitions.bookingOverview.key];
            const parameters = {
                ownerActorId: inputFields.ownerActorId,
                date: formatUtcIsoDateTime(endOfDay(inputFields.date)),
                storageSiteId: getStorageSiteIdParameter(inputFields)
            };

            return values.format === 'excel'
                ? reports.getBookingOverviewReportExcelFile(parameters)
                : reports.getBookingOverviewReport(parameters);
        }
    };

    reportDefinitions.round = {
        inputFields: [
            { name: 'date', type: 'date', defaultValueGetter: () => startOfDay(new Date()) },
            getStorageSiteInputField(true)
        ],
        fetchData: values => {
            const inputFields = values.inputFields[reportDefinitions.round.key];
            const date = typeof inputFields.date === 'string'
                ? parseIsoDate(inputFields.date)
                : inputFields.date;
            const parameters = {
                ownerActorId: inputFields.ownerActorId,
                ownerActorToken: inputFields.ownerActorToken,
                date: formatUtcIsoDateTime(endOfDay(date)),
                storageSiteId: getStorageSiteIdParameter(inputFields)
            };

            return values.format === 'excel'
                ? reports.getRoundReportExcelFile(parameters)
                : reports.getRoundReport(parameters);
        }
    };

    Object.keys(reportDefinitions).forEach(key => {
        reportDefinitions[key].key = key;
        reportDefinitions[key].title = strings.reports[key].title;
        reportDefinitions[key].inputFields.forEach(inputField => {
            inputField.label = strings.reports[key].inputFields[inputField.name]?.label;
            inputField.helperText = strings.reports[key].inputFields[inputField.name]?.helperText;
        });
    });
});

const getStorageSiteInputField = nullable => ({
    name: 'storageSiteId',
    type: 'dropdown',
    dataGetter: ({ data }) => (nullable
            ? [{ label: strings.all, value: 'all' }]
            : []
        ).concat(getSortedStorageSiteItems(data.storageSites)),
    defaultValueGetter: ({ data }) => nullable
        ? 'all'
        : getSortedStorageSiteItems(data.storageSites)[0]?.value
});

const getStorageSiteIdParameter = inputFields => inputFields.storageSiteId === 'all'
    ? undefined
    : inputFields.storageSiteId;

const getSortedStorageSiteItems = storageSites => {
    const sortedItems = storageSites.map(storageSite => ({ label: storageSite.title, value: storageSite.id }));
    sortedItems.sort((a, b) => defaultCompare(a.label, b.label));
    return sortedItems;
};

const getTransactionReportDateTypeInputField = () => ({
    name: 'dateType',
    type: 'dropdown',
    dataGetter: () => Object.values(transactionReportDateTypes).map(o => ({ label: o.title, value: o.key })),
    defaultValueGetter: () => transactionReportDateTypes.payoutArrival.key
});

export default reportDefinitions;
