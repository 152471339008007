import React, {useMemo} from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from 'styles/util';

import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';

const useStyles = makeStyles(({ theme }) => ({
    teamMemberContainer: {
        display: 'flex',
        flexDirection: 'column',
        maxWidth: '300px'
    },
    imageContainer: {
        position: 'relative',
        height: theme.spacing(22),
        width: theme.spacing(22),
        marginBottom: theme.spacing(4),
        overflow: 'hidden',
        '& img': {
            position: 'absolute',
            top: `-${theme.spacing(2)}px`,
            width: '100%',
        }
    },
}));

const getImage = (imageName, imgType) => {
    let image = undefined;
    try {
        image = require(`assets/images/about/${imageName}.${imgType}`)?.default;
    } catch (e) {
        console.log('Could not find image', `/${imageName}.${imgType}`);
    }

    return image;
};

const TeamMember = ({member, imageName, imgType = 'jpg', showImage = false}) => {
    const classes = useStyles();

    const imageSrc = showImage && useMemo(() => getImage(imageName, imgType), [imageName, imgType]);

    return (
        <Box className={classes.teamMemberContainer}>
            {imageSrc && (
                <Box className={classes.imageContainer}>
                    <img src={imageSrc} alt={'Team member'}/>
                </Box>
            )}
            { member.name && <Typography variant="h3" gutterBottom>{member.name}</Typography> }
            { member.position && <Typography variant="body1" gutterBottom>{member.position}</Typography> }
            { member.description && <Typography variant="body1" gutterBottom>{member.description}</Typography> }
            { member.email && <Typography variant="body1" gutterBottom><a href={`mailto:${member.email}`}>{member.email}</a></Typography> }
        </Box>
    );
};


TeamMember.propTypes = {
    member: PropTypes.object,
    imageName: PropTypes.string,
    imgType: PropTypes.string,
    showImage: PropTypes.bool
};

export default TeamMember;
