const mediaTypes = {
    image: {
        accept: ['image/jpeg', 'image/png', 'image/webp']
    },
    video: {
        accept: ['video/mp4', 'video/webm', 'video/mpeg', 'video/ogg', 'video/quicktime']
    },
    document: {
        accept: ['application/pdf']
    }
};

Object.keys(mediaTypes).forEach(key => {
    mediaTypes[key].key = key;
});

export default mediaTypes;
