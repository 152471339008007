import { RSAA } from 'redux-api-middleware';
import { createHeaders } from '../actionHelpers';

export const ACCOUNT_TENANT_BOOKINGS_SECTION_LIST_BOOKINGS = 'ACCOUNT_TENANT_BOOKINGS_SECTION_LIST_BOOKINGS';
export const ACCOUNT_TENANT_BOOKINGS_SECTION_LIST_BOOKINGS_SUCCESS = 'ACCOUNT_TENANT_BOOKINGS_SECTION_LIST_BOOKINGS_SUCCESS';
export const ACCOUNT_TENANT_BOOKINGS_SECTION_LIST_BOOKINGS_FAILURE = 'ACCOUNT_TENANT_BOOKINGS_SECTION_LIST_BOOKINGS_FAILURE';

export const fetchTenantBookings = (tenantActorId, isLoading) => ({
    [RSAA]: {
        types: [
            {
                type: ACCOUNT_TENANT_BOOKINGS_SECTION_LIST_BOOKINGS,
                meta: { isLoading: isLoading === undefined ? true : !!isLoading }
            },
            ACCOUNT_TENANT_BOOKINGS_SECTION_LIST_BOOKINGS_SUCCESS,
            ACCOUNT_TENANT_BOOKINGS_SECTION_LIST_BOOKINGS_FAILURE
        ],
        endpoint: `/api/actors/${tenantActorId}/tenantBookings`,
        headers: createHeaders(false),
        method: 'GET'
    }
});
