import React from 'react';
import PropTypes from 'prop-types';
import { useAppContext } from 'context/AppContext';
import strings from 'localization/strings';
import { makeStyles } from 'styles/util';
import purchaseTypes from 'enums/purchaseTypes';
import storageGroupCategories from 'enums/storageGroupCategories';
import { formatLengthAndWidth } from 'helpers/StringHelper';
import goodsTypes from 'enums/goodsTypes';

import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import Area from 'common/Area';
import ShortDate from 'common/ShortDate';
import TimeSpan from 'common/TimeSpan';
import ReceiptParties from './ReceiptParties';
import ReceiptSpecification from './ReceiptSpecification';
import VatSpecification from './VatSpecification';
import VisibleFor from 'common/VisibleFor';

const useStyles = makeStyles(({ theme }) => ({
    objectTitle: {
        fontSize: '16px',
        fontWeight: 'bold',
        margin: theme.spacing(2, 0)
    },
    tableSummary: {
        width: '100%',
        margin: theme.spacing(2, 0),
        borderCollapse: 'collapse',
        '& td': {
            verticalAlign: 'top'
        }
    },
    caption: {
        '& td': {
            paddingBottom: '0.5em'
        }
    },
    body: {
        '& td': {
            paddingBottom: '1em'
        }
    },
    itemTitle: {
        fontWeight: 'bold'
    },
    right: {
        textAlign: 'right'
    }
}));

const ReceiptBody = ({ purchase, isOwner = false }) => {
    const classes = useStyles();
    const { appContext } = useAppContext();
    const booking = purchase.booking;
    const isInitialPurchase = purchase.type === purchaseTypes.periodBookingPurchase.key || purchase.type === purchaseTypes.subscriptionBookingInitialPurchase.key;
    const isSubscriptionEndPurchase = purchase.type === purchaseTypes.endSubscriptionBookingPurchase.key;
    const area = booking.area ?? booking.storageGroup.storageArea;
    const length = booking.length ?? booking.storageGroup.storageLength;
    const width = booking.width ?? booking.storageGroup.storageWidth;
    const category = storageGroupCategories[booking.storageGroup.category];

    return (
        <>
            <Typography variant="h2" className={classes.objectTitle}>{strings.paymentDocuments.renting} | {booking.storageGroup.storageSite.title}</Typography>

            <VisibleFor branch="storage">
                <Box>
                    <strong>{strings.paymentDocuments.storageSubject}:</strong> {booking.description}
                </Box>
            </VisibleFor>
            {
                booking.goodsType &&
                (
                    <Box>
                        <strong>{strings.vehicleType}:</strong> {goodsTypes[booking.goodsType].title}
                    </Box>
                )
            }
            {
                booking.registrationNumber &&
                (
                    <Box>
                        <strong>{strings.registrationNumber}:</strong> {booking.registrationNumber}
                    </Box>
                )
            }
            {
                booking.storage?.title &&
                (
                    <Box>
                        <strong>{category.storageEnumerationLabel}:</strong> {booking.storage.title}
                    </Box>
                )
            }
            {
                area > 0 &&
                (
                    <Box>
                        <strong>{strings.area}:</strong> <Area value={area} maxNumberOfDecimals={1} />
                    </Box>
                )
            }
            {
                length > 0 && width > 0 &&
                (
                    <Box>
                        <strong>{strings.dimensions}:</strong> {formatLengthAndWidth(length, width, appContext, { maxNumberOfDecimals: 1 })}
                    </Box>
                )
            }
            <hr />

            <table className={classes.tableSummary}>
                <tbody>
                    <ReceiptParties purchase={purchase} isOwner={isOwner} />
                    <tr className={classes.caption}>
                        <td className={classes.itemTitle}>{strings.paymentDocuments.paymentDate}</td>
                        <td className={classes.itemTitle}>{strings.paymentDocuments.bookingNumber}</td>
                        <td className={classes.itemTitle}>{strings.checkIn}</td>
                        <td className={classes.itemTitle + ' ' + classes.right}>{strings.checkOut}</td>
                    </tr>
                    <tr className={classes.body}>
                        <td>
                            <ShortDate value={purchase.paidTime ?? new Date() /* better safe than sorry */} />
                        </td>
                        <td>{booking.id}</td>
                        <td>
                            <ShortDate value={booking.startDate} />
                            {
                                booking.checkInOpeningTimeSpan && isInitialPurchase &&
                                (
                                    <>
                                        <br/>
                                        <TimeSpan value={booking.checkInOpeningTimeSpan} />
                                    </>
                                )
                            }
                        </td>
                        <td className={classes.right}>
                            {
                                booking.endDate ? <ShortDate value={booking.endDate} /> : strings.paymentDocuments.subscription
                            }
                            {
                                booking.checkOutOpeningTimeSpan && (isInitialPurchase || isSubscriptionEndPurchase) &&
                                (
                                    <>
                                        <br />
                                        <TimeSpan value={booking.checkOutOpeningTimeSpan} />
                                    </>
                                )
                            }
                        </td>
                    </tr>
                    <tr>
                        <td colSpan={4}><hr /></td>
                    </tr>

                    <ReceiptSpecification purchase={purchase} />
                    <VatSpecification purchase={purchase} />
                </tbody>
            </table>
        </>
    );
};

ReceiptBody.propTypes = {
    purchase: PropTypes.object.isRequired,
    isOwner: PropTypes.bool
};

export default ReceiptBody;
