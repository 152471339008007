import { RSAA } from 'redux-api-middleware';
import { createRsaaTypes, createHeaders } from './actionHelpers';
import queryString from 'query-string';

export const BOOKINGS_FETCH = 'BOOKINGS_FETCH';

export const fetchBooking = (bookingId, token) => ({
    [RSAA]: {
        types: createRsaaTypes(BOOKINGS_FETCH),
        endpoint: `/api/bookings/${bookingId}?${queryString.stringify({ token })}`,
        headers: createHeaders(false),
        method: 'GET'
    }
});
