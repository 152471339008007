import React, { useState, useEffect } from 'react';
import { useAppContext } from 'context/AppContext';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import strings from 'localization/strings';
import { handleResponse } from 'actions/actionHelpers';
import { fetchStorageBookings } from 'actions/storages';
import { formatIsoDate } from 'helpers/DateHelper';
import { getStorageTitle } from 'helpers/StorageSiteHelper';
import routes from 'routes';
import { getBusinessOrPrivateName } from 'helpers/ActorHelper';
import { getBookingDescription } from 'logic/bookingLogic';

import Loader from 'common/Loader';
import Typography from '@material-ui/core/Typography';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '@material-ui/core/Button';
import TableContainer from '@material-ui/core/TableContainer';
import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import TableBody from '@material-ui/core/TableBody';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import Paper from '@material-ui/core/Paper';
import { Link } from 'react-router-dom';
import ShortDate from 'common/ShortDate';

const CapacityUtilizationDialog = ({ storage, storageGroup, startDate, endDate, open, onClose }) => {
    const dispatch = useDispatch();

    const { appContext } = useAppContext();

    const [bookings, setBookings] = useState(undefined);
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        setBookings(undefined);
        setIsLoading(true);
        dispatch(fetchStorageBookings(storage.id, formatIsoDate(startDate), formatIsoDate(endDate)))
            .then(handleResponse(
                response => {
                    setBookings(response.payload);
                    setIsLoading(false);
                }
            ));
    }, [storage, startDate, endDate]);

    const renderBooking = booking => {
        const manageUrl = booking.id
            ? routes.account.ownerBookingDetails.replace(':bookingId', booking.id)
            : undefined;
        const bookingEndDate = booking.actualEndDate ?? booking.endDate ?? booking.subscriptionEndDate;
        return (
            <TableRow>
                <TableCell>
                    {
                        manageUrl && <Link to={manageUrl}>{booking.id}</Link>
                    }
                    {
                        !booking.id && booking.isTakeoverBooking && 'Takeover'
                    }
                </TableCell>
                <TableCell>{getBusinessOrPrivateName(booking.tenantActor)}</TableCell>
                <TableCell><ShortDate value={booking.startDate}/></TableCell>
                <TableCell><ShortDate value={bookingEndDate} defaultString={strings.untilFurther}/></TableCell>
                <TableCell>{getBookingDescription(booking)}</TableCell>
            </TableRow>
        );
    };

    const dateInterval = <><ShortDate value={startDate}/>{endDate.getTime() !== startDate.getTime() && <> &ndash; <ShortDate value={endDate}/></>}</>;

    return (
        <Dialog fullWidth maxWidth="md" open={open} onClose={onClose}>
            <DialogTitle disableTypography>
                <Typography variant="h5">
                    {getStorageTitle(storage, storageGroup, appContext)}: {dateInterval}
                </Typography>
            </DialogTitle>
            <DialogContent>
                {
                    isLoading && <Loader />
                }
                {
                    bookings &&
                    (
                        <>
                            {
                                bookings.length === 0 &&
                                (
                                    <Typography variant="body1">
                                        {strings.capacityUtilizationNoBookings}
                                    </Typography>
                                )
                            }
                            {
                                bookings.length > 0 &&
                                (
                                    <TableContainer component={Paper}>
                                        <Table size="small">
                                            <TableHead>
                                                <TableRow>
                                                    <TableCell>{strings.bookingNumber}</TableCell>
                                                    <TableCell>{strings.tenant}</TableCell>
                                                    <TableCell>{strings.startDate}</TableCell>
                                                    <TableCell>{strings.endDate}</TableCell>
                                                    <TableCell>{strings.goods}</TableCell>
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                {bookings.map(renderBooking)}
                                            </TableBody>
                                        </Table>
                                    </TableContainer>
                                )
                            }
                        </>
                    )
                }
            </DialogContent>
            <DialogActions>
                <Button
                    variant="contained"
                    color="primary"
                    onClick={onClose}
                >
                    {strings.close}
                </Button>
            </DialogActions>
        </Dialog>
    );
};

CapacityUtilizationDialog.propTypes = {
    storage: PropTypes.object.isRequired,
    storageGroup: PropTypes.object.isRequired,
    startDate: PropTypes.instanceOf(Date).isRequired,
    endDate: PropTypes.instanceOf(Date).isRequired,
    open: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired
};

export default CapacityUtilizationDialog;
