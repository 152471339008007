import * as actionTypes from 'actions/notificationWidgets';
import store from 'store2';

const notificationWidgetsKey = 'notificationWidgets';

const keys = [
];

const initialState = keys.map(key => (
    {
        key,
        hide: !!store.get(`${notificationWidgetsKey}.${key}`)
    }
));

const notificationWidgets = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.NOTIFICATION_WIDGETS_CLOSE:
            store.set(`${notificationWidgetsKey}.${action.key}`, true);
            return state.filter(nw => nw.key !== action.key).concat([
                {
                    key: action.key,
                    hide: true
                }
            ]);
        default:
            return state;
    }
};

export default notificationWidgets;
