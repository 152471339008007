import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from 'styles/util';

import Box from '@material-ui/core/Box';
import Loader from 'common/Loader';

const useStyles = makeStyles(() => ({
    disabledContainer: {
        position: 'relative',
        border: 'none',
        margin: 0,
        padding: 0,
        '&:disabled > *': {
            opacity: 0.333
        }
    },
    saveIndicator: {
        position: 'absolute',
        left: '50%',
        top: '50%',
        margin: '-50px'
    },
    hidden: {
        display: 'none'
    }
}));

const LoadingWrapper = ({ isLoading = false, isSaving = false, children }) => {
    const classes = useStyles();
    return (
        <Box>
            {
                isLoading && <Loader />
            }
            <fieldset className={classes.disabledContainer + (isLoading ? ' ' + classes.hidden : '')} disabled={isSaving}>
                { isSaving && <Box className={classes.saveIndicator}><Loader left={0} top={0}/></Box> }
                {children}
            </fieldset>
        </Box>
    );
};

LoadingWrapper.propTypes = {
    isLoading: PropTypes.bool,
    isSaving: PropTypes.bool,
    children: PropTypes.node
};

export default LoadingWrapper;
