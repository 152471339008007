import strings from 'localization/strings';

const purchaseStatuses = {
    paid: {},
    actionRequired: {},
    failed: {},
    unpaid: {},
    refunded: {}
};

Object.keys(purchaseStatuses).forEach(key => {
    purchaseStatuses[key].key = key;
});

strings.addLoadCallback(() => {
    Object.values(purchaseStatuses).forEach(o => {
        o.title = strings.purchaseStatuses[o.key];
    });
});

export default purchaseStatuses;
