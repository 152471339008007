import React from 'react';
import strings from 'localization/strings';
import camelCase from 'camelcase';

import ShortDate from 'common/ShortDate';

const nonBreakingSpace = String.fromCharCode(160);

const numberToLocale = (number, appContext, { numberOfDecimals = undefined, sanitizeMinusSign = false, skipThousandSeparators = false}) => {
    const options = numberOfDecimals === undefined
        ? undefined
        : {
            minimumFractionDigits: numberOfDecimals,
            maximumFractionDigits: numberOfDecimals,
            useGrouping: !skipThousandSeparators
        };
    let localeString = number.toLocaleString(appContext.language, options);

    // for some zero values, number.toLocaleString returns a strange "negative zero" starting with a long minus sign −, not -
    if (localeString === '−0') {
        localeString = '0';
    }

    if(sanitizeMinusSign) {
        localeString = replaceAll(localeString, '−', '-');
    }

    return localeString;
};

export const formatNumber = (stringOrNumber, appContext, options = {}) => {
    const { numberOfDecimals = undefined, maxNumberOfDecimals = undefined, toLocale = true, sanitizeMinusSign = false, skipThousandSeparators = false, defaultValue = '' } = options || {};
    let number = stringOrNumber;
    if (typeof stringOrNumber !== 'number') {
        number = convertToNumber(stringOrNumber);
    }
    if(isNaN(number)) {
        return defaultValue;
    }

    let actualNumberOfDecimals = numberOfDecimals;

    if(maxNumberOfDecimals !== undefined) {
        if(convertToNumber(number.toFixed(maxNumberOfDecimals)) !== number) {
            actualNumberOfDecimals = maxNumberOfDecimals;
        }
    }

    if(actualNumberOfDecimals !== undefined) {
        return toLocale
            ? numberToLocale(number, appContext, { numberOfDecimals: actualNumberOfDecimals, sanitizeMinusSign, skipThousandSeparators })
            : number.toFixed(actualNumberOfDecimals);
    }
    return toLocale
        ? numberToLocale(number, appContext, { sanitizeMinusSign, skipThousandSeparators })
        : number.toString();
};

export const formatPercentage = (value, appContext, options) => value || value === 0
    ? formatNumber(100 * value, appContext, options) + ' %'
    : '';

export const formatPostalCode = (input) => {
    if (input && input.toString().length === 5) {
        return input.toString().replace(/^(.{3})(.*)$/, '$1 $2');
    }
    return input;
};

export const formatAmount = (amount, currency, appContext, { displayPriceRounding = undefined, toLocale = true, skipCurrencySuffix = false, skipThousandSeparators = false } = {}) => {
    // consider whether we need both currency and appContext arguments
    // we should head towards using appContext only
    const currencySuffix = currency && !skipCurrencySuffix
        ? ' ' + formatCurrency(currency, appContext)
        : '';

    if (!amount && amount !== 0) {
        return '-' + currencySuffix;
    }

    let numberOfDecimals;

    if(displayPriceRounding === true) {
        numberOfDecimals = appContext.currency.numberOfDecimals.displayPriceRounding;
    } else if(displayPriceRounding === false) {
        numberOfDecimals = appContext.currency.numberOfDecimals.exact;
    } else if(displayPriceRounding === undefined) {
        // auto determine number of decimals
        numberOfDecimals = amount === Math.floor(amount)
            ? appContext.currency.numberOfDecimals.default
            : appContext.currency.numberOfDecimals.exact;
    }

    if(toLocale) {
        return amount.toLocaleString(appContext.language, {
            minimumFractionDigits: numberOfDecimals,
            maximumFractionDigits: numberOfDecimals,
            useGrouping: !skipThousandSeparators
        }) + currencySuffix;
    }

    return amount.toFixed(numberOfDecimals) + currencySuffix;
};

const formatCurrency = (currency, appContext) => {
    if(currency !== appContext.currency.code) {
        return currency;
    }
    return appContext.currency.label;
};

export const formatArea = (value, appContext, options) => {
    return strings.formatString(strings.areaTemplate, formatNumber(value, appContext, options));
};

export const formatLengthAndWidth = (length, width, appContext, options) => {
    return strings.formatString(strings.lengthAndWidthTemplate, formatNumber(length, appContext, options), formatNumber(width, appContext, options));
};

export const formatLength = (value, appContext, options) => {
    return strings.formatString(strings.lengthTemplate, formatNumber(value, appContext, options));
};

export const formatQuantity = quantity => {
    return strings.formatString(strings.quantityTemplate, quantity ?? '');
};

export const formatTimePeriod = (arg1, arg2) => {
    let startDate;
    let endDate;
    if(typeof arg1 === 'object') {
        startDate = arg1.startDate;
        endDate = arg1.actualEndDate ?? arg1.endDate ?? arg1.subscriptionEndDate;
    } else {
        startDate = arg1;
        endDate = arg2;
    }
    return <><ShortDate value={startDate}/> &ndash; <ShortDate value={endDate} defaultString={strings.untilFurtherLowercase}/></>;
};

export const formatYesNo = bool => bool ? strings.yes : strings.no;

export const toCamelCase = (s, options = {}) => {
    if(!s) {
        return s;
    }
    if(options.keepSpaces) {
        return s.split(' ').map(ss => toCamelCase(ss, { keepSpaces: false })).join(' ');
    }
    return camelCase(s);
};

export const toPascalCase = (s, options = {}) => {
    if(!s) {
        return s;
    }
    if(options.keepSpaces) {
        return s.split(' ').map(ss => toPascalCase(ss, { keepSpaces: false })).join(' ');
    }
    return camelCase(s, { pascalCase: true });
};

export const shorten = (text, maxLength, html) => {
    if (text && text.length > maxLength) {
        return html
            ? <>{text.substr(0, maxLength)}<>&hellip;</></>
            : text.substr(0, maxLength) + '...';
    }
    return text;
};

export const convertToNumber = value => {
    let v = value;

    if(typeof v === 'number') {
        return isNaN(v)
            ? undefined
            : v;
    }

    if(!v && v !== 0) {
        return undefined;
    }

    if(typeof v === 'string') {
        v = replaceAll(v, nonBreakingSpace, '');
        v = replaceAll(v, ' ', '');
        v = replaceAll(v, ',', '.');
        v = replaceAll(v, '−', '-'); // long minus sign to short minus sign
    }

    const parsedNumber = parseFloat(v);

    return isNaN(parsedNumber)
        ? undefined
        : parsedNumber;
};

export const isNumber = value => {
    return convertToNumber(value) !== undefined;
};

export const sanitizeRegistrationNumber = registrationNumber => {
    if(!registrationNumber) {
        return undefined;
    }
    return replaceAll(replaceAll(registrationNumber, ' ', ''), '-', '')
        .toUpperCase();
};

// polyfill for String.prototype.replaceAll
export const replaceAll = (str, find, replace) => {
    if(!str || typeof str !== 'string') {
        return str;
    }

    if(Array.isArray(find)) {
        let s = str;
        find.forEach(f => {
            s = replaceAll(s, f, replace);
        });
        return s;
    }

    return String.prototype.replaceAll
        ? str.replaceAll(find, replace)
        : str.split(find).join(replace);
};

export const getFileName = path => {
    const atoms = path.split('/');
    const lastAtom = atoms[atoms.length - 1];
    const fileNameAtoms = lastAtom.split('.');
    return fileNameAtoms.slice(0, -1).join('.');
};

export const capitalizeFirstLetter = s => s
    ? s.substr(0, 1).toUpperCase() + s.substr(1)
    : s;

export const countNumberOfCharacters = (str, char) => {
    let count = 0;
    for (let i = 0; i < str.length; i++) {
        if(str[i] === char) {
            count++;
        }
    }
    return count;
};
