import React from 'react';
import { useSelector } from 'react-redux';
import { useLocation, useHistory } from 'react-router-dom';
import organizationTypes from 'enums/organizationTypes';
import strings from 'localization/strings';
import queryString from 'query-string';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import routes from 'routes';
import { makeStyles } from 'styles/util';

import PageTitle from 'common/PageTitle';
import ShadowBox from 'common/ShadowBox';
import MarginBox from 'common/MarginBox';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import UserSettings from './UserSettings';
import CombinedUserActorSettings from './CombinedUserActorSettings';
import ActorSettings from './ActorSettings';
import OwnerSettings from './OwnerSettings';
import BankSettings from './BankSettings';
import Separator from 'common/Separator';
import ActorBlocker from './ActorBlocker';

const useStyles = makeStyles(({ theme }) => ({
    settingsTabs: {
        [theme.breakpoints.down('sm')]: {
            '& .MuiTabs-flexContainer': {
                alignItems: 'center'
            }
        }
    }
}));

const SettingsSection = () => {
    const isMobile = !useMediaQuery(theme => theme.breakpoints.up('md'));
    const location = useLocation();
    const history = useHistory();
    const classes = useStyles();
    const authenticationContext = useSelector(state => state.authentication.context);
    const { user, selectedActor } = authenticationContext;
    const { tab: selectedTabKey } = queryString.parse(location.search);

    const tabs = {
        user: { visible: selectedActor?.organizationType !== organizationTypes.private.key },
        combinedUserActor: { visible: selectedActor?.organizationType === organizationTypes.private.key },
        actor: { visible: selectedActor?.organizationType === organizationTypes.business.key },
        owner: { visible: selectedActor !== undefined }
    };
    Object.keys(tabs).forEach(key => {
        tabs[key].key = key;
        tabs[key].label = strings.accountSettings[key];
    });
    tabs.user.label += ': ' + user.name;
    tabs.actor.label += ': ' + selectedActor?.name;

    const selectedTab = tabs[selectedTabKey]
        ?? Object.values(tabs).find(o => o.visible);

    const handleTabChange = (event, newValue) => {
        const newTab = tabs[newValue];
        const q = queryString.stringify({ tab: newTab?.key });
        history.push(routes.account.settings + (q ? '?' + q : ''));
    };

    const renderOwnerTab = () => (
        <ActorBlocker block={selectedActor?.isBlocked}>
            <OwnerSettings />
            { selectedActor?.isOwner && <><Separator spacing={4}/><BankSettings/></> }
        </ActorBlocker>
    );

    return (
        <>
            <PageTitle>{strings.accountTabs.settings}</PageTitle>

            <ShadowBox title={strings.accountTabs.settings}>
                <MarginBox bottom={4}>
                    <Tabs
                        value={selectedTab.key}
                        onChange={handleTabChange}
                        orientation={isMobile && selectedActor?.organizationType === organizationTypes.business.key ? 'vertical' : 'horizontal'}
                        className={classes.settingsTabs}
                    >
                        {
                            Object.values(tabs).filter(tab => tab.visible).map(tab =>
                                <Tab key={tab.key} value={tab.key} label={tab.label} />
                            )
                        }
                    </Tabs>
                </MarginBox>

                { selectedTab.key === tabs.user.key && <UserSettings /> }
                { selectedTab.key === tabs.combinedUserActor.key && <ActorBlocker block={selectedActor.isBlocked}><CombinedUserActorSettings /></ActorBlocker> }
                { selectedTab.key === tabs.actor.key && <ActorBlocker block={selectedActor.isBlocked}><ActorSettings /></ActorBlocker> }
                { selectedTab.key === tabs.owner.key && renderOwnerTab() }
            </ShadowBox>
        </>
    );
};

export default SettingsSection;
