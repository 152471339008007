import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import strings from 'localization/strings';
import { changeValueMutator } from 'helpers/FormHelper';
import { getStorageSiteAndStorageTitle } from 'helpers/StorageSiteHelper';
import { distinct } from 'helpers/ArrayHelper';
import { formatTimePeriod } from 'helpers/StringHelper';
import { setSuccessResponse, setErrorResponse } from 'actions/responses';
import { makeStyles } from 'styles/util';
import { getContact, getBusinessOrPrivateName } from 'helpers/ActorHelper';
import contactTypes from 'enums/contactTypes';

import Button from '@material-ui/core/Button';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import Alert from '@material-ui/lab/Alert';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Form from 'form/Form';
import { Checkboxes } from 'mui-rff';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import { CopyToClipboard } from 'react-copy-to-clipboard';

const useStyles = makeStyles(({ theme }) => ({
    list: {
        maxHeight: '600px',
        overflow: 'auto'
    },
    information: {
        marginBottom: theme.spacing(2)
    }
}));

const CopyBookingEmailsToClipboardDialog = ({ bookings, open, onClose }) => {
    const classes = useStyles();
    const dispatch = useDispatch();

    const [initialValues, setInitialValues] = useState({});

    useEffect(() => {
        if(bookings) {
            const values = {
                bookings: []
            };
            bookings.forEach(booking => {
                values.bookings[booking.id] = true;
            });
            setInitialValues(values);
        }
    }, [open]);

    const handleCopy = success => {
        if(success) {
            onClose();
            dispatch(setSuccessResponse(strings.copyToClipboardSucceeded));
        } else {
            dispatch(setErrorResponse(strings.copyToClipboardFailed));
        }
    };

    const handleFormSubmit = () => {
        // nothing to be done here
    };

    if(!bookings) {
        return null;
    }

    return (
        <Dialog fullWidth maxWidth="md" open={open} onClose={onClose}>
            <Form
                onSubmit={handleFormSubmit}
                initialValues={initialValues}
                mutators={{
                    changeValue: changeValueMutator
                }}
            >
                {({ handleSubmit, form, values }) => {
                    const handleBookingClick = booking => {
                        form.mutators.changeValue({ name: `bookings[${booking.id}]`, value: !values.bookings[booking.id] });
                    };

                    const semicolonSeparatedEmails = distinct(bookings
                            .filter(booking => values.bookings[booking.id])
                            .map(booking => getContact(booking.tenantActor, contactTypes.mainEmail))
                        )
                        .join(';');

                    return (
                        <form onSubmit={handleSubmit}>
                            <DialogTitle disableTypography>
                                <Typography variant="h5">
                                    {strings.copyBookingEmailsToClipboardDialogTitle}
                                </Typography>
                            </DialogTitle>
                            <DialogContent>
                                <Alert severity="info" className={classes.information}>
                                    {strings.copyBookingEmailsToClipboardDialogBody}
                                </Alert>

                                {
                                    bookings.length === 0 &&
                                    (
                                        <Typography variant="body1" gutterBottom>
                                            {strings.noBookingsInThisCategory}
                                        </Typography>
                                    )
                                }
                                {
                                    bookings.length > 0 &&
                                    (
                                        <List className={classes.list}>
                                            {
                                                bookings.map(booking => {
                                                    const storageGroup = booking.storageGroup;
                                                    const title = getStorageSiteAndStorageTitle(booking.storage, storageGroup);

                                                    const primary = (
                                                        <Box>
                                                            {getBusinessOrPrivateName(booking.tenantActor)} &ndash; {title}, {formatTimePeriod(booking)}
                                                        </Box>
                                                    );
                                                    return (
                                                        <ListItem key={booking.id} dense button onClick={() => handleBookingClick(booking)}>
                                                            <ListItemIcon>
                                                                <Checkboxes
                                                                    name={`bookings[${booking.id}]`}
                                                                    data={{}}
                                                                />
                                                            </ListItemIcon>
                                                            <ListItemText primary={primary} />
                                                        </ListItem>
                                                    );
                                                })
                                            }
                                        </List>
                                    )
                                }
                            </DialogContent>
                            <DialogActions>
                                <CopyToClipboard text={semicolonSeparatedEmails}
                                    onCopy={handleCopy}
                                >
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        disabled={bookings.length === 0}
                                    >
                                        {strings.copyToClipboard}
                                    </Button>
                                </CopyToClipboard>

                                <Button
                                    variant="outlined"
                                    color="primary"
                                    onClick={onClose}
                                >
                                    {strings.close}
                                </Button>
                            </DialogActions>
                        </form>
                    );
                }}
            </Form>
        </Dialog>
    );
};

CopyBookingEmailsToClipboardDialog.propTypes = {
    bookings: PropTypes.array,
    open: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired
};

export default CopyBookingEmailsToClipboardDialog;
