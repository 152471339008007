import { RSAA } from 'redux-api-middleware';
import { createRsaaTypes, createHeaders } from '../actionHelpers';

export const ACCOUNT_OWNER_BOOKINGS_SECTION_LIST_BOOKINGS = 'ACCOUNT_OWNER_BOOKINGS_SECTION_LIST_BOOKINGS';
export const ACCOUNT_OWNER_BOOKINGS_SECTION_LIST_BOOKINGS_SUCCESS = 'ACCOUNT_OWNER_BOOKINGS_SECTION_LIST_BOOKINGS_SUCCESS';
export const ACCOUNT_OWNER_BOOKINGS_SECTION_LIST_BOOKINGS_FAILURE = 'ACCOUNT_OWNER_BOOKINGS_SECTION_LIST_BOOKINGS_FAILURE';

export const fetchOwnerBookings = ownerActorId => ({
    [RSAA]: {
        types: createRsaaTypes(ACCOUNT_OWNER_BOOKINGS_SECTION_LIST_BOOKINGS),
        endpoint: `/api/actors/${ownerActorId}/ownerBookings`,
        headers: createHeaders(false),
        method: 'GET'
    }
});
