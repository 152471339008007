import strings from 'localization/strings';

const organizationTypes = {
    private: {},
    business: {}
};

Object.keys(organizationTypes).forEach(key => {
    organizationTypes[key].key = key;
});

strings.addLoadCallback(() => {
    Object.values(organizationTypes).forEach(o => {
        o.title = strings.organizationTypes[o.key].title;
        o.rentingOutAs = strings.organizationTypes[o.key].rentingOutAs;
        o.bookingAs = strings.organizationTypes[o.key].bookingAs;
    });
});

export default organizationTypes;
