import { RSAA } from 'redux-api-middleware';
import { createRsaaTypes, createHeaders } from '../actionHelpers';
import queryString from 'query-string';

export const ADMIN_TAKEOVERS_SEARCH = 'ADMIN_TAKEOVERS_SEARCH';
export const ADMIN_TAKEOVERS_SEARCH_SUCCESS = 'ADMIN_TAKEOVERS_SEARCH_SUCCESS';
export const ADMIN_TAKEOVERS_GET = 'ADMIN_TAKEOVERS_GET';
export const ADMIN_TAKEOVERS_CREATE = 'ADMIN_TAKEOVERS_CREATE';
export const ADMIN_TAKEOVERS_UPDATE = 'ADMIN_TAKEOVERS_UPDATE';
export const ADMIN_TAKEOVERS_DELETE = 'ADMIN_TAKEOVERS_DELETE';
export const ADMIN_TAKEOVERTENANTS_CONVERT_FILE = 'ADMIN_TAKEOVERTENANTS_CONVERT_FILE';
export const ADMIN_TAKEOVERTENANTS_UPDATE = 'ADMIN_TAKEOVERTENANTS_UPDATE';
export const ADMIN_TAKEOVERTENANTS_DELETE = 'ADMIN_TAKEOVERTENANTS_DELETE';
export const ADMIN_TAKEOVER_SEND_EMAIL_TO_TENANTS = 'ADMIN_TAKEOVER_SEND_EMAIL_TO_TENANTS';
export const ADMIN_TAKEOVER_CREATE_BOOKINGS_FOR_TENANTS = 'ADMIN_TAKEOVER_CREATE_BOOKINGS_FOR_TENANTS';
export const ADMIN_TAKEOVERS_SET_SEARCH_PARAMETERS = 'ADMIN_TAKEOVERS_SET_SEARCH_PARAMETERS';

export const searchTakeovers = searchParameters => ({
    [RSAA]: {
        types: createRsaaTypes(ADMIN_TAKEOVERS_SEARCH),
        endpoint: `/api/admin/takeovers?${queryString.stringify(searchParameters)}`,
        headers: createHeaders(false),
        method: 'GET'
    }
});

export const getTakeover = (id, includeMessages = false) => ({
    [RSAA]: {
        types: createRsaaTypes(ADMIN_TAKEOVERS_GET),
        endpoint: `/api/admin/takeovers/${id}?${queryString.stringify({ includeMessages })}`,
        headers: createHeaders(false),
        method: 'GET'
    }
});

export const createTakeover = request => ({
    [RSAA]: {
        types: createRsaaTypes(ADMIN_TAKEOVERS_CREATE),
        endpoint: '/api/admin/takeovers',
        headers: createHeaders(true),
        method: 'POST',
        body: JSON.stringify(request)
    }
});

export const updateTakeover = (id, request) => ({
    [RSAA]: {
        types: createRsaaTypes(ADMIN_TAKEOVERS_UPDATE),
        endpoint: `/api/admin/takeovers/${id}`,
        headers: createHeaders(true),
        method: 'PUT',
        body: JSON.stringify(request)
    }
});

export const deleteTakeover = id => ({
    [RSAA]: {
        types: createRsaaTypes(ADMIN_TAKEOVERS_DELETE),
        endpoint: `/api/admin/takeovers/${id}`,
        headers: createHeaders(false),
        method: 'DELETE'
    }
});

export const convertTakeoverTenantFile = (takeoverId, file) => {
    const data = new FormData();
    data.append('file', file);
    return {
        [RSAA]: {
            types: createRsaaTypes(ADMIN_TAKEOVERTENANTS_CONVERT_FILE),
            endpoint: `/api/admin/takeovers/${takeoverId}/convertTenantFile`,
            headers: createHeaders(false),
            body: data,
            method: 'PUT'
        }
    };
};

export const updateTakeoverTenant = (takeoverId, takeoverTenantId, request) => ({
    [RSAA]: {
        types: createRsaaTypes(ADMIN_TAKEOVERTENANTS_UPDATE),
        endpoint: `/api/admin/takeovers/${takeoverId}/tenants/${takeoverTenantId}`,
        headers: createHeaders(true),
        method: 'PUT',
        body: JSON.stringify(request)
    }
});

export const deleteTakeoverTenant = (takeoverId, takeoverTenantId) => ({
    [RSAA]: {
        types: createRsaaTypes(ADMIN_TAKEOVERTENANTS_DELETE),
        endpoint: `/api/admin/takeovers/${takeoverId}/tenants/${takeoverTenantId}`,
        headers: createHeaders(false),
        method: 'DELETE'
    }
});

export const sendEmailToTakeoverTenants = (takeoverId, request) => ({
    [RSAA]: {
        types: createRsaaTypes(ADMIN_TAKEOVER_SEND_EMAIL_TO_TENANTS),
        endpoint: `/api/admin/takeovers/${takeoverId}/sendEmailToTenants`,
        headers: createHeaders(true),
        method: 'POST',
        body: JSON.stringify(request)
    }
});

export const createBookingsForTakeoverTenants = (takeoverId, request) => ({
    [RSAA]: {
        types: createRsaaTypes(ADMIN_TAKEOVER_CREATE_BOOKINGS_FOR_TENANTS),
        endpoint: `/api/admin/takeovers/${takeoverId}/tenants/bookings`,
        headers: createHeaders(true),
        method: 'POST',
        body: JSON.stringify(request)
    }
});

export const setSearchParameters = (searchParameters) => ({
    type: ADMIN_TAKEOVERS_SET_SEARCH_PARAMETERS,
    searchParameters
});
