// this component is a combination of the react-image-gallery component (for inline display of images)
// and the react-image-lightbox component (for full-screen display and zooming of images)

import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { getResizedImageUrl } from 'helpers/ImageHelper';
import { makeStyles } from 'styles/util';

import Box from '@material-ui/core/Box';
import ImageGallery from 'react-image-gallery';
import Lightbox from 'react-image-lightbox';
import VideoPlayer from 'common/VideoPlayer';
import KeyboardArrowLeftIcon from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowRightIcon from '@material-ui/icons/KeyboardArrowRight';

import 'react-image-gallery/styles/scss/image-gallery.scss';
import 'react-image-lightbox/style.css';

const useStyles = makeStyles(({ theme, colors, styles }) => ({
    lightbox: {
        '& .ril-toolbar': {
            height: '66.67px'
        },
        '& .ril__toolbarItem': {
            lineHeight: '66.67px'
        },
        [theme.breakpoints.down('xs')]: {
            '& .ril-toolbar': {
                height: '56px'
            },
            '& .ril__toolbarItem': {
                lineHeight: '56px'
            }
        }
    },
    image: {
        cursor: 'zoom-in',
        width: '100%',
        height: '100%',
        borderTopLeftRadius: `${styles.borderRadius}px`,
        borderTopRightRadius: `${styles.borderRadius}px`
    },
    nav: {
        position: 'absolute',
        top: 0,
        bottom: 0,
        zIndex: 10,
        width: '40px',
        height: '40px',
        margin: 'auto',
        border: 'none',
        fontSize: '20px',
        borderRadius: '300px',
        padding: '5px 0',
        color: colors.primaryColor,
        backgroundColor: colors.white,
        '& > *': {
            width: '30px',
            height: '30px'
        },
        '&:hover': {
            cursor: 'pointer',
            color: colors.white,
            backgroundColor: colors.primaryColor,
        }
    },
    leftNav: {
        left: '-20px',
    },
    rightNav: {
        right: '-20px',
    },
    imageGalleryContainer: {
        position: 'relative'
    },
    imageIndexLabel: {
        position: 'absolute',
        bottom: theme.spacing(2),
        right: theme.spacing(2),
        padding: theme.spacing(0.5, 1),
        borderRadius: theme.spacing(1),
        backgroundColor: 'rgba(0, 0, 0, 0.5)',
        color: colors.white
    },
    divider: {
        padding: theme.spacing(0, 0.25)
    }
}));

const fullScreenImageSize = {
    width: 1600,
    height: 1000
};

const ImageCarousel = ({ images = [], videos = [], width, height }) => {
    const classes = useStyles();

    const items = [];

    const [sliderIndex, setSliderIndex] = useState(0);
    const [lightboxIndex, setLightboxIndex] = useState(0);
    const [lightboxOpen, setLightboxOpen] = useState(false);

    const handleClick = () => {
        setLightboxIndex(sliderIndex);
        setLightboxOpen(true);
    };

    const handleSlide = index => {
        setSliderIndex(index);
    };

    const renderNav = {
        renderLeftNav: (onClick, disabled) => (
            <button
                type="button"
                className={classes.nav + ' ' + classes.leftNav}
                disabled={disabled}
                onClick={onClick}
            >
                <KeyboardArrowLeftIcon />
            </button>
        ),
        renderRightNav: (onClick, disabled) => (
            <button
                type="button"
                className={classes.nav + ' ' + classes.rightNav}
                disabled={disabled}
                onClick={onClick}
            >
                <KeyboardArrowRightIcon />
            </button>
        )
    };

    images.forEach(image => {
        items.push({
            renderItem: () => (
                <img
                    className={classes.image}
                    src={getResizedImageUrl(image.mediaUrl, { width, height, bgcolor: 'ffffff', mode: 'crop' })}
                    width={width}
                    height={height}
                    onClick={handleClick}
                />
            )
        });
    });

    videos.forEach(video => {
        items.push({
            renderItem: () => (
                <VideoPlayer src={(video.baseUrl + video.mediaUrl)} />
            )
        });
    });

    const getLightboxUrl = index => getResizedImageUrl(images[index].mediaUrl, { ...fullScreenImageSize, bgcolor: 'ffffff', mode: 'crop' });

    return (
        <>
            <Box className={classes.imageGalleryContainer}>
                <ImageGallery
                    items={items}
                    onSlide={handleSlide}
                    showFullscreenButton={false}
                    showThumbnails={false}
                    showPlayButton={false}
                    startIndex={sliderIndex}
                    {...renderNav}
                />
                {
                    items.length > 1 &&
                    (
                        <Box className={classes.imageIndexLabel}>
                            {sliderIndex + 1}<Box component="span" className={classes.divider}>/</Box>{items.length}
                        </Box>
                    )
                }
            </Box>
            {
                lightboxOpen &&
                (
                    <Lightbox
                        wrapperClassName={classes.lightbox}
                        mainSrc={getLightboxUrl(lightboxIndex)}
                        nextSrc={getLightboxUrl((lightboxIndex + 1) % images.length)}
                        prevSrc={getLightboxUrl((lightboxIndex + images.length - 1) % images.length)}
                        onCloseRequest={() => setLightboxOpen(false)}
                        onMovePrevRequest={() =>
                            setLightboxIndex((lightboxIndex + images.length - 1) % images.length)
                        }
                        onMoveNextRequest={() =>
                            setLightboxIndex((lightboxIndex + 1) % images.length)
                        }
                    />
                )
            }
        </>
    );
};

ImageCarousel.propTypes = {
    images: PropTypes.array,
    videos: PropTypes.array,
    width: PropTypes.number.isRequired,
    height: PropTypes.number.isRequired
};

export default ImageCarousel;
