import strings from 'localization/strings';
import rentalTypes from 'enums/rentalTypes';
import vatModes from 'enums/vatModes';
import organizationTypes from 'enums/organizationTypes';
import { convertToNumber, formatAmount, formatArea } from 'helpers/StringHelper';
import { getPriceInfo } from 'helpers/PriceCalculator';

export const formatStorageGroupArea = (storageGroup, appContext) => {
    if(storageGroup.title)  {
        return storageGroup.title;
    }
    if(storageGroup.rentalType === rentalTypes.customArea.key) {
        return strings.perSquareMeter;
    }
    return formatArea(convertToNumber(storageGroup.storageArea), appContext, { maxNumberOfDecimals: 1 });
};

export const formatStorageGroupPrice = (storageGroup, showExcludingVatSuffix, appContext) => {
    const clonedStorageGroup = { ...storageGroup };
    clonedStorageGroup.vatMode = showExcludingVatSuffix
        ? vatModes.eligibleForVat.key
        : vatModes.notEligibleForVat.key;
    const priceInfo = getPriceInfo(convertToNumber(clonedStorageGroup.price), appContext, clonedStorageGroup, organizationTypes.business.key);
    const formattedAmount = formatAmount(priceInfo.priceExcludingVat, clonedStorageGroup.currency, appContext);
    const formatString = clonedStorageGroup.rentalType === rentalTypes.customArea.key
        ? strings.currencyUnitPerSquareMeterAndMonth
        : strings.currencyUnitPerMonth;
    const vatSuffix = showExcludingVatSuffix
        ? ` ${strings.excludingVatSuffix}`
        : '';
    return strings.formatString(formatString, formattedAmount) + vatSuffix;
};
