import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { useLocation, matchPath } from 'react-router-dom';

import { makeStyles } from 'styles/util';

import Box from '@material-ui/core/Box';
import Breadcrumb from 'common/Breadcrumb';

const useStyles = makeStyles(({ colors }) => ({
    container: {
        display: 'flex',
        justifyContent: 'flex-start',
        flex: '1 0 auto',
        textAlign: 'left'
    },
    breadcrumb: {
        display: 'flex',
        alignItems: 'center'
    },
    link: {
        cursor: 'pointer',
        textDecoration: 'none',
        color: colors.white
    },
    svg: {
        fill: colors.white
    }
}));

const Breadcrumbs = ({routes, homeRoute, homeRouteLabel}) => {
    const classes = useStyles();
    const location = useLocation();
    const pathnames = location.pathname.split('/').filter((x) => x);
    const ref = useRef(null);

    useEffect(() => {
        if (ref.current) {
            if (ref.current.scrollWidth > window.innerWidth - 60) {
                const children = ref.current.children;
                for (let i = 0; i < children.length; i++) {
                    if (ref.current.scrollWidth <= window.innerWidth - 60) {
                        break;
                    }
                    children[i].style.display = 'none';
                }
            }
        }
    }, [pathnames]);

    return (
       <Box className={classes.container} ref={ref}>
            {pathnames.map((value, index) => {
                const to = `/${pathnames.slice(0, index + 1).join('/')}`;
                const currentRoute = routes.find((route) => {
                        return matchPath(to, {
                            path: route.path,
                            exact: true,
                            strict: false
                        });
                    }
                );
                if (!currentRoute && homeRoute && index === 0) {
                    return <Breadcrumb key={value} to={homeRoute.path} label={homeRouteLabel} isLast={isLast} />;
                }

                if (!currentRoute) {
                    return null;
                }

                const path = index === 0 && homeRoute ? homeRoute.path : currentRoute.path;
                const label = index === 0 && homeRouteLabel ? homeRouteLabel : currentRoute.label;
                const isLast = index === pathnames.length - 1;

                return <Breadcrumb key={value} to={path} label={label} isLast={isLast} />;
            })}
       </Box>
    );
};

Breadcrumbs.propTypes = {
    routes: PropTypes.array.isRequired,
    homeRoute: PropTypes.object,
    homeRouteLabel: PropTypes.string
};

export default Breadcrumbs;
