import * as actionTypes from 'actions/admin/conversations';

const initialState = {
    searchResult: {
        isLoading: false
    },
    searchParameters: {}
};

const conversations = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.ADMIN_CONVERSATIONS_SEARCH:
            return { ...state, searchResult: { isLoading: true } };
        case actionTypes.ADMIN_CONVERSATIONS_SEARCH_SUCCESS:
            return { ...state, searchResult: action.payload };
        case actionTypes.ADMIN_CONVERSATIONS_SEARCH_FAILURE:
            return { ...state, searchResult: { isLoading: false } };
        case actionTypes.ADMIN_CONVERSATIONS_SET_SEARCH_PARAMETERS:
            return { ...state, searchParameters: action.searchParameters };
        default:
            return state;
    }
};


export default conversations;
