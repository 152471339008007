import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from 'styles/util';
import multiplicities from 'enums/multiplicities';
import { required } from 'form/validation';
import strings from 'localization/strings';

import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import CustomRadioButtonList from './CustomRadioButtonList';

const useStyles = makeStyles(({ theme, colors }) => ({
    optionContainer: {
        display: 'flex',
        alignItems: 'center',
        border: `2px solid ${colors.mediumGrey}`,
        borderRadius: theme.spacing(1),
        padding: theme.spacing(2),
        margin: theme.spacing(1, 0),
        '& > :first-child': {
            flex: '0 0 auto',
            padding: theme.spacing(0, 2, 0, 0),
        },
        '& > :last-child': {
            flex: '1 1 auto'
        }
    }
}));

const EditStep0 = ({ showStepNumber = true }) => {
    const classes = useStyles();
    const fieldNamePrefix = 'steps[0]';
    const getName = suffix => suffix ? `${fieldNamePrefix}.${suffix}` : fieldNamePrefix;

    const options = [
        { value: multiplicities.single, title: strings.multiplicities.single.title, subtitle: strings.multiplicities.single.subtitle },
        { value: multiplicities.multiple, title: strings.multiplicities.multiple.title, subtitle: strings.multiplicities.multiple.subtitle }
    ];

    const optionsRenderer = (radioComponent, option) => (
        <Box className={classes.optionContainer}>
            <Box>
                {radioComponent}
            </Box>
            <Box>
                <Typography variant="h6" display="block" gutterBottom>
                    {option.title}
                </Typography>
                <Typography variant="body2" display="block">
                    {option.subtitle}
                </Typography>
            </Box>
        </Box>
    );

    return (
        <>
            <Typography variant="h5" display="block" gutterBottom>
                { showStepNumber && '1. ' }
                {strings.multiplicity}
            </Typography>

            <CustomRadioButtonList
                name={getName('multiplicity')}
                options={options}
                renderer={optionsRenderer}
                validate={required}
            />
        </>
    );
};

EditStep0.propTypes = {
    showStepNumber: PropTypes.bool
};

export default EditStep0;
