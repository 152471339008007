import React from 'react';
import PropTypes from 'prop-types';
import strings from 'localization/strings';

import Box from '@material-ui/core/Box';
import Text from 'common/Text';
import BookingDetailsSectionCaption from './BookingDetailsSectionCaption';

const BookingDescriptionGivenAfterPurchase = ({ booking }) => {
    return (
        <Box>
            <BookingDetailsSectionCaption>
                {strings.descriptionGivenAfterPurchaseForTenant}
            </BookingDetailsSectionCaption>
            <Box>
                <Text text={booking.storageGroup.storageSite.descriptionGivenAfterPurchase}/>
            </Box>
        </Box>
    );
};

BookingDescriptionGivenAfterPurchase.propTypes = {
    booking: PropTypes.object.isRequired
};

export default BookingDescriptionGivenAfterPurchase;
