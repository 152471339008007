import GeolocationManager from 'lib/geolocation/GeolocationManager';
import routes from 'routes';

export const getUserLocation = ipLocation => {
    // 1: geolocation API (browser-based)
    const geolocationManager = new GeolocationManager();
    const lastLocation = geolocationManager.getLastLocation();
    if (lastLocation) {
        return lastLocation;
    }
    // 2: user IP (server-based)
    return ipLocation;
};

export const shouldRedirectToAcceptTermsOfService = (authenticationContext, location) => {
    return authenticationContext.user &&
           !authenticationContext.isImpersonated &&
           authenticationContext.user.newTermsOfServiceVersionAwaiting &&
           !(location.pathname === routes.acceptTermsOfService || location.pathname === routes.policies.general || location.pathname === routes.logOut || location.pathname === routes.index || location.pathname === '');
};
