import React from 'react';
import PropTypes from 'prop-types';
import strings from 'localization/strings';
import { required, email } from 'form/validation';
import { makeStyles } from 'styles/util';
import { useForm } from 'react-final-form';

import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import Text from 'common/Text';
import IconPrefix from 'common/IconPrefix';
import { TextField, showErrorOnBlur } from 'mui-rff';
import AcceptTermsOfServiceCheckbox from 'components/AcceptTermsOfServiceCheckbox';
import privateIcon from 'assets/images/private-primary.svg';
import businessIcon from 'assets/images/business-primary.svg';

const useStyles = makeStyles(({ theme, fonts }) => ({
    container: {
        display: 'flex',
        flexDirection: 'column',
        gap: theme.spacing(2.25)
    },
    nextButton: {
        [theme.breakpoints.up('md')]: {
            alignSelf: 'center',
            minWidth: '315px',
        }
    },
    bold: {
        fontFamily: fonts.bold
    }
}));

const BusinessActorForm = ({ fieldNamePrefix, allowAlterActorInformation = false, onSubmit }) => {
    const classes = useStyles();
    const formState = useForm().getState();
    const getName = suffix => suffix ? `${fieldNamePrefix}.${suffix}` : fieldNamePrefix;

    return (
        <Box className={classes.container}>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <Typography variant="body1" gutterBottom>
                        <Text html={strings.authentication.createBusinessActorDetails}/>
                    </Typography>
                </Grid>

                <Grid item xs={12}>
                    <Typography variant="body1" component="div" className={classes.bold}>
                        <IconPrefix icon={<img src={businessIcon}/>}>
                            {strings.authentication.organizationDetails}
                        </IconPrefix>
                    </Typography>
                </Grid>

                <Grid item xs={12} md={6}>
                    <TextField
                        name={getName('actorName')}
                        label={strings.companyNameLabel}
                        variant="outlined"
                        showError={showErrorOnBlur}
                        required
                        disabled={!allowAlterActorInformation}
                        fieldProps={{ validate: required }}
                    />
                </Grid>
                <Grid item xs={12} md={6}>
                    <TextField
                        name={getName('actorOrganizationNumber')}
                        label={strings.organizationNumber}
                        variant="outlined"
                        showError={showErrorOnBlur}
                        required
                        disabled={!allowAlterActorInformation}
                        fieldProps={{ validate: required }}
                    />
                </Grid>

                <Grid item xs={12} md={6}>
                    <TextField
                        name={getName('actorEmail')}
                        label={strings.companyEmail}
                        variant="outlined"
                        showError={showErrorOnBlur}
                        required
                        fieldProps={{ validate: email }}
                    />
                </Grid>
                <Grid item xs={12} md={6}>
                    <TextField
                        name={getName('actorMobileTelephone')}
                        label={strings.companyPhoneNumber}
                        variant="outlined"
                        showError={showErrorOnBlur}
                        required
                        fieldProps={{ validate: required }}
                    />
                </Grid>

                <Grid item xs={12}>
                    <Typography variant="body1" component="div" className={classes.bold}>
                        <IconPrefix icon={<img src={privateIcon}/>}>
                            {strings.authentication.userDetails}
                        </IconPrefix>
                    </Typography>
                </Grid>

                <Grid item xs={12} md={6}>
                    <TextField
                        name={getName('userEmail')}
                        label={strings.userEmail}
                        variant="outlined"
                        showError={showErrorOnBlur}
                        required
                        fieldProps={{ validate: email }}
                    />
                </Grid>

                <Grid item xs={12}>
                    <Typography variant="h6">
                        <AcceptTermsOfServiceCheckbox name={getName('termsOfServiceAccepted')}/>
                    </Typography>
                </Grid>
            </Grid>
            <Button
                className={classes.nextButton}
                type="button"
                variant="contained"
                disabled={formState.invalid}
                color="primary"
                onClick={onSubmit}
            >
                {strings.next}
            </Button>
        </Box>
    );
};

BusinessActorForm.propTypes = {
    fieldNamePrefix: PropTypes.string.isRequired,
    approveImmediately: PropTypes.bool,
    allowAlterActorInformation: PropTypes.bool,
    onSubmit: PropTypes.func.isRequired
};

export default BusinessActorForm;
