import React from 'react';
import strings from 'localization/strings';
import { makeStyles } from 'styles/util';
import { useScrollToTop } from 'helpers/BrowserHelper';

import PageTitle from 'common/PageTitle';
import Container from 'common/Container';
import EarnMoneyWidget from 'components/EarnMoneyWidget';
import TextItemBox from './TextItemBox';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import VisibleFor from 'common/VisibleFor';

const useStyles = makeStyles(({ theme, colors }) => ({
    howItWorksSecondContainer: {
        display: 'flex',
        flexWrap: 'wrap',
        flexDirection: 'row',
        gap: theme.spacing(4),
        marginTop: '40px',
        marginBottom: '20px',
        [theme.breakpoints.up('lg')]: {
            marginTop: '80px',
            marginBottom: '40px'
        }
    },

    textTitle: {
        color: colors.primaryColor
    },

    howItWorksVideoAndTitle: {
        display: 'flex',
        gap: theme.spacing(4),
        marginTop: theme.spacing(4)
    },

    aboutVideoContainer: {
        position: 'relative',
        width: '100%',
        height: 0,
        paddingBottom: '56.25%',
        [theme.breakpoints.up('lg')]: {
            width: '602px',
            height: '339px',
            paddingBottom: 0
        }
    },

    aboutVideoIframe: {
        width: '100%',
        height: '100%',
        position: 'absolute',
        left: 0,
        top: 0,
        [theme.breakpoints.up('lg')]: {
            left: 'auto',
            width: '602px',
            height: '339px'
        }
    },

    videoHowItWorks: {
        backgroundColor: colors.lightestGrey,
        padding: theme.spacing(4)
    },

    column: {
        marginBottom: theme.spacing(4),
        [theme.breakpoints.up('md')]: {
            width: '48%',
            verticalAlign: 'top',
            display: 'inline-block'
        }
    }
}),
({ colors }) => ({
    storage: {
        earnMoneyContainer: {
            background: colors.secondaryColor
        }
    },
    office: {
        earnMoneyContainer: {
            background: colors.primaryColor
        }
    }
}));

const HowItWorksPage = () => {
    const classes = useStyles();

    const howItWorksItems = [
        {
            title: strings.howItWorksList.item1.title,
            description: strings.howItWorksList.item1.description
        },
        {
            title: strings.howItWorksList.item2.title,
            description: strings.howItWorksList.item2.description
        },
        {
            title: strings.howItWorksList.item3.title,
            description: strings.howItWorksList.item3.description
        },
    ];

    useScrollToTop();

    return (
        <>
            <Container withPageMargins>
                <PageTitle>{strings.headerHowItWorks}</PageTitle>
                <Box>
                    <Typography variant="h1" className={classes.pageTitle}>{strings.howItWorksTitle1}</Typography>
                    <Typography variant="body1">{strings.howItWorksText1}</Typography>
                </Box>
                <Box className={classes.howItWorksSecondContainer}>
                    <Box className={classes.column}>
                        <Typography variant="h3" className={classes.textTitle}>{strings.howItWorksTitle2}</Typography>
                        <Typography variant="body1">{strings.howItWorksText2}</Typography>
                    </Box>
                    <Box className={classes.column}>
                        <Typography variant="h3" className={classes.textTitle}>{strings.howItWorksTitle3}</Typography>
                        <Typography variant="body1">{strings.howItWorksText3}</Typography>
                    </Box>
                    <Box className={classes.column}>
                        <Typography variant="h3" className={classes.textTitle}>{strings.howItWorksTitle4}</Typography>
                        <Typography variant="body1">{strings.howItWorksText4}</Typography>
                    </Box>
                    <Box className={classes.column}>
                        <Typography variant="h3" className={classes.textTitle}>{strings.howItWorksTitle5}</Typography>
                        <Typography variant="body1">{strings.howItWorksText5}</Typography>
                    </Box>
                </Box>

                <VisibleFor branch="storage">
                    <Box className={classes.videoHowItWorks} marginTop={4}>
                        <Typography variant="h2" className={classes.textTitle}>{strings.howItWorksTitle6}</Typography>
                        <Box className={classes.howItWorksVideoAndTitle}>
                            <Box className={classes.aboutVideoContainer}>
                                <iframe
                                    width="560"
                                    height="349"
                                    allowFullScreen
                                    frameBorder="0"
                                    className={classes.aboutVideoIframe}
                                    src="https://www.youtube.com/embed/CWw1x2VBv-k"
                                />
                            </Box>
                            <TextItemBox items={howItWorksItems}/>
                        </Box>
                    </Box>
                </VisibleFor>
            </Container>
            <Box className={classes.earnMoneyContainer}>
                <Container>
                    <EarnMoneyWidget/>
                </Container>
            </Box>
        </>
    );
};

export default HowItWorksPage;
