import React, { useEffect, useState, useRef } from 'react';
import PropTypes from 'prop-types';
import { useLocation, useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { makeStyles } from 'styles/util';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { setRedirectUrl } from 'actions/authentication';
import strings from 'localization/strings';
import { createGuid } from 'helpers/GuidHelper';
import { createEidTemporaryData, fetchEidTemporaryData, swedishBankIdDetectDevice, swedishBankIdAuthenticate, swedishBankIdCollect, swedishBankIdCancel } from 'actions/authentication';
import { handleResponse } from 'actions/actionHelpers';
import { getSwedishBankIdMessage } from './swedishBankIdHelpers';
import queryString from 'query-string';
import { navigateToUrl, addQuerystringParametersToUrl } from 'helpers/BrowserHelper';
import { SeverityLevel } from '@microsoft/applicationinsights-web';
import eidProviders from 'enums/eidProviders';

import Platform from 'platform';
import Button from '@material-ui/core/Button';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import Alert from '@material-ui/lab/Alert';
import LinkButton from 'common/LinkButton';
import bankid from 'assets/images/sweden/bankid.svg';
import CircularProgress from '@material-ui/core/CircularProgress';
import TwoButtons from 'common/TwoButtons';

const useStyles = makeStyles(({ theme, fonts }) => ({
    container: {
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        gap: theme.spacing(3.75)
    },
    title: {
        fontFamily: fonts.bold
    },
    message: {
        display: 'flex',
        flexDirection: 'column'
    },
    circularProgress: {
        alignSelf: 'center',
        margin: theme.spacing(3.75, 0, 3.75, -4)
    },
    qrCodeContainer: {
        display: 'flex',
        flexDirection: 'column',
        gap: theme.spacing(3.75)
    },
    qrCodeImage: {
        width: '150px',
        [theme.breakpoints.down('sm')]: {
            alignSelf: 'center'
        }
    }
}));

const SwedishBankIdLogin = ({ title = strings.bankId.loginWithBankId, redirectUrl, clientState, onStatusChange }) => {
    const isMobile = !useMediaQuery(theme => theme.breakpoints.up('md'));
    const classes = useStyles();
    const dispatch = useDispatch();
    const location = useLocation();
    const history = useHistory();
    const querystringParams = queryString.parse(location.search);

    // what a hack for stupid iOS
    const platform = Platform.os.family === 'iOS' || (Platform.os.family === 'OS X' && isMobile)
        ? 'iOS'
        : window.navigator.platform;

    const [isLoading, setIsLoading] = useState(false);
    const [message, setMessage] = useState(undefined); // the message informing about Swedish BankID progress
    const [alertSeverity, setAlertSeverity] = useState(undefined); // the alert severity of the message informing about Swedish BankID progress
    const [swedishBankIdDetectedDevice, setSwedishBankIdDetectedDevice] = useState({}); // the Swedish BankID-related information for the current device
    const [isUsingOtherDevice, setIsUsingOtherDevice] = useState(false); // whether the user has chosen to log in with Swedish BankID on another device
    const [qrCode, setQrCode] = useState(undefined); // the QR code used for login
    const [currentProtectedAuthenticationData, setCurrentProtectedAuthenticationData] = useState(undefined); // the encrypted protectedAuthenticationData from our BankID integration
    const [cancelledOrders, setCancelledOrders] = useState([]); // keep track of cancelled orders to be able to display precise messages
    const [timerHandle, setTimerHandle] = useState(undefined); // timer used for collecting Swedish BankID information from server
    const [launchUrl, setLaunchUrl] = useState(undefined); // url to launch when user interaction is required to launch the BankID app

    // refs, to be used inside timer callback
    const cancelledProtectedAuthenticationDatas = useRef(cancelledOrders);
    cancelledProtectedAuthenticationDatas.current = cancelledOrders;
    const isUsingOtherDeviceRef = useRef(isUsingOtherDevice);
    isUsingOtherDeviceRef.current = isUsingOtherDevice;
    const launchUrlRef = useRef(launchUrl);
    launchUrlRef.current = launchUrl;

    useEffect(() => {
        setIsLoading(true);
        // start off by detecting device capabilites related to Swedish BankID server-side using the user agent string
        dispatch(swedishBankIdDetectDevice(window.navigator.userAgent, platform, window.navigator.appVersion))
            .then(handleResponse(
                response => {
                    setSwedishBankIdDetectedDevice(response.payload);
                    setIsLoading(false);
                    logToApplicationInsights(response.payload, { subType: 'SwedishBankIdDetectedDeviceSuccess', userAgent: window.navigator.userAgent, platform, appVersion: window.navigator.appVersion, requestUrl: getRequestUrl(response) });
                },
                response => {
                    updateMessage(strings.bankId.error, 'error');
                    setIsLoading(false);
                    logToApplicationInsights(response.payload, { subType: 'SwedishBankIdDetectedDeviceError', userAgent: window.navigator.userAgent, platform, appVersion: window.navigator.appVersion, requestUrl: getRequestUrl(response) }, SeverityLevel.Error);
                }
            ));
            return clearTimer;
    }, []);

    useEffect(() => {
        // the Swedish BankID app on iOS will reload the page
        // this is the hook for getting the authentication data back and continue collecting it
        const swedishBankIdGuid = querystringParams.swedishBankIdGuid;
        if(swedishBankIdGuid) {
            setIsLoading(true);
            dispatch(fetchEidTemporaryData(swedishBankIdGuid))
                .then(handleResponse(
                    response => {
                        setCurrentProtectedAuthenticationData(response.payload.protectedAuthenticationData);
                        // after reload, we've lost the redirectUrl state, so re-populate it here
                        dispatch(setRedirectUrl(response.payload.redirectUrl));
                        // re-init the collection
                        const collectRequest = {
                            protectedAuthenticationData: response.payload.protectedAuthenticationData,
                            clientState: response.payload.clientState
                        };
                        collect(collectRequest);
                        logToApplicationInsights(response.payload, { swedishBankIdGuid, subType: 'FetchTemporaryLoginDataSuccess', requestUrl: getRequestUrl(response) });
                    },
                    response => {
                        updateMessage(strings.bankId.error, 'error');
                        setIsLoading(false);
                        logToApplicationInsights(response.payload, { swedishBankIdGuid, subType: 'FetchTemporaryLoginDataError', requestUrl: getRequestUrl(response) }, SeverityLevel.Error);
                    }
                ));
        }
    }, []);

    const triggerOnStatusChange = status => {
        if(onStatusChange) {
            onStatusChange(status);
        }
    };

    const getRequestUrl = response => response.meta.response.url;

    const clearTimer = () => {
        if(timerHandle) {
            window.clearTimeout(timerHandle);
        }
    };

    const handleThisDeviceButtonClick = () => {
        triggerOnStatusChange('started');
        authenticate(false);
    };

    const handleOtherDeviceButtonClick = () => {
        triggerOnStatusChange('started');
        authenticate(true);
    };

    const handleCancelButtonClick = () => {
        cancel();
    };

    const updateMessageFromParameters = parameters => {
        const swedishBankIdMessage = getSwedishBankIdMessage(parameters);
        updateMessage(swedishBankIdMessage.text, swedishBankIdMessage.type);
    };

    const updateMessage = (newMessage, newAlertSeverity) => {
        setMessage(newMessage);
        setAlertSeverity(newAlertSeverity);
    };

    const authenticate = useOtherDevice => {
        const swedishBankIdGuid = createGuid(); // used as a key to temporary login data
        const request = {
            useOtherDevice,
            userAgent: window.navigator.userAgent,
            platform,
            appVersion: window.navigator.appVersion,
            returnUrl: addQuerystringParametersToUrl(window.location.href, { swedishBankIdGuid }, true)  // for iOS, we need the swedishBankIdGuid parameter to be able to pick the authentication data after reload
        };
        setIsLoading(true);
        setCurrentProtectedAuthenticationData(undefined);
        setQrCode(undefined);
        setIsUsingOtherDevice(useOtherDevice);
        setLaunchUrl(undefined);
        updateMessageFromParameters({
            status: 'pending',
            deviceType: swedishBankIdDetectedDevice.type,
            useOtherDevice
        });
        cancelCurrentOrder();
        dispatch(swedishBankIdAuthenticate(request))
            .then(handleResponse(
                response => {
                    const status = response.payload.errorCode
                        ? undefined
                        : 'pending';
                    updateMessageFromParameters({
                        status,
                        errorCode: response.payload.errorCode,
                        deviceType: swedishBankIdDetectedDevice.type,
                        useOtherDevice,
                        deviceMightRequireUserInteractionToLaunchBankIdApp: !!launchUrl
                    });
                    if(status === 'pending') {
                        setCurrentProtectedAuthenticationData(response.payload.protectedAuthenticationData);
                        setQrCode(response.payload.qrCode);
                        if(response.payload.qrCode) {
                            document.getElementById('swedish-bankid-login').scrollIntoView({ behavior: 'smooth' });
                        }
                        if(!useOtherDevice) {
                            if(response.payload.deviceMightRequireUserInteractionToLaunchBankIdApp) {
                                // some browsers require that the user actively clicks on the Swedish BankID link
                                setLaunchUrl(response.payload.launchUrl);
                                logToApplicationInsights(response.payload, { swedishBankIdGuid, subType: 'SetLaunchUrl', request });
                            } else {
                                // other browsers can launch the Swedish BankId app without user interaction
                                launch(response.payload.launchUrl);
                                logToApplicationInsights(response.payload, { swedishBankIdGuid, subType: 'Launch', request });
                            }
                        }

                        if (response.payload.deviceWillReloadPageOnReturnFromBankIdApp) {
                            // we need to store the authentication information server side to be able to pick it up after a reload
                            dispatch(createEidTemporaryData(eidProviders.swedishBankId.key, swedishBankIdGuid, { protectedAuthenticationData: response.payload.protectedAuthenticationData, clientState: { ...clientState, redirectUrl } }));
                            logToApplicationInsights(response.payload, { swedishBankIdGuid, subType: 'CreateTemporaryLoginData', request });
                        } else {
                            // set up the periodic collect call
                            const collectRequest = {
                                protectedAuthenticationData: response.payload.protectedAuthenticationData,
                                clientState
                            };
                            collect(collectRequest);
                            logToApplicationInsights(response.payload, { swedishBankIdGuid, subType: 'CollectStart', request });
                        }
                    } else {
                        setIsLoading(false);
                    }
                },
                response => {
                    updateMessage(strings.bankId.error, 'error');
                    setIsLoading(false);
                    logToApplicationInsights(response.payload, { swedishBankIdGuid, subType: 'AuthenticateException', request }, SeverityLevel.Error);
                }
            ));
    };

    const cancel = () => {
        cancelCurrentOrder();
        setQrCode(undefined);
        updateMessage(undefined, undefined);
        setIsLoading(false);
        triggerOnStatusChange('canceled');
    };

    const cancelCurrentOrder = () => {
        if(currentProtectedAuthenticationData) {
            setCancelledOrders([ ...cancelledOrders, currentProtectedAuthenticationData ]);
            dispatch(swedishBankIdCancel(currentProtectedAuthenticationData));
            logToApplicationInsights({ currentProtectedAuthenticationData }, { subType: 'CollectCancel' });
            setCurrentProtectedAuthenticationData(undefined);
            setQrCode(undefined);
        }
    };

    const launch = url => {
        // simulate a link click to auto-launch
        navigateToUrl(url);
    };

    const collect = request => {
        const { protectedAuthenticationData } = request;
        clearTimer(); // we clean at every invocation; if there is a need for more collections it will be triggered below
        const handle = window.setTimeout(() => {
            if(isCancelledOrder(protectedAuthenticationData)) {
                return;
            }
            dispatch(swedishBankIdCollect(request))
                .then(handleResponse(
                    response => {
                        if(isCancelledOrder(protectedAuthenticationData)) {
                            return;
                        }
                        // report status to user
                        updateMessageFromParameters({
                            status: response.payload.status,
                            hintCode: response.payload.hintCode,
                            deviceType: swedishBankIdDetectedDevice.type,
                            useOtherDevice: isUsingOtherDeviceRef.current,
                            deviceMightRequireUserInteractionToLaunchBankIdApp: !!launchUrlRef.current
                        });

                        switch(response.payload.status) {
                            case 'pending':
                                // update QR code and continue to poll
                                setQrCode(response.payload.qrCode);
                                collect(request);
                                break;
                            case 'complete':
                                // succeeded!
                                // redirect with success
                                setIsLoading(false);
                                setLaunchUrl(undefined);
                                logToApplicationInsights(response.payload, { protectedAuthenticationData, subType: 'CollectComplete', requestUrl: getRequestUrl(response) });
                                history.push(addQuerystringParametersToUrl(redirectUrl, { eidLoginResult: 'success', eidTemporaryDataToken: response.payload.eidTemporaryDataToken } ));
                                break;
                            case 'failed':
                                // inform the user that something went wrong
                                // redirect with failure
                                setIsLoading(false);
                                setQrCode(undefined);
                                setCurrentProtectedAuthenticationData(undefined);
                                setLaunchUrl(undefined);
                                logToApplicationInsights(response.payload, { protectedAuthenticationData, subType: 'CollectFailed', requestUrl: getRequestUrl(response) }, SeverityLevel.Error);
                                history.push(addQuerystringParametersToUrl(redirectUrl, { eidLoginResult: 'failure', eidLoginErrorMessage: getSwedishBankIdMessage(response.payload).text } ));
                                break;
                            default:
                                break;
                        }
                    },
                    response => {
                        // the swedishBankIdCollect API call failed, e.g. due to a network issue
                        // redirect with failure
                        updateMessage(strings.bankId.error, 'error');
                        setQrCode(undefined);
                        setCurrentProtectedAuthenticationData(undefined);
                        setIsLoading(false);
                        logToApplicationInsights(response.payload, { protectedAuthenticationData, subType: 'CollectException', requestUrl: getRequestUrl(response) }, SeverityLevel.Error);
                        history.push(addQuerystringParametersToUrl(redirectUrl, { eidLoginResult: 'failure', eidLoginErrorMessage: strings.bankId.error } ));
                    }
                ));
        }, 1000 /* 1 second, according to Swedish BankID spec */);
        setTimerHandle(handle);
    };

    const isCancelledOrder = protectedAuthenticationData => cancelledProtectedAuthenticationDatas.current.indexOf(protectedAuthenticationData) > -1;

    const cancelLinkButton = currentProtectedAuthenticationData
        ? (
            <LinkButton
                onClick={handleCancelButtonClick}
            >
                {strings.bankId.cancel}
            </LinkButton>
        )
        : undefined;

    const logToApplicationInsights = (data, properties, severityLevel = SeverityLevel.Information) => {
        if(window.applicationInsights) {
            window.applicationInsights.trackTrace({
                message: JSON.stringify(data),
                properties: { type: 'SwedishBankID', ...properties },
                severityLevel
            });
        }
    };

    const circularProgress = (isLoading || true) &&
        (
            <CircularProgress
                size={40}
                color="primary"
                className={classes.circularProgress}
            />
        );

    return (
        <Box id="swedish-bankid-login" className={classes.container}>
            <Typography variant="body1" className={classes.title}>{title}</Typography>

            {
                !isLoading && !currentProtectedAuthenticationData &&
                (
                    <TwoButtons
                        first={{
                            text: strings.bankId.thisDevice,
                            icon: bankid,
                            onClick: handleThisDeviceButtonClick
                        }}
                        second={{
                            text: strings.bankId.otherDevice,
                            icon: bankid,
                            onClick: handleOtherDeviceButtonClick
                        }}
                    />
                )
            }

            {
                launchUrl &&
                (
                    <Button
                        href={launchUrl}
                        color="primary"
                        variant="contained"
                        fullWidth={isMobile}
                    >
                        {strings.bankId.openBankIdApp}
                    </Button>
                )
            }

            {
                message && alertSeverity &&
                (
                    <Alert severity={alertSeverity}>
                        <Box className={classes.message}>
                            <Box>
                                {message}
                            </Box>
                            {circularProgress}
                        </Box>
                    </Alert>
                )
            }

            {
                message && !alertSeverity &&
                (
                    <Box className={classes.message}>
                        <Box>
                            {message}
                        </Box>
                        {circularProgress}
                    </Box>
                )
            }

            { !qrCode && alertSeverity !== 'success' && cancelLinkButton }

            {
                qrCode &&
                (
                    <Box className={classes.qrCodeContainer}>
                        <img className={classes.qrCodeImage} src={`data:image/png;base64,${qrCode}`}/>
                        {cancelLinkButton}
                    </Box>
                )
            }
        </Box>
    );
};

SwedishBankIdLogin.propTypes = {
    title: PropTypes.string,
    description: PropTypes.string,
    redirectUrl: PropTypes.string.isRequired,
    clientState: PropTypes.object,
    onStatusChange: PropTypes.func
};

export default SwedishBankIdLogin;
