import React from 'react';
import PropTypes from 'prop-types';
import strings from 'localization/strings';
import { formatPercentage, formatAmount } from 'helpers/StringHelper';
import { useAppContext } from 'context/AppContext';
import { calculateSum } from 'helpers/ArrayHelper';

import { makeStyles } from 'styles/util';

import Box from '@material-ui/core/Box';
import Amount from 'common/Amount';

const useStyles = makeStyles(() => ({
    vatInformation: {
        display: 'table',
        margin: '1em 1em 1em auto'
    },
    vatInformationRow: {
        display: 'table-row',
    },
    vatInformationLabel: {
        display: 'table-cell',
        padding: '0.15em 2em 0.15em 0',
        textAlign: 'right'
    },
    vatInformationValue: {
        display: 'table-cell',
        padding: '0.15em 0 0.15em 0',
        textAlign: 'right'
    }
}));

const PostInvoiceVatInformation = ({ purchase }) => {
    const classes = useStyles();
    const { appContext } = useAppContext();

    if(purchase.vatItems.length === 0) {
        return null;
    }

    return (
        <Box className={classes.vatInformation}>
        <Box className={classes.vatInformationRow}>
            <Box className={classes.vatInformationLabel}>
                {strings.paymentDocuments.net}
            </Box>
            <Box className={classes.vatInformationValue}>
                <Amount value={calculateSum(purchase.vatItems, o => o.amountIncludingVat)} currency={purchase.currency} displayPriceRounding={false} />
            </Box>
        </Box>

        {
            purchase.vatItems.map((vatItem, i) => (
                <Box key={i} className={classes.vatInformationRow}>
                    <Box className={classes.vatInformationLabel}>
                        {strings.formatString(strings.paymentDocuments.vatXCalculatedOnY, formatPercentage(vatItem.vatRate, appContext), formatAmount(vatItem.amountIncludingVat, purchase.currency, appContext, { displayPriceRounding: false }))}
                    </Box>
                    <Box className={classes.vatInformationValue}>
                        <Amount value={vatItem.vat} currency={purchase.currency} displayPriceRounding={false} />
                    </Box>
                </Box>
            ))
        }
    </Box>
    );
};

PostInvoiceVatInformation.propTypes = {
    purchase: PropTypes.object.isRequired
};

export default PostInvoiceVatInformation;
