import React from 'react';
import PropTypes from 'prop-types';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { getPageSizes } from 'helpers/StorageSearchFormHelper';
import strings from 'localization/strings';
import { makeStyles } from 'styles/util';

import Box from '@material-ui/core/Box';
import Form from 'form/Form';
import Pagination from '@material-ui/lab/Pagination';
import DropdownButton from 'form/DropdownButton';

const PaginationWrapper = ({ onChange, isTop, numberOfStorageSites, searchParams }) => {
    const isMobile = !useMediaQuery(theme => theme.breakpoints.up('md'));
    const useStyles = makeStyles(({ theme, colors }) => ({
        paginationWrapper: {
            display: 'flex',
            alignItems: 'center'
        },
        form: {
            display: 'flex',
            flexDirection: 'column',
            gap: theme.spacing(3),
            width: '100%'
        },
        rowsInfo: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            zoom: isMobile ? 0.75 : 1,
            fontSize: theme.spacing(2),
            fontStyle: 'normal',
            fontWeight: 'normal',
            lineHeight: '150%',
        },
        pageSize: {
            display: 'flex',
            flex: '0 0 auto',
            alignItems: 'center',
            color: colors.darkBlue,
            '& > *': {
                whiteSpace: 'nowrap'
            },
            gap: theme.spacing(2)
        },
        pageSizeTextField: {
            width: '5rem',
            margin: theme.spacing(0),
            '& .MuiOutlinedInput-input': {
                padding: theme.spacing(1.5)
            },
            '& .MuiOutlinedInput-root': {
                borderRadius: theme.spacing(1),
                background: colors.inherit,
                color: colors.darkBlue,
            },
            '& .MuiOutlinedInput-notchedOutline': {
                border: `2px solid ${colors.darkBlue}`
            },
            '& .MuiSvgIcon-root': {
                color: colors.darkBlue,
                fontSize: theme.spacing(2.875)
            }
        },
        showingResults: {
            textAlign: 'right',
            color: colors.black
        },
        paginationComponentWrapper: {
            textAlign: 'center',
            width: isMobile ? '100%' : undefined
        },
        paginationComponent: {
            display: 'inline-block',
            '& .MuiPaginationItem-page': {
                fontSize: theme.spacing(2),
                color: colors.black,
                fontStyle: 'normal',
                fontWeight: 'normal',
                lineHeight: '150%'
            },
            '& .Mui-selected': {
                color: colors.white
            },
            '& .MuiPaginationItem-page:not(.Mui-selected)': {
                backgroundColor: colors.white,
                '&:hover': {
                    backgroundColor: colors.mediumGrey
                }
            },
            '& .MuiPagination-ul li:first-of-type button': {
                backgroundColor: colors.inherit,
                '&:hover': {
                    backgroundColor: colors.mediumGrey
                }
            },
            '& .MuiPagination-ul li:last-of-type button': {
                backgroundColor: colors.inherit,
                '&:hover': {
                    backgroundColor: colors.mediumGrey
                }
            },
            '& .MuiPaginationItem-icon': {
                fontSize: theme.spacing(2.75)
            }
        }
    }));
    const classes = useStyles();

    const { page, pageSize } = searchParams;

    const fromItem = page === 1
        ? 1
        : (page - 1) * pageSize + 1;
    const toItem = Math.min(numberOfStorageSites, page * pageSize);
    const numberOfPages = Math.ceil(numberOfStorageSites / pageSize);

    const handlePageSizeChange = event => {
        onChange({ ...searchParams, pageSize: event.target.value });
    };

    const handlePageChange = (event, newPage) => {
        onChange({ ...searchParams, page: newPage });
    };

    const initialValues = {
        page: searchParams.page,
        pageSize: searchParams.pageSize
    };

    const pagination = numberOfPages > 1 &&
    (
        <Pagination
            className={classes.paginationComponent}
            color="primary"
            page={page}
            count={numberOfPages}
            onChange={handlePageChange}
            siblingCount={isMobile ? 0 : 2}
            boundaryCount={isMobile ? 1 : 2}
        />
    );

    return (
        <Box className={classes.paginationWrapper}>
            <Form
                onSubmit={() => {}}
                initialValues={initialValues}
            >
                {() => (
                    <form className={classes.form}>
                        {
                            isTop &&
                            (
                                <Box className={classes.rowsInfo}>
                                    <Box className={classes.pageSize}>
                                        <Box>
                                            {strings.rowsPerPage}
                                        </Box>
                                        <DropdownButton
                                            name="pageSize"
                                            onChange={handlePageSizeChange}
                                            items={getPageSizes()}
                                        />
                                    </Box>
                                    {
                                        !isMobile &&
                                        (
                                            <Box className={classes.paginationComponentWrapper}>
                                                {pagination}
                                            </Box>
                                        )
                                    }
                                    {
                                        toItem > 0 &&
                                        (
                                            <Box className={classes.showingResults}>
                                                {strings.showingSearchResults}{' '}{fromItem}-{toItem}
                                            </Box>
                                        )
                                    }
                                </Box>
                            )
                        }
                        {
                            !(isTop && !isMobile) &&
                            (
                                <Box className={classes.paginationComponentWrapper}>
                                    {pagination}
                                </Box>
                            )
                        }
                    </form>
                )}
            </Form>
        </Box>
    );
};

PaginationWrapper.propTypes = {
    onChange: PropTypes.func,
    isTop: PropTypes.bool,
    numberOfStorageSites: PropTypes.number,
    searchParams: PropTypes.object
};

export default PaginationWrapper;
