import { RSAA } from 'redux-api-middleware';
import { createRsaaTypes, createHeaders } from '../actionHelpers';

export const ADMIN_MESSAGE_TEMPLATES_GET = 'ADMIN_MESSAGE_TEMPLATES_GET';
export const ADMIN_MESSAGE_TEMPLATES_GET_TEMPLATE = 'ADMIN_MESSAGE_TEMPLATES_GET_TEMPLATE';

export const getMessageTemplates = () => ({
    [RSAA]: {
        types: createRsaaTypes(ADMIN_MESSAGE_TEMPLATES_GET),
        endpoint: '/api/admin/messageTemplates',
        headers: createHeaders(false),
        method: 'GET'
    }
});

export const getMessageTemplate = (type, id) => ({
    [RSAA]: {
        types: createRsaaTypes(ADMIN_MESSAGE_TEMPLATES_GET_TEMPLATE),
        endpoint: `/api/admin/messageTemplates/${type}/${id}`,
        headers: createHeaders(false),
        method: 'GET'
    }
});
