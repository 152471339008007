const pageSizes = {
    twenty: {
        value: 20,
        title: '20'
    },
    fifty: {
        value: 50,
        title: '50'
    },
    hundred: {
        value: 100,
        title: '100'
    }
};

export default pageSizes;
