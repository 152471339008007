import React from 'react';
import PropTypes from 'prop-types';

import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import Text from 'common/Text';

const Description = ({ title, titleVariant, text, html, ...rest }) => {
    return (
        <Box {...rest}>
            <Typography variant={titleVariant} display="block" gutterBottom>{title}</Typography>
            <Typography variant="body1" display="block">
                <Text text={text} html={html}/>
            </Typography>
        </Box>
    );
};

Description.propTypes = {
    title: PropTypes.string.isRequired,
    titleVariant: PropTypes.string,
    text: PropTypes.string,
    html: PropTypes.string
};

export default Description;
