import React from 'react';
import PropTypes from 'prop-types';
import strings from 'localization/strings';
import { makeStyles } from 'styles/util';
import Amount from 'common/Amount';

import Collapse from '@material-ui/core/Collapse';
import Typography from '@material-ui/core/Typography';

const useStyles = makeStyles(({ colors }) => ({
    vatInfo: {
        '& > *': {
            color: colors.mediumDarkGrey
        }
    }
}));

const VatDetails = ({vat, currency, formatAmountOptions, expandVat = false}) => {
    const classes = useStyles();

    if (!vat) {
        return null;
    }

    return (
        <Collapse in={expandVat} className={classes.vatInfo}>
            <Typography variant="body2"><Amount value={vat} currency={currency} {...formatAmountOptions}/> {strings.priceSpecificationTable.vat}</Typography>
        </Collapse>
    );
};

VatDetails.propTypes = {
    vat: PropTypes.number.isRequired,
    currency: PropTypes.string.isRequired,
    formatAmountOptions: PropTypes.object,
    expandVat: PropTypes.bool
};

export default VatDetails;
