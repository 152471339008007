import {RSAA} from 'redux-api-middleware';
import {createHeaders, createRsaaTypes} from '../actionHelpers';
import queryString from 'query-string';

export const ADMIN_SEARCH_STATISTICS_GET = 'ADMIN_SEARCH_STATISTICS_GET';
export const ADMIN_SEARCH_STATISTICS_GET_SUCCESS = 'ADMIN_SEARCH_STATISTICS_GET_SUCCESS';
export const ADMIN_SEARCH_STATISTICS_GET_FAILURE = 'ADMIN_SEARCH_STATISTICS_GET_FAILURE';
export const ADMIN_SEARCH_STATISTICS_SET_SEARCH_PARAMETERS = 'ADMIN_SEARCH_STATISTICS_SET_SEARCH_PARAMETERS';

export const getSearchStatistics = searchParameters => ({
    [RSAA]: {
        types: createRsaaTypes(ADMIN_SEARCH_STATISTICS_GET),
        endpoint: `/api/admin/searchStatistics?${queryString.stringify(searchParameters)}`,
        headers: createHeaders(false),
        method: 'GET'
    }
});

export const setSearchParameters = searchParameters => ({
    type: ADMIN_SEARCH_STATISTICS_SET_SEARCH_PARAMETERS,
    searchParameters
});
