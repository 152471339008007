// will be populated when resources have been loaded
const routes = {};

export const initializeRoutes = appContext => {
    Object.keys(appContext.routes).forEach(key => {
        routes[key] = appContext.routes[key];
    });
};

export default routes;
