import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from 'styles/util';
import useMediaQuery from '@material-ui/core/useMediaQuery';

import Box from '@material-ui/core/Box';

const StepIndicator = ({ currentStep, numberOfSteps, titles }) => {
    const useStyles = makeStyles(({ colors }) => ({
        container: {
            position: 'relative'
        },
        stepContainer: {
            position: 'absolute',
            width: '100%',
            display: 'flex',
            zIndex: 2
        },
        step: {
            flex: `0 0 ${100 / numberOfSteps}%`,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center'
        },
        round: {
            borderRadius: '50%',
            maxWidth: '32px',
            maxHeight: '32px',
            minWidth: '32px',
            minHeight: '32px',
            padding: 0,
            lineHeight: '32px',
            textAlign: 'center'
        },
        line: {
            flex: '1 0 auto',
            height: '4px',
            marginTop: '14px'
        },
        space: {
            flex: '1 0 auto',
        },
        title: {
            flex: '0 0 auto',
            textAlign: 'center',
            fontSize: '70%',
            marginTop: '8px'
        },
        visitedRound: {
            backgroundColor: colors.primaryColor,
            color: colors.white
        },
        futureRound: {
            backgroundColor: colors.mediumGrey,
            color: colors.darkGrey
        },
        visitedTitle: {
            color: colors.textColor
        },
        futureTitle: {
            color: colors.mediumGrey
        },
        fullLine: {
            position: 'absolute',
            top: '14px',
            height: '4px',
            left: 0,
            right: 0,
            backgroundColor: colors.mediumGrey,
            zIndex: 0
        },
        stepLine: {
            position: 'absolute',
            top: '14px',
            height: '4px',
            left: 0,
            width: `${100 * (currentStep + 0.5) / numberOfSteps}%`,
            backgroundColor: colors.primaryColor,
            zIndex: 1
        }
    }));
    const classes = useStyles();

    const steps = [];
    for(let i = 0; i < numberOfSteps; i++) {
        steps.push(i);
    }

    const getClassNameForStep = (step, isTitle) => step <= currentStep
        ? classes['visited' + (isTitle ? 'Title' : 'Round')]
        : classes['future' + (isTitle ? 'Title' : 'Round')];

    const showTitles = useMediaQuery(theme => theme.breakpoints.up('sm'));

    return (
        <Box className={classes.container}>
            <Box className={classes.fullLine} />
            <Box className={classes.stepLine} />
            <Box className={classes.stepContainer}>
                {
                    steps.map(step => (
                        <Box key={step} className={classes.step}>
                            <Box
                                className={classes.round + ' ' + getClassNameForStep(step, false)}
                            >
                                {step + 1}
                            </Box>
                            {
                                showTitles && titles &&
                                (
                                    <Box
                                        className={classes.title + ' ' + getClassNameForStep(step, true)}
                                    >
                                        {titles[step]}
                                    </Box>
                                )
                            }
                        </Box>
                    ))
                }
            </Box>
        </Box>
    );
};

StepIndicator.propTypes = {
    currentStep: PropTypes.number.isRequired,
    numberOfSteps: PropTypes.number.isRequired,
    titles: PropTypes.array
};

export default StepIndicator;
