const discountTypes = {
    amount: {},
    rate: {}
};

Object.keys(discountTypes).forEach(key => {
    discountTypes[key].key = key;
});

export default discountTypes;
