import { RSAA } from 'redux-api-middleware';
import { createRsaaTypes, createHeaders } from './actionHelpers';

export const MESSAGES_CREATE = 'MESSAGES_CREATE';
export const MESSAGES_CREATE_SUCCESS = 'MESSAGES_CREATE_SUCCESS';
export const MESSAGES_CREATE_FAILURE = 'MESSAGES_CREATE_FAILURE';

export const createMessage = createMessageRequest => {
    return {
        [RSAA]: {
            types: createRsaaTypes(MESSAGES_CREATE),
            endpoint: '/api/messages',
            headers: createHeaders(true),
            method: 'POST',
            body: JSON.stringify(createMessageRequest)
        }
    };
};
