const defaultStyles = {
    borderRadius: 0,
    inputBorderRadius: 8,
    buttonBorderRadius: 8,
    transitionEffect: '250ms cubic-bezier(0.4, 0, 0.2, 1)',
    cardBorderRadius: 8,
    boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1)',
    boxShadowHover: '0px 5px 15px 0px rgba(0, 0, 0, 0.1)',
    transformHover: 'translateY(-2px)',
};

export const storageStyles = {
    ...defaultStyles
};

export const officeStyles = {
    ...defaultStyles,
    borderRadius: 30,
    inputBorderRadius: 30,
    buttonBorderRadius: 30
};
