import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from 'styles/util';
import Typography from '@material-ui/core/Typography';

const useStyles = makeStyles(({ theme }) => ({
    readOnlyField: {
        margin: theme.spacing(0.75, 0, 1.5, 0),
        display: 'inline-block',
        width: '100%'
    }
}));

const ReadOnlyField = ({ label, children, className }) => {
    const classes = useStyles();
    return (
        <div className={classes.readOnlyField + (className ? ' ' + className : '')}>
            <Typography variant="caption" display="block" gutterBottom>
                {label}
            </Typography>
            <Typography variant="body1" component="div">
                {children}
            </Typography>
        </div>
    );
};

ReadOnlyField.propTypes = {
    label: PropTypes.string.isRequired,
    children: PropTypes.oneOfType([
        PropTypes.arrayOf(PropTypes.node),
        PropTypes.node
    ]),
    className: PropTypes.string
};

export default ReadOnlyField;
