import strings from 'localization/strings';
import routes from 'routes';

export const getOfficeMenuItems = (platform, appContext, hasTenantBookings, isAuthenticated) => {
    return [
        {
            route: routes.howItWorks,
            text: strings.howItWorksTitle1,
            variant: 'white'
        },
        {
            route: routes.questionsAndAnswers,
            text: strings.questionsAndAnswersShort,
            variant: 'white',
            visible: !isAuthenticated
        },
        {
            route: routes.logIn,
            text: strings.login,
            variant: 'white',
            visible: !isAuthenticated
        },
        {
            route: hasTenantBookings ? routes.account.tenantBookings : routes.account.settings,
            text: strings.myPages,
            variant: 'white',
            visible: isAuthenticated
        },
        {
            route: routes.logOut,
            text: strings.logout,
            variant: 'white',
            visible: isAuthenticated
        },
        {
            route: routes.rentOutStorage,
            text: strings.rentOut,
            variant: 'dark'
        }
    ].filter(o => (!o.platform || o.platform === platform) && o.visible !== false);
};
