import { useEffect } from 'react';
import queryString from 'query-string';

export const useScrollToTop = () => {
    useEffect(scrollToTop, []);
};

export const scrollToTop = () => {
    window.scrollTo(0, 0);
};

export const querystringParameterSet = parameterName => !!queryString.parse(window.location.search)[parameterName];

export const downloadUrl = (url, options = { fileName: undefined, newTab: true }) => {
    const a = document.createElement('a');
    a.href = url;
    if(options.fileName) {
        a.download = options.fileName;
    } else {
        const atoms = url.split('/');
        a.download = atoms[atoms.length - 1];
    }
    if(options.newTab) {
        a.target = '_blank';
    }
    a.click();
};

export const navigateToUrl = url => {
    const a = document.createElement('a');
    a.href = url;
    a.click();
};

export const removeQuerystringParameters = (history, parameterNames) => {
    const updatedQuerystringParameters = queryString.parse(history.location.search);
    parameterNames.forEach(name => {
        updatedQuerystringParameters[name] = undefined;
    });
    history.replace({
        search: queryString.stringify(updatedQuerystringParameters)
    });
};

export const addQuerystringParametersToUrl = (url, parameters, returnAbsoluteUrl) => {
    const urlObj = url.startsWith('http')
        ? new URL(url)
        : new URL(`${window.location.origin}${url}`);

    const updatedQuerystringParameters = queryString.parse(urlObj.search);
    Object.keys(parameters).forEach(parameterName => {
        updatedQuerystringParameters[parameterName] = parameters[parameterName];
    });

    const q = queryString.stringify(updatedQuerystringParameters);
    const result = (returnAbsoluteUrl ? urlObj.origin  : '') + urlObj.pathname + (q ? '?' + q : '');

    return result;
};
