import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useAppContext } from 'context/AppContext';
import { useDispatch } from 'react-redux';
import { querystringParameterSet } from 'helpers/BrowserHelper';
import { logErrorToApplicationInsights } from 'logic/paymentLogic';
import { createPaymentIntent, fetchCardForBooking } from 'actions/stripe';
import { handleResponse } from 'actions/actionHelpers';
import { useStripe, useElements } from '@stripe/react-stripe-js';

import { Elements } from '@stripe/react-stripe-js';
import FullScreenLoader from 'common/FullScreenLoader';

const StripeElementsWrapper = ({ createStripePaymentIntentRequest, setupBookingId, children }) => {
    const minimal = querystringParameterSet('minimal');
    if((!createStripePaymentIntentRequest && !setupBookingId) || minimal) {
        return children({});
    }

    const dispatch = useDispatch();
    const { appContext, stripePromise } = useAppContext();
    const [loading, setLoading] = useState(true);
    const [paymentContext, setPaymentContext] = useState({
        stripePromise
    });
    const [setupContext, setSetupContext] = useState({
        stripePromise
    });

    useEffect(() => {
        if(createStripePaymentIntentRequest) {
            dispatch(createPaymentIntent(createStripePaymentIntentRequest))
                .then(handleResponse(
                    response => {
                        setPaymentContext({
                            ...paymentContext,
                            clientSecret: response.payload.clientSecret,
                            savedCard: response.payload.savedCard
                        });
                        setLoading(false);
                    },
                    response => {
                        setLoading(false);
                        if(response.meta.response.status === 404) {
                            // no payment to be made
                            setPaymentContext({
                                ...paymentContext,
                                noPayment: true
                            });
                            return false; // show no dialog
                        }

                        logErrorToApplicationInsights(response);
                        setPaymentContext({
                            ...paymentContext,
                            errorResponse: response
                        });
                        return undefined; // show default dialog
                    }
                ));
        } else if(setupBookingId) {
            dispatch(fetchCardForBooking(setupBookingId))
                .then(handleResponse(
                    response => {
                        setSetupContext({
                            ...setupContext,
                            savedCard: response.payload
                        });
                        setLoading(false);
                    },
                    () => {
                        setLoading(false);
                        return false;
                    }
                ));
        }
    }, []);

    if(loading) {
        return <FullScreenLoader open/>;
    }

    const options = createStripePaymentIntentRequest
        ? {
            clientSecret: paymentContext.clientSecret
        }
        : {
            mode: 'setup',
            currency: appContext.currency.code.toLowerCase()
        };

    return (
        <Elements stripe={stripePromise} options={options}>
            <Inner paymentContext={paymentContext} setupContext={setupContext}>
                {children}
            </Inner>
        </Elements>
    );
};

StripeElementsWrapper.propTypes = {
    createStripePaymentIntentRequest: PropTypes.object,
    setupBookingId: PropTypes.number,
    children: PropTypes.func.isRequired
};

// we need an inner component to be able to use Stripe's hooks
const Inner = ({ paymentContext, setupContext, children }) => {
    const stripe = useStripe();
    const elements = useElements();
    // keeps information about whether the payment element further down in the children structure is completed
    const [paymentElementComplete, setPaymentElementComplete] = useState(false);

    useEffect(() => {
        if(elements) {
            // kind of ugly: add custom function on Stripe's elements object
            elements.onElementChange = event => {
                setPaymentElementComplete(event.complete);
            };
        }
    }, [elements]);

    const args = {
        stripePaymentContext: {
            ...paymentContext,
            stripe,
            elements,
            paymentElementComplete
        },
        stripeSetupContext: {
            ...setupContext,
            stripe,
            elements,
            paymentElementComplete
        }
    };
    return children(args);
};


Inner.propTypes = {
    paymentContext: PropTypes.object,
    setupContext: PropTypes.object,
    children: PropTypes.func.isRequired
};

export default StripeElementsWrapper;
