import React from 'react';
import ReadOnlyField from 'admin/presentational/ReadOnlyField';
import PropTypes from 'prop-types';

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import ShortDateTime from 'common/ShortDateTime';
import Amount from 'common/Amount';

const PaymentList = ({ payments }) => {
    if (payments.length === 0) {
        return null;
    }

    return (
        <ReadOnlyField label="Payments">
            <Table size="small">
                <TableHead>
                    <TableRow>
                        <TableCell>Payment ID</TableCell>
                        <TableCell>Amount</TableCell>
                        <TableCell>Fee</TableCell>
                        <TableCell>Status</TableCell>
                        <TableCell>Time</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {
                        payments.map(payment => (
                            <TableRow key={payment.id}>
                                <TableCell>{payment.id}</TableCell>
                                <TableCell><Amount value={payment.amount} currency={payment.currency}/></TableCell>
                                <TableCell><Amount value={payment.fee} currency={payment.currency}/></TableCell>
                                <TableCell>{payment.status}</TableCell>
                                <TableCell><ShortDateTime value={payment.time}/></TableCell>
                            </TableRow>
                        ))
                    }
                </TableBody>
            </Table>
        </ReadOnlyField>
    );
};

PaymentList.propTypes = {
    payments: PropTypes.array.isRequired
};

export default PaymentList;
