import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { handleResponse } from 'actions/actionHelpers';
import { fetchParakeyKeysForDomainId } from 'actions/parakey';
import { required } from 'form/validation';
import strings from 'localization/strings';
import lockTypes from 'enums/lockTypes';
import { makeStyles } from 'styles/util';
import { createFormDataForNewLockConfiguration } from 'logic/editStorageSiteLogic';

import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import { TextField, showErrorOnBlur } from 'mui-rff';
import MenuItem from '@material-ui/core/MenuItem';
import { FieldArray } from 'react-final-form-arrays';
import AddIcon from '@material-ui/icons/Add';
import IconButton from '@material-ui/core/IconButton';
import DeleteOutlineOutlinedIcon from '@material-ui/icons/DeleteOutlineOutlined';
import Loader from 'common/Loader';

const useStyles = makeStyles(({ theme }) => ({
    lockConfigurationRow: {
        display: 'flex',
        alignItems: 'center'
    },
    lockType: {
        flex: '1 1 33%',
        marginRight: theme.spacing(1)
    },
    data: {
        flex: '1 1 33%',
        marginRight: theme.spacing(1)
    },
    name: {
        flex: '1 1 33%',
        marginRight: theme.spacing(1)
    },
    removeButton: {
        flex: '0 0 auto'
    }
}));

const LockConfigurationEditor = ({ name: parentName, parakeyDomainId }) => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const [isLoading, setIsLoading] = useState(true);
    const [parakeyKeys, setParakeyKeys] = useState(undefined);

    useEffect(() => {
        if(parakeyDomainId) {
            setIsLoading(true);
            dispatch(fetchParakeyKeysForDomainId(parakeyDomainId))
                .then(handleResponse(
                    response => {
                        setParakeyKeys(response.payload);
                        setIsLoading(false);
                    },
                    response => {
                        setIsLoading(false);
                        setParakeyKeys([]);
                        return response.meta.response.status === 403
                            ? false // no dialog
                            : undefined; // default dialog
                    }
                ));
        } else {
            setIsLoading(false);
        }
    }, [parakeyDomainId]);

    const getDataLabel = value => lockTypes[value.lockType]?.dataLabel;

    const handleAdd = fields => {
        const newLockConfiguration = createFormDataForNewLockConfiguration();
        fields.push(newLockConfiguration);
    };

    const handleRemove = (fields, index) => {
        fields.remove(index);
    };

    const renderDataField = (fields, name, index) => {
        if(fields.value[index].lockType === lockTypes.parakey.key) {
            return (
                <TextField
                    select
                    name={`${name}.data`}
                    label={getDataLabel(fields.value[index])}
                    size="small"
                    variant="outlined"
                    required
                    fieldProps={{ validate: required }}
                    showError={showErrorOnBlur}
                >
                    {
                        (parakeyKeys ?? []).map(o =>
                            <MenuItem key={o.id} value={o.id}>{o.name}</MenuItem>
                        )
                    }
                </TextField>
            );
        }
        return (
            <TextField
                name={`${name}.data`}
                label={getDataLabel(fields.value[index])}
                size="small"
                variant="outlined"
                required
                fieldProps={{ validate: required }}
                showError={showErrorOnBlur}
            />
        );
    };

    return (
        <FieldArray name={parentName}>
            {({ fields }) =>
                <>
                    {
                        isLoading && <Loader />
                    }
                    {
                        !isLoading &&
                        (
                            <>
                                {
                                    fields.length === 0 &&
                                    (
                                        <Typography variant="body1" display="block" gutterBottom>
                                            {strings.noLockConfigurations}
                                        </Typography>
                                    )
                                }
                                {
                                    fields.map((name, index) => (
                                        <Box key={index} className={classes.lockConfigurationRow}>
                                            <Box className={classes.lockType}>
                                                <TextField
                                                    select
                                                    name={`${name}.lockType`}
                                                    label={strings.lockType}
                                                    size="small"
                                                    variant="outlined"
                                                >
                                                    {
                                                        Object.values(lockTypes).map(o =>
                                                            <MenuItem key={o.key} value={o.key}>{o.title}</MenuItem>
                                                        )
                                                    }
                                                </TextField>
                                            </Box>
                                            <Box className={classes.data}>
                                                {renderDataField(fields, name, index)}
                                            </Box>
                                            {
                                                fields.length > 1 &&
                                                (
                                                    <Box className={classes.name}>
                                                        <TextField
                                                            name={`${name}.name`}
                                                            label={strings.lockName}
                                                            size="small"
                                                            variant="outlined"
                                                            required
                                                            fieldProps={{ validate: required }}
                                                            showError={showErrorOnBlur}
                                                        />
                                                    </Box>
                                                )
                                            }
                                            <IconButton
                                                className={classes.removeButton}
                                                onClick={() => handleRemove(fields, index)}
                                            >
                                                <DeleteOutlineOutlinedIcon/>
                                            </IconButton>
                                        </Box>
                                    ))
                                }
                                <IconButton
                                    color="primary"
                                    onClick={() => handleAdd(fields)}
                                >
                                    <AddIcon/>
                                </IconButton>
                            </>
                        )
                    }
                </>
            }
        </FieldArray>
    );
};

LockConfigurationEditor.propTypes = {
    name: PropTypes.string.isRequired,
    parakeyDomainId: PropTypes.string
};

export default LockConfigurationEditor;
