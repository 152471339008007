import { RSAA } from 'redux-api-middleware';
import { createRsaaTypes, createHeaders } from './actionHelpers';
import queryString from 'query-string';

export const STORAGEGROUPS_FETCH_BOOKING_CALENDAR = 'STORAGEGROUPS_FETCH_BOOKING_CALENDAR';
export const STORAGEGROUPS_FETCH_BOOKINGS = 'STORAGEGROUPS_FETCH_BOOKINGS';

export const fetchStorageGroupBookingCalendar = (storageGroupId, bookingIdToExclude, includeStorageBookingCalendars) => {
    const qs = queryString.stringify({
        bookingIdToExclude,
        includeStorageBookingCalendars
    });
    return {
        [RSAA]: {
            types: createRsaaTypes(STORAGEGROUPS_FETCH_BOOKING_CALENDAR),
            endpoint: `/api/storageGroups/${storageGroupId}/bookingCalendar${(qs ? '?' + qs : '')}`,
            headers: createHeaders(false),
            method: 'GET'
        }
    };
};

export const fetchStorageGroupBookings = (storageGroupId, from, to) => {
    const qs = queryString.stringify({
        from,
        to
    });
    return {
        [RSAA]: {
            types: createRsaaTypes(STORAGEGROUPS_FETCH_BOOKINGS),
            endpoint: `/api/storageGroups/${storageGroupId}/bookings?${qs}`,
            headers: createHeaders(false),
            method: 'GET'
        }
    };
};
