import { RSAA } from 'redux-api-middleware';
import { createRsaaTypes, createHeaders } from '../actionHelpers';

export const ADMIN_COMPANIES_LIST = 'ADMIN_COMPANIES_LIST';

export const getCompanies = () => ({
    [RSAA]: {
        types: createRsaaTypes(ADMIN_COMPANIES_LIST),
        endpoint: '/api/admin/companies',
        headers: createHeaders(false),
        method: 'GET'
    }
});
