import React, { useState, useEffect } from 'react';
import { makeStyles } from 'styles/util';
import { useAppContext } from 'context/AppContext';
import { useParams } from 'react-router';
import { useDispatch } from 'react-redux';
import { fetchStorageSite, fetchStorageSiteBookings } from 'actions/storageSites';
import { handleResponse } from 'actions/actionHelpers';
import { parseIsoDate, formatIsoDate } from 'helpers/DateHelper';
import { startOfMonth, add } from 'date-fns';
import strings from 'localization/strings';
import { getStorageTitle, getStorageSorter } from 'helpers/StorageSiteHelper';
import storageGroupStatuses from 'enums/storageGroupStatuses';
import storageStatuses from 'enums/storageStatuses';
import queryString from 'query-string';
import routes from 'routes';
import bookingStatuses from 'enums/bookingStatuses';

import PageTitle from 'common/PageTitle';
import ShadowBox from 'common/ShadowBox';
import Loader from 'common/Loader';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import CapacityUtilizationTimelineView from './CapacityUtilizationTimelineView';
import CapacityUtilizationDialog from './CapacityUtilizationDialog';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import Box from '@material-ui/core/Box';
import ButtonContainer from 'common/ButtonContainer';
import { Link } from 'react-router-dom';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import SortIcon from '@material-ui/icons/Sort';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';

const useStyles = makeStyles(() => ({
    orderByContainer: {
        display: 'inline-block'
    }
}));

const BookingOverview = () => {
    const classes = useStyles();
    const params = useParams();
    const dispatch = useDispatch();

    const { appContext } = useAppContext();

    const orderByItems = [
        { value: 'titleOrNumber', title: strings.titleOrNumber },
        { value: 'area', title: strings.area }
    ];

    const [storageSite, setStorageSite] = useState(undefined);
    const [storageSiteBookings, setStorageSiteBookings] = useState(undefined);
    const [startDate, setStartDate] = useState(startOfMonth(new Date()));
    const [orderBy, setOrderBy] = useState(orderByItems[0].value);
    const [clickedStorage, setClickedStorage] = useState(undefined);
    const [clickedStorageGroup, setClickedStorageGroup] = useState(undefined);
    const [clickedStorageStartDate, setClickedStorageStartDate] = useState(undefined);
    const [clickedStorageEndDate, setClickedStorageEndDate] = useState(undefined);
    const [capacityUtilizationDialogOpen, setCapacityUtilizationDialogOpen] = useState(false);
    const [orderByMenuAnchorElement, setOrderByMenuAnchorElement] = useState(undefined);

    const storageSiteId = parseInt(params.storageSiteId, 10);

    useEffect(() => {
        setStorageSite(undefined);
        setStorageSiteBookings(undefined);
        dispatch(fetchStorageSite(storageSiteId, formatIsoDate(startDate), formatIsoDate(getEndDate()), true /* includeStorageGroupBookingCalendars */))
            .then(handleResponse(
                response => setStorageSite(response.payload)
            ));
        dispatch(fetchStorageSiteBookings(storageSiteId, formatIsoDate(startDate), formatIsoDate(getEndDate())))
            .then(handleResponse(
                response => setStorageSiteBookings(response.payload)
            ));
    }, [startDate]);

    const getStorageGroup = id => {
        return storageSite.storageGroups.filter(o => o.id === id)[0];
    };

    const getEndDate = () => add(add(startDate, { months: 6 }), { days: -1 });

    const handleCapacityUtilizationTimelineViewItemClick = args => {
        setClickedStorage(args.item.storage);
        setClickedStorageGroup(args.item.storageGroup);
        setClickedStorageStartDate(parseIsoDate(args.capacityUtilization.interval.startDate));
        setClickedStorageEndDate(parseIsoDate(args.capacityUtilization.interval.endDate));
        setCapacityUtilizationDialogOpen(true);
    };

    const handleCapacityUtilizationDialogClose = () => {
        setCapacityUtilizationDialogOpen(false);
    };

    const handlePreviousButtonClick = () => {
        const d = add(startDate, { months: -6 });
        setStartDate(d);
    };

    const handleNextButtonClick = () => {
        const d = add(startDate, { months: 6 });
        setStartDate(d);
    };

    const handleOrderByChange = item => {
        setOrderBy(item.value);
        handleCloseOrderByMenu();
    };

    const handleOpenOrderByMenu = event => {
        setOrderByMenuAnchorElement(event.currentTarget);
    };

    const handleCloseOrderByMenu = () => {
        setOrderByMenuAnchorElement(undefined);
    };

    const items = [];
    const allowedBookingStatuses = [ bookingStatuses.confirmed.key, bookingStatuses.purchased.key ];
    if(storageSite && storageSiteBookings) {
        storageSite.bookingCalendar.storageGroupBookingCalendars.forEach(sgbc => {
            const storageGroup = getStorageGroup(sgbc.storageGroupId);
            if(storageGroup.status !== storageGroupStatuses.deleted.key) {
                sgbc.storageBookingCalendars.forEach(sbc => {
                    const storage = storageGroup.storages.filter(o => o.id === sbc.storageId)[0];
                    if(storage.status !== storageStatuses.deleted.key) {
                        items.push({
                            storage,
                            storageGroup,
                            title: getStorageTitle(storage, storageGroup, appContext),
                            capacityUtilizations: sbc.capacityUtilizations,
                            bookings: storageSiteBookings.filter(o => o.storage?.id === storage.id && allowedBookingStatuses.find(bs => bs === o.bookingStatus))
                        });
                    }
                });
            }
        });
    }

    getStorageSorter(appContext)(items, orderBy);

    const title = storageSite
        ? `${strings.bookingOverview}: ${storageSite.title}`
        : strings.bookingOverview;

    const actionPanel = (
        <>
            <Box className={classes.orderByContainer}>
                <Button aria-controls="order-by-menu" aria-haspopup="true" onClick={handleOpenOrderByMenu}>
                    <SortIcon/>
                    {orderByItems.find(item => item.value === orderBy).title}
                    <KeyboardArrowDownIcon/>
                </Button>
                <Menu
                    id="order-by-menu"
                    anchorEl={orderByMenuAnchorElement}
                    anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
                    transformOrigin={{ vertical: 'top', horizontal: 'right' }}
                    keepMounted
                    open={Boolean(orderByMenuAnchorElement)}
                    onClose={handleCloseOrderByMenu}
                    getContentAnchorEl={undefined}
                >
                    {
                        orderByItems.map(item => <MenuItem key={item.value} onClick={() => handleOrderByChange(item)}>{item.title}</MenuItem>)
                    }
                </Menu>
            </Box>

            <IconButton
                disabled={!storageSite}
                onClick={handlePreviousButtonClick}
            >
                <ChevronLeftIcon/>
            </IconButton>
            <IconButton
                disabled={!storageSite}
                onClick={handleNextButtonClick}
            >
                <ChevronRightIcon/>
            </IconButton>
        </>
    );

    return (
        <>
            <PageTitle>{title}</PageTitle>
            <ShadowBox title={title} actionPanel={actionPanel}>
                {
                    !storageSite && <Loader />
                }
                {
                    storageSite &&
                    (
                        <>
                            <CapacityUtilizationTimelineView
                                items={items}
                                startDate={startDate}
                                endDate={getEndDate()}
                                onItemClick={handleCapacityUtilizationTimelineViewItemClick}
                            />

                            {
                                clickedStorage && clickedStorageGroup && clickedStorageStartDate && clickedStorageEndDate &&
                                (
                                    <CapacityUtilizationDialog
                                        storage={clickedStorage}
                                        storageGroup={clickedStorageGroup}
                                        startDate={clickedStorageStartDate}
                                        endDate={clickedStorageEndDate}
                                        open={capacityUtilizationDialogOpen}
                                        onClose={handleCapacityUtilizationDialogClose}
                                    />
                                )
                            }
                            <ButtonContainer>
                                <Button
                                    variant="contained"
                                    color="primary"
                                    component={Link}
                                    to={routes.account.ownerBookings + '?' + queryString.stringify({ storageSiteId: storageSite.id })}
                                >
                                    {strings.showBookingList}
                                </Button>
                                <Button
                                    variant="contained"
                                    color="primary"
                                    component={Link}
                                    to={routes.account.storageSites}
                                >
                                    {strings.showStorageSiteList}
                                </Button>
                                <Button
                                    variant="contained"
                                    color="primary"
                                    component={Link}
                                    to={routes.account.storageSiteCapacityUtilizationChart.replace(':storageSiteId', storageSite.id)}
                                >
                                    {strings.showCapacityUtilizationChart}
                                </Button>
                            </ButtonContainer>
                        </>
                    )
                }
            </ShadowBox>
        </>
    );
};

export default BookingOverview;
