import React from 'react';
import PropTypes from 'prop-types';
import { useAppContext } from 'context/AppContext';
import { makeStyles } from 'styles/util';
import strings from 'localization/strings';
import { required, mustBeGreaterThan } from 'form/validation';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { createEndAdornment } from 'helpers/FormHelper';

import Box from '@material-ui/core/Box';
import { TextField, Checkboxes, showErrorOnBlur } from 'mui-rff';
import IconButton from '@material-ui/core/IconButton';
import DeleteOutlineOutlinedIcon from '@material-ui/icons/DeleteOutlineOutlined';

const PostInvoiceItemEditor = ({ fieldNamePrefix, onRemove, submitting, useVat = false, useCustomCommissionRate = false }) => {
    const isMobile = !useMediaQuery(theme => theme.breakpoints.up('md'));
    const useStyles = makeStyles(({ theme, colors }) => ({
        container: {
            display: 'flex',
            flexWrap: isMobile
                ? 'wrap'
                : 'nowrap',
            '& > *': {
                margin: theme.spacing(0, 1)
            },
            marginTop: theme.spacing(2),
            paddingBottom: isMobile
                ? theme.spacing(2)
                : undefined,
            marginBottom: isMobile
                ? theme.spacing(2)
                : undefined,
            borderBottom: isMobile
                ? `1.5px solid ${colors.mediumGrey}`
                : undefined,
            '&:last-child': {
                paddingBottom: 0,
                marginBottom: 0,
                borderBottom: 'none'
            }
        },
        description: {
            flex: isMobile
                ? '100% 0 0'
                : '50% 1 1'
        },
        amount: {
            flex: isMobile
                ? 'auto 1 1'
                : '25% 0 1'
        },
        eligibleForVat: {
            flex: isMobile
                ? 'auto 1 1'
                : 'auto 0 0'

        },
        commissionRate: {
            flex: isMobile
                ? 'auto 0 0'
                : '25% 0 1'
        },
        removeButton: {
            flex: 'auto 0 0'
        }
    }));
    const classes = useStyles();
    const { appContext } = useAppContext();

    const getName = suffix => `${fieldNamePrefix}.${suffix}`;

    return (
        <Box className={classes.container}>
            <TextField
                className={classes.description}
                name={getName('description')}
                label={strings.postInvoiceLineLabel}
                variant="outlined"
                showError={showErrorOnBlur}
                disabled={submitting}
                required
                fieldProps={{ validate: required }}
            />
            <TextField
                className={classes.amount}
                name={getName('amount')}
                label={strings.postInvoiceLineAmount}
                variant="outlined"
                showError={showErrorOnBlur}
                disabled={submitting}
                required
                fieldProps={{ validate: mustBeGreaterThan(0) }}
                InputProps={createEndAdornment(appContext.currency.label)}
            />
            {
                useVat &&
                (
                    <Box className={classes.eligibleForVat}>
                        <Checkboxes
                            name={getName('eligibleForVat')}
                            data={
                                { label: strings.eligibleForVat, value: true }
                            }
                            disabled={submitting}
                        />
                    </Box>
                )
            }
            {
                useCustomCommissionRate &&
                (
                    <TextField
                        className={classes.commissionRate}
                        name={getName('commissionRate')}
                        label={strings.postInvoiceLineCommissionRate}
                        variant="outlined"
                        showError={showErrorOnBlur}
                        disabled={submitting}
                        required
                        fieldProps={{ validate: mustBeGreaterThan(0) }}
                    />
                )
            }
            <IconButton
                className={classes.removeButton}
                onClick={onRemove}
            >
                <DeleteOutlineOutlinedIcon/>
            </IconButton>
        </Box>
    );
};

PostInvoiceItemEditor.propTypes = {
    fieldNamePrefix: PropTypes.string.isRequired,
    onRemove: PropTypes.func.isRequired,
    submitting: PropTypes.bool.isRequired,
    useVat: PropTypes.bool,
    useCustomCommissionRate: PropTypes.bool
};

export default PostInvoiceItemEditor;
