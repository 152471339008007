import { RSAA } from 'redux-api-middleware';
import { createRsaaTypes, createHeaders } from './actionHelpers';
import queryString from 'query-string';

export const CAPACITY_UTILIZATIONS_GET_STORAGE_SITES = 'CAPACITY_UTILIZATIONS_GET_STORAGE_SITES';

export const getStorageSitesWithCapacityUtilizations = request => {
    const modifiedRequest = {
        ...request,
        ownerActorIds: (request.ownerActorIds ?? []).length === 0
            ? undefined
            : request.ownerActorIds.join(',')
    };
    return {
        [RSAA]: {
            types: createRsaaTypes(CAPACITY_UTILIZATIONS_GET_STORAGE_SITES),
            endpoint: `/api/capacityUtilizations?${queryString.stringify(modifiedRequest)}`,
            headers: createHeaders(false),
            method: 'GET'
        }
    };
};
