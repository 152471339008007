import React from 'react';
import PropTypes from 'prop-types';
import strings from 'localization/strings';

import { Helmet } from 'react-helmet-async';

const PageTitle = ({ children, prependCompanyName = true }) => {
    const title = prependCompanyName
        ? `${strings.companyName} - ${children}`
        : children;
    return (
        <Helmet defer={false}>
            <title>{title}</title>
            <meta property="og:title" content={title}/>
        </Helmet>
    );
};

PageTitle.propTypes = {
    children: PropTypes.string.isRequired,
    prependCompanyName: PropTypes.bool
};

export default PageTitle;
