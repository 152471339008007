import { RSAA } from 'redux-api-middleware';
import { createRsaaTypes, createHeaders } from '../actionHelpers';
import queryString from 'query-string';

export const ADMIN_STORAGE_SITES_SEARCH = 'ADMIN_STORAGE_SITES_SEARCH';
export const ADMIN_STORAGE_SITES_SEARCH_SUCCESS = 'ADMIN_STORAGE_SITES_SEARCH_SUCCESS';
export const ADMIN_STORAGE_SITES_SEARCH_FAILURE = 'ADMIN_STORAGE_SITES_SEARCH_FAILURE';
export const ADMIN_STORAGE_SITES_GET = 'ADMIN_STORAGE_SITES_GET';
export const ADMIN_STORAGE_SITES_SET_SEARCH_PARAMETERS = 'ADMIN_STORAGE_SITES_SET_SEARCH_PARAMETERS';
export const ADMIN_STORAGE_SITES_UPDATE = 'ADMIN_STORAGE_SITES_UPDATE';

export const searchStorageSites = searchParameters => ({
    [RSAA]: {
        types: createRsaaTypes(ADMIN_STORAGE_SITES_SEARCH),
        endpoint: `/api/admin/storageSites?${queryString.stringify(searchParameters)}`,
        headers: createHeaders(false),
        method: 'GET'
    }
});

export const getStorageSite = storageSiteId => ({
    [RSAA]: {
        types: createRsaaTypes(ADMIN_STORAGE_SITES_GET),
        endpoint: `/api/admin/storageSites/${storageSiteId}`,
        headers: createHeaders(false),
        method: 'GET'
    }
});

export const updateStorageSite = (storageSiteId, request) => ({
    [RSAA]: {
        types: createRsaaTypes(ADMIN_STORAGE_SITES_UPDATE),
        endpoint: `/api/admin/storageSites/${storageSiteId}`,
        headers: createHeaders(true),
        method: 'PUT',
        body: JSON.stringify(request)
    }
});

export const setSearchParameters = searchParameters => ({
    type: ADMIN_STORAGE_SITES_SET_SEARCH_PARAMETERS,
    searchParameters
});
