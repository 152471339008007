import React, {useEffect, useState} from 'react';
import {useDispatch} from 'react-redux';

import AdminContentWrapper from '../../presentational/AdminContentWrapper';
import AdminPage from '../../presentational/AdminPage';
import routes from 'routes';
import AdminTable from 'components/adminTable/AdminTable';
import Box from '@material-ui/core/Box';
import Loader from 'common/Loader';
import {getMessageTemplates} from '../../../actions/admin/messageTemplates';
import {handleResponse} from '../../../actions/actionHelpers';
import { Link } from 'react-router-dom';

const MessageTemplates = () => {
    const dispatch = useDispatch();
    const [messageTemplates, setMessageTemplates] = useState(undefined);
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
       loadMessageTemplates();
    }, []);

    const loadMessageTemplates = () => {
      setIsLoading(true);
      dispatch(getMessageTemplates())
          .then(handleResponse(
              response => {
                  setMessageTemplates(response.payload);
                  setIsLoading(false);
              },
              () => setIsLoading(false)
          ));
    };

    const columns = [
        {
            title: 'Name',
            id: 'name',
            render: messageTemplate =>
                <Link to= {
                    routes.admin
                        .messageTemplate
                        .replace(':type', messageTemplate.type)
                        .replace(':messageTemplateId', messageTemplate.name)
                } > {messageTemplate.name}
                </Link>,
            align: 'left'
        },
        {
            title: 'Type',
            id: 'type',
            render: messageTemplate => messageTemplate.type
        }
    ];

    return (
        <AdminPage title="Message templates">
            <Box>
                {
                    isLoading && <Loader />
                }
                {
                    !isLoading && messageTemplates.items && (
                        <AdminContentWrapper type="listing">
                            <AdminTable
                                items={messageTemplates.items}
                                count={messageTemplates.count}
                                columns={columns}
                            />
                        </AdminContentWrapper>
                    )
                }
            </Box>
        </AdminPage>
    );
};

export default MessageTemplates;
