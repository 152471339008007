import React from 'react';
import PropTypes from 'prop-types';
import strings from 'localization/strings';
import bookingStatuses from 'enums/bookingStatuses';

import Button from '@material-ui/core/Button';
import BookingDetailsSectionCaption from './BookingDetailsSectionCaption';

const Agreement = ({ booking }) => {
    const title = booking.bookingStatus === bookingStatuses.purchased.key
        ? strings.downloadAgreement
        : strings.downloadDraftAgreement;

    return (
        <>
            <BookingDetailsSectionCaption>
                {strings.agreement}
            </BookingDetailsSectionCaption>
            <Button
                size="small"
                color="primary"
                variant="outlined"
                target="_blank"
                component="a"
                href={booking.agreementUrl}
            >
                {title} ({strings.pdf})
            </Button>
        </>
    );
};

Agreement.propTypes = {
    booking: PropTypes.object.isRequired
};

export default Agreement;
