import React from 'react';
import strings from 'localization/strings';
import { makeStyles } from 'styles/util';
import { useAppContext } from 'context/AppContext';
import iconMail from 'assets/icons/ic_mail.svg';
import iconPhone from 'assets/icons/ic_phone.svg';

import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';

const useStyles = makeStyles(({ colors, theme, styles }) => ({
    contactUs: {
        color: colors.white,
        backgroundColor: colors.primaryColor,
        padding: theme.spacing(4),
        '& a': {
            color: colors.white,
            display: 'inline-block',
            marginRight: theme.spacing(2)
        },
        borderRadius: `${styles.borderRadius}px`
    },
    caption: {
        marginBottom: theme.spacing(2)
    },
    phoneOpeningHours: {
        marginTop: theme.spacing(2)
    },
    imageSpan: {
        marginRight: '14px',
        verticalAlign: 'middle',
        '& img': {
            verticalAlign: 'middle',
            marginBottom: '4px'
        }
    }
}));

const ContactUs = () => {
    const classes = useStyles();
    const { appContext } = useAppContext();
    return (
        <Box className={classes.contactUs}>
            <Typography variant="h3" className={classes.caption}>{strings.contactStorage}</Typography>
            <a href={`mailto:${appContext.company.email}`}><Typography variant="body1"><span className={classes.imageSpan}><img src={iconMail}/></span>{appContext.company.email}</Typography></a>
            <a href={`tel:${appContext.company.telephone}`}><Typography variant="body1"><span className={classes.imageSpan}><img src={iconPhone}/></span>{appContext.company.telephone}</Typography></a>
            <Typography variant="body1" className={classes.phoneOpeningHours}>{strings.phoneOpeningHours}</Typography>
        </Box>
    );
};

export default ContactUs;
