import React from 'react';
import PropTypes from 'prop-types';
import strings from 'localization/strings';
import { required, email, isPasswordOptional, isSamePassword } from 'form/validation';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import userAuthenticationTypes from 'enums/userAuthenticationTypes';

import MarginBox from 'common/MarginBox';
import Button from '@material-ui/core/Button';
import Form from 'form/Form';
import { TextField, Checkboxes, showErrorOnBlur } from 'mui-rff';
import ButtonContainer from 'common/ButtonContainer';
import TooltipIcon from 'common/TooltipIcon';

const UserEditor = ({ initialValues, authenticationType, isCombinedUserActorEditor = false, isSaving, onSubmit }) => {
    const isMobile = !useMediaQuery(theme => theme.breakpoints.up('md'));

    const nameFieldsDisabled = authenticationType === userAuthenticationTypes.eid.key;
    const nameFieldHelperText = nameFieldsDisabled
        ? strings.nameNotEditableDueToSwedishBankId
        : undefined;

    const showPasswordFields = authenticationType === userAuthenticationTypes.usernameAndPassword.key;

    const showMobileTelephoneFields = isCombinedUserActorEditor;

    let emailHelperText;

    if(!isCombinedUserActorEditor) {
        emailHelperText = authenticationType === userAuthenticationTypes.usernameAndPassword.key
            ? strings.userEmailAsUsernameHelperText
            : strings.userEmailHelperText;
    }

    return (
        <Form
            initialValues={initialValues}
            onSubmit={onSubmit}
        >
            {({ values, handleSubmit, submitting, invalid }) => (
                <form onSubmit={handleSubmit}>
                    <TextField
                        name="firstName"
                        label={strings.firstName}
                        variant="outlined"
                        showError={showErrorOnBlur}
                        required
                        disabled={nameFieldsDisabled}
                        fieldProps={{ validate: required }}
                        helperText={nameFieldHelperText}
                    />

                    <TextField
                        name="lastName"
                        label={strings.lastName}
                        variant="outlined"
                        showError={showErrorOnBlur}
                        required
                        disabled={nameFieldsDisabled}
                        fieldProps={{ validate: required }}
                        helperText={nameFieldHelperText}
                    />

                    <TextField
                        name="email"
                        label={strings.email}
                        variant="outlined"
                        showError={showErrorOnBlur}
                        required
                        fieldProps={{ validate: email }}
                        helperText={emailHelperText}
                    />

                    {
                        showMobileTelephoneFields &&
                        (
                            <MarginBox bottom={2}>
                                <TextField
                                    name="mobileTelephone"
                                    label={strings.phoneNumber}
                                    variant="outlined"
                                    showError={showErrorOnBlur}
                                    required
                                    fieldProps={{ validate: required }}
                                />

                                <Checkboxes
                                    name="sendSms"
                                    data={{
                                        label: <>{strings.smsCheckbox}<TooltipIcon text={strings.smsCheckboxTooltip}/></>,
                                        value: true
                                    }}
                                />
                            </MarginBox>
                        )
                    }

                    {
                        showPasswordFields &&
                        (
                            <>
                                <TextField
                                    name="password"
                                    type="password"
                                    label={strings.newPassword}
                                    variant="outlined"
                                    showError={showErrorOnBlur}
                                    fieldProps={{ validate: isPasswordOptional }}
                                />
                                {
                                    values.password &&
                                    (
                                        <TextField
                                            name="confirmPassword"
                                            type="password"
                                            label={strings.confirmPassword}
                                            variant="outlined"
                                            showError={showErrorOnBlur}
                                            fieldProps={{ validate: isSamePassword(values.password) }}
                                        />
                                    )
                                }
                            </>
                        )
                    }

                    <ButtonContainer>
                        <Button
                            type="submit"
                            color="primary"
                            variant="contained"
                            disabled={submitting || invalid || isSaving}
                            fullWidth={isMobile}
                        >
                            {strings.save}
                        </Button>
                    </ButtonContainer>
                </form>
            )}
        </Form>
    );
};

UserEditor.propTypes = {
    initialValues: PropTypes.object.isRequired,
    authenticationType: PropTypes.string.isRequired,
    isCombinedUserActorEditor: PropTypes.bool,
    isSaving: PropTypes.bool.isRequired,
    onSubmit: PropTypes.func.isRequired
};

export default UserEditor;
