import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';
import strings from 'localization/strings';
import queryString from 'query-string';
import { createMessage } from 'actions/messages';
import { handleResponse } from 'actions/actionHelpers';
import { listConversations } from 'actions/account/conversationsSection';
import useMediaQuery from '@material-ui/core/useMediaQuery';

import Button from '@material-ui/core/Button';
import MessageDialog from 'components/MessageDialog';

const ContactOwner = ({ storageSite, userId, actorId, setIsSaving, onLogIn }) => {
    const isMobile = !useMediaQuery(theme => theme.breakpoints.up('md'));
    const location = useLocation();
    const dispatch = useDispatch();

    const querystringParameters = queryString.parse(location.search);
    const [messageDialogOpen, setMessageDialogOpen] = useState(querystringParameters.messageDialogOpen || false);

    const handleMessageDialogOpen = () => {
        if(!actorId) {
            onLogIn({ messageDialogOpen: true }, strings.loginRequiredForContactOwnerBody);
        } else {
            setMessageDialogOpen(true);
        }
    };

    const handleMessageDialogOk = messageText => {
        const successContent = { dialog: { title: strings.postMessageSucceededTitle, body: strings.postMessageSucceededTenantBody } };
        const failureContent = { dialog: { title: strings.postMessageFailedTitle, body: strings.postMessageFailedBody } };

        const createMessageRequest = {
            writerUserId: userId,
            writerActorId: actorId,
            text: messageText,
            storageSiteId: storageSite.id,
            recipientActorId: storageSite.ownerActor.id
        };

        setMessageDialogOpen(false);
        setIsSaving(true);
        dispatch(createMessage(createMessageRequest))
            .then(handleResponse(
                () => {
                    setIsSaving(false);
                    dispatch(listConversations(actorId));
                    return successContent;
                },
                () => {
                    setIsSaving(false);
                    return failureContent;
                }
            ));
    };

    const handleMessageDialogClose = () => {
        setMessageDialogOpen(false);
    };

    return (
        <>
           <Button
                type="button"
                color="primary"
                variant="contained"
                fullWidth={isMobile}
                onClick={handleMessageDialogOpen}
            >
                {strings.contactTheOwner}
            </Button>
            <MessageDialog
                title={strings.contactTheOwner}
                content={strings.askAQuestionToOwnerNoBooking}
                open={messageDialogOpen}
                onOk={handleMessageDialogOk}
                onClose={handleMessageDialogClose}
            />
        </>
    );
};

ContactOwner.propTypes = {
    storageSite: PropTypes.object.isRequired,
    userId: PropTypes.number,
    actorId: PropTypes.number,
    setIsSaving: PropTypes.func,
    onLogIn: PropTypes.func.isRequired,
    messageDialogOpen: PropTypes.bool
};

export default ContactOwner;
