import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from 'styles/util';
import { getSelectedTab, getFilteredBookings } from './BookingsSectionHelper';
import strings from 'localization/strings';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import clsx from 'clsx';

import PageTitle from 'common/PageTitle';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Chip from '@material-ui/core/Chip';
import Loader from 'common/Loader';
import LoadingError from 'common/LoadingError';
import Pagination from '@material-ui/lab/Pagination';

const useStyles = makeStyles(({ theme, colors }) => ({
    paginationWrapper: {
        textAlign: 'center',
    },
    pagination: {
        display: 'inline-block',
        '& .MuiPaginationItem-root.Mui-selected': {
            color: colors.white
        }
    },
    badge: {
        backgroundColor: colors.primaryColor,
        color: colors.white,
        borderRadius: '50%',
        fontSize: '0.75rem',
        height: '1.25rem',
        width: '1.25rem',
        lineHeight: '1.25rem',
        textAlign: 'center',
        marginLeft: theme.spacing(1),
        display: 'inline-block'
    },
    filterChips: {
        display: 'flex',
        flexWrap: 'wrap',
        gap: theme.spacing(1),
        justifyContent: 'center'
    },
    filterChip: {
        backgroundColor: 'transparent',
        '&:hover, &:focus': {
            backgroundColor: colors.lightBackgroundColor
        }
    },
    activeFilterChip: {
        backgroundColor: colors.lightBackgroundColor
    },
    actionPanel: {
        flex: '0 0 auto'
    },
    titleWithPanel: {
        display: 'flex',
        justifyContent: 'space-between'
    }
}));

const BookingsSection = ({ bookings, filteredBookings, isLoading, loadingError, tabs, onFilterChange, filterParams, title, titleActionPanel, renderBooking, bookingFilter = undefined, paginated = false }) => {
    const classes = useStyles();
    const isMobile = !useMediaQuery(theme => theme.breakpoints.up('md'));
    const selectedTab = getSelectedTab(tabs, filterParams);

    const handleChipClick = (newValue) => {
        const newTab = tabs.find(tab => tab.key === newValue);
        const adminBookingStatus = newTab.adminBookingStatus
            ? newTab.adminBookingStatus.key
            : undefined;
        onFilterChange({
            status: adminBookingStatus
        });
    };

    const formatChipLabel = tab => {
        const adminBookingStatus = tab.adminBookingStatus
            ? tab.adminBookingStatus.key
            : undefined;
        const count = (getFilteredBookings(bookings, { ...filterParams, status: adminBookingStatus }) || []).length;

        return (
            <>
                {tab.label}
                {count > 0 &&
                    <Box className={classes.badge} component="span">
                        {count}
                    </Box>
                }
            </>
        );
    };

    const getPaginatedBookings = () => {
        if(!paginated) {
            return filteredBookings;
        }
        // page is one-based
        const startIndex = (filterParams.page - 1) * filterParams.pageSize;
        const endIndex = filterParams.page * filterParams.pageSize - 1;
        const paginatedBookings = [];
        for(let i = startIndex; i <= endIndex; i++) {
            if(filteredBookings[i]) {
                paginatedBookings.push(filteredBookings[i]);
            }
        }
        return paginatedBookings;
    };

    const getPaginationElement = () => {
        if(!paginated) {
            return undefined;
        }

        const numberOfPages = Math.floor((filteredBookings.length - 1) / filterParams.pageSize) + 1;

        if(numberOfPages < 2) {
            return undefined;
        }

        return (
            <Box className={classes.paginationWrapper}>
                <Pagination
                    className={classes.pagination}
                    color="primary"
                    page={filterParams.page}
                    count={numberOfPages}
                    onChange={handlePageChange}
                    siblingCount={isMobile ? 0 : 2}
                    boundaryCount={isMobile ? 1 : 2}
                />
            </Box>
        );
    };

    const handlePageChange = (event, newPage) => {
        onFilterChange({ ...filterParams, page: newPage });
    };

    const isUnfiltered = !filterParams.status && !filterParams.storageSiteId && !filterParams.q;

    return (
        <>
            <PageTitle>{title}</PageTitle>
            <Box className={classes.titleWithPanel}>
                <Typography variant="h2">{title}</Typography>
                {
                    titleActionPanel &&
                    (
                        <Box className={classes.actionPanel}>
                            {titleActionPanel}
                        </Box>
                    )
                }
            </Box>
            {
                loadingError && <LoadingError />
            }
            {
                isLoading && <Loader />
            }
            {
                !isLoading && bookings && (
                    <>
                        {bookingFilter}
                        <Box className={classes.filterChips}>
                            {
                                tabs.map(tab =>
                                    <Chip
                                        key={tab.key}
                                        className={clsx(classes.filterChip, {
                                            [classes.activeFilterChip]: tab.key === selectedTab.key
                                        })}
                                        clickable={true}
                                        label={formatChipLabel(tab)}
                                        onClick={() => handleChipClick(tab.key)}
                                    />
                                )
                            }
                        </Box>

                        {getPaginationElement()}

                        <Grid container spacing={2}>
                            {
                                filteredBookings.length === 0 &&
                                (
                                    <Grid item>
                                        <Typography variant="body1">{isUnfiltered ? strings.noBookings : strings.noBookingsInThisCategory}</Typography>
                                    </Grid>
                                )
                            }
                            {
                                getPaginatedBookings().map(booking =>
                                    (
                                        <Grid item xs={12} sm={6} key={booking.id} className={classes.booking}>
                                            {renderBooking(booking)}
                                        </Grid>
                                    )
                                )
                            }
                        </Grid>
                        {getPaginationElement()}
                    </>
                )
            }
        </>
    );
};

BookingsSection.propTypes = {
    bookings: PropTypes.array,
    filteredBookings: PropTypes.array,
    isLoading: PropTypes.bool.isRequired,
    loadingError: PropTypes.bool,
    tabs: PropTypes.array.isRequired,
    onFilterChange: PropTypes.func.isRequired,
    filterParams: PropTypes.object.isRequired,
    title: PropTypes.string.isRequired,
    titleActionPanel: PropTypes.node,
    renderBooking: PropTypes.func.isRequired,
    bookingFilter: PropTypes.node,
    paginated: PropTypes.bool
};

export default BookingsSection;
