import React, { useEffect } from 'react';
import { useAppContext } from 'context/AppContext';
import { useDispatch, useSelector } from 'react-redux';
import { semverGreaterThan } from 'helpers/EnvironmentHelper';
import { ping } from 'actions/ping';
import { makeStyles } from 'styles/util';
import strings from 'localization/strings';

import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';

const useStyles = makeStyles(({ theme, colors }) => ({
    container: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        padding: theme.spacing(1, 2),
        borderBottom: `1px solid ${colors.black}`,
        color: colors.attentionForeground,
        background: colors.attentionBackground
    },
    newVersionInformation: {
        flex: '0 1 auto',
        paddingRight: theme.spacing(2)
    },
    button: {
        flex: '0 0 auto'
    }
}));

const ApiVersionChecker = () => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const versionFromApiHeader = useSelector(state => state.versions.apiVersion);
    const { appContext } = useAppContext();

    useEffect(() => {
        // send a ping every minute to be able to detect new backend versions
        const interval = window.setInterval(() => {
            dispatch(ping());
        }, 60000);
        return () => window.clearInterval(interval);
    }, []);

    const handleReload = () => {
        window.location.reload();
    };

    const currentVersion = appContext.appVersion;

    if(versionFromApiHeader && semverGreaterThan(versionFromApiHeader, currentVersion)) {
        return (
            <Box className={classes.container}>
                <Box className={classes.newVersionInformation}>
                    {strings.newApplicationVersionAvailable}
                </Box>
                <Button
                    className={classes.button}
                    variant="contained"
                    color="primary"
                    onClick={handleReload}
                >
                    {strings.reloadApplication}
                </Button>
            </Box>
        );
    }

    return null;
};

export default ApiVersionChecker;
