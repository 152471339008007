import React from 'react';
import PropTypes from 'prop-types';
import { useAppContext } from 'context/AppContext';
import strings from 'localization/strings';
import dateRuleTypes from 'enums/dateRuleTypes';

import Information from './Information';
import WithDividerWrapper from 'common/WithDividerWrapper';

const OpeningTimeSpanRules = ({ storageSite }) => {
    const { appContext } = useAppContext();
    const openingTimes = storageSite.openingTimeSpanRules
        .filter(openingTimeSpanRule => dateRuleTypes[openingTimeSpanRule.type].isActive(openingTimeSpanRule))
        .map(openingTimeSpanRule => dateRuleTypes[openingTimeSpanRule.type].getDescription(openingTimeSpanRule, { asValueObject: true, ignorePastDates: true, appContext }));

    return (
        <Information title={strings.openingTimeSpanRules} titleVariant="h3" items={openingTimes} onSeparateLines />
    );
};

OpeningTimeSpanRules.propTypes = {
    storageSite: PropTypes.object.isRequired
};

export default WithDividerWrapper(OpeningTimeSpanRules);
