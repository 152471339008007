import React from 'react';
import PropTypes from 'prop-types';
import { useAppContext } from 'context/AppContext';
import { createFormDataForNewStorageGroup } from 'logic/editStorageSiteLogic';
import useMediaQuery from '@material-ui/core/useMediaQuery';

import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import StorageGroup from './StorageGroup';
import { FieldArray } from 'react-final-form-arrays';
import { makeStyles } from 'styles/util';
import storageGroupStatuses from 'enums/storageGroupStatuses';

const useStyles = makeStyles(({ theme }) => ({
    addButton: {
        marginTop: theme.spacing(1)
    }
}));

const StorageGroupList = ({ storageSiteFormData, storageSiteExists = false, showExclusiveVatSuffix = false, category, fieldNamePrefix, onSaveStorageGroup, showDeleted = false, editable = false }) => {
    const classes = useStyles();
    const { appContext } = useAppContext();

    const getName = suffix => suffix ? `${fieldNamePrefix}.${suffix}` : fieldNamePrefix;

    const handleAddStorageGroup = fields => {
        const newStorageGroup = createFormDataForNewStorageGroup(category, fields.value, appContext);
        newStorageGroup.editor = {...newStorageGroup};
        fields.push(newStorageGroup);
    };

    const handleRemoveStorageGroup = (fields, index) => {
        fields.remove(index);
    };

    const buttonFullWidth = !useMediaQuery(theme => theme.breakpoints.up('md'));

    return (
        <FieldArray name={getName('storageGroups')}>
            {({ fields }) => (
                <>
                    {
                        fields.length > 0 &&
                        (
                            <Box>
                                {
                                    fields.map((name, index) => {
                                        const value = fields.value[index];
                                        if(value.status === storageGroupStatuses.deleted.key && !showDeleted) {
                                            return undefined;
                                        }
                                        return (
                                            <StorageGroup
                                                key={name}
                                                storageSiteFormData={storageSiteFormData}
                                                category={category}
                                                fieldNamePrefix={name}
                                                showExclusiveVatSuffix={showExclusiveVatSuffix}
                                                editable={editable}
                                                storageSiteExists={storageSiteExists}
                                                onSave={onSaveStorageGroup}
                                                onRemove={() => handleRemoveStorageGroup(fields, index)}
                                            />
                                        );
                                    })
                                }
                            </Box>
                        )
                    }
                    {
                        editable &&
                        (
                            <Button
                                className={classes.addButton}
                                variant="contained"
                                color="secondary"
                                onClick={() => handleAddStorageGroup(fields)}
                                fullWidth={buttonFullWidth}
                            >
                                {category.addButtonLabel}
                            </Button>
                        )
                    }
                </>
            )}
        </FieldArray>
    );
};

StorageGroupList.propTypes = {
    storageSiteExists: PropTypes.bool,
    storageSiteFormData: PropTypes.object.isRequired,
    showExclusiveVatSuffix: PropTypes.bool,
    category: PropTypes.object.isRequired,
    fieldNamePrefix: PropTypes.string.isRequired,
    onSaveStorageGroup: PropTypes.func,
    showDeleted: PropTypes.bool,
    editable: PropTypes.bool,
};

export default StorageGroupList;
