import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from 'styles/util';
import { parseIsoDate } from 'helpers/DateHelper';

import TimeAgo from 'common/TimeAgo';
import ReadOnlyField from '../../presentational/ReadOnlyField';
import Typography from '@material-ui/core/Typography';

const useStyles = makeStyles(({ theme }) => ({
    noteList: {
        margin: 0,
        padding: 0,
        listStyle: 'none',
        '& li': {
            marginBottom: theme.spacing(2)
        },
    },
    createdByUserName: {
        display: 'inline-block',
    },
    createdTime: {
        display: 'inline-block',
        marginLeft: theme.spacing(1),
        opacity: 0.6
    }
}));

const NoteList = ({ notes }) => {
    const classes = useStyles();

    if(notes.length === 0) {
        return null;
    }

    const sortedNotes = [...notes];
    sortedNotes.sort((a, b) => -(parseIsoDate(a.createdTime).getTime() - parseIsoDate(b.createdTime).getTime()));

    return (
        <ReadOnlyField label="Notes">
            <ul className={classes.noteList}>
                {
                    sortedNotes.map(note =>
                        (
                            <li key={note.id}>
                                <Typography variant="caption" display="block">
                                    <span className={classes.createdByUserName}>
                                        {note.createdByUserName}
                                    </span>
                                    <TimeAgo date={note.createdTime} className={classes.createdTime}/>
                                </Typography>
                                <Typography variant="body1" display="block">
                                    {note.text}
                                </Typography>
                            </li>
                        )
                    )
                }
            </ul>
        </ReadOnlyField>
    );
};

NoteList.propTypes = {
    notes: PropTypes.array.isRequired
};

export default NoteList;
