import React from 'react';
import PropTypes from 'prop-types';
import { useField } from 'react-final-form';
import { makeStyles } from 'styles/util';
import { showErrorOnBlur, TextField } from 'mui-rff';
import strings from 'localization/strings';
import { mustBeNumberIf } from 'form/validation';
import { convertToNumber } from 'helpers/StringHelper';
import { calculateArea } from 'helpers/StorageAreaCalculator';
import { createEndAdornment } from 'helpers/FormHelper';
import rentalTypes from 'enums/rentalTypes';
import storageGroupCategories from 'enums/storageGroupCategories';

import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import TooltipIcon from 'common/TooltipIcon';
import Area from 'common/Area';

const useStyles = makeStyles(({ theme, colors }) => ({
    textInput: {
        margin: theme.spacing(1, 0, 2),
        '& .MuiOutlinedInput-root': {
            borderRadius: theme.spacing(1),
            color: colors.darkBlue,
        },
        '& .MuiTextField-root': {

        },
        '& .MuiOutlinedInput-input': {
            padding: theme.spacing(1.875, 0, 1.875, 1.5)
        },
        '& .MuiOutlinedInput-notchedOutline': {
            border: `2px solid ${colors.darkBlue}`
        },
        '& .MuiSvgIcon-root': {
            color: colors.darkBlue,
            fontSize: theme.spacing(2.875)
        },
        '& .MuiOutlinedInput-adornedEnd': {
            paddingRight: theme.spacing(1)
        },
    },

    disabled: {
        '& .MuiOutlinedInput-root': {
            '& input': {
                textAlign: 'right',
                padding: theme.spacing(1, 2),
                background: 'lightestGrey'
            }
        },
    },
    customAreaContainer: {
        display: 'flex',
        position: 'relative',
        gap: theme.spacing(0.75),
        '& .MuiTypography-subtitle1': {
            whiteSpace: 'nowrap',
            alignSelf: 'center',
            marginBottom: theme.spacing(-1.5)
        }
    },
    customAreaFields: {
        display: 'flex',
        flexDirection: 'column',
        '& .MuiFormControl-fullWidth': {
            width: 'auto'
        },
    },
    price: {
        textAlign: 'right',
        color: colors.primaryColor
    },
    errorLabel: {
        position: 'absolute',
        bottom: theme.spacing(-1),
        margin: theme.spacing(0),
        lineHeight: 0
    },
    errorLabelHidden: {
        display: 'none'
    },
    notBookable: {
        color: colors.textColorDisabled
    },
    measurementPadding: {
        paddingRight: theme.spacing(2)
    },
    tooltip: {
        position: 'absolute',
        right: 0,
        margin: '0 !important'
    }
}));

const CustomStorageAreaFields = ({ storageGroup, value, disabled, checked }) => {
    const classes = useStyles();
    let fieldNamePrefix = '';
    if(storageGroup.rentalType === rentalTypes.customArea.key) {
        fieldNamePrefix = storageGroup.category;
    }

    const widthField = useField(`categories.${fieldNamePrefix}.width`);
    const customWidth = convertToNumber(widthField.input.value);

    const lengthField = useField(`categories.${fieldNamePrefix}.length`);
    const customLength = convertToNumber(lengthField.input.value);
    const areaCalculation = calculateArea(customLength, customWidth, storageGroupCategories[storageGroup.category]);

    return (
        <Box className={classes.customAreaContainer}>
            <Box className={classes.customAreaFields}>
                <Typography variant="subtitle2">{strings.length}</Typography>
                <TextField
                    name={`categories.${fieldNamePrefix}.length`}
                    variant="outlined"
                    showError={showErrorOnBlur}
                    size="small"
                    disabled={value || disabled ? !checked : false}
                    className={checked || !value ? classes.textInput : classes.disabled}
                    fieldProps={{validate: mustBeNumberIf(checked)}}
                    InputProps={createEndAdornment(strings.meterAbbreviated)}
                    FormHelperTextProps={{classes: {
                        error: checked ? classes.errorLabel :  classes.errorLabelHidden
                    }}}
                />
            </Box>
            <Box className={classes.customAreaFields}>
                <Typography variant="subtitle2">{strings.width}</Typography>
                <TextField
                    name={`categories.${fieldNamePrefix}.width`}
                    variant="outlined"
                    showError={showErrorOnBlur}
                    size="small"
                    disabled={value || disabled ? !checked : false}
                    className={checked || !value ? classes.textInput : classes.disabled}
                    fieldProps={{validate: mustBeNumberIf(checked)}}
                    InputLabelProps={{ shrink: true }}
                    InputProps={createEndAdornment(strings.meterAbbreviated)}
                    FormHelperTextProps={{classes: {
                        error: checked ? classes.errorLabel :  classes.errorLabelHidden
                    }}}
                />
            </Box>
            <Typography variant="subtitle1">
                <>
                    {
                        <>&nbsp;=&nbsp;<Area value={areaCalculation?.adjustedArea} maxNumberOfDecimals={1} defaultString="-"/></>
                    }
                </>
            </Typography>
            {areaCalculation.areaDiffer && (
                <TooltipIcon text={strings.vehicleSizeDescriptionBox} className={classes.tooltip} />
            )}
        </Box>
    );
};

CustomStorageAreaFields.propTypes = {
    storageGroup: PropTypes.object,
    value: PropTypes.number,
    disabled: PropTypes.bool,
    checked: PropTypes.bool,
};

export default CustomStorageAreaFields;
