import React from 'react';
import PropTypes from 'prop-types';
import { useAppContext } from 'context/AppContext';
import { formatTimeSpan } from 'helpers/OpeningTimeSpanHelper';

const TimeSpan = ({ value, includeMode = false }) => {
    const { appContext } = useAppContext();
    return <>{formatTimeSpan(value, { includeMode, appContext })}</>;
};

TimeSpan.propTypes = {
    value: PropTypes.object.isRequired,
    includeMode: PropTypes.bool
};

export default TimeSpan;
