import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router';
import { useHistory } from 'react-router-dom';
import { handleResponse } from 'actions/actionHelpers';
import { getUser, updateUser } from 'actions/admin/users';
import { logIn } from 'actions/authentication';
import routes from 'routes';

import Button from '@material-ui/core/Button';
import EditUserForm from './EditUserForm';
import Loader from 'common/Loader';
import AdminPage from '../../presentational/AdminPage';
import AdminContentWrapper from '../../presentational/AdminContentWrapper';
import AdminActionWrapper from '../../presentational/AdminActionWrapper';

const User = () => {
    const dispatch = useDispatch();
    const history = useHistory();
    const params = useParams();
    const [user, setUser] = useState();
    const [isLoading, setIsLoading] = useState(true);
    const [isSubmitting, setIsSubmitting] = useState(false);

    useEffect(() => {
        dispatch(getUser(params.userId))
            .then(handleResponse(
                response => {
                    setUser(response.payload);
                    setIsLoading(false);
                },
                () => setIsLoading(false)
            ));
    }, []);

    const handleSubmit = (formValues) => {
        setIsSubmitting(true);
        const updateUserRequest = {
            firstName: formValues.firstName,
            lastName: formValues.lastName,
            email: formValues.email,
            isSuperAdmin: formValues.isSuperAdmin,
            newPassword: formValues.newPassword
        };
        dispatch(updateUser(user.id, updateUserRequest))
            .then(handleResponse(
                () => {
                    history.push(routes.admin.users);
                    setIsSubmitting(false);
                    return { snackbar: { message: 'User was successfully saved.' } };
                },
                () => setIsSubmitting(false)
            ));
    };

    const handleCancel = () => {
        history.push(routes.admin.users);
    };

    const handleLogInClick = () => {
        dispatch(logIn({
            impersonationUserId: user.id
        }))
            .then(handleResponse(
                () => history.push(routes.account.overview)
            ));
    };

    const title = 'User: ' + (user ? user.name : 'Loading...');

    return (
        <AdminPage title={title}>
            {
                isLoading && <Loader />
            }
            {
                user && (
                    <AdminContentWrapper type="detail">

                        <EditUserForm
                            user={user}
                            isSubmitting={isSubmitting}
                            onSubmit={handleSubmit}
                            onCancel={handleCancel}
                        />

                        <AdminActionWrapper>
                            <Button
                                variant="contained"
                                color="primary"
                                onClick={handleLogInClick}
                            >
                                Log in as {user.name}
                            </Button>
                        </AdminActionWrapper>

                    </AdminContentWrapper>
                )
            }
        </AdminPage>
    );
};

export default User;
