import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useAppContext } from 'context/AppContext';
import { makeStyles } from 'styles/util';
import { getShortDateFormat, formatShortDate, parseDate, parseDateTime, formatShortDateTimeSeconds, formatIsoDate } from 'helpers/DateHelper';
import bookingStatuses from 'enums/bookingStatuses';
import adminBookingStatuses from 'enums/adminBookingStatuses';
import storageGroupCategories from 'enums/storageGroupCategories';
import paymentRecurrences from 'enums/paymentRecurrences';
import cancellationReasons from 'enums/cancellationReasons';
import bookingItemTypes from 'enums/bookingItemTypes';
import routes from 'routes';
import arrayMutators from 'final-form-arrays';
import { changeValueMutator } from 'helpers/FormHelper';
import { calculateAmountIncludingVat } from 'helpers/MonetaryHelper';
import { formatNumber, formatAmount, convertToNumber } from 'helpers/StringHelper';
import { getStorageTitle } from 'helpers/StorageSiteHelper';
import { required } from 'form/validation';
import goodsTypes from 'enums/goodsTypes';
import paymentProviders from 'enums/paymentProviders';
import { getContact, getBusinessOrPrivateName } from 'helpers/ActorHelper';
import contactTypes from 'enums/contactTypes';
import lockTypes from 'enums/lockTypes';

import { Link } from 'react-router-dom';
import Form from 'form/Form';
import { TextField, showErrorOnBlur } from 'mui-rff';
import MarginBox from 'common/MarginBox';
import MenuItem from '@material-ui/core/MenuItem';
import Button from '@material-ui/core/Button';
import AdminFormWrapper from '../../presentational/AdminFormWrapper';
import AdminFormFields from '../../presentational/AdminFormFields';
import AdminFormButtons from '../../presentational/AdminFormButtons';
import ReadOnlyField from '../../presentational/ReadOnlyField';
import PurchaseList from './PurchaseList';
import NoteList from './NoteList';
import StorageEditor from './StorageEditor';
import BookingItemsEditor from './BookingItemsEditor';
import { Checkboxes } from 'mui-rff';
import BookingActions from './BookingActions';
import TimeAgo from 'common/TimeAgo';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import SubscriptionPriceChangeInfo from './SubscriptionPriceChangeInfo';
import ShortDate from 'common/ShortDate';
import ShortDateTime from 'common/ShortDateTime';
import AdminActorSelectorDialog from 'components/adminActorSelector/AdminActorSelectorDialog';
import LinkButton from 'common/LinkButton';
import DatePicker from 'form/DatePicker';

const useStyles = makeStyles(({ theme, colors }) => ({
    storageAndPricingAccordion: {
        margin: theme.spacing(2, 0),
        boxShadow: 'none',
        borderTop: `1px solid ${colors.mediumGrey}`,
        borderBottom: `1px solid ${colors.mediumGrey}`
    },
    storageAndPricingAccordionSummary: {
        padding: theme.spacing(0)
    },
    storageAndPricingAccordionDetails: {
        flexDirection: 'column',
        paddingLeft: theme.spacing(0),
        paddingRight: theme.spacing(0)
    },
    tenantActorName: {
        paddingRight: theme.spacing(1)
    }
}));

const formatAmountOptions = { skipCurrencySuffix: true, skipThousandSeparators: true };
const formatNumberOptions = { skipThousandSeparators: true, sanitizeMinusSign: true };

const EditBookingForm = ({ booking, storageSite, isSubmitting = false, onSubmit, onCancel }) => {
    const classes = useStyles();

    const [tenantActor, setTenantActor] = useState({
        id: booking.tenantActorId,
        name: booking.tenantName,
        contacts: [
            { type: contactTypes.mainEmail.key, value: booking.tenantEmail },
            { type: contactTypes.mainMobileTelephone.key, value: booking.tenantMobileTelephone },
        ]
    });
    const [actorSelectorDialogOpen, setActorSelectorDialogOpen] = useState(false);

    const { appContext } = useAppContext();

    const storageVatRate = booking.bookingItems.find(o => o.type === bookingItemTypes.storage.key).vatRate;

    const initialValues = {
        startDate: formatShortDate(booking.startDate, appContext),
        endDate: formatShortDate(booking.endDate, appContext),
        actualEndDate: formatShortDate(booking.actualEndDate, appContext),
        confirmationDeadline: formatShortDateTimeSeconds(booking.confirmationDeadline, appContext),
        initialPaymentDeadline: formatShortDateTimeSeconds(booking.initialPaymentDeadline, appContext),
        flagged: booking.flagged,
        cancellationReason: booking.cancellationReason,
        cancellationReasonText: booking.cancellationReasonText,
        storageGroupId: booking.storageGroupId,
        storageId: booking.storageId,
        subscriptionPeriodStoragePrice: formatAmount(booking.priceInfo.perMonth.amounts.storageAmount, booking.currency, appContext, formatAmountOptions),
        storageVatRate: formatNumber(100 * storageVatRate, appContext, formatNumberOptions),
        bookingItems: booking.bookingItems
            .filter(bookingItem => bookingItem.type !== bookingItemTypes.storage.key)
            .map(bookingItem => ({
                ...bookingItem,
                amount: formatAmount(bookingItem.amount, booking.currency, appContext, formatAmountOptions),
                vatRate: formatNumber(100 * bookingItem.vatRate, appContext, formatNumberOptions),
                isPredefined: bookingItem.referenceId !== undefined
            })),
        effectDate: formatShortDate(new Date(), appContext),
        tenantActorId: booking.tenantActorId,
        bookingStatus: booking.bookingStatus,
        parakeyAdditionalUserEmails: booking.lockConfiguration?.parakeyAdditionalUserEmails.join(', ')
    };

    let showStorageAndPricingSection;

    if(booking.subscriptionBooking) {
        showStorageAndPricingSection =
            booking.adminBookingStatus === adminBookingStatuses.requested.key ||
            booking.adminBookingStatus === adminBookingStatuses.confirmed.key ||
            booking.adminBookingStatus === adminBookingStatuses.notStarted.key ||
            booking.adminBookingStatus === adminBookingStatuses.active.key;
    } else {
        showStorageAndPricingSection =
            booking.adminBookingStatus === adminBookingStatuses.requested.key ||
            booking.adminBookingStatus === adminBookingStatuses.confirmed.key;
    }

    const getBookingStatusText = () => {
        if(booking.adminBookingStatus === adminBookingStatuses.canceled.key) {
            return `(by ${booking.canceledBy})`;
        }
        return undefined;
    };

    const parseLockConfiguration = formValues => {
        const emailArray = (formValues.parakeyAdditionalUserEmails ?? '')
            .split(',')
            .map(o => o.trim())
            .filter(o => o !== '');
        if(emailArray.length === 0) {
            return undefined;
        }
        return { parakeyAdditionalUserEmails: emailArray };
    };

    const handleFormSubmit = formValues => {
        onSubmit({
            ...formValues,
            startDate: parseDate(formValues.startDate, appContext),
            endDate: parseDate(formValues.endDate, appContext),
            actualEndDate: parseDate(formValues.actualEndDate, appContext),
            confirmationDeadline: parseDateTime(formValues.confirmationDeadline, appContext),
            initialPaymentDeadline: parseDateTime(formValues.initialPaymentDeadline, appContext),
            effectDate: parseDate(formValues.effectDate, appContext),
            lockConfiguration: parseLockConfiguration(formValues)
        });
    };

    const calculatePreviousSubscriptionPeriodPrice = () => {
        let sum = 0;
        booking.bookingItems
            .filter(bookingItem => bookingItem.paymentRecurrence === paymentRecurrences.perMonth.key)
            .forEach(bookingItem => {
                sum += calculateAmountIncludingVat(bookingItem.amount, bookingItem.vatRate, appContext);
            });
        return sum;
    };

    const calculateNewSubscriptionPeriodPrice = values => {
        let sum = 0;
        getBookingItemsIncludingStorageBookingItem(values)
            .filter(bookingItem => bookingItem.paymentRecurrence === paymentRecurrences.perMonth.key)
            .forEach(bookingItem => {
                sum += calculateAmountIncludingVat(convertToNumber(bookingItem.amount), convertToNumber(bookingItem.vatRate) / 100, appContext);
            });
        return sum;
    };

    const getStorageAndPricingTitle = formValues => {
        const storageGroup = storageSite.storageGroups.find(o => o.id === formValues.storageGroupId);
        const storage = storageGroup.storages.find(o => o.id === formValues.storageId) ?? { title: 'Not selected' };
        return getStorageTitle(storage, storageGroup, appContext);
    };

    const getBookingItemsIncludingStorageBookingItem = values => {
        return [{
            amount: values.subscriptionPeriodStoragePrice,
            vatRate: values.storageVatRate,
            paymentRecurrence: paymentRecurrences.perMonth.key
        }].concat(values.bookingItems);
    };

    const isParakeyBooking = formValues => {
        const storageGroup = storageSite.storageGroups.find(o => o.id === formValues.storageGroupId);
        return storageGroup.lockConfigurations.find(o => o.lockType === lockTypes.parakey.key) !== undefined;
    };

    const handleActorSelectorDialogClose = () => {
        setActorSelectorDialogOpen(false);
    };

    const handleChangeTenantActorClick = () => {
        setActorSelectorDialogOpen(true);
    };

    const getStatuses = () => {
        if (booking.bookingStatus === bookingStatuses.confirmed.key) {
            return {
                status: [booking.bookingStatus, bookingStatuses.requested.key],
                disabled: false
            };
        }
        if (booking.bookingStatus === bookingStatuses.canceled.key) {
            return {
                status: [booking.bookingStatus, bookingStatuses.confirmed.key],
                disabled: false
            };
        }
        return {
            status: [booking.bookingStatus],
            disabled: true
        };
    };

    const canChangeStartDate = () => !booking.subscriptionBooking || booking.bookingStatus !== bookingStatuses.purchased.key;

    const canChangeEndDate = () => !booking.subscriptionBooking && booking.bookingStatus !== bookingStatuses.purchased.key;

    const canChangeActualEndDate = () =>
        (!booking.subscriptionBooking && booking.bookingStatus === bookingStatuses.purchased.key)
        ||
        (
            booking.subscriptionBooking &&
            (
                booking.adminBookingStatus === adminBookingStatuses.subscriptionEnding.key ||
                booking.adminBookingStatus === adminBookingStatuses.ended.key
            )
        );

    return (
        <AdminFormWrapper isSubmitting={isSubmitting}>
            <Form
                initialValues={initialValues}
                mutators={{
                    ...arrayMutators,
                    changeValue: changeValueMutator
                }}
                onSubmit={handleFormSubmit}
            >
                {({ form, values, handleSubmit }) => {
                    const previousSubscriptionPeriodPrice = calculatePreviousSubscriptionPeriodPrice();
                    const newSubscriptionPeriodPrice = calculateNewSubscriptionPeriodPrice(values);
                    const priceChanged = newSubscriptionPeriodPrice !== previousSubscriptionPeriodPrice;

                    const handleActorSelectorDialogOk = actor => {
                        setTenantActor(actor);
                        form.change('tenantActorId', actor.id);
                        setActorSelectorDialogOpen(false);
                    };

                    return (
                        <form onSubmit={handleSubmit}>
                            <AdminFormFields>

                                <ReadOnlyField label="Storage site">
                                    <Link to={routes.storageSiteDetails.replace(':storageSiteId', booking.storageSiteId)} target="_blank">{booking.storageSiteTitle}</Link>
                                </ReadOnlyField>

                                <ReadOnlyField label="Owner">
                                    <Link to={routes.admin.actor.replace(':actorId', booking.ownerActorId)}>{booking.ownerName}</Link>
                                </ReadOnlyField>

                                <ReadOnlyField label="Tenant">
                                    <Link className={classes.tenantActorName} to={routes.admin.actor.replace(':actorId', tenantActor.id)}>{getBusinessOrPrivateName(tenantActor)}</Link>
                                    <LinkButton onClick={handleChangeTenantActorClick}>Change</LinkButton>
                                </ReadOnlyField>

                                <ReadOnlyField label="Tenant contact details">
                                    <a href={'mailto:' + getContact(tenantActor, contactTypes.mainEmail)}>{getContact(tenantActor, contactTypes.mainEmail)}</a>
                                    {getContact(tenantActor, contactTypes.mainMobileTelephone) && ' | '}
                                    {getContact(tenantActor, contactTypes.mainMobileTelephone)}
                                </ReadOnlyField>

                                <ReadOnlyField label="Description">
                                    {booking.description}
                                </ReadOnlyField>

                                {
                                    booking.storageGroupCategory === storageGroupCategories.vehicle.key &&
                                    (
                                        <>
                                            <ReadOnlyField label="GoodsType">
                                                {(goodsTypes[booking.goodsType] ? goodsTypes[booking.goodsType].title : '')}
                                            </ReadOnlyField>
                                            <ReadOnlyField label="RegistrationNumber">
                                                {booking.registrationNumber}
                                            </ReadOnlyField>
                                        </>
                                    )
                                }

                                <ReadOnlyField label="Subscription">
                                    {booking.paymentProviderPortalSubscriptionUrl ? <a href={booking.paymentProviderPortalSubscriptionUrl} target="_blank">{booking.paymentProviderSubscriptionId}</a> : (booking.subscriptionBooking ? 'yes' : 'no')}
                                </ReadOnlyField>

                                {
                                    booking.paymentProvider &&
                                    (
                                        <ReadOnlyField label="Payment provider">
                                            {paymentProviders[booking.paymentProvider].providerName}
                                        </ReadOnlyField>
                                    )
                                }

                                {
                                    booking.takeoverTenantId &&
                                    (
                                        <ReadOnlyField label="Takeover booking">
                                            <Link to={routes.admin.takeover.replace(':takeoverId', booking.takeoverId)}>{booking.takeoverTenantId}</Link>
                                        </ReadOnlyField>
                                    )
                                }

                                <ReadOnlyField label="Booking request created time">
                                    <ShortDateTime value={booking.createdTime}/>
                                </ReadOnlyField>

                                <DatePicker
                                    name="startDate"
                                    label="Start date"
                                    formatFunc={getShortDateFormat}
                                    disabled={!canChangeStartDate()}
                                    inputVariant="outlined"
                                    showError={showErrorOnBlur}
                                    fieldProps={{ validate: required }}
                                />

                                {
                                    !canChangeEndDate() &&
                                    (
                                        <ReadOnlyField label="End date">
                                            <ShortDate value={booking.endDate} defaultString="until further"/>
                                        </ReadOnlyField>
                                    )
                                }
                                {
                                    canChangeEndDate() &&
                                    (
                                        <DatePicker
                                            name="endDate"
                                            label="End date"
                                            formatFunc={getShortDateFormat}
                                            inputVariant="outlined"
                                            showError={showErrorOnBlur}
                                            fieldProps={{ validate: required }}
                                        />
                                    )
                                }
                                {
                                    canChangeActualEndDate() &&
                                    (
                                        <DatePicker
                                            name="actualEndDate"
                                            label="Actual end date"
                                            formatFunc={getShortDateFormat}
                                            inputVariant="outlined"
                                            showError={showErrorOnBlur}
                                        />
                                    )
                                }

                                <ReadOnlyField label="Lifecycle">
                                    {booking.adminBookingStatus}
                                </ReadOnlyField>

                                <TextField
                                    select
                                    name="bookingStatus"
                                    label="Status"
                                    variant="outlined"
                                    disabled={getStatuses().disabled}
                                >
                                    {
                                        getStatuses().status.map(status => (
                                            <MenuItem key={status} value={status}>
                                                {status} {getBookingStatusText()}
                                            </MenuItem>
                                        ))
                                    }
                                </TextField>
                                <ReadOnlyField label="Updated">
                                    <TimeAgo date={booking.updatedTime}/>
                                </ReadOnlyField>

                                {
                                    showStorageAndPricingSection &&
                                    (
                                        <Accordion className={classes.storageAndPricingAccordion}>
                                            <AccordionSummary className={classes.storageAndPricingAccordionSummary} expandIcon={<ExpandMoreIcon />}>
                                                Storage and pricing: {getStorageAndPricingTitle(values)}
                                            </AccordionSummary>
                                            <AccordionDetails className={classes.storageAndPricingAccordionDetails}>
                                                <StorageEditor
                                                    booking={booking}
                                                    storageSite={storageSite}
                                                />

                                                <BookingItemsEditor
                                                    booking={booking}
                                                    storageSite={storageSite}
                                                />

                                                {
                                                    priceChanged && values.bookingStatus === bookingStatuses.purchased.key &&
                                                    (
                                                        <MarginBox top={2}>
                                                            <TextField
                                                                name="effectDate"
                                                                label="Date for price change"
                                                                helperText={`Prices will be changed at this date, and the adjustments will be included in the next subscription invoice due at ${formatIsoDate(booking.nextSubscriptionInvoiceTime)}.`}
                                                                variant="outlined"
                                                            />
                                                        </MarginBox>
                                                    )
                                                }

                                                {
                                                    booking.subscriptionBooking &&
                                                    (
                                                        <SubscriptionPriceChangeInfo
                                                            booking={booking}
                                                            previousPrice={previousSubscriptionPeriodPrice}
                                                            newPrice={newSubscriptionPeriodPrice}
                                                            nextInvoiceTime={booking.nextSubscriptionInvoiceTime}
                                                        />
                                                    )
                                                }
                                            </AccordionDetails>
                                        </Accordion>
                                    )
                                }

                                <TextField
                                    name="confirmationDeadline"
                                    label="Confirmation deadline"
                                    variant="outlined"
                                    disabled={values.bookingStatus !== bookingStatuses.requested.key}
                                />

                                <TextField
                                    name="initialPaymentDeadline"
                                    label="Initial payment deadline"
                                    variant="outlined"
                                    disabled={values.bookingStatus !== bookingStatuses.confirmed.key}
                                />

                                {
                                    values.bookingStatus === bookingStatuses.canceled.key &&
                                    (
                                        <>
                                            <TextField
                                                select
                                                name="cancellationReason"
                                                label="Cancellation reason"
                                                variant="outlined"
                                            >
                                                {
                                                    Object.values(cancellationReasons).map(o => (
                                                        <MenuItem key={o.key} value={o.key}>{o.title}</MenuItem>
                                                    ))
                                                }
                                            </TextField>

                                            <TextField
                                                name="cancellationReasonText"
                                                label="Cancellation reason notes"
                                                variant="outlined"
                                                maxLength={1024}
                                                multiline
                                                rows={3}
                                            />
                                        </>
                                    )
                                }

                                {
                                    booking.agreementUrl &&
                                    (
                                        <ReadOnlyField label="Agreement">
                                            <Link to={booking.agreementUrl} target="_blank">{booking.agreementUrl}</Link>
                                        </ReadOnlyField>
                                    )
                                }

                                {
                                    booking.agreementUrl &&
                                    (
                                        <ReadOnlyField label="Agreement">
                                            <Link to={booking.agreementUrl} target="_blank">{booking.agreementUrl}</Link>
                                        </ReadOnlyField>
                                    )
                                }

                                {
                                    isParakeyBooking(values) &&
                                    (
                                        <TextField
                                            name="parakeyAdditionalUserEmails"
                                            label="Additional Parakey users"
                                            helperText="Comma-separated email addresses for additional Parakey users."
                                            variant="outlined"
                                        />
                                    )
                                }

                                <PurchaseList purchases={booking.purchases} />

                                <NoteList notes={booking.notes}/>

                                {
                                    // only possible to remove a flag
                                    // flags are added along with notes
                                    booking.flagged &&
                                    (
                                        <Checkboxes
                                            name="flagged"
                                            data={{
                                                label: 'Flagged',
                                                value: true
                                            }}
                                        />
                                    )
                                }
                            </AdminFormFields>

                            <AdminFormButtons>
                                <Button
                                    type="submit"
                                    variant="contained"
                                    color="primary"
                                >
                                    Save
                                </Button>
                                <Button
                                    type="button"
                                    variant="outlined"
                                    color="primary"
                                    onClick={onCancel}
                                >
                                    Cancel
                                </Button>
                            </AdminFormButtons>

                            <AdminActorSelectorDialog
                                title="Select new tenant"
                                onOk={handleActorSelectorDialogOk}
                                onClose={handleActorSelectorDialogClose}
                                open={actorSelectorDialogOpen}
                            />
                        </form>
                    );
            }}
            </Form>

            <BookingActions booking={booking} />

        </AdminFormWrapper>
    );
};

EditBookingForm.propTypes = {
    booking: PropTypes.object.isRequired,
    storageSite: PropTypes.object.isRequired,
    isSubmitting: PropTypes.bool,
    onSubmit: PropTypes.func.isRequired,
    onCancel: PropTypes.func.isRequired
};

export default EditBookingForm;
