import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import strings from 'localization/strings';
import { makeStyles } from 'styles/util';

import Typography from '@material-ui/core/Typography';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import TimeAgo from 'common/TimeAgo';
import BookingDetailsSectionCaption from './BookingDetailsSectionCaption';

const useStyles = makeStyles(({ theme }) => ({
    list: {
        padding: 0
    },
    createdByUserName: {
        display: 'inline-block',
        marginRight: theme.spacing(1)
    },
    createdTime: {
        display: 'inline-block',
        opacity: 0.6
    }
}));

const BookingNotes = ({ booking }) => {
    const classes = useStyles();
    const authenticationContext = useSelector(state => state.authentication.context);
    const showAuthor = authenticationContext.isSuperAdminOrImpersonated;

    const renderNoteListItem = note => (
        <ListItem key={note.id} disableGutters>
            <ListItemText>
                <Typography variant="caption" display="block">
                    {
                        showAuthor &&
                        (
                            <span className={classes.createdByUserName}>
                                {note.createdByUserName}
                            </span>
                        )
                    }
                    <TimeAgo date={note.createdTime} className={classes.createdTime}/>
                </Typography>
                <Typography variant="body1" display="block">
                    {note.text}
                </Typography>
            </ListItemText>
        </ListItem>
    );

    return (
        <>
            <BookingDetailsSectionCaption>
                {strings.bookingNotes}
            </BookingDetailsSectionCaption>
            <List dense className={classes.list}>
                {booking.notes.map(note => renderNoteListItem(note))}
            </List>
        </>
    );
};

BookingNotes.propTypes = {
    booking: PropTypes.object.isRequired
};

export default BookingNotes;
