import React from 'react';
import PropTypes from 'prop-types';
import { useAppContext } from 'context/AppContext';
import { makeStyles } from 'styles/util';
import useMediaQuery from '@material-ui/core/useMediaQuery';

import InfoLabel from './InfoLabel';
import Box from '@material-ui/core/Box';

const useStyles = (isMobile, horizontal) => makeStyles(({ theme, styles }) => ({
    areaAndPriceContainer: {
        display: 'flex',
        flexDirection: isMobile || horizontal ? 'row' : 'column',
        flex: '1 1 auto',
        overflow: 'hidden',
        borderTopRightRadius: isMobile || horizontal ? 'initial' : `${styles.borderRadius / 2.5}px`,
        borderBottomRightRadius: `${styles.borderRadius / 2.5}px`,
        borderBottomLeftRadius: isMobile || horizontal ? `${styles.borderRadius / 2.5}px` : 'initial',
        gap: theme.spacing(0.25)
    }
}));

const AreaAndPrice = ({ areaText, areaTitle, priceTitle, priceText, horizontal = false, forceSmall = false }) => {
    const isMobile = useMediaQuery(theme => theme.breakpoints.down('xs')) || forceSmall;
    const classes = useStyles(isMobile, horizontal)();
    const { appContext } = useAppContext();
    return (
        <Box className={classes.areaAndPriceContainer}>
            <InfoLabel
                title={areaTitle}
                text={areaText}
                textColor={appContext.colors.areaLabelTextColor}
                backgroundColor={appContext.colors.areaLabelBackgroundColor}
                forceSmall={forceSmall}
            />
            <InfoLabel
                title={priceTitle}
                text={priceText}
                textColor={appContext.colors.priceLabelTextColor}
                backgroundColor={appContext.colors.areaLabelBackgroundColor}
                forceSmall={forceSmall}
            />
        </Box>
    );
};

AreaAndPrice.propTypes = {
    areaTitle: PropTypes.string,
    areaText: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.node
    ]),
    priceTitle: PropTypes.string,
    priceText: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.node
    ]),
    backgroundColor: PropTypes.string,
    horizontal: PropTypes.bool,
    forceSmall: PropTypes.bool
};

export default AreaAndPrice;
