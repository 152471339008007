import PropTypes from 'prop-types';
import { useAppContext } from 'context/AppContext';
import { formatPercentage } from 'helpers/StringHelper';

const Percentage = ({ value, ...rest }) => {
    const { appContext } = useAppContext();
    return formatPercentage(value, appContext, { ...rest });
};

Percentage.propTypes = {
    value: PropTypes.number,
    numberOfDecimals: PropTypes.number,
    maxNumberOfDecimals: PropTypes.number,
    toLocale: PropTypes.bool,
    sanitizeMinusSign: PropTypes.bool,
    skipThousandSeparators: PropTypes.bool,
    defaultValue: PropTypes.string
};

export default Percentage;
