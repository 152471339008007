import React from 'react';
import { useAppContext } from 'context/AppContext';
import PropTypes from 'prop-types';

// named DateDisplay instead of Date to avoid name collision with javascript Date
const DateDisplay = ({ value, formatter, defaultString = '' }) => {
    if(!value) {
        return <>{defaultString}</>;
    }
    const { appContext } = useAppContext();
    return <>{formatter(value, appContext)}</>;
};

DateDisplay.propTypes = {
    value: PropTypes.oneOfType([PropTypes.instanceOf(Date), PropTypes.string]),
    formatter: PropTypes.func.isRequired,
    defaultString: PropTypes.string
};

export default DateDisplay;
