import { createTheme } from '@material-ui/core/styles';
import { breakpoints } from './breakpoints';
import { nestedMerge } from 'helpers/ObjectHelper';
import { createBaseMuiTheme } from './baseMuiTheme';

export const createOfficeMuiTheme = appContext => {
    const { colors, styles, materialUiLocale } = appContext;

    const theme = {
        overrides: {
            MuiTypography: {
                h1: {
                    fontFamily: '"DM Serif Display", sans-serif',
                    fontSize: '52px',
                    lineHeight: '67.6px',
                    letterSpacing: '-1.04px',

                    [`@media only screen and (max-width: ${breakpoints.sm}px)`]: {
                        fontSize: '36px',
                        lineHeight: '46.8px',
                        letterSpacing: '-0.72px'
                    }
                },
                h2: {
                    fontFamily: '"Manrope Extra Bold", sans-serif',
                    fontSize: '28px',
                    lineHeight: '36.4px',
                    letterSpacing: '-0.56px',

                    [`@media only screen and (max-width: ${breakpoints.sm}px)`]: {
                        fontSize: '22px',
                        lineHeight: '28.6px',
                        letterSpacing: '-0.44px'
                    }
                },
                h3: {
                    fontFamily: '"Manrope Extra Bold", sans-serif',
                    fontSize: '22px',
                    lineHeight: '28.6px',
                    letterSpacing: '-0.44px'
                },
                h4: {
                    fontFamily: '"DM Serif Display", sans-serif',
                    fontSize: '24px',
                    lineHeight: '32.4px',
                    letterSpacing: '-0.24px',

                    [`@media only screen and (max-width: ${breakpoints.sm}px)`]: {
                        fontSize: '20px',
                        lineHeight: '26px',
                        letterSpacing: '-0.2px'
                    }
                },
                body1: {
                    fontFamily: '"Manrope Regular", sans-serif',
                    fontSize: '16px',
                    lineHeight: '24px'
                },
                body2: {
                    fontFamily: '"Manrope Regular", sans-serif',
                    fontSize: '14px',
                    lineHeight: '21px'
                },
            },
            MuiAppBar: {
                colorPrimary: {
                    color: colors.primaryColor,
                    backgroundColor: colors.white
                }
            },
            MuiPaper: {
                rounded: {
                    borderRadius: `${styles.borderRadius / 2}px`
                }
            },
            MuiButton: {
                root: {
                    borderRadius: '300px',
                },
                label: {
                    fontFamily: '"Manrope Extra Bold", sans-serif',
                    fontSize: '16px',
                    paddingLeft: '20px',
                    paddingRight: '20px'
                },
                sizeLarge: {
                    minHeight: '50px',
                    paddingLeft: '30px',
                    paddingRight: '30px'
                }
            },
            MuiSelect: {
                select: {
                    '&:focus': {
                        borderRadius: `${styles.borderRadius}px`
                    }
                }
            },
            MuiInputBase: {
                root: {
                    fontSize: '16px',
                    lineHeight: '24px',
                    borderRadius: `${styles.borderRadius}px`
                }
            },
            PrivateNotchedOutline: {
                root: {
                    borderRadius: `${styles.borderRadius}px`
                }
            },
            MuiPickersToolbarText: {
                toolbarTxt: {
                    fontFamily: '"Manrope Extra Bold", sans-serif',
                    fontSize: '22px',
                    lineHeight: '28.6px',
                    letterSpacing: '-0.44px'
                }
            },
            MuiCssBaseline: {
                '@global': {
                    body: {
                        color: colors.black
                    },
                    strong: {
                        fontFamily: '"Manrope Extra Bold", sans-serif',
                        letterSpacing: ' -0.16px'
                    }
                }
            }
        }
    };

    return createTheme(nestedMerge(createBaseMuiTheme(appContext), theme), materialUiLocale);
};
