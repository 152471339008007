import React, { useState, useEffect } from 'react';
import { useAppContext } from 'context/AppContext';
import { useParams } from 'react-router';
import { useDispatch } from 'react-redux';
import { makeStyles } from 'styles/util';
import { fetchStorageSite } from 'actions/storageSites';
import { handleResponse } from 'actions/actionHelpers';
import { formatIsoDate } from 'helpers/DateHelper';
import { startOfMonth, add } from 'date-fns';
import strings from 'localization/strings';
import { getStorageTitle } from 'helpers/StorageSiteHelper';
import storageGroupStatuses from 'enums/storageGroupStatuses';
import queryString from 'query-string';
import routes from 'routes';
import { getCurrency, getValueForCurrency } from 'logic/capacityUtilizationLogic';

import PageTitle from 'common/PageTitle';
import ShadowBox from 'common/ShadowBox';
import Loader from 'common/Loader';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import ButtonContainer from 'common/ButtonContainer';
import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import TableBody from '@material-ui/core/TableBody';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import { Link } from 'react-router-dom';
import Percentage from 'common/Percentage';
import Area from 'common/Area';
import Amount from 'common/Amount';

const useStyles = makeStyles(() => ({
    nowrap: {
        whiteSpace: 'nowrap'
    }
}));

const CapacityUtilizationTable = () => {
    const classes = useStyles();
    const params = useParams();
    const dispatch = useDispatch();

    const { appContext } = useAppContext();

    const [storageSite, setStorageSite] = useState(undefined);
    const [startDate, setStartDate] = useState(startOfMonth(new Date()));

    const storageSiteId = parseInt(params.storageSiteId, 10);

    useEffect(() => {
        setStorageSite(undefined);
        dispatch(fetchStorageSite(storageSiteId, formatIsoDate(startDate), formatIsoDate(getEndDate()), true))
            .then(handleResponse(
                response => setStorageSite(response.payload)
            ));
    }, [startDate]);

    const getStorageGroup = id => {
        return storageSite.storageGroups.filter(o=>o.id === id)[0];
    };

    const getEndDate = () => add(add(startDate, { years: 1 }), { days: -1 });

    const handlePreviousButtonClick = () => {
        const d = add(startDate, { years: -1 });
        setStartDate(d);
    };

    const handleNextButtonClick = () => {
        const d = add(startDate, { years: 1 });
        setStartDate(d);
    };

    const items = [];
    if(storageSite) {
        storageSite.bookingCalendar.storageGroupBookingCalendars.forEach(sgbc => {
            const storageGroup = getStorageGroup(sgbc.storageGroupId);
            if(storageGroup.status !== storageGroupStatuses.deleted.key) {
                sgbc.storageBookingCalendars.forEach(sbc => {
                    const storage = storageGroup.storages.filter(o => o.id === sbc.storageId)[0];
                    items.push({
                        storage,
                        storageGroup,
                        title: getStorageTitle(storage, storageGroup, appContext),
                        capacityUtilizations: sbc.capacityUtilizations
                    });
                });
            }
        });
    }

    const actionPanel = (
        <>
            <IconButton
                disabled={!storageSite}
                onClick={handlePreviousButtonClick}
            >
                <ChevronLeftIcon/>
            </IconButton>
            <IconButton
                disabled={!storageSite}
                onClick={handleNextButtonClick}
            >
                <ChevronRightIcon/>
            </IconButton>
        </>
    );

    const title = storageSite
        ? `${strings.capacityUtilization}: ${storageSite.title}`
        : strings.capacityUtilization;

    return (
        <>
            <PageTitle>{title}</PageTitle>
            <ShadowBox title={title} actionPanel={actionPanel}>
                {
                    !storageSite && <Loader />
                }
                {
                    storageSite &&
                    (
                        <>
                            <Table size="small">
                                <TableHead>
                                    <TableRow>
                                        <TableCell>{strings.fromUppercase}</TableCell>
                                        <TableCell>{strings.toUppercase}</TableCell>
                                        <TableCell align="right">{strings.numberOfBookings}</TableCell>
                                        <TableCell align="right">{strings.numberOfFreeStorages}</TableCell>
                                        <TableCell align="right">{strings.totalNumberOfStorages}</TableCell>
                                        <TableCell align="right">{strings.occupiedArea}</TableCell>
                                        <TableCell align="right">{strings.totalArea}</TableCell>
                                        <TableCell align="right">{strings.occupiedArea} (%)</TableCell>
                                        <TableCell align="right">{strings.totalBookingValue}</TableCell>
                                        <TableCell align="right">{strings.totalStorageSiteValue}</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {
                                        storageSite.bookingCalendar.capacityUtilizations.map((cu, i) => (
                                            <TableRow key={i}>
                                                <TableCell className={classes.nowrap}>
                                                    {cu.interval.startDate}
                                                </TableCell>
                                                <TableCell className={classes.nowrap}>
                                                    {cu.interval.endDate}
                                                </TableCell>
                                                <TableCell align="right">
                                                    {cu.bookedStorageCount}
                                                </TableCell>
                                                <TableCell align="right">
                                                    {(cu.totalStorageCount !== undefined ? cu.totalStorageCount - cu.bookedStorageCount : '')}
                                                </TableCell>
                                                <TableCell align="right">
                                                    {(cu.totalStorageCount !== undefined ? cu.totalStorageCount : '')}
                                                </TableCell>
                                                <TableCell align="right">
                                                    <Area value={cu.bookedArea} numberOfDecimals={0} />
                                                </TableCell>
                                                <TableCell align="right">
                                                    <Area value={cu.totalArea} numberOfDecimals={0} />
                                                </TableCell>
                                                <TableCell align="right">
                                                    <Percentage value={cu.totalArea ? cu.bookedArea / cu.totalArea : undefined} numberOfDecimals={1}/>
                                                </TableCell>
                                                <TableCell align="right">
                                                    <Amount value={getValueForCurrency(cu.totalValue, getCurrency(cu.totalValue))} currency={getCurrency(cu.totalValue)} />
                                                </TableCell>
                                                <TableCell align="right">
                                                    <Amount value={getValueForCurrency(cu.amount, getCurrency(cu.amount))} currency={getCurrency(cu.amount)} />
                                                </TableCell>
                                            </TableRow>
                                        ))
                                    }
                                </TableBody>
                            </Table>

                            <ButtonContainer>
                                <Button
                                    variant="contained"
                                    color="primary"
                                    component={Link}
                                    to={routes.account.ownerBookings + '?' + queryString.stringify({ storageSiteId: storageSite.id })}
                                >
                                    {strings.showBookingList}
                                </Button>
                                <Button
                                    variant="contained"
                                    color="primary"
                                    component={Link}
                                    to={routes.account.storageSites}
                                >
                                    {strings.showStorageSiteList}
                                </Button>
                                <Button
                                    variant="contained"
                                    color="primary"
                                    component={Link}
                                    to={routes.account.storageSiteBookingOverview.replace(':storageSiteId', storageSite.id)}
                                >
                                    {strings.showBookingOverview}
                                </Button>
                            </ButtonContainer>
                        </>
                    )
                }
            </ShadowBox>
        </>
    );
};

export default CapacityUtilizationTable;
