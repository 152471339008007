import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from 'styles/util';

const useStyles = makeStyles(({ theme }) => ({
    adminActionWrapper: {
        [theme.breakpoints.up('md')]: {
            maxWidth: '400px'
        },
        flexGrow: 1,
        '& > *': {
            marginBottom: theme.spacing(1),
            width: '100%'
        }
    }
}));

const AdminActionWrapper = (props) => {
    const classes = useStyles();
    return (
        <div className={classes.adminActionWrapper}>
            {props.children}
        </div>
    );
};

AdminActionWrapper.propTypes = {
    children: PropTypes.oneOfType([
        PropTypes.arrayOf(PropTypes.node),
        PropTypes.node
    ])
};

export default AdminActionWrapper;
