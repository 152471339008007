import React, { useState, useEffect } from 'react';
import { useAppContext } from 'context/AppContext';
import { handleResponse } from 'actions/actionHelpers';
import { validatePassword } from 'actions/authentication';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import store from 'store2';
import { required } from 'form/validation';
import { makeStyles } from 'styles/util';

import MuiContainer from '@material-ui/core/Container';
import Loader from 'common/Loader';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import Form from 'form/Form';
import Alert from '@material-ui/lab/Alert';
import { TextField, showErrorOnBlur } from 'mui-rff';

const useStyles = makeStyles(({ theme }) => ({
    container: {
        margin: theme.spacing(4, 0),
        width: '400px',
        maxWidth: '100%'
    },
    logotype: {
        width: '200px',
        maxWidth: '100%'
    }
}));

const PasswordProtector = ({ passwordType, localhostPassthrough, children }) => {
    const { appContext } = useAppContext();
    const usePassword = appContext.authentication.usePasswords.includes(passwordType) && !(localhostPassthrough && window.location.hostname === 'localhost');
    const storeKey = `store.authentication.passwords.${passwordType}`;

    if(!usePassword) {
        return children;
    }

    const classes = useStyles();
    const dispatch = useDispatch();

    const password = store.get(storeKey);

    const [isLoading, setIsLoading] = useState(!!password);
    const [passwordValidated, setPasswordValidated] = useState(undefined);

    const initialValues = {
        password: null
    };

    useEffect(() => {
        if(password) {
            validate(password);
        }
    }, []);

    const handleFormSubmit = formValues => {
        store.set(storeKey, formValues.password);
        validate(formValues.password);
    };

    const validate = value => {
        setIsLoading(true);
        dispatch(validatePassword(passwordType, value))
            .then(handleResponse(
                response => {
                    setPasswordValidated(response.payload.isValid);
                    setIsLoading(false);
                })
            );
    };

    if(isLoading) {
        return <Loader/>;
    }

    if(passwordValidated) {
        return children;
    }

    return (
        <MuiContainer className={classes.container}>
            <img src={appContext.images.topLogo} className={classes.logotype}/>
            {
                passwordValidated === false &&
                (
                    <Alert severity="error">
                        Invalid password.
                    </Alert>
                )
            }
            {
                passwordValidated === undefined &&
                (
                    <Typography variant="body1" gutterBottom>Password required.</Typography>
                )
            }
            <Form
                initialValues={initialValues}
                onSubmit={handleFormSubmit}
            >
                {({ handleSubmit, invalid }) => (
                    <form onSubmit={handleSubmit}>
                        <TextField
                            name="password"
                            label="Password"
                            variant="outlined"
                            type="password"
                            showError={showErrorOnBlur}
                            required
                            fieldProps={{ validate: required }}
                        />
                        <Button
                            type="submit"
                            variant="contained"
                            color="primary"
                            disabled={invalid}
                        >
                            OK
                        </Button>
                    </form>
                )}
            </Form>
        </MuiContainer>
    );
};

PasswordProtector.propTypes = {
    passwordType: PropTypes.string.isRequired,
    localhostPassthrough: PropTypes.bool,
    children: PropTypes.node.isRequired
};

export default PasswordProtector;
