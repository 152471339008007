import { RSAA } from 'redux-api-middleware';
import { createRsaaTypes, createHeaders } from '../actionHelpers';
import queryString from 'query-string';

export const ADMIN_PAYOUTS_SEARCH = 'ADMIN_PAYOUTS_SEARCH';
export const ADMIN_PAYOUTS_SEARCH_SUCCESS = 'ADMIN_PAYOUTS_SEARCH_SUCCESS';
export const ADMIN_PAYOUTS_SEARCH_FAILURE = 'ADMIN_PAYOUTS_SEARCH_FAILURE';

export const ADMIN_PAYOUTS_SET_SEARCH_PARAMETERS = 'ADMIN_PAYOUTS_SET_SEARCH_PARAMETERS';

export const ADMIN_PAYOUTS_GET = 'ADMIN_PAYOUTS_GET';

export const ADMIN_PAYOUTS_RETRY = 'ADMIN_PAYOUTS_RETRY';

export const searchPayouts = searchParameters => ({
    [RSAA]: {
        types: createRsaaTypes(ADMIN_PAYOUTS_SEARCH),
        endpoint: `/api/admin/payouts?${queryString.stringify(searchParameters)}`,
        headers: createHeaders(false),
        method: 'GET'
    }
});

export const getPayout = payoutId => ({
    [RSAA]: {
        types: createRsaaTypes(ADMIN_PAYOUTS_GET),
        endpoint: `/api/admin/payouts/${payoutId}`,
        headers: createHeaders(false),
        method: 'GET'
    }
});

export const retryPayout = payoutId => ({
    [RSAA]: {
        types: createRsaaTypes(ADMIN_PAYOUTS_RETRY),
        endpoint: `/api/admin/payouts/${payoutId}/retry`,
        headers: createHeaders(false),
        method: 'PUT'
    }
});

export const setSearchParameters = searchParameters => ({
    type: ADMIN_PAYOUTS_SET_SEARCH_PARAMETERS,
    searchParameters
});
