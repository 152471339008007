import PropTypes from 'prop-types';
import { useAppContext } from 'context/AppContext';
import { formatNumber } from 'helpers/StringHelper';

const Number = ({ value, ...rest }) => {
    const { appContext } = useAppContext();
    return formatNumber(value, appContext, { ...rest });
};

Number.propTypes = {
    value: PropTypes.number.isRequired,
    numberOfDecimals: PropTypes.number,
    maxNumberOfDecimals: PropTypes.number,
    toLocale: PropTypes.bool,
    sanitizeMinusSign: PropTypes.bool,
    skipThousandSeparators: PropTypes.bool,
    defaultValue: PropTypes.string
};

export default Number;
