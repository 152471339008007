import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from 'styles/util';
import { Link } from 'react-router-dom';

import Box from '@material-ui/core/Box';

const AccountLink = ({ to, children, className, badge }) => {
    const useStyles = makeStyles(({ theme, colors, fonts, styles }) => ({
        accountLink: {
            width: '100%',
            backgroundColor: colors.white,
            border: `1px solid ${colors.lightGreyBorder ?? colors.mediumGrey}`,
            borderRadius: `${styles.cardBorderRadius}px`,
            color: `${colors.textColor} !important`,
            textDecoration: 'none',
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            boxShadow: styles.boxShadow,
            padding: theme.spacing(1.875, 1.25, 1.875, 2.5),
            transition: `box-shadow ${styles.transitionEffect}, transform ${styles.transitionEffect}`,
            '& svg': {
                fill: colors.textColor
            },
            '&:hover': {
                boxShadow: styles.boxShadowHover,
                transform: styles.transformHover
            }
        },
        iconContainer: {
            display: 'flex',
            alignItems: 'center',
            gap: theme.spacing(1.25)
        },
        badge: {
            backgroundColor: colors.primaryColor,
            color: colors.white,
            borderRadius: '50%',
            height: '1.75rem',
            width: '1.75rem',
            lineHeight: '1.75rem',
            textAlign: 'center',
            padding: 0,
            fontFamily: fonts.bold,
            fontSize: theme.typography.body1.fontSize,
        }
    }));
    const classes = useStyles();

    return (
        <Link to={to} className={[classes.accountLink, className].filter(Boolean).join(' ')}>
            {children}
            <Box className={classes.iconContainer}>
                {badge && <Box className={classes.badge}>{badge}</Box>}
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
                    <path d="M9.70504 6L8.29504 7.41L12.875 12L8.29504 16.59L9.70504 18L15.705 12L9.70504 6Z" />
                </svg>
            </Box>
        </Link>
    );
};

AccountLink.propTypes = {
    to: PropTypes.string.isRequired,
    children: PropTypes.node.isRequired,
    className: PropTypes.string,
    badge: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
};

export default AccountLink;
