import React  from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from 'styles/util';

import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';

const useStyles = makeStyles(({ theme }) => ({
    boardPositionMembersContainer: {
        display: 'flex',
        flexDirection: 'column',
        [theme.breakpoints.down('sm')]: {
            flex: '1 1 0px',
            minWidth: '200px',
        }
    }
}));

const BoardPositionMembers = ({position, members}) => {
    const classes = useStyles();
    return (
        <Box className={classes.boardPositionMembersContainer}>
            <Typography variant="h3" gutterBottom>{position}</Typography>
            {Object.entries(members).map(([key, memberName]) => (
                <Typography key={key} variant="body1" display="block" gutterBottom>{memberName}</Typography>
            ))}
        </Box>
    );
};


BoardPositionMembers.propTypes = {
    position: PropTypes.string,
    members: PropTypes.arrayOf(PropTypes.string)
};

export default BoardPositionMembers;
