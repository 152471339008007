import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from 'styles/util';
import { formatClassNames } from 'helpers/ComponentHelper';

const useStyles = makeStyles(({ theme, colors }) => ({
    listItem: {
        position: 'relative',
        display: 'flex',
        '&::before': {
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            color: colors.white,
            fontSize: theme.typography.pxToRem(22),
            borderRadius: '50%',
            backgroundColor: colors.primaryColor,
            width: '2rem',
            height: '2rem',
            marginRight: theme.spacing(2.5),
            flexShrink: 0,
            fontFamily: theme.overrides.MuiTypography.h3.fontFamily,
            lineHeight: 1
        },
        '& > *': {
            display: 'inline-flex',
        }
    }
}));

const StyledListItem = ({
    children,
    className,
    ...rest
}) => {
    const classes = useStyles();
    return (
        <li className={formatClassNames([classes.listItem, className])}  {...rest}>
            {children}
        </li>
    );
};

StyledListItem.propTypes = {
    children: PropTypes.node.isRequired,
    className: PropTypes.string
};

export default StyledListItem;
