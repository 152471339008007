import React from 'react';
import strings from 'localization/strings';
import { useDispatch, useSelector } from 'react-redux';
import { closeSuccessDialog } from 'actions/responses';
import { useAppContext } from 'context/AppContext';

import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import Alert from '@material-ui/lab/Alert';
import Lottie from 'react-lottie';

const SuccessDialog = () => {
    const { appContext } = useAppContext();
    const dispatch = useDispatch();
    const open = useSelector(state => state.responses.successDialogOpen);

    const { title, body, severity = 'success', fullWidth = false, onClose, useAnimation = false } = useSelector(state => state.responses.successDialogData) ?? {};

    const handleClose = () => {
        if(onClose) {
            onClose();
        }
        dispatch(closeSuccessDialog());
    };

    return (
        <Dialog open={open} onClose={handleClose} fullWidth={fullWidth}>
            {
                useAnimation && (
                    <Lottie
                        options={{
                            loop: false,
                            autoplay: true,
                            animationData: appContext.animations.success,
                            rendererSettings: {
                                preserveAspectRatio: 'xMidYMid slice'
                            }
                        }}
                        height={250}
                        width={250}
                    />
                )
            }
            <DialogTitle disableTypography>
                <Typography variant="h5">
                    {title}
                </Typography>
            </DialogTitle>
            <DialogContent>
                {
                    useAnimation
                        ? body
                        : <Alert severity={severity}>{body}</Alert>
                }
            </DialogContent>
            <DialogActions>
                <Button
                    type="button"
                    variant="contained"
                    color="primary"
                    onClick={handleClose}
                    fullWidth
                >
                    {strings.close}
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default SuccessDialog;
