import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import strings from 'localization/strings';
import { makeStyles } from 'styles/util';

import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';

const useStyles = makeStyles(({ theme, colors }) => ({
    tableHeader: {
        borderBottom: `1px solid ${colors.lightGreyBorder ?? colors.mediumGrey}`,
        padding: theme.spacing(1.875)
    }
}));

const PriceSpecificationHeader = ({ rowClassName }) => {
    const classes = useStyles();
    return (
        <Box className={clsx(classes.tableHeader, rowClassName)}>
            <Typography variant="body2">{strings.priceSpecificationTable.description}</Typography>
            <Typography variant="body2">{strings.priceSpecificationTable.amount}</Typography>
        </Box>
    );
};

PriceSpecificationHeader.propTypes = {
    rowClassName: PropTypes.string,
};

export default PriceSpecificationHeader;
