import { RSAA } from 'redux-api-middleware';
import { createRsaaTypes, createHeaders } from '../actionHelpers';
import queryString from 'query-string';

export const ADMIN_CONVERSATIONS_SEARCH = 'ADMIN_CONVERSATIONS_SEARCH';
export const ADMIN_CONVERSATIONS_SEARCH_SUCCESS = 'ADMIN_CONVERSATIONS_SEARCH_SUCCESS';
export const ADMIN_CONVERSATIONS_SEARCH_FAILURE = 'ADMIN_CONVERSATIONS_SEARCH_FAILURE';
export const ADMIN_CONVERSATIONS_SET_SEARCH_PARAMETERS = 'ADMIN_CONVERSATIONS_SET_SEARCH_PARAMETERS';

export const ADMIN_CONVERSATIONS_GET = 'ADMIN_CONVERSATIONS_GET';

export const ADMIN_CONVERSATIONS_UPDATE = 'ADMIN_CONVERSATIONS_UPDATE';

export const ADMIN_CONVERSATIONS_CREATE_MESSAGE = 'ADMIN_CONVERSATIONS_CREATE_MESSAGE';

export const searchConversations = (searchParameters) => ({
    [RSAA]: {
        types: createRsaaTypes(ADMIN_CONVERSATIONS_SEARCH),
        endpoint: `/api/admin/conversations?${queryString.stringify(searchParameters)}`,
        headers: createHeaders(false),
        method: 'GET'
    }
});

export const getConversation = (conversationId) => ({
    [RSAA]: {
        types: createRsaaTypes(ADMIN_CONVERSATIONS_GET),
        endpoint: `/api/admin/conversations/${conversationId}`,
        headers: createHeaders(false),
        method: 'GET'
    }
});

export const updateConversation = (conversationId, updateConversationRequest) => ({
    [RSAA]: {
        types: createRsaaTypes(ADMIN_CONVERSATIONS_UPDATE),
        endpoint: `/api/admin/conversations/${conversationId}`,
        headers: createHeaders(true),
        method: 'PUT',
        body: JSON.stringify(updateConversationRequest)
    }
});

export const createMessage = (conversationId, createMessageRequest) => ({
    [RSAA]: {
        types: createRsaaTypes(ADMIN_CONVERSATIONS_CREATE_MESSAGE),
        endpoint: `/api/admin/conversations/${conversationId}/messages`,
        headers: createHeaders(true),
        method: 'POST',
        body: JSON.stringify(createMessageRequest)
    }
});

export const setSearchParameters = (searchParameters) => ({
    type: ADMIN_CONVERSATIONS_SET_SEARCH_PARAMETERS,
    searchParameters
});
