import React from 'react';
import strings from 'localization/strings';

export const getSwedishBankIdMessage = ({ status, hintCode, errorCode, deviceType, useOtherDevice, deviceMightRequireUserInteractionToLaunchBankIdApp }) => {
    // see https://www.bankid.com/utvecklare/guider/teknisk-integrationsguide/rp-anvaendarfall
    if(status === 'pending' && hintCode === 'outstandingTransaction' && useOtherDevice) {
        return {
            text: strings.bankId.scanQrCode,
            type: 'info'
        };
    }

    if(status === 'pending' && hintCode === 'noClient') {
        return {
            text: strings.bankId.rfa1,
            type: 'info'
        };
    }

    if(errorCode === 'cancelled') {
        return {
            text: strings.bankId.rfa3,
            type: 'error'
        };
    }

    if(errorCode === 'alreadyInProgress') {
        return {
            text: strings.bankId.rfa4,
            type: 'error'
        };
    }

    if(errorCode === 'requestTimeout' || errorCode === 'maintenance' || errorCode === 'internalError') {
        return {
            text: strings.bankId.rfa5,
            type: 'error'
        };
    }

    if(status === 'failed' && hintCode === 'userCancel') {
        return {
            text: strings.bankId.rfa6,
            type: 'error'
        };
    }

    if(status === 'failed' && hintCode === 'expiredTransaction') {
        return {
            text: strings.bankId.rfa8,
            type: 'error'
        };
    }

    if(status === 'pending' && hintCode === 'userSign') {
        return {
            text: strings.bankId.rfa9,
            type: 'info'
        };
    }

    if(status === 'pending' && hintCode === 'outstandingTransaction') {
        return {
            text: deviceMightRequireUserInteractionToLaunchBankIdApp
                ? undefined
                : strings.bankId.rfa13,
            type: 'info'
        };
    }

    if(status === 'pending' && hintCode === 'started' && deviceType !== 'Mobile') {
        return {
            text: <>{strings.searchingForBankId}<br/><br/>{strings.bankId.rfa15a}</>,
            type: 'info'
        };
    }

    if(status === 'pending' && hintCode === 'started' && deviceType === 'Mobile') {
        return {
            text: <>{strings.searchingForBankId}<br/><br/>{strings.bankId.rfa15b}</>,
            type: 'info'
        };
    }

    if(status === 'failed' && hintCode === 'certificateErr') {
        return {
            text: strings.bankId.rfa16,
            type: 'error'
        };
    }

    if(status === 'failed' && hintCode === 'startFailed') {
        return {
            text: strings.bankId.rfa17b,
            type: 'error'
        };
    }

    if(status === 'pending') {
        return {
            text: strings.bankId.rfa21,
            type: 'info'
        };
    }

    if(status === 'failed') {
        return {
            text: strings.bankId.rfa22,
            type: 'error'
        };
    }

    if(status === 'complete') {
        return {
            text: strings.bankId.complete,
            type: 'success'
        };
    }

    return {
        text: undefined,
        type: undefined
    };
};
