import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from 'styles/util';
import { getGoogleMapsScript } from 'helpers/MapHelper';

import Box from '@material-ui/core/Box';
import { GoogleMap, Marker } from '@react-google-maps/api';

const useStyles = makeStyles(({ theme }) => ({
    mapContainer: {
        width: '100%',
        [theme.breakpoints.down('sm')]: {
            padding: theme.spacing(2, 0)
        }
    },
    map: {
        width: '100%',
        height: '400px'
    }
}));

const StorageSiteMap = ({ storageSite }) => {
    const classes = useStyles();
    const googleMapsScript = getGoogleMapsScript();

    if(!storageSite.location || !googleMapsScript.isLoaded || window.configuration.userAgentIsBot) {
        return null;
    }

    const options = {
        streetViewControl: false
    };

    const center = {
        lat: storageSite.location.latitude,
        lng: storageSite.location.longitude
    };

    return (
        <Box className={classes.mapContainer}>
            <GoogleMap
                mapContainerClassName={classes.map}
                center={center}
                options={options}
                zoom={13}
            >
                <Marker position={center} />
            </GoogleMap>
        </Box>
    );
};

StorageSiteMap.propTypes = {
    storageSite: PropTypes.object.isRequired
};

export default StorageSiteMap;
