import React, { useState } from 'react';
import PropTypes from 'prop-types';
import strings from 'localization/strings';
import { required } from 'form/validation';

import Form from 'form/Form';
import MessageConsent from './MessageConsent';
import { TextField, showErrorOnBlur } from 'mui-rff';
import Dialog from '@material-ui/core/Dialog/Dialog';
import DialogContent from '@material-ui/core/DialogContent/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogActions from '@material-ui/core/DialogActions/DialogActions';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';

const MessageDialog = ({ title, content, open, onOk, onClose }) => {
    const initialValues = {
        messageText: undefined
    };

    const [consentGiven, setConsentGiven] = useState(false);

    const handleFormSubmit = (values) => {
        onOk(values.messageText);
    };

    return (
        <Dialog open={open}>
            <Form
                initialValues={initialValues}
                onSubmit={handleFormSubmit}
            >
                {({ handleSubmit, invalid }) => (
                    <form onSubmit={handleSubmit}>
                        <DialogTitle disableTypography>
                            <Typography variant="h5">
                                {title}
                            </Typography>
                        </DialogTitle>
                        <DialogContent>
                            <Box>
                                {content}
                            </Box>
                            <TextField
                                name="messageText"
                                variant="outlined"
                                showError={showErrorOnBlur}
                                rows={3}
                                multiline
                                fullWidth
                                required
                                fieldProps={{ validate: required }}
                            />
                            <MessageConsent
                                onConsentToggled={(value) => setConsentGiven(value)}
                            />
                        </DialogContent>
                        <DialogActions>
                            {
                                onOk &&
                                (
                                    <Button
                                        type="submit"
                                        variant="contained"
                                        color="primary"
                                        disabled={invalid || !consentGiven}
                                    >
                                        {strings.send}
                                    </Button>
                                )
                            }
                            {
                                onClose &&
                                (
                                    <Button
                                        variant="outlined"
                                        color="primary"
                                        onClick={onClose}
                                    >
                                        {strings.cancel}
                                    </Button>
                                )
                            }
                        </DialogActions>
                    </form>
                )}
            </Form>
        </Dialog>
    );
};

MessageDialog.propTypes = {
    title: PropTypes.string.isRequired,
    content: PropTypes.string.isRequired,
    open: PropTypes.bool.isRequired,
    onOk: PropTypes.func.isRequired,
    onClose: PropTypes.func.isRequired,
};

export default MessageDialog;
