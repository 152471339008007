const additionalServiceStatuses = {
    active: {},
    deleted: {}
};

Object.keys(additionalServiceStatuses).forEach(key => {
    additionalServiceStatuses[key].key = key;
});

export default additionalServiceStatuses;
