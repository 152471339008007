import React from 'react';
import { parseIsoDate, formatShortDate, formatShortDateTime, formatMonth, formatYear } from 'helpers/DateHelper';
import { calculateSum } from 'helpers/ArrayHelper';
import strings from 'localization/strings';

import Number from 'common/Number';
import Percentage from 'common/Percentage';


const formatFloat = (value, formatString) => {
    if(isNaN(value)) {
        return <></>;
    }
    // kind of fragile, a JS function that mimics C# would be ideal
    if(formatString === '0.0%') {
        return <Percentage value={value} numberOfDecimals={1}/>;
    }
    if(formatString === '#,##0.00') {
        return <Number value={value} numberOfDecimals={2}/>;
    }
    if(formatString === '#,##0') {
        return <Number value={value} numberOfDecimals={0}/>;
    }
    return <Number value={value}/>;
};

const dataTypes = {
    string: {
        render: value => value
    },
    time: {
        render: (value, column, appContext) => {
            const dateValue = parseIsoDate(value);
            return formatShortDateTime(dateValue, appContext);
        }
    },
    date: {
        render: (value, column, appContext) => {
            const dateValue = parseIsoDate(value);
            return formatShortDate(dateValue, appContext);
        }
    },
    month: {
        render: (value, column, appContext) => {
            const dateValue = parseIsoDate(value);
            return formatMonth(dateValue, appContext);
        }
    },
    year: {
        render: (value, column, appContext) => {
            const dateValue = parseIsoDate(value);
            return formatYear(dateValue, appContext);
        }
    },
    float: {
        render: (value, column) => formatFloat(value, column.formatString),
        cellAlign: 'right'
    },
    integer: {
        render: value => value,
        cellAlign: 'right'
    },
    decimal: {
        render: (value, column) => formatFloat(value, column.formatString),
        cellAlign: 'right'
    },
    boolean: {
        render: value => value ? strings.yes : strings.no
    }
};

const getRenderFunction = (column, reportDefinition) => {
    const renderFunction = reportDefinition?.renderers
        ? reportDefinition.renderers[column.key]
        : undefined;
    return renderFunction ?? dataTypes[column.dataType].render;
};

export const renderCell = (item, column, columnIndex, reportDefinition, appContext) => {
    const renderFunction = getRenderFunction(column, reportDefinition);
    return renderFunction(item.cells[columnIndex].value, column, appContext);
};

export const renderFooterCell = (rows, column, columnIndex, reportDefinition, appContext) => {
    if(column.footerSum) {
        const renderFunction = getRenderFunction(column, reportDefinition);
        return renderFunction(calculateSum(rows.map(row => row.cells[columnIndex].value)), column, appContext);
    }
    return undefined;
};

export const getCellAlign = column => dataTypes[column.dataType].cellAlign;

export const createReportDefinitions = (originalReportDefinitions, initialValues, authenticationContext, data = {}) => {
    const reportDefinitions = {
        ...originalReportDefinitions
    };
    const context = {
        authenticationContext,
        data
    };
    Object.values(reportDefinitions).forEach(reportDefinition => {
        // for each report definition
        initialValues.inputFields[reportDefinition.key] = {};
        reportDefinition.inputFields.forEach(inputField => {
            // for each input field in the report definition
            initialValues.inputFields[reportDefinition.key][inputField.name] = inputField.defaultValueGetter
                ? inputField.defaultValueGetter(context)
                : undefined;
                if(inputField.dataGetter) {
                    inputField.data = inputField.dataGetter(context);
                }
                if(inputField.visibleGetter) {
                    inputField.visible = inputField.visibleGetter(context);
                }
        });
    });
    return reportDefinitions;
};

export const createTableColumns = (reportData, reportDefinition, appContext) => {
    return reportData.columns.map((column, columnIndex) => ({
        title: column.title,
        id: columnIndex,
        render: item => renderCell(item, column, columnIndex, reportDefinition, appContext),
        align: getCellAlign(column)
    }));
};

export const createTableItems = (reportData, sortingParameters) => {
    const items = reportData.rows.map((row, rowIndex) => ({
        id: rowIndex,
        ...row
    }));

    if(sortingParameters) {
        items.sort((a, b) => {
            const v1 = a.cells[sortingParameters.sortByColumn].value;
            const v2 = b.cells[sortingParameters.sortByColumn].value;
            const sign = sortingParameters.sortDirection === 'desc'
                ? -1
                : 1;
            if(v1 > v2) {
                return sign;
            }
            if(v1 < v2) {
                return -sign;
            }
            return 0;
        });
    }

    return items;
};

export const createTableFooterCells = (reportData, reportDefinition, appContext) => {
    return reportData.columns.map((column, columnIndex) => ({
        value: renderFooterCell(reportData.rows, column, columnIndex, reportDefinition, appContext),
        align: getCellAlign(column)
    }));
};
