import React from 'react';
import { showSuccessDialog, showErrorDialog, showSwitchActorDialog, setSuccessResponse, setErrorResponse } from './responses';
import { convertToNumber } from 'helpers/StringHelper';
import strings from 'localization/strings';
import { toCamelCase } from 'helpers/StringHelper';
import { enqueueAdminSnackbarMessage } from 'actions/admin/general';
import { downloadUrl } from 'helpers/BrowserHelper';
import { replaceAll } from 'helpers/StringHelper';

const resourceBelongsToHeaderName = 'X-Resource-Belongs-To';

const actionCreator = (type, message) => ({
    type,
    meta: (action, state, response) => ({
        isAdminAction: type.substr(0, 6) === 'ADMIN_',
        message,
        response
    })
});

export const createRsaaTypes = (prefix, messages) => {
    const m = messages || {};
    return [
        actionCreator(prefix),
        actionCreator(`${prefix}_SUCCESS`, m.success),
        actionCreator(`${prefix}_FAILURE`, m.failure)
    ];
};

export const handleResponse = (successAction, failureAction) => response => {
    // should be used for all api calls, pattern:
    // dispatch(apiCall(request))
    //     .then(handleResponse(
    //         response => { /* success */ },
    //         response => { /* failure */ },
    //     ));

    // successAction: function to execute on success
    //                if this function returns an object with a dialog property, a dialog is shown based on { dialog: { title, body, severity, fullWidth, onClose } }
    //                if this function returns an object with a snackbar property, a snackbar message is shown based on { snackbar: { message, variant } }
    //                otherwise, no dialog is shown
    // failureAction: function to execute on failure
    //                if this function returns false, no dialog is shown
    //                if this function returns an object with a dialog property, a dialog is shown based on { dialog: { title, body, suppressServerErrorMessage, fullWidth, onClose } }
    //                if this function returns an object with a snackbar property, a snackbar message is shown based on { snackbar: { message, variant } }
    //                otherwise, a default error dialog is shown
    if(isSwitchActorResponse(response)) {
        const actorIdToSwitchTo = getActorIdToSwitchTo(response);
        response.meta.store.dispatch(showSwitchActorDialog({ actorId: actorIdToSwitchTo }));
    } else if(isSuccessfulResponse(response)) {
        const successData = successAction && successAction(response);
        if(successData?.dialog) {
            response.meta.store.dispatch(showSuccessDialog(successData.dialog));
        }
        if(successData?.snackbar) {
            if(response.meta.isAdminAction) {
                response.meta.store.dispatch(enqueueAdminSnackbarMessage(successData.snackbar.message, { variant: successData.snackbar.variant ?? 'success' }));
            } else {
                response.meta.store.dispatch(setSuccessResponse(successData.snackbar.message));
            }
        }
    } else {
        const failureData = failureAction && failureAction(response);
        if(!failureAction || failureData !== false) {
            if(failureData?.snackbar) {
                if(response.meta.isAdminAction) {
                    response.meta.store.dispatch(enqueueAdminSnackbarMessage(failureData.snackbar.message, { variant: failureData.snackbar.variant ?? 'error' }));
                } else {
                    response.meta.store.dispatch(setErrorResponse(failureData.snackbar.message));
                }
            } else {
                response.meta.store.dispatch(showErrorDialog(response, failureData?.dialog));
            }
        }
    }
};

const isSwitchActorResponse = response => {
    return response.type.substr(response.type.length - 8) === '_FAILURE' &&
           response.payload.status === 403 &&
           response.meta?.response.headers.has(resourceBelongsToHeaderName);
};

const getActorIdToSwitchTo = response =>
    convertToNumber(response.meta.response.headers.get(resourceBelongsToHeaderName));

export const isSuccessfulResponse = response => {
    return response.type.substr(response.type.length - 8) === '_SUCCESS';
};

export const createHeaders = hasJsonPayload => state => {
    const headers = {
        'Accept': 'application/json'
    };
    if (state.authentication.token) {
        headers['Authorization'] = state.authentication.token; // eslint-disable-line dot-notation
    }
    if (state.authentication.passwords?.site) {
        headers['SitePassword'] = state.authentication.passwords.site; // eslint-disable-line dot-notation
    }
    if (hasJsonPayload) {
        headers['Content-Type'] = 'application/json';
    }
    return headers;
};

export const createDownloadFileTypes = actionName => [
    actionName,
    {
        type: `${actionName}_SUCCESS`,
        payload: downloadFile
    },
    `${actionName}_FAILURE`
];

export const getSuccessMessage = successResponse => {
    return successResponse.meta?.message ??
           successResponse.snackbar?.message;
};

export const getErrorMessage = errorResponse => {
    // format of error response from API:
    // { error: true, meta: { isAdminAction, message, response: { status, statusText, headers, ... }, store }, payload: { message, name, response: { Message, ModelState }, status, statusText, stack } }
    if(!errorResponse) {
        return undefined;
    }
    if(errorResponse.payload?.response && typeof errorResponse.payload.response === 'string') {
        // response is a plain string
        return errorResponse.payload.response;
    } else if(errorResponse.payload?.response && typeof errorResponse.payload.response.ModelState === 'object' && Object.keys(errorResponse.payload.response.ModelState).length > 0) {
        // model state object
        return formatModelStateError(errorResponse.payload.response.ModelState);
    } else if(errorResponse.payload?.response && typeof errorResponse.payload.response.errors === 'object' && Object.keys(errorResponse.payload.response.errors).length > 0) {
        // model state (framework)
        return formatModelStateError(errorResponse.payload.response.errors);
    } else if(errorResponse.payload?.status === 401) {
        // HTTP 401 Unauthorized
        return <>401 Unauthorized &ndash; {strings.errorMessages.http401}</>;
    } else if(errorResponse.payload?.status === 403) {
        // HTTP 403 Forbidden
        return <>403 Forbidden &ndash; {strings.errorMessages.http403}</>;
    } else if(errorResponse.payload?.message) {
        // generic API error message
        return errorResponse.payload.message;
    } else if(errorResponse.meta?.message) {
        // message in meta object
        return errorResponse.meta.message;
    }
    return undefined;
};

const formatModelStateError = modelState => (
    <>
    {
        Object.keys(modelState)
            .map((key, i) => {
                let messages = [];
                if(typeof modelState[key] === 'string') {
                    messages = [localizeErrorMessage(modelState[key], key)];
                } else if(Array.isArray(modelState[key])) {
                    messages = modelState[key].map(o => localizeErrorMessage(o, key));
                }
                return (
                    <React.Fragment key={i}>
                        { messages.map((message, j) => <div key={j}>{message}</div>) }
                    </React.Fragment>
                );
            })
    }
    </>
);

const localizeErrorMessage = (message, key) => {
    const localizedMessage = strings.errorMessages[toCamelCase(message)];
    if(localizedMessage) {
        return localizedMessage;
    }
    if(key) {
        return `${key}: ${message}`;
    }
    return message;
};

const downloadFile = (action, state, response) => {
    const contentDisposition  = response.headers.get('Content-Disposition');

    // the API returns a Content-Disposition header value like attachment; filename=whatever.ext; filename*=UTF-8''whatever.ext
    // trim the garbage
    let fileName;
    if(contentDisposition) {
        fileName = contentDisposition.substr(contentDisposition.indexOf('filename=') + 9);
        const semicolonPos = fileName.indexOf(';');
        if(semicolonPos > -1) {
            fileName = fileName.substr(0, semicolonPos);
        }
        fileName = replaceAll(fileName, '"', '');
    }

    response.blob().then(blob => {
        const url = window.URL.createObjectURL(blob);
        downloadUrl(url, { fileName, newTab: false });
    });
};
