import React, { useState } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { makeStyles } from 'styles/util';
import { useAppContext } from 'context/AppContext';
import strings from 'localization/strings';
import { createPaymentFormInitialValues, sanitizePaymentFormValues, stripeFormIsIncomplete, getAvailableAndCurrentPaymentProviders, getSelectedPaymentProvider } from 'logic/paymentLogic';
import { getContact, getAddress } from 'helpers/ActorHelper';
import purchaseTypes from 'enums/purchaseTypes';
import contactTypes from 'enums/contactTypes';
import paymentProviders from 'enums/paymentProviders';
import addressTypes from 'enums/addressTypes';
import { formatIsoDate } from 'helpers/DateHelper';

import StripeElementsWrapper from 'components/stripe/StripeElementsWrapper';
import Form from 'form/Form';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import PaymentElement from 'components/payment/PaymentElement';
import Amount from 'common/Amount';
import ShortDate from 'common/ShortDate';
import MarginBox from 'common/MarginBox';
import InitialPaymentPriceSpecification from './InitialPaymentPriceSpecification';

const useStyles = makeStyles(({theme}) => ({
    actions: {
        flexWrap: 'wrap',
        gap: theme.spacing(2)
    },
    btnSmall: {
        height: '40px',
    },
    halfWidth: {
        flex: `0 0 calc(50% - ${theme.spacing(1)}px)`
    }
}));

const ExtendBookingStep2Form = ({ existingBooking, newBookingForExtendedPeriod, endDate, isSaving, onOk, onCancel }) => {
    const { appContext } = useAppContext();
    const classes = useStyles();
    const [paymentElementProvider, setPaymentElementProvider] = useState(undefined);
    const [paymentSetupComplete, setPaymentSetupComplete] = useState(false);

    const availablePaymentProviders = getAvailableAndCurrentPaymentProviders(existingBooking);

    const selectedPaymentProvider = getSelectedPaymentProvider(existingBooking, availablePaymentProviders);

    const createStripePaymentIntentRequest = availablePaymentProviders.includes(paymentProviders.stripe.key)
        ? {
            type: purchaseTypes.periodBookingPurchase.key,
            bookingIdToExtend: existingBooking.id,
            endDate: formatIsoDate(endDate)
        }
        : undefined;

    const initialValues = {
        payment: createPaymentFormInitialValues({
            paymentProvider: selectedPaymentProvider,
            billecta: {
                collectAddress: true,
                name: existingBooking.tenantActor.name,
                email: getContact(existingBooking.tenantActor, [contactTypes.invoiceEmail, contactTypes.mainEmail]),
                address: existingBooking.invoiceAddress ?? getAddress(existingBooking.tenantActor, addressTypes.invoice)
            }
        }, appContext)
    };

    const handleFormSubmit = values => {
        onOk(values);
    };

    const handleSelectedPaymentProvider = (provider) => {
        setPaymentElementProvider(provider);
    };

    const handlePaymentSetupComplete = (isComplete) => {
        setPaymentSetupComplete(isComplete);
    };

    const getAmountToPay = paymentProvider => {
        const invoiceInfo = newBookingForExtendedPeriod.paymentProviderInvoiceInfos
            .find(o => o.paymentProvider === paymentProvider);
        if(!invoiceInfo) {
            return undefined;
        }
        const amounts = invoiceInfo.initialInvoice.priceInfo.amounts;
        return amounts.totalAmount + amounts.totalVat + amounts.roundingCorrection;
    };

    const getPayButtonText = paymentProvider => {
        const amountToPay = getAmountToPay(paymentProvider);
        if(amountToPay === undefined) {
            return strings.pay;
        }
        return strings.formatString(paymentProviders[paymentProvider].payAmountLabel, <Amount value={amountToPay} currency={newBookingForExtendedPeriod.currency}/>);
    };

    return (
        <StripeElementsWrapper createStripePaymentIntentRequest={createStripePaymentIntentRequest}>
            {
                ({ stripePaymentContext }) => (
                    <Form
                        initialValues={initialValues}
                        initialValuesEqual={() => true}
                        onSubmit={values => handleFormSubmit(sanitizePaymentFormValues(values, stripePaymentContext))}
                    >
                        {({ handleSubmit, values, invalid }) => {
                            return (
                                <form onSubmit={handleSubmit}>
                                    <DialogTitle disableTypography>
                                        <Typography variant="h5">
                                            {strings.extendBookingDialogTitle}
                                        </Typography>
                                    </DialogTitle>
                                    <DialogContent>
                                        <Typography variant="body1">
                                            {strings.extendedEndDate}: <ShortDate value={endDate}/>
                                        </Typography>
                                        <MarginBox top={4}>
                                            <PaymentElement
                                                name="payment"
                                                paymentProviders={availablePaymentProviders}
                                                stripePaymentContext={stripePaymentContext}
                                                onSelectedPaymentProvider={handleSelectedPaymentProvider}
                                                paymentSetupComplete={paymentSetupComplete}
                                            >
                                                <InitialPaymentPriceSpecification booking={newBookingForExtendedPeriod} paymentProvider={values.payment.paymentProvider} />
                                            </PaymentElement>
                                        </MarginBox>
                                    </DialogContent>
                                    <DialogActions disableSpacing className={classes.actions}>
                                        {
                                        paymentSetupComplete &&
                                            (
                                                <>
                                                    <Button
                                                        type="submit"
                                                        variant="contained"
                                                        color="primary"
                                                        fullWidth
                                                        disabled={invalid || isSaving || stripeFormIsIncomplete(values.payment, stripePaymentContext)}
                                                    >
                                                        {getPayButtonText(values.payment.paymentProvider)}
                                                    </Button>
                                                    <Button
                                                        className={`${classes.btnSmall} ${classes.halfWidth}`}
                                                        type="button"
                                                        variant="contained"
                                                        color="secondary"
                                                        onClick={() => handlePaymentSetupComplete(false)}
                                                    >
                                                        <ArrowBackIcon />{strings.goBack}
                                                    </Button>
                                                </>
                                            )
                                        }
                                        {
                                        !paymentSetupComplete &&
                                            (
                                                <Button
                                                    type="button"
                                                    variant="contained"
                                                    color="primary"
                                                    fullWidth
                                                    onClick={() => handlePaymentSetupComplete(true)}
                                                    disabled={!paymentElementProvider}
                                                >
                                                    {strings.continue}
                                                </Button>
                                            )
                                        }
                                        <Button
                                            className={clsx(classes.btnSmall, {[classes.halfWidth]: paymentSetupComplete})}
                                            variant="contained"
                                            color="secondary"
                                            fullWidth={!paymentSetupComplete}
                                            onClick={onCancel}
                                            disabled={isSaving}
                                        >
                                            {strings.cancel}
                                        </Button>
                                    </DialogActions>
                                </form>
                            );
                        }}
                    </Form>
                )
            }
        </StripeElementsWrapper>
    );
};

ExtendBookingStep2Form.propTypes = {
    existingBooking: PropTypes.object.isRequired,
    newBookingForExtendedPeriod: PropTypes.object.isRequired,
    endDate: PropTypes.object.isRequired,
    isSaving: PropTypes.bool,
    onOk: PropTypes.func.isRequired,
    onCancel: PropTypes.func.isRequired
};

export default ExtendBookingStep2Form;
