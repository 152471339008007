import React from 'react';
import { useAppContext } from 'context/AppContext';
import strings from 'localization/strings';
import { makeStyles } from 'styles/util';
import routes from 'routes';
import { useScrollToTop } from 'helpers/BrowserHelper';

import PageTitle from 'common/PageTitle';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import { Link } from 'react-router-dom';
import MuiContainer from '@material-ui/core/Container';
import ContentBox from 'common/ContentBox';
import Text from 'common/Text';
import ContactBox from 'common/ContactBox';

const useStyles = makeStyles(({ theme, colors }) => ({
    pageWrapper: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        gap: theme.spacing(3.75),
        marginTop: theme.spacing(10),
        marginBottom: theme.spacing(3.75),
        [theme.breakpoints.down('sm')]: {
            marginTop: theme.spacing(3.75)
        }
    },
    loginIcon: {
        width: '168px',
        height: '145px',
        marginBottom: theme.spacing(2.5),
        [theme.breakpoints.down('sm')]: {
            width: '112px',
            height: '96px',
            marginBottom: theme.spacing(0)
        }
    },
    buttonContainer: {
        display: 'flex',
        gap: theme.spacing(4),
        width: '100%',
        '& > *': {
            flex: '1 1 100%'
        },
        [theme.breakpoints.down('sm')]: {
            flexDirection: 'column',
            gap: theme.spacing(3)
        }
    },
    contactBox: {
        alignSelf: 'start'
    },
    orSeparator: {
        display: 'none',
        [theme.breakpoints.down('sm')]: {
            display: 'flex',
            gap: theme.spacing(3),
            alignSelf: 'center',
            alignItems: 'center'
        }
    },
    orSeparatorLine: {
        width: theme.spacing(7.5),
        borderTop: `1px solid ${colors.darkBlue}`
    }
}));

const LoginPage = () => {
    const classes = useStyles();
    const { appContext } = useAppContext();

    const title = strings.login;

    useScrollToTop();

    return (
        <>
            <PageTitle>{title}</PageTitle>
            <MuiContainer maxWidth={false}>
                <ContentBox className={classes.pageWrapper} themeMaxWidth="xs">
                    <img className={classes.loginIcon} src={appContext.images.login} alt={strings.login} />
                    <Box>
                        <Typography variant="h2" gutterBottom>
                            {strings.login}
                        </Typography>
                        <Typography variant="body1">
                            <Text html={strings.pages.login.description}/>
                        </Typography>
                    </Box>
                    <Box className={classes.buttonContainer}>
                        <Button
                            variant="contained"
                            color="primary"
                            component={Link}
                            to={routes.eidLogIn}
                        >
                            {strings.login}
                        </Button>
                        <Box className={classes.orSeparator}>
                            <Box className={classes.orSeparatorLine}/>
                            <Box>{strings.or}</Box>
                            <Box className={classes.orSeparatorLine}/>
                        </Box>
                        <Button
                            variant="contained"
                            color="primary"
                            component={Link}
                            to={routes.createAccount}
                        >
                            {strings.createAccount}
                        </Button>
                    </Box>
                    <Box>
                        <Typography variant="h3" gutterBottom>
                            {strings.doYouNeedHelp}
                        </Typography>
                        <Typography variant="body1">
                            {strings.pages.login.helpMessage}
                        </Typography>
                    </Box>
                    <ContactBox className={classes.contactBox}/>
                </ContentBox>
            </MuiContainer>
        </>
    );
};

export default LoginPage;
