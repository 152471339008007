import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { handleResponse } from 'actions/actionHelpers';
import { updateUser } from 'actions/users';
import { refreshAuthenticationContext } from 'actions/authentication';
import strings from 'localization/strings';
import organizationTypes from 'enums/organizationTypes';
import routes from 'routes';

import LoadingWrapper from 'form/LoadingWrapper';
import UserEditor from './UserEditor';
import { Link } from 'react-router-dom';
import Separator from 'common/Separator';

const UserSettings = () => {
    const dispatch = useDispatch();

    const authenticationContext = useSelector(state => state.authentication.context);
    const { user, actors, isActorImpersonated } = authenticationContext;

    const initialValues = {
        firstName: user.firstName,
        lastName: user.lastName,
        email: user.email,
        password: undefined,
        confirmPassword: undefined
    };

    const [isSaving, setIsSaving] = useState(false);

    const showCreatePrivateActorLink = actors.find(o => o.organizationType === organizationTypes.private.key) === undefined && !isActorImpersonated;

    const handleSubmit = formValues => {
        setIsSaving(true);
        const updateUserRequest = { ...formValues };
        dispatch(updateUser(user.id, updateUserRequest))
            .then(handleResponse(
                () => {
                    dispatch(refreshAuthenticationContext())
                        .then(handleResponse(
                            () => {
                                // context in Redux state is updated by reducer
                                setIsSaving(false);
                                return {
                                    snackbar: {
                                        message: strings.userSettingsUpdated
                                    }
                                };
                            },
                            () => setIsSaving(false)
                        ));
                },
                () => setIsSaving(false)
            ));
    };

    return (
        <LoadingWrapper
            isSaving={isSaving}
        >
            <UserEditor
                initialValues={initialValues}
                authenticationType={user.authenticationType}
                isSaving={isSaving}
                onSubmit={handleSubmit}
            />
            {
                showCreatePrivateActorLink &&
                (
                    <>
                        <Separator spacing={4}/>
                        <Link to={routes.account.createPrivateActor}>
                            {strings.createPrivateActor}
                        </Link>
                    </>
                )
            }
        </LoadingWrapper>
    );
};

export default UserSettings;
